import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { debounce, join, get, head, last, isEqual, map, trim } from 'lodash'
import { reset } from 'redux-form'
import ReactQueryParams from 'react-query-params'

import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO, MODAL, PERMISSIONS } from '../../utils/enums'

import SearchInputField from '../../atoms/SearchInputField'
import Pagination from '../../atoms/Pagination'
import UsersTable from '../../components/users/UsersTable'

import { statusPush } from '../../actions/statusActions'
import locale from '../../resources/locale'
import CreateUser from '../../components/modals/CreateUser'
import DialogModal from '../../components/modals/DialogModal'
import { history } from '../../utils/history'
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'
import Breadcrumbs from '../../components/Breadcrumb'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import ExportButton from '../../components/buttons/ExportButton'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'email', 'state']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class UsersPage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		resetForm: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			users: {
				data: [],
				isLoading: true,
				isFailure: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		this.loadUsers(filtersOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.USERS_PAGE, filtersOrder)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default: break
		}
		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		this.setQueryParams({
			...filterOrder
		})
		this.loadUsers(filterOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.USERS_PAGE, filterOrder)
	}

	searchUsers = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	loadUsers = async (filters) => {
		if (this._mounted) {
			this.setState({
				users: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const { municipality } = this.props
				const query = { ...filters }
				if (municipality) {
					query.municipalityID = municipality.id
				}
				const response = await getReq(ENDPOINTS.USERS, query)
				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder(queryParams.page, 1)
				} else {
					this.setState({
						...this.state,
						users: {
							data: get(response, 'data'),
							isLoading: false,
							isFailure: false
						}
					})
				}
			} catch (error) {
				this.setState({
					...this.state,
					users: {
						data: null,
						isLoading: false,
						isFailure: false
					}
				})
			}
		}
	}

	inviteUser = async (values) => {
		const { pushStatus, municipality, selectedFiltersOrders } = this.props
		try {
			const data = {
				email: values.email,
				permissions: values.permissions
			}
			if (!municipality && data.permissions !== PERMISSIONS.ADMINISTRATOR) {
				data.municipalities = map(values.municipalities, (m) => m.value)
			}

			const endpoint = municipality ? ENDPOINTS.USERS_MUNICIPALITY(municipality.id) : ENDPOINTS.USERS
			await postReq(endpoint, null, data)
			pushStatus({
				type: INFO,
				msg: locale['page.users.add.success']
			})
			this.dismissModal()
			this.loadUsers(selectedFiltersOrders)
		} catch (error) {
			// Error
		}
	}

	openUserDetail = (user) => {
		const { match } = this.props
		const { municipalityID } = match.params

		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.users.detail'], municipalityID, user.id))
		} else {
			history.push(locale.formatString(locale['path.administration.users.detail'], user.id))
		}
	}

	setActiveUser = (data) => {
		const { pushStatus, selectedFiltersOrders } = this.props
		return async () => {
			this.dismissModal()
			try {
				await patchReq(ENDPOINTS.USER(data.id), null, { isActive: !data.isActive })
				this.loadUsers(selectedFiltersOrders)
				pushStatus({
					type: INFO,
					msg: data.isActive ? locale['page.users.deactivate.success'] : locale['page.users.activate.success']
				})
			} catch (error) {
				this.loadUsers(selectedFiltersOrders)
			}
		}
	}

	setUserRemoved = (user) => {
		const { pushStatus, selectedFiltersOrders, municipality } = this.props
		return async () => {
			this.dismissModal()
			try {
				const endpoint = municipality ? ENDPOINTS.USER_MUNICIPALITY(municipality.id, user.id) : ENDPOINTS.USER(user.id)
				await deleteReq(endpoint, null)
				this.loadUsers(selectedFiltersOrders)
				pushStatus({
					type: INFO,
					msg: locale['page.users.delete.success']
				})
			} catch (error) {
				this.loadUsers(selectedFiltersOrders)
			}
		}
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	resetForm = (modal) => {
		const { resetForm } = this.props
		if (modal === MODAL.CREATE_USER) {
			resetForm(FORMS.CREATE_USER)
		}
	}

	openModal = (modal) => (data) => {
		this.resetForm(modal)
		this.setState({
			modal,
			modalData: data
		})
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []
		if (municipality) {
			items.push({
				key: 'menu.item.municipality.info',
				name: locale['menu.item.municipality.info']
			})
			items.push({
				key: 'page.municipalities.detail.breadcrumbs',
				name: municipality.name
			})
		} else {
			items.push({
				key: 'page.administration.breadcrumbs',
				name: locale['page.administration.breadcrumbs']
			})
		}
		items.push({
			key: 'page.users.breadcrumbs',
			name: locale['page.users.breadcrumbs']
		})
		return items
	}

	render() {
		const { users, modal, modalData } = this.state
		const { selectedFiltersOrders, municipality } = this.props

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		const context = { ...selectedFiltersOrders }
		if (municipality) {
			context.municipalityID = municipality.id
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.users.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row justify={'space-between'} gutter={[16, 16]}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchUsers, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton
										url={ENDPOINTS.USERS_EXPORT}
										context={context}
									/>
								</Col>
								<Col>
									<Button
										onClick={this.openModal(MODAL.CREATE_USER)}
									>
										<PlusOutlined/>
										<span>{locale['page.users.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<UsersTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onSetActive={this.openModal(MODAL.SET_ACTIVE_USER)}
										onRemove={this.openModal(MODAL.REMOVE_USER)}
										onDetail={this.openUserDetail}
										users={get(users, 'data.users')}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										loading={get(users, 'isLoading')}
										municipality={municipality}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(users, 'data.context.pages')}
										page={get(users, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<CreateUser
						shown={modal === MODAL.CREATE_USER}
						inviteHandler={this.inviteUser}
						dismissHandler={this.dismissModal}
						municipality={municipality || null}
					/>
					<DialogModal
						shown={modal === MODAL.SET_ACTIVE_USER}
						cancelHandler={this.dismissModal}
						acceptHandler={this.setActiveUser(modalData)}
						message={
							get(modalData, 'isActive') ? locale['page.users.deactivate.message'] : locale['page.users.activate.message']
						}
						title={
							locale.formatString(
								get(modalData, 'isActive') ?
									locale['page.users.deactivate.title'] :
									locale['page.users.activate.title'],
								join([get(modalData, 'name'), get(modalData, 'surname')], ' ')
							)
						}
						acceptTitle={
							get(modalData, 'isActive') ? locale['page.users.deactivate.accept'] : locale['page.users.activate.accept']
						}
					/>
					<DialogModal
						shown={modal === MODAL.REMOVE_USER}
						cancelHandler={this.dismissModal}
						acceptHandler={this.setUserRemoved(modalData)}
						message={locale['page.users.delete.message']}
						title={locale.formatString(locale['page.users.delete.title'], join([get(modalData, 'name'), get(modalData, 'surname')], ' '))}
						acceptTitle={locale['page.users.delete.accept']}
					/>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.USERS_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	resetForm: bindActionCreators(reset, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
