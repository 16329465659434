import React from 'react'
import cx from 'classnames'
import i18next from 'i18next'

import { map, join, uniq } from 'lodash'

// @ts-ignore
import ReactTooltip from 'react-tooltip'
import locale from '../../resources/locale'
import { DEFAULT_WASTE_TYPE_COLOR, FILTER_TYPE, INVALID_STATE, INVALID_STATES, UNSUITABLE_CONDITION } from '../../utils/enums'
import { QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { formatDatetime } from '../../utils/utils'
import { BaseUser, WasteTypeBase } from '../../types/data'

export const queryParams = {
	order: 'order',
	page: 'page',
	limit: 'limit',
	columns: 'columns',

	search: 'search',
	quantityFrom: 'quantityFrom',
	quantityTo: 'quantityTo',
	codeSearch: 'codeSearch',
	serialNumberSearch: 'serialNumberSearch',
	rfidSearch: 'rfidSearch',
	rfidSerialSearch: 'rfidSerialSearch',
	scanDatetimeFrom: 'scanDatetimeFrom',
	scanDatetimeTo: 'scanDatetimeTo',
	containerTypeIDs: 'containerTypeIDs',
	municipalityWasteTypeIDs: 'municipalityWasteTypeIDs',
	collectionRoundIDs: 'collectionRoundIDs',
	weightFrom: 'weightFrom',
	weightTo: 'weightTo',
	state: 'state',
	createdAtFrom: 'createdAtFrom',
	createdAtTo: 'createdAtTo',
	deviceIDs: 'deviceIDs',
	customerNameSearch: 'customerNameSearch',
	collectionPlaceNameSearch: 'collectionPlaceNameSearch',
	municipalityNameSearch: 'municipalityNameSearch',
	collectionPlaceStreetSearch: 'collectionPlaceStreetSearch',
	collectionPlaceStreetNumberSearch: 'collectionPlaceStreetNumberSearch',
	collectionPlaceNumberSearch: 'collectionPlaceNumberSearch'
}

export const columns = () => [
	{
		name: 'code',
		sortable: true,
		title: i18next.t('loc:Kód'),
		filter: {
			name: 'codeSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'serialNumber',
		sortable: true,
		className: 'wide',
		title: i18next.t('loc:Číslo'),
		filter: {
			name: 'serialNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'rfidSerial',
		sortable: true,
		title: i18next.t('loc:RFID sériové číslo'),
		filter: {
			name: 'rfidSerialSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'rfid',
		sortable: true,
		className: 'wide',
		title: i18next.t('loc:RFID'),
		filter: {
			name: 'rfidSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'scanDatetime',
		sortable: true,
		title: i18next.t('loc:Čas zosnímania'),
		render: formatDatetime,
		filter: {
			type: FILTER_TYPE.DATE_RANGE
		}
	}, {
		name: 'containerType.name',
		sortable: true,
		title: i18next.t('loc:Typ nádoby'),
		filter: {
			name: 'containerTypeIDs',
			type: FILTER_TYPE.SELECT,
			isLoading: true
		}
	}, {
		name: 'containerType.wasteType',
		sortable: true,
		title: i18next.t('loc:Druh odpadu'),
		render: (value: WasteTypeBase) => {
			if (value) {
				return (
					<>
						<div className={'dot'} style={{ backgroundColor: `#${value.color || DEFAULT_WASTE_TYPE_COLOR}` }}/>
						{value.name}
					</>
				)
			}
			return '-'
		},
		filter: {
			name: 'municipalityWasteTypeIDs',
			type: FILTER_TYPE.SELECT,
			isLoading: true
		}
	}, {
		name: 'collectionRound.name',
		sortable: true,
		title: i18next.t('loc:Priradený zvoz'),
		filter: {
			name: 'collectionRoundIDs',
			type: FILTER_TYPE.SELECT,
			isLoading: true
		}
	}, {
		name: 'quantity',
		sortable: true,
		title: i18next.t('loc:Naplnenosť(%)'),
		render: (val: number) => val ? `${(val * 100).toFixed(1)} %` : '-',
		filter: {
			type: FILTER_TYPE.NUMBER_RANGE
		}
	}, {
		name: 'weight',
		sortable: true,
		title: i18next.t('loc:Hmotnosť'),
		render: (val: number) => val ? `${val.toFixed(2)} kg` : '-',
		filter: {
			type: FILTER_TYPE.NUMBER_RANGE
		}
	}, {
		name: 'createdAt',
		sortable: true,
		title: i18next.t('loc:Dátum vytvorenia'),
		render: formatDatetime,
		filter: {
			type: FILTER_TYPE.DATE_RANGE
		}
	}, {
		name: 'deviceID',
		sortable: true,
		title: i18next.t('loc:ID zariadenia'),
		filter: {
			name: 'deviceIDs',
			type: FILTER_TYPE.SELECT
		}
	}, {
		name: 'customer.name',
		sortable: true,
		title: i18next.t('loc:Názov platcu'),
		filter: {
			name: 'customerNameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'collectionPlace.name',
		sortable: true,
		title: i18next.t('loc:Zberné miesto'),
		filter: {
			name: 'collectionPlaceNameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'municipality.name',
		sortable: true,
		title: i18next.t('loc:Obec'),
		filter: {
			name: 'municipalityNameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'collectionPlace.street',
		sortable: true,
		title: i18next.t('loc:Ulica'),
		filter: {
			name: 'collectionPlaceStreetSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'collectionPlace.streetNumber',
		sortable: true,
		title: i18next.t('loc:Orientačné číslo'),
		filter: {
			name: 'collectionPlaceStreetNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'collectionPlace.number',
		sortable: true,
		title: i18next.t('loc:Súpisné číslo'),
		filter: {
			name: 'collectionPlaceNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'creator',
		sortable: false,
		title: i18next.t('table.columns.creator'),
		dataIndex: 'creator',
		render: (value: BaseUser) => value ? `${value.name} ${value.surname}` : '-'
	}, {
		name: 'state',
		className: 'text-center small',
		dataNames: ['id', 'invalidState', 'unsuitableConditionState', 'marker'],
		sortable: false,
		title: i18next.t('loc:Stav'),
		// eslint-disable-next-line react/prop-types
		render: ({
			id,
			invalidState,
			marker,
			unsuitableConditionState
		}: any) => {
			const items = []

			if (unsuitableConditionState) {
				// @ts-ignore
				const unsuitable = `${i18next.t('loc:Nezhodný stav')}: ${locale[`unsuitable.conditions.${unsuitableConditionState}`]}}`
				items.push((
					<div
						key={`${id}_unsuitable`}
					>
						{unsuitable}
					</div>
				))
			}

			if (invalidState && invalidState.length) {
				// @ts-ignore
				const invalid = join(uniq(map(invalidState, (state) => `${locale[`invalid.state.${state}`]}${!!marker && state === INVALID_STATE.CONTAINER ? ` - ${marker}` : ''}`)), ', ')
				items.push((
					<div key={`${id}_invalid`}>
						{i18next.t('loc:Chybný záznam')}:&nbsp;
						{invalid}
					</div>
				))
			}

			if (!unsuitableConditionState && (!invalidState || !invalidState.length)) {
				items.push((
					<div key={`${id}_ok`}>OK</div>
				))
			}

			return (
				<>
					<div
						data-tip
						data-for={`state-tooltip-${id}`}
						className={cx('state', {
							invalid: invalidState.length,
							unsuitable: !!unsuitableConditionState
						})}
					/>
					<ReactTooltip id={`state-tooltip-${id}`} place={'left'} effect={'solid'}>
						{items}
					</ReactTooltip>
				</>
			)
		},
		filter: {
			name: 'state',
			type: FILTER_TYPE.SELECT,
			values: map([...INVALID_STATES, ...Object.values(UNSUITABLE_CONDITION)], (state, index) => ({
				value: state,
				label: index < INVALID_STATES.length
					// @ts-ignore
					? locale[`invalid.state.${state}`]
					// @ts-ignore
					: locale[`unsuitable.conditions.${state}`]
			})),
			isLoading: false
		}
	}
]

export const defaultColumns = (columns: { name: string }[]) => [columns[4].name, columns[7].name, columns[8].name, columns[9].name, columns[18].name]

export const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'scanDatetime:DESC',
		allowedValues: [
			'code', 'serialNumber', 'rfid', 'rfidSerial', 'scanDatetime', 'quantity', 'weight', 'containerType.name',
			'createdAt', 'deviceID', 'customer.name', 'collectionPlace.name', 'collectionPlace.street',
			'collectionPlace.streetNumber', 'collectionPlace.number', 'containerType.wasteType', 'collectionRound.name',
			'municipality.name', 'creator'
		]
	},
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	columns: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: defaultColumns
	},
	codeSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	serialNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	rfidSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	rfidSerialSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	quantityFrom: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	quantityTo: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	scanDatetimeFrom: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	scanDatetimeTo: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	containerTypeIDs: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	municipalityWasteTypeIDs: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	collectionRoundIDs: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	weightFrom: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	weightTo: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	state: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	createdAtFrom: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	createdAtTo: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	deviceIDs: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	customerNameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	collectionPlaceNameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	municipalityNameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	collectionPlaceStreetSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	collectionPlaceStreetNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	collectionPlaceNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	}
}
