import React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { Col, Row, Tooltip } from 'antd'

import { ContainersSummary } from '../../../../types/data'

import { Wrapper, ListWrapper } from './ListStatistics'
import { Color, Overlay, TitleText } from './ListStatisticsRow'
import { Text, TextTiny } from '../../../../components/Typography'

type ListRowProps = {
	color: string
	name: string
	used: number
	unused: number
}

type Props = {
	title: string
	tooltip?: {
		title: string
		description: string
	}
	emptyText: string
	data: ContainersSummary | null
}

const ListRow = ({ color, name, unused, used }: ListRowProps) => (
	<Row gutter={8} style={{ height: 40, marginBottom: 15 }} align={'middle'}>
		<Col style={{ height: '100%' }}>
			<Color color={color}/>
		</Col>
		<Col style={{ width: 150 }}>
			<Tooltip
				overlay={<Overlay>{name}</Overlay>}
			>
				<TitleText className={'medium'}>
					{name}
				</TitleText>
			</Tooltip>
		</Col>
		<Col flex={1}>
			<Text className={'text-center'}>{unused}</Text>
		</Col>
		<Col flex={1}>
			<Text className={'text-center'}>{used}</Text>
		</Col>
	</Row>
)

const ContainersSummarySmallTable = ({ data }: Props) => {
	const [t] = useTranslation()

	return (
		<Wrapper>
			<Row gutter={8}>
				<Col>
					<Text className={'bold'}>{t('CustomerPage.containersSummaryTitle')}</Text>
					<TextTiny>{t('CustomerPage.spentByMonths', { count: 12 })}</TextTiny>
				</Col>
			</Row>
			<Row>
				<Col style={{ width: 152 }}/>
				<Col flex={1} className={'text-center'}>
					{t('CustomerPage.unused')}
				</Col>
				<Col flex={1} className={'text-center'}>
					{t('CustomerPage.used')}
				</Col>
			</Row>
			<ListWrapper>
				{map(data?.oneTimeContainersSummary, (item, index) => (
					<ListRow
						key={index}
						color={`#${item.color}`}
						name={item.name}
						used={item.lastTwelveMonths}
						unused={item.activeCount}
					/>
				))}
			</ListWrapper>
		</Wrapper>
	)
}

export default ContainersSummarySmallTable
