import { Col, Row } from 'antd'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImportModal from '../../../components/ImportModal'
import { SubHeading } from '../../../components/Typography'
import { BoxContent } from '../../../layouts/Box'
import { defaultModalState } from '../../../types/data'
import { MODAL } from '../../../utils/enums'
import Button from '../../../components/buttons/Button'

const DataMigrationTool = () => {
	const { t } = useTranslation()
	const [modal, setModal] = useState(defaultModalState)

	const handleCloseModal = useCallback(() => setModal(defaultModalState), [])

	return (
		<>
			<BoxContent className={'top-margin padded'}>
				<SubHeading>{t('ToolsPage.dataMigrationTitle')}</SubHeading>
				<Row gutter={[16, 16]}>
					<Col span={24} md={12}>
						<Button
							onClick={() => setModal({ key: MODAL.IMPORT_MIGRATION_PZO, data: null })}
						>
							{t('ToolsPage.importFile')}
						</Button>
					</Col>
				</Row>
			</BoxContent>
			<ImportModal
				modalKey={modal.key}
				onClose={handleCloseModal}
			/>
		</>
	)
}

export default DataMigrationTool
