import React from 'react'
import ReactQueryParams from 'react-query-params'
import i18next from 'i18next'

import * as PropTypes from 'prop-types'

import { findIndex, get, indexOf, map, reduce, sortBy } from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { change, formValueSelector, initialize, reset, submit } from 'redux-form'

import moment from 'moment-timezone'
import locale from '../../resources/locale'

import { mergeQueryParams, normalizeQueryParams } from '../../utils/queryParams'
import { history } from '../../utils/history'
import { ENDPOINTS, FILTER_POSITION, FILTER } from '../../utils/enums'
import { listPropTypes } from '../../utils/propTypes'

import { statusPush } from '../../actions/statusActions'
import { getContainerTypesSelectOptions } from '../../redux/containerTypes/selectors'
import { loadContainerTypes } from '../../redux/containerTypes/actions'
import { loadQuantitativeCollections } from '../../redux/quantitativeCollections/actions'

import Table, { selectFilterProps } from '../../components/table/Table'
import Breadcrumbs from '../../components/Breadcrumb'
import ExportButton from '../../components/buttons/ExportButton'
import StatisticsRangePicker from '../../atoms/StatisticsRangePicker'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Pagination from '../../atoms/Pagination'

import { queryParams, queryTypes, columns, defaultColumns } from './quantitativeCollectionsConstants'

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

class QuantitativeCollectionsPage extends ReactQueryParams {
	static propTypes = {
		municipality: PropTypes.object,
		actions: PropTypes.shape({
			resetForm: PropTypes.func.isRequired,
			pushStatus: PropTypes.func.isRequired,
			loadCollections: PropTypes.func.isRequired,
			loadContainerTypes: PropTypes.func.isRequired,
			initialize: PropTypes.func.isRequired,
			change: PropTypes.func.isRequired,
			submit: PropTypes.func.isRequired
		}),
		list: listPropTypes('stats', {
			id: PropTypes.string.isRequired
		}),
		filtersValues: PropTypes.shape({
			containerType: PropTypes.shape(selectFilterProps).isRequired
		})
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = reduce(queryParams, (result, item) => ({ [item]: null, ...result }), { columns: defaultColumns })
	}

	componentDidMount() {
		const { actions, filters, municipality } = this.props
		this._mounted = true

		const queryFilters = normalizeQueryParams(queryTypes, mergeQueryParams(filters, this.queryParams))

		// Remove unwanted query
		history.replace(history.location.pathname, { search: null })
		this.setQueryParams(queryFilters)

		actions.initialize(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS, queryFilters)
		const municipalityID = municipality ? municipality.id : undefined

		actions.loadCollections(this.buildFilters(queryFilters))
		actions.loadContainerTypes({ municipalityID, limit: 'ALL' })
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	buildFilters = (filters) => {
		const { municipality } = this.props

		const municipalityID = municipality ? municipality.id : undefined

		return {
			...filters,
			municipalityID,
			state: undefined,
			columns: undefined
		}
	}

	onResetFilters = () => {
		const { filters } = this.props
		const defaultFilters = {
			columns: filters.columns,
			order: filters.order,
			limit: filters.limit,
			page: 1
		}

		const newFilters = reduce(filters, (result, filter, key) => ({
			...result,
			[key]: defaultFilters[key] || null
		}), {})

		this.onFilter(newFilters)
	}

	getSelectedColumns = () => {
		const { filters, filtersValues } = this.props

		const sortedColumns = sortBy(filters.columns, (item) => findIndex(columns, { name: item }))

		return reduce(columns, (result, item) => {
			const filterIndex = indexOf(sortedColumns, item.name)

			if (filterIndex >= 0) {
				if (filtersValues[item.name]) {
					item.filter = {
						...item.filter,
						...filtersValues[item.name],
						position: FILTER_POSITION.RIGHT
					}
				}

				if (filterIndex >= sortedColumns.length - 2) {
					item.filter.position = FILTER_POSITION.LEFT
				}

				return [
					...result,
					item
				]
			}
			return result
		}, [])
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.statistics.breadcrumbs',
			name: locale['page.statistics.breadcrumbs']
		}, {
			key: 'page.quantitativeCollections.breadcrumbs',
			name: locale['page.quantitativeCollections.breadcrumbs']
		}]
	}

	onFilter = (values) => {
		const { actions } = this.props
		const query = normalizeQueryParams(queryTypes, values)
		this.setQueryParams(query)
		initialize(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS, query)
		actions.loadCollections(this.buildFilters(query))
	}

	onChangeFilter = (filter) => (value) => {
		const { actions, filters } = this.props
		actions.change(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS, filter, value)
		this.onFilter({ ...filters, [filter]: value })
	}

	onChangeColumns = (columns) => {
		const { filters } = this.props
		const query = normalizeQueryParams(queryTypes, { ...filters, columns })
		this.setQueryParams(query)
	}

	onChangeRange = (range) => {
		const { actions, filters } = this.props
		const dateFrom = range.from ? range.from.toISOString() : null
		const dateTo = range.to ? range.to.toISOString() : null
		actions.change(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS, queryParams.dateFrom, dateFrom)
		actions.change(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS, queryParams.dateTo, dateTo)
		this.onFilter({ ...filters, [queryParams.dateFrom]: dateFrom, [queryParams.dateTo]: dateTo })
	}

	openCustomerDetail = (customer) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.customers.detail.municipalityPath', { municipalityID: municipality.id, customerID: customer.customerID })
			: i18next.t('paths:LISTS.customers.detail.path', { customerID: customer.customerID }))
	}

	render() {
		const { list, filters } = this.props

		const selectedColumns = this.getSelectedColumns()

		const range = {
			from: filters.dateFrom ? moment(filters.dateFrom) : null,
			to: filters.dateTo ? moment(filters.dateTo) : null
		}

		return (
			<>
				<Breadcrumbs items={this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.collections.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<div className={'row justify-content-between'}>
								<div className={'flex-box'}>
									<ExportButton url={ENDPOINTS.STATISTICS_QUANTITATIVE_COLLECTIONS_EXPORT} context={this.buildFilters(filters)}/>
								</div>
								<div>
									<StatisticsRangePicker
										onChange={this.onChangeRange}
										range={range}
									/>
								</div>
							</div>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<Table
										name={FILTER.QUANTITATIVE_COLLECTIONS_FILTERS}
										columns={selectedColumns}
										tableColumns={tableColumns}
										defaultColumns={defaultColumns}
										filters={filters}
										onDetail={this.openCustomerDetail}
										onResetFilters={this.onResetFilters}
										isSelectable={false}
										isLoading={list.isLoading}
										isFailure={list.isFailure}
										data={get(list, 'data.stats')}
										onSort={this.onChangeFilter(queryParams.order)}
										onFilter={this.onFilter}
										onChangeColumns={this.onChangeColumns}
									/>
									<ListItemsLimit
										limit={filters.limit}
										onLimit={this.onChangeFilter(queryParams.limit)}
									/>
									<Pagination
										pages={get(list, 'data.context.pages')}
										page={get(list, 'data.context.page')}
										onPage={this.onChangeFilter(queryParams.page)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const filtersSelector = formValueSelector(FILTER.QUANTITATIVE_COLLECTIONS_FILTERS)

const mapStateToProps = (state) => ({
	list: state.quantitativeCollections.list,
	filtersValues: {
		containerType: getContainerTypesSelectOptions(state)
	},
	filters: filtersSelector(state, ...Object.values(queryParams)),
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	actions: {
		loadCollections: bindActionCreators(loadQuantitativeCollections, dispatch),
		loadContainerTypes: bindActionCreators(loadContainerTypes, dispatch),
		initialize: bindActionCreators(initialize, dispatch),
		resetForm: bindActionCreators(reset, dispatch),
		change: bindActionCreators(change, dispatch),
		submit: bindActionCreators(submit, dispatch),
		pushStatus: bindActionCreators(statusPush, dispatch)
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(QuantitativeCollectionsPage)
