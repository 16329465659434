import styled from 'styled-components'
import { Dayjs } from 'dayjs'

import generatePicker from 'antd/lib/date-picker/generatePicker'
// eslint-disable-next-line import/no-extraneous-dependencies
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

import { Select as AntSelect, Checkbox as AntCheckbox, Upload, InputNumber } from 'antd'

import UploadIcon from '../../resources/images/ic-upload.svg'
import SearchIcon from '../../resources/images/ic-search.svg'

const { Dragger } = Upload

const AntDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as any)

export const Label = styled.label`
	display: block;
	font-size: 14px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0.2px;
	color: ${({ theme }) => theme.colors.neutral['800']};
	margin-bottom: 6px;

	&.inline {
		display: inline-block;
	}
`

export const Info = styled.div`
	visibility: hidden;
	font-size: 9px;
	padding: 2px;

	&.info {
		visibility: visible;
		color: ${({ theme }) => theme.colors.warn};
	}

	&.error {
		visibility: visible;
		color: ${({ theme }) => theme.colors.error};
	}
`

export const AntInputNumber = styled(InputNumber)`
	.ant-input-number-handler-wrap {
		display: none;
	}

	input {
		width: 100%;
		border: 1px solid ${({ theme }) => theme.colors.border};
		border-radius: 4px;
		padding: 5px 8px;
		font-size: 14px;

		&:focus {
			border-style: solid;
			border-width: 1px;
			box-shadow: ${({ theme }) => theme.focusShadow};
			border-color: ${({ theme }) => theme.colors.primary['500']};
		}
	}

	&.error {
		input {
			border-color: ${({ theme }) => theme.colors.error};
		}
	}
`

export const Input = styled.input`
	width: 100%;
	min-height: 32px;
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 4px;
	color: black;
	outline: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	box-shadow: none;
	-webkit-appearance: none;
	background: white;
	border: 1px solid ${({ theme }) => theme.colors.border};

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholder};
	}

	&:focus {
		box-shadow: ${({ theme }) => theme.focusShadow};
		border-color: ${({ theme }) => theme.colors.primary['500']};
	}

	&.error {
		border-color: ${({ theme }) => theme.colors.error};
		box-shadow: ${({ theme }) => theme.errorShadow};

		&::placeholder {
			color: ${({ theme }) => theme.colors.inputPlaceholder};
		}
	}

	&.small {
		padding: 12px 10px;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
`

export const TextArea = styled.textarea`
	width: 100%;
	min-height: 32px;
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 4px;
	color: black;
	outline: none;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 1ms;
	box-shadow: none;
	-webkit-appearance: none;
	background: white;
	border: 1px solid ${({ theme }) => theme.colors.border};
	resize: vertical;

	&::placeholder {
		color: ${({ theme }) => theme.colors.placeholder};
	}

	&:focus {
		box-shadow: ${({ theme }) => theme.focusShadow};
		border-color: ${({ theme }) => theme.colors.primary['500']};
	}

	&.error {
		border-color: ${({ theme }) => theme.colors.error};
		box-shadow: ${({ theme }) => theme.errorShadow};

		&::placeholder {
			color: ${({ theme }) => theme.colors.inputPlaceholder};
		}
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}
`

export const DatePicker = styled(AntDatePicker)`
	&.ant-picker {
		height: 32px;
		padding: 4px 10px;
		border-radius: 4px;
		border: 1px solid ${({ theme }) => theme.colors.border};
		display: block;

		&.error {
			border-color: ${({ theme }) => theme.colors.error};
			box-shadow: ${({ theme }) => theme.errorShadow};

			& > .ant-picker-input > input::placeholder {
				color: ${({ theme }) => theme.colors.error};
			}
		}

		& > .ant-picker-input > input {
			font-size: 14px;

			&::placeholder {
				color: ${({ theme }) => theme.colors.inputPlaceholder}
			}
		}

		&.ant-picker-focused {
			& .ant-picker-clear {
				background-color: white;
			}

			background-color: white;
			border-color: ${({ theme }) => theme.colors.primary['500']};
			box-shadow: ${({ theme }) => theme.focusShadow}
		}
	}
`

export const Checkbox = styled(AntCheckbox)``

export const Select = styled(AntSelect)`
	display: block;

	.ant-select-arrow {
		color: ${({ theme }) => theme.colors.border};
	}

	&.ant-select-multiple .ant-select-selection-search {
		margin-inline-start: 2px;
	}

	&.ant-select {

		.ant-select-selector {
			min-height: 32px;
			border-radius: 4px;
			border-color: ${({ theme }) => theme.colors.border};
			font-size: 14px;
			background-color: white;
			padding: 0 8px;

			.ant-select-selection-search + .ant-select-selection-placeholder {
				margin-left: 0;
			}

			.ant-select-selection-item {
				display: flex;
				align-items: center;
				padding-left: 0;

				.ant-select-selection-item-remove span {
					margin-bottom: 9px;
				}
			}

			.ant-select-selection-search-input {
				height: 100%;
			}

			.ant-select-selection-placeholder {
				align-self: center;
				margin-left: 0;
				color: ${({ theme }) => theme.colors.inputPlaceholder};

				&:after {
					content: none;
				}
			}
		}

		&.error .ant-select-selector {
			border-color: ${({ theme }) => theme.colors.error};
			box-shadow: ${({ theme }) => theme.errorShadow};

			.ant-select-selection-placeholder {
				color: ${({ theme }) => theme.colors.error};
			}
		}
	}

	&.ant-select.ant-select-focused {
		.ant-select-selector {
			background: white;
			border-color: ${({ theme }) => theme.colors.primary['500']};
			box-shadow: ${({ theme }) => theme.focusShadow} !important;
		}
	}
`

export const InputWrapper = styled.div`
	margin-bottom: 8px;
	position: relative;

	&.inline {
		align-items: center;
		display: flex;

		> * + * {
			margin-left: 16px;
		}
	}

	&.search {
		&:before {
			left: 8px;
			top: 8px;
			position: absolute;
			content: '';
			width: 16px;
			height: 16px;
			background: url("${SearchIcon}") center no-repeat;
			background-size: 16px;
		}

		& > ${Input} {
			padding-left: 30px;
		}
	}
`

export const UploadDragger = styled(Dragger)`
	&.ant-upload.ant-upload-drag {
		padding: 0;
		cursor: pointer;
		border: 1px dashed ${({ theme }) => theme.colors.border};

		& .ant-upload-btn {
			padding: 0;
		}

		& > .ant-upload.ant-upload-btn {
			display: block;
			height: 100%;

			& > .ant-upload-drag-container {
				height: 100%;
			}
		}

		height: 54px;

		&.image {
			width: 210px;
			height: 210px;
		}
	}
`

export const UploadPlaceholder = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 24px;

	&.image {
		background: #f5f5f5 url(${UploadIcon}) no-repeat center 50px;
		padding: 80px 32px 0 32px;
	}
`

export const UploadContent = styled.div`
	height: 100%;
	text-align: left;
	line-height: 20px;
    width: 100%;
    font-size: 13px;
    color: #000;
    opacity: .8;
    transition: all .1s linear;
	display: flex;
	align-items: center;

	& > img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`
