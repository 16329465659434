import React from 'react'
import PropTypes from 'prop-types'
import { isFinite, join } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import ReactTooltip from 'react-tooltip'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'
import { COLOR_PALETTE } from '../../utils/enums'

class WasteTypeTableRow extends React.Component {
	static propTypes = {
		wasteType: PropTypes.object.isRequired,
		onRemove: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		countIndex: PropTypes.number
	}

	constructor(props) {
		super(props)
		this.state = { sort: props.defaultSort || null }
	}

	render() {
		const { wasteType, onRemove, onDetail, countIndex } = this.props

		return (
			<tr key={wasteType.id} onClick={() => onDetail(wasteType)}>
				<td>{wasteType.name}</td>
				<td>{wasteType.wasteType.code}</td>
				<td>
					<div className={'dot'} style={{ background: `#${wasteType.color}` }}/>
					<span>{locale[`colors.${COLOR_PALETTE[wasteType.color]}`]}</span>
				</td>
				<td>{join([wasteType.coefficient, 'kg/l'], ' ')}</td>
				<td>{isFinite(wasteType.collectionYardWeightLimit) ? `${wasteType.collectionYardWeightLimit} kg` : '-'}</td>
				<td className={'text-center'} style={{ color: wasteType.isActiveMotivationModel ? '#AFCA0B' : '#F03E41' }}>
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${wasteType.id}`} icon={wasteType.isActiveMotivationModel ? faCheckCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${wasteType.id}`} place={'left'} effect={'solid'}>
						<span>
							{wasteType.isActiveMotivationModel ? locale['page.waste.types.active.motivation'] : locale['page.waste.types.inactive.motivation']}
						</span>
					</ReactTooltip>
				</td>
				<td className={'action'} style={{ zIndex: `${countIndex}` }}>
					<PopupTool
						items={[{
							key: 'common.delete',
							title: locale['common.delete'],
							icon: faPowerOff,
							callback: () => onRemove(wasteType)
						}]}
					/>
				</td>
			</tr>
		)
	}
}

export default WasteTypeTableRow
