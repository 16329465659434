import React from 'react'
import cx from 'classnames'
import moment from 'moment-timezone'

import * as PropTypes from 'prop-types'

import { map, uniq, reduce } from 'lodash'

import locale from '../../resources/locale'
import { EVENT_TYPE } from '../../utils/enums'

class CalendarDayView extends React.Component {
	static propTypes = {
		day: PropTypes.object.isRequired,
		isToday: PropTypes.bool.isRequired,
		isSelected: PropTypes.bool.isRequired,
		isAnotherMonth: PropTypes.bool.isRequired,
		events: PropTypes.arrayOf(PropTypes.shape({
			collectionRounds: PropTypes.arrayOf(PropTypes.shape({
				wasteType: PropTypes.shape({
					color: PropTypes.string.isRequired
				})
			}))
		})).isRequired,
		onSelect: PropTypes.func.isRequired,
		onCreate: PropTypes.func.isRequired,
		defaultLabels: PropTypes.bool
	}

	getLabels = () => {
		const { events, defaultLabels } = this.props

		const colors = uniq(reduce(events, (result, e) => [
			...result,
			...map(e.collectionRounds, (cr) => cr.wasteType.color),
			...(e.type === EVENT_TYPE.OTHER ? [EVENT_TYPE.OTHER] : [])
		], []))

		return map(colors, (item, index) => (
			<div
				key={index}
				className={'calendar-label'}
				style={
					// eslint-disable-next-line no-nested-ternary
					defaultLabels
						? { backgroundColor: '#1E87F0' }
						: item !== EVENT_TYPE.OTHER ? { backgroundColor: `#${item}` } : { border: '2px solid #555555', backgroundColor: 'transparent' }
				}
			/>
		))
	}

	handleDoubleClick = () => {
		const { day, onCreate } = this.props
		const date = moment()
		date.year(day.year)
		date.month(day.month)
		date.date(day.day)
		onCreate(date)
	}

	render() {
		const { onSelect, isSelected, isToday, isAnotherMonth, day } = this.props
		return (
			<td
				className={cx('calendar-day', { today: isToday, selected: isSelected, another: isAnotherMonth })}
				onClick={() => onSelect(day)}
				onDoubleClick={this.handleDoubleClick}
			>
				<div className={'calendar-date'}>
					{day.day}
					{isToday && <div className={'day-label'}>{locale['common.today']}</div>}
				</div>
				<div className={'calendar-labels'}>
					{this.getLabels()}
				</div>
			</td>
		)
	}
}

export default CalendarDayView
