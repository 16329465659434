import { COLLECTION_PLACES_ACTIONS } from './constants'
import { collectionPlaces } from '../../api'

export const collectionPlacesSelectLoadStart = {
	type: COLLECTION_PLACES_ACTIONS.SELECT_LOAD_START
}

export const collectionPlacesSelectLoadFail = {
	type: COLLECTION_PLACES_ACTIONS.SELECT_LOAD_FAIL
}

export const collectionPlacesSelectLoadDone = (data) => ({
	type: COLLECTION_PLACES_ACTIONS.SELECT_LOAD_DONE,
	payload: data
})

export const loadCollectionPlacesSelect = (filters) => async (dispatch) => {
	dispatch(collectionPlacesSelectLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const { data } = await collectionPlaces.loadCollectionPlaces(query)

		dispatch(collectionPlacesSelectLoadDone(data))

		return data.collectionPlaces
	} catch (e) {
		dispatch(collectionPlacesSelectLoadFail)

		return []
	}
}

export const collectionPlacesListLoadStart = {
	type: COLLECTION_PLACES_ACTIONS.LIST_LOAD_START
}

export const collectionPlacesListLoadFail = {
	type: COLLECTION_PLACES_ACTIONS.LIST_LOAD_FAIL
}

export const collectionPlacesListLoadDone = (data) => ({
	type: COLLECTION_PLACES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadCollectionPlacesList = (filters) => async (dispatch) => {
	dispatch(collectionPlacesListLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const { data } = await collectionPlaces.loadCollectionPlaces(query)

		dispatch(collectionPlacesListLoadDone(data))
	} catch (e) {
		dispatch(collectionPlacesListLoadFail)
	}
}
