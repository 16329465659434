import React from 'react'
import i18next from 'i18next'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'

import locale from '../../../resources/locale'

import InfoFooter from '../../../components/InfoFooter'

class ContainerTypeUniversalForm extends React.Component {
	static propTypes = {
		cancelHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired
	}

	render() {
		const { cancelHandler, initialValues } = this.props

		return (
			<div className={'box'}>
				<div className={'box-content with-padding'}>
					<div className={'box-head'}/>
					<div className={'box-body'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Názov')}</label>
									<div>{initialValues.name}</div>
								</div>
							</div>
							<div className={'col-md-6'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Typ')}</label>
									<div>{locale[`container.type.${get(initialValues, 'type')}`]}</div>
								</div>
							</div>
						</div>
						<InfoFooter
							createdAt={get(initialValues, 'createdAt')}
							creator={get(initialValues, 'creator')}
							updatedAt={get(initialValues, 'updatedAt')}
							editor={get(initialValues, 'editor')}
						/>
					</div>
				</div>
				<div className={'box-footer clearfix'}>
					<button
						type="button"
						onClick={cancelHandler}
						className={'button outline pull-right'}
					>
						{locale['common.back']}
					</button>
				</div>
			</div>
		)
	}
}

export default ContainerTypeUniversalForm
