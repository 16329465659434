import { reduce, forEach } from 'lodash'
import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { COLLECTIONS_ACTIONS } from './constants'

export const collectionsLoadStart = {
	type: COLLECTIONS_ACTIONS.LIST_LOAD_START
}

export const collectionsLoadFail = {
	type: COLLECTIONS_ACTIONS.LIST_LOAD_FAIL
}

export const collectionsLoadDone = (data) => ({
	type: COLLECTIONS_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadCollections = (filters) => async (dispatch) => {
	dispatch(collectionsLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const normalizedQuery = reduce(query, (result, item, key) => {
			if (item) {
				return { ...result, [key]: item }
			}
			return result
		}, {})

		const response = await getReq(ENDPOINTS.COLLECTIONS, normalizedQuery)

		dispatch(collectionsLoadDone(response.data))
	} catch (e) {
		dispatch(collectionsLoadFail)
	}
}

export const deselectAllCollections = () => ({ type: COLLECTIONS_ACTIONS.SET_SELECTED, payload: {} })

export const toggleSelectCollections = (collections) => (dispatch, getState) => {
	const state = getState()

	const selectedCollections = { ...state.collections.selectedCollections }

	forEach(collections, (item) => {
		const key = `${item.id}`
		if (selectedCollections[key]) {
			delete selectedCollections[key]
		} else {
			selectedCollections[key] = item
		}
	})

	dispatch({ type: COLLECTIONS_ACTIONS.SET_SELECTED, payload: selectedCollections })
}
