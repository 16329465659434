import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { debounce, map } from 'lodash'
import i18next from 'i18next'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'
import { validate, validateSelect, validatorOptions } from '../../utils/validator'

import { statusPush } from '../../actions/statusActions'

import { colors, wasteTypes } from '../../utils/select'
import { ENDPOINTS, FORMS } from '../../utils/enums'
import { getReq } from '../../utils/request'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import SwitchField from '../../atoms/SwichField'
import NumericInputField from '../../atoms/NumericInputField'
import { parseNumber } from '../../utils/utils'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateCoefficient = validate([validatorOptions.REQUIRED, validatorOptions.NUMBER])
const validateNumber = validate([validatorOptions.NUMBER])
const validateType = validate([validatorOptions.REQUIRED])
const validateColor = validate([validatorOptions.REQUIRED])
const validateCode = validateSelect([validatorOptions.REQUIRED])

class WasteTypeForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		deleteHandler: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		pushStatus: PropTypes.func.isRequired,
		action: PropTypes.shape({
			onChange: PropTypes.func.isRequired,
			checked: PropTypes.bool.isRequired
		})
	}

	searchWasteTypes = (search, callback) => {
		getReq(ENDPOINTS.WASTE_TYPES_OPTIONS, { search, order: 'name', sort: 'ASC' }).then((response) => {
			const items = map(response.data, (item) => ({
				label: `${item.code} - ${item.name}`,
				value: item.id,
				key: item.id
			}))
			return callback(items)
		}).catch(() => callback([]))
	}

	handleSubmit = (values) => {
		const { submitHandler } = this.props
		submitHandler({
			name: values.name,
			type: values.type,
			color: values.color,
			coefficient: values.coefficient,
			categoryID: values.code.value,
			collectionYardWeightLimit: values.collectionYardWeightLimit
		})
	}

	codesSelectFieldOptions = {
		label: i18next.t('loc:Katalógové číslo'),
		required: true,
		isMulti: false,
		showLabel: true,
		loadOptions: debounce(this.searchWasteTypes, 300)
	}

	colorsSelectFieldOptions = {
		label: i18next.t('loc:Farba odpadu'),
		required: true,
		showLabel: true,
		options: colors
	}

	typesSelectFieldOptions = {
		label: i18next.t('loc:Spôsob zberu'),
		required: true,
		showLabel: true,
		options: wasteTypes
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov odpadu'),
		required: true
	}

	coefficientFieldOptions = {
		label: i18next.t('loc:Váhový koeficient (kg/l)'),
		required: true
	}

	collectionYardWeightLimitFieldOptions = {
		label: i18next.t('loc:Limit odpadu na zbernom dvore(kg)'),
		required: false
	}

	deleteWasteType = (e) => {
		const { deleteHandler } = this.props
		e.preventDefault()
		deleteHandler()
	}

	render() {
		const { handleSubmit, cancelHandler, action } = this.props

		return (
			<form onSubmit={handleSubmit((data) => this.handleSubmit(data))} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<div className={'input-switch'}>
										<SwitchField action={action} className={'pull-right'}/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='name'
										placeholder={locale['field.placeholder.waste.type.name']}
										component={TextInputField}
										props={this.nameFieldOptions}
										validate={validateName}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='code'
										placeholder={locale['field.placeholder.waste.type.code']}
										component={AsyncSelectField}
										props={this.codesSelectFieldOptions}
										validate={validateCode}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='color'
										placeholder={locale['field.placeholder.waste.type.color']}
										component={SelectField}
										props={this.colorsSelectFieldOptions}
										validate={validateColor}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='type'
										placeholder={locale['field.placeholder.waste.type.type']}
										component={SelectField}
										props={this.typesSelectFieldOptions}
										validate={validateType}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='coefficient'
										placeholder={locale['field.placeholder.waste.type.coefficient']}
										component={NumericInputField}
										normalize={parseNumber}
										props={this.coefficientFieldOptions}
										validate={validateCoefficient}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='collectionYardWeightLimit'
										placeholder={'Zadajte limit'}
										component={NumericInputField}
										normalize={parseNumber}
										props={this.collectionYardWeightLimitFieldOptions}
										validate={validateNumber}
									/>
								</div>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button className={'danger'} onClick={this.deleteWasteType}>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={cancelHandler}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch)
})

export default reduxForm({
	form: FORMS.WASTE_TYPE_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(WasteTypeForm))
