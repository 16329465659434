import React from 'react'
import styled from 'styled-components'
import { startsWith } from 'lodash'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Menu } from 'antd'

import { RouteComponentProps } from 'react-router'

import { getAuthUser } from '../../../redux/authentication'

import Administration from './Administration'
import Lists from './Lists'
import Registry from './Registry'
import Statistics from './Statistics'
import Home from './Home'
import Municipality from './Municipality'

const StyledMenu = styled(Menu)`
	height: 100%;
	border-right: 0;
	background-color: ${({ theme }) => theme.colors.darkBackground};
	overflow: auto;
	user-select: none;
`

type Props = RouteComponentProps<{ municipalityID?: string }>

const AppMenu = ({ history }: Props) => {
	const [t] = useTranslation()
	const authUser = useSelector(getAuthUser)
	const municipality = useSelector((state: any) => state.selectedMunicipality.municipality.data)
	const selectedMenu = useSelector((state: any) => state.menu.key)

	const handleMenuSelect = (key: string | number) => {
		const stringKey = `${key}`

		if (municipality && !startsWith(stringKey, 'ADMINISTRATION')) {
			history.push(t(`paths:${key}.municipalityPath`, { municipalityID: municipality.id }))
		} else {
			history.push(t(`paths:${key}.path`, { municipalityID: municipality?.id }))
		}
	}

	return (
		<StyledMenu
			onClick={({ key }: { key: string | number }) => handleMenuSelect(key)}
			selectedKeys={selectedMenu ? [selectedMenu] : []}
		>
			<Home authUser={authUser.data} municipality={municipality}/>
			<Statistics authUser={authUser.data} municipality={municipality}/>
			<Registry authUser={authUser.data} municipality={municipality}/>
			<Lists authUser={authUser.data} municipality={municipality}/>
			{!!municipality && <Municipality authUser={authUser.data} municipality={municipality}/>}
			{!!authUser.data?.isAdmin && <Administration authUser={authUser.data}/>}
		</StyledMenu>
	)
}

export default AppMenu
