import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import locale from '../resources/locale'
import { Info, InputWrapper, Label, Select } from '../components/form/Styled'

const SelectField = ({ input, name, label, showLabel, options, isMulti, isLoading, meta, className, onChange, ...rest }) => {
	const valid = !!meta.valid && !!input.value
	const error = meta.touched && meta.error
	const value = input.value ? input.value : undefined

	const handleBlur = () => {
		input?.onBlur()
	}

	const handleChange = (value) => {
		input?.onChange(value)
		if (onChange) {
			onChange(value)
		}
	}

	const inputClassName = cx(className || '', {
		error,
		valid
	})

	return (
		<InputWrapper className={cx({ error: meta.touched && meta.error })}>
			{showLabel && <Label htmlFor={name}>{label}</Label>}
			<Select
				{...input}
				{...rest}
				value={value}
				showSearch={false}
				options={options}
				loading={isLoading}
				onChange={handleChange}
				onBlur={handleBlur}
				mode={isMulti ? 'multiple' : undefined}
			/>
			<Info className={inputClassName}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

SelectField.propTypes = {
	input: PropTypes.object,
	inputValue: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
	isMulti: PropTypes.bool,
	meta: PropTypes.object,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	onInputChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isSearchable: PropTypes.bool,
	value: PropTypes.object,
	handleOnChange: PropTypes.func,
	components: PropTypes.object
}

SelectField.defaultProps = {
	showLabel: false,
	placeholder: locale['field.placeholder.search'],
	label: '',
	isSearchable: false
}

export default SelectField
