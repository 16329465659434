import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import cx from 'classnames'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, forEach, get, isEmpty, map, reduce, split, trim, values } from 'lodash'
import { Link } from 'react-router-dom'
import { change, getFormValues, initialize, SubmissionError } from 'redux-form'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { mergeQueryParams, normalizeQueryParams } from '../../utils/queryParams'
import { columns, defaultColumns, queryParams, queryTypes } from './constants'

import { collectionPlaces } from '../../api'

import { ENDPOINTS, FILTER, INFO, MODAL } from '../../utils/enums'
import { history } from '../../utils/history'
import { getSelectedColumns } from '../../utils/filter'

import { getCollectionPlacesList } from '../../redux/collectionPlaces/selectors'
import { loadCollectionPlacesList } from '../../redux/collectionPlaces/actions'
import { getSelectedMunicipality } from '../../redux/municipalities/selectors'

import { statusPush } from '../../actions/statusActions'

import DialogModal from '../../components/modals/DialogModal'
import Table from '../../components/table/Table'
import Breadcrumbs from '../../components/Breadcrumb'

import ExportButton from '../../components/buttons/ExportButton'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Pagination from '../../atoms/Pagination'

import locale from '../../resources/locale'

import SearchInputField from '../../atoms/SearchInputField'
import NestParentModal from './components/NestParentModal'
import Button from '../../components/buttons/Button'

const filtersSelector = getFormValues(FILTER.COLLECTION_PLACES_FILTERS)

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

const updateQueryParams = (query) => {
	const normalizedQueryParams = reduce(query, (result, value, key) => value ? {
		...result,
		[key]: value
	} : result, {})

	history.replace({
		pathname: history.location.pathname,
		search: `?${qs.stringify(normalizedQueryParams, { encode: false })}`
	})
}

const CollectionPlacesPage = ({ match }) => {
	const [t] = useTranslation()

	const [modal, setModal] = useState({ data: null, key: null })

	const dispatch = useDispatch()
	const municipality = useSelector(getSelectedMunicipality)
	const filters = useSelector(filtersSelector) || {}
	const list = useSelector(getCollectionPlacesList)
	const [selected, setSelected] = useState({})

	const breadcrumbsItems = useMemo(() => {
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.lists.breadcrumbs',
			name: locale['page.lists.breadcrumbs']
		}, {
			key: 'page.collectionPlaces.breadcrumbs',
			name: locale['page.collectionPlaces.breadcrumbs']
		}]
	}, [municipality])

	// region CALLBACKS

	const debouncedFilter = useMemo(() => debounce((filters) => {
		const query = normalizeQueryParams(queryTypes, filters)
		const municipalityID = municipality ? municipality.id : undefined
		const reqQuery = {
			...query,
			municipalityID
		}

		updateQueryParams(query)
		dispatch(loadCollectionPlacesList(reqQuery))
	}, 200), [dispatch, municipality])

	const handleFilter = useCallback((values) => {
		const query = normalizeQueryParams(queryTypes, { ...values, page: 1 })
		updateQueryParams(query)

		dispatch(initialize(FILTER.COLLECTION_PLACES_FILTERS, query))
	}, [dispatch])

	const handleResetFilters = useCallback(() => {
		const defaultFilters = {
			columns: filters.columns,
			order: filters.order,
			limit: filters.limit,
			page: 1
		}

		const newFilters = reduce(filters, (result, filter, key) => ({
			...result,
			[key]: defaultFilters[key] || null
		}), {})

		handleFilter(newFilters)
	}, [filters, handleFilter])

	const handleChangePage = useCallback((value) => dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, queryParams.page, value)), [dispatch])
	const handleChangeLimit = useCallback((value) => dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, queryParams.limit, value)), [dispatch])
	const handleChangeColumns = useCallback((value) => dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, queryParams.columns, value)), [dispatch])
	const handleChangeOrder = useCallback((value) => {
		const [order, direction] = split(value, ':')
		dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, queryParams.order, order))
		dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, queryParams.direction, direction))
	}, [dispatch])

	const handleGetActions = useCallback((data) => [{
		key: 'common.delete',
		title: locale['common.delete'],
		icon: faTrashAlt,
		callback: () => setModal({ key: MODAL.REMOVE_COLLECTION_PLACE, data })
	}], [])

	const handleSelect = useCallback((items) => {
		const newItems = { ...selected }

		forEach(items, (item) => {
			if (newItems[`${item.id}`]) {
				delete newItems[`${item.id}`]
			} else {
				newItems[`${item.id}`] = item
			}
		})

		setSelected(newItems)
	}, [selected])

	const handleCloseModal = (e) => {
		if (e) {
			e.preventDefault()
		}
		setModal({ key: null, data: null })
	}

	const handleRemoveCollectionPlace = async () => {
		if (modal.data) {
			try {
				await collectionPlaces.removeCollectionPlace(modal.data.id, modal.data.municipality.id)

				dispatch(statusPush({
					type: INFO,
					msg: locale['page.collectionPlaces.delete.success']
				}))

				debouncedFilter(filters)

				handleCloseModal()

				return true
			} catch (error) {
				return Promise.reject(new SubmissionError({}))
			}
		}

		return undefined
	}

	const handleCloseNestParentModal = useCallback(() => {
		setModal({ key: null, data: null })
		setSelected({})
	})

	const handleCollectionPlaceDetail = (item) => {
		const { municipalityID } = match.params
		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.collections.places.detail'], municipalityID, item.id))
		} else {
			history.push(locale.formatString(locale['path.lists.collections.places.detail'], item.id))
		}
	}

	// endregion

	// region EFFECTS

	useEffect(() => {
		const queryParams = qs.parse(history.location.search.substring(1))
		const queryFilters = normalizeQueryParams(queryTypes, mergeQueryParams(filters, queryParams))
		updateQueryParams(queryFilters)

		dispatch(initialize(FILTER.COLLECTION_PLACES_FILTERS, queryFilters))
	}, [])

	// Filter customer codes debounce
	useEffect(() => {
		debouncedFilter({ ...filters, page: 1 })
	}, [debouncedFilter, filters.limit, filters.nameSearch, filters.addressSearch, filters.types, filters.containersCountFrom,
		filters.containersCountTo, 	filters.numberSearch, filters.streetNumberSearch, filters.parentSearch,
		filters.flatNumberSearch, filters.streetSearch,
		filters.municipalitySearch, filters.customerSearch, filters.search])

	useEffect(() => {
		debouncedFilter(filters)
	}, [debouncedFilter, filters.page, filters.order, filters.direction])

	// Updated query with changed columns
	useEffect(() => {
		const query = normalizeQueryParams(queryTypes, filters)
		updateQueryParams(query)
	}, [filters, filters.columns])

	// endregion

	return (
		<>
			<Breadcrumbs
				items={breadcrumbsItems}
			/>
			<div className={'box'}>
				<div className={'box-title'}>
					{locale['page.collectionPlaces.title']}
				</div>
				<div className={'box-content'}>
					<div className={'box-head'}>
						<Row gutter={[16, 16]} justify={'space-between'}>
							<Col>
								<SearchInputField
									name={'search'}
									onChange={(search) => dispatch(change(FILTER.COLLECTION_PLACES_FILTERS, 'search', trim(search)))}
									defaultValue={filters.search || ''}
								/>
							</Col>
							<Col flex={1}>
								<ExportButton url={ENDPOINTS.COLLECTION_PLACES_EXPORT} context={{ ...filters, municipalityID: municipality?.id }}/>
							</Col>
							<Col>
								<Link
									to={municipality ? locale.formatString(locale['path.municipality.collections.places.create'], municipality.id) : '#'}
									disabled={!municipality}
									className={cx({ disabled: !municipality })}
								>
									<Button disabled={!municipality}>
										<PlusOutlined/>
										<span>{locale['page.collectionPlaces.add']}</span>
									</Button>
								</Link>
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<Table
							onResetFilters={handleResetFilters}
							name={FILTER.COLLECTION_PLACES_FILTERS}
							getActions={handleGetActions}
							columns={getSelectedColumns(filters, {}, columns)}
							tableColumns={tableColumns}
							defaultColumns={defaultColumns}
							filters={filters || {}}
							isLoading={list.isLoading}
							isFailure={list.isFailure}
							onDetail={handleCollectionPlaceDetail}
							isSelectable={!!municipality}
							selectedItems={selected}
							onSelect={handleSelect}
							data={get(list, 'data.collectionPlaces')}
							onSort={handleChangeOrder}
							onChangeColumns={handleChangeColumns}
							onFilter={handleFilter}
						/>
						<ListItemsLimit
							limit={get(filters, 'limit')}
							onLimit={handleChangeLimit}
						/>
						{!isEmpty(selected) &&
						<Row style={{ marginTop: 16, marginLeft: 48 }}>
							<Col>
								<Button
									onClick={() => setModal({ key: MODAL.NEST_PARENT, data: null })}
								>
									{t('CollectionPlacesPage.assignNest')}
								</Button>
							</Col>
						</Row>}
						<Pagination
							pages={get(list, 'data.context.pages')}
							page={get(list, 'data.context.page')}
							onPage={handleChangePage}
						/>
					</div>
				</div>
			</div>
			<DialogModal
				shown={modal.key === MODAL.REMOVE_COLLECTION_PLACE}
				cancelHandler={handleCloseModal}
				acceptHandler={handleRemoveCollectionPlace}
				message={locale['page.collectionPlaces.delete.message']}
				title={modal.data ? locale.formatString(locale['page.collectionPlaces.delete.title'], get(modal, 'data.name')) : ''}
				acceptTitle={locale['page.collectionPlaces.delete.accept']}
			/>
			<NestParentModal
				selected={values(selected)}
				municipality={municipality}
				visible={modal.key === MODAL.NEST_PARENT}
				onClose={handleCloseNestParentModal}
			/>
		</>
	)
}

CollectionPlacesPage.propTypes = {
	match: PropTypes.object
}

export default CollectionPlacesPage
