import React, { useCallback, useEffect, useMemo, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { createForm } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'antd'
import { get, map, replace } from 'lodash'

import { history } from '../../utils/history'

import { Box, BoxContent, BoxFooter, BoxHeader, BoxTitle } from '../../layouts/Box'
import { useDataLoader } from '../../hooks/useDataLoader'

import { formatContainerName, formatUserName } from '../../utils/formatters'
import { formatCustomer, getMessages } from '../../utils/helpers'
import { DATE_TIME_FORMAT } from '../../utils/enums'

import { getSelectedMunicipality } from '../../redux/municipalities/selectors'
import { statusPush } from '../../actions/statusActions'

import { bulkCollections } from '../../api'

import SpinLoading from '../../components/SpinLoading'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/buttons/Button'
import { HeadingFour } from '../../components/Typography'

import BulkCollectionForm, { defaultValues, FormValues } from './components/BulkCollectionForm'

type Props = RouteComponentProps<{ bulkCollectionID: string, municipalityID?: string }>

const AppInfoWrap = styled.div`
	height: 360px;
	display: flex;
	flex-direction: column;
`

const AppInfo = styled.div`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.neutral['500']};
	background: ${({ theme }) => theme.colors.neutral['50']};
	margin-bottom: 24px;
	padding: 24px;
	border-radius: 8px;
	flex-grow: 1;

	label {
		font-weight: 500;
		color: ${({ theme }) => theme.colors.neutral['800']};
	}

	.sign {
		display: block;
		max-height: 250px;
		margin: 0 auto;
	}
`

const BulkCollectionPage = ({ match }: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const { bulkCollectionID, municipalityID } = match.params

	const [submitting, setSubmitting] = useState(false)
	const [detailState, loadDetail] = useDataLoader(bulkCollections.loadBulkCollection)
	const municipality = useSelector(getSelectedMunicipality)

	const handleBack = useCallback(() => {
		if (municipalityID) {
			history.push(t('paths:REGISTRY.collectionYard.municipalityPath', { municipalityID }))
		} else {
			history.push(t('paths:REGISTRY.collectionYard.path'))
		}
	}, [municipalityID, t])

	const handleSubmit = useCallback(async (values: FormValues) => {
		try {
			setSubmitting(true)

			const submitData = {
				scanDatetime: values.scanDatetime,
				collectionYardID: values.collectionYard?.value,
				customerID: values.customer?.value,
				collections: map(values.collections, (collection) => ({
					containerID: collection.container?.value,
					weight: replace(`${collection?.weight || 0}`, ',', '.')
				}))
			}

			const response = bulkCollectionID
				? await bulkCollections.updateBulkCollection(bulkCollectionID, submitData)
				: await bulkCollections.createBulkCollection(municipalityID!, submitData)

			const { messages } = getMessages(response)

			handleBack()

			dispatch(statusPush(messages[0]))

			return Promise.resolve()
		} catch (e: any) {
			const { messages } = getMessages(e.response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve({})
		} finally {
			setSubmitting(false)
		}
	}, [bulkCollectionID, municipalityID, handleBack, dispatch])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit,
		mutators: { ...arrayMutators }
	}), [handleSubmit])

	const breadcrumbs = useMemo(() => {
		if (municipality || municipalityID === `${municipality?.id}`) {
			return [{
				key: 'path.municipalities',
				name: t('paths:MUNICIPALITY.title')
			}, {
				key: 'path.administration.municipalities.detail',
				name: municipality ? municipality.name : t('loc:Načítavam')
			}, {
				key: 'path.REGISTRY',
				name: t('paths:REGISTRY.title')
			}, {
				key: 'path.REGISTRY.collectionYard',
				name: t('paths:REGISTRY.collectionYard.title'),
				link: t('paths:REGISTRY.collectionYard.municipalityPath', { municipalityID })
			}, {
				key: 'path.REGISTRY.collectionYard.detail',
				name: detailState.data
					? t('loc:Záznam {{id}}', { id: detailState.data.id })
					: t('loc:Načítavam')
			}]
		}

		return [{
			key: 'path.REGISTRY',
			name: t('paths:REGISTRY.title')
		}, {
			key: 'path.REGISTRY.collectionYard',
			name: t('paths:REGISTRY.collectionYard.title'),
			link: t('paths:REGISTRY.collectionYard.path')
		}, {
			key: 'path.REGISTRY.collectionYard.detail',
			name: detailState.data
				? t('loc:Záznam {{id}}', { id: detailState.data.id })
				: t('loc:Načítavam')
		}]
	}, [municipalityID, municipality, detailState.data, t])

	useEffect(() => {
		if (bulkCollectionID) {
			loadDetail(bulkCollectionID)
		} else {
			form.restart(defaultValues)
		}
	}, [form, loadDetail, bulkCollectionID])

	useEffect(() => {
		if (detailState.data) {
			const { collectionYard, customer, collections, scanDatetime } = detailState.data

			const initialValues = {
				scanDatetime,
				collectionYard: { value: collectionYard.id, label: collectionYard.name },
				customer: { value: customer.id, label: formatCustomer(customer), item: customer },
				collections: collections.map(({ container, weight }) => ({
					container: { value: container.id, label: formatContainerName(container, true), item: container },
					weight
				}))
			}

			form.restart(initialValues)
		}
	}, [detailState.data, form])

	let content

	if (detailState.isLoading) {
		content = <SpinLoading/>
	}

	if (detailState.data) {
		content = <Row gutter={[24, 24]}>
			<Col span={24}>
				<BulkCollectionForm form={form} municipalityID={match.params.municipalityID || detailState.data?.municipalityID}/>
			</Col>
			<Col span={24}>
				<HeadingFour>
					{t('loc:Informácie z aplikácie')}
				</HeadingFour>
			</Col>
			<Col span={12}>
				<AppInfoWrap>
					<AppInfo>
						<label>{t('loc:Vytvorené')}</label>
						<div>
							<div>{formatUserName(detailState.data?.creator)}</div>
							<div>{dayjs(detailState.data?.createdAt).format(DATE_TIME_FORMAT)}</div>
						</div>
					</AppInfo>
					<AppInfo>
						<label>{t('loc:Kód platcu')}</label>
						<div>{detailState.data?.customerCode || '-'}</div>
					</AppInfo>
					<AppInfo>
						<label>{t('loc:Upravené')}</label>
						<div>
							{detailState?.data?.editor ? <>
								<div>{formatUserName(detailState.data.editor)}</div>
								<div>{dayjs(detailState.data.updatedAt).format(DATE_TIME_FORMAT)}</div>
							</> : '-'}
						</div>
					</AppInfo>
				</AppInfoWrap>
			</Col>
			<Col span={12}>
				<AppInfoWrap>
					<AppInfo>
						<label>{t('loc:Podpis')}</label>
						<div>
							{detailState?.data?.sign ? <img className='sign' alt='sign' src={detailState?.data?.sign} /> : t('loc:Žiadny podpis')}
						</div>
					</AppInfo>
				</AppInfoWrap>
			</Col>
		</Row>
	}

	return (
		<Box>
			<BoxHeader>
				<Breadcrumb items={breadcrumbs}/>
				<BoxTitle>
					{t('loc:Záznam {{id}}', { id: get(detailState, 'data.id') || '-' })}
				</BoxTitle>
			</BoxHeader>
			<BoxContent className={'padded'}>
				{content}
			</BoxContent>
			<BoxFooter>
				<Row justify={'end'} gutter={[16, 16]}>
					<Col>
						<Button
							disabled={submitting}
							onClick={handleBack}
							className={'secondary'}
						>
							{t('loc:Späť')}
						</Button>
					</Col>
					<Col>
						<Button
							disabled={submitting}
							loading={submitting}
							onClick={form.submit}
						>
							{t('loc:Uložiť')}
						</Button>
					</Col>
				</Row>
			</BoxFooter>
		</Box>
	)
}

export default BulkCollectionPage
