import React, { FocusEvent, useState, KeyboardEvent, MutableRefObject } from 'react'
import cx from 'classnames'

import { FieldRenderProps } from 'react-final-form'
import { Info, Input, InputWrapper, Label } from './Styled'

type Props = FieldRenderProps<string> & {
	label?: string
	required?: boolean
	autoComplete?: string
	maxLength?: number
	className?: string
	disabled?: boolean
	autoFocus?: boolean
	placeholder?: string
	inputRef?: MutableRefObject<HTMLInputElement | null>
	icons?: Array<{icon: string, handleClick: Function}>
	showSuccessIcon?: boolean

	onKeyPress?: (e: KeyboardEvent) => void
}

const TextField = ({ meta, input, label, required, autoComplete, inputRef, className, disabled, autoFocus, placeholder, maxLength = 255, ...rest }: Props) => {
	const [focused, setFocused] = useState(!!input.value)

	const error = meta.touched && meta.error
	const info = meta.error && focused

	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		input.onFocus(e)
		setFocused(true)
	}

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		input.onBlur(e)
		setFocused(false)
	}

	const componentClass = cx(className, { error, info })

	return (
		<InputWrapper className={className}>
			<Label>
				{label}{required ? '*' : ''}
			</Label>
			<Input
				{...input}
				{...rest}
				ref={inputRef}
				disabled={disabled}
				maxLength={maxLength}
				autoComplete={autoComplete}
				onFocus={handleFocus}
				autoFocus={autoFocus}
				placeholder={placeholder}
				onBlur={handleBlur}
				required={required}
				className={componentClass}
				type={input.type}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default TextField
