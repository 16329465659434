import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initialize, reset } from 'redux-form'
import { get } from 'lodash'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// actions
import { statusPush } from '../../actions/statusActions'

// components
import DeviceForm from '../../components/devices/DeviceForm'
import DialogModal from '../../components/modals/DialogModal'

import Breadcrumbs from '../../components/Breadcrumb'

// utils
import { ENDPOINTS, FORMS, INFO, MODAL } from '../../utils/enums'
import { getReq, patchReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'

// recources
import locale from '../../resources/locale'

class ContainerTypePage extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		match: PropTypes.object.isRequired,
		wasteTypes: PropTypes.arrayOf(PropTypes.object.isRequired),
		loadMunicipalityWasteTypes: PropTypes.func.isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			modalData: null,
			device: {
				isLoading: false,
				isFailure: false,
				data: null
			}
		}
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	getDevice = async () => {
		const { match } = this.props
		try {
			const { deviceID } = match.params

			if (!this._mounted) {
				return
			}
			this.setState({
				device: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			const res = await getReq(ENDPOINTS.DEVICE(deviceID))
			if (!this._mounted) {
				return
			}
			this.setState({
				device: {
					data: get(res, 'data'),
					isLoading: false,
					isFailure: false
				}
			})
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				device: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidUpdate() {
		const { device, initialValues } = this.state
		const { initialize } = this.props
		if (device.data && !device.isLoading && !initialValues) {
			const initValues = {
				name: get(device, 'data.name'),
				type: get(device, 'data.type'),
				number: get(device, 'data.number'),
				assignedFrom: get(device, 'data.assignedFrom'),
				assignedTo: get(device, 'data.assignedTo'),
				UUID: get(device, 'data.UUID'),
				SIM: get(device, 'data.SIM'),
				initTimestamp: new Date()
			}
			// clear form
			initialize(FORMS.UPDATE_DEVICE_FORM, initValues, true)
			reset(FORMS.UPDATE_DEVICE_FORM)
			if (!this._mounted) {
				return
			}
			this.setState({
				initialValues: initValues
			})
		}
	}

	componentDidMount() {
		this._mounted = true

		const { reset } = this.props

		// clear form
		reset(FORMS.UPDATE_DEVICE_FORM)

		// load data
		this.getDevice()
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	submitHandler = async (values) => {
		const { match, pushStatus, municipality } = this.props
		try {
			const { deviceID } = match.params
			const { device } = this.state
			const municipalityID = get(device, 'data.municipality.id')

			const body = {
				name: values.name,
				type: values.type,
				number: values.number,
				assignedFrom: values.assignedFrom,
				assignedTo: values.assignedTo,
				UUID: values.UUID,
				SIM: values.SIM
			}
			await patchReq(ENDPOINTS.MUNICIPALITY_DEVICE(municipalityID, deviceID), null, body)
			// refetch data
			pushStatus({
				type: INFO,
				msg: locale['page.device.update.success']
			})
			// redirect on pages types list
			if (municipality) {
				history.push(locale.formatString(locale['path.municipality.devices'], municipalityID))
			} else {
				history.push(locale['path.administration.devices'])
			}
		} catch (error) {
			// Error
		}
	}

	backBtnCallback = (e) => {
		const { match } = this.props
		const { municipalityID } = match.params
		// redirect on pages types list

		e?.preventDefault()

		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.devices'], municipalityID))
		} else {
			history.push(locale['path.administration.devices'])
		}
	}

	onDeleteDevice = async () => {
		try {
			const { pushStatus, municipality } = this.props
			const { device } = this.state
			const deviceID = get(device, 'data.id')
			const municipalityID = get(device, 'data.municipalityID')

			await deleteReq(ENDPOINTS.MUNICIPALITY_DEVICE(municipalityID, deviceID))
			pushStatus({
				type: INFO,
				msg: locale['page.device.delete.success']
			})
			this.dismissModal()
			// redirect on devices list
			if (municipality) {
				history.replace(locale.formatString(locale['path.municipality.devices'], municipalityID))
			} else {
				history.replace(locale['path.administration.devices'])
			}
		} catch (e) {
			// Error
		}
	}

	render() {
		const { municipality } = this.props
		const { device, initialValues, modal, modalData } = this.state

		if (!device.data || device.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !device.isLoading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.users.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		let items = []
		if (municipality) {
			items = [{
				key: 'page.municipality',
				name: locale['page.municipality.settings.breadcrumbs']
			}, {
				key: 'page.municipality.detail',
				name: municipality.name
			}]
		} else {
			items = [{
				key: 'page.administration.breadcrumbs',
				name: locale['page.administration.breadcrumbs']
			}]
		}

		const breadcrumbsItems = [...items, {
			key: 'page.devices.breadcrumbs',
			name: locale['page.devices.breadcrumbs']
		}, {
			key: 'page.device.breadcrumbs.detail',
			name: get(device, 'data.name')
		}]

		return (
			<>
				<Breadcrumbs
					items={breadcrumbsItems}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_DEVICE}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteDevice}
					message={locale.formatString(locale['page.device.remove.message'])}
					title={locale.formatString(locale['page.device.remove.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.device.remove.accept']}
				/>
				<DeviceForm
					municipality={municipality ? null : get(device, 'data.municipality.name')}
					backBtnCallback={this.backBtnCallback}
					deleteBtnCallback={this.openModal(MODAL.DELETE_DEVICE)}
					onSubmit={this.submitHandler}
					initialValues={initialValues}
					device={device}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	reset: bindActionCreators(reset, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTypePage)
