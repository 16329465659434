import {
	MUNICIPALITY_WASTE_TYPES_LOAD_START,
	MUNICIPALITY_WASTE_TYPES_LOAD_DONE,
	MUNICIPALITY_WASTE_TYPES_LOAD_FAIL,
	SELECTED_MUNICIPALITY_LOAD_START,
	SELECTED_MUNICIPALITY_LOAD_DONE,
	SELECTED_MUNICIPALITY_LOAD_FAIL,
	SELECTED_MUNICIPALITY_DESELECTED,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START,
	MUNICIPALITY_MOTIVATION_MODEL_LOAD_START,
	MUNICIPALITY_MOTIVATION_MODEL_LOAD_DONE,
	MUNICIPALITY_MOTIVATION_MODEL_LOAD_FAIL,
	MUNICIPALITIES_LOAD_START,
	MUNICIPALITIES_LOAD_DONE,
	MUNICIPALITIES_LOAD_FAIL,
	SET_LAST_COLLECTION_YARD
} from '../types/municipality'

import { RESET_STORE } from '../types/global'

export const initState = {
	municipality: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	lastCollectionYard: null,
	wasteTypes: {
		isLoading: false,
		isFailure: false,
		data: []
	},
	collectionPlacesYard: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	collectionPlaceContainers: {
		isLoading: false,
		isFailure: false,
		data: []
	},
	municipalities: {
		isLoading: false,
		isFailure: false,
		data: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case SET_LAST_COLLECTION_YARD: {
			return {
				...state,
				lastCollectionYard: action.payload
			}
		}
		case MUNICIPALITIES_LOAD_DONE:
			return {
				...state,
				municipalities: {
					isFailure: false,
					isLoading: false,
					data: action.payload.data
				}
			}
		case MUNICIPALITIES_LOAD_START:
			return {
				...state,
				municipalities: {
					isFailure: false,
					isLoading: true,
					data: null
				}
			}
		case MUNICIPALITIES_LOAD_FAIL:
			return {
				...state,
				municipalities: {
					isFailure: true,
					isLoading: false,
					data: null
				}
			}
		case SELECTED_MUNICIPALITY_LOAD_START:
			return {
				...state,
				municipality: {
					data: null,
					isFailure: false,
					isLoading: true
				}

			}
		case SELECTED_MUNICIPALITY_LOAD_DONE:
			return {
				...state,
				municipality: {
					data: action.payload.municipality,
					isFailure: false,
					isLoading: false
				}
			}

		case SELECTED_MUNICIPALITY_LOAD_FAIL:
			return {
				...state,
				municipality: {
					...state.municipality,
					data: null,
					isFailure: true,
					isLoading: false
				}
			}
		case MUNICIPALITY_WASTE_TYPES_LOAD_START:
			return {
				...state,
				wasteTypes: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case MUNICIPALITY_WASTE_TYPES_LOAD_DONE:
			return {
				...state,
				wasteTypes: {
					data: action.payload.municipalityWasteTypes,
					isFailure: false,
					isLoading: false
				}
			}

		case MUNICIPALITY_WASTE_TYPES_LOAD_FAIL:
			return {
				...state,
				wasteTypes: {
					data: [],
					isFailure: true,
					isLoading: false
				}
			}
		case MUNICIPALITY_MOTIVATION_MODEL_LOAD_START:
			return {
				...state,
				motivationModels: {
					data: [],
					isFailure: false,
					isLoading: true
				}
			}
		case MUNICIPALITY_MOTIVATION_MODEL_LOAD_DONE:
			return {
				...state,
				motivationModels: {
					data: action.payload.motivationModels,
					isFailure: false,
					isLoading: false
				}
			}
		case MUNICIPALITY_MOTIVATION_MODEL_LOAD_FAIL:
			return {
				...state,
				motivationModels: {
					data: [],
					isFailure: true,
					isLoading: false
				}
			}
		case SELECTED_MUNICIPALITY_DESELECTED:
			return {
				...state,
				lastCollectionYard: null,
				municipality: {
					data: null,
					isFailure: false,
					isLoading: false
				}
			}
		case MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE:
			return {
				...state,
				collectionPlacesYard: {
					data: action.payload.municipalityCollectionPlacesYard,
					isLoading: false,
					isFailure: false
				}
			}
		case MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL:
			return {
				...state,
				collectionPlacesYard: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START:
			return {
				...state,
				collectionPlacesYard: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START:
			return {
				...state,
				collectionPlaceContainers: {
					...initState.collectionPlaceContainers,
					isLoading: true
				}
			}
		case MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL:
			return {
				...state,
				collectionPlaceContainers: {
					...initState.collectionPlaceContainers,
					isFailure: true
				}
			}
		case MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE:
			return {
				...state,
				collectionPlaceContainers: {
					...initState.collectionPlaceContainers,
					data: action.payload.municipalityCollectionPlaceContainers
				}

			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
