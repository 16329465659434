import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'

import locale from '../resources/locale'

class InfoFooter extends React.Component {
	render = () => {
		const { createdAt, creator, updatedAt, editor } = this.props
		return (
			<div className={'row'}>
				<div className={'col-md-3'}>
					<div className={'info-wrapper'}>
						<label>{locale['common.createdAt']}</label>
						<div>{moment(createdAt).format(locale['common.date.format'])}</div>
						<div>{get(creator, 'name')} {get(creator, 'surname')}</div>
					</div>
				</div>
				{editor && <div className={'col-md-3'}>
					<div className={'info-wrapper'}>
						<label>{locale['common.updatedAt']}</label>
						<div>{moment(updatedAt).format(locale['common.date.format'])}</div>
						<div>{get(editor, 'name')} {get(editor, 'surname')}</div>
					</div>
				</div>}
			</div>
		)
	}
}

InfoFooter.propTypes = {
	createdAt: PropTypes.string,
	creator: PropTypes.shape({
		name: PropTypes.string.isRequired,
		surname: PropTypes.string.isRequired
	}),
	updatedAt: PropTypes.string,
	editor: PropTypes.shape({
		name: PropTypes.string.isRequired,
		surname: PropTypes.string.isRequired
	})
}

export default InfoFooter
