import styled from 'styled-components'

export const BoxTitle = styled.h3`
	font-size: 10px;
	padding: 20px 40px;
	color: ${({ theme }) => theme.colors.label};
	text-transform: uppercase;
`

export const BoxHeader = styled.div`
	margin-bottom: 16px;
`

export const BoxFooter = styled.div`
	margin-top: 16px;
`

export const BoxPadded = styled.div`
	padding: 24px;
`

export const BoxContent = styled.div`
	background: white;
	box-shadow: ${({ theme }) => theme.boxShadow};
	border-radius: 8px;
	min-height: 16px;
	overflow: hidden;
	
	&.top-margin {
		margin-top: 24px;
	}
	
	&.padded {
		padding: 32px;
	}
	
	&.tab {
		border-top-left-radius: 0;
	}
`

export const Box = styled.section`
	margin-bottom: 20px;
	
	&.padded {
		padding: 16px;
	}
`
