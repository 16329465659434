import React from 'react'
import i18next from 'i18next'
import * as PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { Field, reduxForm, propTypes } from 'redux-form'

import { map, get } from 'lodash'
import locale from '../../../resources/locale'

import Modal from '../../../components/modals/Modal'

import { FORMS, CODE_PORTION_TYPES, CONTAINER_TYPES } from '../../../utils/enums'

import SelectField from '../../../atoms/SelectField'
import TextAreaInputField from '../../../atoms/TextAreaInputField'

import CodePortionValidator from './CodePortionValidator'
import AddressCodePortion from './AddressCodePortion'
import AnonymousCodePortion from './AnonymousCodePortion'
import Button from '../../../components/buttons/Button'

const codePortionsTypesOptions = map(Object.values(CODE_PORTION_TYPES), (type) => ({
	value: type,
	label: locale[`codePortion.type.${type}`]
}))

class CodePortionForm extends React.Component {
	static propTypes = {
		...propTypes,
		handleSubmit: PropTypes.func.isRequired,
		dismissHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object,
		shown: PropTypes.bool,
		loadContainerTypes: PropTypes.func.isRequired,
		loadCollectionPlaces: PropTypes.func.isRequired,
		municipalityID: PropTypes.number,
		codePortionType: PropTypes.oneOf(Object.values(CODE_PORTION_TYPES)),
		containerTypes: PropTypes.shape({
			data: PropTypes.shape({
				containerTypes: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		}),
		municipalities: PropTypes.shape({
			data: PropTypes.shape({
				municipalities: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		}),
		collectionPlaces: PropTypes.shape({
			data: PropTypes.shape({
				collectionPlaces: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		})
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	onMunicipalityChange = (event, value) => {
		const { array, loadContainerTypes, loadCollectionPlaces, change } = this.props
		array.removeAll('addressCodes.containerTypes')
		array.push('addressCodes.containerTypes', {})
		change('addressCodes.exclude', [])
		loadContainerTypes({ municipalityID: value, limit: 1000, types: [CONTAINER_TYPES.ONE_TIME, CONTAINER_TYPES.PERMANENT, CONTAINER_TYPES.UNIVERSAL] })
		loadCollectionPlaces({ municipalityID: value, limit: 100 })
	}

	render() {
		const {
			dismissHandler, handleSubmit, shown, municipalities, submitting, codePortionType,
			containerTypes, municipalityID, collectionPlaces, loadCollectionPlaces
		} = this.props

		let formContent = null

		if (codePortionType) {
			formContent = codePortionType === CODE_PORTION_TYPES.ADDRESS
				?
				<AddressCodePortion
					containerTypes={containerTypes}
					municipalityID={municipalityID}
					collectionPlaces={collectionPlaces}
					loadCollectionPlaces={loadCollectionPlaces}
				/>
				: <AnonymousCodePortion/>
		}

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="l">
				<form onSubmit={handleSubmit} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['pages.codePortions.new']}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='type'
									placeholder={locale['field.placeholder.codePortionType']}
									component={SelectField}
									props={{
										label: i18next.t('loc:Typ kódov'),
										showLabel: true,
										required: true,
										options: codePortionsTypesOptions,
										isLoading: get(municipalities, 'isLoading', false)
									}}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='municipalityID'
									placeholder={locale['field.placeholder.municipality']}
									component={SelectField}
									onChange={this.onMunicipalityChange}
									props={{
										label: i18next.t('loc:Obec'),
										showLabel: true,
										required: true,
										options: map(get(municipalities, 'data.municipalities'), (item) => ({
											value: item.id,
											label: item.name
										})),
										isLoading: get(municipalities, 'isLoading', false)
									}}
								/>
							</div>
						</div>
						{formContent}
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='note'
									placeholder={locale['field.placeholder.codePortionType']}
									component={TextAreaInputField}
									props={{
										label: i18next.t('loc:Poznámka'),
										placeholder: locale['field.placeholder.note'],
										showLabel: true,
										required: true
									}}
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
									disabled={submitting}
									loading={submitting}
								>
									{locale['common.save']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CREATE_CODE_PORTION,
	destroyOnUnmount: true,
	touchOnChange: true,
	initialValues: {
		municipalityID: null,
		note: null,
		type: CODE_PORTION_TYPES.ADDRESS,
		anonymousCodes: {
			fixed: 0,
			oneTime: 0
		},
		addressCodes: {
			exclude: [],
			containerTypes: [{}],
			collectionPlaceTypes: []
		}
	},
	validate: CodePortionValidator
})(CodePortionForm)
