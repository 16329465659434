import { RESET_STORE } from '../../types/global'
import { COLLECTION_ROUNDS_ACTIONS } from './constants'

export const initState = {
	list: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	map: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	overview: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_START:
			return {
				...initState,
				...state,
				list: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_FAIL:
			return {
				...initState,
				...state,
				list: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_DONE:
			return {
				...initState,
				...state,
				list: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_START:
			return {
				...initState,
				...state,
				detail: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_FAIL:
			return {
				...initState,
				...state,
				detail: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_START:
			return {
				...initState,
				...state,
				map: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_FAIL:
			return {
				...initState,
				...state,
				map: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_DONE:
			return {
				...initState,
				...state,
				map: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_START:
			return {
				...initState,
				...state,
				overview: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_FAIL:
			return {
				...initState,
				...state,
				overview: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_DONE:
			return {
				...initState,
				...state,
				overview: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return { ...state }
	}
}
