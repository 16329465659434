import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Dropdown, Menu } from 'antd'
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { history } from '../../utils/history'
import { authenticationActions, getAuthUser } from '../../redux/authentication'
import { getPermissions } from '../../utils/helpers'

const Item = styled.div`
	color: ${({ theme }) => theme.colors.text};
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-right: 30px
`

const StyledMenuItem = styled(Menu.Item)`
	display: flex;
	align-items: center;

	& > .anticon {
		margin-bottom: 2px;
		margin-right: 12px;
	}
`

const UserName = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 16px;

	& > .name {
		font-size: 12px;
		font-weight: bold;
	}

	& > .permissions {
		font-size: 11px;
		color: ${({ theme }) => theme.colors.lightText}
	}
`

const StyledMenu = styled(Menu)`
	border-radius: 8px;
	overflow: hidden;
`

const ProfileDropdown = () => {
	const dispatch = useDispatch()
	const user = useSelector(getAuthUser)

	const [t] = useTranslation()

	const handleLogout = async () => {
		await dispatch(authenticationActions.logoutUser())
		history.push(t('paths:AUTHENTICATION.login.path'))
	}

	const handleOpenProfile = () => {
		history.push(t('paths:PROFILE.path'))
	}

	const permissions = useMemo(() => {
		if (user.data) {
			if (user.data.isAdmin) {
				return t('PERMISSIONS.ADMINISTRATOR')
			}

			if (user.data.municipalities?.length) {
				return getPermissions(user.data.municipalities[0].permissions[0])
			}
		}

		return ''
	}, [t, user.data])

	const menu = (
		<StyledMenu>
			<StyledMenuItem key={1} onClick={handleOpenProfile} style={{ padding: 15 }}>
				<UserOutlined/>
				<span>{t('ProfileDropdown.Profile')}</span>
			</StyledMenuItem>
			<StyledMenuItem key={0} onClick={handleLogout} style={{ padding: 15 }}>
				<LogoutOutlined/>
				<span>{t('ProfileDropdown.Logout')}</span>
			</StyledMenuItem>
		</StyledMenu>
	)

	return (
		<Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'}>
			<Item onClick={(e) => e.preventDefault()}>
				<UserName>
					<span className={'name'}>{`${user.data?.name || ''} ${user.data?.surname || ''}`}</span>
					<span className={'permissions'}>{permissions}</span>
				</UserName>
				<DownOutlined style={{ marginLeft: 10 }}/>
			</Item>
		</Dropdown>
	)
}

export default React.memo(ProfileDropdown)
