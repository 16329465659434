import { findIndex, get, indexOf, reduce, sortBy } from 'lodash'
import { FILTER_POSITION } from './enums'

export const INVALID_ONLY = 'INVALID_ONLY'
export const UNASSIGNED_ONLY = 'UNASSIGNED_ONLY'
export const UNSUITABLE_ONLY = 'UNSUITABLE_ONLY'
export const WITHOUT_COLLECTION = 'WITHOUT_COLLECTION'

export const getSelectedColumns = (filters, filtersValues, columns) => {
	const sortedColumns = sortBy(get(filters, 'columns'), (item) => findIndex(columns, { name: item }))

	return reduce(columns, (result, item) => {
		const filterIndex = indexOf(sortedColumns, item.name)

		if (filterIndex >= 0) {
			if (item.filter && filtersValues[item.filter.name || item.name]) {
				item.filter = {
					...item.filter,
					...filtersValues[item.filter.name || item.name],
					position: FILTER_POSITION.RIGHT
				}
			}

			if (filterIndex >= sortedColumns.length - 2 && item.filter) {
				item.filter.position = FILTER_POSITION.LEFT
			}

			return [
				...result,
				item
			]
		}

		return result
	}, [])
}
