import { createSelector } from 'reselect'
import { get, map, filter } from 'lodash'
import { CONTAINER_TYPES } from '../../utils/enums'

export const getContainerTypesList = (state) => state.containerTypes.list
export const getContainerTypesSelectOptions = createSelector([getContainerTypesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.containerTypes'), (item) => ({
		value: item.id,
		label: item.name
	}))
}))

export const getContainerTypesSelectOptionsWithNoTypeOption = createSelector([getContainerTypesList], (list) => {
	const item = {
		isLoading: get(list, 'isLoading', true),
		values: map(get(list, 'data.containerTypes'), (item) => ({
			value: item.id,
			label: item.name
		}))
	}
	if (get(list, 'isLoading') === true) {
		return item
	}
	return { ...item, values: [...item.values, { value: 0, label: 'Bez typu' }] }
})

export const getOneTimeContainerTypesSelectOptions = createSelector([getContainerTypesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(filter(get(list, 'data.containerTypes'), (item) => item.type === CONTAINER_TYPES.ONE_TIME), (item) => ({
		value: item.id,
		label: item.name
	}))
}))
