import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Col, Row, TablePaginationConfig } from 'antd'
import { debounce, every, values } from 'lodash'
import { ColumnTitle, FilterValue, SorterResult } from 'antd/es/table/interface'
import dayjs, { Dayjs } from 'dayjs'
import SK from 'antd/lib/date-picker/locale/sk_SK'
import Chart from 'chart.js/auto'
import { createEnumParam, NumberParam, StringParam, useQueryParams } from 'use-query-params'

import { Box, BoxContent, BoxHeader } from '../../layouts/Box'

import { Sorting, OrderDirections } from '../../types/data'

import { COLLECTION_PLACE_TYPE, SORTING_CHART_COLOR, SORTING_GROUP } from '../../utils/enums'
import { getOrderFromSorter, omitNilFilters, omitNotDefinedKeys } from '../../utils/helpers'

import { statistics } from '../../api'

import { filtersActions, FiltersState, getCollectionPlacesSortingFilters } from '../../redux/filters'
import { getSelectedMunicipality } from '../../redux/municipalities/selectors'

import { useDataLoader } from '../../hooks/useDataLoader'

import Breadcrumb from '../../components/Breadcrumb'
import ExportButton from '../../components/buttons/ExportButton'
import Table from '../../components/Table'
import SearchInputField from '../../atoms/SearchInputField'
import SpinLoading from '../../components/SpinLoading'

import { DatePicker, Select } from '../../components/form/Styled'
import { SubHeading } from '../../components/Typography'
import { history } from '../../utils/history'

const queryConfig = {
	current: NumberParam,
	pageSize: NumberParam,
	order: StringParam,
	search: StringParam,
	fromDate: StringParam,
	toDate: StringParam,
	type: createEnumParam(Object.values(COLLECTION_PLACE_TYPE)),
	filter: createEnumParam(Object.values(SORTING_GROUP)),
	direction: createEnumParam(['ascend', 'descend'])
}

const { RangePicker } = DatePicker

const sortingTypes = [
	SORTING_GROUP.ZERO, SORTING_GROUP.ONE_TO_TEN, SORTING_GROUP.ELEVEN_TO_TWENTY, SORTING_GROUP.TWENTY_ONE_TO_THIRTY, SORTING_GROUP.THIRTY_ONE_TO_FIFTY, SORTING_GROUP.FIFTY_ONE_AND_MORE
]

const CollectionPlacesSortingPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const municipality = useSelector(getSelectedMunicipality)
	const sortingFilters = useSelector(getCollectionPlacesSortingFilters)

	const countCanvasRef = useRef<HTMLCanvasElement | null>(null)
	const weightCanvasRef = useRef<HTMLCanvasElement | null>(null)
	const countChartRef = useRef<Chart>()
	const weightChartRef = useRef<Chart>()
	const statsLoadable = useRef(false)

	const [statsState, loadStats] = useDataLoader(statistics.loadCollectionPlacesSorting)
	const [graphState, loadGraph] = useDataLoader(statistics.loadCollectionPlacesSortingGraph)

	const [query, setQuery] = useQueryParams(queryConfig)

	const municipalityID = municipality?.id

	const breadcrumbs = useMemo(() => {
		const items = [{
			key: 'path.STATISTICS',
			name: t('paths:STATISTICS.title')
		}, {
			key: 'path.STATISTICS.collectionPlacesSorting',
			name: t('paths:STATISTICS.collectionPlacesSorting.title')
		}]

		if (municipality) {
			return [{
				key: 'path.MUNICIPALITY',
				name: t('paths:MUNICIPALITY.title')
			}, {
				key: 'path.MUNICIPALITY.name',
				name: municipality.name
			}, ...items]
		}

		return items
	}, [municipality, t])

	const numberCol = useCallback((name: string, title: string) => ({
		key: name,
		dataIndex: name,
		title: title as ColumnTitle<Sorting>,
		sorter: true,
		showSorterTooltip: false,
		width: '10%',
		ellipsis: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: sortingFilters.order === name ? sortingFilters.direction : null,
		render: (item: number) => item?.toFixed(2)
	}), [sortingFilters.direction, sortingFilters.order])

	const columns = useMemo(() => [
		{
			...numberCol('name', t('table.columns.title')),
			width: '24%',
			ellipsis: true,
			render: (item: string) => <strong>{item}</strong>
		},
		numberCol('total', t('table.columns.total')),
		numberCol('municipal', t('table.columns.municipal')),
		numberCol('plastic', t('table.columns.plastic')),
		numberCol('paper', t('table.columns.paper')),
		numberCol('bio', t('table.columns.bio')),
		numberCol('other', t('table.columns.other')),
		{
			...numberCol('rate', t('table.columns.rate')),
			width: 'auto'
		}
	], [numberCol, t])

	const collectionPlaceTypeOptions = useMemo(() => [{
		value: COLLECTION_PLACE_TYPE.FLAT_HOUSE,
		label: t('COLLECTION_PLACE_TYPE.FLAT_HOUSE')
	}, {
		value: COLLECTION_PLACE_TYPE.FAMILY_HOUSE,
		label: t('COLLECTION_PLACE_TYPE.FAMILY_HOUSE')
	}, {
		value: COLLECTION_PLACE_TYPE.FLAT,
		label: t('COLLECTION_PLACE_TYPE.FLAT')
	}, {
		value: COLLECTION_PLACE_TYPE.COLLECTION_YARD,
		label: t('COLLECTION_PLACE_TYPE.COLLECTION_YARD')
	}, {
		value: COLLECTION_PLACE_TYPE.COTTAGE,
		label: t('COLLECTION_PLACE_TYPE.COTTAGE')
	}, {
		value: COLLECTION_PLACE_TYPE.NEST,
		label: t('COLLECTION_PLACE_TYPE.NEST')
	}, {
		value: COLLECTION_PLACE_TYPE.OTHER,
		label: t('COLLECTION_PLACE_TYPE.OTHER')
	}], [t])

	const filterOptions = useMemo(() => [{
		value: SORTING_GROUP.ZERO,
		label: t('SORTING_GROUP.ZERO')
	}, {
		value: SORTING_GROUP.ONE_TO_TEN,
		label: t('SORTING_GROUP.ONE_TO_TEN')
	}, {
		value: SORTING_GROUP.ELEVEN_TO_TWENTY,
		label: t('SORTING_GROUP.ELEVEN_TO_TWENTY')
	}, {
		value: SORTING_GROUP.TWENTY_ONE_TO_THIRTY,
		label: t('SORTING_GROUP.TWENTY_ONE_TO_THIRTY')
	}, {
		value: SORTING_GROUP.THIRTY_ONE_TO_FIFTY,
		label: t('SORTING_GROUP.THIRTY_ONE_TO_FIFTY')
	}, {
		value: SORTING_GROUP.FIFTY_ONE_AND_MORE,
		label: t('SORTING_GROUP.FIFTY_ONE_AND_MORE')
	}], [t])

	const ranges = useMemo(() => ({
		[t('common.lastTwelveMonths')]: [dayjs().subtract(12, 'months').startOf('day'), dayjs()] as [Dayjs, Dayjs],
		[t('common.thisYear')]: [dayjs().startOf('year'), dayjs().endOf('year')] as [Dayjs, Dayjs],
		[t('common.thisMonth')]: [dayjs().startOf('month'), dayjs().endOf('month')] as [Dayjs, Dayjs],
		[t('common.thisQuarter')]: [dayjs().startOf('quarter'), dayjs().endOf('quarter')] as [Dayjs, Dayjs]
	}), [t])

	const locale = useMemo(() => ({
		emptyText: statsState.isLoading ? '' : t('common.noData')
	}), [statsState.isLoading, t])

	const pagination = useMemo(() => ({
		current: sortingFilters.current,
		total: statsState.data?.context.totalCount,
		pageSize: sortingFilters.pageSize,
		pageSizeOptions: ['20', '50', '100']
	}), [sortingFilters, statsState.data?.context.totalCount])

	const filterCollections = useCallback((filters: Partial<FiltersState['collectionPlacesSorting']>) => {
		dispatch(filtersActions.savePartialFilters({
			key: 'collectionPlacesSorting',
			filters
		}))
	}, [dispatch])

	const handleSearch = useMemo(() => debounce((search: string) => {
		filterCollections({
			...sortingFilters,
			search
		})
	}, 300), [filterCollections, sortingFilters])

	const handleChangeType = useCallback((type) => filterCollections({
		type
	}), [filterCollections])

	const handleChangeFilter = useCallback((filter) => filterCollections({
		filter
	}), [filterCollections])

	const handleChangeRange = useCallback((dates) => {
		const fromDate = dates ? dates[0].startOf('day').toISOString() : ''
		const toDate = dates ? dates[1].endOf('day').toISOString() : ''
		filterCollections({
			fromDate,
			toDate
		})
	}, [filterCollections])

	const handleChangeTable = useCallback((pagination: TablePaginationConfig, tableFilters: Record<string, FilterValue | null>, sorter: SorterResult<Sorting> | SorterResult<Sorting>[]) => {
		const [order, direction] = getOrderFromSorter(sorter)

		filterCollections({
			direction,
			current: pagination.current,
			pageSize: pagination.pageSize,
			order: order || 'name'
		})
	}, [filterCollections])

	const handleClickChart = useCallback((event, element) => {
		const index = element[0]?.index

		if (index >= 0) {
			const filter = sortingTypes[index]

			if (filter) {
				handleChangeFilter(filter)
			}
		}
	}, [handleChangeFilter])

	const onRow = useCallback((model) => ({
		onClick: () => history.push(municipalityID
			? t('paths:LISTS.collectionPlaces.detail.municipalityPath', { municipalityID, collectionPlaceID: model.id })
			: t('paths:LISTS.collectionPlaces.detail.path', { collectionPlaceID: model.id }))
	}), [municipalityID, t])

	useEffect(() => {
		if (statsState.data) {
			const {
				stats,
				context
			} = statsState.data
			if (!stats.length && context.current > 1) {
				filterCollections({ current: 1 })
			}
		}
	}, [filterCollections, statsState.data])

	useEffect(() => {
		let filters: object = sortingFilters

		// Do load stats until query and redux is merged
		if (!statsLoadable.current) {
			// Filter keys that are not used
			filters = omitNotDefinedKeys({
				...sortingFilters,
				...omitNilFilters(query)
			}, Object.keys(queryConfig))

			dispatch(filtersActions.saveFilters({
				key: 'collectionPlacesSorting',
				filters
			}))

			setQuery(filters)
			statsLoadable.current = true
		} else {
			loadStats({
				...sortingFilters,
				municipalityID
			})
			setQuery(filters)
		}
		// eslint-disable-next-line
	}, [dispatch, sortingFilters, loadStats, municipalityID, setQuery])

	useEffect(() => {
		loadStats({
			...sortingFilters,
			municipalityID
		})
	}, [sortingFilters, loadStats, municipalityID])

	useEffect(() => {
		if (statsLoadable.current) {
			loadGraph({
				fromDate: sortingFilters.fromDate,
				toDate: sortingFilters.toDate,
				type: sortingFilters.type,
				search: sortingFilters.search,
				municipalityID
			})
		}
	}, [sortingFilters.fromDate, sortingFilters.toDate, sortingFilters.type, sortingFilters.search, loadStats, municipalityID, loadGraph])

	useEffect(() => {
		if (graphState.data) {
			const {
				weight,
				sum
			} = graphState.data

			const labels = [
				t('SORTING_GROUP.ZERO'),
				t('SORTING_GROUP.ONE_TO_TEN'),
				t('SORTING_GROUP.ELEVEN_TO_TWENTY'),
				t('SORTING_GROUP.TWENTY_ONE_TO_THIRTY'),
				t('SORTING_GROUP.THIRTY_ONE_TO_FIFTY'),
				t('SORTING_GROUP.FIFTY_ONE_AND_MORE')
			]

			const backgroundColor = [
				SORTING_CHART_COLOR[SORTING_GROUP.ZERO],
				SORTING_CHART_COLOR[SORTING_GROUP.ONE_TO_TEN],
				SORTING_CHART_COLOR[SORTING_GROUP.ELEVEN_TO_TWENTY],
				SORTING_CHART_COLOR[SORTING_GROUP.TWENTY_ONE_TO_THIRTY],
				SORTING_CHART_COLOR[SORTING_GROUP.THIRTY_ONE_TO_FIFTY],
				SORTING_CHART_COLOR[SORTING_GROUP.FIFTY_ONE_AND_MORE]
			]

			const options = {
				cutout: '60%',
				maintainAspectRatio: false,
				onClick: handleClickChart,
				plugins: {
					datalabels: {
						display: false
					},
					legend: {
						position: 'bottom' as 'bottom',
						labels: {
							usePointStyle: true
						}
					}
				}
			}

			countChartRef.current?.destroy()
			weightChartRef.current?.destroy()
			const countContext = countCanvasRef.current?.getContext('2d')
			const weightContext = weightCanvasRef.current?.getContext('2d')

			if (countContext) {
				countChartRef.current = new Chart(countContext, {
					type: 'doughnut',
					data: {
						labels,
						datasets: [{
							data: [
								sum[SORTING_GROUP.ZERO],
								sum[SORTING_GROUP.ONE_TO_TEN],
								sum[SORTING_GROUP.ELEVEN_TO_TWENTY],
								sum[SORTING_GROUP.TWENTY_ONE_TO_THIRTY],
								sum[SORTING_GROUP.THIRTY_ONE_TO_FIFTY],
								sum[SORTING_GROUP.FIFTY_ONE_AND_MORE]
							],
							backgroundColor
						}]
					},
					options
				})
			}

			if (weightContext) {
				weightChartRef.current = new Chart(weightContext, {
					type: 'doughnut',
					data: {
						labels,
						datasets: [{
							data: [
								weight[SORTING_GROUP.ZERO],
								weight[SORTING_GROUP.ONE_TO_TEN],
								weight[SORTING_GROUP.ELEVEN_TO_TWENTY],
								weight[SORTING_GROUP.TWENTY_ONE_TO_THIRTY],
								weight[SORTING_GROUP.THIRTY_ONE_TO_FIFTY],
								weight[SORTING_GROUP.FIFTY_ONE_AND_MORE]
							],
							backgroundColor
						}]
					},
					options
				})
			}
		}
	}, [graphState.data, handleClickChart, t])

	const showEmpty = useMemo(() => every(values(graphState.data?.sum), (value) => value === 0), [graphState.data])
	const rangeValue: [Dayjs, Dayjs] | undefined = useMemo(() => sortingFilters.fromDate ? [
		dayjs(sortingFilters.fromDate),
		dayjs(sortingFilters.toDate)
	] : undefined, [sortingFilters.fromDate, sortingFilters.toDate])

	let graphContent

	if (graphState.isLoading) {
		graphContent = (
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<SpinLoading height={'336px'}/>
				</Col>
				<Col span={12}>
					<SpinLoading height={'336px'}/>
				</Col>
			</Row>
		)
	} else if (showEmpty) {
		graphContent = (
			<Row
				justify={'center'}
				align={'middle'}
				style={{ height: 336 }}
			>
				<Col>
					<SubHeading className={'grey'}>{t('CollectionPlacesSortingPage.noData')}</SubHeading>
				</Col>
			</Row>
		)
	} else if (graphState.data) {
		graphContent = (
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<SubHeading
						className={'text-center'}>{t('CollectionPlacesSortingPage.countGraphTitle')}</SubHeading>
					<div style={{ height: 304 }}>
						<canvas ref={countCanvasRef} height={'300px'} width={'300px'}/>
					</div>
				</Col>
				<Col span={12}>
					<SubHeading className={'text-center'}>{t('CollectionPlacesSortingPage.sumGraphTitle')}</SubHeading>
					<div style={{ height: 304 }}>
						<canvas ref={weightCanvasRef} height={'300px'} width={'300px'}/>
					</div>
				</Col>
			</Row>
		)
	}

	return (
		<Box>
			<BoxHeader>
				<Breadcrumb items={breadcrumbs}/>
			</BoxHeader>
			<BoxContent className={'padded'}>
				<Row gutter={[16, 16]}>
					<Col>
						<SearchInputField
							name={'search'}
							onChange={handleSearch}
							defaultValue={sortingFilters.search}
						/>
					</Col>
					<Col flex={1}>
						<ExportButton
							url={'/api/admin/statistics/collection-places-sorting/export'}
							context={omitNilFilters({
								...sortingFilters,
								municipalityID
							})}
						/>
					</Col>
					<Col style={{ width: 200 }}>
						<Select
							allowClear={true}
							value={sortingFilters.filter || undefined}
							onChange={handleChangeFilter}
							options={filterOptions}
							placeholder={t('fields.sortingFilter.placeholder')}
						/>
					</Col>
					<Col style={{ width: 200 }}>
						<Select
							allowClear={true}
							value={sortingFilters.type || undefined}
							onChange={handleChangeType}
							options={collectionPlaceTypeOptions}
							placeholder={t('fields.collectionPlaceType.placeholder')}
						/>
					</Col>
					<Col>
						<RangePicker
							onChange={handleChangeRange}
							ranges={ranges}
							value={rangeValue}
							locale={SK}
						/>
					</Col>
					<Col span={24}>
						{graphContent}
					</Col>
					<Col span={24}>
						<Table
							rowKey={'id'}
							dataSource={statsState.data?.stats || []}
							loading={statsState.isLoading}
							onRow={onRow}
							// @ts-ignore
							onChange={handleChangeTable}
							columns={columns}
							pagination={pagination}
							locale={locale}
							scroll={{ x: 1220 }}
						/>
					</Col>
				</Row>

			</BoxContent>
		</Box>
	)
}

export default CollectionPlacesSortingPage
