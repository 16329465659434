import React, { useCallback, useState } from 'react'

import { DownloadOutlined } from '@ant-design/icons'

import { downloadReq } from '../../utils/request'
import { downloadFile, fileNameFromResponse } from '../../utils/upload'

import Button from './Button'

type Props = {
	url: string
	context?: object

	className?: string
	title?: string
}

const ExportButton = ({ url, className, title, context = {} }: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const handleDownload = useCallback(async () => {
		try {
			setIsLoading(true)
			const response = await downloadReq(url, context)
			const name = fileNameFromResponse(response)
			downloadFile({ name }, response.data)
			setIsLoading(false)
		} catch (e) {
			setIsLoading(false)
		}
	}, [url, context])

	return (
		<Button loading={isLoading} disabled={isLoading} onClick={handleDownload} className={className}>
			<DownloadOutlined/>
			{!!title && <span>{title}</span>}
		</Button>
	)
}

export default ExportButton
