import {
	USER_LOGIN_SUCCESS,
	USER_LOGOUT
} from '../types/auth'

import {
	USER_LOAD_SUCCESS, USER_UPDATE
} from '../types/user'

import { RESET_STORE } from '../types/global'
import { MUNICIPALITIES_UPDATE } from '../types/municipality'

export const initState = {
	profile: null
}

export default (state = initState, action) => {
	switch (action.type) {
		case USER_LOGIN_SUCCESS:
		case USER_LOAD_SUCCESS:
			return {
				...state,
				profile: action.payload.user
			}
		case USER_UPDATE:
			return {
				...state,
				profile: {
					...state.profile,
					...action.payload.user
				}
			}
		case MUNICIPALITIES_UPDATE:
			return {
				...state,
				profile: {
					...state.profile,
					municipalities: action.payload.municipalities
				}
			}
		case USER_LOGOUT:
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
