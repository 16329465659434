import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import cx from 'classnames'
import moment from 'moment-timezone'
import i18next from 'i18next'
import locale from '../resources/locale'
import { isSameDate } from '../utils/utils'
import closeIcon from '../resources/images/close.svg'

require('moment/locale/sk')

class DateRangePicker extends React.Component {
	static propTypes = {
		input: PropTypes.shape({
			onChange: PropTypes.func.isRequired,
			onBlur: PropTypes.func.isRequired,
			value: PropTypes.shape({
				from: PropTypes.any,
				to: PropTypes.any
			}).isRequired
		}),
		meta: PropTypes.shape({
			error: PropTypes.string,
			touched: PropTypes.bool.isRequired
		}),
		format: PropTypes.string.isRequired,
		timeFormat: PropTypes.string,
		placeholder: PropTypes.string,
		showLabel: PropTypes.bool.isRequired,
		label: PropTypes.string
	}

	constructor(props) {
		super(props)
		this.state = {
			active: false,
			mode: 'days',
			fromDate: null,
			toDate: null
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node
	}

	setInputRef = (node) => {
		this.inputRef = node
	}

	handleClickOutside = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.onBlur()
		}
	}

	onChange = (date) => {
		const { input } = this.props
		const { value } = input
		const dateRange = {}
		if (value.from && moment(value.from) < date) {
			dateRange.from = value.from
			dateRange.to = date.toDate()
		} else {
			dateRange.from = date.toDate()
			dateRange.dateTo = null
		}

		input.onChange(dateRange)
	}

	onBlur = () => {
		const { input } = this.props
		const { active } = this.state
		if (active) {
			input.onBlur()
			this.setState({
				active: false
			})
		}
	}

	onFocus = () => {
		const { input } = this.props
		input.onFocus()
		this.inputRef.blur()
		this.setState({
			active: true
		})
	}

	formatValue = (value) => {
		const { format } = this.props

		let formattedDateRange = ''
		if (value.from) {
			const date = moment(value.from)
			if (date.isValid()) {
				formattedDateRange = `od ${date.format(format)}`
			}
		}
		if (value.from && value.to) {
			const date = moment(value.to)
			if (date.isValid()) {
				formattedDateRange = `${formattedDateRange} - do ${date.format(format)}`
			}
		}

		return formattedDateRange
	}

	renderYear = (props, year, selectedDate) => {
		let selected = false
		if (selectedDate) {
			const selectedYear = selectedDate.year()
			selected = selectedYear === year
		}
		return (
			<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
				{year}
			</td>
		)
	}

	renderMonth = (props, month, year, selectedDate) => {
		let selected = false
		if (selectedDate) {
			const selectedYear = selectedDate.year()
			const selectedMonth = selectedDate.month()
			selected = selectedYear === year && selectedMonth === month
		}
		return (
			<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
				{locale[`month.short.${month}`]}
			</td>
		)
	}

	renderDay = (props, currentDate) => {
		const { input: { value } } = this.props

		let marginFrom = false
		let marginTo = false

		if (value.from) {
			marginFrom = isSameDate(currentDate, moment(value.from))
		}

		if (value.to) {
			marginTo = !marginFrom && isSameDate(currentDate, moment(value.to))
		}

		let through = false

		if (value.from && value.to) {
			through = !marginFrom && !marginTo && moment(value.from) < currentDate && moment(value.to) > currentDate
		}

		const today = isSameDate(moment(), currentDate)

		return (
			<td {...props} className={cx(props.className, { marginFrom, marginTo, through, today }, 'calendar-tile')}>
				{currentDate.date()}
			</td>
		)
	}

	onViewModeChange = (mode) => {
		this.setState({
			mode
		})
	}

	clear = () => {
		const { input } = this.props
		input.onChange({
			from: null,
			to: null
		})
	}

	lastTwelveMonths = () => {
		const { input } = this.props
		input.onChange({
			from: moment().subtract(12, 'month').startOf('day'),
			to: moment().endOf('day')
		})
	}

	thisQuarter = () => {
		const { input } = this.props
		input.onChange({
			from: moment().startOf('quarter'),
			to: moment().endOf('quarter')
		})
	}

	thisYear = () => {
		const { input } = this.props
		input.onChange({
			from: moment().startOf('year'),
			to: moment().endOf('year')
		})
	}
	closeCalendar = (e) => {
		e.stopPropagation()
		this.inputRef.blur()
		this.setState({ active: false })
	}
	render() {
		const { showLabel, label, input, placeholder, meta: { touched, error } } = this.props
		const { active } = this.state

		const empty = !input.value.from && !input.value.to

		let hint

		if (input.value.from && input.value.to) {
			hint = i18next.t('loc:Vyberte začiatok alebo koniec obdobia')
		} else if (input.value.from) {
			hint = i18next.t('loc:Vyberte koniec obdobia')
		} else {
			hint = i18next.t('loc:Vyberte začiatok obdobia')
		}

		return (
			<div ref={this.setWrapperRef} onClick={this.onFocus} className={cx('input-wrapper', { error: touched && error, empty })}>
				{showLabel && <label>{label}</label>}
				<input
					{...input}
					ref={this.setInputRef}
					placeholder={placeholder}
					value={this.formatValue(input.value)}
					onBlur={() => {}}
					onChange={() => {}}
					type={'text'}
					autoComplete={'off'}
					className={'range-picker-input'}
				/>
				<div className={cx('date-picker range-picker', { active })}>
					<div className={'close-button-wrapper'} onClick={this.closeCalendar}><img className={'close-button'} src={closeIcon} alt={'closeIcon'}/></div>
					<div className={'range-hint'}>
						{hint}
					</div>
					<Datetime
						closeOnSelect={true}
						closeOnTab={true}
						locale={'sk'}
						timeFormat={false}
						defaultValue={moment()}
						className={'date-picker-calendar'}
						input={false}
						onBlur={this.onBlur}
						onChange={this.onChange}
						onViewModeChange={this.onViewModeChange}
						renderYear={this.renderYear}
						renderMonth={this.renderMonth}
						renderDay={this.renderDay}
					/>
					<div className={'range-tips'}>
						<button className={'period-button'} onClick={this.lastTwelveMonths}>{i18next.t('loc:Posledných 12 mesiacov')}</button>
						<button className={'period-button'} onClick={this.thisQuarter}>{i18next.t('loc:Tento štvrťrok')}</button>
						<button className={'period-button'} onClick={this.thisYear}>{i18next.t('loc:Tento rok')}</button>
						<button className={'period-button'} onClick={this.clear}>{i18next.t('loc:Vyčistiť')}</button>
					</div>
				</div>
			</div>
		)
	}
}

export default DateRangePicker
