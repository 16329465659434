import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initialize, reset } from 'redux-form'
import { get } from 'lodash'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// components
import ContainerTypesForm from './components/ContainerTypesForm'

import Breadcrumbs from '../../components/Breadcrumb'

// utils
import { CONTAINER_TYPES, ENDPOINTS, FORMS, INFO } from '../../utils/enums'
import { getReq, patchReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'

// actions
import { loadMunicipalityWasteTypes } from '../../actions/municipalityActions'
import { statusPush } from '../../actions/statusActions'

// recources
import locale from '../../resources/locale'
import ContainerTypeUniversalForm from './components/ContainerTypeUniversalForm'

class ContainerTypePage extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		municipality: PropTypes.shape({
			id: PropTypes.number.isRequired
		}),
		match: PropTypes.object.isRequired,
		wasteTypes: PropTypes.arrayOf(PropTypes.object.isRequired),
		loadMunicipalityWasteTypes: PropTypes.func.isRequired
	}

	_mounted = false

	constructor(props) {
		super(props)
		this.state = {
			modal: false,
			containerType: {
				isLoading: false,
				isFailure: false,
				data: null
			}
		}
	}

	getContainerType = async () => {
		const { match } = this.props
		try {
			const { municipalityID, containerTypeID } = match.params

			if (!this._mounted) {
				return
			}
			this.setState({
				containerType: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			const res = await getReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerTypeID))
			if (!this._mounted) {
				return
			}
			this.setState({
				containerType: {
					data: get(res, 'data'),
					isLoading: false,
					isFailure: false
				}
			})
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				containerType: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidUpdate(prevProps) {
		const { containerType, initialValues } = this.state
		const { initialize, municipality } = this.props
		if (containerType.data && !containerType.isLoading && !initialValues) {
			const initValues = {
				name: get(containerType, 'data.name'),
				capacity: get(containerType, 'data.capacity'),
				type: get(containerType, 'data.type'),
				wasteType: get(containerType, 'data.wasteType.id'),
				initTimestamp: new Date()
			}
			// clear form
			initialize(FORMS.UPDATE_CONTAINER_TYPE_FORM, initValues, true)
			reset(FORMS.UPDATE_CONTAINER_TYPE_FORM)
			if (!this._mounted) {
				return
			}
			this.setState({
				initialValues: initValues
			})
		}

		if (prevProps.municipality?.id !== municipality?.id) {
			if (!municipality) {
				history.replace('/')
			} else {
				history.replace(locale.formatString(locale['path.municipality.containerTypes'], municipality.id))
			}
		}
	}

	componentDidMount() {
		this._mounted = true

		const { loadMunicipalityWasteTypes, wasteTypes, reset } = this.props

		// clear form
		reset(FORMS.UPDATE_CONTAINER_TYPE_FORM)

		// load data
		this.getContainerType()

		if (!wasteTypes) {
			loadMunicipalityWasteTypes()
		}
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	onDeleteContainerType = async (containerType) => {
		try {
			const municipalityID = this.props.municipality.id
			const endpoint = ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerType.id)
			await deleteReq(endpoint)
			this.props.pushStatus({
				type: INFO,
				msg: locale['page.containerType.delete.success']
			})

			// redirect on customer list
			if (this.props.match?.params?.municipalityID) {
				history.push(locale.formatString(locale['path.municipality.containerTypes'], this.props.match.params.municipalityID))
			} else {
				history.push(locale['path.lists.containerTypes'])
			}
		} catch (e) {
			// Error
		}
	}

	onSetActiveContainerType = async (containerType) => {
		try {
			const data = { isActive: !containerType.isActive }
			const municipalityID = this.props.municipality.id
			const endpoint = ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerType.id)

			await patchReq(endpoint, null, data)
			this.props.pushStatus({
				type: INFO,
				msg: locale['page.containerType.update.success']
			})

			this.getContainerType()
		} catch (e) {
			// Error
		}
	}

	submitHandler = async (values) => {
		const { match, pushStatus } = this.props
		try {
			const { municipalityID } = match.params
			const { containerTypeID } = match.params
			const body = {
				name: values.name,
				wasteTypeID: values.wasteType,
				capacity: values.capacity,
				type: values.type
			}
			await patchReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerTypeID), null, body)
			// refetch data
			pushStatus({
				type: INFO,
				msg: locale['page.containerType.update.success']
			})
			// redirect on pages types list
			history.push(locale.formatString(locale['path.municipality.containerTypes'], municipalityID))
		} catch (error) {
			// Error
		}
	}

	backBtnCallback = () => {
		const { match } = this.props
		const { municipalityID } = match.params
		// redirect on pages types list
		history.push(locale.formatString(locale['path.municipality.containerTypes'], municipalityID))
	}

	render() {
		const { match, municipality } = this.props
		const { municipalityID } = match.params
		const { containerType, initialValues } = this.state

		if (!containerType.data || containerType.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !containerType.isLoading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.containerType.delete.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const breadcrumbsItems = [{
			key: 'page.municipality',
			name: locale['page.municipality.settings.breadcrumbs']
		}, {
			key: 'page.municipality.detail',
			name: get(municipality, 'name')
		}, {
			key: 'page.containerTypes.breadcrumbs',
			name: locale['page.containerTypes.breadcrumbs'],
			link: locale.formatString(locale['path.municipality.containerTypes'], municipalityID)
		}, {
			key: 'page.containerType.breadcrumbs.detail',
			name: get(containerType, 'data.name')
		}]

		let form
		if (get(containerType, 'data.type') && get(containerType, 'data.type') === CONTAINER_TYPES.UNIVERSAL) {
			form = (
				<ContainerTypeUniversalForm cancelHandler={this.backBtnCallback} initialValues={containerType.data}/>
			)
		} else {
			form = (
				<ContainerTypesForm
					deleteBtnCallback={this.onDeleteContainerType}
					setActiveBtnCallback={this.onSetActiveContainerType}
					backBtnCallback={this.backBtnCallback}
					onSubmit={this.submitHandler}
					initialValues={initialValues}
					containerType={containerType}
				/>
			)
		}

		return (
			<>
				<Breadcrumbs
					items={breadcrumbsItems}
				/>
				{form}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	wasteTypes: get(state, 'selectedMunicipality.wasteTypes.data.types')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	loadMunicipalityWasteTypes: bindActionCreators(loadMunicipalityWasteTypes, dispatch),
	reset: bindActionCreators(reset, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContainerTypePage)
