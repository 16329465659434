import { MENU_ITEM_SELECTED, MENU_TOGGLE_SHOW, HIDE_MENU } from '../types/menu'

export function selectMenuItem(key) {
	return async (dispatch) => {
		dispatch({
			type: MENU_ITEM_SELECTED,
			payload: {
				key
			}
		})
	}
}

export function toggleMenuShown(visible) {
	return {
		type: MENU_TOGGLE_SHOW,
		payload: { visible }
	}
}

export function hideMenu() {
	return {
		type: HIDE_MENU
	}
}
