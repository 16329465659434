import React from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import i18next from 'i18next'

import { map } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'
import TableHead from '../../atoms/TableHead'
import WasteTypeTableRow from './WasteTypeTableRow'
import { EmptyTableState } from '../EmptyTableState'

class WasteTypesTable extends React.Component {
	static propTypes = {
		wasteTypes: PropTypes.arrayOf(PropTypes.object),
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		sortingDirection: PropTypes.string,
		sortingColumn: PropTypes.string
	}

	render() {
		const {
			wasteTypes,
			loading,
			onRemove,
			onDetail,
			sortingDirection,
			sortingColumn,
			onSort
		} = this.props

		const rows = map(wasteTypes, (wasteType, index) => (
			<WasteTypeTableRow
				key={index}
				wasteType={wasteType}
				onRemove={onRemove}
				onDetail={onDetail}
				countIndex={index}
			/>
		))

		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={sortingColumn === 'name'}
								title={i18next.t('loc:Názov odpadu')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'code'}
								isSorting={sortingColumn === 'code'}
								title={i18next.t('loc:Katalógové číslo')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'color'}
								isSorting={sortingColumn === 'color'}
								title={i18next.t('loc:Farba odpadu')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'coefficient'}
								isSorting={sortingColumn === 'coefficient'}
								title={i18next.t('loc:Váhový koeficient (kg/l)')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'collectionYardWeightLimit'}
								isSorting={sortingColumn === 'collectionYardWeightLimit'}
								title={i18next.t('loc:Limit na zberný dvor (kg)')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'isActiveMotivationModel'}
								isSorting={sortingColumn === 'isActiveMotivationModel'}
								title={i18next.t('loc:Motivačný model')}
								onSort={onSort}
								className={'text-center'}
								initDirection={sortingDirection}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && wasteTypes && !wasteTypes.length &&
				<EmptyTableState title={locale['page.waste.types.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.waste.types.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default WasteTypesTable
