import { deleteReq, getReq, postReq } from '../utils/request'
import { CollectionPlace, CollectionPlaceMapItem } from '../types/data'
import { ListResponse, Response } from '../types/api'
import { omitNilFilters } from '../utils/helpers'

export default {
	loadCollectionPlaces: (filters: object): ListResponse<{ collectionPlaces: CollectionPlace[] }> =>
		getReq('/api/v1/collection-places', omitNilFilters(filters)),

	removeCollectionPlace: (id: string | number, municipalityID: string | number) =>
		deleteReq(`/api/v0/municipalities/${municipalityID}/collection-places/${id}`, {}),

	loadStreets: (filters: object) =>
		getReq('/api/v1/collection-places/streets', filters),

	setCollectionPlaceChildren: (id: string | number, data: object) => postReq(`/api/v1/collection-places/${id}/children`, {}, data),
	assignContainers: (placeID: string | number, municipalityID: string | number, data: object) => postReq(`/api/v0/municipalities/${municipalityID}/collection-places/${placeID}/containers`, {}, data),
	loadMap: (filters: object): Response<{ collectionPlaces: CollectionPlaceMapItem[] }> => getReq('/api/admin/collection-places/map', filters)
}
