import React from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AuthRoute from '../AuthRoute'
import BaseRoute from '../BaseRoute'

import DashboardPage from '../../pages/dashboard/DashboardPage'
import NotFoundPage from '../../pages/NotFoundPage'

const Home = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<AuthRoute
				exact
				menuItem={'HOME.dashboard'}
				path={t('paths:HOME.dashboard.municipalityPath', { municipalityID: ':municipalityID' })}
				component={DashboardPage}
			/>
			{/* Other Routes */}
			<BaseRoute
				path={'/'}
				component={NotFoundPage}
			/>
		</Switch>
	)
}

export default Home
