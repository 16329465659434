import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import dayjs, { Dayjs } from 'dayjs'
import { FieldRenderProps } from 'react-final-form'

import SK from 'antd/lib/date-picker/locale/sk_SK'

import { DatePicker, Info, InputWrapper, Label } from './Styled'

type Props = FieldRenderProps<Dayjs | string> & {
	label?: string
	required?: boolean
	parser?: (date: Dayjs) => Dayjs
	disabled?: boolean
	showTime?: boolean | { defaultValue?: Dayjs }
	format?: string
	picker?: 'date' | 'month' | 'quarter' | 'year'
	dateRender?: (current: Dayjs, today: Dayjs) => React.ReactNode
	onPanelChange?: (value: Dayjs, mode: string) => void

	allowClear?: boolean
	placeholder?: string
}

const DateField = ({ label, required, parser, input, meta, ...rest }: Props) => {
	const [touched, setTouched] = useState(false)

	const error = meta.touched && meta.error
	const className = cx({ error })

	let value: Dayjs | null

	if (input.value) {
		value = dayjs(input.value)
	} else {
		value = null
	}

	useEffect(() => {
		if (meta.touched) {
			setTouched(true)
		}
	}, [meta.touched])

	const handleChange = (value: any) => {
		if (value) {
			let date = dayjs(value)
			if (parser) {
				date = parser(date)
			}
			input.onChange(date.toISOString())
		} else {
			input.onChange(null)
		}
		input.onBlur()
	}

	const handleOpen = (open: boolean) => {
		if (!open && !touched) {
			setTouched(true)
		}

		if (!open) {
			input.onBlur()
		}
	}

	return (
		<InputWrapper className={'small'}>
			{!!label &&
			<Label
				className={cx({ placeholder: !input.value })}
			>
				{label}{required ? '*' : ''}
			</Label>}
			<DatePicker
				{...input}
				{...rest}
				// @ts-ignore
				picker={rest.picker}
				onOpenChange={handleOpen}
				value={value}
				inputReadOnly={true}
				onChange={handleChange}
				locale={SK}
				className={className}
			/>
			<Info className={className}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default DateField
