import React from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PERMISSIONS } from '../../utils/enums'

import AuthRoute from '../AuthRoute'
import BaseRoute from '../BaseRoute'

import CollectionPlacesPage from '../../pages/collectionPlaces/CollectionPlacesPage'
import CollectionPlacePage from '../../pages/collectionPlaces/CollectionPlacePage'
import CollectionPlaceCreatePage from '../../pages/collectionPlaces/CollectionPlaceCreatePage'
import CustomersPage from '../../pages/customers/CustomersPage'
import CustomerPage from '../../pages/customers/CustomerPage'
import CustomerCodesPage from '../../pages/customerCodes/CustomerCodesPage'
import CalendarPage from '../../pages/calendar/CalendarPage'
import CalendarEventPage from '../../pages/calendar/CalendarEventPage'
import ContainersPage from '../../pages/containers/ContainersPage'
import NotFoundPage from '../../pages/NotFoundPage'
import ContainerPage from '../../pages/containers/ContainerPage'

const Lists = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<AuthRoute
				exact
				menuItem={'LISTS.collectionPlaces'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.collectionPlaces.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CollectionPlacesPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.collectionPlaces'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.collectionPlaces.create.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CollectionPlaceCreatePage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.collectionPlaces'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.collectionPlaces.detail.municipalityPath', { municipalityID: ':municipalityID', collectionPlaceID: ':collectionPlaceID' })}
				component={CollectionPlacePage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.customers'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.customers.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CustomersPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.customers'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.customers.detail.municipalityPath', { municipalityID: ':municipalityID', customerID: ':customerID' })}
				component={CustomerPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.customersCodes'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.customersCodes.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CustomerCodesPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.calendar'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.calendar.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CalendarPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.calendar'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.calendar.detail.municipalityPath', { municipalityID: ':municipalityID', eventID: ':eventID' })}
				component={CalendarEventPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.containers'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.containers.municipalityPath', { municipalityID: ':municipalityID' })}
				component={ContainersPage}
			/>
			<AuthRoute
				exact
				menuItem={'LISTS.containers'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:LISTS.containers.detail.municipalityPath', { municipalityID: ':municipalityID', containerID: ':containerID' })}
				component={ContainerPage}
			/>
			{/* Other Routes */}
			<BaseRoute
				path={'/'}
				component={NotFoundPage}
			/>
		</Switch>
	)
}

export default Lists
