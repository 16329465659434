import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm, unregisterField, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'

import { get } from 'lodash'
import locale from '../../resources/locale'

import { EVENT_TYPE, FORMS } from '../../utils/enums'
import EventFormFields from './EventFormFields'
import InfoFooter from '../InfoFooter'
import Button from '../buttons/Button'

class CalendarEventForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		deleteHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		wasteTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
		unregisterField: PropTypes.func.isRequired,
		type: PropTypes.string,
		event: PropTypes.object.isRequired
	}

	onTypeChange = (e, type) => {
		const { unregisterField } = this.props
		if (type !== EVENT_TYPE.COLLECTION_ROUND) {
			unregisterField(FORMS.CREATE_EVENT, 'wasteTypeID')
		}
	}

	removeEvent = (e) => {
		e.preventDefault()
		const { deleteHandler } = this.props
		deleteHandler()
	}

	render() {
		const { handleSubmit, submitHandler, cancelHandler, wasteTypes, type, event } = this.props

		return (
			<form onSubmit={handleSubmit(submitHandler)} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<EventFormFields
								isCreate={false}
								wasteTypes={wasteTypes}
								onTypeChange={this.onTypeChange}
								type={type}
							/>
							{event.streets &&
							<div className={'row'}>
								<div className={'col-md-12'}>
									<div className={'info-wrapper'}>
										<label>{'Ulice'}</label>
										<div>{event.streets}</div>
									</div>
								</div>
							</div>}
							<InfoFooter
								createdAt={get(event, 'createdAt')}
								creator={get(event, 'creator')}
								updatedAt={get(event, 'updatedAt')}
								editor={get(event, 'editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button className={'danger'} onClick={this.removeEvent}>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={cancelHandler}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const selector = formValueSelector(FORMS.CALENDAR_EVENT_FORM)

const mapStateToProps = (state) => ({
	type: selector(state, 'type'),
	wasteTypes: get(state, 'selectedMunicipality.wasteTypes.data.types', [])
})

const mapDispatchToProps = (dispatch) => ({
	unregisterField: bindActionCreators(unregisterField, dispatch)
})

export default reduxForm({
	form: FORMS.CALENDAR_EVENT_FORM,
	touchOnChange: true,
	destroyOnUnmount: true
})(connect(mapStateToProps, mapDispatchToProps)(CalendarEventForm))
