import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Col, Row } from 'antd'

import { statistics } from '../../../api'

import { getSelectedMunicipality } from '../../../redux/municipalities/selectors'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SpinLoading from '../../../components/SpinLoading'

import { HeadingFour, SubHeading, Text } from '../../../components/Typography'

const ContainersSummary = () => {
	const [t] = useTranslation()

	const municipality = useSelector(getSelectedMunicipality)

	const [summaryState, loadSummary] = useDataLoader(statistics.loadContainersCount)

	useEffect(() => {
		loadSummary({ municipalityID: municipality?.id })
	}, [loadSummary, municipality])

	if (summaryState.isLoading) {
		return (
			<SpinLoading height={'138px'}/>
		)
	}

	return (
		<Row justify={'space-between'} gutter={[8, 16]}>
			<Col span={24}>
				<SubHeading>{t('DashboardPage.containers')}</SubHeading>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers.total || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					{t('DashboardPage.totalCount')}
				</Text>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers['110'] || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					110 l
				</Text>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers['120'] || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					120 l
				</Text>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers['240'] || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					240 l
				</Text>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers['1100'] || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					1100 l
				</Text>
			</Col>
			<Col xl={4} lg={8} md={8} sm={8}>
				<HeadingFour>
					{summaryState.data?.containers.other || '-'}
				</HeadingFour>
				<Text className={'grey'}>
					{t('DashboardPage.otherCapacity')}
				</Text>
			</Col>
		</Row>
	)
}

export default React.memo(ContainersSummary)
