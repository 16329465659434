import i18next from 'i18next'
import dayjs from 'dayjs'
import slugify from 'slugify'
import { SorterResult } from 'antd/es/table/interface'

import {
	every,
	isNil,
	join,
	omitBy,
	isEmpty,
	get,
	times,
	includes,
	isString,
	replace,
	isNumber,
	round
} from 'lodash'

import {
	CUSTOMER_LEGAL_FORM, DUPLICITY_HANDLE, DUPLICITY_OVERRIDE_INTERVAL, MUNICIPALITY_TYPE, PERMISSIONS
} from './enums'

import { Customer, OrderDirection } from '../types/data'
import { MESSAGE_TYPE } from '../types/enums'
import { formatAddress } from './utils'

export const getPermissions = (permissions: PERMISSIONS) => {
	switch (permissions) {
		case PERMISSIONS.ADMINISTRATOR:
			return i18next.t('PERMISSIONS.ADMINISTRATOR')
		case PERMISSIONS.MUNICIPALITY_ADMIN:
			return i18next.t('PERMISSIONS.MUNICIPALITY_ADMIN')
		case PERMISSIONS.MUNICIPALITY_WORKER:
			return i18next.t('PERMISSIONS.MUNICIPALITY_WORKER')
		case PERMISSIONS.COLLECTION_WORKER:
			return i18next.t('PERMISSIONS.COLLECTION_WORKER')
		case PERMISSIONS.COLLECTION_YARD_WORKER:
			return i18next.t('PERMISSIONS.COLLECTION_YARD_WORKER')
		default:
			return i18next.t('PERMISSIONS.NONE')
	}
}

export const getMunicipalityType = (type: MUNICIPALITY_TYPE) => {
	switch (type) {
		case MUNICIPALITY_TYPE.CITY:
			return i18next.t('MUNICIPALITY_TYPE.CITY')
		case MUNICIPALITY_TYPE.REGION:
			return i18next.t('MUNICIPALITY_TYPE.REGION')
		case MUNICIPALITY_TYPE.CITY_PART:
			return i18next.t('MUNICIPALITY_TYPE.CITY_PART')
		case MUNICIPALITY_TYPE.WASTE_COMPANY:
			return i18next.t('MUNICIPALITY_TYPE.WASTE_COMPANY')
		default:
			return ''
	}
}

export const getDuplicityHandle = (value: DUPLICITY_HANDLE) => {
	switch (value) {
		case DUPLICITY_HANDLE.IGNORE:
			return i18next.t('DUPLICITY_HANDLE.IGNORE')
		case DUPLICITY_HANDLE.PROCESS:
			return i18next.t('DUPLICITY_HANDLE.PROCESS')
		case DUPLICITY_HANDLE.OVERRIDE:
			return i18next.t('DUPLICITY_HANDLE.OVERRIDE')
		case DUPLICITY_HANDLE.MERGE:
			return i18next.t('DUPLICITY_HANDLE.MERGE')
		default:
			return ''
	}
}

export const getDuplicityOverrideInterval = (value: DUPLICITY_OVERRIDE_INTERVAL) => {
	switch (value) {
		case DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS:
			return i18next.t('DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS')
		case DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS:
			return i18next.t('DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS')
		case DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY:
			return i18next.t('DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY')
		default:
			return ''
	}
}

export const getDuplicityOverrideIntervalNumber = (value: DUPLICITY_OVERRIDE_INTERVAL) => {
	switch (value) {
		case DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS:
			return 2
		case DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS:
			return 4
		case DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY:
			return 24
		default:
			return 2
	}
}

export const getDuplicityOverrideIntervalFromNumber = (value: number) => {
	switch (value) {
		case 2:
			return DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS
		case 4:
			return DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS
		case 24:
			return DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY
		default:
			return DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS
	}
}

export const objectHasNoValues = (object: object): boolean => Object.values(object).length
	? every(object, (value) => value === null || value === undefined || value === '')
	: true

export const formatCustomer = (customer: Customer) => {
	if (!customer) {
		return '-'
	}
	if (customer.isAnonymous) {
		return i18next.t('common.anonymousCustomer', { id: customer.id })
	}

	const items = []
	if (customer.legalForm === CUSTOMER_LEGAL_FORM.INDIVIDUAL) {
		if (customer.titleBefore) {
			items.push(customer.titleBefore)
		}
		if (customer.name) {
			items.push(customer.name)
		}
		if (customer.surname) {
			items.push(customer.surname)
		}
		if (customer.titleAfter) {
			items.push(customer.titleAfter)
		}

		const name = join(items, ' ')

		let result = name
		if (customer.street) {
			const address = formatAddress(customer)
			result = `${name}, ${address}`
		}
		return result
	}
	if (customer.companyName) {
		items.push(customer.companyName)
	}
	return join(items, ', ')
}

export const omitNilFilters = (filters: object) => omitBy(filters, (item) => {
	if (typeof item === 'number') {
		return false
	}

	return isNil(item) || isEmpty(item) || item === ''
})

export const omitNotDefinedKeys = <T extends object>(object: T, keys: string[]) => omitBy(object, (val, key) => !includes(keys, key))

export const getMessages = (response: any, defaultMessage?: string, type: MESSAGE_TYPE = MESSAGE_TYPE.ERROR) => {
	const messages = get(response, 'data.messages')
	if (messages) {
		return { messages }
	}
	return {
		messages: [{
			type,
			message: defaultMessage || i18next.t('errors.genericError')
		}]
	}
}

/**
 * @return Array [order, direction]
 * Return normalized order and direction from ANT table
 * @param sorter
 */
export const getOrderFromSorter = <RecordType extends object>(sorter: SorterResult<RecordType> | SorterResult<RecordType>[]): [string | undefined, OrderDirection] => Array.isArray(sorter)
	? [
		typeof sorter[0].column?.key === 'number' ? `${sorter[0].column?.key}` : sorter[0].column?.key,
		sorter[0].order || 'ascend'
	]
	: [
		typeof sorter.column?.key === 'number' ? `${sorter.column?.key}` : sorter.column?.key,
		sorter.order || 'ascend'
	]

const firstYear = 2019

export const createYearsList = () => {
	const currentYear = dayjs().year()

	return times(currentYear - firstYear + 1, (index) => firstYear + index)
}

export const createMonthList = () => {
	const firstMonth = dayjs(`${firstYear}-01`, 'YYYY-MM')
	const count = dayjs().diff(firstMonth, 'months') + 1

	return times(count, (index) => firstMonth.add(index, 'month').format('YYYY-MM'))
}

export const monthFormat = 'YYYY-MM'

export const fromStringToFloat = (string: string | number | null | undefined): number | null => {
	let result
	if (string && isString(string)) {
		result = parseFloat(replace(string, ',', '.').replace(' ', ''))
	} else if (string) {
		result = Number(string)
	} else {
		result = null
	}

	return result
}

/**
 * Returns null - e.g. input was cleared
 *
 * Returns NaN - e.g. We use distinguishNaN and input value is "asdf"
 */
export const transformNumberFieldValue = (rawValue: number | string | undefined | null, min?: number, max?: number, precision?: number, notNullValue?: boolean, distinguishNaN?: boolean) => {
	let result = null
	const value = typeof rawValue === 'string' ? fromStringToFloat(rawValue) : rawValue
	if (!value && notNullValue) {
		result = min
	}
	if (isNumber(value) && isFinite(value)) {
		if (isNumber(min) && value < min) {
			result = min
		} else if (isNumber(max) && value > max) {
			result = max
		} else if (isNumber(min) && isNumber(max) && value >= min && value <= max) {
			result = value
		}
	} else if (distinguishNaN && Number.isNaN(value)) {
		result = NaN
	}

	if (isFinite(result as any) && isNumber(precision)) {
		result = round(result as number, precision)
	}

	return result
}

export const createSlug = (value: string, separator = '-', lower = true) => {
	if (value) {
		return slugify(value, {
			replacement: separator,
			lower
		})
	}
	return ''
}
