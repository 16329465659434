import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createForm } from 'final-form'

import { pick } from 'lodash'

import { Col, Row } from 'antd'

import { getSelectedMunicipality } from '../../redux/municipalities/selectors'
import { useDataLoader } from '../../hooks/useDataLoader'
import { municipalities } from '../../api'
import { MODAL } from '../../utils/enums'
import { statusPush } from '../../actions/statusActions'

import { defaultModalState, MotivationModel } from '../../types/data'

import { getDuplicityOverrideIntervalFromNumber, getDuplicityOverrideIntervalNumber } from '../../utils/helpers'

import { Box, BoxContent, BoxFooter, BoxHeader, BoxTitle } from '../../layouts/Box'

import Breadcrumb from '../../components/Breadcrumb'
import MunicipalityForm, { FormValues } from './components/MunicipalityForm'
import Button from '../../components/buttons/Button'
import SpinLoading from '../../components/SpinLoading'
import MotivationModelModal, { FormValues as ModelFormValues } from './components/MotivationModelModal'
import MotivationModelsTable from './components/MotivationModelsTable'
import ImportModal from '../../components/ImportModal'

type Props = RouteComponentProps<{ municipalityID: string }>

const MunicipalityPage = ({ match }: Props) => {
	const [t] = useTranslation()
	const municipality = useSelector(getSelectedMunicipality)
	const dispatch = useDispatch()

	const [submitting, setSubmitting] = useState(false)
	const [modal, setModal] = useState(defaultModalState)
	const [municipalityState, loadMunicipality] = useDataLoader(municipalities.loadMunicipality)

	const breadcrumbs = useMemo(() => {
		if (!municipality || match.params.municipalityID !== `${municipality?.id}`) {
			return [{
				key: 'path.administration',
				name: t('paths:ADMINISTRATION.title')
			}, {
				key: 'path.administration.municipalities',
				name: t('paths:ADMINISTRATION.municipalities.title'),
				link: t('paths:ADMINISTRATION.municipalities.path')
			}, {
				key: 'path.administration.municipalities.detail',
				name: municipalityState.data ? municipalityState.data.name : t('loading')
			}]
		}

		return [{
			key: 'path.municipalities',
			name: t('paths:MUNICIPALITY.title')
		}, {
			key: 'path.administration.municipalities.detail',
			name: municipalityState.data ? municipalityState.data.name : t('loading')
		}]
	}, [match.params.municipalityID, municipality, municipalityState.data, t])

	const handleSubmit = useCallback(async (values: FormValues) => {
		try {
			setSubmitting(true)

			const submitData = {
				...pick(values, 'name', 'sepanID', 'zip', 'type', 'useUniversalContainerType', 'allowPublicStatistics', 'useAutomaticCollectionRounds', 'useGeneralCollectionRound', 'useQuantity', 'useUnsuitableConditions', 'duplicityHandle', 'note'),
				duplicityOverrideInterval: getDuplicityOverrideIntervalFromNumber(values.duplicityOverrideInterval),
				attachmentID: values.attachment?.id,
				coverID: values.logo?.id,
				parentID: values.parent?.value,
				webServices: {
					ALTARES: values.altares?.username ? values.altares : null,
					GX: values.gx?.username ? values.gx : null,
					SEPAN: values.sepan?.username ? values.sepan : null,
					RADIUM: values.radium?.username ? values.radium : null
				}
			}

			const { data } = await municipalities.updateMunicipality(match.params.municipalityID, submitData)

			dispatch(statusPush(data.messages[0]))

			return Promise.resolve()
		} catch (e) {
			return Promise.resolve({})
		} finally {
			setSubmitting(false)
		}
	}, [dispatch, match.params.municipalityID])

	const handleSubmitModel = useCallback(async (values: ModelFormValues) => {
		if (municipalityState.data) {
			try {
				let response
				if (values.id) {
					response = await municipalities.updateMotivationModel(values.id, municipalityState.data.id, values)
				} else {
					response = await municipalities.createMotivationModel(municipalityState.data.id, values)
				}

				const { data } = response

				dispatch(statusPush(data.messages[0]))

				setModal(defaultModalState)
				loadMunicipality(match.params.municipalityID)

				return Promise.resolve()
			} catch (e) {
				return Promise.resolve({})
			}
		}

		return Promise.resolve()
	}, [dispatch, loadMunicipality, match.params.municipalityID, municipalityState.data])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit
	}), [handleSubmit])

	const modelForm = useMemo(() => createForm({
		onSubmit: handleSubmitModel
	}), [handleSubmitModel])

	const handleUpdateModel = useCallback((model: MotivationModel) => {
		const initData = pick(model, 'id', 'type', 'baseFee', 'referencePeriod', 'validFrom', 'validTo', 'collectionsCountMonthly', 'maxDiscount', 'plannedVolume', 'weightDiscount')

		modelForm.restart(initData)
		setModal({ key: MODAL.MOTIVATION_MODEL_MODAL, data: null })
	}, [modelForm])

	const handleDuplicateModel = useCallback((model: MotivationModel) => {
		const initData = pick(model, 'type', 'baseFee', 'referencePeriod', 'validFrom', 'validTo', 'collectionsCountMonthly', 'maxDiscount', 'plannedVolume', 'weightDiscount')

		modelForm.restart(initData)
		setModal({ key: MODAL.MOTIVATION_MODEL_MODAL, data: null })
	}, [modelForm])

	const handleCloseModal = useCallback(() => setModal(defaultModalState), [])

	const handleCreateModel = useCallback(() => {
		modelForm.restart({
			type: null,
			baseFee: null,
			referencePeriod: null,
			validFrom: null,
			validTo: null,
			collectionsCountMonthly: null,
			maxDiscount: null,
			plannedVolume: null,
			weightDiscount: null
		})
		setModal({ key: MODAL.MOTIVATION_MODEL_MODAL, data: null })
	}, [modelForm])

	useEffect(() => {
		loadMunicipality(match.params.municipalityID)
	}, [loadMunicipality, match.params.municipalityID])

	useEffect(() => {
		const { data } = municipalityState

		if (data) {
			form.restart({
				logo: data.logo ? { id: data.logo.id, url: data.logo.url } : null,
				name: data.name,
				zip: data.zip,
				type: data.type,
				useUniversalContainerType: data.useUniversalContainerType,
				allowPublicStatistics: data.allowPublicStatistics,
				useAutomaticCollectionRounds: data.useAutomaticCollectionRounds,
				useGeneralCollectionRound: data.useGeneralCollectionRound,
				useQuantity: data.useQuantity,
				useUnsuitableConditions: data.useUnsuitableConditions,
				note: data.note,
				attachment: data.attachment,
				duplicityHandle: data.duplicityHandle,
				duplicityOverrideInterval: getDuplicityOverrideIntervalNumber(data.duplicityOverrideInterval),
				sepanID: data?.sepanID,
				altares: data?.webServices?.ALTARES || null,
				sepan: data?.webServices?.SEPAN || null,
				gx: data?.webServices?.GX || null,
				radium: data?.webServices?.RADIUM || null,
				parent: data?.parent ? { value: data.parent.id, label: data.parent.name } : null
			})
		}
	}, [form, municipalityState])

	let content

	if (municipalityState.isLoading) {
		content = <SpinLoading/>
	}

	if (municipalityState.data) {
		content = (
			<MunicipalityForm
				detail={municipalityState.data}
				form={form}
			/>
		)
	}

	return (
		<>
			<Box>
				<BoxHeader>
					<Breadcrumb items={breadcrumbs}/>
					<BoxTitle>{t('MunicipalityPage.title')}</BoxTitle>
				</BoxHeader>
				<BoxContent className={'padded'}>
					<Row gutter={[16, 16]} justify={'end'} style={{ marginBottom: 32 }}>
						<Col>
							<Button
								onClick={() => setModal({ key: MODAL.IMPORT_CUSTOMERS, data: null })}
								className={'secondary'}
							>
								{t('MunicipalityPage.importCustomers')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => setModal({ key: MODAL.IMPORT_MUNICIPALITY, data: null })}
								className={'secondary'}
							>
								{t('MunicipalityPage.importMunicipality')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => setModal({ key: MODAL.IMPORT_CONTAINERS, data: null })}
								className={'secondary'}
							>
								{t('MunicipalityPage.importContainers')}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={() => setModal({ key: MODAL.IMPORT_CALENDAR, data: null })}
								className={'secondary'}
							>
								{t('MunicipalityPage.importCalendar')}
							</Button>
						</Col>
					</Row>
					{content}
				</BoxContent>
				<BoxFooter>
					<Row justify={'end'}>
						<Col>
							<Button
								disabled={submitting}
								loading={submitting}
								onClick={() => form.submit()}
							>
								{t('common.save')}
							</Button>
						</Col>
					</Row>
				</BoxFooter>
			</Box>
			{!!municipalityState.data &&
			<Box>
				<BoxHeader>
					<BoxTitle>{t('MunicipalityPage.motivationsModels')}</BoxTitle>
				</BoxHeader>
				<BoxContent>
					<MotivationModelsTable
						onDuplicate={handleDuplicateModel}
						onUpdate={handleUpdateModel}
						motivationModels={municipalityState.data.motivationModels}
					/>
				</BoxContent>
				<BoxFooter>
					<Row justify={'end'}>
						<Col>
							<Button
								onClick={handleCreateModel}
							>
								{t('MunicipalityPage.addMotivationModel')}
							</Button>
						</Col>
					</Row>
				</BoxFooter>
			</Box>}
			<MotivationModelModal
				form={modelForm}
				visible={modal.key === MODAL.MOTIVATION_MODEL_MODAL}
				onClose={handleCloseModal}
			/>
			{!!municipalityState.data &&
			<ImportModal
				municipality={municipalityState.data}
				modalKey={modal.key}
				onClose={handleCloseModal}
			/>}
		</>
	)
}

export default MunicipalityPage
