import React from 'react'
import { useTranslation } from 'react-i18next'
import { Switch } from 'react-router-dom'

import { PERMISSIONS } from '../utils/enums'

import AuthRoute from './AuthRoute'
import BaseRoute from './BaseRoute'

import UsersPage from '../pages/users/UsersPage'
import UserPage from '../pages/users/UserPage'
import MunicipalitiesPage from '../pages/municipalities/MunicipalitiesPage'
import MunicipalityPage from '../pages/municipalities/MunicipalityPage'
import DevicesPage from '../pages/devices/DevicesPage'
import DevicePage from '../pages/devices/DevicePage'
import CodePortionsPage from '../pages/codePortions/CodePortionsPage'
import CodePortionPage from '../pages/codePortions/CodePortionPage'
import ToolsPage from '../pages/Tools/ToolsPage'
import NotFoundPage from '../pages/NotFoundPage'

const Administration = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.users'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.users.path')}
				component={UsersPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.users'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.users.detail.path', { userID: ':userID' })}
				component={UserPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.municipalities'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.municipalities.path')}
				component={MunicipalitiesPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.municipalities'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.municipalities.detail.path', { municipalityID: ':municipalityID' })}
				component={MunicipalityPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.devices'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.devices.path')}
				component={DevicesPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.devices'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.devices.detail.path', { deviceID: ':deviceID' })}
				component={DevicePage}
			/>

			<AuthRoute
				exact
				menuItem={'ADMINISTRATION.codePortions'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.codePortions.path')}
				component={CodePortionsPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.codePortions'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.codePortions.detail.path', { codePortionID: ':codePortionID' })}
				component={CodePortionPage}
			/>

			<AuthRoute
				exact
				pathKey={'ADMINISTRATION'}
				menuItem={'ADMINISTRATION.tools'}
				permissions={[PERMISSIONS.ADMINISTRATOR]}
				path={t('paths:ADMINISTRATION.tools.path')}
				component={ToolsPage}
			/>

			{/* Other Routes */}
			<BaseRoute
				path={'/'}
				component={NotFoundPage}
			/>
		</Switch>
	)
}

export default Administration
