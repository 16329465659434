import { deleteReq, getReq, patchReq, postReq } from '../utils/request'
import { ListResponse } from '../types/api'
import { Collection } from '../types/data'

export default {
	loadCollections: (filters: object): ListResponse<{ collections: Collection[] }> => getReq('/api/v0/collections', filters),
	createCollection: (municipalityID: number | string, data: object) => postReq(`/api/v0/municipalities/${municipalityID}/collections`, {}, data),
	updateCollections: (municipalityID: number | string, data: object) => patchReq(`/api/v0/municipalities/${municipalityID}/collections`, {}, data),
	updateContainerType: (data: object) => patchReq('/api/v0/collections/containerTypes', {}, data),
	deleteCollection: (id: number | string, municipalityID: number | string) => deleteReq(`/api/v0/municipalities/${municipalityID}/collections/${id}`, {}),
	deleteCollections: (municipalityID: number | string, data: object) => deleteReq(`/api/v0/municipalities/${municipalityID}/collections`, {}, data)

}
