import { SELECTED_FILTERS_ORDERS_CHANGED, RESET_ALL_FILTERS } from '../types/selectedFiltersOrders'
import { initState } from '../reducers/selectedFiltersOrdersReducer'

export const filtersOrdersChangedCreator = (selector, filtersOrder) => ({
	type: SELECTED_FILTERS_ORDERS_CHANGED,
	payload: {
		selector,
		selectedFiltersSorters: filtersOrder
	}
})

export const resetAllFilters = () => ({ type: RESET_ALL_FILTERS })

export const resetFiltersOrders = (selector) => (dispatch) => {
	dispatch(filtersOrdersChangedCreator(selector, initState[selector]))
}

export const selectedFiltersOrdersChanged = (selector, filtersOrder) => (dispatch) => {
	dispatch(filtersOrdersChangedCreator(selector, filtersOrder))
}
