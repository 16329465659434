import { SAVE_FORM_DATA, REMOVE_FORM_DATA } from '../types/forms'

export function saveFormData(form, data, municipality) {
	return {
		type: SAVE_FORM_DATA,
		payload: {
			form,
			data,
			municipality: `${municipality.id}`
		}
	}
}

export function removeSavedData(form, municipality) {
	return {
		type: REMOVE_FORM_DATA,
		payload: {
			form,
			municipality: `${municipality.id}`
		}
	}
}
