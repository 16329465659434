import React from 'react'
import { useTranslation } from 'react-i18next'
import { intersection, some, concat } from 'lodash'

import { MunicipalityBase, Profile } from '../../../types/data'
import { MUNICIPALITY_TYPE, PERMISSIONS } from '../../../utils/enums'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import Home from '../../../resources/images/ic-home.svg'
import Graph from '../../../resources/images/ic-graph.svg'
import GraphCol from '../../../resources/images/ic-graph-column.svg'
import Board from '../../../resources/images/ic-board.svg'
import Weight from '../../../resources/images/ic-weight.svg'
import Container from '../../../resources/images/ic-container.svg'
import File from '../../../resources/images/ic-file.svg'
import MenuLink from './MenuLink'

type Props = {
	authUser?: Profile | null
	municipality: MunicipalityBase | null
}

const permissions = [PERMISSIONS.ADMINISTRATOR, PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]

const Statistics = ({ authUser, municipality, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin && some(authUser?.municipalities, (item) => !intersection(item.permissions, permissions).length)) {
		return null
	}

	let items = [
		<StyledItem key={'STATISTICS.municipalitySorting'}>
			<Icon icon={Graph}/>
			<MenuLink basePath={'paths:STATISTICS.municipalitySorting'} municipality={municipality}>
				{t('paths:STATISTICS.municipalitySorting.title')}
			</MenuLink>
		</StyledItem>
	]

	if (municipality?.type !== MUNICIPALITY_TYPE.WASTE_COMPANY) {
		items = concat(items, [
			<StyledItem key={'STATISTICS.collectionPlacesSorting'}>
				<Icon icon={Home}/>
				<MenuLink basePath={'paths:STATISTICS.collectionPlacesSorting'} municipality={municipality}>
					{t('paths:STATISTICS.collectionPlacesSorting.title')}
				</MenuLink>
			</StyledItem>,
			<StyledItem key={'STATISTICS.customersSorting'}>
				<Icon icon={GraphCol}/>
				<MenuLink basePath={'paths:STATISTICS.customersSorting'} municipality={municipality}>
					{t('paths:STATISTICS.customersSorting.title')}
				</MenuLink>
			</StyledItem>,
			<StyledItem key={'STATISTICS.motivation'}>
				<Icon icon={Board}/>
				<MenuLink basePath={'paths:STATISTICS.motivation'} municipality={municipality}>
					{t('paths:STATISTICS.motivation.title')}
				</MenuLink>
			</StyledItem>
		])
	}

	items = concat(items, [
		<StyledItem key={'STATISTICS.quantitativeCollections'}>
			<Icon icon={File}/>
			<MenuLink basePath={'paths:STATISTICS.quantitativeCollections'} municipality={municipality}>
				{t('paths:STATISTICS.quantitativeCollections.title')}
			</MenuLink>
		</StyledItem>,
		<StyledItem key={'STATISTICS.quantity'}>
			<Icon icon={Weight}/>
			<MenuLink basePath={'paths:STATISTICS.quantity'} municipality={municipality}>
				{t('paths:STATISTICS.quantity.title')}
			</MenuLink>
		</StyledItem>,
		<StyledItem key={'STATISTICS.uncollected'}>
			<Icon icon={Container}/>
			<MenuLink basePath={'paths:STATISTICS.uncollected'} municipality={municipality}>
				{t('paths:STATISTICS.uncollected.title')}
			</MenuLink>
		</StyledItem>
	])

	return (
		<StyledItemGroup
			{...props}
			key={'STATISTICS'}
			title={t('paths:STATISTICS.title')}
		>
			{items}
		</StyledItemGroup>
	)
}

export default React.memo(Statistics)
