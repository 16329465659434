import React, { useMemo, useState } from 'react'
import i18next from 'i18next'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Col, Row } from 'antd'

import { MODAL } from '../utils/enums'

import { bulkCollections, collections } from '../api'

import { statusPush } from '../actions/statusActions'

import Button from './buttons/Button'

import { Modal, ModalContent, ModalFooter, ModalHead } from './Modal'
import { TextLarge } from './Typography'

const getAction = (key?: MODAL | null): { api: Function, title: string, message: string } | null => {
	switch (key) {
		case MODAL.DELETE_COLLECTION:
			return {
				api: collections.deleteCollection,
				title: i18next.t('DeleteModal.deleteCollection.title'),
				message: i18next.t('DeleteModal.deleteCollection.message')
			}
		case MODAL.DELETE_COLLECTIONS:
			return {
				api: collections.deleteCollections,
				title: i18next.t('DeleteModal.deleteCollections.title'),
				message: i18next.t('DeleteModal.deleteCollections.message')
			}
		case MODAL.DELETE_BULK_COLLECTION:
			return {
				api: bulkCollections.deleteBulkCollection,
				title: i18next.t('DeleteModal.deleteBulkCollection.title'),
				message: i18next.t('DeleteModal.deleteBulkCollection.message')
			}
		default:
			return null
	}
}

type Props = {
	modalKey?: MODAL | null
	data?: object
	params: (string | number)[]
	onClose: (reload: boolean) => void
}

const DeleteModal = ({ onClose, modalKey, params }: Props) => {
	const [t] = useTranslation()

	const [submitting, setSubmitting] = useState(false)
	const dispatch = useDispatch()
	const action = useMemo(() => getAction(modalKey), [modalKey])

	const handleSubmit = async () => {
		if (action) {
			try {
				setSubmitting(true)

				const { data } = await action.api(...params)

				dispatch(statusPush(data.messages[0]))

				onClose(true)
			} catch (e) {
				// Error
			} finally {
				setSubmitting(false)
			}
		}
	}

	return (
		<Modal
			onCancel={() => onClose(false)}
			visible={!!action}
			footer={null}
		>
			<ModalHead>
				{action?.title}
			</ModalHead>
			<ModalContent className={'padded'}>
				<TextLarge>{action?.message}</TextLarge>
			</ModalContent>
			<ModalFooter>
				<Row justify={'end'} gutter={[16, 8]}>
					<Col>
						<Button
							className={'secondary'}
							onClick={(e) => {
								e.preventDefault()
								onClose(false)
							}}
						>
							{t('common.close')}
						</Button>
					</Col>
					<Col>
						<Button
							loading={submitting}
							disabled={submitting}
							onClick={handleSubmit}
						>
							{t('common.submit')}
						</Button>
					</Col>
				</Row>
			</ModalFooter>
		</Modal>
	)
}

export default React.memo(DeleteModal)
