import { getReq } from '../utils/request'
import { ListResponse, Response } from '../types/api'
import { ContainersSummary, CustomerCollection, CustomerDetail, Customer, SortingRate } from '../types/data'
import { omitNilFilters } from '../utils/helpers'

export default {
	loadCustomers: (filters: object = {}): ListResponse<{ customers: Customer[] }> => getReq('/api/admin/customers', omitNilFilters(filters)),
	loadCustomer: (id: string | number): Response<CustomerDetail> => getReq(`/api/admin/customers/${id}`, {}),
	loadCustomerSorting: (id: string | number, filters: object): Response<SortingRate> => getReq(`/api/v1/customers/${id}/sorting-rate`, filters),
	loadCustomerCollections: (id: string | number, filters: object): ListResponse<{ collections: CustomerCollection[] }> => getReq(`/api/v1/customers/${id}/collections`, filters),
	loadContainersSummary: (id: string | number): Response<ContainersSummary> => getReq(`/api/v1/customers/${id}/containers-summary`, {})
}
