import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { isEqual, map } from 'lodash'
import CalendarDayView from './CalendarDayView'

class CalendarWeek extends React.Component {
	static propTypes = {
		events: PropTypes.object.isRequired,
		days: PropTypes.arrayOf(PropTypes.shape({
			day: PropTypes.number.isRequired,
			month: PropTypes.number.isRequired,
			year: PropTypes.number.isRequired,
			week: PropTypes.number.isRequired
		})),
		selectedDay: PropTypes.shape({
			day: PropTypes.number.isRequired,
			month: PropTypes.number.isRequired,
			year: PropTypes.number.isRequired
		}).isRequired,
		onCreate: PropTypes.func.isRequired,
		month: PropTypes.number.isRequired,
		onSelect: PropTypes.func.isRequired,
		defaultLabels: PropTypes.bool
	}

	getDays = () => {
		const { onSelect, onCreate, days, selectedDay, month, events, defaultLabels } = this.props
		const now = moment()
		const today = {
			day: now.date(),
			month: now.month(),
			year: now.year(),
			week: now.isoWeek()
		}

		return map(days, (day, index) => {
			const isSelected = isEqual(day, selectedDay)
			const isToday = isEqual(day, today)
			const isAnotherMonth = month !== day.month
			const dayEvents = events[`${day.day}.${day.month}`] || []

			return (
				<CalendarDayView
					key={index}
					day={day}
					isSelected={isSelected}
					isToday={isToday}
					isAnotherMonth={isAnotherMonth}
					events={dayEvents}
					onCreate={onCreate}
					onSelect={onSelect}
					defaultLabels={defaultLabels}
				/>
			)
		})
	}

	render() {
		return (
			<tr>
				{this.getDays()}
			</tr>
		)
	}
}

export default CalendarWeek
