import { get, set, forEach } from 'lodash'

import locale from '../../../resources/locale'
import { CODE_PORTION_TYPES } from '../../../utils/enums'

export default (values) => {
	const errors = {}

	if (!values.type) {
		errors.type = locale['error.validation.REQUIRED']
	}

	if (values.type === CODE_PORTION_TYPES.ANONYMOUS) {
		const oneTime = get(values, 'anonymousCodes.oneTime')
		const fixed = get(values, 'anonymousCodes.fixed')
		if (!oneTime && !fixed) {
			set(errors, 'anonymousCodes.oneTime', locale['error.validation.AT_LEAST_ONE_NUMBER'])
			set(errors, 'anonymousCodes.fixed', locale['error.validation.AT_LEAST_ONE_NUMBER'])
		}
	} else if (values.type === CODE_PORTION_TYPES.ADDRESS) {
		if (!get(values, 'addressCodes.municipalityID')) {
			set(errors, 'addressCodes.municipalityID', locale['error.validation.REQUIRED'])
		}

		if (!get(values, 'addressCodes.containerTypes.length')) {
			set(errors, 'type', locale['error.validation.AT_LEAST_ONE_CONTAINER_TYPE'])
		}

		if (!get(values, 'addressCodes.collectionPlaceTypes.length')) {
			set(errors, 'addressCodes.collectionPlaceTypes', locale['error.validation.REQUIRED'])
		}

		forEach(get(values, 'addressCodes.containerTypes'), (item, index) => {
			if (!item.id) {
				set(errors, `addressCodes.containerTypes[${index}].id`, locale['error.validation.REQUIRED'])
			}

			if (!item.count) {
				set(errors, `addressCodes.containerTypes[${index}].count`, locale.formatString(locale['error.validation.GT'], 0))
			}
		})
	}

	return errors
}
