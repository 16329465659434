export const WARN = 'WARN'
export const INFO = 'INFO'
export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'

export const CRASH_ERROR_TYPES = {
	NETWORK: 'NETWORK',
	APPLICATION: 'APPLICATION'
}

export enum HOST {
	TEST = 'testServer',
	PRODUCTION = 'productionServer'
}

export enum NAMESPACE {
	PATHS = 'paths',
	LOC = 'loc'
}

export const KEYS = {
	ENTER: 'Enter',
	TAB: 'Tab'
}

export enum AUDIENCE {
	API = 'api',
	FORGOT_PASSWORD = 'api-forgot-password',
	INVITE = 'invitation'
}

export enum PERIOD {
	MONTH = 'MONTH',
	QUARTER = 'QUARTER'
}

export const ANONYMOUS = 'ANONYMOUS'
export const CUSTOMER_MAX_MEMBERS_COUNT = 20

export const MIN_NUMBER = -99999999
export const MAX_NUMBER = 999999999

export const DATE_TIME_FORMAT = 'D.M.YYYY HH:mm'

export const ENDPOINTS = {
	LOGS: '/api/v0/logs',
	LOGOUT: '/api/v0/logout',
	USER: (id: string | number) => `/api/v0/users/${id}`,
	USER_MUNICIPALITY: (municipalityID: string | number, userID: string | number) => `/api/v0/municipalities/${municipalityID}/users/${userID}`,
	USERS_MUNICIPALITY: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/users`,
	USER_RESEND: (id: string | number) => `/api/v0/users/${id}/resend`,
	USERS: '/api/v0/users',
	PROFILE: '/api/v0/profile',
	MUNICIPALITIES: '/api/v0/municipalities',
	MUNICIPALITY: (id: string | number) => `/api/v0/municipalities/${id}`,
	UPLOAD: '/api/v0/upload',
	MOTIVATION_MODEL: (municipalityID: string | number, id: string | number) => `/api/v0/municipalities/${municipalityID}/motivation-models/${id}`,
	WASTE_TYPES: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/waste-types`,
	WASTE_TYPE: (municipalityID: string | number, wasteTypeID: string | number) => `/api/v0/municipalities/${municipalityID}/waste-types/${wasteTypeID}`,
	WASTE_TYPES_OPTIONS: '/api/v0/waste-types',
	MUNICIPALITY_WASTE_TYPES: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/waste-types`,

	MUNICIPALITY_CONTAINER_TYPES: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/container-types`,
	CONTAINER_TYPES: '/api/v0/container-types',

	MUNICIPALITY_CONTAINER_TYPE: (municipalityID: string | number, containerTypeID: string | number) => `/api/v0/municipalities/${municipalityID}/container-types/${containerTypeID}`,
	MUNICIPALITY_COLLECTION_PLACE: (municipalityID: string | number, placeID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-places/${placeID}`,
	MUNICIPALITY_COLLECTION_PLACES: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-places`,
	COLLECTION_PLACE: (placeID: string | number) => `/api/v0/collection-places/${placeID}`,
	COLLECTION_PLACE_CUSTOMERS: (municipalityID: string | number, placeID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-places/${placeID}/customers`,
	COLLECTION_PLACE_CUSTOMER: (municipalityID: string | number, placeID: string | number, customerID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-places/${placeID}/customers/${customerID}`,
	COLLECTION_PLACE_CONTAINERS: (municipalityID: string | number, placeID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-places/${placeID}/containers`,
	MUNICIPALITY_CUSTOMERS: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/customers`,
	MUNICIPALITY_CUSTOMER: (municipalityID: string | number, customerID: string | number) => `/api/v0/municipalities/${municipalityID}/customers/${customerID}`,
	CUSTOMERS: '/api/v0/customers',
	CONTAINERS: '/api/v0/containers',
	CONTAINERS_EXPORT: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/containers/export`,
	MUNICIPALITY_CONTAINERS: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/containers`,
	MUNICIPALITIES_CONTAINERS: '/api/v0/municipalities/containers',
	MUNICIPALITY_CONTAINERS_COUNT: '/api/v0/municipalities/containers/count',
	MUNICIPALITY_CONTAINER: (municipalityID: string | number, containerID: string | number) => `/api/v0/municipalities/${municipalityID}/containers/${containerID}`,
	CONTAINERS_DEACTIVATE: '/api/admin/containers/deactivate',
	CONTAINERS_CHANGE_TYPE: '/api/admin/containers/change-type',
	CUSTOMER: (customerID: string | number) => `/api/v0/customers/${customerID}`,
	COLLECTION_PLACES: '/api/v0/collection-places',
	COLLECTION_ROUNDS: '/api/v0/collection-rounds',
	COLLECTION_ROUND: (roundID: string | number) => `/api/v0/collection-rounds/${roundID}`,
	MUNICIPALITY_COLLECTION_ROUND: (municipalityID: string | number, roundID: string | number) => `/api/v0/municipalities/${municipalityID}/collection-rounds/${roundID}`,
	COLLECTIONS: '/api/v0/collections',
	SYNC_COLLECTIONS: '/api/v0/collections/sync',
	COLLECTION: (collectionID: string | number) => `/api/v0/collections/${collectionID}`,
	MUNICIPALITY_COLLECTIONS: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/collections`,
	MUNICIPALITY_COLLECTION: (municipalityID: string | number, collectionID: string | number) => `/api/v0/municipalities/${municipalityID}/collections/${collectionID}`,
	CHANGE_CONTAINER_TYPES: '/api/v0/collections/containerTypes',
	ATTACHMENT: (id: string | number) => `/api/v0/attachments/${id}`,
	CHANGE_PASSWORD: '/api/v0/change-password',
	CALENDAR: '/api/admin/calendar',
	CALENDAR_EVENTS: '/api/admin/calendar/events',
	CALENDAR_EVENT: (eventID: string | number) => `/api/admin/calendar/events/${eventID}`,
	MUNICIPALITY_CALENDAR_EVENTS: (municipalityID: string | number) => `/api/admin/municipalities/${municipalityID}/calendar/events`,
	MUNICIPALITY_CALENDAR_EVENT: (municipalityID: string | number, eventID: string | number) => `/api/admin/municipalities/${municipalityID}/calendar/events/${eventID}`,
	MUNICIPALITY_DEVICES: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/devices`,
	MUNICIPALITY_DEVICE: (municipalityID: string | number, deviceID: string | number) => `/api/v0/municipalities/${municipalityID}/devices/${deviceID}`,
	DEVICE: (deviceID: string | number) => `/api/v0/devices/${deviceID}`,
	DEVICES: '/api/v0/devices',
	COLLECTION_YARDS: '/api/v0/collection-yards',
	COLLECTION_YARDS_EXPORT: '/api/v0/collection-yards/export',
	COLLECTION_YARD: (collectionYardID: string | number) => `/api/v0/collection-yards/${collectionYardID}`,
	MUNICIPALITIES_SORTING: '/api/v0/statistics/municipalities/sorting',
	MUNICIPALITIES_SORTING_EXPORT: '/api/v0/statistics/municipalities/sorting/export',
	MOTIVATION: '/api/v0/statistics/motivation',
	CUSTOMERS_SORTING: '/api/v0/statistics/customers/sorting',
	COLLECTION_PLACES_SORTING: '/api/v0/statistics/collection-places/sorting',
	QUANTITY: '/api/v0/statistics/quantity',
	WEIGHT: '/api/v0/statistics/weight',
	UNCOLLECTED: '/api/v0/statistics/uncollected',
	UNCOLLECTED_EXPORT: '/api/v0/statistics/uncollected/export',

	WASTE_TYPES_MUNICIPALITY: '/api/v0/municipalities-waste-types',
	STATISTICS_COUNTS: '/api/v0/statistics/counts',
	STATISTICS_CUMULATIVE_WEIGHT: '/api/v0/statistics/cumulative-weight',
	STATISTICS_TOP_HOUSEHOLDS: '/api/v0/statistics/top-households',
	STATISTICS_PROGRESS_COLLECTIONS: '/api/v0/statistics/progress-collections',
	STATISTICS_SORTING_RATE: '/api/v0/statistics/sorting-rate',
	STATISTICS_COLLECTION_PLACES_MAP_DATA: '/api/v0/statistics/collection-places-map-data',
	STATISTICS_QUANTITATIVE_COLLECTIONS: '/api/v0/statistics/quantitativeCollection',
	STATISTICS_QUANTITATIVE_COLLECTIONS_EXPORT: '/api/v0/statistics/quantitativeCollection/export',

	MUNICIPALITIES_EXPORT: '/api/v0/municipalities/export',
	CUSTOMERS_SORTING_EXPORT: '/api/v0/statistics/customers/sorting/export',
	COLLECTION_PLACES_SORTING_EXPORT: '/api/v0/statistics/collection-places/sorting/export',
	CONTAINER_TYPES_EXPORT: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/container-types/export`,
	WASTE_TYPES_EXPORT: (municipalityID: string | number) => `/api/v0/municipalities/${municipalityID}/waste-types/export`,
	USERS_EXPORT: '/api/v0/users/export',
	CUSTOMERS_EXPORT: '/api/v0/customers/export',
	COLLECTIONS_EXPORT: '/api/v0/collections/export',
	COLLECTION_ROUNDS_EXPORT: '/api/v0/collection-rounds/export',
	COLLECTION_PLACES_EXPORT: '/api/v1/collection-places/export',
	CALENDAR_EXPORT: '/api/admin/calendar/events/export',
	MOTIVATION_EXPORT: '/api/v0/statistics/motivation/export',

	RFID_IMPORT: '/api/v0/containers/import/rfid',

	ADDRESSES: '/api/v0/address',

	CODE_PORTIONS: '/api/v0/code-portions',
	EXPORT_CODE_PORTION: (id: string | number) => `/api/v1/code-portions/${id}/export`,
	CODE_PORTION: (id: string | number) => `/api/v0/code-portions/${id}`,
	CODE_PORTION_CODES: (id: string | number) => `/api/v0/code-portions/${id}/codes`,

	CUSTOMER_CODES: '/api/v0/customers-codes',
	CUSTOMER_CODES_EXPORT: '/api/v0/customers-codes/export',
	CUSTOMER_CODE: (id: string | number) => `/api/v0/customers-codes/${id}`,
	CUSTOMER_CODES_ASSIGN: (id: string | number) => `/api/v0/municipalities/${id}/customers-codes`,
	CUSTOMER_CODES_IMPORT: (id: string | number) => `/api/v0/municipalities/${id}/customers-codes/import`,

	CONTAINERS_TRANSFER: '/api/admin/containers/transfer',
	CONTAINER_TRANSFER: (municipalityID: string | number, id: string | number) => `/api/v0/municipalities/${municipalityID}/containers/${id}/transfer`,

	UNDELETE: '/api/v0/admin/undelete'
}

export const CODE_PORTION_STATES = {
	CREATED: 'CREATED',
	PRINTED: 'PRINTED'
}

export const CODE_PORTION_TYPES = {
	ADDRESS: 'ADDRESS',
	ANONYMOUS: 'ANONYMOUS'
}

export enum MOTIVATION_MODEL {
	FEE = 'FEE',
	COMBINED_FEE = 'COMBINED_FEE',
	DISCOUNT = 'DISCOUNT',
	QUANTITY = 'QUANTITY',
	NONE = 'NONE'
}

export enum REFERENCE_PERIOD {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
	HALFYEARLY = 'HALFYEARLY',
	YEARLY = 'YEARLY',
	OTHER = 'OTHER'
}

export const STATISTICS_PERIODS = {
	MONTH: 'MONTH',
	QUARTER: 'QUARTER',
	HALF_YEAR: 'HALF_YEAR',
	YEAR: 'YEAR'
}

export enum PERMISSIONS {
	ADMINISTRATOR = 'ADMINISTRATOR',
	MUNICIPALITY_ADMIN = 'MUNICIPALITY_ADMIN',
	MUNICIPALITY_WORKER = 'MUNICIPALITY_WORKER',
	COLLECTION_WORKER = 'COLLECTION_WORKER',
	COLLECTION_YARD_WORKER = 'COLLECTION_YARD_WORKER',
	MUNICIPALITY_DUSTMAN = 'MUNICIPALITY_DUSTMAN',
	NONE = 'NONE'
}

export enum BULK_COLLECTIONS_TAB_PANE {
	STATS = 'STATS',
	COLLECTIONS = 'COLLECTIONS'
}

export enum DIALOG {}

export enum MODAL {
	IMPORT_COLLECTIONS = 'IMPORT_COLLECTIONS',
	IMPORT_CONTAINERS = 'IMPORT_CONTAINERS',
	IMPORT_CALENDAR = 'IMPORT_CALENDAR',
	IMPORT_MUNICIPALITY = 'IMPORT_MUNICIPALITY',
	IMPORT_CUSTOMERS = 'IMPORT_CUSTOMERS',
	IMPORT_RFID = 'IMPORT_RFID',
	IMPORT_MIGRATION_PZO = 'IMPORT_MIGRATION_PZO',

	DELETE_COLLECTION = 'DELETE_COLLECTION',
	DELETE_COLLECTIONS = 'DELETE_COLLECTIONS',
	DELETE_BULK_COLLECTION = 'DELETE_BULK_COLLECTION',

	EXPIRE_PREVIOUS_CUSTOMER = 'EXPIRE_PREVIOUS_CUSTOMER',
	SET_INACTIVE_CONTAINER = 'SET_INACTIVE_CONTAINER',
	SET_INACTIVE_CONTAINERS_BULK = 'SET_INACTIVE_CONTAINERS_BULK',
	SET_ACTIVE_CONTAINER = 'SET_ACTIVE_CONTAINER',
	DELETE_CONTAINER = 'DELETE_CONTAINER',
	DELETE_EVENT = 'DELETE_EVENT',
	CREATE_USER = 'CREATE_USER',
	SET_ACTIVE_USER = 'SET_ACTIVE_USER',
	REMOVE_USER = 'REMOVE_USER',
	SET_ACTIVE_MUNICIPALITY = 'SET_ACTIVE_MUNICIPALITY',
	CREATE_MUNICIPALITY = 'CREATE_MUNICIPALITY',
	CREATE_WASTE_TYPE = 'CREATE_WASTE_TYPE',
	REMOVE_WASTE_TYPE = 'REMOVE_WASTE_TYPE',
	CREATE_CONTAINER_TYPE = 'CREATE_CONTAINER_TYPE',
	IMAGE_CROPPER_MODAL = 'IMAGE_CROPPER_MODAL',
	MOTIVATION_MODEL_MODAL = 'MOTIVATION_MODEL_MODAL',
	CREATE_COLLECTION_PLACE = 'CREATE_COLLECTION_PLACE',
	SET_COLLECTION_PLACE_CUSTOMER = 'SET_COLLECTION_PLACE_CUSTOMER',
	SET_COLLECTION_PLACE_CONTAINER = 'SET_COLLECTION_PLACE_CONTAINER',
	CREATE_CUSTOMER = 'CREATE_CUSTOMER',
	SET_INACTIVE_CUSTOMER = 'SET_INACTIVE_CUSTOMER',
	SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER',
	DELETE_CUSTOMER = 'DELETE_CUSTOMER',
	REMOVE_COLLECTION_PLACE = 'REMOVE_COLLECTION_PLACE',
	SET_ACTIVE_CONTAINER_TYPE = 'SET_ACTIVE_CONTAINER_TYPE',
	REMOVE_COLLECTION_ROUND = 'REMOVE_COLLECTION_ROUND',
	REMOVE_COLLECTION = 'REMOVE_COLLECTION',
	SYNC_COLLECTIONS = 'SYNC_COLLECTIONS',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	CREATE_EVENT = 'CREATE_EVENT',
	REMOVE_EVENT = 'REMOVE_EVENT',
	CREATE_DEVICE = 'CREATE_DEVICE',
	DELETE_DEVICE = 'DELETE_DEVICE',
	CHANGE_CONTAINER_TYPE = 'CHANGE_CONTAINER_TYPE',
	CHANGE_CONTAINERS_TYPE_BULK = 'CHANGE_CONTAINERS_TYPE_BULK',
	COLLECTION_SET_COLLECTION_ROUND = 'COLLECTION_SET_COLLECTION_ROUND',
	CREATE_COLLECTION = 'CREATE_COLLECTION',
	CREATE_BULK_COLLECTION = 'CREATE_BULK_COLLECTION',
	CREATE_CODE_PORTION = 'CREATE_CODE_PORTION',
	PRINT_CODE_PORTION = 'PRINT_CODE_PORTION',
	SUBMIT_CODE_PORTION = 'SUBMIT_CODE_PORTION',
	DELETE_CODE_PORTION = 'DELETE_CODE_PORTION',
	TRANSFER_CONTAINER = 'TRANSFER_CONTAINER',
	TRANSFER_CONTAINERS_BULK = 'TRANSFER_CONTAINERS_BULK',

	NEST_PARENT = 'NEST_PARENT',

	ASSIGN_CUSTOMERS_CODES = 'ASSIGN_CUSTOMERS_CODES',
	IMPORT_CUSTOMERS_CODES = 'IMPORT_CUSTOMERS_CODES',
	ASSIGN_CUSTOMERS_CODE = 'ASSIGN_CUSTOMERS_CODE',
	EXPIRE_CUSTOMERS_CODE = 'EXPIRE_CUSTOMERS_CODE',
	CANCEL_CUSTOMERS_CODE_EXPIRATION = 'CANCEL_CUSTOMERS_CODE_EXPIRATION',

	CODE_PORTION_EXPORT_DIALOG = 'CODE_PORTION_EXPORT_DIALOG',
	MERGE_COLLECTION_ROUNDS = 'MERGE_COLLECTION_ROUNDS'
}

export enum MUNICIPALITY_TYPE {
	CITY = 'CITY',
	CITY_PART = 'CITY_PART',
	REGION = 'REGION',
	WASTE_COMPANY = 'WASTE_COMPANY'
}

export enum WASTE_TYPE {
	DOOR_TO_DOOR = 'DOOR_TO_DOOR',
	COLLECTION_YARD = 'COLLECTION_YARD',
	COLLECTION_PLACE = 'COLLECTION_PLACE'
}

export enum WEIGHT_CALCULATE_TYPE {
	COEFFICIENT = 'COEFFICIENT',
	COLLECTION_ROUND_WEIGHT = 'COLLECTION_ROUND_WEIGHT'
}

export const FORMS = {
	USER_FORM: 'USER_FORM',
	COLLECTION_FORM: 'COLLECTION_FORM',
	CREATE_COLLECTION_ROUND: 'CREATE_COLLECTION_ROUND',
	USER_PROFILE_FORM: 'USER_PROFILE_FORM',
	LOGIN_FORM: 'LOGIN_FORM',
	COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	RESET_PASSWORD: 'RESET_PASSWORD',
	MUNICIPALITY_FORM: 'MUNICIPALITY_FORM',
	MOTIVATION_MODEL_FORM: 'MOTIVATION_MODEL_FORM',
	WASTE_TYPE_FORM: 'WASTE_TYPE_FORM',
	CONTAINER_TYPE_FORM: 'CONTAINER_TYPE_FORM',
	CREATE_CONTAINER_TYPE: 'CONTAINER_TYPE_FORM',
	CREATE_MUNICIPALITY: 'CREATE_MUNICIPALITY',
	CREATE_USER: 'CREATE_USER',
	CREATE_WASTE_TYPE: 'CREATE_WASTE_TYPE',
	UPDATE_CONTAINER_TYPE_FORM: 'UPDATE_CONTAINER_TYPE_FORM',
	CREATE_CONTAINER_TYPE_FORM: 'CREATE_CONTAINER_TYPE_FORM',
	COLLECTION_PLACE_FORM: 'COLLECTION_PLACE_FORM',
	SET_COLLECTION_PLACE_CUSTOMER_FORM: 'SET_COLLECTION_PLACE_CUSTOMER_FORM',
	CREATE_CUSTOMER_FORM: 'CREATE_CUSTOMER_FORM',
	UPDATE_CUSTOMER_FORM: 'UPDATE_CUSTOMER_FORM',
	CREATE_COLLECTION_PLACE: 'CREATE_COLLECTION_PLACE',
	DEACTIVATE_CUSTOMER: 'DEACTIVATE_CUSTOMER',
	COLLECTION_ROUND_FORM: 'COLLECTION_ROUND_FORM',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	CREATE_EVENT: 'CREATE_EVENT',
	CALENDAR_EVENT_FORM: 'CALENDAR_EVENT_FORM',
	CREATE_DEVICE_FORM: 'CREATE_DEVICE_FORM',
	UPDATE_DEVICE_FORM: 'UPDATE_DEVICE_FORM',
	COLLECTION_SET_COLLECTION_ROUND: 'COLLECTION_SET_COLLECTION_ROUND',
	CREATE_COLLECTION: 'CREATE_COLLECTION',
	CREATE_BULK_COLLECTION: 'CREATE_BULK_COLLECTION',
	UPDATE_COLLECTION_YARD_FORM: 'UPDATE_COLLECTION_YARD_FORM',
	CHANGE_CONTAINER_TYPE: 'CHANGE_CONTAINER_TYPE',
	CREATE_CODE_PORTION: 'CREATE_CODE_PORTION',
	CONTAINERS_TRANSFER: 'CONTAINERS_TRANSFER',
	UNDELETE: 'UNDELETE',

	ASSIGN_CUSTOMERS_CODES: 'ASSIGN_CUSTOMERS_CODES',
	ASSIGN_CUSTOMER_CODES: 'ASSIGN_CUSTOMER_CODES',
	EXPIRE_CUSTOMER_CODE: 'EXPIRE_CUSTOMER_CODE',
	TRANSFER_CONTAINER: 'TRANSFER_CONTAINER',
	BULK_COLLECTIONS_PAGE_SCAN_DATETIME_FILTER: 'BULK_COLLECTIONS_PAGE_SCAN_DATETIME_FILTER',
	BULK_COLLECTIONS_PAGE_WEIGHT_FILTER: 'BULK_COLLECTIONS_PAGE_WEIGHT_FILTER'
}

export enum FILTER {
	COLLECTIONS_FILTERS = 'COLLECTIONS_FILTERS',
	COLLECTION_ROUNDS_FILTERS = 'COLLECTION_ROUNDS_FILTERS',
	COLLECTION_ROUND_MAP_FILTERS = 'COLLECTION_ROUND_MAP_FILTERS',
	QUANTITATIVE_COLLECTIONS_FILTERS = 'QUANTITATIVE_COLLECTIONS_FILTERS',
	CUSTOMER_CODES_FILTERS = 'CUSTOMER_CODES_FILTERS',
	CUSTOMER_CODES_TABLE_FILTERS = 'CUSTOMER_CODES_TABLE_FILTERS',
	COLLECTION_PLACES_FILTERS = 'COLLECTION_PLACES_FILTERS',
	CONTAINER_COLLECTION_FILTERS = 'CONTAINER_COLLECTION_FILTERS'
}

export enum FILE_TYPE {
	COVER = 'COVER',
	ATTACHMENT = 'ATTACHMENT'
}

export const COLORS_HEX = {
	BLUE: '1E87F0',
	RED: 'F03E41',
	YELLOW: 'FAD500',
	GREEN: 'AFCA0B',
	BROWN: 'AB6E50',
	BLACK: '000000',
	GREY: 'DDDDDD',
	ORANGE: 'F29200'
}

export const COLORS = {
	BLUE: 'BLUE',
	RED: 'RED',
	YELLOW: 'YELLOW',
	GREEN: 'GREEN',
	BROWN: 'BROWN',
	BLACK: 'BLACK',
	GREY: 'GREY',
	ORANGE: 'ORANGE'
}

export const COLOR_PALETTE = {
	'1E87F0': COLORS.BLUE,
	F03E41: COLORS.RED,
	FAD500: COLORS.YELLOW,
	AFCA0B: COLORS.GREEN,
	AB6E50: COLORS.BROWN,
	'000000': COLORS.BLACK,
	DDDDDD: COLORS.GREY,
	F29200: COLORS.ORANGE
}

export const CONTAINER_TYPES = {
	PERMANENT: 'PERMANENT',
	ONE_TIME: 'ONE_TIME',
	UNIVERSAL: 'UNIVERSAL',
	COLLECTION_YARD: 'COLLECTION_YARD'
}

export const FILTER_ORDER_SELECTORS = {
	CONTAINERS_PAGE: 'CONTAINERS_PAGE',
	CONTAINER_TYPES_PAGE: 'CONTAINER_TYPES_PAGE',
	CUSTOMERS_PAGE: 'CUSTOMERS_PAGE',
	COLLECTION_PLACES_PAGE: 'COLLECTION_PLACES_PAGE',
	USERS_PAGE: 'USERS_PAGE',
	MUNICIPALITIES_PAGE: 'MUNICIPALITIES_PAGE',
	WASTE_TYPES_PAGE: 'WASTE_TYPES_PAGE',
	COLLECTION_PLACE_PAGE: 'COLLECTION_PLACE_PAGE',
	COLLECTION_ROUNDS_PAGE: 'COLLECTION_ROUNDS_PAGE',
	COLLECTION_ROUND_PAGE: 'COLLECTION_ROUND_PAGE',
	COLLECTION_YARDS_PAGE: 'COLLECTION_YARDS_PAGE',
	CALENDAR_PAGE: 'CALENDAR_PAGE',
	DEVICES_PAGE: 'DEVICES_PAGE',
	COLLECTIONS_PAGE: 'COLLECTIONS_PAGE',
	MUNICIPALITIES_SORTING_PAGE: 'MUNICIPALITIES_SORTING_PAGE',
	CUSTOMERS_SORTING_PAGE: 'CUSTOMERS_SORTING_PAGE',
	COLLECTION_PLACES_SORTING_PAGE: 'COLLECTION_PLACES_SORTING_PAGE',
	QUANTITY_PAGE: 'QUANTITY_PAGE',
	WEIGHT_PAGE: 'WEIGHT_PAGE',
	MOTIVATION_PAGE: 'MOTIVATION_PAGE',
	UNCOLLECTED_PAGE: 'UNCOLLECTED_PAGE',
	DASHBOARD: 'DASHBOARD',
	CODE_PORTIONS_PAGE: 'CODE_PORTIONS_PAGE',
	CODE_PORTION_PAGE: 'CODE_PORTION_PAGE'
}

export enum SORTING_GROUP {
	ZERO = 'ZERO',
	ONE_TO_TEN = 'ONE_TO_TEN',
	ELEVEN_TO_TWENTY = 'ELEVEN_TO_TWENTY',
	TWENTY_ONE_TO_THIRTY = 'TWENTY_ONE_TO_THIRTY',
	THIRTY_ONE_TO_FIFTY = 'THIRTY_ONE_TO_FIFTY',
	FIFTY_ONE_AND_MORE = 'FIFTY_ONE_AND_MORE'
}

export enum SORTING_PERIOD {
	MONTH = 'MONTH',
	QUARTER = 'QUARTER',
	HALF_YEAR = 'HALF_YEAR',
	YEAR = 'YEAR'
}

export enum TIME_SPAN {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY'
}

export enum COLLECTION_PLACE_TYPE {
	FAMILY_HOUSE = 'FAMILY_HOUSE',
	FLAT_HOUSE = 'FLAT_HOUSE',
	FLAT = 'FLAT',
	COLLECTION_YARD = 'COLLECTION_YARD',
	NEST = 'NEST',
	OTHER = 'OTHER',
	COTTAGE = 'COTTAGE'
}

export enum COLLECTION_ROUND_TYPE {
	STANDARD = 'STANDARD',
	GENERAL = 'GENERAL'
}

export enum CUSTOMER_LEGAL_FORM {
	LEGAL = 'LEGAL',
	INDIVIDUAL = 'INDIVIDUAL'
}

export enum EVENT_TYPE {
	COLLECTION_ROUND = 'COLLECTION_ROUND',
	OTHER = 'OTHER'
}

export const DEVICE_TYPES = {
	TERMINAL: 'TERMINAL',
	DISCOVERY: 'DISCOVERY',
	ARCOBEAT: 'ARCOBEAT',
	ARCO40EVO: 'ARCO40EVO',
	EXTERNAL_DEVICE: 'EXTERNAL_DEVICE',
	GX_SOLUTIONS: 'GX_SOLUTIONS',
	SEPAN_ELTE_GPS: 'ELTE_GPS',
	RADIUM_DEVICE: 'RADIUM_DEVICE'
}

export enum UNSUITABLE_CONDITION {
	INCORRECT_MATERIAL = 'INCORRECT_MATERIAL',
	CONTAMINATED = 'CONTAMINATED',
	UNCOMPRESSED = 'UNCOMPRESSED',
	DAMAGED_BIN = 'DAMAGED_BIN',
	MARKER100 = 'MARKER100',
	MARKER200 = 'MARKER200',
	MARKER300 = 'MARKER300',
	MARKER400 = 'MARKER400'
}

export const QUANTITY_CHART_SECTIONS = {
	LESS_THEN_QUARTER: 'LESS_THEN_QUARTER',
	LESS_THEN_HALF: 'LESS_THEN_HALF',
	MORE_THEN_HALF: 'MORE_THEN_HALF',
	LESS_THEN_FULL: 'LESS_THEN_FULL',
	FULL_AND_MORE: 'FULL_AND_MORE',
	NOT_COLLECTED: 'NOT_COLLECTED'
}

export const QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR = {
	LESS_THEN_QUARTER: '#e44048',
	LESS_THEN_HALF: '#f0f6ce',
	MORE_THEN_HALF: '#e2eb92',
	LESS_THEN_FULL: '#b4c941',
	FULL_AND_MORE: '#44c943',
	NOT_COLLECTED: '#d8d8d8'
}

export enum SORTING_CHART_COLOR {
	ZERO = '#BD10E0',
	ONE_TO_TEN = '#bc2525',
	ELEVEN_TO_TWENTY = '#b16d21',
	TWENTY_ONE_TO_THIRTY = '#1f8487',
	THIRTY_ONE_TO_FIFTY = '#3a66a8',
	FIFTY_ONE_AND_MORE = '#488f20'
}

export const DEFAULT_WASTE_TYPE_COLOR = 'FAFAFA'

export const SORTING_CHART_ELEMENTS = [
	'ZERO', 'ONE_TO_TEN', 'ELEVEN_TO_TWENTY', 'TWENTY_ONE_TO_THIRTY', 'THIRTY_ONE_TO_FIFTY', 'FIFTY_ONE_AND_MORE'
]

export const INVALID_STATE = {
	CONTAINER: 'CONTAINER',
	DUPLICITY: 'DUPLICITY',
	WASTE_TYPE: 'WASTE_TYPE',
	EXPIRED_CONTAINER: 'EXPIRED_CONTAINER'
}

export const FILTER_TYPE = {
	FULL_TEXT: 'FULL_TEXT',
	SELECT: 'SELECT',
	DATE_RANGE: 'DATE_RANGE',
	NUMBER_RANGE: 'NUMBER_RANGE'
}

export const FILTER_POSITION = {
	RIGHT: 'RIGHT',
	LEFT: 'LEFT'
}

export enum UNDELETE_TABLE {
	COLLECTIONS = 'collections',
	COLLECTION_PLACES = 'collectionPlaces',
	CUSTOMERS = 'customers',
	CONTAINER_TYPES = 'containerTypes',
	MUNICIPALITY_WASTE_TYPES = 'municipalityWasteTypes',
	CONTAINERS = 'containers',
	COLLECTION_ROUNDS = 'collectionRounds',
	EVENTS = 'events'
}

export enum SETTINGS_KEY {
	AVERAGE_WASTES = 'AVERAGE_WASTES'
}

export enum PIN_TYPE {
	VALID = 'VALID',
	INVALID = 'INVALID',
	UNCOLLECTED = 'UNCOLLECTED'
}

export enum DUPLICITY_HANDLE {
	IGNORE = 'IGNORE',
	OVERRIDE = 'OVERRIDE',
	PROCESS = 'PROCESS',
	MERGE = 'MERGE'
}

export enum DUPLICITY_OVERRIDE_INTERVAL {
	TWO_HOURS = 'TWO_HOURS',
	FOUR_HOURS = 'FOUR_HOURS',
	SAME_DAY = 'SAME_DAY'
}

export enum COLLECTION_ROUND_STATE {
	WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
	APPROVED = 'APPROVED'
}

export const FILTER_POSITIONS = Object.values(FILTER_POSITION)
export const INVALID_STATES = Object.values(INVALID_STATE)
export const FILTER_TYPES = Object.values(FILTER_TYPE)
