import React from 'react'

import {
	debounce,
	get,
	isEqual,
	head,
	find,
	last,
	trim
} from 'lodash'
import { reset, initialize, change } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQueryParams from 'react-query-params'
import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// atoms
import Pagination from '../../atoms/Pagination'
import SearchInputField from '../../atoms/SearchInputField'

// recources
import locale from '../../resources/locale'

// utils
import { MODAL, ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO, CUSTOMER_LEGAL_FORM } from '../../utils/enums'
import { getReq, postReq, patchReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'

// components
import CreateCustomerModal from '../../components/modals/CreateCustomer'
import CustomersTable from './components/CustomersTable'
import DeactivateModal from '../../components/modals/DeactivateModal'
import DialogModal from '../../components/modals/DialogModal'
import ExportButton from '../../components/buttons/ExportButton'
import Button from '../../components/buttons/Button'

// pages
import Breadcrumbs from '../../components/Breadcrumb'

// actions
import * as SelectedFiltersOrdersActions from '../../actions/selectedFiltersOrdersActions'
import * as MunicipalityActions from '../../actions/municipalityActions'
import { statusPush } from '../../actions/statusActions'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'address', 'legalForm', 'activeTo']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class CustomersPage extends ReactQueryParams {
	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			customers: {
				data: [],
				isLoading: true,
				isFailure: false
			},
			modalData: null
		}
	}

	_mounted = false

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersActions, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.limit:
			case queryParams.search:
				newFilterOrder = (value) ? `${value}` : null
				break
			default:
				break
		}

		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}
		this.setQueryParams({
			...filterOrder
		})

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		// fetch data for table
		this.getCustomers(filterOrder)
		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CUSTOMERS_PAGE, filterOrder)
	}

	searchCustomers = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	createHandler = async (values) => {
		const { selectedMunicipality, selectedFiltersOrders, dispatch, pushStatus } = this.props
		try {
			const body = {
				legalForm: values.legalForm,
				name: values.name,
				surname: values.surname,
				titleBefore: values.titleBefore,
				titleAfter: values.titleAfter,
				companyName: values.companyName,
				ico: values.ico,
				street: values.street,
				streetNumber: values.streetNumber,
				number: values.number,
				city: values.city,
				zip: values.zip
			}
			await postReq(ENDPOINTS.MUNICIPALITY_CUSTOMERS(get(selectedMunicipality, 'municipality.data.id')), null, body)

			pushStatus({
				type: INFO,
				msg: locale['page.customers.create.success']
			})
			this.dismissModal()

			dispatch(reset(FORMS.CREATE_CUSTOMER_FORM))
			this.getCustomers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	getCustomers = async (query) => {
		const { match } = this.props
		const { municipalityID } = match.params

		try {
			if (!this._mounted) {
				return
			}
			this.setState({
				customers: {
					data: [],
					isLoading: true,
					isFailure: false
				}
			})
			const res = await getReq(ENDPOINTS.CUSTOMERS, { ...query, municipalityID })
			if (!this._mounted) {
				return
			}

			const context = get(res, 'data.context', {})
			if (query.page > 1 && query.page > context.pages) {
				this.onChangeFilterOrder(queryParams.page, 1)
			} else {
				this.setState({
					customers: {
						data: get(res, 'data'),
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				customers: {
					data: [],
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidMount = async () => {
		this._mounted = true
		const { selectedFiltersOrdersActions, selectedFiltersOrders } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// loading data
		this.getCustomers(filtersOrder)

		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CUSTOMERS_PAGE, filtersOrder)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onCustomerDetail = (customerId) => {
		const { match } = this.props
		const { municipalityID } = match.params
		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.customers.detail'], municipalityID, customerId))
		} else {
			history.push(locale.formatString(locale['path.lists.customers.detail'], customerId))
		}
	}

	setActiveCustomer = async (values) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData } = this.state
			const customerID = modalData.id
			const { municipalityID } = modalData

			const body = {
				activeTo: get(values, 'activeTo', null)
			}

			await patchReq(ENDPOINTS.MUNICIPALITY_CUSTOMER(municipalityID, customerID), null, body)
			pushStatus({
				type: INFO,
				msg: locale['page.customers.update.success']
			})
			this.dismissModal()
			this.getCustomers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onDeleteCustomer = async () => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData } = this.state
			const customerID = modalData.id
			const { municipalityID } = modalData

			await deleteReq(ENDPOINTS.MUNICIPALITY_CUSTOMER(municipalityID, customerID))
			pushStatus({
				type: INFO,
				msg: locale['page.customers.remove.success']
			})
			this.dismissModal()
			this.getCustomers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	openCustomerForm = () => {
		const { initializeForm } = this.props
		const values = this.getInitialValues()
		initializeForm(FORMS.CREATE_CUSTOMER_FORM, values)
		this.openModal(MODAL.CREATE_CUSTOMER)()
	}

	getInitialValues = () => {
		const { selectedMunicipality } = this.props
		const municipality = get(selectedMunicipality, 'municipality.data', true)
		if (municipality) {
			return {
				legalForm: CUSTOMER_LEGAL_FORM.INDIVIDUAL,
				zip: municipality.zip,
				city: municipality.name
			}
		}
		return {}
	}

	breadcrumbsItems = () => {
		const { selectedMunicipality } = this.props
		const municipality = get(selectedMunicipality, 'municipality.data')
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.lists.breadcrumbs',
			name: locale['page.lists.breadcrumbs']
		}, {
			key: 'page.customers.breadcrumbs',
			name: locale['page.customers.breadcrumbs']
		}]
	}

	onSetActiveCustomer = (customer) => {
		if (customer.activeTo) {
			this.openModal(MODAL.SET_ACTIVE_CUSTOMER)(customer)
		} else {
			this.openModal(MODAL.SET_INACTIVE_CUSTOMER)(customer)
		}
	}

	onSelectAddress = (value) => {
		const { change } = this.props

		change(FORMS.CREATE_CUSTOMER_FORM, 'street', value.street)
		change(FORMS.CREATE_CUSTOMER_FORM, 'zip', value.zip)
		change(FORMS.CREATE_CUSTOMER_FORM, 'city', value.city)
	}

	render = () => {
		const { selectedFiltersOrders, match, municipality } = this.props
		const { customers, modal, modalData } = this.state
		const order = selectedFiltersOrders.order || ''
		const isSortingColumn = head(order.split(':'))
		const isSortingDirection = last(order.split(':'))
		const { municipalityID } = match.params

		// find picked customer from table row

		let customer
		let customerName = ''
		if (modal === MODAL.SET_ACTIVE_CUSTOMER || modal === MODAL.SET_INACTIVE_CUSTOMER || modal === MODAL.DELETE_CUSTOMER) {
			customer = find(get(customers, 'data.customers'), (customer) => customer.id === modalData.id)
			customerName = customer.isAnonymous
				? locale.formatString(locale['page.customers.anonymous'], customer.id)
				: get(customer, 'name')
		}

		const context = { ...selectedFiltersOrders }
		if (municipality) {
			context.municipalityID = municipality.id
		}

		return (
			<>
				<Breadcrumbs items={this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.customer.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={16} justify={'space-between'}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchCustomers, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton url={ENDPOINTS.CUSTOMERS_EXPORT} context={context}/>
								</Col>
								<Col>
									<Button
										disabled={!municipalityID}
										onClick={this.openCustomerForm}
									>
										<PlusOutlined/>
										<span>{locale['page.customers.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<CustomersTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onDelete={this.openModal(MODAL.DELETE_CUSTOMER)}
										onSetActive={this.onSetActiveCustomer}
										onDetail={this.onCustomerDetail}
										customers={customers.data.customers}
										loading={customers.isLoading}
										isSortingColumn={isSortingColumn}
										isSortingDirection={isSortingDirection}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(customers, 'data.context.pages')}
										page={get(customers, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<DialogModal
						shown={modal === MODAL.DELETE_CUSTOMER}
						cancelHandler={this.dismissModal}
						acceptHandler={this.onDeleteCustomer}
						message={locale.formatString(locale['page.customer.remove.message'], get(customer, 'collectionPlacesCount', 0))}
						title={locale.formatString(locale['page.customer.remove.title'], customerName)}
						acceptTitle={locale['page.customer.remove.accept']}
					/>
					<DialogModal
						shown={modal === MODAL.SET_ACTIVE_CUSTOMER}
						cancelHandler={this.dismissModal}
						acceptHandler={this.setActiveCustomer}
						message={locale['page.customers.activate.message']}
						title={locale.formatString(locale['page.customers.activate.title'], customerName)}
						acceptTitle={locale['page.customers.activate.accept']}
					/>
					<DeactivateModal
						shown={modal === MODAL.SET_INACTIVE_CUSTOMER}
						dismissHandler={this.dismissModal}
						message={locale.formatString(locale['page.customers.deactivate.message'], get(customer, 'collectionPlacesCount', 0))}
						title={locale.formatString(locale['page.customers.deactivate.title'], customerName)}
						acceptTitle={locale['page.customers.deactivate.accept']}
						onSubmit={this.setActiveCustomer}
					/>
					<CreateCustomerModal
						dismissHandler={this.dismissModal}
						createHandler={this.createHandler}
						shown={modal === MODAL.CREATE_CUSTOMER}
						onSelectSuggestion={this.onSelectAddress}
						municipalityID={parseInt(municipalityID, 10)}
					/>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedMunicipality: state.selectedMunicipality,
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CUSTOMERS_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	selectedFiltersOrdersActions: bindActionCreators(SelectedFiltersOrdersActions, dispatch),
	municipalityActions: bindActionCreators(MunicipalityActions, dispatch),
	initializeForm: bindActionCreators(initialize, dispatch),
	change: bindActionCreators(change, dispatch),
	dispatch
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage)
