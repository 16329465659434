import React, { CSSProperties, PropsWithChildren } from 'react'

import styled from 'styled-components'
import cx from 'classnames'

import { LoadingOutlined } from '@ant-design/icons'

export const ButtonElement = styled.button`
	border: none;
	outline: none;

	font-family: 'Inter', sans-serif;
	background-color: ${({ theme }) => theme.colors.primary['500']};
	color: ${({ theme }) => theme.colors.neutral['800']};

	font-size: 16px;
	line-height: 22px;
	padding: 5px 12px;
	border-radius: 8px;
	height: 32px;

	display: flex;
	align-items: center;

	* + * {
		margin-left: 8px;
	}

	&.center {
		justify-content: center;
	}

	&.medium {
		padding: 10px 12px;
	}

	&.large {
		padding: 14px 12px;
	}

	&:disabled {
		opacity: 0.5;
	}

	&.secondary {
		background-color: ${({ theme }) => theme.colors.neutral['200']};
	}

	&.danger {
		color: white;
		background-color: ${({ theme }) => theme.colors.error};
	}

	:hover:not(:disabled) {
		background-color: ${({ theme }) => theme.colors.primary['700']};
		box-shadow: 0 6px 9px rgba(123, 176, 23, 0.22), 0 5.59103px 6.0308px rgba(123, 176, 23, 0.139481), 0 2.4445px 1.79553px rgba(123, 176, 23, 0.10264);

		&.secondary {
			background-color: ${({ theme }) => theme.colors.neutral['300']};
			box-shadow: 0 6px 9px rgba(187, 187, 187, 0.22), 0 5.59103px 6.0308px rgba(175, 175, 175, 0.14), 0 2.4445px 1.79553px rgba(177, 177, 177, 0.1);
		}

		&.danger {
			color: white;
			background-color: ${({ theme }) => theme.colors.error};
			box-shadow: 0 6px 9px rgba(176, 23, 23, 0.22), 0 5.59103px 6.0308px rgba(176, 23, 28, 0.14), 0 2.4445px 1.79553px rgba(176, 23, 33, 0.1);
		}
	}

	&:not(.loading) > .loading-icon {
		display: none;
	}

	& > .loading-icon {
		margin-left: 8px;
		align-self: center;
	}
`

type Props = PropsWithChildren<{
	loading?: boolean
	disabled?: boolean
	className?: string
	style?: CSSProperties
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}>

const Button = ({ children, className, loading, ...rest }: Props) => (
	<ButtonElement {...rest} className={cx(className, { loading })}>
		{children}
		{loading && <LoadingOutlined spin={true} color={'black'} className={'loading-icon'}/>}
	</ButtonElement>
)

export default Button
