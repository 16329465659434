import React from 'react'
import * as PropTypes from 'prop-types'

import { Field, reduxForm, propTypes } from 'redux-form'
import { Col, Row } from 'antd'

import locale from '../../../resources/locale'

import { FORMS } from '../../../utils/enums'
import DateTimePickerField from '../../../atoms/DateTimePickerField'
import Button from '../../../components/buttons/Button'

const CustomerExpireCode = ({ handleCancel, handleSubmit }) => (
	<form
		onSubmit={handleSubmit}
		className={'form-horizontal'}
		noValidate
		autoComplete={'off'}
	>
		<div className={'modal-header'}>
			{locale['page.customers.detail.expire.code']}
		</div>
		<div className={'modal-content'}>
			<div className="row" style={{ marginBottom: '20px' }}>
				<Field
					name={'validTo'}
					component={DateTimePickerField}
					props={{
						label: locale['page.customers.detail.validTo.select'],
						placeholder: locale['page.customers.detail.validTo.none'],
						format: locale['common.date.format'],
						timeFormat: 'HH:mm',
						showLabel: true
					}}
				/>
			</div>
		</div>
		<div className={'modal-footer clearfix'}>
			<Row gutter={16} style={{ marginTop: 16 }}>
				<Col>
					<Button
						onClick={handleCancel}
						className={'secondary'}
					>
						{locale['common.cancel']}
					</Button>
				</Col>
				<Col>
					<Button
						type="submit"
						onClick={(e) => {
							e.preventDefault()
							handleSubmit(e)
						}}
					>
						{locale['common.save']}
					</Button>
				</Col>
			</Row>
		</div>
	</form>
)

CustomerExpireCode.propTypes = {
	handleCancel: PropTypes.func.isRequired,
	...propTypes
}

export default reduxForm({
	form: FORMS.EXPIRE_CUSTOMER_CODE,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CustomerExpireCode)
