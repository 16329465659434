import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import ImgCrop from 'antd-img-crop'

import { FieldRenderProps } from 'react-final-form'

import { Spin } from 'antd'

import { InputWrapper, Label, UploadContent, UploadDragger, UploadPlaceholder } from './Styled'
import { uploadFile } from '../../utils/upload'
import { FILE_TYPE } from '../../utils/enums'
import { guid } from '../../utils/files'

const DownloadFileContent = styled.a`
	display: flex;
	align-items: center;
	padding: 0 16px;
	text-overflow: ellipsis;
`

type Props = FieldRenderProps<any> & {
	required?: boolean
	label?: string
	placeholder?: string
	accept?: string
	multiple?: boolean
	className?: string
	image?: boolean
	fileType?: FILE_TYPE
}

const DropZoneField = ({ input, required, label, placeholder, accept, multiple, className, fileType = FILE_TYPE.COVER, image = true }: Props) => {
	const [loadingFile, setLoadingFile] = useState(null)

	const customRequest = useCallback(async ({ file }) => {
		try {
			setLoadingFile(file.name)

			const { data } = await uploadFile(file, { type: fileType }, `${guid()}-${file.name}`)

			setLoadingFile(null)
			input.onChange(data)
			input.onBlur()

			return { inputName: input.name, imageName: name, uri: data.uri }
		} catch (e) {
			return e
		}
	}, [fileType, input])

	let content
	if (loadingFile) {
		content = (<div><Spin size={'small'} style={{ marginRight: 10 }}/>{loadingFile}</div>)
	} else if (!input.value) {
		content = (
			<UploadPlaceholder className={className}>
				{placeholder}
			</UploadPlaceholder>
		)
	} else if (input.value) {
		if (image) {
			content = <img src={input.value.url} alt={'Image'}/>
		} else {
			content = (
				<DownloadFileContent
					onClick={(e) => e.stopPropagation()}
					href={input.value.url}
					target={'_blank'}
				>
					{input.value.name || input.value.url}
				</DownloadFileContent>
			)
		}
	}

	const uploader = (
		<UploadDragger
			{...input}
			accept={accept}
			onChange={() => {}}
			fileList={[]}
			customRequest={customRequest}
			multiple={multiple || false}
			className={className}
			type={'drag'}
		>
			<UploadContent>
				{content}
			</UploadContent>
		</UploadDragger>
	)

	if (fileType === FILE_TYPE.COVER) {
		return (
			<InputWrapper>
				<Label>
					{label}{required ? '*' : ''}
				</Label>
				<ImgCrop grid>
					{uploader}
				</ImgCrop>
			</InputWrapper>
		)
	}

	return (
		<InputWrapper>
			<Label>
				{label}{required ? '*' : ''}
			</Label>
			{uploader}
		</InputWrapper>
	)
}

export default DropZoneField
