import React from 'react'
import { render } from 'react-dom'

import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

import App from './App'

import 'bootstrap/dist/css/bootstrap.css'

import 'antd/es/style/core/index.less'
import 'antd/es/grid/style/index.css'
import 'antd/es/modal/style/index.less'
import 'antd/es/date-picker/style/index.less'
import 'antd/es/checkbox/style/index.less'
import 'antd/es/select/style/index.less'
import 'antd/es/icon/style/index.less'
import 'antd/es/spin/style/index.less'
import 'antd/es/layout/style/index.less'
import 'antd/es/menu/style/index.less'
import 'antd/es/slider/style/index.less'
import 'antd/es/tooltip/style/index.less'
import 'antd/es/table/style/index.less'
import 'antd/es/pagination/style/index.less'
import 'antd/es/button/style/index.less'
import 'antd/es/dropdown/style/index.less'
import 'antd/es/tabs/style/index.less'

import './resources/styles/global.sass'
import './resources/styles/temp.sass'
import './resources/styles/select.sass'
import './resources/styles/fonts.css'

import 'react-image-crop/dist/ReactCrop.css'

import { unregister } from './serviceWorkerRegistration'

unregister()

require('dayjs/locale/sk')

dayjs.locale('sk')

dayjs.extend(quarterOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Bratislava')

const app = <App/>

render(app, document.getElementById('root'))
