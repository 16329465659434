import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import slugify from 'slugify'

import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { filter, take, join, map, replace, split, startsWith, find } from 'lodash'

import { Dropdown, Menu, Spin } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { MunicipalityBase } from '../../types/data'

import { municipalities } from '../../api'

import { getAuthUser } from '../../redux/authentication'

import Loading from '../../components/Loading'

import { useDataLoader } from '../../hooks/useDataLoader'

import { municipalityLoadDone, selectMunicipality } from '../../actions/municipalityActions'

import ErbPlaceholder from '../../resources/images/img-erb-placeholder.svg'
import { filtersActions } from '../../redux/filters'

const Input = styled.input`
	border: none;
	outline: none;

	&:disabled {
		background-color: white;
	}
`

const MunicipalityItem = styled.div`
	display: flex;
	height: 40px;
	align-items: center;

	img {
		width: 60px;
		height: 100%;
		object-fit: contain;
	}

	span, ${Input} {
		flex: 1;
		margin-left: 10px;
		max-height: 40px;
		text-overflow: ellipsis;
		word-wrap: break-word;
	}
`

const SelectedMunicipality = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

const StyledMenu = styled(Menu)`
	max-height: 300px;
	overflow: auto;
`

const Wrapper = styled.div`
 	height: 100%;
 	padding: 15px 22px;
 	display: flex;
 	align-items: center;

	@media(min-width: 992px) {
		${MunicipalityItem} {
			width: 300px;
		}
		${StyledMenu} {
			width: 300px;
		}
	}
`

const MunicipalitySelect = () => {
	const [t] = useTranslation()
	const [search, setSearch] = useState('')
	const [municipalitiesState, load] = useDataLoader(municipalities.loadMunicipalities)

	const dispatch = useDispatch()
	const selectedMunicipality = useSelector((state: any) => state.selectedMunicipality.municipality)
	const authUser = useSelector(getAuthUser)

	const match = useRouteMatch<{ municipalityID?: string }>()
	const history = useHistory()

	const handleSelectMunicipality = (municipality: MunicipalityBase | null) => {
		setSearch('')
		dispatch(municipalityLoadDone(municipality))
		dispatch(filtersActions.reset())

		if (history.location.pathname !== t('paths:PROFILE.path') && !startsWith(history.location.pathname, t('paths:ADMINISTRATION.path'))) {
			let path: string
			if (municipality) {
				const municipalityPath = t('paths:MUNICIPALITY.path', { municipalityID: municipality.id })
				if (match.params.municipalityID) {
					const pathToReplace = t('paths:MUNICIPALITY.path', { municipalityID: match.params.municipalityID })
					path = replace(history.location.pathname, pathToReplace, municipalityPath)
				} else {
					path = `${municipalityPath}${history.location.pathname}`
				}
			} else if (match.params.municipalityID) {
				const pathToReplace = t('paths:MUNICIPALITY.path', { municipalityID: match.params.municipalityID })
				path = replace(history.location.pathname, pathToReplace, '')
			} else {
				path = history.location.pathname
			}

			const pathSegments = split(path, '/')

			if (municipality) {
				path = join(take(pathSegments, 5), '/')
			} else {
				const sectionPath = t('paths:MUNICIPALITY.sectionPath')
				if (startsWith(path, sectionPath)) {
					path = '/'
				} else {
					path = join(take(pathSegments, 3), '/')
				}
			}

			history.push(path)
		}
	}

	useEffect(() => {
		// if (!selectedMunicipality.isLoading && !startsWith(history.location.pathname, t('paths:ADMINISTRATION.path'))) {
		// NOTE: fix redux issue.
		if (!startsWith(history.location.pathname, t('paths:ADMINISTRATION.path'))) {
			if (match.params.municipalityID && (!selectedMunicipality.data || match.params.municipalityID !== `${selectedMunicipality.data.id}`)) {
				dispatch(selectMunicipality(match.params.municipalityID))
				dispatch(filtersActions.reset())
			} else if (authUser.data && !authUser.data?.isAdmin && authUser.data.municipalities?.length === 1 && !selectedMunicipality.data) {
				const municipality = authUser.data.municipalities[0]
				const municipalityPath = t('paths:MUNICIPALITY.path', { municipalityID: municipality.id })

				if (history.location.pathname !== t('paths:PROFILE.path')) {
					let path: string = history.location.pathname
					if (!startsWith(history.location.pathname, municipalityPath)) {
						path = `${municipalityPath}${history.location.pathname}`
					}

					dispatch(municipalityLoadDone(municipality))
					history.replace(path)
				}
			} else if (!match.params.municipalityID && selectedMunicipality.data) {
				const profilePath = t('paths:PROFILE.path')
				const administrationPath = t('paths:ADMINISTRATION.path')

				if (history.location.pathname !== profilePath && history.location.pathname !== administrationPath) {
					dispatch(selectMunicipality(null))
				}
			}
		}
	}, [authUser.data, dispatch, history, history.location.pathname, match.params, selectedMunicipality.data, selectedMunicipality.isLoading, t])

	useEffect(() => {
		if (authUser.data) {
			load({ limit: 'ALL', order: 'name:ASC' })
		}
	}, [authUser.data, load])

	useEffect(() => {
		if (authUser.data && selectedMunicipality.data) {
			if (!authUser.data.isAdmin && !find(authUser.data.municipalities, (item) => item.id === selectedMunicipality.data.id)) {
				if (authUser.data.municipalities?.length === 1) {
					dispatch(selectMunicipality(authUser.data.municipalities[0].id))
				} else {
					history.replace('/')
				}
			}
		} else if (!authUser.data?.isAdmin && !selectedMunicipality.data && authUser.data?.municipalities?.length === 1) {
			const path = history.location.pathname
			const municipalityID = authUser.data.municipalities[0].id
			const municipalityPath = t('paths:MUNICIPALITY.path', { municipalityID })

			if (!startsWith(path, municipalityPath)) {
				history.replace(municipalityPath + path)
			}
		}
	}, [authUser.data, dispatch, history, selectedMunicipality.data, t])

	const options = useMemo(() => {
		const municipalities = municipalitiesState.data?.municipalities || []

		if (search) {
			const slug = slugify(search, { lower: true })
			return filter(municipalities, (item) => slugify(item.name, { lower: true }).includes(slug))
		}

		return municipalities
	}, [search, municipalitiesState.data?.municipalities])

	let menuContent

	if (authUser.isLoading || selectedMunicipality.isLoading) {
		return (
			<Wrapper>
				<Spin style={{ marginTop: 4 }}/>
			</Wrapper>
		)
	}

	if (municipalitiesState.data?.municipalities.length === 1) {
		const municipality = municipalitiesState.data?.municipalities[0]

		return (
			<Wrapper>
				<SelectedMunicipality>
					<MunicipalityItem>
						<img src={municipality?.logo?.url || ErbPlaceholder} alt={'Erb'}/>
						<span>{municipality?.name || ''}</span>
					</MunicipalityItem>
				</SelectedMunicipality>
			</Wrapper>
		)
	}

	if (municipalitiesState.isLoading) {
		menuContent = (
			<Menu.Item>
				<MunicipalityItem>
					<Loading/>
				</MunicipalityItem>
			</Menu.Item>
		)
	} else {
		menuContent = [
			<Menu.Item
				key={'ALL'}
				onClick={() => handleSelectMunicipality(null)}
			>
				<MunicipalityItem>
					<img src={ErbPlaceholder} alt={'Erb'}/>
					<span>{t('MunicipalitySelect.allMunicipalities')}</span>
				</MunicipalityItem>
			</Menu.Item>,
			...map(options, (item) => (
				<Menu.Item
					key={item.id}
					onClick={() => handleSelectMunicipality(item)}
				>
					<MunicipalityItem>
						<img src={item.logo?.url || ErbPlaceholder} alt={'Erb'}/>
						<span>{item.name}</span>
					</MunicipalityItem>
				</Menu.Item>
			))
		]
	}

	return (
		<Wrapper>
			<Dropdown
				overlay={
					<StyledMenu>
						{menuContent}
					</StyledMenu>
				}
			>
				<SelectedMunicipality>
					<MunicipalityItem>
						<img src={selectedMunicipality.data?.logo?.url || ErbPlaceholder} alt={'Erb'}/>
						<Input
							value={search}
							onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
							disabled={!authUser.data?.isAdmin}
							placeholder={selectedMunicipality.data?.name || t('MunicipalitySelect.allMunicipalities')}
						/>
						<DownOutlined/>
					</MunicipalityItem>
				</SelectedMunicipality>
			</Dropdown>
		</Wrapper>
	)
}

export default React.memo(MunicipalitySelect)
