import React from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import locale from '../resources/locale'

const ListItemsLimit = ({ limit, onLimit }) => (
	<div className={'limit-wrapper'}>
		<div className={'title'}>{locale['common.limit']}</div>
		<div className={cx('limit', { active: parseInt(limit, 10) === 20 })} onClick={() => onLimit(20)}>20</div>
		<div className={cx('limit', { active: parseInt(limit, 10) === 50 })} onClick={() => onLimit(50)}>50</div>
		<div className={cx('limit', { active: parseInt(limit, 10) === 100 })} onClick={() => onLimit(100)}>100</div>
	</div>
)

ListItemsLimit.propTypes = {
	limit: PropTypes.any,
	onLimit: PropTypes.func.isRequired
}

ListItemsLimit.defaultPropTypes = {
	limit: 20
}

export default ListItemsLimit
