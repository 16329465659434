import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { WASTE_TYPES_ACTIONS } from './constants'

export const wasteTypesLoadStart = {
	type: WASTE_TYPES_ACTIONS.LIST_LOAD_START
}

export const wasteTypesLoadFail = {
	type: WASTE_TYPES_ACTIONS.LIST_LOAD_FAIL
}

export const wasteTypesLoadDone = (data) => ({
	type: WASTE_TYPES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadWasteTypes = (filters) => async (dispatch) => {
	dispatch(wasteTypesLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.WASTE_TYPES_MUNICIPALITY, query)

		dispatch(wasteTypesLoadDone(response.data))
	} catch (e) {
		dispatch(wasteTypesLoadFail)
	}
}
