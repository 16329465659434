import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { debounce, join, get, isEqual, head, last, trim } from 'lodash'
import { formValueSelector, reset } from 'redux-form'

import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import ReactQueryParams from 'react-query-params'
import { ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO, MODAL, MUNICIPALITY_TYPE } from '../../utils/enums'

import SearchInputField from '../../atoms/SearchInputField'
import Pagination from '../../atoms/Pagination'
import MunicipalitiesTable from '../../components/municipalities/MunicipalitiesTable'

import { statusPush } from '../../actions/statusActions'
import { history } from '../../utils/history'

import locale from '../../resources/locale'
import CreateMunicipality from '../../components/modals/CreateMunicipality'
import DialogModal from '../../components/modals/DialogModal'
import { getReq, patchReq, postReq } from '../../utils/request'
import Breadcrumbs from '../../components/Breadcrumb'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import ExportButton from '../../components/buttons/ExportButton'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { loadMunicipalities } from '../../redux/municipalities/actions'
import { getMunicipalitiesWasteCompaniesSelectOptions } from '../../redux/municipalities/selectors'
import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'usersCount', 'collectionRoundsCount', 'type', 'lastCollectionRound', 'useGeneralCollectionRound', 'isActive']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class MunicipalitiesPage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		resetForm: PropTypes.func.isRequired,
		loadMunicipalities: PropTypes.func.isRequired,
		municipalityType: PropTypes.oneOf(Object.values(MUNICIPALITY_TYPE)),
		wasteCompanies: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			values: PropTypes.array.isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			municipalities: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders, loadMunicipalities } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		this.loadMunicipalities(filtersOrder)
		loadMunicipalities({ limit: 'ALL' })
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.MUNICIPALITIES_PAGE, filtersOrder)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default:
				break
		}

		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		this.setQueryParams({
			...filterOrder
		})
		this.loadMunicipalities(filterOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.MUNICIPALITIES_PAGE, filterOrder)
	}

	loadMunicipalities = async (filters) => {
		if (this._mounted) {
			this.setState({
				municipalities: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const query = { ...filters }
				const response = await getReq(ENDPOINTS.MUNICIPALITIES, query)

				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder(queryParams.page, 1)
				} else {
					this.setState({
						...this.state,
						municipalities: {
							data: get(response, 'data'),
							isLoading: false,
							isFailure: false
						}
					})
				}
			} catch (error) {
				this.setState({
					...this.state,
					municipalities: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	createMunicipality = async (data) => {
		const { pushStatus, selectedFiltersOrders } = this.props
		try {
			await postReq(ENDPOINTS.MUNICIPALITIES, null, data)
			this.dismissModal()
			pushStatus({
				type: INFO,
				msg: locale['page.municipalities.add.success']
			})
			this.loadMunicipalities(selectedFiltersOrders)
		} catch (error) {
			this.loadMunicipalities(selectedFiltersOrders)
		}
	}

	searchMunicipalities = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	setActiveMunicipality = (data) => {
		const { pushStatus, selectedFiltersOrders } = this.props
		return async () => {
			this.dismissModal()
			try {
				await patchReq(ENDPOINTS.MUNICIPALITY(data.id), null, { isActive: !data.isActive })
				pushStatus({
					type: INFO,
					msg: data.isActive ? locale['page.municipalities.deactivate.success'] : locale['page.municipalities.activate.success']
				})
				this.loadMunicipalities(selectedFiltersOrders)
			} catch (error) {
				this.loadMunicipalities(selectedFiltersOrders)
			}
		}
	}

	openMunicipalityDetail = (municipality) => {
		history.push(locale.formatString(locale['path.administration.municipalities.detail'], municipality.id))
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	resetForm = (modal) => {
		const { resetForm } = this.props
		if (modal === MODAL.CREATE_MUNICIPALITY) {
			resetForm(FORMS.CREATE_MUNICIPALITY)
		}
	}

	openModal = (modal) => (data) => {
		this.resetForm(modal)
		this.setState({
			modal,
			modalData: data
		})
	}

	breadcrumbsItems = () => [{
		key: 'page.administration.breadcrumbs',
		name: locale['page.administration.breadcrumbs']
	}, {
		key: 'page.municipalities.breadcrumbs',
		name: locale['page.municipalities.breadcrumbs']
	}]

	render() {
		const { municipalities, modal, modalData } = this.state
		const { selectedFiltersOrders, municipalityType, wasteCompanies } = this.props

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))
		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.municipalities.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row justify={'space-between'} gutter={16}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchMunicipalities, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								{/* NOTE: hide cause of MO-1583 <Col flex={1}>
									<ExportButton
										url={ENDPOINTS.MUNICIPALITIES_EXPORT}
										context={selectedFiltersOrders}
									/>
								</Col> */}
								<Col>
									<Button
										onClick={this.openModal(MODAL.CREATE_MUNICIPALITY)}
									>
										<PlusOutlined/>
										<span>{locale['page.municipalities.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<MunicipalitiesTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onSetActive={this.openModal(MODAL.SET_ACTIVE_MUNICIPALITY)}
										onDetail={this.openMunicipalityDetail}
										municipalities={get(municipalities, 'data.municipalities')}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										loading={get(municipalities, 'isLoading')}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(municipalities, 'data.context.pages')}
										page={get(municipalities, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<CreateMunicipality
						shown={modal === MODAL.CREATE_MUNICIPALITY}
						municipalityType={municipalityType}
						wasteCompanies={wasteCompanies}
						dismissHandler={this.dismissModal}
						createHandler={this.createMunicipality}
					/>
					<DialogModal
						shown={modal === MODAL.SET_ACTIVE_MUNICIPALITY}
						cancelHandler={this.dismissModal}
						acceptHandler={this.setActiveMunicipality(modalData)}
						message={
							get(modalData, 'isActive') ? locale['page.municipalities.deactivate.message'] : locale['page.municipalities.activate.message']
						}
						title={
							locale.formatString(
								get(modalData, 'isActive') ?
									locale['page.municipalities.deactivate.title'] :
									locale['page.municipalities.activate.title'],
								join([get(modalData, 'name')], ' ')
							)
						}
						acceptTitle={
							get(modalData, 'isActive') ? locale['page.municipalities.deactivate.accept'] : locale['page.municipalities.activate.accept']
						}
					/>
				</div>
			</>
		)
	}
}

const selector = formValueSelector(FORMS.CREATE_MUNICIPALITY)

const mapStateToProps = (state) => ({
	wasteCompanies: getMunicipalitiesWasteCompaniesSelectOptions(state),
	municipalityType: selector(state, 'type'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.MUNICIPALITIES_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	loadMunicipalities: bindActionCreators(loadMunicipalities, dispatch),
	pushStatus: bindActionCreators(statusPush, dispatch),
	resetForm: bindActionCreators(reset, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MunicipalitiesPage)
