import { some, get, isEmpty } from 'lodash'

export function canUpdateUser(user) {
	return !user.isAdmin
}

export function checkPermissions(permissions, user, selectedMunicipalityId) {
	if (!isEmpty(permissions)) {
		// USER IS ADMINISTRATOR
		if (get(user, 'isAdmin')) {
			return true
		}
		// check permission for selected municipality
		if (selectedMunicipalityId) {
			return some(get(user, 'municipalities', []), (municipality) => {
				if (municipality.id === selectedMunicipalityId) {
					return some(get(municipality, 'permissions', []), (permission) => permissions.includes(permission))
				}
				return false
			})
		} // if municipality is not selected check every permissions and compare with allowed
		return some(get(user, 'municipalities', []), (municipality) => some(get(municipality, 'permissions', []), (permission) => permissions.includes(permission)))
	}
	return true
}
