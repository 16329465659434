import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { COLLECTION_ROUNDS_ACTIONS } from './constants'
import { collectionRounds } from '../../api'

export const collectionRoundsLoadStart = {
	type: COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_START
}

export const collectionRoundsLoadFail = {
	type: COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_FAIL
}

export const collectionRoundsLoadDone = (data) => ({
	type: COLLECTION_ROUNDS_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadCollectionRounds = (filters) => async (dispatch) => {
	dispatch(collectionRoundsLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.COLLECTION_ROUNDS, query)

		dispatch(collectionRoundsLoadDone(response.data))
	} catch (e) {
		dispatch(collectionRoundsLoadFail)
	}
}

export const collectionRoundLoadStart = {
	type: COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_START
}

export const collectionRoundLoadFail = {
	type: COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_FAIL
}

export const collectionRoundLoadDone = (data) => ({
	type: COLLECTION_ROUNDS_ACTIONS.DETAIL_LOAD_DONE,
	payload: data
})

export const loadCollectionRound = (id) => async (dispatch) => {
	dispatch(collectionRoundLoadStart)

	try {
		const endpoint = ENDPOINTS.COLLECTION_ROUND(id)
		const response = await getReq(endpoint)

		dispatch(collectionRoundLoadDone(response.data))

		return response.data
	} catch (e) {
		dispatch(collectionRoundLoadFail)

		return e
	}
}

export const collectionRoundMapLoadStart = {
	type: COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_START
}

export const collectionRoundMapLoadFail = {
	type: COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_FAIL
}

export const collectionRoundMapLoadDone = (data) => ({
	type: COLLECTION_ROUNDS_ACTIONS.MAP_LOAD_DONE,
	payload: data
})

export const loadCollectionRoundMap = (filters) => async (dispatch) => {
	dispatch(collectionRoundMapLoadStart)

	try {
		const response = await collectionRounds.loadMap(filters)

		dispatch(collectionRoundMapLoadDone(response.data))

		return response.data
	} catch (e) {
		dispatch(collectionRoundMapLoadFail)

		return e
	}
}

export const collectionRoundOverviewLoadStart = {
	type: COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_START
}

export const collectionRoundOverviewLoadFail = {
	type: COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_FAIL
}

export const collectionRoundOverviewLoadDone = (data) => ({
	type: COLLECTION_ROUNDS_ACTIONS.OVERVIEW_LOAD_DONE,
	payload: data
})

export const loadCollectionRoundOverview = (id) => async (dispatch) => {
	dispatch(collectionRoundOverviewLoadStart)

	try {
		const response = await collectionRounds.loadOverview(id)

		dispatch(collectionRoundOverviewLoadDone(response.data))

		return response.data
	} catch (e) {
		dispatch(collectionRoundOverviewLoadFail)

		return e
	}
}
