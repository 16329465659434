import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import moment from 'moment'
import i18next from 'i18next'

// components
import Modal from './Modal'

// recources
import locale from '../../resources/locale'

// atoms
import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'
import DateTimePickerField from '../../atoms/DateTimePickerField'

// utils
import { FORMS, DEVICE_TYPES } from '../../utils/enums'
import { validate, validatorOptions, validateSelect } from '../../utils/validator'
import Button from '../buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateSelectRequired = validateSelect([validatorOptions.REQUIRED])
const validateDate = validate([validatorOptions.REQUIRED])

class CreateDevice extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		wasteTypes: PropTypes.object,
		formValues: PropTypes.object,
		initialize: PropTypes.func.isRequired
	}

	state = {
		shown: false
	}

	componentDidMount = () => {
		this.props.reset(FORMS.CREATE_DEVICE_FORM)
	}

	validateDateFrom = (value, allValues) => {
		const error = validateDate(value)
		if (!error && allValues.assignedTo) {
			const fromDate = moment(value)
			const toDate = moment(allValues.assignedTo)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.FROM_DATE']
			}
		}
		return error
	}

	normalizeDateFrom = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.startOf('day').toDate()
		}
		return value
	}

	validateDateTo = (value, allValues) => {
		if (value && allValues.assignedFrom) {
			const fromDate = moment(allValues.assignedFrom)
			const toDate = moment(value)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.TO_DATE']
			}
		}
		return null
	}

	normalizeDateTo = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.endOf('day').toDate()
		}
		return value
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	render = () => {
		const { handleSubmit, dismissHandler, shown } = this.props

		const titleInputField = {
			label: i18next.t('loc:Názov'),
			required: true,
			showLabel: true
		}

		const typeSelectField = {
			label: i18next.t('loc:Typ'),
			required: true,
			showLabel: true,
			options: [{
				label: locale['page.device.TERMINAL'],
				value: DEVICE_TYPES.TERMINAL
			}, {
				label: locale['page.device.DISCOVERY'],
				value: DEVICE_TYPES.DISCOVERY
			}, {
				label: locale['page.device.ARCOBEAT'],
				value: DEVICE_TYPES.ARCOBEAT
			}, {
				label: locale['page.device.ARCO40EVO'],
				value: DEVICE_TYPES.ARCO40EVO
			}, {
				label: locale['page.device.EXTERNAL_DEVICE'],
				value: DEVICE_TYPES.EXTERNAL_DEVICE
			}, {
				label: locale['page.device.GX_SOLUTIONS'],
				value: DEVICE_TYPES.GX_SOLUTIONS
			}, {
				label: locale['page.device.SEPAN_ELTE_GPS'],
				value: DEVICE_TYPES.SEPAN_ELTE_GPS
			}, {
				label: locale['page.device.RADIUM_DEVICE'],
				value: DEVICE_TYPES.RADIUM_DEVICE
			}]
		}

		const deviceNumberInputField = {
			label: i18next.t('loc:Kód zariadenia'),
			required: true,
			showLabel: true
		}

		const assignedFromFieldOptions = {
			label: i18next.t('loc:Priradené od'),
			required: true,
			showLabel: true,
			minDate: moment().startOf('day'),
			format: locale['common.date.format.date']
		}

		const assignedToFieldOptions = {
			label: i18next.t('loc:Priradené do'),
			required: true,
			showLabel: true,
			minDate: moment().startOf('day'),
			format: locale['common.date.format.date']
		}

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit} className={'form-horizontal'} noValidate autoComplete={'off'}>
					<div className={'modal-header'}>
						{locale['page.device.add']}
					</div>
					<div className={'modal-content'}>
						<div className="row" style={{ marginBottom: '20px' }}>
							<div className={'col-12'}>
								<Field
									name='name'
									placeholder={locale['field.placeholder.title']}
									component={TextInputField}
									props={titleInputField}
									validate={validateRequiredString}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='type'
									placeholder={locale['field.placeholder.type']}
									component={SelectField}
									props={typeSelectField}
									validate={validateSelectRequired}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='number'
									placeholder={locale['field.placeholder.deviceNumber']}
									component={TextInputField}
									props={deviceNumberInputField}
									validate={validateRequiredString}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='UUID'
									placeholder={'UUID'}
									component={TextInputField}
									label={'UUID'}
									showLabel={true}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='SIM'
									placeholder={'SIM'}
									component={TextInputField}
									label={'SIM'}
									showLabel={true}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='assignedFrom'
									placeholder={locale['field.placeholder.assigned.from']}
									component={DateTimePickerField}
									props={assignedFromFieldOptions}
									validate={this.validateDateFrom}
									normalize={this.normalizeDateFrom}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='assignedTo'
									placeholder={locale['field.placeholder.assigned.to']}
									component={DateTimePickerField}
									props={assignedToFieldOptions}
									validate={this.validateDateTo}
									normalize={this.normalizeDateTo}
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{locale['page.device.add']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CREATE_DEVICE_FORM,
	updateUnregisteredFields: true,
	destroyOnUnmount: true,
	touchOnChange: true
})((CreateDevice))
