import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'

import { change, getFormValues, initialize, reset } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import {
	map, chunk, get, split, sortBy, reverse
} from 'lodash'
import { Col, Row } from 'antd'

import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import { SORT_DESC } from '../../../types/table'
import { getSelectedColumns } from '../../../utils/filter'
import { FILTER, FORMS, MODAL } from '../../../utils/enums'

import locale from '../../../resources/locale'

import CustomerAssignCodesForm from './CustomerAssignCodesForm'
import CustomerExpireCode from './CustomerExpireCode'

import { columns, defaultColumns, queryParams } from './customerCodesConstants'
import Table from '../../../components/table/Table'
import Modal from '../../../components/modals/Modal'

import ListItemsLimit from '../../../atoms/ListImtesLimit'
import Pagination from '../../../atoms/Pagination'
import validateAssignCustomerCodes from '../validators/validateAssignCustomerCodes'
import DialogModal from '../../../components/modals/DialogModal'
import Button from '../../../components/buttons/Button'

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

const filtersSelector = getFormValues(FILTER.CUSTOMER_CODES_TABLE_FILTERS)

const filterCodes = (codes, filters) => {
	const [order, direction] = split(filters.order, ':')
	const ordered = sortBy(codes, order || 'code')
	if (direction === SORT_DESC) {
		reverse(ordered)
	}

	const chunks = chunk(ordered, get(filters, 'limit', 20))
	const page = chunks[get(filters, 'page', 0) - 1]

	return { codes: page, limit: get(filters, 'limit', 20), pages: chunks.length }
}

const CustomerCodesTable = ({ customer, codes, handleAssignCodes, handleExpireCode }) => {
	const dispatch = useDispatch()
	const filters = useSelector(filtersSelector) || {}
	const [modal, setModal] = useState({ key: null })

	useEffect(() => {
		const initFilters = {
			columns: defaultColumns,
			limit: 20,
			order: 'code:ASC',
			...filters || {},
			page: 1
		}

		dispatch(initialize(FILTER.CUSTOMER_CODES_TABLE_FILTERS, initFilters))
	}, [dispatch])

	const data = filterCodes(codes, filters || {})

	return (
		<>
			<div className={'box'}>
				<div className={'box-content'}>
					<div className={'box-head'} style={{ height: 80 }}>
						<Row justify={'end'}>
							<Col>
								<Button
									onClick={() => setModal({ key: MODAL.ASSIGN_CUSTOMERS_CODE })}
								>
									<span>{locale['page.customers.detail.assign.code']}</span>
								</Button>
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<Table
							data={data.codes || []}
							tableColumns={tableColumns}
							isFailure={false}
							isSelectable={false}
							onFilter={() => {
							}}
							onResetFilters={() => {
							}}
							getActions={(code) => [{
								key: 'common.expire',
								title: code.validTo ? 'Zrušiť expiráciu' : locale['page.customers.detail.expire.code'],
								icon: faCalendarTimes,
								callback: () => {
									if (code.validTo) {
										setModal({
											key: MODAL.CANCEL_CUSTOMERS_CODE_EXPIRATION,
											data: code
										})
									} else {
										dispatch(initialize(FORMS.EXPIRE_CUSTOMER_CODE, { validTo: code.validTo ? moment(code.validTo).toDate() : null }))
										dispatch(reset(FORMS.EXPIRE_CUSTOMER_CODE))
										setModal({
											key: MODAL.EXPIRE_CUSTOMERS_CODE,
											data: code
										})
									}
								}
							}]}
							columns={getSelectedColumns(filters, {}, columns)}
							onChangeColumns={(value) => dispatch(change(FILTER.CUSTOMER_CODES_TABLE_FILTERS, queryParams.columns, value))}
							defaultColumns={defaultColumns}
							onSort={() => {
							}}
							filters={filters}
							isLoading={false}
							name={FILTER.CUSTOMER_CODES_TABLE_FILTERS}
							style={{
								minHeight: 200,
								maxHeight: codes.length ? 'unset' : 200
							}}
						/>
						<ListItemsLimit
							limit={get(filters, 'limit', 20)}
							onLimit={(value) => dispatch(change(FILTER.CUSTOMER_CODES_TABLE_FILTERS, queryParams.limit, value))}
						/>
						<Pagination
							pages={get(data, 'pages')}
							page={get(filters, 'page', 1)}
							onPage={(value) => dispatch(change(FILTER.CUSTOMER_CODES_TABLE_FILTERS, queryParams.page, value))}
						/>
					</div>
				</div>
			</div>
			<Modal
				shown={modal.key === MODAL.ASSIGN_CUSTOMERS_CODE}
				dismiss={() => setModal({
					key: null,
					data: null
				})}
			>
				<CustomerAssignCodesForm
					handleCancel={(e) => {
						e.preventDefault()
						setModal({
							key: null,
							data: null
						})
					}}
					validate={validateAssignCustomerCodes}
					municipality={{ id: customer.municipalityID }}
					codes={codes}
					onSubmit={(values) => {
						setModal({
							key: null,
							data: null
						})
						handleAssignCodes(values.codes)
					}}
				/>
			</Modal>

			<DialogModal
				shown={modal.key === MODAL.CANCEL_CUSTOMERS_CODE_EXPIRATION}
				cancelHandler={(e) => {
					e.preventDefault()
					setModal({
						key: null,
						data: null
					})
				}}
				message={'Naozaj chcete zrušiť expiráciu?'}
				title={'Zrušiť expiráciu'}
				acceptHandler={() => {
					handleExpireCode(modal.data, null)
					setModal({
						key: null,
						data: null
					})
				}}
				acceptTitle={'Potvrdiť'}
			/>
			<Modal
				shown={modal.key === MODAL.EXPIRE_CUSTOMERS_CODE}
				dismiss={() => setModal({
					key: null,
					data: null
				})}
			>
				<CustomerExpireCode
					handleCancel={(e) => {
						e.preventDefault()
						setModal({
							key: null,
							data: null
						})
					}}
					onSubmit={(values) => {
						handleExpireCode(modal.data, values.validTo)
						setModal({
							key: null,
							data: null
						})
					}}
				/>
			</Modal>
		</>
	)
}

CustomerCodesTable.propTypes = {
	customer: PropTypes.object.isRequired,
	handleExpireCode: PropTypes.func.isRequired,
	handleAssignCodes: PropTypes.func.isRequired,
	codes: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		code: PropTypes.string.isRequired,
		serial: PropTypes.string.isRequired,
		validFrom: PropTypes.string.isRequired,
		validTo: PropTypes.string,
		createdAt: PropTypes.string.isRequired
	}))
}

export default CustomerCodesTable
