import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import dayjs from 'dayjs'

import { debounce, find, map } from 'lodash'
import { useTranslation } from 'react-i18next'

import { FormApi, FormState } from 'final-form'
import { Field, Form, FormSpy } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Col, Row } from 'antd'

import { DeleteOutlined } from '@ant-design/icons'

import { COLLECTION_PLACE_TYPE, DATE_TIME_FORMAT } from '../../../utils/enums'
import { CONTAINER_TYPE } from '../../../types/enums'

import { CollectionPlace, Container, Customer, LabeledValue } from '../../../types/data'

import { collectionPlaces, containers, customers } from '../../../api'

import { formatCustomer } from '../../../utils/helpers'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SelectField from '../../../components/form/SelectField'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/buttons/Button'

import { composeValidators, validateGte, validateNumber, validateRequired } from '../../../utils/form'
import { formatContainerName } from '../../../utils/formatters'
import DateField from '../../../components/form/DateField'
import { HeadingFour } from '../../../components/Typography'

export type FormValues = {
	scanDatetime: string
	customer: LabeledValue<Customer> | null
	collectionYard: LabeledValue<CollectionPlace> | null
	collections: {
		weight: number
		container: LabeledValue<Container> | null
	}[]
}

export const defaultValues: FormValues = {
	scanDatetime: dayjs().toISOString(),
	customer: null,
	collectionYard: null,
	collections: []
}

const defaultCollectionValue = {
	weight: 0,
	container: null
}

type Props = {
	modal?: boolean
	form: FormApi<FormValues>
	municipalityID?: string | number
}

const BulkCollectionForm = ({ modal, form, municipalityID }: Props) => {
	const [t] = useTranslation()

	const [customersState, loadCustomers] = useDataLoader(customers.loadCustomers)
	const [collectionYardsState, loadCollectionYards] = useDataLoader(collectionPlaces.loadCollectionPlaces)
	const [containersState, loadContainers] = useDataLoader(containers.loadContainers)
	const [customerContainersState, loadCustomerContainers] = useDataLoader(containers.loadContainers)

	const currentYard = useRef<LabeledValue<CollectionPlace> | null>(null)
	const currentCustomer = useRef<LabeledValue<Customer> | null>(null)

	const customersOptions: LabeledValue<Customer>[] = useMemo(() => map(customersState.data?.customers, (customer) => ({
		value: customer.id, label: formatCustomer(customer), item: customer
	})), [customersState.data?.customers])

	const collectionYardsOptions: LabeledValue<CollectionPlace>[] = useMemo(() => map(collectionYardsState.data?.collectionPlaces, (yard) => ({
		value: yard.id, label: yard.name, item: yard
	})), [collectionYardsState.data?.collectionPlaces])

	const containersOptions: LabeledValue<Container>[] = useMemo(() => {
		const containers = [...containersState.data?.containers || [], ...customerContainersState.data?.containers || []]

		return map(containers, (container) => ({
			value: container.id, label: formatContainerName(container, true), item: container
		}))
	}, [containersState.data?.containers, customerContainersState.data?.containers])

	const handleSearchCustomers = useMemo(() => debounce((search: string) => {
		loadCustomers({ search, order: 'name', direction: 'ASC', municipalityID })
	}, 150), [loadCustomers, municipalityID])

	const handleChange = useCallback(({ values }: FormState<FormValues>) => {
		const resetContainers = () => {
			const collections = values.collections?.map((collection) => ({
				container: null,
				weight: collection.weight
			}))

			form.change('collections', collections)
		}

		if (values.customer && values.customer.value !== currentCustomer.current?.value) {
			loadCustomerContainers({
				customerID: values.customer.value,
				limit: 'ALL',
				types: [CONTAINER_TYPE.ONE_TIME],
				assignedTo: dayjs().toISOString()
			})

			if (currentCustomer.current) {
				resetContainers()
			}
		}

		if (values.collectionYard && values.collectionYard.value !== currentYard.current?.value) {
			loadContainers({
				collectionPlaceID: values.collectionYard.value,
				limit: 'ALL',
				types: [CONTAINER_TYPE.COLLECTION_YARD]
			})

			if (currentYard.current) {
				resetContainers()
			}
		}

		currentCustomer.current = values.customer
		currentYard.current = values.collectionYard
	}, [form, loadContainers, loadCustomerContainers])

	useEffect(() => {
		loadCustomers({ order: 'name', direction: 'ASC', municipalityID })
		loadCollectionYards({ order: 'name', types: [COLLECTION_PLACE_TYPE.COLLECTION_YARD], municipalityID })
	}, [form, loadCollectionYards, loadCustomers, municipalityID])

	return (
		// @ts-ignore
		<Form
			form={form}
			subscription={{}}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} style={{ marginBottom: 16 }}>
					<FormSpy
						subscription={{ values: true }}
						onChange={handleChange}
					/>
					<Row gutter={[16, 16]}>
						<Col span={modal ? 24 : 12}>
							<Field
								name={'customer'}
								validate={validateRequired}
								validateFields={[]}
								render={(props) => (
									<SelectField
										{...props}
										labelInValue
										required
										showSearch
										label={t('fields.customer.label')}
										placeholder={t('fields.customer.placeholder')}
										isLoading={customersState.isLoading}
										filterOption={false}
										onSearch={handleSearchCustomers}
										options={customersOptions}
									/>
								)}
							/>
						</Col>
						<Col span={modal ? 24 : 12}>
							<Field
								name={'collectionYard'}
								validate={validateRequired}
								validateFields={[]}
								render={(props) => (
									<SelectField
										{...props}
										labelInValue
										required
										label={t('fields.collectionYard.label')}
										placeholder={t('fields.collectionYard.placeholder')}
										isLoading={collectionYardsState.isLoading}
										options={collectionYardsOptions}
									/>
								)}
							/>
						</Col>
						<Col span={modal ? 24 : 12}>
							<Field
								name={'scanDatetime'}
								validate={validateRequired}
								validateFields={[]}
								render={(props) => (
									<DateField
										{...props}
										labelInValue
										required
										showTime={true}
										format={DATE_TIME_FORMAT}
										label={t('fields.scanDatetime.label')}
										placeholder={t('fields.scanDatetime.placeholder')}
									/>
								)}
							/>
						</Col>
						<Col span={24}>
							<HeadingFour>{t('loc:Záznamy')}</HeadingFour>
							<FieldArray
								name={'collections'}
								subscription={{}}
								render={({ fields }) => (
									<Row gutter={[16, 0]}>
										{fields.map((name, index) => (
											<Col key={name} span={24}>
												<Row gutter={[16, 0]}>
													<Col span={modal ? 24 : 12}>
														<Field
															name={'customer'}
															render={({ input: customerInput }) => (
																<Field
																	name={`${name}.container`}
																	validate={validateRequired}
																	validateFields={[]}
																	render={(props) => {
																		let info = ''

																		if (customerInput.value.item && props.input.value) {
																			const { wasteTypes } = customerInput.value.item

																			const container = find(containersOptions, (container) => container.value === props.input.value.value)
																			const containerWasteType = container?.item?.containerType?.wasteType

																			const wasteType = find(wasteTypes, (wasteType) => wasteType.wasteTypeID === containerWasteType?.id)

																			if (wasteType?.limit) {
																				info = wasteType.limit > 0
																					? t('loc:Zostávajúci limit odpadu {{limit}}kg', { limit: wasteType.limit })
																					: t('loc:Limit odpadu prekročený')
																			}
																		}

																		return (
																			<SelectField
																				{...props}
																				labelInValue
																				required
																				info={info}
																				label={t('fields.container.label')}
																				placeholder={t('fields.container.placeholder')}
																				isLoading={containersState.isLoading || customerContainersState.isLoading}
																				options={containersOptions}
																				showSearch
																				filterSlugBased
																			/>
																		)
																	}}
																/>
															)}
														/>
													</Col>
													<Col flex={'auto'}>
														<Field
															name={`${name}.weight`}
															type={'number'}
															validate={composeValidators(validateRequired, validateNumber, validateGte(0))}
															validateFields={[]}
															render={(props) => (
																<TextField
																	{...props}
																	required
																	label={t('fields.weight.label')}
																	placeholder={t('fields.weight.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col style={{ width: 60, paddingTop: 29 }}>
														<Button
															onClick={(e) => {
																e.preventDefault()
																fields.remove(index)
															}}
															className={'secondary'}
														>
															<DeleteOutlined style={{ fontSize: 18 }}/>
														</Button>
													</Col>
												</Row>
											</Col>
										))}
										<Col span={24}>
											<Button
												onClick={(e) => {
													e.preventDefault()
													fields.push(defaultCollectionValue)
												}}
											>
												{t('loc:Pridať záznam')}
											</Button>
										</Col>
									</Row>
								)}
							/>
						</Col>
					</Row>
				</form>
			)}
		/>
	)
}

export default React.memo(BulkCollectionForm)
