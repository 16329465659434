import { MediaQuery } from '../types/styled'

export const getMediaQueryStyles = (query: MediaQuery | number, styleName: string) => {
	if (typeof query === 'number') {
		return `${styleName}: ${query}px;`
	}

	return `
		${query.xs ? `@media(max-width: 575px) { ${styleName}: ${query.xs}${typeof query.xs === 'number' ? 'px' : ''} }` : ''}
		${query.sm ? `@media(min-width: 576px) { ${styleName}: ${query.sm}${typeof query.sm === 'number' ? 'px' : ''} }` : ''}
		${query.md ? `@media(min-width: 768px) { ${styleName}: ${query.md}${typeof query.md === 'number' ? 'px' : ''} }` : ''}
		${query.lg ? `@media(min-width: 992px) { ${styleName}: ${query.lg}${typeof query.lg === 'number' ? 'px' : ''} }` : ''}
		${query.xl ? `@media(min-width: 1200px) { ${styleName}: ${query.xl}${typeof query.xl === 'number' ? 'px' : ''} }` : ''}
		${query.xxl ? `@media(min-width: 1600px) { ${styleName}: ${query.xxl}${typeof query.xxl === 'number' ? 'px' : ''} }` : ''}
	`
}
