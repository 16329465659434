import React, { useCallback, useEffect } from 'react'
import moment from 'moment'
import * as PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field, propTypes } from 'redux-form'
import { map } from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { validate, validatorOptions } from '../../utils/validator'

import { loadCollectionPlacesSelect } from '../../redux/collectionPlaces/actions'

import locale from '../../resources/locale'

import Modal from '../modals/Modal'

import { FORMS } from '../../utils/enums'

import DateTimePickerField from '../../atoms/DateTimePickerField'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import { getCollectionPlacesSelectOptions } from '../../redux/collectionPlaces/selectors'
import Button from '../buttons/Button'

const validateRequired = validate([validatorOptions.REQUIRED])

const TransferContainerModal = ({ onDismiss, shown, handleSubmit, municipalityID, submitting, hideFieldAssignedFrom }) => {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const selectOptions = useSelector(getCollectionPlacesSelectOptions)

	const validateDateFrom = useCallback((value, allValues) => {
		const error = validateRequired(value)
		if (!error && allValues.assignedTo) {
			const fromDate = moment(value)
			const toDate = moment(allValues.assignedTo)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.FROM_DATE']
			}
		}
		return error
	}, [])

	const normalizeDateFrom = useCallback((value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.startOf('day').toDate()
		}
		return value
	}, [])

	const debouncedSearch = useCallback(async (search, callback) => {
		const places = await dispatch(loadCollectionPlacesSelect({ search, municipalityID }))
		const items = map(places, (item) => ({
			value: item.id, label: `${item.name}${item.customer ? ` - ${item.customer.fullName}` : ''}`
		}))

		callback(items)
		return items
	}, [dispatch, municipalityID])

	useEffect(() => {
		debouncedSearch('', () => {})
	}, [debouncedSearch, municipalityID])

	return (
		<Modal dismiss={onDismiss} shown={shown} size="s">
			{shown && <form onSubmit={handleSubmit} className={'form-horizontal'} noValidate>
				<div className={'modal-header'}>
					{locale['page.collectionPlaces.detail.transfer.title']}
				</div>
				<div className={'modal-content'}>
					<Field
						name='collectionPlaceID'
						placeholder={locale['field.placeholder.collection.place']}
						component={AsyncSelectField}
						validate={validateRequired}
						props={{
							showLabel: true,
							label: t('loc:Zberné miesto'),
							loadOptions: debouncedSearch,
							defaultOptions: selectOptions.values
						}}
					/>
					{!hideFieldAssignedFrom && <Field
						name='assignedFrom'
						placeholder={locale['field.placeholder.assigned.from']}
						component={DateTimePickerField}
						props={{
							showLabel: true,
							defaultOptions: true,
							label: t('loc:Aktívny od'),
							format: locale['common.date.format.date']
						}}
						validate={validateDateFrom}
						normalize={normalizeDateFrom}
					/>}
				</div>
				<div className={'modal-footer clearfix'}>
					<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
						<Col>
							<Button
								disabled={submitting}
								onClick={(e) => {
									e.preventDefault()
									onDismiss()
								}}
								className={'secondary'}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
						<Col>
							<Button
								loading={submitting}
								disabled={submitting}
							>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>}
		</Modal>
	)
}

const form = reduxForm({
	form: FORMS.TRANSFER_CONTAINER,
	destroyOnUnmount: true
})

TransferContainerModal.propTypes = {
	...propTypes,
	municipalityID: PropTypes.number,
	shown: PropTypes.bool.isRequired,
	onDismiss: PropTypes.func.isRequired,
	hideFieldAssignedFrom: PropTypes.bool
}

export default form(TransferContainerModal)
