import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'
import i18next from 'i18next'

// components
import { debounce } from 'lodash'
import Modal from './Modal'

// recources
import locale from '../../resources/locale'

// atoms
import TextInputField from '../../atoms/TextInputField'
import RadioField from '../../atoms/RadioField'

// utils
import { FORMS, CUSTOMER_LEGAL_FORM } from '../../utils/enums'
import { validate, validatorOptions } from '../../utils/validator'
import { searchAddresses } from '../../utils/address'
import SuggestionsInputField from '../../atoms/SuggestionsInputField'
import Button from '../buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateZip = validate([validatorOptions.REQUIRED_STRING, validatorOptions.ZIP])

class CreateCustomer extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		createHandler: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		wasteTypes: PropTypes.object,
		formValues: PropTypes.object,
		initialize: PropTypes.func.isRequired,
		onSelectSuggestion: PropTypes.func.isRequired,
		municipalityID: PropTypes.number
	}

	state = {
		shown: false
	}

	loadAddresses = (search, callback) => {
		const { municipalityID } = this.props
		const filter = { municipalityID, search }
		searchAddresses(filter, callback)
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov'),
		required: true
	}

	streetOptions = {
		onSelectSuggestion: this.props.onSelectSuggestion,
		loadOptions: debounce(this.loadAddresses, 300),
		label: i18next.t('loc:Ulica'),
		showLabel: true,
		autoComplete: 'new-password'
	}

	componentDidMount = () => {
		this.props.reset(FORMS.CREATE_CUSTOMER_FORM)
		this.props.initialize({ legalForm: CUSTOMER_LEGAL_FORM.INDIVIDUAL })
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	validateNumber = (value, allValues) => {
		if (!allValues.number && !allValues.streetNumber) {
			return locale['error.validation.customer.number']
		}
		return undefined
	}

	render = () => {
		const { handleSubmit, createHandler, dismissHandler, shown, formValues, reset } = this.props

		let switchFields = null
		if (formValues.legalForm === CUSTOMER_LEGAL_FORM.INDIVIDUAL) {
			switchFields = (
				<>
					<div className={'col-md-6'}>
						<Field
							name='titleBefore'
							autoComplete={'off'}
							placeholder={i18next.t('loc:Titul pred menom')}
							label={i18next.t('loc:Titul pred menom')}
							component={TextInputField}
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='titleAfter'
							placeholder={i18next.t('loc:Titul za menom')}
							component={TextInputField}
							label={i18next.t('loc:Titul za menom')}
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='name'
							placeholder={i18next.t('loc:Meno')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Meno')}
							required
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='surname'
							placeholder={i18next.t('loc:Priezvisko')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Priezvisko')}
							required
							showLabel
						/>
					</div>

				</>
			)
		} else if (formValues.legalForm === CUSTOMER_LEGAL_FORM.LEGAL) {
			switchFields = (
				<>
					<div className={'col-md-6'}>
						<Field
							name='companyName'
							placeholder={i18next.t('loc:Názov firmy')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Názov firmy')}
							required
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='ico'
							placeholder={i18next.t('loc:IČO')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:IČO')}
							required
							showLabel
						/>
					</div>
				</>
			)
		}
		return (
			<Modal dismiss={dismissHandler} shown={shown} size="m">
				<form onSubmit={handleSubmit((data) => createHandler(data))} className={'form-horizontal'} noValidate autoComplete={'off'}>
					<div className={'modal-header'}>
						{locale['page.customers.add']}
					</div>
					<div className={'modal-content'}>
						<div className="row" style={{ marginBottom: '20px' }}>
							<div className={'col-md-6'}>
								<Field
									name='legalForm'
									props={{
										value: CUSTOMER_LEGAL_FORM.INDIVIDUAL,
										label: locale['page.customers.person'],
										customOnChange: () => reset(FORMS.CREATE_CUSTOMER_FORM)
									}}
									component={RadioField}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='legalForm'
									props={{
										value: CUSTOMER_LEGAL_FORM.LEGAL,
										label: locale['page.customers.company'],
										customOnChange: () => reset(FORMS.CREATE_CUSTOMER_FORM)
									}}
									component={RadioField}
								/>
							</div>
						</div>
						<div className={'row'}>
							{switchFields}
							<div className={'col-md-6'}>
								<Field
									name='street'
									placeholder={i18next.t('loc:Ulica')}
									component={SuggestionsInputField}
									validate={validateRequiredString}
									label={i18next.t('loc:Ulica')}
									props={this.streetOptions}
									required
									showLabel
								/>
							</div>
							<div className={'col-md-3'}>
								<Field
									name="number"
									placeholder={locale['field.placeholder.address.numbers']}
									component={TextInputField}
									validate={this.validateNumber}
									label={i18next.t('loc:Súpisné číslo')}
									required
									showLabel
								/>
							</div>
							<div className={'col-md-3'}>
								<Field
									name="streetNumber"
									placeholder={locale['field.placeholder.address.numbers.street']}
									component={TextInputField}
									validate={this.validateNumber}
									label={i18next.t('loc:Orientačné číslo')}
									required
									showLabel
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='city'
									placeholder={i18next.t('loc:Obec')}
									component={TextInputField}
									validate={validateRequiredString}
									label={i18next.t('loc:Obec')}
									required
									showLabel
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='zip'
									placeholder={i18next.t('loc:PSČ')}
									component={TextInputField}
									validate={validateZip}
									label={locale['field.placeholder.zip']}
									required
									showLabel
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
							<Col>
								<Button
									onClick={this.cancelForm}
									className={'secondary'}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
							<Col>
								<Button>
									{locale['page.customers.add']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

const selector = formValueSelector(FORMS.CREATE_CUSTOMER_FORM)

const mapStateToProps = (state) => ({
	formValues: {
		legalForm: selector(state, 'legalForm')
	}
})

export default reduxForm({
	form: FORMS.CREATE_CUSTOMER_FORM,
	updateUnregisteredFields: true,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps)(CreateCustomer))
