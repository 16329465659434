import i18next from 'i18next'

const validators = {
	collectionRounds: (value: { value: number, label: string }[]) => {
		if (value.length < 2) {
			return i18next.t('CollectionRoundsMergeModal.validation.collectionRoundsNumber', { count: 2 })
		}

		return undefined
	}
}

export default validators
