/* eslint-disable react/prop-types */
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { map, debounce } from 'lodash'
import { Col, Row } from 'antd'
import i18next from 'i18next'

import Modal from './Modal'

import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'

import { validate, validateSelect, validatorOptions } from '../../utils/validator'
import { wasteTypes, colors, customStyles } from '../../utils/select'
import { ENDPOINTS, MODAL as FORMS } from '../../utils/enums'
import { getReq } from '../../utils/request'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import { parseNumber } from '../../utils/utils'
import NumericInputField from '../../atoms/NumericInputField'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateCoefficient = validate([validatorOptions.REQUIRED, validatorOptions.NUMBER])
const validateNumber = validate([validatorOptions.NUMBER])
const validateType = validate([validatorOptions.REQUIRED])
const validateColor = validate([validatorOptions.REQUIRED])
const validateCode = validateSelect([validatorOptions.REQUIRED])

class CreateWasteType extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		createHandler: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired
	}

	searchWasteTypes = (search, callback) => {
		getReq(ENDPOINTS.WASTE_TYPES_OPTIONS, { search, order: 'name', sort: 'ASC' }).then((response) => {
			const items = map(response.data, (item) => ({
				label: `${item.code} - ${item.name}`,
				value: item.id,
				key: item.id
			}))
			return callback(items)
		}).catch(() => callback([]))
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
		this.codesSelectFieldOptions.options = this.props.types
	}

	handleSubmit = (values) => {
		const { createHandler } = this.props
		createHandler({
			name: values.name,
			type: values.type,
			color: values.color,
			coefficient: values.coefficient,
			categoryID: values.code.value,
			collectionYardWeightLimit: values.collectionYardWeightLimit
		})
	}

	codesSelectFieldOptions = {
		label: i18next.t('loc:Katalógové číslo'),
		required: true,
		showLabel: true,
		loadOptions: debounce(this.searchWasteTypes, 300)
	}

	colorsSelectFieldOptions = {
		label: i18next.t('loc:Farba odpadu'),
		required: true,
		showLabel: true,
		options: colors,
		components: {
			Option: ((props) => {
				const { innerProps, isDisabled, value, label } = props
				return !isDisabled ? (
					<div style={customStyles.option({}, props)} className={'react-select-option'} {...innerProps}>
						<div className={'dot'} style={{ backgroundColor: `#${value}` }}/>
						<div>{label}</div>
					</div>
				) : null
			}),
			SingleValue: ((props) => {
				const { innerProps, isDisabled, data } = props
				return !isDisabled ? (
					<div style={customStyles.singleValue({}, props)} className={'react-select-option'} {...innerProps}>
						<div className={'dot'} style={{ backgroundColor: `#${data.value}`, margin: '0px 10px 0px 0px' }}/>
						<div>{data.label}</div>
					</div>
				) : null
			})
		}
	}

	typesSelectFieldOptions = {
		label: i18next.t('loc:Spôsob zberu'),
		required: true,
		showLabel: true,
		options: wasteTypes
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov odpadu'),
		required: true
	}

	coefficientFieldOptions = {
		label: i18next.t('loc:Váhový koeficient (kg/l)'),
		required: true,
		min: 0,
		max: 100000
	}

	collectionYardWeightLimitFieldOptions = {
		label: 'Limit odpadu na zbernom dvore(kg)',
		required: false
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	render() {
		const { handleSubmit, dismissHandler, shown } = this.props
		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit((data) => this.handleSubmit(data))} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['page.waste.types.add']}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='name'
									placeholder={locale['field.placeholder.waste.type.name']}
									component={TextInputField}
									props={this.nameFieldOptions}
									validate={validateName}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='code'
									placeholder={locale['field.placeholder.waste.type.code']}
									component={AsyncSelectField}
									props={this.codesSelectFieldOptions}
									validate={validateCode}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='color'
									placeholder={locale['field.placeholder.waste.type.color']}
									component={SelectField}
									props={this.colorsSelectFieldOptions}
									validate={validateColor}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='type'
									placeholder={locale['field.placeholder.waste.type.type']}
									component={SelectField}
									props={this.typesSelectFieldOptions}
									validate={validateType}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='coefficient'
									placeholder={locale['field.placeholder.waste.type.coefficient']}
									component={NumericInputField}
									normalize={parseNumber}
									props={this.coefficientFieldOptions}
									validate={validateCoefficient}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='collectionYardWeightLimit'
									placeholder={'Zadajte limit'}
									component={NumericInputField}
									normalize={parseNumber}
									props={this.collectionYardWeightLimitFieldOptions}
									validate={validateNumber}
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{locale['page.waste.types.add']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CREATE_WASTE_TYPE,
	touchOnChange: true,
	destroyOnUnmount: true
})(CreateWasteType)
