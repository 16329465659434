import {
	STATISTICS_COUNTS_LOAD_DONE,
	STATISTICS_COUNTS_LOAD_FAIL,
	STATISTICS_COUNTS_LOAD_START,
	STATISTICS_CUMULATIVE_WEIGHT_LOAD_START,
	STATISTICS_CUMULATIVE_WEIGHT_LOAD_FAIL,
	STATISTICS_CUMULATIVE_WEIGHT_LOAD_DONE,
	STATISTICS_TOP_HOUSEHOLDS_LOAD_START,
	STATISTICS_TOP_HOUSEHOLDS_LOAD_FAIL,
	STATISTICS_TOP_HOUSEHOLDS_LOAD_DONE,
	STATISTICS_PROGRESS_COLLECTIONS_LOAD_DONE,
	STATISTICS_PROGRESS_COLLECTIONS_LOAD_START,
	STATISTICS_PROGRESS_COLLECTIONS_LOAD_FAIL,
	STATISTICS_SORTING_RATE_LOAD_START,
	STATISTICS_SORTING_RATE_LOAD_FAIL,
	STATISTICS_SORTING_RATE_LOAD_DONE,
	STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_START,
	STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_DONE,
	STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_FAIL
} from '../types/statistics'
import { RESET_STORE } from '../types/global'

export const initState = {
	counts: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	cumulativeWeight: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	topHouseholds: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	progressCollections: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	sortingRate: {
		isLoading: false,
		isFailure: false,
		data: null
	},
	collectionPlacesMapData: {
		isLoading: false,
		isFailure: false,
		data: null
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case STATISTICS_COUNTS_LOAD_START:
			return {
				...state,
				counts: {
					...initState.counts,
					isLoading: true
				}
			}
		case STATISTICS_COUNTS_LOAD_FAIL:
			return {
				...state,
				counts: {
					...initState.counts,
					isFailure: true
				}
			}
		case STATISTICS_COUNTS_LOAD_DONE:
			return {
				...state,
				counts: {
					...initState.counts,
					data: action.payload.counts
				}
			}
		case STATISTICS_CUMULATIVE_WEIGHT_LOAD_START:
			return {
				...state,
				cumulativeWeight: {
					...initState.cumulativeWeight,
					isLoading: true
				}
			}
		case STATISTICS_CUMULATIVE_WEIGHT_LOAD_FAIL:
			return {
				...state,
				cumulativeWeight: {
					...initState.cumulativeWeight,
					isFailure: true
				}
			}
		case STATISTICS_CUMULATIVE_WEIGHT_LOAD_DONE:
			return {
				...state,
				cumulativeWeight: {
					...initState.cumulativeWeight,
					data: action.payload
				}
			}
		case STATISTICS_TOP_HOUSEHOLDS_LOAD_START:
			return {
				...state,
				topHouseholds: {
					...initState.topHouseholds,
					isLoading: true
				}
			}
		case STATISTICS_TOP_HOUSEHOLDS_LOAD_FAIL:
			return {
				...state,
				topHouseholds: {
					...initState.topHouseholds,
					isFailure: true
				}
			}
		case STATISTICS_TOP_HOUSEHOLDS_LOAD_DONE:
			return {
				...state,
				topHouseholds: {
					...initState.topHouseholds,
					data: action.payload
				}
			}
		case STATISTICS_PROGRESS_COLLECTIONS_LOAD_START:
			return {
				...state,
				progressCollections: {
					...initState.progressCollections,
					isLoading: true
				}
			}
		case STATISTICS_PROGRESS_COLLECTIONS_LOAD_FAIL:
			return {
				...state,
				progressCollections: {
					...initState.progressCollections,
					isFailure: true
				}
			}
		case STATISTICS_PROGRESS_COLLECTIONS_LOAD_DONE:
			return {
				...state,
				progressCollections: {
					...initState.progressCollections,
					data: action.payload
				}
			}
		case STATISTICS_SORTING_RATE_LOAD_START:
			return {
				...state,
				sortingRate: {
					...initState.sortingRate,
					isLoading: true
				}
			}
		case STATISTICS_SORTING_RATE_LOAD_FAIL:
			return {
				...state,
				sortingRate: {
					...initState.sortingRate,
					isFailure: true
				}
			}
		case STATISTICS_SORTING_RATE_LOAD_DONE:
			return {
				...state,
				sortingRate: {
					...initState.sortingRate,
					data: action.payload
				}
			}
		case STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_START:
			return {
				...state,
				collectionPlacesMapData: {
					...initState.collectionPlacesMapData,
					isLoading: true
				}
			}
		case STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_DONE:
			return {
				...state,
				collectionPlacesMapData: {
					...initState.collectionPlacesMapData,
					data: action.payload
				}
			}
		case STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_FAIL:
			return {
				...state,
				collectionPlacesMapData: {
					...initState.collectionPlacesMapData,
					isFailure: true
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return { ...state }
	}
}
