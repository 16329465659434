import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import i18next from 'i18next'

import Popover from 'react-tiny-popover'

import { get } from 'lodash'
import SelectFilter from './SelectFilter'
import { selectItemPropTypes } from './Table'
import { FILTER_POSITION } from '../../utils/enums'
import ColumnsIcon from '../../resources/images/ic-columns.svg'

class TableColumns extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		style: PropTypes.any,
		columns: PropTypes.arrayOf(PropTypes.shape(selectItemPropTypes)).isRequired,
		onChange: PropTypes.func.isRequired,
		onResetColumns: PropTypes.func.isRequired
	}

	state = {
		filterVisible: false
	}

	node = null
	filter = React.createRef()

	constructor(props) {
		super(props)

		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside(event) {
		if (!this.state.filterVisible) {
			return
		}
		const filterNode = get(this.filter, 'current.node.current')
		if ((this.node && !this.node.contains(event.target)) && (filterNode && !filterNode.contains(event.target))) {
			this.setState({ filterVisible: false })
		}
	}

	toggleFilter = (e) => {
		this.setState({ filterVisible: !this.state.filterVisible })
		e.stopPropagation()
	}

	render() {
		const { columns, onChange, onResetColumns, style, id } = this.props
		const { filterVisible } = this.state

		const filterIconStyles = {
			background: `url(${ColumnsIcon}) no-repeat center`,
			backgroundSize: 'contain'
		}

		return (
			<th
				style={style}
				className={'columns text-center'}
			>
				<Popover
					isOpen={filterVisible}
					position={'bottom'}
					containerClassName={'table-filter-wrapper'}
					content={(
						<SelectFilter
							ref={this.filter}
							id={id}
							name={'columns'}
							title={i18next.t('loc:Zobrazené stĺpce')}
							position={FILTER_POSITION.LEFT}
							visible={filterVisible}
							values={columns}
							isLoading={false}
							onChange={onChange}
							onFilter={() => this.setState({ filterVisible: false })}
							onReset={onResetColumns}
						/>
					)}
					arrowColor={'white'}
					arrowSize={10}
				>
					<div
						// eslint-disable-next-line no-return-assign
						ref={(ref) => this.node = ref}
						onClick={this.toggleFilter}
						className={'filter-icon'}
						style={{ maxWidth: '50px' }}
					>
						<div className={'icon'} style={filterIconStyles}/>
					</div>
				</Popover>
			</th>
		)
	}
}

export default TableColumns
