import React from 'react'
import i18next from 'i18next'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import { map, get } from 'lodash'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../../resources/locale'

// components

import CodesTableRow from './CodesTableRow'
// atoms
import TableHead from '../../../atoms/TableHead'
import { EmptyTableState } from '../../../components/EmptyTableState'

class CodesTable extends React.Component {
	static propTypes = {
		codes: PropTypes.shape({
			data: PropTypes.shape({
				codes: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					code: PropTypes.string.isRequired,
					serialNumber: PropTypes.string.isRequired,
					collectionPlace: PropTypes.string
				})).isRequired
			}),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		onSort: PropTypes.func.isRequired,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string
	}

	render() {
		const { codes, onSort, sortingColumn, sortingDirection } = this.props
		const rows = map(get(codes, 'data.codes'), (item) =>
			<CodesTableRow
				key={get(item, 'id')}
				item={item}
			/>)

		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'code'}
								isSorting={sortingColumn === 'code'}
								title={i18next.t('loc:Kód')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'serialNumber'}
								isSorting={sortingColumn === 'serialNumber'}
								title={i18next.t('loc:Číslo')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'collectionPlace'}
								className={'text-right'}
								isSorting={sortingColumn === 'collectionPlace'}
								title={i18next.t('loc:Zberné miesto')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!codes.isLoading && get(codes, 'data.portions') && !get(codes, 'data.portions.length') &&
				<EmptyTableState title={locale['page.codePortions.none']}/>}
				<div className={cx('table-loading', { hidden: !codes.isLoading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.codePortions.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default CodesTable
