import React, { useCallback } from 'react'
import cx from 'classnames'
import styled from 'styled-components'

import { map } from 'lodash'

import { Col, Row } from 'antd'

import { ButtonElement } from './Button'

const StyledCol = styled(Col)`
	> ${ButtonElement} {
		border-radius: 0;
		box-shadow: none !important;
	}
	
	&:first-of-type > ${ButtonElement} {
		border-radius: 8px 0 0 8px;
	}

	&:last-of-type > ${ButtonElement} {
		border-radius: 0 8px 8px 0;
	}
`

type Props<T> = {
	value: T | null
	allowClear?: boolean
	onChange: (value: T | null) => void
	items: { value: T, label: string }[]
}

function SegmentedButton<T>({ value, items, onChange, allowClear = false }: Props<T>) {
	const handleChange = useCallback((newValue: T) => {
		if (value === newValue && allowClear) {
			onChange(null)
		} else if (value !== newValue) {
			onChange(newValue)
		}
	}, [allowClear, onChange, value])

	return (
		<Row wrap={false}>
			{map(items, (item) => (
				<StyledCol key={`${item.value}`}>
					<ButtonElement
						onClick={() => handleChange(item.value)}
						className={cx({ secondary: item.value !== value })}
					>
						{item.label}
					</ButtonElement>
				</StyledCol>
			))}
		</Row>
	)
}

export default SegmentedButton
