import React from 'react'
import { bindActionCreators } from 'redux'
import { reduxForm, unregisterField, formValueSelector, change } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'

import { get, map, filter } from 'lodash'
import locale from '../../resources/locale'

import { statusPush } from '../../actions/statusActions'

import { COLLECTION_PLACE_TYPE, ENDPOINTS, FORMS } from '../../utils/enums'
import CollectionPlaceFormFields from './CollectionPlaceFormFields'
import { getReq } from '../../utils/request'
import InfoFooter from '../InfoFooter'
import { getAuthUser } from '../../redux/authentication'
import Button from '../buttons/Button'

class CollectionPlaceForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		deleteHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		unregisterField: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		type: PropTypes.string,
		change: PropTypes.func.isRequired,
		pushStatus: PropTypes.func.isRequired,
		municipalityID: PropTypes.number
	}

	loadFlatHouses = (keyword, callback) => {
		const { initialValues } = this.props
		const context = {
			municipalityID: get(initialValues, 'municipalityID'),
			type: COLLECTION_PLACE_TYPE.FLAT_HOUSE
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.COLLECTION_PLACES, context).then((response) => {
			const items = map(get(response, 'data.collectionPlaces'), (item) => ({
				value: item.id,
				label: item.name,
				item
			}))
			callback(filter(items, (item) => item.value !== initialValues.id))
		}).catch(() => callback([]))
	}

	loadNests = (keyword, callback) => {
		const { initialValues } = this.props
		const context = {
			municipalityID: get(initialValues, 'municipalityID'),
			type: COLLECTION_PLACE_TYPE.NEST
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.COLLECTION_PLACES, context).then((response) => {
			const items = map(get(response, 'data.collectionPlaces'), (item) => ({
				value: item.id,
				label: item.name,
				item
			}))
			callback(filter(items, (item) => item.value !== initialValues.id))
		}).catch(() => callback([]))
	}

	handleTypeChange = (value) => {
		const { unregisterField } = this.props
		if (value !== COLLECTION_PLACE_TYPE.COLLECTION_YARD && value !== COLLECTION_PLACE_TYPE.NEST) {
			unregisterField(FORMS.COLLECTION_PLACE_FORM, 'name')
		}
	}

	handleParentChange = (value) => {
		const { change } = this.props
		const address = get(value, 'item.address')

		change('parent', null)

		if (address) {
			change('street', address.street)
			change('number', address.number)
			change('streetNumber', address.streetNumber)
			change('city', address.city)
			change('zip', address.zip)
		}
	}

	removeCollectionPlace = (e) => {
		e.preventDefault()
		const { deleteHandler } = this.props
		deleteHandler()
	}

	onSelectAddress = (value) => {
		const { change } = this.props

		change(FORMS.COLLECTION_PLACE_FORM, 'street', value.street)
		change(FORMS.COLLECTION_PLACE_FORM, 'zip', value.zip)
		change(FORMS.COLLECTION_PLACE_FORM, 'city', value.city)
	}

	render() {
		const { handleSubmit, submitHandler, initialValues, cancelHandler, type, municipalityID } = this.props

		return (
			<form onSubmit={handleSubmit(submitHandler)} noValidate>
				<div className={'box'}>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<CollectionPlaceFormFields
								onTypeChange={this.handleTypeChange}
								onParentChange={this.handleParentChange}
								loadFlatHouses={this.loadFlatHouses}
								loadNests={this.loadNests}
								municipalityID={municipalityID}
								onSelectSuggestion={this.onSelectAddress}
								type={type}
							/>
							<InfoFooter
								createdAt={get(initialValues, 'createdAt')}
								creator={get(initialValues, 'creator')}
								updatedAt={get(initialValues, 'updatedAt')}
								editor={get(initialValues, 'editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button onClick={this.removeCollectionPlace} className={'danger'}>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={cancelHandler}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const selector = formValueSelector(FORMS.COLLECTION_PLACE_FORM)

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	user: getAuthUser(state),
	type: selector(state, 'type')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	unregisterField: bindActionCreators(unregisterField, dispatch),
	change: bindActionCreators(change, dispatch)
})

export default reduxForm({
	form: FORMS.COLLECTION_PLACE_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(CollectionPlaceForm))
