import { RESET_STORE } from '../../types/global'
import { CUSTOMER_CODES_ACTIONS } from './constants'

export const initState = {
	list: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	select: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case CUSTOMER_CODES_ACTIONS.LIST_LOAD_START:
			return {
				...initState,
				...state,
				list: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case CUSTOMER_CODES_ACTIONS.LIST_LOAD_FAIL:
			return {
				...initState,
				...state,
				list: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case CUSTOMER_CODES_ACTIONS.LIST_LOAD_DONE:
			return {
				...initState,
				...state,
				list: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case CUSTOMER_CODES_ACTIONS.SELECT_LOAD_START:
			return {
				...initState,
				...state,
				select: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case CUSTOMER_CODES_ACTIONS.SELECT_LOAD_FAIL:
			return {
				...initState,
				...state,
				select: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case CUSTOMER_CODES_ACTIONS.SELECT_LOAD_DONE:
			return {
				...initState,
				...state,
				select: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return { ...state }
	}
}
