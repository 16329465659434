import { deleteReq, getReq, patchReq, postReq } from '../utils/request'
import { ListResponse, Response } from '../types/api'
import {
	BulkCollection, BulkCollectionCustomer, BulkCollectionListItem, WasteTypesSummary, CollectionYardVisits
} from '../types/data'

export default {
	loadBulkCollection: (id: string | number): Response<BulkCollection> => getReq(`/api/admin/bulk-collections/${id}`, {}),
	createBulkCollection: (municipalityID: string | number, data: object) => postReq(`/api/admin/municipalities/${municipalityID}/bulk-collections`, {}, data),
	updateBulkCollection: (id: string | number, data: object) => patchReq(`/api/admin/bulk-collections/${id}`, {}, data),
	loadWasteTypeSummary: (filters: object): Response<{ collections: WasteTypesSummary[] }> => getReq('/api/admin/bulk-collections/statistics/waste-types-summary', filters),
	loadCustomersSummary: (filters: object): ListResponse<{ customers: BulkCollectionCustomer[] }> => getReq('/api/admin/bulk-collections/statistics/customers-collections', filters),
	loadBulkCollections: (filters: object): ListResponse<{ bulkCollections: BulkCollectionListItem[] }> => getReq('/api/admin/bulk-collections', filters),
	deleteBulkCollection: (id: string | number) => deleteReq(`/api/admin/bulk-collections/${id}`, {}),
	loadVisits: (filters: object): Response<{ visits: CollectionYardVisits }> => getReq('/api/admin/bulk-collections/statistics/collection-yard-visits', filters)
}
