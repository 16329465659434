import React, { Component } from 'react'
import cx from 'classnames'
import { map, get, filter, reduce } from 'lodash'
import * as PropTypes from 'prop-types'

import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faUndo, faFolderOpen } from '@fortawesome/free-solid-svg-icons'
import { FILTER_POSITIONS, FILTER_TYPES } from '../../utils/enums'

import { SORT_ASC, SORT_DESC } from '../../types/table'

import TableHead from './TableHead'
import TableRow from './TableRow'
import locale from '../../resources/locale'

export const columnPropTypes = {
	name: PropTypes.string.isRequired,
	sortable: PropTypes.bool.isRequired,

	title: PropTypes.string.isRequired,
	className: PropTypes.string,
	style: PropTypes.any,
	render: PropTypes.func,
	filter: PropTypes.shape({
		title: PropTypes.string,
		type: PropTypes.oneOf(FILTER_TYPES).isRequired,
		icon: PropTypes.string,
		isLoading: PropTypes.bool,
		values: PropTypes.array,
		value: PropTypes.any,
		renderLabel: PropTypes.func,
		position: PropTypes.oneOf(FILTER_POSITIONS)
	})
}

export const selectItemPropTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired
}

export const selectFilterProps = {
	isLoading: PropTypes.bool.isRequired,
	values: PropTypes.arrayOf(PropTypes.shape(selectItemPropTypes)).isRequired
}

class Table extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		style: PropTypes.any,
		name: PropTypes.string.isRequired,
		columns: PropTypes.arrayOf(PropTypes.shape(columnPropTypes)).isRequired,
		filters: PropTypes.object.isRequired,
		order: PropTypes.string,
		direction: PropTypes.oneOf([SORT_ASC, SORT_DESC]),
		data: PropTypes.arrayOf(PropTypes.object),
		isLoading: PropTypes.bool.isRequired,
		isFailure: PropTypes.bool.isRequired,
		isSelectable: PropTypes.bool.isRequired,
		onResetFilters: PropTypes.func.isRequired,
		onSort: PropTypes.func.isRequired,
		onFilter: PropTypes.func.isRequired,
		onDetail: PropTypes.func,
		onChangeColumns: PropTypes.func.isRequired,
		onSelect: PropTypes.func,
		getActions: PropTypes.func,
		defaultColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
		tableColumns: PropTypes.arrayOf(PropTypes.shape(selectItemPropTypes)).isRequired,
		selectedItems: PropTypes.objectOf(PropTypes.any)
	}

	static defaultProps = {
		id: 'table'
	}

	HeadComponent = null

	constructor(props) {
		super(props)

		this.HeadComponent = TableHead(props.name)
	}

	toggleSelectAll = (isSelectedAll) => {
		const { data, selectedItems, onSelect } = this.props

		if (isSelectedAll) {
			onSelect(data)
		} else {
			const notSelected = filter(data, (item) => !selectedItems[`${item.id}`])
			onSelect(notSelected)
		}
	}

	render() {
		const {
			columns, data, filters, onResetFilters, onFilter, onDetail, onSelect, getActions, onChangeColumns,
			tableColumns, defaultColumns, isSelectable, selectedItems, isLoading, isFailure, id, style, onSort
		} = this.props

		let isSelectedAll = !!get(data, 'length')

		let content
		let rows

		if (isFailure) {
			content = (
				<div className={cx('table-loading', { hidden: false })}>
					<div>
						<span>{locale['table.loading.failure']}</span>
					</div>
				</div>
			)
		} else if (!data || isLoading) {
			content = (
				<div className={cx('table-loading', { hidden: false })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['table.loading.data']}</span>
					</div>
				</div>
			)
		} else {
			rows = map(data, (dataItem, index) => {
				const isSelected = isSelectable && !!selectedItems[dataItem.id]
				isSelectedAll = isSelectedAll && isSelected

				return (
					<TableRow
						key={dataItem.id}
						columns={columns}
						data={dataItem}
						isSelectable={isSelectable}
						isSelected={isSelected}
						getActions={getActions}
						onSelect={onSelect}
						onDetail={onDetail}
						countIndex={index}
					/>
				)
			})

			if (!data.length) {
				const ignoredFilters = ['columns', 'limit', 'page', 'order']
				const haveActiveFilters = reduce(filters, (bool, filter, key) => {
					if (ignoredFilters.includes(key)) {
						return bool
					}
					return bool || !!filter
				}, false)

				content = (
					<div className={cx('table-loading', { hidden: false })}>
						<div onClick={haveActiveFilters ? () => onResetFilters() : undefined} style={{ cursor: haveActiveFilters ? 'pointer' : 'inherit' }}>
							<FontAwesomeIcon icon={haveActiveFilters ? faUndo : faFolderOpen} size={'2x'}/>
							<span>{haveActiveFilters ? locale['table.loading.reset.filter'] : locale['table.loading.no.data']}</span>
						</div>
					</div>
				)
			}
		}

		return (
			<ScrollSync>
				<div
					className={cx('table-wrapper scrollable sticky', { 'with-data': data && data.length > 0 })}
					style={style}
				>
					<div
						className={'headers'}
					>
						<ScrollSyncPane>
							<div className={'scroller'}>
								<table>
									<this.HeadComponent
										tableColumns={tableColumns}
										defaultColumns={defaultColumns}
										onChangeColumns={onChangeColumns}
										isSelectable={isSelectable}
										isSelected={isSelectedAll}
										onSelect={() => this.toggleSelectAll(isSelectedAll)}
										onSubmit={(values) => onFilter({ ...values, page: 1 })}
										columns={columns}
										filters={filters}
										onSort={onSort}
										id={id}
									/>
								</table>
							</div>
						</ScrollSyncPane>
					</div>
					<div className={'content'}>
						<ScrollSyncPane>
							<div className={'scroller no-scrollbar'}>
								<table>
									<thead>
										<tr>
											{isSelectable && <th style={{ width: '70px' }}/>}
											{map(columns, (column) =>
												<th
													key={column.name}
													style={{ height: 1 }}
													className={cx('sortable regular', column.className)}
												/>)}
											<th style={{ height: 1, color: 'transparent' }} className={'columns'}/>
										</tr>
									</thead>
									<tbody>
										{rows}
									</tbody>
								</table>
							</div>
						</ScrollSyncPane>
					</div>
					{content}
				</div>
			</ScrollSync>
		)
	}
}

export default Table
