import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { capitalize, map, maxBy, times } from 'lodash'

import Chart from 'chart.js/auto'
import dataLabelsPlugin from 'chartjs-plugin-datalabels'

import { Col, Row } from 'antd'

import theme from '../../../utils/theme'
import { TIME_SPAN } from '../../../utils/enums'

import { filtersActions, getCollectionYardVisitsFilters } from '../../../redux/filters'

import { bulkCollections } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SegmentedButton from '../../../components/buttons/SegmentedButton'
import SpinLoading from '../../../components/SpinLoading'

import { SubHeading, Text } from '../../../components/Typography'
import { createYearsList } from '../../../utils/helpers'
import { InputWrapper, Label, Select } from '../../../components/form/Styled'

Chart.register(dataLabelsPlugin)

const ChartWrapper = styled.div`
	height: 250px;
`

type Props = {
	municipalityID?: string | number
}

const baseOptions = {
	borderRadius: 2,
	pointRadius: 2,
	maxBarThickness: 25,
	skipNull: true
}

const years = createYearsList()

const createLabels = (timeSpan: TIME_SPAN, values: string[]): string[] | string[][] => {
	const date = dayjs()

	switch (timeSpan) {
		case TIME_SPAN.MONTHLY:
			return map(values, (time) => capitalize(date.set('month', Number(time) - 1).format('MMM')))
		case TIME_SPAN.WEEKLY:
			return map(values, (time) => [capitalize(date.set('day', Number(time)).format('ddd')), `${time}.`])
		case TIME_SPAN.DAILY:
			return map(values, (time) => `${time}:00`)
		default:
			return []
	}
}

const CollectionYardCollections = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const dispatch = useDispatch()
	const filters = useSelector(getCollectionYardVisitsFilters)

	const [summaryState, loadSummary] = useDataLoader(bulkCollections.loadVisits)

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const chartRef = useRef<Chart | null>(null)

	const periodTypeOptions = useMemo(() => [{
		value: TIME_SPAN.DAILY,
		label: t('TIME_SPAN.DAILY')
	}, {
		value: TIME_SPAN.WEEKLY,
		label: t('TIME_SPAN.WEEKLY')
	}, {
		value: TIME_SPAN.MONTHLY,
		label: t('TIME_SPAN.MONTHLY')
	}], [t])

	const dayOptions = useMemo(() => {
		const date = dayjs().startOf('week')
		return times(7, (day) => ({
			value: day + 1,
			label: capitalize(date.add(day, 'day').format('ddd'))
		}))
	}, [])

	const yearOptions = useMemo(() => years.map((year) => ({
		value: year, label: `${year}`
	})), [])

	const handleChangeDay = useCallback((day: number | null) => {
		dispatch(filtersActions.saveFilters({ key: 'collectionYardVisits', filters: { ...filters, day } }))
	}, [dispatch, filters])

	const handleChangeYear = useCallback((year) => {
		dispatch(filtersActions.saveFilters({ key: 'collectionYardVisits', filters: { ...filters, year } }))
	}, [dispatch, filters])

	const handleChangeTimeSpan = useCallback((timeSpan: TIME_SPAN | null) => {
		dispatch(filtersActions.saveFilters({ key: 'collectionYardVisits', filters: { ...filters, timeSpan } }))
	}, [dispatch, filters])

	useEffect(() => {
		const { timeSpan, year, day } = filters

		const date = dayjs().set('year', year)

		const dateFrom = date.startOf('year').toISOString()
		const dateTo = date.endOf('year').toISOString()

		loadSummary({
			timeSpan,
			municipalityID,
			dateFrom,
			dateTo,
			day
		})
	}, [filters.day, loadSummary, municipalityID, filters.timeSpan, filters])

	useEffect(() => {
		if (summaryState.data) {
			const { visits } = summaryState.data

			const maxVisit = maxBy(Object.values(visits)) || 10

			const data = {
				labels: createLabels(filters.timeSpan, Object.keys(visits)),
				datasets: [{
					...baseOptions,
					data: Object.values(visits),
					backgroundColor: theme.colors.primary['300']
				}, {
					...baseOptions,
					data: times(Object.values(visits).length, () => maxVisit),
					backgroundColor: theme.colors.neutral['100'],
					hoverBackgroundColor: theme.colors.neutral['100']
				}]
			}

			chartRef.current?.destroy()
			const context = canvasRef.current?.getContext('2d')

			if (context) {
				chartRef.current = new Chart(context, {
					type: 'bar',
					// @ts-ignore
					data,
					options: {
						animation: {
							duration: (context) => context.datasetIndex === 0 ? 1500 : 0
						},
						maintainAspectRatio: false,
						layout: {
							padding: {
								bottom: 15
							}
						},
						scales: {
							x: {
								stacked: true,
								position: 'top',
								grid: {
									display: false,
									drawBorder: false
								}
							},
							x2: {
								position: 'bottom',
								labels: []
							},
							y: {
								beginAtZero: true,
								stacked: false,
								display: false,
								position: 'left',
								grid: {
									display: false,
									drawBorder: false
								}
							}
						},
						plugins: {
							datalabels: {
								anchor: 'start',
								align: 'top',
								offset: -25,
								display: (context) => context.datasetIndex === 0
							},
							tooltip: {
								enabled: false
							},
							legend: {
								display: false
							}
						}
					}
				})
			}
		}
	}, [filters.timeSpan, summaryState.data, t])

	let content

	if (summaryState.isLoading) {
		content = <SpinLoading height={'100%'}/>
	} else if (summaryState.data) {
		content = (
			<Row gutter={16} align={'bottom'} wrap={false}>
				<Col>
					<Text className={'grey'}>{t('BulkCollectionsPage.visitCount')}</Text>
				</Col>
				<Col flex={1}>
					<canvas height={'250px'} ref={canvasRef}/>
				</Col>
			</Row>
		)
	}

	return (
		<Row gutter={[16, 16]} justify={'space-between'} align={'bottom'}>
			<Col span={24}>
				<SubHeading>{t('BulkCollectionsPage.collectionYardVisits')}</SubHeading>
			</Col>
			<Col flex={'350px'} style={{ height: 62 }}>
				<InputWrapper>
					<Label>{t('BulkCollectionsPage.period')}</Label>
					<Select
						value={filters.year}
						options={yearOptions}
						onChange={handleChangeYear}
					/>
				</InputWrapper>
			</Col>
			<Col>
				<Row gutter={[16, 16]}>
					{filters.timeSpan === TIME_SPAN.DAILY &&
					<Col>
						<SegmentedButton
							value={filters.day}
							allowClear={true}
							onChange={handleChangeDay}
							items={dayOptions}
						/>
					</Col>}
					<Col>
						<SegmentedButton
							value={filters.timeSpan}
							onChange={handleChangeTimeSpan}
							items={periodTypeOptions}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ChartWrapper>
					{content}
				</ChartWrapper>
			</Col>
		</Row>
	)
}

export default CollectionYardCollections
