import React from 'react'
import { useTranslation } from 'react-i18next'
import { intersection, some } from 'lodash'

import { MunicipalityBase, Profile } from '../../../types/data'
import { PERMISSIONS } from '../../../utils/enums'

import MenuLink from './MenuLink'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import Container from '../../../resources/images/ic-container.svg'
import Pass from '../../../resources/images/ic-pass.svg'
import Trash from '../../../resources/images/ic-trash.svg'
import Users from '../../../resources/images/ic-users.svg'
import Scanner from '../../../resources/images/ic-scanner.svg'

const permissions = [
	PERMISSIONS.ADMINISTRATOR,
	PERMISSIONS.MUNICIPALITY_ADMIN,
	PERMISSIONS.MUNICIPALITY_WORKER
]

type Props = {
	authUser?: Profile | null
	municipality: MunicipalityBase
}

const Municipality = ({ authUser, municipality, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin && some(authUser?.municipalities, (item) => !intersection(item.permissions, permissions).length)) {
		return null
	}

	return (
		<StyledItemGroup
			{...props}
			key={'MUNICIPALITY'}
			title={t('paths:MUNICIPALITY.title')}
		>
			<StyledItem key={'MUNICIPALITY.settings'}>
				<Icon icon={Pass}/>
				<MenuLink basePath={'paths:MUNICIPALITY.settings'} municipality={municipality}>
					{t('paths:MUNICIPALITY.settings.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'MUNICIPALITY.wasteTypes'}>
				<Icon icon={Container}/>
				<MenuLink basePath={'paths:MUNICIPALITY.wasteTypes'} municipality={municipality}>
					{t('paths:MUNICIPALITY.wasteTypes.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'MUNICIPALITY.containerTypes'}>
				<Icon icon={Trash}/>
				<MenuLink basePath={'paths:MUNICIPALITY.containerTypes'} municipality={municipality}>
					{t('paths:MUNICIPALITY.containerTypes.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'MUNICIPALITY.users'}>
				<Icon icon={Users}/>
				<MenuLink basePath={'paths:MUNICIPALITY.users'} municipality={municipality}>
					{t('paths:MUNICIPALITY.users.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'MUNICIPALITY.devices'}>
				<Icon icon={Scanner}/>
				<MenuLink basePath={'paths:MUNICIPALITY.devices'} municipality={municipality}>
					{t('paths:MUNICIPALITY.devices.title')}
				</MenuLink>
			</StyledItem>
		</StyledItemGroup>
	)
}

export default Municipality
