import { createSelector } from 'reselect'
import { get, map, filter } from 'lodash'
import { MUNICIPALITY_TYPE } from '../../utils/enums'

export const getMunicipalitiesList = (state) => state.municipalities.list
export const getSelectedMunicipality = (state) => get(state, 'selectedMunicipality.municipality.data')
export const getLastCollectionYard = (state) => get(state, 'selectedMunicipality.lastCollectionYard')
export const getMunicipalitiesSelectOptions = createSelector([getMunicipalitiesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.municipalities'), (item) => ({
		value: item.id,
		label: item.name
	}))
}))

export const getMunicipalitiesWasteCompaniesSelectOptions = createSelector([getMunicipalitiesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(filter(get(list, 'data.municipalities'), (item) => item.type === MUNICIPALITY_TYPE.WASTE_COMPANY), (item) => ({
		value: item.id,
		label: item.name
	}))
}))
