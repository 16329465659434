import React from 'react'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash'
import moment from 'moment-timezone'
import { Col, Row } from 'antd'

// atoms
import TextInputField from '../../../atoms/TextInputField'
import SuggestionsInputField from '../../../atoms/SuggestionsInputField'

// components
import InfoFooter from '../../../components/InfoFooter'

// resources
import locale from '../../../resources/locale'

// actions
import { statusPush } from '../../../actions/statusActions'

// utils
import { FORMS, CUSTOMER_LEGAL_FORM } from '../../../utils/enums'
import { validate, validatorOptions } from '../../../utils/validator'
import { searchAddresses } from '../../../utils/address'
import Button from '../../../components/buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateZip = validate([validatorOptions.REQUIRED_STRING, validatorOptions.ZIP])

class CustomerForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		initialValues: PropTypes.object,
		onSetActive: PropTypes.func.isRequired,
		pushStatus: PropTypes.func.isRequired,
		backBtnCallback: PropTypes.func.isRequired,
		formValues: PropTypes.object,
		deleteBtnCallback: PropTypes.func.isRequired,
		customer: PropTypes.object,
		municipalityID: PropTypes.number,
		onSelectSuggestion: PropTypes.func.isRequired
	}

	loadAddresses = (search, callback) => {
		const { municipalityID } = this.props
		const filter = { municipalityID, search }
		searchAddresses(filter, callback)
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov'),
		required: true
	}

	streetOptions = {
		onSelectSuggestion: this.props.onSelectSuggestion,
		loadOptions: debounce(this.loadAddresses, 300),
		label: i18next.t('loc:Ulica'),
		showLabel: true
	}

	setActiveCustomer = (e) => {
		e.preventDefault()
		const { onSetActive } = this.props
		onSetActive()
	}

	getActivateTitle = (customer) => {
		if (customer.activeTo) {
			if (moment(customer.activeTo) > moment()) {
				return locale['page.customers.remove.active.to']
			}
			return locale['page.customers.detail.activate']
		}
		return locale['page.customers.detail.deactivate']
	}

	validateNumber = (value, allValues) => {
		if (!allValues.number && !allValues.streetNumber) {
			return locale['error.validation.customer.number']
		}

		return undefined
	}

	render = () => {
		const { handleSubmit, formValues, deleteBtnCallback, backBtnCallback, customer, initialValues } = this.props
		let switchFields = null
		if (formValues.legalForm === CUSTOMER_LEGAL_FORM.INDIVIDUAL) {
			switchFields = (
				<>
					<div className={'col-md-6'}>
						<Field
							name='titleBefore'
							placeholder={i18next.t('loc:Titul pred menom')}
							label={i18next.t('loc:Titul pred menom')}
							component={TextInputField}
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='titleAfter'
							placeholder={i18next.t('loc:Titul za menom')}
							component={TextInputField}
							label={i18next.t('loc:Titul za menom')}
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='name'
							placeholder={i18next.t('loc:Meno')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Meno')}
							required
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='surname'
							placeholder={i18next.t('loc:Priezvisko')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Priezvisko')}
							required
							showLabel
						/>
					</div>

				</>
			)
		} else if (formValues.legalForm === CUSTOMER_LEGAL_FORM.LEGAL) {
			switchFields = (
				<>
					<div className={'col-md-6'}>
						<Field
							name='companyName'
							placeholder={i18next.t('loc:Názov firmy')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:Názov firmy')}
							required
							showLabel
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='ico'
							placeholder={i18next.t('loc:IČO')}
							component={TextInputField}
							validate={validateRequiredString}
							label={i18next.t('loc:IČO')}
							required
							showLabel
						/>
					</div>
				</>
			)
		}
		return (
			<form onSubmit={handleSubmit} className={'form-horizontal'} noValidate>
				<div className={'box'}>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}>
							<Row justify={'end'}>
								<Col>
									<Button
										style={{ marginBottom: '20px', marginRight: '-40px' }}
										onClick={this.setActiveCustomer}
									>
										{this.getActivateTitle(customer)}
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								{switchFields}
								<div className={'col-md-6'}>
									<Field
										name='street'
										placeholder={i18next.t('loc:Ulica')}
										component={SuggestionsInputField}
										validate={validateRequiredString}
										props={this.streetOptions}
										required
										showLabel
									/>
								</div>
								<div className={'col-md-3'}>
									<Field
										name="number"
										placeholder={locale['field.placeholder.address.numbers']}
										component={TextInputField}
										label={i18next.t('loc:Súpisné číslo')}
										validate={this.validateNumber}
										showLabel
									/>
								</div>
								<div className={'col-md-3'}>
									<Field
										name="streetNumber"
										placeholder={locale['field.placeholder.address.numbers.street']}
										component={TextInputField}
										label={i18next.t('loc:Orientačné číslo')}
										validate={this.validateNumber}
										showLabel
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='city'
										placeholder={i18next.t('loc:Obec')}
										component={TextInputField}
										validate={validateRequiredString}
										label={i18next.t('loc:Obec')}
										required
										showLabel
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='zip'
										placeholder={i18next.t('loc:PSČ')}
										component={TextInputField}
										validate={validateZip}
										label={locale['field.placeholder.zip']}
										required
										showLabel
									/>
								</div>
							</div>
							<InfoFooter
								createdAt={get(initialValues, 'createdAt')}
								creator={get(initialValues, 'creator')}
								updatedAt={get(initialValues, 'updatedAt')}
								editor={get(initialValues, 'editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button onClick={deleteBtnCallback} className={'danger'}>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={backBtnCallback}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const selector = formValueSelector(FORMS.UPDATE_CUSTOMER_FORM)

const mapStateToProps = (state) => ({
	formValues: {
		legalForm: selector(state, 'legalForm')
	}
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch)
})

const form = reduxForm({
	form: FORMS.UPDATE_CUSTOMER_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(CustomerForm)

export default connect(mapStateToProps, mapDispatchToProps)(form)
