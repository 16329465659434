import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { keyBy, map } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import { getCollectionRoundDetail, getCollectionRoundOverview } from '../../../redux/collectionRounds/selectors'
import { loadCollectionRoundOverview } from '../../../redux/collectionRounds/actions'
import ExportButton from '../../../components/buttons/ExportButton'

const Dot = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 6px;
	margin-right: 10px;
`

const SummaryCell = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;

	&:not(:last-of-type) {
		border-bottom: 1px solid #ccc;
	}

	&.heading {
		background-color: #f1eff9;
	}

	&.legend {
		background-color: #f6f7fb;
	}
`

const SummaryColumn = styled.div`
	min-width: 90px;
	text-align: center;

	&:not(:last-of-type) > ${SummaryCell} {
		border-right: 1px solid #ccc;
	}

	&.flex {
		flex: 1;
	}
`

const SummaryTable = styled.div`
	border-radius: 10px;
	border: 1px solid #ccc;
	display: flex;
	overflow: hidden;
	margin-bottom: 30px;
`

const CollectionRoundOverview = () => {
	const dispatch = useDispatch()
	const detail = useSelector(getCollectionRoundDetail)
	const overview = useSelector(getCollectionRoundOverview)

	useEffect(() => {
		if (detail.data) {
			dispatch(loadCollectionRoundOverview(detail.data.id))
		}
	}, [detail.data, dispatch])

	const content = useMemo(() => {
		if (overview.data) {
			const {
				wasteTypesNames,
				wasteTypesCapacities,
				wasteTypes,
				containerTypesName,
				collectionsByStreets,
				counts,
				streets
			} = overview.data

			return (
				<>
					<div className={'row'} style={{ marginBottom: 30 }}>
						<div className={'col-md-6'}>
							<ExportButton
								url={`/api/v1/collection-rounds/${detail.data?.id}/overview/export`}
							/>
						</div>
					</div>
					<div className={'chart-title collection-place'} style={{ marginBottom: 15 }}>
						<span>Typy odpadov</span>
					</div>
					<SummaryTable>
						<SummaryColumn>
							<SummaryCell className={'legend'}/>
							{map(wasteTypesCapacities, (capacity) => (
								<SummaryCell key={capacity} className={'legend'}>{capacity}l</SummaryCell>
							))}
						</SummaryColumn>
						{map(wasteTypesNames, (name) => {
							const grouped = keyBy(wasteTypes[name], 'capacity')
							const item = wasteTypes[name][0]
							const color = item ? item.color : '000000'

							return (
								<SummaryColumn key={name} className={'flex'}>
									<SummaryCell className={'heading'}>
										<Dot style={{ backgroundColor: `#${color}` }}/>
										<span>{name}</span>
									</SummaryCell>
									{map(wasteTypesCapacities, (capacity) => {
										const item = grouped[`${capacity}`]
										return <SummaryCell key={capacity}>{item?.collections || 0}</SummaryCell>
									})}
								</SummaryColumn>
							)
						})}
					</SummaryTable>

					<div className={'chart-title collection-place'} style={{ marginBottom: 15 }}>
						<span>Ulice</span>
					</div>
					<SummaryTable>
						<SummaryColumn>
							<SummaryCell className={'legend'}/>
							{map(streets, (street) => (
								<SummaryCell key={street} className={'legend'}>{street}</SummaryCell>
							))}
						</SummaryColumn>
						{map(containerTypesName, (name) => {
							const grouped = keyBy(collectionsByStreets[name], 'street')
							const item = collectionsByStreets[name][0]
							const color = item ? item.color : '000000'

							return (
								<SummaryColumn key={name} className={'flex'}>
									<SummaryCell className={'heading'}>
										<Dot style={{ backgroundColor: `#${color}` }}/>
										<span>{name}</span>
									</SummaryCell>
									{map(streets, (street) => {
										const item = grouped[street]
										return <SummaryCell key={street}>{item?.collections || 0}</SummaryCell>
									})}
								</SummaryColumn>
							)
						})}
						<SummaryColumn>
							<SummaryCell className={'heading'}>Spolu</SummaryCell>
							{map(streets, (street) => {
								const count = counts[street]
								return (
									<SummaryCell key={street}>{count?.count || 0}</SummaryCell>
								)
							})}
						</SummaryColumn>
						<SummaryColumn>
							<SummaryCell className={'heading'}>Nevyvezené</SummaryCell>
							{map(streets, (street) => {
								const uncollectedStreet = counts[street]
								return (
									<SummaryCell key={street}>{uncollectedStreet?.uncollected || 0}</SummaryCell>
								)
							})}
						</SummaryColumn>
					</SummaryTable>
				</>
			)
		}

		return null
	}, [overview.data])

	return (
		<div className={'box'}>
			<div className={'box-content with-padding'}>
				<div className={'box-head'}/>
				<div className={'box-body'}>
					{content}
				</div>
			</div>
		</div>
	)
}

export default CollectionRoundOverview
