import React, { useCallback, useEffect, useMemo, useState } from 'react'
import qs from 'qs'

import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, initialize, change, reset } from 'redux-form'
import { debounce, get, map, reduce, trim } from 'lodash'

import { PlusOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

import { faUniversalAccess, faCalendarTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Table from '../../components/table/Table'
import Breadcrumbs from '../../components/Breadcrumb'
import ImportModal from '../../components/modals/ImportModal'
import Modal from '../../components/modals/Modal'
import CustomerCodesAssignForm from './components/CustomerCodesAssignForm'

import ListItemsLimit from '../../atoms/ListImtesLimit'
import Pagination from '../../atoms/Pagination'

import locale from '../../resources/locale'

import { columns, queryParams, queryTypes, defaultColumns } from './constants'
import { mergeQueryParams, normalizeQueryParams } from '../../utils/queryParams'
import { ENDPOINTS, FILTER, FORMS, MODAL } from '../../utils/enums'
import { getSelectedColumns } from '../../utils/filter'
import { history } from '../../utils/history'

import { getCustomerCodesList } from '../../redux/customerCodes/selectors'
import { getMunicipalitiesSelectOptions, getSelectedMunicipality } from '../../redux/municipalities/selectors'
import {
	loadCustomerCodes,
	importCustomerCodes,
	assignCustomerCodes,
	expireCustomerCode
} from '../../redux/customerCodes/actions'

import CustomerExpireCode from '../customers/components/CustomerExpireCode'
import ExportButton from '../../components/buttons/ExportButton'
import SearchInputField from '../../atoms/SearchInputField'
import DialogModal from '../../components/modals/DialogModal'

import Button from '../../components/buttons/Button'

const filtersSelector = getFormValues(FILTER.CUSTOMER_CODES_FILTERS)

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

const updateQueryParams = (query) => {
	const normalizedQueryParams = reduce(query, (result, value, key) => value ? {
		...result,
		[key]: value
	} : result, {})

	history.replace({
		pathname: history.location.pathname,
		search: `?${qs.stringify(normalizedQueryParams, { encode: false })}`
	})
}

const CustomerCodesPage = () => {
	const [modal, setModal] = useState({ data: null, key: null })

	const dispatch = useDispatch()
	const municipality = useSelector(getSelectedMunicipality)
	const filters = useSelector(filtersSelector) || {}
	const list = useSelector(getCustomerCodesList)
	const municipalityIDs = useSelector(getMunicipalitiesSelectOptions)

	// region CALLBACKS

	const debouncedFilter = useMemo(() => debounce((filters) => {
		const query = normalizeQueryParams(queryTypes, filters)
		const municipalityID = municipality ? municipality.id : undefined
		const reqQuery = {
			...query,
			municipalityID,
			columns: undefined
		}

		updateQueryParams(query)
		dispatch(loadCustomerCodes(reqQuery))
	}, 200), [dispatch, municipality])

	const handleFilter = useCallback((values) => {
		const query = normalizeQueryParams(queryTypes, values)
		updateQueryParams(query)

		dispatch(initialize(FILTER.CUSTOMER_CODES_FILTERS, query))
	}, [dispatch])

	const handleResetFilters = useCallback(() => {
		const defaultFilters = {
			columns: filters.columns,
			order: filters.order,
			limit: filters.limit,
			page: 1
		}

		const newFilters = reduce(filters, (result, filter, key) => ({
			...result,
			[key]: defaultFilters[key] || null
		}), {})

		handleFilter(newFilters)
	}, [filters, handleFilter])

	const handleImportClick = useCallback(() => setModal({ key: MODAL.IMPORT_CUSTOMERS_CODES, data: null }), [setModal])

	const handleChangePage = useCallback((value) => dispatch(change(FILTER.CUSTOMER_CODES_FILTERS, queryParams.page, value)), [dispatch])
	const handleChangeLimit = useCallback((value) => dispatch(change(FILTER.CUSTOMER_CODES_FILTERS, queryParams.limit, value)), [dispatch])
	const handleChangeOrder = useCallback((value) => dispatch(change(FILTER.CUSTOMER_CODES_FILTERS, queryParams.order, value)), [dispatch])
	const handleChangeColumns = useCallback((value) => dispatch(change(FILTER.CUSTOMER_CODES_FILTERS, queryParams.columns, value)), [dispatch])

	const handleGetActions = (code) => {
		const actions = []

		if (code.validTo) {
			actions.push({
				key: 'common.expire',
				title: 'Zrušiť expiráciu',
				icon: faCalendarTimes,
				callback: () => {
					setModal({ key: MODAL.CANCEL_CUSTOMERS_CODE_EXPIRATION, data: code })
				}
			})
		} else {
			actions.push({
				key: 'common.expire',
				title: locale['page.customers.detail.expire.code'],
				icon: faCalendarTimes,
				callback: () => {
					dispatch(initialize(FORMS.EXPIRE_CUSTOMER_CODE, { validTo: code.validTo ? moment(code.validTo).toDate() : null }))
					dispatch(reset(FORMS.EXPIRE_CUSTOMER_CODE))
					setModal({ key: MODAL.EXPIRE_CUSTOMERS_CODE, data: code })
				}
			})
		}

		if (municipality) {
			actions.push({
				key: 'pages.customerCodes.assign',
				title: locale['pages.customerCodes.assign'],
				icon: faUniversalAccess,
				callback: () => {
					dispatch(initialize(FORMS.ASSIGN_CUSTOMERS_CODES, {}))
					setModal({ key: MODAL.ASSIGN_CUSTOMERS_CODES, data: code })
				}
			})
		}

		return actions
	}

	const handleImportCodes = useCallback((file) => {
		dispatch(importCustomerCodes(municipality, file))
		setModal({ key: null, data: null })
	}, [dispatch, municipality])

	const handleAssignCodes = useCallback((values) => {
		setModal({ key: null, data: null })
		const customersCodes = [{
			code: modal.data.code, customerID: values.customerID.value
		}]
		const onSuccess = () => debouncedFilter(filters)
		dispatch(assignCustomerCodes(municipality, { customersCodes }, { onSuccess }))
	}, [debouncedFilter, dispatch, filters, modal.data?.code, municipality])

	const handleExpireCode = useCallback((values) => {
		const meta = { onSuccess: () => debouncedFilter(filters) }
		dispatch(expireCustomerCode(modal.data, values, meta))
		setModal({ key: null, data: null })
	}, [debouncedFilter, dispatch, filters, modal.data])

	const handleCloseModal = (e) => {
		if (e) {
			e.preventDefault()
		}
		setModal({ key: null, data: null })
	}

	// endregion

	// region EFFECTS

	useEffect(() => {
		const queryParams = qs.parse(history.location.search.substring(1))
		const queryFilters = normalizeQueryParams(queryTypes, mergeQueryParams(filters, queryParams))
		updateQueryParams(queryFilters)

		dispatch(initialize(FILTER.CUSTOMER_CODES_FILTERS, queryFilters))
	}, [dispatch])

	// Filter customer codes debounce
	useEffect(() => debouncedFilter(filters), [debouncedFilter, filters])

	// Updated query with changed columns
	useEffect(() => {
		const query = normalizeQueryParams(queryTypes, filters)
		updateQueryParams(query)
	}, [filters, filters.columns])

	// endregion

	return (
		<>
			<Breadcrumbs
				items={[{
					key: 'pages.customerCodes.title',
					name: locale['pages.customerCodes.title']
				}]}
			/>
			<div className={'box'}>
				<div className={'box-title'}>
					{locale['pages.customerCodes.title']}
				</div>
				<div className={'box-content'}>
					<div className={'box-head'}>
						<Row gutter={[16, 16]} justify={'space-between'}>
							<Col>
								<SearchInputField
									name={'search'}
									onChange={(search) => dispatch(change(FILTER.CUSTOMER_CODES_FILTERS, 'search', trim(search)))}
									defaultValue={filters.search || ''}
								/>
							</Col>
							<Col flex={1}>
								<ExportButton
									url={ENDPOINTS.CUSTOMER_CODES_EXPORT}
									context={{ municipalityID: municipality ? municipality.id : undefined, ...filters }}
								/>
							</Col>
							<Col>
								<Button
									disabled={!municipality}
									onClick={handleImportClick}
								>
									<PlusOutlined/>
									<span>{locale['pages.customerCodes.import']}</span>
								</Button>
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Table
									onResetFilters={handleResetFilters}
									name={FILTER.CUSTOMER_CODES_FILTERS}
									getActions={handleGetActions}
									columns={getSelectedColumns(filters, { municipalityIDs }, columns)}
									tableColumns={tableColumns}
									defaultColumns={defaultColumns}
									filters={filters || {}}
									isLoading={list.isLoading}
									isFailure={list.isFailure}
									isSelectable={false}
									data={get(list, 'data.customersCodes')}
									onSort={handleChangeOrder}
									onChangeColumns={handleChangeColumns}
									onFilter={handleFilter}
								/>
								<ListItemsLimit
									limit={get(filters, 'limit')}
									onLimit={handleChangeLimit}
								/>
								<Pagination
									pages={get(list, 'data.context.pages')}
									page={get(list, 'data.context.page')}
									onPage={handleChangePage}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{!!municipality &&
			<ImportModal
				shown={modal.key === MODAL.IMPORT_CUSTOMERS_CODES}
				title={locale['pages.customerCodes.import.title']}
				createHandler={handleImportCodes}
				dismissHandler={handleCloseModal}
			/>}

			<DialogModal
				shown={modal.key === MODAL.CANCEL_CUSTOMERS_CODE_EXPIRATION}
				cancelHandler={handleCloseModal}
				message={'Naozaj chcete zrušiť expiráciu?'}
				title={'Zrušiť expiráciu'}
				acceptHandler={() => handleExpireCode({ validTo: null })}
				acceptTitle={'Potvrdiť'}
			/>

			{!!municipality &&
			<Modal
				shown={modal.key === MODAL.ASSIGN_CUSTOMERS_CODES}
				dismiss={handleCloseModal}
			>
				<CustomerCodesAssignForm
					handleCancel={handleCloseModal}
					municipality={municipality}
					onSubmit={handleAssignCodes}
				/>
			</Modal>}
			<Modal
				shown={modal.key === MODAL.EXPIRE_CUSTOMERS_CODE}
				dismiss={handleCloseModal}
			>
				<CustomerExpireCode
					handleCancel={handleCloseModal}
					onSubmit={handleExpireCode}
				/>
			</Modal>
		</>
	)
}

export default CustomerCodesPage
