import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import i18next from 'i18next'

import { useTranslation } from 'react-i18next'

import { map } from 'lodash'

import { Col, Row } from 'antd'

import dayjs from 'dayjs'
import { HeadingFive, TextSmall, Text } from '../../../components/Typography'
import { Box, BoxContent } from '../../../layouts/Box'
import { CustomerDetail } from '../../../types/data'

import StatisticsContainer from './statistics/StatisticsContainer'
import PieChartStatistics from './statistics/PieChartStatistics'
import ListStatistics, { ExpandableSection } from './statistics/ListStatistics'
import ContainersSummarySmallTable from './statistics/ContainersSummarySmallTable'
import ContainersSummaryTable from './statistics/ContainersSummaryTable'
import StatisticsTooltip from './statistics/StatisticsTooltip'
import { useDataLoader } from '../../../hooks/useDataLoader'
import { customers } from '../../../api'
import { formatDatetime } from '../../../utils/utils'
import theme from '../../../utils/theme'
import { DEFAULT_WASTE_TYPE_COLOR } from '../../../utils/enums'

const PieCenterText = styled.div`
	width: 80px;
	padding-top: 20px;
	text-align: center;
`

type Props = {
	detail: CustomerDetail
}

const formatLimit = (limit: number | null) => {
	if (limit) {
		return `${limit} kg`
	}

	if (typeof limit === 'number') {
		return i18next.t('CustomerPage.wasteTypeLimitReached')
	}

	return i18next.t('CustomerPage.wasteTypeNoLimit')
}

const CustomerSorting = ({ detail }: Props) => {
	const [t] = useTranslation()
	const [year, setYear] = useState<string | number>(dayjs().year())

	const [sortingRate, loadCustomerSortingRate] = useDataLoader(customers.loadCustomerSorting)
	const [collections, loadCollections] = useDataLoader(customers.loadCustomerCollections)
	const [yardCollections, loadYardCollections] = useDataLoader(customers.loadCustomerCollections)
	const [containersSummary, loadSummary] = useDataLoader(customers.loadContainersSummary)
	const [customerState, loadCustomer] = useDataLoader(customers.loadCustomer)

	const [expandedSection, setExpandedSection] = useState<ExpandableSection>(null)

	const sortingRateData = useMemo(() => map(sortingRate.data?.wasteTypes, (dataItem) => ({
		value: dataItem.rate, label: dataItem.name, color: `#${dataItem.color}`
	})), [sortingRate.data])

	const collectionsData = useMemo(() => map(collections.data?.collections, (item) => ({
		title: item?.container?.containerType?.wasteType?.name || item?.container?.containerType?.name || t('CustomerPage.unassignedContainer'),
		subtitle: formatDatetime(item.scanDatetime),
		rightText: item?.container?.serialNumber || item?.container?.rfidSerial || item?.container?.code || item?.container?.rfid || '-',
		color: `#${item?.container?.containerType?.wasteType?.color || DEFAULT_WASTE_TYPE_COLOR}`
	})), [collections.data?.collections, t])

	const collectionYardCollectionsData = useMemo(() => map(yardCollections.data?.collections, (item) => ({
		title: item?.container?.containerType?.wasteType?.name || item?.container?.containerType?.name || t('CustomerPage.unassignedContainer'),
		subtitle: formatDatetime(item.scanDatetime),
		rightText: `${item.weight} kg`,
		color: `#${item?.container?.containerType?.wasteType?.color || DEFAULT_WASTE_TYPE_COLOR}`
	})), [t, yardCollections.data?.collections])

	const wasteTypesData = useMemo(() => map(customerState.data?.wasteTypes, (wasteType) => ({
		title: wasteType.name,
		subtitle: '',
		rightText: formatLimit(wasteType.limit),
		rightTextColor: wasteType.limit !== null && wasteType.limit < 0 ? theme.colors.error : undefined,
		color: `#${wasteType?.color || DEFAULT_WASTE_TYPE_COLOR}`
	})), [customerState.data])

	const sortingRateFilters = useMemo(() => ({
		options: Array.from({ length: dayjs().year() - 2019 + 1 }, (_, index) => ({
			value: 2019 + index,
			label: `${2019 + index}`
		})),
		value: year,
		onChange: setYear
	}), [year])

	const collectionsTooltip = useMemo(() => ({
		title: t('CustomerPage.sortingRateTooltipTitle'),
		description: t('CustomerPage.sortingRateTooltipDescription')
	}), [t])

	const collectionYardTooltip = useMemo(() => ({
		title: t('CustomerPage.collectionTooltipTitle'),
		description: t('CustomerPage.collectionTooltipDescription')
	}), [t])

	useEffect(() => {
		if (detail) {
			loadCustomerSortingRate(detail.id, { year })
		}
	}, [detail, loadCustomerSortingRate, year])

	useEffect(() => {
		if (detail) {
			loadCollections(detail.id, { page: 1, limit: 1000 })
			loadYardCollections(detail.id, { page: 1, limit: 1000, isCollectionYard: true })
			loadSummary(detail.id)
			loadCustomer(detail.id)
		}
	}, [detail, loadCollections, loadCustomer, loadSummary, loadYardCollections])

	return (
		<Box>
			<BoxContent className={'padded tab'}>
				<Row gutter={[20, 20]}>
					<Col span={24} md={12} xl={8}>
						<StatisticsContainer isLoading={sortingRate.isLoading} className={'graph'}>
							<PieChartStatistics
								title={t('CustomerPage.sortingRate')}
								emptyText={t('CustomerPage.emptyCollections')}
								centerText={(
									<PieCenterText>
										<HeadingFive>{sortingRate.data?.sortingRate || 0} %</HeadingFive>
										<TextSmall>{t('CustomerPage.sortingRate')}</TextSmall>
									</PieCenterText>
								)}
								tooltip={collectionsTooltip}
								filter={sortingRateFilters}
								data={sortingRateData}
							/>
						</StatisticsContainer>
					</Col>
					<Col span={24} md={12} xl={8}>
						<StatisticsContainer
							className={cx({ expanded: expandedSection === 'collections' })}
							isLoading={collections.isLoading}
						>
							<ListStatistics
								data={collectionsData}
								emptyText={t('CustomerPage.emptyCollections')}
								title={t('CustomerPage.collectionsTitle')}
								section={'collections'}
								expanded={expandedSection === 'collections'}
								onExpand={setExpandedSection}
								tooltip={collectionYardTooltip}
							/>
						</StatisticsContainer>
					</Col>
					<Col span={24} md={12} xl={8}>
						<StatisticsContainer
							className={cx({ expanded: expandedSection === 'collectionYard' })}
							isLoading={yardCollections.isLoading}
						>
							<ListStatistics
								data={collectionYardCollectionsData}
								emptyText={t('CustomerPage.emptyCollections')}
								title={t('CustomerPage.collectionYardCollectionsTitle')}
								section={'collectionYard'}
								expanded={expandedSection === 'collectionYard'}
								onExpand={setExpandedSection}
								tooltip={{
									title: t('CustomerPage.collectionYardCollectionsTooltipTitle'),
									description: t('CustomerPage.collectionYardCollectionsTooltipDescription')
								}}
							/>
						</StatisticsContainer>
					</Col>
					<Col span={24} md={0}>
						<StatisticsContainer isLoading={containersSummary.isLoading}>
							<ContainersSummarySmallTable
								title={t('CustomerPage.containersSummaryTitle')}
								emptyText={t('CustomerPage.containersSummaryEmpty')}
								data={containersSummary.data}
							/>
						</StatisticsContainer>
					</Col>
					<Col span={24} md={12} xl={8}>
						<StatisticsContainer
							className={cx({ expanded: expandedSection === 'wasteTypes' })}
							isLoading={customerState.isLoading}
						>
							<ListStatistics
								data={wasteTypesData}
								emptyText={t('CustomerPage.emptyWasteTypes')}
								title={t('CustomerPage.wasteTypesLimitTitle')}
								section={'collectionYard'}
								expanded={expandedSection === 'wasteTypes'}
								onExpand={setExpandedSection}
								tooltip={{
									title: t('CustomerPage.wasteTypesLimitTooltipTitle'),
									description: t('CustomerPage.wasteTypesLimitTooltipDescription')
								}}
							/>
						</StatisticsContainer>
					</Col>
					<Col span={0} md={24}>
						<Row gutter={[8, 8]}>
							<Col>
								<Text className={'bold'}>{t('CustomerPage.containersSummaryTitle')}</Text>
							</Col>
							<Col>
								<StatisticsTooltip
									title={t('CustomerPage.containersSummaryTitle')}
									description={t('CustomerPage.containersSummaryDescription')}
								/>
							</Col>
							<Col span={24}>
								<StatisticsContainer className={'borderless'} isLoading={containersSummary.isLoading}>
									<ContainersSummaryTable summary={containersSummary.data}/>
								</StatisticsContainer>
							</Col>
						</Row>
					</Col>
				</Row>
			</BoxContent>
		</Box>
	)
}

export default CustomerSorting
