import React from 'react'
import * as PropTypes from 'prop-types'
import i18next from 'i18next'

import { Field, FieldArray, FormSection } from 'redux-form'

import { map, get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import locale from '../../../resources/locale'

import { COLLECTION_PLACE_TYPE } from '../../../utils/enums'

import TextInputField from '../../../atoms/TextInputField'
import SelectField from '../../../atoms/SelectField'

import AsyncSelectField from '../../../atoms/AsyncSelectField'
import Button from '../../../components/buttons/Button'

const collectionPlacesTypesOptions = map(Object.values(COLLECTION_PLACE_TYPE), (type) => ({
	value: type,
	label: locale[`collectionPlace.type.${type}`]
}))

class AddressCodePortion extends React.Component {
	static propTypes = {
		municipalityID: PropTypes.number,
		loadCollectionPlaces: PropTypes.func.isRequired,
		containerTypes: PropTypes.shape({
			data: PropTypes.shape({
				containerTypes: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		}),
		municipalities: PropTypes.shape({
			data: PropTypes.shape({
				municipalities: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		}),
		collectionPlaces: PropTypes.shape({
			data: PropTypes.shape({
				collectionPlaces: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					name: PropTypes.string.isRequired
				}))
			}),
			isLoading: PropTypes.bool.isRequired
		})
	}

	componentDidMount() {
		const { municipalityID, loadCollectionPlaces } = this.props

		if (municipalityID) {
			loadCollectionPlaces({ municipalityID, limit: 100 })
		}
	}

	onInputChange = (input) => {
		this.setState({ input })
		this.searchCollectionPlaces(input)
	}

	searchCollectionPlaces = (nameSearch, callback) => {
		const { loadCollectionPlaces, municipalityID } = this.props
		const query = { municipalityID, limit: 100 }

		if (nameSearch) {
			query.search = nameSearch
		}

		loadCollectionPlaces(query).then((places) => {
			callback(map(places, (place) => ({
				value: place.id,
				label: place.customer?.fullName ? `${place.name} - ${place.customer.fullName}` : place.name
			})))
		})
	}

	getContainerTypes = ({ fields, containerTypes }) => {
		const types = fields.map((containerType, index) => (
			<React.Fragment key={index}>
				<div className={'col-md-6'}>
					<Field
						name={`${containerType}.id`}
						placeholder={locale['field.placeholder.containerType']}
						component={SelectField}
						props={{
							label: i18next.t('loc:Typ nádoby'),
							required: true,
							showLabel: true,
							isLoading: get(containerTypes, 'isLoading'),
							options: map(get(containerTypes, 'data.containerTypes'), (item) => ({
								value: item.id,
								label: item.name
							}))
						}}
					/>
				</div>
				<div className={'col-5'}>
					<Field
						name={`${containerType}.count`}
						placeholder={locale['field.placeholder.codesCount']}
						component={TextInputField}
						type={'number'}
						label={i18next.t('loc:Počet kódov')}
						min={0}
						max={1000000}
						required
						showLabel
					/>
				</div>
				<div className={'col-1'} style={{ marginTop: '30px' }}>
					<Button type="button" onClick={() => fields.remove(index)} className={'secoandary'}>
						<FontAwesomeIcon icon={faTrashAlt}/>
					</Button>
				</div>
			</React.Fragment>
		))
		return (
			<div className="row">
				{types}
				<div className={'col-12'}>
					<Button type="button" onClick={() => fields.push({})}>
						<FontAwesomeIcon icon={faPlusCircle}/>
						<span>{i18next.t('loc:Pridať typ nádoby')}</span>
					</Button>
				</div>
			</div>
		)
	}

	render() {
		const { containerTypes, municipalityID } = this.props

		return (
			<div className={'row'}>
				<div className={'col-12'}>
					<FormSection name={'addressCodes'}>
						<div className={'row'}>
							{!!municipalityID && <div className={'col-12'}>
								<FieldArray
									name="containerTypes"
									component={this.getContainerTypes}
									props={{ containerTypes }}
								/>
							</div>}
						</div>
						<div className={'row'} style={{ marginTop: '15px' }}>
							{!!municipalityID &&
							<div className={'col-12'}>
								<Field
									name='collectionPlaceTypes'
									placeholder={locale['field.placeholder.collectionPlaceTypes']}
									component={SelectField}
									props={{
										label: i18next.t('loc:Typy zberných miest'),
										isMulti: true,
										options: collectionPlacesTypesOptions
									}}
								/>
							</div>}
							<div className={'col-12'}>
								<Field
									name='exclude'
									placeholder={locale['field.placeholder.collectionPlaces']}
									component={AsyncSelectField}
									props={{
										label: i18next.t('loc:Ignorované zberné miesta'),
										required: true,
										isMulti: true,
										showLabel: true,
										loadOptions: this.searchCollectionPlaces
									}}
								/>
							</div>
						</div>
					</FormSection>
				</div>
			</div>
		)
	}
}

export default AddressCodePortion
