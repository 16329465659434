import { getReq } from '../utils/request'
import { ListResponse, Response } from '../types/api'
import {
	CollectionsSummary, ContainersCount, MunicipalitySortingRate, TopHouseholds, WastesPerCustomer,
	Sorting, SortingGraph, WeightSummary
} from '../types/data'
import { omitNilFilters } from '../utils/helpers'

export default {
	loadContainersCount: (filters: object): Response<{ containers: ContainersCount }> => getReq('/api/admin/statistics/containers-count', filters),
	loadCounts: (filters: object): Response<{ collectionPlacesCount: number, collectionsCount: number }> => getReq('/api/admin/statistics/count', filters),
	loadHouseholdsSummary: (filters: object): Response<TopHouseholds> => getReq('/api/admin/statistics/households', filters),
	loadCollectionsSummary: (filters: object): Response<CollectionsSummary> => getReq('/api/admin/statistics/collections', filters),
	loadCustomerSortingRate: (filters: object) => getReq('/api/admin/statistics/sorting-rate', filters),
	loadSortingRate: (filters: object): Response<MunicipalitySortingRate> => getReq('/api/admin/statistics/municipality-sorting-rate', filters),
	loadWastesPerCustomer: (filters: object): Response<WastesPerCustomer> => getReq('/api/admin/statistics/wastes-per-customer', filters),

	loadCollectionPlacesSorting: (filters: object): ListResponse<{ stats: Sorting[] }> => getReq('/api/admin/statistics/collection-places-sorting', omitNilFilters(filters)),
	loadCollectionPlacesSortingGraph: (filters: object): Response<SortingGraph> => getReq('/api/admin/statistics/collection-places-sorting/graph', omitNilFilters(filters)),

	loadMunicipalitySorting: (filters: object): ListResponse<{ stats: Sorting[] }> => getReq('/api/admin/statistics/municipality-sorting', omitNilFilters(filters)),

	loadCustomersSorting: (filters: object): ListResponse<{ stats: Sorting[] }> => getReq('/api/admin/statistics/customers-sorting', omitNilFilters(filters)),
	loadCustomersSortingGraph: (filters: object): Response<SortingGraph> => getReq('/api/admin/statistics/customers-sorting/graph', omitNilFilters(filters)),

	loadWeight: (filters: object): Response<WeightSummary> => getReq('/api/admin/statistics/weight', omitNilFilters(filters))
}
