import React, { useCallback, useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { filtersActions, getBulkCollectionsWasteTypeSummaryFilters } from '../../../redux/filters'

import { useDataLoader } from '../../../hooks/useDataLoader'

import { bulkCollections } from '../../../api'

import { SubHeading, Text } from '../../../components/Typography'
import { InputWrapper, Label, Select } from '../../../components/form/Styled'

import WasteTypeLabel from '../../../components/WasteTypeLabel'
import Dot from '../../../components/Dot'
import SpinLoading from '../../../components/SpinLoading'
import ExportButton from '../../../components/buttons/ExportButton'
import { createMonthList, monthFormat } from '../../../utils/helpers'

type Props = {
	municipalityID?: string
}

const ValueWrapper = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
`

const TitleCol = styled(Col)`
	width: 190px;
`

const EmptyCol = styled(Col)`
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	
	${Text} {
		margin-top: 40px;
		color: ${({ theme }) => theme.colors.neutral['500']};
	}
`

const Background = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	height: 40px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.neutral['50']};
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`

const Divider = styled.div`
	position: absolute;
	left: 0;
	top: 40px;
	height: 1px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.neutral['100']};
`

const TableRow = styled(Row)`
	position: relative;
`

const months = createMonthList()

const WasteTypesSummary = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const filters = useSelector(getBulkCollectionsWasteTypeSummaryFilters)
	const dispatch = useDispatch()

	const [summaryState, loadSummary] = useDataLoader(bulkCollections.loadWasteTypeSummary)

	const periodOptions = useMemo(() => months.map((month) => ({
		value: month, label: dayjs(month, monthFormat).format('MMMM YYYY')
	})), [])

	const handleChangePeriod = useCallback((period) => {
		dispatch(filtersActions.saveFilters({ key: 'bulkCollectionsWasteTypeSummaryFilters', filters: { period } }))
	}, [dispatch])

	const context = useMemo(() => {
		const date = dayjs(filters.period, monthFormat)
		return { municipalityID, month: date.month() + 1, year: date.year() }
	}, [filters.period, municipalityID])

	useEffect(() => {
		loadSummary(context)
	}, [context, loadSummary])

	let content

	if (summaryState.isLoading) {
		content = (
			<SpinLoading height={'120px'}/>
		)
	}

	if (summaryState.data) {
		const rowJustify = summaryState.data.collections.length > 3 ? 'space-between' : 'start'

		content = (
			<TableRow gutter={16} justify={rowJustify} wrap={false}>
				{!!summaryState.data.collections.length &&
				<>
					<Background/>
					<Divider/>
				</>}
				<TitleCol>
					<ValueWrapper/>
					<ValueWrapper>
						<Text className={'without-margin medium'}>{t('BulkCollectionsPage.collectionsCount')}</Text>
					</ValueWrapper>
					<ValueWrapper>
						<Text className={'without-margin medium'}>{t('BulkCollectionsPage.collectionsWeight')}</Text>
					</ValueWrapper>
				</TitleCol>
				{!summaryState.data.collections.length &&
				<EmptyCol flex={1}>
					<Text>{t('BulkCollectionsPage.noCollectionsInPeriod')}</Text>
				</EmptyCol>}
				{!!summaryState.data.collections.length &&
				<Col flex={1} style={{ overflow: 'auto' }}>
					<Row gutter={16} justify={rowJustify} wrap={false}>
						{map(summaryState.data?.collections, (wasteType) => (
							<Col key={wasteType.id} style={{ minWidth: 150 }}>
								<ValueWrapper>
									<WasteTypeLabel>
										<Dot color={`#${wasteType.color}`}/>
										<Text className={'without-margin medium'}>{wasteType.name}</Text>
									</WasteTypeLabel>
								</ValueWrapper>
								<ValueWrapper>
									{wasteType.count}
								</ValueWrapper>
								<ValueWrapper className={'total'}>
									{`${wasteType.weight.toFixed(2)} kg`}
								</ValueWrapper>
							</Col>
						))}
					</Row>
				</Col>}
			</TableRow>
		)
	}

	return (
		<Row
			gutter={[16, 16]}
			justify={'space-between'}
			align={'bottom'}
		>
			<Col span={24}>
				<SubHeading>{t('BulkCollectionsPage.wasteTypeSummaryTitle')}</SubHeading>
			</Col>
			<Col style={{ width: 200 }}>
				<InputWrapper className={'without-margin'}>
					<Label>{t('BulkCollectionsPage.period')}</Label>
					<Select
						value={filters.period}
						onChange={handleChangePeriod}
						options={periodOptions}
						allowClear={false}
					/>
				</InputWrapper>
			</Col>
			<Col>
				<ExportButton
					url={'/api/admin/bulk-collections/statistics/waste-types-summary/export'}
					className={'secondary'}
					title={t('common.export')}
					context={context}
				/>
			</Col>
			<Col span={24}>
				{content}
			</Col>
		</Row>
	)
}

export default React.memo(WasteTypesSummary)
