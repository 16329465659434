import React from 'react'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'

class PopupTool extends React.Component {
	static propTypes = {
		items: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.string.isRequired,
			callback: PropTypes.func.isRequired,
			icon: PropTypes.object.isRequired,
			title: PropTypes.string.isRequired
		})).isRequired]).isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			node: null,
			active: false
		}
	}

	activePopup = (e) => {
		e.stopPropagation()

		const td = e.currentTarget.parentNode.parentNode

		this.setState({ active: true, node: td })

		td?.classList.add('active-popup')
	}

	onLeave = () => {
		const { node } = this.state

		node?.classList.remove('active-popup')
		this.setState({ active: false, node: null })
	}

	handleClick = (item) => (e) => {
		e.stopPropagation()
		item.callback()
	}

	render() {
		const { active } = this.state
		const { items } = this.props

		const actionItems = typeof items === 'function' ? items() : items

		const actions = map(actionItems, (item) => (
			<li
				key={item.key}
				onClick={this.handleClick(item)}
			>
				<FontAwesomeIcon icon={item.icon}/>
				<span>{item.title}</span>
			</li>
		))

		return (
			<div className={'actions-wrapper'}>
				<div onClick={this.activePopup} className={cx('actions', { active })} onMouseLeave={this.onLeave}>
					<FontAwesomeIcon color={'#9da7cd'} icon={faEllipsisV}/>
					<ul>
						{actions}
					</ul>
				</div>
			</div>
		)
	}
}

export default PopupTool
