import { formatDatetime } from '../../../utils/utils'

export const queryParams = {
	columns: 'columns', limit: 'limit', page: 'page', order: 'order'
}

export const columns = [
	{
		name: 'code',
		sortable: true,
		className: 'wide',
		title: 'Kód'
	}, {
		name: 'serial',
		sortable: true,
		title: 'Číslo'
	}, {
		name: 'validFrom',
		sortable: true,
		title: 'Platný od',
		render: formatDatetime
	}, {
		name: 'validTo',
		sortable: true,
		title: 'Platný do',
		render: formatDatetime
	}, {
		name: 'createdAt',
		sortable: true,
		title: 'Dátum vytvorenia',
		render: formatDatetime
	}
]

export const defaultColumns = [columns[0].name, columns[1].name, columns[2].name, columns[3].name, columns[4].name]
