import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PERMISSIONS } from '../../utils/enums'

import AuthRoute from '../AuthRoute'

import Home from './Home'
import Statistics from './Statistics'
import Registry from './Resgitry'
import Lists from './Lists'

import MunicipalityPage from '../../pages/municipalities/MunicipalityPage'
import WasteTypesPage from '../../pages/wasteTypes/WasteTypesPage'
import WasteTypePage from '../../pages/wasteTypes/WasteTypePageOld'
import ContainerTypesPage from '../../pages/containerTypes/ContainerTypesPage'
import ContainerTypePage from '../../pages/containerTypes/ContainerTypePage'
import UsersPage from '../../pages/users/UsersPage'
import UserPage from '../../pages/users/UserPage'
import DevicesPage from '../../pages/devices/DevicesPage'
import DevicePage from '../../pages/devices/DevicePage'

const Municipality = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<Route
				path={t('paths:STATISTICS.municipalityPath', { municipalityID: ':municipalityID' })}
				component={Statistics}
			/>
			<Route
				path={t('paths:REGISTRY.municipalityPath', { municipalityID: ':municipalityID' })}
				component={Registry}
			/>
			<Route
				path={t('paths:LISTS.municipalityPath', { municipalityID: ':municipalityID' })}
				component={Lists}
			/>

			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.settings'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.settings.municipalityPath', { municipalityID: ':municipalityID' })}
				component={MunicipalityPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.wasteTypes'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.wasteTypes.municipalityPath', { municipalityID: ':municipalityID' })}
				component={WasteTypesPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.wasteTypes'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.wasteTypes.detail.municipalityPath', { municipalityID: ':municipalityID', wasteTypeID: ':wasteTypeID' })}
				component={WasteTypePage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.containerTypes'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.containerTypes.municipalityPath', { municipalityID: ':municipalityID' })}
				component={ContainerTypesPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.containerTypes'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.containerTypes.detail.municipalityPath', { municipalityID: ':municipalityID', containerTypeID: ':containerTypeID' })}
				component={ContainerTypePage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.users'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.users.municipalityPath', { municipalityID: ':municipalityID' })}
				component={UsersPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.users'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.users.detail.municipalityPath', { municipalityID: ':municipalityID', userID: ':userID' })}
				component={UserPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.devices'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.devices.municipalityPath', { municipalityID: ':municipalityID' })}
				component={DevicesPage}
			/>
			<AuthRoute
				exact
				menuItem={'MUNICIPALITY.devices'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:MUNICIPALITY.devices.detail.municipalityPath', { municipalityID: ':municipalityID', deviceID: ':deviceID' })}
				component={DevicePage}
			/>

			<Route
				path={t('paths:HOME.municipalityPath', { municipalityID: ':municipalityID' })}
				component={Home}
			/>
		</Switch>
	)
}

export default Municipality
