import React from 'react'
import * as PropTypes from 'prop-types'
import ReactQueryParams from 'react-query-params'
import qs from 'qs'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector, reset } from 'redux-form'
import { forEach, get, head, isEqual, last, map } from 'lodash'

import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import locale from '../../resources/locale'
import { history } from '../../utils/history'
import {
	CODE_PORTION_STATES,
	CODE_PORTION_TYPES,
	ENDPOINTS, ERROR,
	FILTER_ORDER_SELECTORS,
	FORMS, INFO,
	MODAL
} from '../../utils/enums'
import { downloadFile, fileNameFromResponse, uploadFile } from '../../utils/upload'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { deleteReq, downloadReq, patchReq, postReq } from '../../utils/request'
import { formatDate } from '../../utils/utils'

import { loadContainerTypes } from '../../redux/containerTypes/actions'
import { loadMunicipalities } from '../../actions/municipalityActions'
import { loadCodePortions } from '../../actions/codePortionsActions'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import { statusPush } from '../../actions/statusActions'

import Breadcrumbs from '../../components/Breadcrumb'
import Pagination from '../../atoms/Pagination'
import CodePortionsTable from './components/CodePortionsTable'
import CodePortionForm from './components/CodePortionForm'
import DialogModal from '../../components/modals/DialogModal'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import ImportModal from '../../components/modals/ImportModal'
import { loadCollectionPlacesSelect } from '../../redux/collectionPlaces/actions'
import { getCollectionPlacesSelectOptions } from '../../redux/collectionPlaces/selectors'
import DownloadModal from './components/DownloadModal'
import Button from '../../components/buttons/Button'

const queryParams = {
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'createdAt:DESC',
		allowedValues: ['createdAt', 'municipality', 'state', 'containersCount', 'note']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class CodePortionsPage extends ReactQueryParams {
	static propTypes = {
		actions: PropTypes.shape({
			reset: PropTypes.func.isRequired,
			loadPortions: PropTypes.func.isRequired,
			loadMunicipalities: PropTypes.func.isRequired,
			loadContainerTypes: PropTypes.func.isRequired,
			loadCollectionPlaces: PropTypes.func.isRequired,
			selectedFiltersOrdersChanged: PropTypes.func.isRequired,
			pushStatus: PropTypes.func.isRequired
		}),
		codePortions: PropTypes.shape({
			portions: PropTypes.shape({
				data: PropTypes.shape({
					portions: PropTypes.arrayOf(PropTypes.shape({
						id: PropTypes.number.isRequired,
						municipality: PropTypes.string.isRequired,
						containersCount: PropTypes.string.isRequired,
						state: PropTypes.oneOf(Object.values(CODE_PORTION_STATES)).isRequired,
						createdAt: PropTypes.string.isRequired
					})),
					lastPortion: PropTypes.shape({
						id: PropTypes.number.isRequired,
						municipality: PropTypes.string.isRequired,
						containersCount: PropTypes.string.isRequired,
						state: PropTypes.oneOf(Object.values(CODE_PORTION_STATES)).isRequired,
						createdAt: PropTypes.string.isRequired
					}),
					context: {
						pages: PropTypes.number.isRequired,
						page: PropTypes.number.isRequired,
						count: PropTypes.number.isRequired
					}
				}),
				isLoading: PropTypes.bool.isRequired,
				isFailure: PropTypes.bool.isRequired
			})
		}),
		selectedFiltersOrders: PropTypes.object.isRequired
	}

	state = {
		modal: null,
		modalData: null,
		codePortionsCount: {
			addressCodes: 0,
			anonymousCodes: 0,
			isLoading: false
		}
	}

	defaultQueryParams = {
		[queryParams.order]: null,
		[queryParams.page]: null,
		[queryParams.limit]: null
	}

	breadcrumbsItems = {
		items: [{
			key: 'page.administration.breadcrumbs',
			name: locale['page.administration.breadcrumbs']
		}, {
			key: 'page.codePortions.breadcrumbs',
			name: locale['page.codePortions.breadcrumbs']
		}]
	}

	componentDidMount() {
		this._mounted = true
		const { actions, selectedFiltersOrders, municipalityID } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// Remove unwanted query params from query string
		const query = qs.stringify(filtersOrder)
		history.replace(history.location.pathname, { search: query })

		actions.loadPortions(filtersOrder)
		if (municipalityID) {
			actions.loadContainerTypes({ limit: 1000, municipalityID })
		}
		actions.loadMunicipalities({ limit: 1000, order: 'name:ASC' }) // NOTE: Big limit for municipalities
		actions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CODE_PORTIONS_PAGE, filtersOrder)
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (filters) => {
		const { actions, selectedFiltersOrders } = this.props
		const filterOrder = { ...selectedFiltersOrders }

		forEach(filters, ({ type, value }) => {
			let newFilterOrder
			switch (type) {
				case queryParams.order:
					newFilterOrder = `${value.name}:${value.sort}`
					break
				case queryParams.page:
				case queryParams.limit:
					newFilterOrder = (value) ? `${value}` : null
					break
				default:
					break
			}
			filterOrder[type] = newFilterOrder
			if (type === queryParams.search) {
				filterOrder[queryParams.page] = 1
			}
		})

		const normalized = normalizeQueryParams(queryTypes, { ...filterOrder })
		this.setQueryParams(normalized)

		actions.loadPortions(normalized)
		actions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CODE_PORTIONS_PAGE, normalized)
	}

	openDownloadModal = (portion) => {
		this.setState({ modal: MODAL.CODE_PORTION_EXPORT_DIALOG, modalData: portion })
	}

	downloadCodePortion = async (page, limit, withAddress) => {
		const { modalData } = this.state

		try {
			this.closeModal()
			const response = await downloadReq(ENDPOINTS.EXPORT_CODE_PORTION(modalData.id), { page, limit, withAddress })
			const name = fileNameFromResponse(response)
			downloadFile({ name }, response.data)
		} catch (e) {
			// Error
		}
	}

	printCodePortion = async (portion) => {
		const { actions, selectedFiltersOrders } = this.props

		try {
			const response = await patchReq(ENDPOINTS.CODE_PORTION(portion.id), null, { isActive: true })

			const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

			actions.loadPortions(filtersOrder)

			this.closeModal()

			return response
		} catch (error) {
			return Promise.reject(error)
		}
	}

	onCreate = () => {
		this.setState({
			modal: MODAL.CREATE_CODE_PORTION
		})
	}

	onDelete = (item) => {
		const { codePortions, actions } = this.props

		const lastPortion = get(codePortions, 'data.lastPortion')
		if (lastPortion) {
			if (lastPortion.state === CODE_PORTION_STATES.CREATED && lastPortion.id === item.id) {
				this.setState({

					modal: MODAL.DELETE_CODE_PORTION
				})
			} else if (lastPortion.id !== item.id) {
				actions.pushStatus({
					type: ERROR,
					msg: locale['page.codePortions.delete.invalid']
				})
			}
		}
	}

	onPrint = (portion) => {
		this.setState({
			modal: MODAL.PRINT_CODE_PORTION,
			modalData: portion
		})
	}

	onDetail = (portion) => {
		history.push(locale.formatString(locale['path.administration.codePortions.detail'], portion.id))
	}

	closeModal = () => {
		this.setState({ modal: null, modalData: null })
	}

	createCodePortionData = (values) => {
		const data = {
			note: values.note
		}

		if (values.type === CODE_PORTION_TYPES.ADDRESS) {
			data.addressCodes = {
				...values.addressCodes,
				municipalityID: values.municipalityID,
				exclude: map(values.addressCodes?.exclude, (item) => item.value)
			}
		} else if (values.type === CODE_PORTION_TYPES.ANONYMOUS) {
			data.anonymousCodes = {
				...values.anonymousCodes,
				municipalityID: values.municipalityID
			}
		}

		return data
	}

	createCodePortion = async (values) => {
		const { actions, selectedFiltersOrders } = this.props

		try {
			const data = this.createCodePortionData(values)

			const response = await postReq(ENDPOINTS.MUNICIPALITIES_CONTAINERS, null, data)

			const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

			actions.reset(FORMS.CREATE_CODE_PORTION)
			actions.loadPortions(filtersOrder)

			this.closeModal()

			return response
		} catch (error) {
			return Promise.reject(error)
		}
	}

	deleteCodePortion = async () => {
		const { actions, selectedFiltersOrders } = this.props

		try {
			const response = await deleteReq(ENDPOINTS.CODE_PORTIONS, null, null)

			const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

			actions.loadPortions(filtersOrder)

			this.closeModal()

			actions.pushStatus({
				type: INFO,
				msg: locale['page.codePortions.delete.success']
			})

			return response
		} catch (error) {
			return Promise.reject(error)
		}
	}

	getDeleteMessage = () => {
		const { codePortions } = this.props

		const lastPortion = get(codePortions, 'data.lastPortion')

		if (lastPortion && lastPortion.state === CODE_PORTION_STATES.CREATED) {
			const param = lastPortion.municipality ? lastPortion.municipality : locale['codePortion.type.ANONYMOUS']
			return locale.formatString(locale['pages.codePortions.delete.msg'], param, formatDate(lastPortion.createdAt))
		} if (!lastPortion) {
			return locale['pages.codePortions.delete.not.exists']
		}

		return locale['pages.codePortions.delete.unable']
	}

	importRfid = async (file) => {
		const { actions } = this.props
		try {
			this.closeModal()
			actions.pushStatus({
				type: INFO,
				msg: locale['page.municipalities.detail.import.loading']
			})
			await uploadFile(file, {}, file.name, ENDPOINTS.RFID_IMPORT)
			actions.pushStatus({
				type: INFO,
				msg: locale['page.municipalities.detail.import.success']
			})
		} catch (error) {
			// Error
		}
	}

	openSubmitModal = async (values) => {
		this.setState({
			modalData: values,
			modal: MODAL.SUBMIT_CODE_PORTION,
			codePortionsCount: {
				addressCodes: 0,
				anonymousCodes: 0,
				isLoading: true
			}
		})

		try {
			if (values.type === CODE_PORTION_TYPES.ADDRESS) {
				const requestData = this.createCodePortionData(values)

				const { data } = await postReq(ENDPOINTS.MUNICIPALITY_CONTAINERS_COUNT, null, requestData)

				this.setState({
					codePortionsCount: {
						addressCodes: data.addressCodes,
						anonymousCodes: 0,
						isLoading: false
					}
				})
			} else if (values.type === CODE_PORTION_TYPES.ANONYMOUS) {
				this.setState({
					codePortionsCount: {
						addressCodes: 0,
						anonymousCodes: Number(get(values, 'anonymousCodes.fixed', 0)) + Number(get(values, 'anonymousCodes.oneTime', 0)),
						isLoading: false
					}
				})
			}
		} catch (error) {
			this.setState({
				modalData: null,
				modal: null,
				codePortionsCount: {
					addressCodes: 0,
					anonymousCodes: 0,
					isLoading: false
				}
			})
		}
	}

	render() {
		const {
			selectedFiltersOrders, codePortions, municipalities, containerTypes, actions, codePortionType, municipalityID, collectionPlaces
		} = this.props

		const { modal, modalData, codePortionsCount } = this.state

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		const submitType = get(modalData, 'type')

		const submitCount = submitType === CODE_PORTION_TYPES.ADDRESS
			? codePortionsCount.addressCodes
			: codePortionsCount.anonymousCodes

		const submitMessage = codePortionsCount.isLoading
			? locale['pages.codePortions.submit.loading']
			: locale.formatString(locale['pages.codePortions.submit.message'], submitCount)

		const canDeletePortion = get(codePortions, 'data.lastPortion.state') === CODE_PORTION_STATES.CREATED

		return (
			<>
				<Breadcrumbs {...this.breadcrumbsItems}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.codePortions.breadcrumbs']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={16} justify={'end'}>
								<Col>
									<Button
										onClick={() => this.setState({ modal: MODAL.IMPORT_RFID })}
									>
										<PlusOutlined/>
										<span>{locale['page.municipalities.detail.import.rfid.button']}</span>
									</Button>
								</Col>
								<Col>
									<Button
										onClick={this.onCreate}
									>
										<PlusOutlined/>
										<span>{locale['page.codePortions.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<CodePortionsTable
										onSort={(value) => this.onChangeFilterOrder([{
											type: queryParams.order,
											value
										}])}
										onDelete={this.onDelete}
										onDownload={this.openDownloadModal}
										onPrint={this.onPrint}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										portions={codePortions}
										onDetail={this.onDetail}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder([{ type: queryParams.limit, value: limit }])}
									/>
									<Pagination
										pages={get(codePortions, 'data.context.pages')}
										page={get(codePortions, 'data.context.page')}
										onPage={(value) => this.onChangeFilterOrder([{
											type: queryParams.page,
											value
										}])}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ImportModal
					title={locale['page.municipalities.detail.import.rfid']}
					shown={modal === MODAL.IMPORT_RFID}
					dismissHandler={this.closeModal}
					createHandler={this.importRfid}
				/>
				<CodePortionForm
					shown={modal === MODAL.CREATE_CODE_PORTION}
					municipalities={municipalities}
					containerTypes={containerTypes}
					dismissHandler={this.closeModal}
					onSubmit={this.openSubmitModal}
					codePortionType={codePortionType}
					municipalityID={municipalityID}
					collectionPlaces={collectionPlaces}
					loadContainerTypes={actions.loadContainerTypes}
					loadCollectionPlaces={actions.loadCollectionPlaces}
				/>
				<DialogModal
					shown={modal === MODAL.PRINT_CODE_PORTION}
					cancelHandler={this.closeModal}
					acceptHandler={() => this.printCodePortion(modalData)}
					message={locale['pages.codePortions.print.message']}
					title={locale['pages.codePortions.print.title']}
					acceptTitle={locale['pages.codePortions.print.accept']}
				/>
				<DownloadModal
					shown={modal === MODAL.CODE_PORTION_EXPORT_DIALOG}
					type={modalData?.type}
					onDownload={this.downloadCodePortion}
					count={modalData?.containersCount || 0}
					dismissHandler={this.closeModal}
				/>
				<DialogModal
					shown={modal === MODAL.SUBMIT_CODE_PORTION}
					cancelHandler={this.closeModal}
					acceptHandler={() => this.createCodePortion(modalData)}
					message={submitMessage}
					title={locale['pages.codePortions.submit.title']}
					acceptTitle={locale['pages.codePortions.submit.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_CODE_PORTION}
					cancelHandler={this.closeModal}
					acceptHandler={canDeletePortion ? () => this.deleteCodePortion(modalData) : null}
					message={this.getDeleteMessage()}
					title={locale['pages.codePortions.delete.title']}
					acceptTitle={locale['pages.codePortions.delete.accept']}
				/>
			</>
		)
	}
}

const selector = formValueSelector(FORMS.CREATE_CODE_PORTION)

const mapStateToProps = (state) => ({
	containerTypes: state.containerTypes.list,
	municipalities: state.selectedMunicipality.municipalities,
	codePortions: state.codePortions.portions,
	collectionPlaces: getCollectionPlacesSelectOptions(state),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CODE_PORTIONS_PAGE],
	municipalityID: selector(state, 'municipalityID'),
	codePortionType: selector(state, 'type'),
	collectionPlaceTypes: selector(state, 'collectionPlaceTypes')
})

const mapDispatchToProps = (dispatch) => ({
	actions: {
		selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch),
		loadPortions: bindActionCreators(loadCodePortions, dispatch),
		loadMunicipalities: bindActionCreators(loadMunicipalities, dispatch),
		loadContainerTypes: bindActionCreators(loadContainerTypes, dispatch),
		loadCollectionPlaces: bindActionCreators(loadCollectionPlacesSelect, dispatch),
		reset: bindActionCreators(reset, dispatch),
		pushStatus: bindActionCreators(statusPush, dispatch)
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CodePortionsPage)
