export const MUNICIPALITY_WASTE_TYPES_LOAD_START = 'MUNICIPALITY_WASTE_TYPES_LOAD_START'
export const MUNICIPALITY_WASTE_TYPES_LOAD_DONE = 'MUNICIPALITY_WASTE_TYPES_LOAD_DONE'
export const MUNICIPALITY_WASTE_TYPES_LOAD_FAIL = 'MUNICIPALITY_WASTE_TYPES_LOAD_FAIL'

export const MUNICIPALITIES_UPDATE = 'MUNICIPALITIES_UPDATE'

export const SELECTED_MUNICIPALITY_LOAD_START = 'SELECTED_MUNICIPALITY_LOAD_START'
export const SELECTED_MUNICIPALITY_LOAD_DONE = 'SELECTED_MUNICIPALITY_LOAD_DONE'
export const SELECTED_MUNICIPALITY_LOAD_FAIL = 'SELECTED_MUNICIPALITY_LOAD_FAIL'
export const SELECTED_MUNICIPALITY_DESELECTED = 'SELECTED_MUNICIPALITY_DESELECTED'

export const MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START = 'MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START'
export const MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE = 'MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE'
export const MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL = 'MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL'

export const MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START = 'MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START'
export const MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL = 'MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL'
export const MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE = 'MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE'

export const MUNICIPALITY_MOTIVATION_MODEL_LOAD_START = 'MUNICIPALITY_MOTIVATION_MODEL_LOAD_START'
export const MUNICIPALITY_MOTIVATION_MODEL_LOAD_DONE = 'MUNICIPALITY_MOTIVATION_MODEL_LOAD_DONE'
export const MUNICIPALITY_MOTIVATION_MODEL_LOAD_FAIL = 'MUNICIPALITY_MOTIVATION_MODEL_LOAD_FAIL'

export const MUNICIPALITIES_LOAD_START = 'MUNICIPALITIES_LOAD_START'
export const MUNICIPALITIES_LOAD_FAIL = 'MUNICIPALITIES_LOAD_FAIL'
export const MUNICIPALITIES_LOAD_DONE = 'MUNICIPALITIES_LOAD_DONE'

export const SET_LAST_COLLECTION_YARD = 'SET_LAST_COLLECTION_YARD'
