import { getReq, patchReq, postReq } from '../utils/request'
import { ListResponse } from '../types/api'
import { CollectionRound } from '../types/data'

export default {
	loadMap: (filters: object) => getReq('/api/v1/collection-rounds/map', filters, '', {}),
	loadOverview: (id: string | number) => getReq(`/api/v1/collection-rounds/${id}/overview`, {}, '', {}),
	exportOverview: (id: string | number) => getReq(`/api/v1/collection-rounds/${id}/overview/export`, {}, '', {}),

	loadCollectionRounds: (filters: object): ListResponse<{ collectionRounds: CollectionRound[] }> => getReq('/api/v0/collection-rounds', filters),
	mergeCollectionRounds: (data: object) => postReq('/api/v1/collection-rounds/merge', {}, data),
	updateCollectionRound: (id: string | number, municipalityID: string | number, data: object) => patchReq(`/api/v0/municipalities/${municipalityID}/collection-rounds/${id}`, {}, data)
}
