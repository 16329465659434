import React, { FocusEvent, KeyboardEvent, useEffect, useState } from 'react'
import cx from 'classnames'

import { LabeledValue } from 'antd/es/select'
import { FieldRenderProps } from 'react-final-form'

import { Info, InputWrapper, Label, Select } from './Styled'
import { createSlug } from '../../utils/helpers'

type FilterOption = (inputValue: string, option: any) => boolean

type FieldValue = string | number | { value: string | number, label: string }

type Props = FieldRenderProps<FieldValue | FieldValue[]> & {
	required?: boolean
	label?: string
	isLoading?: boolean
	className?: string
	placeholder?: string
	mode?: 'multiple' | 'tags'
	info?: string

	disabled?: boolean
	showSearch?: boolean
	labelInValue?: boolean
	allowClear?: boolean
	filterOption?: boolean | FilterOption
	filterSlugBased?: boolean
	onSearch?: (search: string) => void
	tokensSeparator?: string[]
	options: LabeledValue[]
	hasExtra?: boolean
}

const SelectField = ({ label, required, options, isLoading, input, className, meta, mode, onSearch, showSearch, info, hasExtra, filterSlugBased, ...rest }: Props) => {
	const [focused, setFocused] = useState(!!input.value)
	const [searchValue, setSearchValue] = useState('')

	const valid = !!meta.valid && !!input.value
	const error = meta.touched && meta.error
	const value = input.value ? input.value : undefined

	useEffect(() => {
		if (onSearch) {
			onSearch(searchValue)
		}
	}, [onSearch, searchValue])

	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		input.onFocus(e)
		setFocused(true)
	}

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		input.onBlur(e)
		setFocused(false)
	}

	const handleChange = (value: any, options: any) => {
		let val = value

		if (hasExtra && mode !== 'tags' && mode !== 'multiple' && options?.extra) {
			val = {
				...value,
				extra: options?.extra
			}
		}

		input.onChange(val)
		setSearchValue('')
	}

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (showSearch && onSearch && e.code === 'Enter') {
			e.stopPropagation()
		}
	}

	const inputClassName = cx(className || '', {
		error,
		info: !!info,
		valid
	})

	let filterOptionWrap = rest.filterOption
	if (filterSlugBased === true) {
		filterOptionWrap = (inputValue: any, option: any) => createSlug(option.label.toLowerCase()).indexOf(createSlug(inputValue.toLowerCase())) >= 0
	}

	return (
		<InputWrapper className={className}>
			<Label
				className={cx({ placeholder: !input.value && !focused })}
			>
				{label}{required ? '*' : ''}
			</Label>
			<Select
				{...input}
				{...rest}
				// @ts-ignore
				value={value}
				showSearch={showSearch}
				searchValue={showSearch ? searchValue : undefined}
				onSearch={showSearch ? setSearchValue : undefined}
				onInputKeyDown={handleKeyDown}
				options={options}
				loading={isLoading}
				className={inputClassName}
				onChange={handleChange}
				onBlur={handleBlur}
				onFocus={handleFocus}
				mode={mode}
				filterOption={filterOptionWrap}
			/>
			<Info className={inputClassName}>{meta.touched ? meta.error || info || '' : info || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default SelectField
