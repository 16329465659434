import React, { PureComponent } from 'react'
import cx from 'classnames'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import * as PropTypes from 'prop-types'

import { Fields } from 'redux-form'

import locale from '../../resources/locale'
import { FILTER_POSITION, FILTER_POSITIONS } from '../../utils/enums'
import { isSameDate } from '../../utils/utils'
import Button from '../buttons/Button'

require('moment/locale/sk')

const dateFormat = locale['common.date.format.date']

const renderYear = (props, year, selectedDate) => {
	let selected = false
	if (selectedDate) {
		const selectedYear = selectedDate.year()
		selected = selectedYear === year
	}
	return (
		// eslint-disable-next-line react/prop-types
		<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
			{year}
		</td>
	)
}

const renderMonth = (props, month, year, selectedDate) => {
	let selected = false
	if (selectedDate) {
		const selectedYear = selectedDate.year()
		const selectedMonth = selectedDate.month()
		selected = selectedYear === year && selectedMonth === month
	}
	return (
		// eslint-disable-next-line react/prop-types
		<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
			{locale[`month.short.${month}`]}
		</td>
	)
}

const renderDay = (props, currentDate, fields) => {
	let marginFrom = false
	let marginTo = false

	const fromField = fields[fields.fromName]
	const toField = fields[fields.toName]
	const currentDateValue = currentDate.toISOString()

	let through = false

	if (fromField.input.value) {
		marginFrom = isSameDate(currentDate, moment(fromField.input.value))
	}

	if (toField.input.value) {
		marginTo = !marginFrom && isSameDate(currentDate, moment(toField.input.value))
	}

	if (fromField.input.value && toField.input.value) {
		through = !marginFrom && !marginTo && fromField.input.value < currentDateValue && toField.input.value > currentDateValue
	}

	const today = isSameDate(moment(), currentDate)

	return (
		// eslint-disable-next-line react/prop-types
		<td {...props} className={cx(props.className, { marginFrom, marginTo, through, today }, 'calendar-tile')}>
			{currentDate.date()}
		</td>
	)
}

const onChange = (date, fields) => {
	const fromField = fields[fields.fromName]
	const toField = fields[fields.toName]

	const dateValue = date.toISOString()
	const fromValue = fromField.input.value
	const toValue = toField.input.value

	if (!fromValue && !toValue) {
		fromField.input.onChange(date.startOf('day').toISOString())
		toField.input.onChange(date.endOf('day').toISOString())
	} else if (dateValue < fromValue) {
		fromField.input.onChange(date.startOf('day').toISOString())
	} else if (dateValue > toValue) {
		toField.input.onChange(date.endOf('day').toISOString())
	} else {
		toField.input.onChange(date.endOf('day').toISOString())
	}
}

const formatDateRange = (fields) => {
	const fromField = fields[fields.fromName]
	const toField = fields[fields.toName]

	const fromValue = fromField.input.value
	const toValue = toField.input.value

	if (!fromValue && !toValue) {
		return locale['field.placeholder.date.range']
	} if (!fromValue) {
		return locale.formatString(locale['dateRange.to'], moment(toValue).format(dateFormat))
	} if (!toValue) {
		return locale.formatString(locale['dateRange.from'], moment(fromValue).format(dateFormat))
	}
	return locale.formatString(locale['dateRange.from.to'], moment(fromValue).format(dateFormat), moment(toValue).format(dateFormat))
}

const DatetimeField = (fields) => (
	<>
		<div className={'date-format'}>
			{formatDateRange(fields)}
		</div>
		<div className={'date-picker range-picker filter active'}>
			<Datetime
				onChange={(value) => onChange(value, fields)}
				className={'date-picker-calendar'}
				locale={'sk'}
				input={false}
				timeFormat={false}
				renderYear={renderYear}
				renderMonth={renderMonth}
				renderDay={(props, currentDate) => renderDay(props, currentDate, fields)}
			/>
		</div>
	</>
)

class DateRangeFilter extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		onFilter: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
		visible: PropTypes.bool.isRequired,
		position: PropTypes.oneOf(FILTER_POSITIONS)
	}

	node = React.createRef()

	render() {
		const { onFilter, onReset, title, visible, name, position } = this.props

		return (
			<div
				ref={this.node}
				className={cx('table-filter date-range', { visible, left: position === FILTER_POSITION.LEFT })}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={'row'}>
					<div className={'col-8 title'}>{title}</div>
					<div className={'col-4 reset'}>
						<span onClick={onReset}>{locale['common.reset']}</span>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-12 stats-range-picker'}>
						<Fields
							names={[`${name}From`, `${name}To`]}
							fromName={`${name}From`}
							toName={`${name}To`}
							component={DatetimeField}
						/>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-12'}>
						<Button
							className={cx('pull-right')}
							onClick={onFilter}
						>
							{locale['common.use']}
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default DateRangeFilter
