import React from 'react'
import { map } from 'lodash'
import { Col, Row } from 'antd'

import Dot from '../Dot'

import { Text } from '../Typography'

type Props = {
	stats: {
		color: string
		label: string
	}[]
}

const ChartLegend = ({ stats }: Props) => (
	<Row gutter={[16, 16]} wrap={true}>
		{map(stats, (item, index) => (
			<Col key={index}>
				<Dot color={item.color}/>
				<Text className={'without-margin'}>{item.label}</Text>
			</Col>
		))}
	</Row>
)

export default React.memo(ChartLegend)
