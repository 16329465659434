import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { reduce, chunk, map } from 'lodash'
import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import locale from '../../resources/locale'

import CalendarWeek from './CalendarWeek'
import CalendarLegend from './CalendarLegend'
import Button from '../buttons/Button'

class CalendarView extends React.Component {
	static propTypes = {
		events: PropTypes.arrayOf(PropTypes.shape({
			date: PropTypes.string.isRequired,
			collectionRounds: PropTypes.arrayOf(PropTypes.shape({
				wasteType: PropTypes.shape({
					color: PropTypes.string.isRequired
				})
			}))
		})),
		days: PropTypes.arrayOf(PropTypes.shape({
			day: PropTypes.number.isRequired,
			month: PropTypes.number.isRequired,
			year: PropTypes.number.isRequired,
			week: PropTypes.number.isRequired
		})),
		selectedDay: PropTypes.shape({
			day: PropTypes.number.isRequired,
			month: PropTypes.number.isRequired,
			year: PropTypes.number.isRequired
		}).isRequired,
		month: PropTypes.number.isRequired,
		year: PropTypes.number.isRequired,
		loading: PropTypes.bool,
		onSelect: PropTypes.func.isRequired,
		onSelectNextMonth: PropTypes.func.isRequired,
		onSelectPreviousMonth: PropTypes.func.isRequired,
		onCreate: PropTypes.func
	}

	getWeeks = () => {
		const { onSelect, onCreate, events, days, selectedDay, month } = this.props

		const groupedEvents = reduce(events, (value, item) => {
			const date = moment(item.date)
			const day = date.date()
			const month = date.month()
			const key = `${day}.${month}`
			value[key] = [...value[key] || [], item]
			return value
		}, {})

		const weeks = chunk(days, 7)
		return map(weeks, (week, index) => (
			<CalendarWeek
				key={index}
				events={groupedEvents}
				days={week}
				month={month}
				selectedDay={selectedDay}
				onCreate={onCreate}
				onSelect={onSelect}
			/>
		))
	}

	onCreate = () => {
		const { onCreate, selectedDay } = this.props

		const date = moment().set('year', selectedDay.year).set('month', selectedDay.month).set('date', selectedDay.day)
		onCreate(date)
	}

	render() {
		const { onSelectNextMonth, onSelectPreviousMonth, events, onCreate, month, year } = this.props

		return (
			<div className={'box'}>
				<div className={'box-title'}>
					{locale['page.calendar.title']}
				</div>
				<div className={'box-content with-padding'}>
					<div className={'box-head'} style={{ padding: '20px 0 0 0' }}>
						<Row gutter={[16, 16]} justify={'space-between'}>
							<Col>
								<div className={'circle'} onClick={onSelectPreviousMonth}>
									<FontAwesomeIcon icon={faChevronLeft}/>
								</div>
								<div className={'month-name'}>
									<span>{`${locale[`month.${month}`]} ${year}`}</span>
								</div>
								<div className={'circle'} onClick={onSelectNextMonth}>
									<FontAwesomeIcon icon={faChevronRight}/>
								</div>
							</Col>
							<Col>
								{onCreate &&
								<Button
									onClick={this.onCreate}
								>
									<PlusOutlined/>
									<span>{locale['page.calendar.events.add']}</span>
								</Button>}
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<div className={'table-wrapper'} style={{ marginBottom: '40px' }}>
							<table className={'calendar'}>
								<thead>
									<tr>
										<th>{locale['day.1']}</th>
										<th>{locale['day.2']}</th>
										<th>{locale['day.3']}</th>
										<th>{locale['day.4']}</th>
										<th>{locale['day.5']}</th>
										<th>{locale['day.6']}</th>
										<th>{locale['day.7']}</th>
									</tr>
								</thead>
								<tbody>
									{this.getWeeks()}
								</tbody>
							</table>
						</div>
						<CalendarLegend events={events}/>
					</div>
				</div>
			</div>
		)
	}
}

export default CalendarView
