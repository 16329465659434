export const STATISTICS_COUNTS_LOAD_START = 'STATISTICS_COUNTS_LOAD_START'
export const STATISTICS_COUNTS_LOAD_FAIL = 'STATISTICS_COUNTS_LOAD_FAIL'
export const STATISTICS_COUNTS_LOAD_DONE = 'STATISTICS_COUNTS_LOAD_DONE'

export const STATISTICS_CUMULATIVE_WEIGHT_LOAD_START = 'STATISTICS_CUMULATIVE_WEIGHT_LOAD_START'
export const STATISTICS_CUMULATIVE_WEIGHT_LOAD_DONE = 'STATISTICS_CUMULATIVE_WEIGHT_LOAD_DONE'
export const STATISTICS_CUMULATIVE_WEIGHT_LOAD_FAIL = 'STATISTICS_CUMULATIVE_WEIGHT_LOAD_FAIL'

export const STATISTICS_TOP_HOUSEHOLDS_LOAD_START = 'STATISTICS_TOP_HOUSEHOLDS_LOAD_START'
export const STATISTICS_TOP_HOUSEHOLDS_LOAD_FAIL = 'STATISTICS_TOP_HOUSEHOLDS_LOAD_FAIL'
export const STATISTICS_TOP_HOUSEHOLDS_LOAD_DONE = 'STATISTICS_TOP_HOUSEHOLDS_LOAD_DONE'

export const STATISTICS_PROGRESS_COLLECTIONS_LOAD_START = 'STATISTICS_PROGRESS_COLLECTIONS_LOAD_START'
export const STATISTICS_PROGRESS_COLLECTIONS_LOAD_FAIL = 'STATISTICS_PROGRESS_COLLECTIONS_LOAD_FAIL'
export const STATISTICS_PROGRESS_COLLECTIONS_LOAD_DONE = 'STATISTICS_PROGRESS_COLLECTIONS_LOAD_DONE'

export const STATISTICS_SORTING_RATE_LOAD_START = 'STATISTICS_SORTING_RATE_LOAD_START'
export const STATISTICS_SORTING_RATE_LOAD_DONE = 'STATISTICS_SORTING_RATE_LOAD_DONE'
export const STATISTICS_SORTING_RATE_LOAD_FAIL = 'STATISTICS_SORTING_RATE_LOAD_FAIL'

export const STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_START = 'STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_START'
export const STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_DONE = 'STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_DONE'
export const STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_FAIL = 'STATISTICS_COLLECTION_PLACES_MAP_DATA_LOAD_FAIL'
