import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import cx from 'classnames'
import i18next from 'i18next'

// components
import Modal from './Modal'

// resources
import locale from '../../resources/locale'

// atoms
import TextInputField from '../../atoms/TextInputField'

// utils
import { FORMS } from '../../utils/enums'
import { validate, validatorOptions } from '../../utils/validator'
import Button from '../buttons/Button'

const validateString = validate([validatorOptions.REQUIRED_STRING])
const validatePassword = validate([
	validatorOptions.REQUIRED_STRING,
	validatorOptions.PASSWORD_LENGTH,
	validatorOptions.PASSWORD_UPPERCASE,
	validatorOptions.PASSWORD_LOWERCASE,
	validatorOptions.PASSWORD_NUMBER,
	validatorOptions.PASSWORD_SPECIAL
])

class ChangePassword extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		submitHandler: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired
	}

	state = {
		shown: false
	}

	passwordFieldOptions = {
		label: i18next.t('loc:Staré heslo'),
		required: true
	}

	newPasswordFieldOptions = {
		label: i18next.t('loc:Nové heslo'),
		required: true
	}

	confirmPasswordFieldOptions = {
		label: i18next.t('loc:Opakovať Heslo'),
		required: true
	}

	validateConfirmPassword = (value, allValues) => {
		if (value !== allValues.newPassword) {
			return locale['error.validation.PASSWORD_MATCH']
		}
		return undefined
	}

	dismiss = () => {
		const { reset, dismissHandler } = this.props
		reset()
		dismissHandler()
	}

	render = () => {
		const { handleSubmit, submitHandler, shown } = this.props

		return (
			<Modal dismiss={this.dismiss} shown={shown} size="s">
				<form onSubmit={handleSubmit((data) => submitHandler(data))} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['page.users.detail.change.password']}
					</div>
					<div className={'modal-content'}>
						<div className="row" style={{ marginBottom: '20px' }}>
							<div className={'col-12'}>
								<Field
									name='oldPassword'
									type={'password'}
									placeholder={locale['field.placeholder.password.old']}
									props={this.passwordFieldOptions}
									component={TextInputField}
									validate={validateString}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='newPassword'
									type={'password'}
									placeholder={locale['field.placeholder.password.new']}
									component={TextInputField}
									props={this.newPasswordFieldOptions}
									validate={validatePassword}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='confirmPassword'
									type={'password'}
									placeholder={locale['field.placeholder.password.confirm']}
									component={TextInputField}
									props={this.confirmPasswordFieldOptions}
									validate={[validateString, this.validateConfirmPassword]}
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Button
							type="submit"
							className={cx('button', 'pull-right')}
							style={{ marginTop: '20px' }}
						>
							{locale['page.users.detail.change']}
						</Button>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CHANGE_PASSWORD,
	updateUnregisteredFields: true,
	destroyOnUnmount: true,
	touchOnChange: true
})(ChangePassword)
