import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Col, Row } from 'antd'

import { getSelectedMunicipality } from '../../redux/municipalities/selectors'

import { Box, BoxContent, BoxHeader } from '../../layouts/Box'
import { HeadingOne, SubHeading } from '../../components/Typography'

import ContainersSummary from './components/ContainersSummary'
import CollectionsSummary from './components/CollectionsSummary'
import HouseholdsSummary from './components/HouseholdsSummary'
import WasteTypesSummary from './components/WasteTypesSummary'
import SortingRateSummary from './components/SortingRateSummary'
import Calendar from './components/Calendar'
import Map from './components/Map'
import WastesPerCustomer from './components/WastesPerCustomer'

const DashboardPage = () => {
	const [t] = useTranslation()

	const municipality = useSelector(getSelectedMunicipality)

	return (
		<Box className={'padded'}>
			<BoxHeader>
				<HeadingOne>{t('DashboardPage.title')}</HeadingOne>
				<SubHeading className={'grey'}>{t('DashboardPage.subtitle')}</SubHeading>
			</BoxHeader>
			<Row gutter={[24, 24]}>
				<Col xl={17} lg={24} md={24} sm={24}>
					<BoxContent className={'padded'}>
						<ContainersSummary/>
					</BoxContent>
				</Col>
				<Col xl={7} lg={24} md={24} sm={24}>
					<BoxContent className={'padded'}>
						<CollectionsSummary/>
					</BoxContent>
				</Col>
			</Row>
			{!!municipality &&
			<BoxContent className={'padded top-margin'}>
				<WasteTypesSummary municipalityID={municipality.id}/>
			</BoxContent>}
			<BoxContent className={'padded top-margin'}>
				<HouseholdsSummary municipalityID={municipality?.id}/>
			</BoxContent>
			<BoxContent className={'padded top-margin'}>
				<WastesPerCustomer municipalityID={municipality?.id}/>
			</BoxContent>
			{!!municipality &&
			<Row gutter={[24, 0]}>
				<Col xl={12} md={24} sm={24} xs={24}>
					<BoxContent className={'padded top-margin'}>
						<SortingRateSummary
							title={t('DashboardPage.totalSortingRateTitle')}
							municipalityID={municipality.id}
							useYear={false}
						/>
					</BoxContent>
				</Col>
				<Col xl={12} md={24} sm={24} xs={24}>
					<BoxContent className={'padded top-margin'}>
						<SortingRateSummary
							title={t('DashboardPage.periodSortingRateTitle')}
							municipalityID={municipality.id}
							useYear={true}
						/>
					</BoxContent>
				</Col>
			</Row>}
			<BoxContent className={'padded top-margin'}>
				<Calendar municipalityID={municipality?.id}/>
			</BoxContent>
			<BoxContent className={'top-margin'}>
				<Map municipalityID={municipality?.id}/>
			</BoxContent>
		</Box>
	)
}

export default DashboardPage
