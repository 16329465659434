import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class RadioField extends React.Component {
	handleChange = (value) => {
		const { input, customOnChange } = this.props
		if (customOnChange) {
			customOnChange()
		}
		input.onChange(value)
	}

	render = () => {
		const { value, input, label } = this.props
		return (
			<label onClick={() => this.handleChange(value)} className={'radio-wrapper'}>
				<div className={cx('radio-button', { active: input.value === value })}/>
				{/* <input name={name} value={value} type="radio" onChange={this.handleChange} checked={input.value === value}/> */}
				{input.value === value && <strong className={'radio-label'}>{label}</strong>}
				{input.value !== value && <span className={'radio-label'}>{label}</span> }
			</label>
		)
	}
}

RadioField.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	value: PropTypes.any,
	name: PropTypes.string,
	label: PropTypes.string,
	customOnChange: PropTypes.func
}

export default RadioField
