import { ListResponse, Response } from '../types/api'
import { MunicipalityBase, MunicipalityDetail } from '../types/data'
import { getReq, patchReq, postReq } from '../utils/request'
import { uploadFile } from '../utils/upload'

export default {
	loadMunicipalities: (filters: object): ListResponse<{ municipalities: MunicipalityBase[] }> => getReq('/api/v0/municipalities', filters),
	loadMunicipality: (id: string | number): Response<MunicipalityDetail> => getReq(`/api/v0/municipalities/${id}`, {}),
	loadMotivationModels: (id: string | number) => getReq(`/api/v0/municipalities/${id}/motivation-models`, {}),

	updateMunicipality: (id: string | number, data: object) => patchReq(`/api/v0/municipalities/${id}/`, {}, data),
	createMotivationModel: (id: string | number, data: object) => postReq(`/api/v0/municipalities/${id}/motivation-models`, {}, data),
	updateMotivationModel: (id: string | number, municipalityID: string| number, data: object) => patchReq(`/api/v0/municipalities/${municipalityID}/motivation-models/${id}`, {}, data),

	importCustomers: (municipalityID: string | number, file: any, name: string, data: any = {}) => uploadFile(file, data, name, `/api/v1/municipalities/${municipalityID}/customers/import`),
	importCalendar: (municipalityID: string | number, file: any, name: string, data: any = {}) => uploadFile(file, data, name, `/api/admin/municipalities/${municipalityID}/calendar/import`),
	importMunicipality: (municipalityID: string | number, file: any, name: string, data: any = {}) => uploadFile(file, data, name, `/api/v0/municipalities/${municipalityID}/import`),
	importContainers: (municipalityID: string | number, file: any, name: string, data: any = {}) => uploadFile(file, data, name, `/api/v0/municipalities/${municipalityID}/containers/import`),
	importMigrationPZO: (municipalityID: string | number, file: any, name: string, data: any = {}) => uploadFile(file, data, name, '/api/v0/migration/enrich-pzo')
}
