import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { RouteComponentProps } from 'react-router'

import { Breadcrumb, Layout } from 'antd'

import Menu from './Menu'
import HeaderContent from './HeaderContent'

import JRKLogo from '../../resources/images/logo-jrk.png'
import ElwisLogo from '../../resources/images/elwis.svg'

const { Header, Content, Sider } = Layout

type Props = RouteComponentProps<{ municipalityID?: string }> & PropsWithChildren<{
	path: string
	breadcrumbs?: {
		title: string
		link?: string
	}[]
}>

const InnerContent = styled.section`
	min-height: calc(100% - 80px);
	padding: 16px;
`

const Footer = styled.footer`
 	height: 80px;
 	background: white;
 	border-top: 2px solid ${({ theme }) => theme.colors.primary['500']};
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-weight: bold;
	font-size: 12px;
	padding: 0 32px;

	& > div > a {
		color: ${({ theme }) => theme.colors.primary['500']}
	}

	& > div > img {
		height: 40px;
		margin-left: 10px;
	}
`

const DesktopSiderWrap = styled.div`
	@media only screen and (max-width: 992px) {
		display: none;
	}
`

const MainLayout = ({ children, breadcrumbs, match, ...props }: Props) => {
	const [t] = useTranslation()

	const menu = <Menu {...props} match={match}/>

	return <Layout className={'site-layout-background'} style={{ height: '100%' }}>
		<Header style={{ padding: 0, boxShadow: 'none' }}>
			<HeaderContent menu={menu}/>
		</Header>
		<Layout>
			<DesktopSiderWrap>
				<Sider width={250} style={{ paddingBottom: 0, height: '100%' }}>
					{menu}
				</Sider>
			</DesktopSiderWrap>
			<Layout style={{ padding: '0' }}>
				{!!breadcrumbs &&
				<Breadcrumb style={{ margin: '16px 0' }}>
					{map(breadcrumbs, (item) => (
						<Breadcrumb.Item href={item.link}>{item.title}</Breadcrumb.Item>
					))}
				</Breadcrumb>}
				<Content style={{ overflowY: 'scroll' }}>
					<InnerContent>
						{children}
					</InnerContent>
					<Footer>
						<div>
							<span>Powered by</span>
							<img src={JRKLogo} alt={'JRK'}/>
						</div>
						<div>
							<span>{t('MainLayout.version')} {process.env.REACT_APP_VERSION}</span>
						</div>
						<div>
							<a href={'//www.menejodpadu.sk'} target={'_blank'} rel="noreferrer">www.menejodpadu.sk</a>
							<img src={ElwisLogo} alt={'Elwis'}/>
						</div>
					</Footer>
				</Content>
			</Layout>
		</Layout>
	</Layout>
}

export default MainLayout
