import { WARN, ERROR, INFO, SUCCESS } from './enums'

export default (errorType) => {
	switch (errorType) {
		case WARN:
			return 'orange'
		case INFO:
		case SUCCESS:
			return 'green'
		case ERROR:
			return 'red'
		default:
			return 'blue'
	}
}

export const colorByWasteType = (type) => {
	switch (type) {
		case 'municipal':
			return '#000000'
		case 'paper':
			return '#4348c2'
		case 'plastic':
			return '#c3c123'
		case 'glass':
			return '#399359'
		default:
			return '#919191'
	}
}
