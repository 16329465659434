import React, { useState } from 'react'
import styled from 'styled-components'

import Popover from 'react-tiny-popover'

import { map, join } from 'lodash'
import { Row, Col } from 'antd'

import locale from '../../../resources/locale'

import { formatDatetime } from '../../../utils/utils'
import { history } from '../../../utils/history'

import { Text } from '../../../components/Typography'

import ValidIcon from '../../../resources/images/icon-valid-pin.svg'
import InvalidIcon from '../../../resources/images/icon-invalid-pin.svg'
import UncollectedIcon from '../../../resources/images/icon-uncollected-pin.svg'
import TrashIcon from '../../../resources/images/icon-trash.svg'

const Icon = styled.div`
	height: 35px;
	width: 35px;
	position: relative;
	
	&.VALID {
		background: url("${ValidIcon}") center no-repeat;
	}
	
	&.INVALID {
		background: url("${InvalidIcon}") center no-repeat;
	}

	&.UNCOLLECTED {
		background: url("${UncollectedIcon}") center no-repeat;
	}
	
	background-color: transparent;

	background-size: contain !important;
`

const InfoWrapper = styled.div`
	padding-bottom: 20px;
`

const Info = styled.div`
	width: 200px;
	background: white;
	padding: 14px;
	border-radius: 8px;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		width: 15px;
		height: 15px;
		border-radius: 4px;
		bottom: 12px;
		background-color: white;
		z-index: 10;
	}
`

type Props = {
	pin: any
}

const MapPin = ({ pin }: Props) => {
	const [open, setOpen] = useState(false)

	const invalidStates = map(pin.invalidState, (state) =>
		// @ts-ignore
		locale[`invalid.state.${state}`])

	// @ts-ignore
	const unsuitableState = pin.unsuitableConditionState ? locale[`unsuitable.conditions.${pin.unsuitableConditionState}`] : null

	const handleClick = () => {
		if (pin.collectionID && pin.municipalityID) {
			history.push(`/obce/${pin.municipalityID}/evidencia/zaznamy/${pin.collectionID}`)
		}
	}

	return (
		<Popover
			isOpen={open}
			content={(
				<InfoWrapper>
					<Info>
						<Row gutter={8} style={{ marginBottom: 13 }}>
							<Col>
								<img src={TrashIcon} alt={'Trash'}/>
							</Col>
							<Col>
								<strong>{pin.code}</strong>
							</Col>
						</Row>
						{!!pin.name && <Text>{pin.name}</Text>}
						<Text style={{ marginBottom: 13 }}>{pin.lat}, {pin.lon}</Text>
						<Text className={'grey'}>{formatDatetime(pin.scanDatetime)}</Text>
						{!!invalidStates.length &&
						<Text className={'grey'}>
							{join(invalidStates, ', ')}
						</Text>}
						{!!unsuitableState &&
						<Text className={'grey'}>{unsuitableState}</Text>}
					</Info>
				</InfoWrapper>
			)}
			position={'top'}
			containerStyle={{ zIndex: '10' }}
		>
			<Icon
				onMouseEnter={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				className={pin.type}
				onClick={handleClick}
			/>
		</Popover>
	)
}

export default MapPin
