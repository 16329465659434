import { USER_UPDATE } from '../types/user'

export function updateUser(user) {
	return {
		type: USER_UPDATE,
		payload: {
			user
		}
	}
}
