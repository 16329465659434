import styled from 'styled-components'

const WasteTypeLabel = styled.div`
	display: flex;
	align-items: center;
	
	& > * + * {
		margin-left: 5px;
		margin-bottom: -2px;
	}
`

export default WasteTypeLabel
