import styled from 'styled-components'

const Dot = styled.div<{ color?: string }>`
	width: 12px;
	height: 12px;
	border-radius: 6px;
	margin-right: 12px;
	font-size: 12px;
	
	min-width: 12px;
	
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${({ color }) => color || 'black'};
	
	&.small {
		font-size: 8px;
		
		width: 8px;
		height: 8px;
		border-radius: 4px;
		margin-right: 2px;

		min-width: 8px;
	}
`

export default Dot
