import { FILTER_TYPE } from '../../utils/enums'
import { QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { formatDatetime } from '../../utils/utils'

export const queryParams = {
	order: 'order',
	page: 'page',
	limit: 'limit',
	columns: 'columns',

	codeSearch: 'codeSearch',
	serialSearch: 'serialSearch',
	validFromFrom: 'validFromFrom',
	validFromTo: 'validFromTo',
	validToFrom: 'validToFrom',
	validToTo: 'validToTo',
	createdAtFrom: 'createdAtFrom',
	createdAtTo: 'createdAtTo',
	customerNameSearch: 'customerNameSearch'
}

export const columns = [
	{
		name: 'code',
		sortable: true,
		className: 'wide',
		title: 'Kód',
		filter: {
			name: 'codeSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'serial',
		sortable: true,
		title: 'Číslo',
		filter: {
			name: 'serialSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'validFrom',
		sortable: true,
		title: 'Platný od',
		render: formatDatetime,
		filter: {
			type: FILTER_TYPE.DATE_RANGE
		}
	}, {
		name: 'validTo',
		sortable: true,
		title: 'Platný do',
		render: formatDatetime,
		filter: {
			type: FILTER_TYPE.DATE_RANGE
		}
	}, {
		name: 'customer.name',
		sortable: true,
		title: 'Názov platcu',
		filter: {
			name: 'customerNameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'createdAt',
		sortable: true,
		title: 'Dátum vytvorenia',
		render: formatDatetime,
		filter: {
			type: FILTER_TYPE.DATE_RANGE
		}
	}
]

export const defaultColumns = [columns[0].name, columns[1].name, columns[2].name, columns[3].name, columns[4].name, columns[5].name]

export const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'code:ASC',
		allowedValues: [
			'code', 'serial', 'validFrom', 'validTo', 'customer.name', 'municipality.name', 'createdAt'
		]
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	columns: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: defaultColumns
	},
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	codeSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	serialSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	validFromFrom: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	validFromTo: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	validToFrom: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	validToTo: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	createdAtFrom: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	createdAtTo: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	customerNameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	}
}
