export enum CONTAINER_TYPE {
	PERMANENT = 'PERMANENT',
	ONE_TIME = 'ONE_TIME',
	UNIVERSAL = 'UNIVERSAL',
	COLLECTION_YARD = 'COLLECTION_YARD'
}

export enum MESSAGE_TYPE {
	INFO = 'INFO',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum ASSIGN_TYPE {
	DEFAULT = 'DEFAULT',
	FIRST_AND_LAST = 'FIRST_AND_LAST'
}
