import React from 'react'
import Switch from 'react-switch'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

class SwitchField extends React.Component {
	static propTypes = {
		action: PropTypes.shape({
			onChange: PropTypes.func.isRequired,
			checked: PropTypes.bool.isRequired
		}),
		style: PropTypes.object,
		className: PropTypes.string
	}

	constructor(props) {
		super(props)

		const { action } = props
		this.state = {
			checked: action?.checked || false
		}
	}

	handleChange = (checked) => {
		const { action } = this.props
		this.setState({
			checked
		})
		action.onChange(checked)
	}

	render() {
		const { action, style, className } = this.props
		const { checked } = this.state

		return (
			<label className={cx('switch-wrapper', className)} style={style}>
				<p>{checked ? action.onLabel : action.offLabel}</p>
				<Switch
					{...action}
					checked={checked}
					className={'switch'}
					onChange={debounce(this.handleChange, 150)}
				/>
			</label>
		)
	}
}

export default SwitchField
