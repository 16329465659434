import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnyObject } from 'final-form'
import dayjs from 'dayjs'

import { State } from './index'
import { OrderDirection } from '../types/data'
import {
	BULK_COLLECTIONS_TAB_PANE, COLLECTION_PLACE_TYPE, SORTING_GROUP, SORTING_PERIOD, TIME_SPAN
} from '../utils/enums'

type BaseTableFilters = {
	order: string
	direction: OrderDirection
	current: number
	pageSize: number
}

type FilterPayload<Key extends keyof FiltersState> = {
	key: Key
	filters: FiltersState[Key]
}

type PartialFilterPayload<Key extends keyof FiltersState> = {
	key: Key
	filters: Partial<FiltersState[Key]>
}

export type FiltersState = {
	collections: AnyObject
	collectionYardVisits: {
		year: number
		day: number
		week: string
		timeSpan: TIME_SPAN
	},
	bulkCollectionsWasteTypeSummaryFilters: {
		period: string
	}
	bulkCollectionsTab: BULK_COLLECTIONS_TAB_PANE,
	bulkCollections: BaseTableFilters & {
		order: 'name' | 'weight' | 'scanDatetime' | 'customer'
		nameSearch: string
		customerSerialSearch: string
		customerSearch: string
		wasteTypes: number[]
		search: string
		scanDatetime?: string[]
		weight?: (number | null)[]
	}
	bulkCollectionsCustomersSummary: BaseTableFilters & {
		order: 'name' | 'visitCount' | 'unsortedWeight' | 'sortedWeight' | 'totalWeight'
		direction: 'ascend' | 'descend'
		nameSearch: string
		codeSearch: string
		year: number
	}
	collectionPlacesSorting: BaseTableFilters & {
		search: string
		fromDate: string
		toDate: string
		type: COLLECTION_PLACE_TYPE | null
		filter: SORTING_GROUP | null
	}
	customersSorting: BaseTableFilters & {
		search: string
		fromDate: string
		toDate: string
		filter: SORTING_GROUP | null
	}
	municipalitySorting: BaseTableFilters & {
		period: SORTING_PERIOD
	},
	weight: {
		wasteTypes: []
		fromDate: string
		toDate: string
	}
}

const initialState: FiltersState = {
	collections: {},
	collectionYardVisits: {
		year: dayjs().year(),
		day: dayjs().day(),
		week: dayjs().toISOString(),
		timeSpan: TIME_SPAN.DAILY
	},
	bulkCollectionsWasteTypeSummaryFilters: {
		period: dayjs().format('YYYY-MM')
	},
	bulkCollectionsTab: BULK_COLLECTIONS_TAB_PANE.STATS,
	bulkCollections: {
		order: 'scanDatetime',
		direction: 'descend',
		nameSearch: '',
		customerSerialSearch: '',
		customerSearch: '',
		scanDatetime: undefined,
		weight: undefined,
		wasteTypes: [],
		search: '',
		current: 1,
		pageSize: 20
	},
	bulkCollectionsCustomersSummary: {
		order: 'visitCount',
		direction: 'descend',
		year: dayjs().year(),
		nameSearch: '',
		codeSearch: '',
		current: 1,
		pageSize: 10
	},
	collectionPlacesSorting: {
		search: '',
		order: 'name',
		direction: 'descend',
		current: 1,
		pageSize: 20,
		filter: null,
		fromDate: '',
		toDate: '',
		type: null
	},
	customersSorting: {
		search: '',
		order: 'name',
		direction: 'descend',
		current: 1,
		pageSize: 20,
		filter: null,
		fromDate: '',
		toDate: ''
	},
	municipalitySorting: {
		order: 'year',
		direction: 'descend',
		current: 1,
		pageSize: 20,
		period: SORTING_PERIOD.MONTH
	},
	weight: {
		wasteTypes: [],
		fromDate: '',
		toDate: ''
	}
}
export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		reset: () => initialState,
		savePartialFilters: <Key extends keyof FiltersState>(state: FiltersState, action: PayloadAction<PartialFilterPayload<Key>>) => {
			const filters = state[action.payload.key]

			state[action.payload.key] = {
				// @ts-ignore
				...filters,
				...action.payload.filters
			}
		},
		saveFilters: <Key extends keyof FiltersState>(state: FiltersState, action: PayloadAction<FilterPayload<Key>>) => {
			state[action.payload.key] = action.payload.filters
		}
	}
})

export const getFilters = (key: keyof FiltersState) => (state: State) => state.filters[key]
export const getBulkCollectionsCustomersFilters = (state: State) => state.filters.bulkCollectionsCustomersSummary
export const getBulkCollectionsFilters = (state: State) => state.filters.bulkCollections
export const getBulkCollectionsTab = (state: State) => state.filters.bulkCollectionsTab
export const getCollectionYardVisitsFilters = (state: State) => state.filters.collectionYardVisits
export const getBulkCollectionsWasteTypeSummaryFilters = (state: State) => state.filters.bulkCollectionsWasteTypeSummaryFilters
export const getCollectionPlacesSortingFilters = (state: State) => state.filters.collectionPlacesSorting
export const getMunicipalitySortingFilters = (state: State) => state.filters.municipalitySorting
export const getCustomersSortingFilters = (state: State) => state.filters.customersSorting
export const getWeightFilters = (state: State) => state.filters.weight

export const filtersActions = {
	...filtersSlice.actions
}
