import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Form } from 'react-final-form'
import { Col, Row } from 'antd'
import { FormApi } from 'final-form'
import { useDispatch } from 'react-redux'

import { UNDELETE_TABLE } from '../../../utils/enums'
import { getMessages } from '../../../utils/helpers'
import { validateRequired } from '../../../utils/form'

import { statusPush } from '../../../actions/statusActions'
import { admin } from '../../../api'

import SelectField from '../../../components/form/SelectField'
import Button from '../../../components/buttons/Button'
import TextField from '../../../components/form/TextField'

import { BoxContent } from '../../../layouts/Box'
import { SubHeading } from '../../../components/Typography'

type FormValues = {
	id: string
	table: string | null
}

const UndeleteForm = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const handleSubmit = useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
		try {
			const response = await admin.undelete(values)

			form.restart({
				id: '',
				table: null
			})
			const { messages } = getMessages(response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve()
		} catch (e: any) {
			const { messages } = getMessages(e.response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve({})
		}
	}, [dispatch])

	const tableOptions = useMemo(() => [{
		value: UNDELETE_TABLE.COLLECTION_PLACES, label: UNDELETE_TABLE.COLLECTION_PLACES
	}, {
		value: UNDELETE_TABLE.COLLECTION_ROUNDS, label: UNDELETE_TABLE.COLLECTION_ROUNDS
	}, {
		value: UNDELETE_TABLE.COLLECTIONS, label: UNDELETE_TABLE.COLLECTIONS
	}, {
		value: UNDELETE_TABLE.CONTAINER_TYPES, label: UNDELETE_TABLE.CONTAINER_TYPES
	}, {
		value: UNDELETE_TABLE.EVENTS, label: UNDELETE_TABLE.EVENTS
	}, {
		value: UNDELETE_TABLE.CONTAINERS, label: UNDELETE_TABLE.CONTAINERS
	}, {
		value: UNDELETE_TABLE.CUSTOMERS, label: UNDELETE_TABLE.CUSTOMERS
	}, {
		value: UNDELETE_TABLE.MUNICIPALITY_WASTE_TYPES, label: UNDELETE_TABLE.MUNICIPALITY_WASTE_TYPES
	}], [])

	return (
		<Form
			onSubmit={handleSubmit}
			subscription={{ submitting: true }}
			render={({ handleSubmit, submitting }) => (
				<BoxContent className={'top-margin padded'}>
					<SubHeading>{t('ToolsPage.undeleteTitle')}</SubHeading>
					<form onSubmit={handleSubmit}>
						<Row gutter={[16, 16]}>
							<Col span={24} md={12}>
								<Field
									name={'id'}
									validate={validateRequired}
									validateFields={[]}
									render={(props) => (
										<TextField
											{...props}
											label={t('fields.id.label')}
											placeholder={t('fields.id.placeholder')}
										/>
									)}
								/>
							</Col>
							<Col span={24} md={12}>
								<Field
									name={'table'}
									validate={validateRequired}
									validateFields={[]}
									render={(props) => (
										<SelectField
											{...props}
											options={tableOptions}
											label={t('fields.table.label')}
											placeholder={t('fields.table.placeholder')}
										/>
									)}
								/>
							</Col>
						</Row>
					</form>
					<Row justify={'end'}>
						<Col>
							<Button
								loading={submitting}
								disabled={submitting}
								onClick={handleSubmit}
							>
								{t('common.save')}
							</Button>
						</Col>
					</Row>
				</BoxContent>
			)}
		/>
	)
}

export default UndeleteForm
