import React from 'react'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, map } from 'lodash'

// atoms
import TextInputField from '../../../atoms/TextInputField'
import SelectField from '../../../atoms/SelectField'
import NumericInputField from '../../../atoms/NumericInputField'

// utils
import { validate, validateSelect, validatorOptions } from '../../../utils/validator'
import { FORMS } from '../../../utils/enums'
import { parseNumber } from '../../../utils/utils'
import { containerTypes } from '../../../utils/select'

// recources
import locale from '../../../resources/locale'

// components
import InfoFooter from '../../../components/InfoFooter'

// actions
import { statusPush } from '../../../actions/statusActions'
import { getAuthUser } from '../../../redux/authentication'
import Button from '../../../components/buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateNumber = validate([validatorOptions.REQUIRED, validatorOptions.NUMBER])
const validateRequired = validateSelect([validatorOptions.REQUIRED])

class ContainerTypesForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		containerType: PropTypes.object,
		initialValues: PropTypes.object.isRequired,
		backBtnCallback: PropTypes.func.isRequired,
		deleteBtnCallback: PropTypes.func.isRequired,
		setActiveBtnCallback: PropTypes.func.isRequired,
		wasteTypes: PropTypes.object
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov'),
		required: true
	}

	buildActionButtons = (containerType) => {
		if (containerType.data.isActive) {
			return (
				<Col flex={1}>
					<Button
						className={'secondary'}
						onClick={() => this.props.setActiveBtnCallback(containerType.data)}
					>
						{locale['common.deactivate']}
					</Button>
				</Col>
			)
		}

		return (
			<>
				<Col>
					<Button
						className={'danger'}
						onClick={() => this.props.deleteBtnCallback(containerType.data)}
					>
						{locale['common.delete']}
					</Button>
				</Col>
				<Col flex={1}>
					<Button
						className={'secondary'}
						onClick={() => this.props.setActiveBtnCallback(containerType.data)}
					>
						{locale['common.activate']}
					</Button>
				</Col>
			</>
		)
	}

	render = () => {
		const {
			handleSubmit,
			wasteTypes,
			backBtnCallback,
			containerType
		} = this.props
		const wasteTypesOptions = map(get(wasteTypes, 'data.types'), (type) => ({
			value: type.id,
			label: type.name
		}))

		const titleInputField = {
			label: i18next.t('loc:Názov'),
			required: true,
			showLabel: true
		}

		const wasteTypeSelectField = {
			label: i18next.t('loc:Druh odpadu'),
			required: true,
			showLabel: true,
			isLoading: wasteTypes.isLoading,
			options: wasteTypesOptions
		}

		const capacityInputField = {
			label: i18next.t('loc:Objem nádoby (l)'),
			required: true,
			showLabel: true,
			min: 1,
			max: 50000,
			step: 0
			// NOTE: precision is not supported
			// precision: 0
		}

		const containerTypeSelectField = {
			label: i18next.t('loc:Typ nádoby'),
			required: true,
			showLabel: true,
			options: containerTypes
		}

		return (
			<form onSubmit={handleSubmit} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name="name"
										placeholder={locale['field.placeholder.title']}
										component={TextInputField}
										props={titleInputField}
										validate={validateRequiredString}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name="wasteType"
										type={'text'}
										placeholder={locale['field.placeholder.wasteType']}
										component={SelectField}
										props={wasteTypeSelectField}
										validate={validateRequired}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name="capacity"
										component={NumericInputField}
										placeholder={locale['field.placeholder.capacity']}
										props={capacityInputField}
										normalize={parseNumber}
										validate={validateNumber}
									/>
								</div>
								<div className="col-md-6">
									<Field
										name="type"
										type={'text'}
										placeholder={locale['field.placeholder.type']}
										component={SelectField}
										props={containerTypeSelectField}
										validate={validateRequired}
									/>
								</div>
							</div>
							<InfoFooter
								createdAt={get(containerType, 'data.createdAt')}
								creator={get(containerType, 'data.creator')}
								updatedAt={get(containerType, 'data.updatedAt')}
								editor={get(containerType, 'data.editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						{this.buildActionButtons(containerType)}
						<Col>
							<Button
								onClick={backBtnCallback}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	municipalities: getAuthUser(state).data?.municipalities,
	user: getAuthUser(state),
	wasteTypes: state.selectedMunicipality.wasteTypes
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch)
})

const form = reduxForm({
	form: FORMS.UPDATE_CONTAINER_TYPE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(ContainerTypesForm)

export default connect(mapStateToProps, mapDispatchToProps)(form)
