import React from 'react'
import { Link } from 'react-router-dom'

import locale from '../resources/locale'

import NotFoundImage from '../resources/images/img-not-found.png'

const NotFoundPage = () => (
	<div className={'not-found-wrapper'}>
		<div>
			<div className={'not-found-image'} style={{ backgroundImage: `url(${NotFoundImage})` }}/>
			<div className={'not-found'}>
				<div className={'header'}>
					{locale['page.not.found.header']}
				</div>
				<div className={'content'}>
					<Link to={locale['path.index']}>
						{locale['page.not.found.message']}
					</Link>
				</div>
			</div>
		</div>
	</div>
)

export default NotFoundPage
