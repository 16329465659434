import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Profile, TokenPayload } from '../types/data'
import { StateType } from '../types/state'
import { State } from './index'

export type AuthenticationState = {
	token: string | null
	data: TokenPayload | null
	authUser: StateType<Profile | null>
}

const initialState: AuthenticationState = {
	token: null,
	data: null,
	authUser: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		logoutUser: (state: AuthenticationState) => {
			state.token = null
			state.data = null
			state.authUser = initialState.authUser
		},
		saveUser: (state: AuthenticationState, action: PayloadAction<{ accessToken: string, tokenData: TokenPayload, profile: Profile }>) => {
			state.authUser.data = action.payload.profile
			state.token = action.payload.accessToken
			state.data = action.payload.tokenData
		}
	}
})

export const getToken = (state: State) => state.authentication.token
export const getTokenData = (state: State) => state.authentication.data
export const getAuthUser = (state: State) => state.authentication.authUser

export const authenticationActions = {
	...authenticationSlice.actions
}
