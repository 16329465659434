import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get, debounce } from 'lodash'
import { Col, Row } from 'antd'
import i18next from 'i18next'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'
import { ENDPOINTS, MUNICIPALITY_TYPE } from '../../utils/enums'

// components
import CalendarTableRow from './CalendarTableRow'

// atoms
import TableHead from '../../atoms/TableHead'
import { EmptyTableState } from '../EmptyTableState'
import SearchInputField from '../../atoms/SearchInputField'
import Pagination from '../../atoms/Pagination'
import ExportButton from '../buttons/ExportButton'

class CalendarTable extends React.Component {
	static propTypes = {
		events: PropTypes.arrayOf(PropTypes.object),
		context: PropTypes.object,
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		onPage: PropTypes.func.isRequired,
		onSearch: PropTypes.func.isRequired,
		search: PropTypes.string,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string,
		exportContext: PropTypes.object,
		municipality: PropTypes.object
	}

	render() {
		const {
			events, context, exportContext, loading, onPage, onSearch, onDelete,
			onDetail, onSort, sortingColumn, sortingDirection, search, municipality
		} = this.props

		const rows = map(events, (event, index) =>
			<CalendarTableRow
				key={get(event, 'id')}
				event={event}
				onDetail={onDetail}
				onDelete={onDelete}
				countIndex={index}
				municipality={municipality}
			/>)

		return (
			<div className={'box'}>
				<div className={'box-title'}>
					{locale['page.calendar.events']}
				</div>
				<div className={'box-content'}>
					<div className={'box-head'}>
						<Row gutter={16}>
							<Col>
								<SearchInputField
									name={'search'}
									onChange={debounce(onSearch, 300)}
									defaultValue={search || ''}
								/>
							</Col>
							<Col>
								<ExportButton url={ENDPOINTS.CALENDAR_EXPORT} context={exportContext}/>
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<div className={'table-wrapper'}>
							<table>
								<thead>
									<tr>
										<TableHead
											name={'date'}
											isSorting={sortingColumn === 'date'}
											title={i18next.t('loc:Dátum udalosti')}
											initDirection={sortingDirection}
											onSort={onSort}
										/>
										<TableHead
											name={'type'}
											isSorting={sortingColumn === 'type'}
											title={i18next.t('loc:Typ udalosti')}
											onSort={onSort}
											initDirection={sortingDirection}
										/>
										{municipality && municipality.type === MUNICIPALITY_TYPE.WASTE_COMPANY &&
										<th>{i18next.t('loc:Obce')}</th>
										}
										<th>{i18next.t('loc:Druh odpadu')}</th>
										<TableHead
											name={'isDoorToDoor'}
											isSorting={sortingColumn === 'isDoorToDoor'}
											title={i18next.t('loc:Od dverí k dverám')}
											onSort={onSort}
											initDirection={sortingDirection}
											className={'text-center'}
										/>
										<TableHead
											name={'isPublic'}
											isSorting={sortingColumn === 'isPublic'}
											title={i18next.t('loc:Publikovať pre občanov')}
											onSort={onSort}
											initDirection={sortingDirection}
											className={'text-center'}
										/>
										<TableHead
											name={'isVisibleForStatistics'}
											isSorting={sortingColumn === 'isVisibleForStatistics'}
											title={i18next.t('loc:Viditeľný pre štatistiky')}
											onSort={onSort}
											initDirection={sortingDirection}
											className={'text-center'}
										/>
										<th/>
									</tr>
								</thead>
								<tbody>
									{rows}
								</tbody>
							</table>
							{!loading && events && !events.length &&
							<EmptyTableState title={locale['page.calendar.none']}/>}
							<div className={cx('table-loading', { hidden: !loading })}>
								<div>
									<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
									<span>{locale['page.users.loading']}</span>
								</div>
							</div>
						</div>
						<Pagination
							pages={get(context, 'pages')}
							page={get(context, 'page')}
							onPage={onPage}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default CalendarTable
