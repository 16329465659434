import styled from 'styled-components'

import { Modal as AntModal } from 'antd'

import { HeadingTwo } from './Typography'

export const Modal = styled(AntModal)`
	.ant-modal-close {
		color: white;
		top: 5px;
	}
	
	.ant-modal-body {
		padding: 0;
	}
`

export const ModalHead = styled(HeadingTwo)`
	color: white;
	padding: 24px;
	background: ${({ theme }) => theme.colors.primary['500']};
`

export const ModalFooter = styled.div`
	border-top: 1px solid ${({ theme }) => theme.colors.primary['500']};
	padding: 24px;
`

export const ModalContent = styled.div`
	padding: 24px;
`
