import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { debounce, map, some } from 'lodash'

import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Col, Row, Table, TablePaginationConfig, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { MODAL } from '../../../utils/enums'
import { history } from '../../../utils/history'

import { BulkCollectionListItem, defaultModalState, OrderDirections } from '../../../types/data'

import { useDataLoader } from '../../../hooks/useDataLoader'
import { bulkCollections, wasteTypes } from '../../../api'

import { filtersActions, FiltersState, getBulkCollectionsFilters } from '../../../redux/filters'

import { formatDatetime } from '../../../utils/utils'
import { formatCustomer, getOrderFromSorter } from '../../../utils/helpers'

import ExportButton from '../../../components/buttons/ExportButton'
import Button from '../../../components/buttons/Button'
import SearchFilter from '../../../components/TableFilters/SearchFilter'
import WasteTypeLabel from '../../../components/WasteTypeLabel'
import Dot from '../../../components/Dot'
import BulkCollectionModal from './BulkCollectionModal'
import TableActions from '../../../components/TableFilters/TableActions'

import { Input, InputWrapper } from '../../../components/form/Styled'
import DeleteModal from '../../../components/DeleteModal'
import SelectFilter from '../../../components/TableFilters/SelectFilter'
import ScanDatetimeFilter, { IScanDatetimeFilter } from './ScanDatetimeFilter'
import WeightFilter, { IWeightFilter } from './WeightFilter'

type Props = {
	municipalityID?: number | string
}

const Collections = ({ municipalityID }: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const collectionsFilters = useSelector(getBulkCollectionsFilters)

	const refreshRef = useRef<boolean>(false)

	const [visibleFilter, setVisibleFilter] = useState<string | null>('')
	const [modal, setModal] = useState(defaultModalState)

	const [collectionsState, loadCollections] = useDataLoader(bulkCollections.loadBulkCollections)
	const [wasteTypesState, loadWasteTypes] = useDataLoader(wasteTypes.loadMunicipalityWasteTypes)

	const handleCloseModal = useCallback((refresh: boolean) => {
		if (refresh) {
			refreshRef.current = true
		}

		setModal(defaultModalState)
	}, [])

	const onRow = useCallback((model: BulkCollectionListItem) => ({
		onClick: () => history.push(municipalityID
			? t('paths:REGISTRY.collectionYard.detail.municipalityPath', { municipalityID, bulkCollectionID: model.id })
			: t('paths:REGISTRY.collectionYard.detail.path', { bulkCollectionID: model.id }))
	}), [municipalityID, t])

	const deleteParams = useMemo(() => {
		if (modal.key === MODAL.DELETE_BULK_COLLECTION) {
			return [modal.data]
		}

		return []
	}, [modal.data, modal.key])

	const wasteTypeOptions = useMemo(() => map(wasteTypesState.data?.wasteTypes, (wasteType) => ({
		value: `${wasteType.id}`, text: wasteType.name
	})), [wasteTypesState.data])

	const loadCollectionsWrap = useCallback((collectionsFilters: any) => {
		const scanDatetimeFrom = collectionsFilters?.scanDatetime?.[0]
		const scanDatetimeTo = collectionsFilters?.scanDatetime?.[1]

		const weightFrom = collectionsFilters?.weight?.[0]
		const weightTo = collectionsFilters?.weight?.[1]

		loadCollections({
			...collectionsFilters,
			scanDatetime: undefined,
			scanDatetimeFrom,
			scanDatetimeTo,
			weight: undefined,
			weightFrom,
			weightTo,
			municipalityID
		})
	}, [loadCollections, municipalityID])

	useEffect(() => {
		if (refreshRef.current) {
			refreshRef.current = false

			loadCollectionsWrap(collectionsFilters)
		}
	}, [loadCollectionsWrap, collectionsFilters])

	const handleFilter = useCallback((name: keyof FiltersState['bulkCollections'], value: any) => {
		dispatch(filtersActions.savePartialFilters({
			key: 'bulkCollections',
			filters: {
				[name]: value,
				current: 1
			}
		}))

		setVisibleFilter(null)
	}, [dispatch])

	const handleSearch = useMemo(() => debounce((search: string) => {
		handleFilter('search', search)
	}, 300), [handleFilter])

	const handleDelete = useCallback((item: BulkCollectionListItem) => {
		setModal({ key: MODAL.DELETE_BULK_COLLECTION, data: item.id })
	}, [])

	const handleChangeTable = useCallback((pagination: TablePaginationConfig, tableFilters: Record<string, FilterValue | null>, sorter: SorterResult<BulkCollectionListItem> | SorterResult<BulkCollectionListItem>[]) => {
		const [order, direction] = getOrderFromSorter(sorter)

		const filters = {
			direction,
			order,
			current: pagination.current,
			pageSize: pagination.pageSize
		}

		dispatch(filtersActions.savePartialFilters({
			key: 'bulkCollections',
			filters
		}))
	}, [dispatch])

	const pagination = useMemo(() => ({
		current: collectionsFilters.current,
		total: collectionsState.data?.context.totalCount,
		pageSize: collectionsFilters.pageSize,
		pageSizeOptions: ['20', '50', '100']
	}), [collectionsFilters, collectionsState.data?.context.totalCount])

	const locale = useMemo(() => ({
		emptyText: collectionsState.isLoading ? '' : t('common.noData')
	}), [collectionsState.isLoading, t])

	const weightFrom = collectionsFilters?.weight?.[0]
	const weightTo = collectionsFilters?.weight?.[1]

	const columns: any = useMemo(() => [{
		key: 'customer',
		width: '22%',
		ellipsis: true,
		title: t('table.columns.customer'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: collectionsFilters.order === 'customer' ? collectionsFilters.direction : null,
		filterDropdownVisible: visibleFilter === 'customerSearch',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'customerSearch' : null),
		filteredValue: collectionsFilters.customerSearch ? [collectionsFilters.customerSearch] : [],
		filterDropdown: (
			<SearchFilter
				filtersName={'bulkCollections'}
				name={'customerSearch'}
				onFilter={handleFilter}
			/>
		),
		render: (item: BulkCollectionListItem) => formatCustomer(item.customer)
	}, {
		key: 'wasteTypes',
		width: '14%',
		title: t('table.columns.wasteTypes'),
		filterDropdownVisible: visibleFilter === 'wasteTypes',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'wasteTypes' : null),
		filteredValue: collectionsFilters.wasteTypes?.length ? collectionsFilters.wasteTypes : [],
		filterDropdown: (
			<SelectFilter
				filtersName={'bulkCollections'}
				name={'wasteTypes'}
				visible={visibleFilter === 'wasteTypes'}
				options={wasteTypeOptions}
				onFilter={handleFilter}
			/>
		),
		render: (item: BulkCollectionListItem) => {
			const { collections } = item

			if (!collections.length) {
				return '-'
			}

			if (collections.length === 1) {
				return (
					<WasteTypeLabel>
						<Dot color={`#${collections[0].container?.containerType?.wasteType?.color}`}/>
						<span>{collections[0].container?.containerType?.wasteType?.name}</span>
					</WasteTypeLabel>
				)
			}

			return (
				<Tooltip
					title={(
						<Row gutter={[16, 16]}>
							{map(item.collections, (collection) => (
								<Col key={collection.id} span={24}>
									<WasteTypeLabel>
										<Dot color={`#${collection.container?.containerType?.wasteType?.color}`}/>
										<span>{collection.container?.containerType?.wasteType?.name}</span>
									</WasteTypeLabel>
								</Col>
							))}
						</Row>
					)}
				>
					<span>{t('BulkCollectionsPage.multipleWasteTypes')}</span>
				</Tooltip>
			)
		}
	}, {
		key: 'weight',
		dataIndex: 'weight',
		width: '12%',
		title: t('table.columns.weight'),
		render: (weight: string) => `${Number(weight).toFixed(2)} kg`,
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: collectionsFilters.order === 'weight' ? collectionsFilters.direction : null,
		filterDropdownVisible: visibleFilter === 'weight',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'weight' : null),
		filteredValue: some(collectionsFilters?.weight, (item) => !!item) ? 'active' : null,
		filterDropdown: (
			<WeightFilter
				onSubmit={({ weightFrom, weightTo }: IWeightFilter) => {
					handleFilter('weight', [weightFrom, weightTo])
				}}
			/>
		)
	}, {
		key: 'scanDatetime',
		dataIndex: 'scanDatetime',
		width: 235,
		title: t('table.columns.scanDatetime'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: collectionsFilters.order === 'scanDatetime' ? collectionsFilters.direction : null,
		filterDropdownVisible: visibleFilter === 'scanDatetime',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'scanDatetime' : null),
		filteredValue: some(collectionsFilters?.scanDatetime, (item) => !!item) ? 'active' : null,
		filterDropdown: (
			<ScanDatetimeFilter
				onSubmit={({ scanDatetimeFrom, scanDatetimeTo }: IScanDatetimeFilter) => {
					handleFilter('scanDatetime', [scanDatetimeFrom, scanDatetimeTo])
				}}
			/>
		),
		render: (scanDatetime: string) => formatDatetime(scanDatetime)
	}, {
		key: 'name',
		title: t('table.columns.title'),
		width: '14%',
		sorter: true,
		showSorterTooltip: false,
		ellipsis: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: collectionsFilters.order === 'name' ? collectionsFilters.direction : null,
		filterDropdownVisible: visibleFilter === 'nameSearch',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'nameSearch' : null),
		filteredValue: collectionsFilters.nameSearch ? [collectionsFilters.nameSearch] : [],
		filterDropdown: (
			<SearchFilter
				filtersName={'bulkCollections'}
				name={'nameSearch'}
				onFilter={handleFilter}
			/>
		),
		render: (item: BulkCollectionListItem) => <strong>{item.collectionYard.name}</strong>
	}, {
		key: 'customerCodeSerial',
		width: 145,
		dataIndex: 'customerCodeSerial',
		title: t('table.columns.customerCodeSerial'),
		render: (code: string) => code || '-',
		filterDropdownVisible: visibleFilter === 'customerSerialSearch',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'customerSerialSearch' : null),
		filteredValue: collectionsFilters.customerSerialSearch ? [collectionsFilters.customerSerialSearch] : [],
		filterDropdown: (
			<SearchFilter
				filtersName={'bulkCollections'}
				name={'customerSerialSearch'}
				onFilter={handleFilter}
			/>
		)
	}, {
		key: 'space',
		width: 'auto'
	}, {
		key: 'actions',
		width: 50,
		render: (item: BulkCollectionListItem) => (
			<TableActions
				actions={[{ title: t('common.delete'), icon: <DeleteOutlined/>, callback: () => handleDelete(item) }]}
			/>
		)
	}], [
		collectionsFilters.direction, collectionsFilters.nameSearch, collectionsFilters.order, handleDelete, handleFilter, t, visibleFilter, wasteTypeOptions,
		collectionsFilters?.scanDatetime?.[0], collectionsFilters?.scanDatetime?.[1],
		collectionsFilters?.weight?.[0], collectionsFilters?.weight?.[1]
	])

	useEffect(() => {
		loadWasteTypes({ municipalityID, pageSize: 1000000 })
	}, [loadWasteTypes, municipalityID])

	useEffect(() => {
		loadCollectionsWrap(collectionsFilters)
	}, [loadCollectionsWrap, collectionsFilters])

	return (
		<Row gutter={[16, 16]} justify={'space-between'}>
			<Col>
				<InputWrapper className={'search without-margin'}>
					<Input
						defaultValue={collectionsFilters.search}
						onChange={(e) => handleSearch(e.target.value)}
						placeholder={t('BulkCollectionsPage.searchPlaceholder')}
					/>
				</InputWrapper>
			</Col>
			<Col>
				<Row gutter={16}>
					<Col>
						<ExportButton
							url={'/api/admin/bulk-collections/export'}
							className={'secondary'}
							title={t('common.export')}
							context={{ ...collectionsFilters,
								municipalityID,
								weightFrom,
								weightTo,
								weight: undefined
							}}
						/>
					</Col>
					{!!municipalityID &&
					<Col>
						<Button onClick={() => setModal({ key: MODAL.CREATE_BULK_COLLECTION, data: null })}>
							{t('BulkCollectionsPage.addCollection')}
						</Button>
					</Col>}
				</Row>
			</Col>
			<Col span={24}>
				<Table
					columns={columns}
					rowKey={'id'}
					locale={locale}
					onRow={onRow}
					onChange={handleChangeTable}
					loading={collectionsState.isLoading}
					dataSource={collectionsState.data?.bulkCollections}
					pagination={pagination}
					getPopupContainer={(trigger:any) => trigger.closest('thead')}
					scroll={{ x: 1220 }}
				/>
				{!!municipalityID &&
				<BulkCollectionModal
					visible={modal.key === MODAL.CREATE_BULK_COLLECTION}
					onClose={handleCloseModal}
					municipalityID={municipalityID}
				/>}
				<DeleteModal
					modalKey={modal.key}
					params={deleteParams}
					onClose={handleCloseModal}
				/>
			</Col>
		</Row>
	)
}

export default Collections
