import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { isNil } from 'lodash'

import { FiltersState, getFilters } from '../../redux/filters'

import Button from '../buttons/Button'

import { Input } from '../form/Styled'

const Wrapper = styled.div`
	padding: 15px;
`

type Props<Key extends keyof FiltersState> = {
	filtersName: Key
	name: keyof FiltersState[Key]
	onFilter: (name: keyof FiltersState[Key], value: string) => void
}

function SearchFilter<Key extends keyof FiltersState>({ name, filtersName, onFilter }: Props<Key>) {
	const [t] = useTranslation()

	const filters = useSelector(getFilters(filtersName))
	const [search, setSearch] = useState('')

	// @ts-ignore
	const filterValue = filters[name]

	useEffect(() => {
		setSearch(isNil(filterValue) ? '' : `${filterValue}`)
	}, [filterValue])

	return (
		<Wrapper>
			<Row justify={'end'} gutter={[16, 16]} style={{ width: 350 }}>
				<Col span={24}>
					<Input
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder={t('SearchFilter.placeholder')}
					/>
				</Col>
				<Col>
					<Button
						className={'small secondary'}
						onClick={() => onFilter(name, '')}
					>
						{t('SearchFilter.clear')}
					</Button>
				</Col>
				<Col>
					<Button
						className={'small'}
						onClick={() => onFilter(name, search)}
					>
						{t('SearchFilter.search')}
					</Button>
				</Col>
			</Row>
		</Wrapper>
	)
}

export default SearchFilter
