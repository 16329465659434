import React from 'react'
import moment from 'moment'
import * as PropTypes from 'prop-types'

import { get } from 'lodash'
import { faTrashAlt, faPowerOff, faRecycle, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import PopupTool from '../../atoms/PopupTool'
import { CheckBoxRaw } from '../../atoms/CheckBoxField'
import locale from '../../resources/locale'

import { CONTAINER_TYPES } from '../../utils/enums'

class ContainerTableRow extends React.Component {
	static propTypes = {
		isSelectable: PropTypes.bool,
		isSelected: PropTypes.bool,
		onSelected: PropTypes.func.isRequired,
		container: PropTypes.object.isRequired,
		showPlace: PropTypes.bool,
		onDelete: PropTypes.func,
		onEdit: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		onChangeContainerType: PropTypes.func.isRequired,
		onTransfer: PropTypes.func.isRequired,
		countIndex: PropTypes.number.isRequired
	}

	getActivateTitle = (container) => {
		if (container.assignedTo) {
			if (moment(container.assignedTo) > moment()) {
				return locale['page.customers.remove.active.to']
			}
			return locale['common.activate']
		}
		return locale['common.deactivate']
	}

	getPopupToolItems = () => {
		const { container, onDelete, onChangeContainerType, onEdit, onTransfer } = this.props

		const items = [{
			key: 'common.activate',
			title: this.getActivateTitle(container),
			icon: faPowerOff,
			callback: () => onEdit(container)
		}]

		if (onChangeContainerType) {
			items.push({
				key: 'page.collections.change.container.accept',
				title: locale['page.collections.change.container.accept'],
				icon: faRecycle,
				callback: () => onChangeContainerType(container)
			})
		}

		if (container.type !== CONTAINER_TYPES.COLLECTION_YARD && !container.assignedTo) {
			items.push({
				key: 'page.collectionPlaces.detail.transfer',
				title: locale['page.collectionPlaces.detail.transfer'],
				icon: faExchangeAlt,
				callback: () => onTransfer(container)
			})
		}

		if (onDelete) {
			return [{
				key: 'common.delete',
				title: locale['common.delete'],
				icon: faTrashAlt,
				callback: () => onDelete(container)
			}, ...items]
		}

		return items
	}

	render() {
		const { container, showPlace, countIndex, onDetail, isSelectable, isSelected, onSelected } = this.props
		const format = locale['common.date.format.date']

		return (
			<tr onClick={() => onDetail(get(container, 'id'))}>
				{isSelectable && <td style={{ width: '50px' }} onClick={(e) => e.stopPropagation()}>
					<CheckBoxRaw
						name={`checkbox-${container?.id}`}
						checked={isSelected}
						onChange={(value) => onSelected(container?.id, value)}
					/>
				</td>}
				<td className={'long-text'} style={{ maxWidth: '200px' }}>{get(container, 'name')}</td>
				<td className={'text-center'}>{get(container, 'capacity')}</td>
				<td>{locale[`container.types.${container.type}`]}</td>
				<td>{container.assignedFrom ? moment(container.assignedFrom).format(format) : '-'}</td>
				<td>{container.assignedTo ? moment(container.assignedTo).format(format) : '-'}</td>
				<td>{get(container, 'code')}</td>
				<td>{get(container, 'serialNumber', '-') || '-'}</td>
				<td>{get(container, 'rfid', '-') || '-'}</td>
				<td>{get(container, 'rfidSerial', '-') || '-'}</td>
				{showPlace && <td>{get(container, 'collectionPlaceName', '-')}</td>}
				{showPlace && <td>{get(container, 'customerName', '-') || '-'}</td>}
				<td className={'action'} style={{ zIndex: countIndex }}>
					<PopupTool
						items={this.getPopupToolItems()}
					/>
				</td>
			</tr>
		)
	}
}

export default ContainerTableRow
