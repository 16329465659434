import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { EnvironmentOutlined, FileTextOutlined } from '@ant-design/icons'

import { statistics } from '../../../api'

import { getSelectedMunicipality } from '../../../redux/municipalities/selectors'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SpinLoading from '../../../components/SpinLoading'

import { SubHeading, Text } from '../../../components/Typography'

const CollectionsSummary = () => {
	const [t] = useTranslation()

	const municipality = useSelector(getSelectedMunicipality)

	const [summaryState, loadSummary] = useDataLoader(statistics.loadCounts)

	useEffect(() => {
		loadSummary({ municipalityID: municipality?.id })
	}, [loadSummary, municipality])

	if (summaryState.isLoading) {
		return (
			<SpinLoading height={'138px'}/>
		)
	}

	return (
		<Row gutter={[0, 32]}>
			<Col span={24}>
				<Row align={'middle'} gutter={16} wrap={false}>
					<Col>
						<FileTextOutlined style={{ fontSize: 18 }}/>
					</Col>
					<Col>
						<SubHeading>{summaryState.data?.collectionsCount || '-'}</SubHeading>
						<Text className={'without-margin grey'}>{t('DashboardPage.collectionsCount')}</Text>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row align={'middle'} gutter={16} wrap={false}>
					<Col>
						<EnvironmentOutlined style={{ fontSize: 18 }}/>
					</Col>
					<Col>
						<SubHeading>{summaryState.data?.collectionPlacesCount || '-'}</SubHeading>
						<Text className={'without-margin grey'}>{t('DashboardPage.collectionPlacesCount')}</Text>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default React.memo(CollectionsSummary)
