import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import i18next from 'i18next'
import locale from '../../resources/locale'

class UserInfo extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		resendHandler: PropTypes.func.isRequired
	}

	getUserState = () => {
		const { user, resendHandler } = this.props

		if (!user.isConfirmed) {
			const resend = () => {
				resendHandler(user)
			}
			return (
				<div className={'info-wrapper'} style={{ marginLeft: '-40px' }}>
					<label>
						{locale['page.users.detail.confirmed.not']}&nbsp;
						<span onClick={resend}>{locale['page.users.detail.invitation.resend']}</span>
					</label>
				</div>
			)
		}
		return null
	}

	render() {
		const { user } = this.props

		return (
			<div className={'box'}>
				<div className={'box-title'}>
					{locale['common.basic.info']}
				</div>
				<div className={'box-content with-padding'}>
					<div className={'box-head'}>
						{this.getUserState()}
					</div>
					<div className={'box-body'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Meno')}</label>
									<div>{user.name || '-'}</div>
								</div>
							</div>
							<div className={'col-md-6'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Priezvisko')}</label>
									<div>{user.surname || '-'}</div>
								</div>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Email')}</label>
									<div>{user.email}</div>
								</div>
							</div>
						</div>
						<div className={'row'}>
							<div className={'col-md-3'}>
								<div className={'info-wrapper'}>
									<label>{locale['page.users.detail.created']}</label>
									<div>{moment(user.createdAt).format(locale['common.date.format.date'])}</div>
								</div>
							</div>
							<div className={'col-md-3'}>
								<div className={'info-wrapper'}>
									<label>{locale['page.users.detail.login']}</label>
									<div>{user.lastLoginAt ? moment(user.lastLoginAt).format(locale['common.date.format']) : '-'}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default UserInfo
