import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { faTrashAlt, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'

class CollectionPlaceCustomerTableRow extends React.Component {
	static propTypes = {
		customer: PropTypes.object.isRequired,
		onDetail: PropTypes.func,
		onEdit: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		countIndex: PropTypes.number.isRequired
	}

	getActivateTitle = (customer) => {
		if (customer.activeTo) {
			if (moment(customer.activeTo) > moment()) {
				return locale['page.customers.remove.active.to']
			}
			return locale['common.activate']
		}
		return locale['common.deactivate']
	}

	onDetail = () => {
		const { customer, onDetail } = this.props

		if (onDetail) {
			onDetail(customer)
		}
	}

	render() {
		const { customer, onEdit, onDelete, countIndex } = this.props
		const format = locale['common.date.format.date']

		return (
			<tr onClick={this.onDetail}>
				<td>{customer.isAnonymous ? locale.formatString(locale['page.customers.anonymous'], customer.customerID) : get(customer, 'name')}</td>
				<td className={'text-center'}>{get(customer, 'membersCount') || '-'}</td>
				<td>{customer.activeFrom ? moment(customer.activeFrom).format(format) : '-'}</td>
				<td>{customer.activeTo ? moment(customer.activeTo).format(format) : '-'}</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					<PopupTool
						items={[{
							key: 'common.activate',
							title: this.getActivateTitle(customer),
							icon: faPowerOff,
							callback: () => onEdit(customer)
						}, {
							key: 'common.delete',
							title: locale['common.delete'],
							icon: faTrashAlt,
							callback: () => onDelete(customer)
						}]}
					/>
				</td>
			</tr>
		)
	}
}

export default CollectionPlaceCustomerTableRow
