import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { createForm } from 'final-form'
import { Field, Form } from 'react-final-form'
import { Col, Row } from 'antd'
import { map, reduce, replace } from 'lodash'
import { useDispatch } from 'react-redux'

import { composeValidators, validateGte, validateRequired } from '../../../utils/form'
import { createYearsList, getMessages } from '../../../utils/helpers'
import { SETTINGS_KEY } from '../../../utils/enums'

import { useDataLoader } from '../../../hooks/useDataLoader'
import { settings } from '../../../api'

import Button from '../../../components/buttons/Button'
import TextField from '../../../components/form/TextField'
import SpinLoading from '../../../components/SpinLoading'

import { BoxContent } from '../../../layouts/Box'
import { HeadingFive, SubHeading } from '../../../components/Typography'
import { statusPush } from '../../../actions/statusActions'

type FormValues = {
	sortedWaste: {
		[year: string]: number
	}
	unsortedWaste: {
		[year: string]: number
	}
}

const years = createYearsList()

const AverageWasteForm = () => {
	const [t] = useTranslation()

	const dispatch = useDispatch()
	const [settingsState, loadSettings] = useDataLoader(settings.loadSettings)

	const handleSubmit = useCallback(async (values: FormValues) => {
		try {
			const response = await settings.updateSettings({
				[SETTINGS_KEY.AVERAGE_WASTES]: {
					sortedWaste: reduce(values.sortedWaste, (result, value, key) => ({
						...result,
						[replace(key, '_', '')]: value
					}), {}),
					unsortedWaste: reduce(values.unsortedWaste, (result, value, key) => ({
						...result,
						[replace(key, '_', '')]: value
					}), {})
				}
			})

			const { messages } = getMessages(response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve()
		} catch (e: any) {
			const { messages } = getMessages(e.response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve({})
		}
	}, [dispatch])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit
	}), [handleSubmit])

	useEffect(() => {
		loadSettings()
	}, [loadSettings])

	useEffect(() => {
		if (settingsState.data) {
			const { sortedWaste, unsortedWaste } = settingsState.data[SETTINGS_KEY.AVERAGE_WASTES]

			form.restart({
				sortedWaste: reduce(sortedWaste, (result, value, key) => ({
					...result,
					[`_${key}`]: value
				}), {} as { [year: string]: number }),
				unsortedWaste: reduce(unsortedWaste, (result, value, key) => ({
					...result,
					[`_${key}`]: value
				}), {} as { [year: string]: number })
			})
		}
	}, [form, settingsState.data])

	let content: React.ReactNode

	if (settingsState.isLoading) {
		content = <SpinLoading height={`${(years.length * 86) + 24}px`}/>
	} else if (settingsState.data) {
		content = (
			<Row gutter={[16, 16]}>
				<Col span={24} md={12}>
					<HeadingFive>{t('ToolsPage.sortedWastes')}</HeadingFive>
					<Field
						name={'sortedWastes'}
						render={() => map(years, (year) => (
							<Field
								key={`sortedWaste.${year}`}
								name={`sortedWaste._${year}`}
								validate={composeValidators(validateRequired, validateGte(0))}
								validateFields={[]}
								type={'number'}
								render={(props) => (
									<TextField
										{...props}
										label={t('fields.year.labelWithParam', { year })}
										placeholder={t('fields.year.placeholder', { year })}
									/>
								)}
							/>
						))}
					/>
				</Col>
				<Col span={24} md={12}>
					<HeadingFive>{t('ToolsPage.unsortedWastes')}</HeadingFive>
					<Field
						name={'unsortedWastes'}
						render={() => map(years, (year) => (
							<Field
								key={`unsortedWaste.${year}`}
								name={`unsortedWaste._${year}`}
								validate={composeValidators(validateRequired)}
								validateFields={[]}
								type={'number'}
								render={(props) => (
									<TextField
										{...props}
										label={t('fields.year.labelWithParam', { year })}
										placeholder={t('fields.year.placeholder', { year })}
									/>
								)}
							/>
						))}
					/>
				</Col>
			</Row>
		)
	}

	return (
		<Form
			onSubmit={handleSubmit}
			subscription={{ submitting: true }}
			form={form}
			render={({ handleSubmit, submitting }) => (
				<BoxContent className={'top-margin padded'}>
					<SubHeading>{t('ToolsPage.averageWasteTitle')}</SubHeading>
					<form onSubmit={handleSubmit}>
						{content}
					</form>
					<Row justify={'end'}>
						<Col>
							<Button
								loading={submitting}
								disabled={submitting}
								onClick={handleSubmit}
							>
								{t('common.save')}
							</Button>
						</Col>
					</Row>
				</BoxContent>
			)}
		/>
	)
}

export default AverageWasteForm
