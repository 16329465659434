import React from 'react'
import { useTranslation } from 'react-i18next'

import { Profile } from '../../../types/data'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import Containers from '../../../resources/images/ic-container.svg'
import Town from '../../../resources/images/ic-town.svg'
import Scanner from '../../../resources/images/ic-scanner.svg'
import Users from '../../../resources/images/ic-users.svg'
import QRCode from '../../../resources/images/ic-qr-code.svg'
import MenuLink from './MenuLink'

type Props = {
	authUser?: Profile | null
}

const Administration = ({ authUser, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin) {
		return null
	}

	return (
		<StyledItemGroup
			{...props}
			key={'ADMINISTRATION'}
			title={t('paths:ADMINISTRATION.title')}
		>
			<StyledItem key={'ADMINISTRATION.codePortions'}>
				<Icon icon={QRCode}/>
				<MenuLink basePath={'paths:ADMINISTRATION.codePortions'} municipality={null}>
					{t('paths:ADMINISTRATION.codePortions.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'ADMINISTRATION.tools'}>
				<Icon icon={Containers}/>
				<MenuLink basePath={'paths:ADMINISTRATION.tools'} municipality={null}>
					{t('paths:ADMINISTRATION.tools.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'ADMINISTRATION.municipalities'}>
				<Icon icon={Town}/>
				<MenuLink basePath={'paths:ADMINISTRATION.municipalities'} municipality={null}>
					{t('paths:ADMINISTRATION.municipalities.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'ADMINISTRATION.users'}>
				<Icon icon={Users}/>
				<MenuLink basePath={'paths:ADMINISTRATION.users'} municipality={null}>
					{t('paths:ADMINISTRATION.users.title')}
				</MenuLink>
			</StyledItem>
			<StyledItem key={'ADMINISTRATION.devices'}>
				<Icon icon={Scanner}/>
				<MenuLink basePath={'paths:ADMINISTRATION.devices'} municipality={null}>
					{t('paths:ADMINISTRATION.devices.title')}
				</MenuLink>
			</StyledItem>
		</StyledItemGroup>
	)
}

export default Administration
