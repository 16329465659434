import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import i18next from 'i18next'

// atoms
import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'

// utils
import { validate, validateSelect, validatorOptions } from '../../utils/validator'
import { FORMS, DEVICE_TYPES, PERMISSIONS } from '../../utils/enums'

// recources
import locale from '../../resources/locale'

// components
import InfoFooter from '../InfoFooter'
import Permission from '../Permission'
import DateTimePickerField from '../../atoms/DateTimePickerField'

// actions
import { statusPush } from '../../actions/statusActions'
import { formatBatteryLevel, formatDatetime } from '../../utils/utils'
import { getAuthUser } from '../../redux/authentication'
import Button from '../buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateSelectRequired = validateSelect([validatorOptions.REQUIRED])
const validateDate = validate([validatorOptions.REQUIRED])

class DeviceForm extends React.Component {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
		device: PropTypes.object,
		initialValues: PropTypes.object,
		backBtnCallback: PropTypes.func.isRequired,
		deleteBtnCallback: PropTypes.func.isRequired,
		municipality: PropTypes.string
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov'),
		required: true
	}

	validateDateFrom = (value, allValues) => {
		const error = validateDate(value)
		if (!error && allValues.assignedTo) {
			const fromDate = moment(value)
			const toDate = moment(allValues.assignedTo)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.FROM_DATE']
			}
		}
		return error
	}

	normalizeDateFrom = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.startOf('day').toDate()
		}
		return value
	}

	validateDateTo = (value, allValues) => {
		if (value && allValues.assignedFrom) {
			const fromDate = moment(allValues.assignedFrom)
			const toDate = moment(value)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.TO_DATE']
			}
		}
		return null
	}

	normalizeDateTo = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.endOf('day').toDate()
		}
		return value
	}

	render = () => {
		const { handleSubmit, backBtnCallback, device, deleteBtnCallback, municipality } = this.props

		const titleInputField = {
			label: i18next.t('loc:Názov'),
			required: true,
			showLabel: true
		}

		const typeSelectField = {
			label: i18next.t('loc:Typ'),
			required: true,
			showLabel: true,
			options: [{
				label: locale['page.device.TERMINAL'],
				value: DEVICE_TYPES.TERMINAL
			}, {
				label: locale['page.device.DISCOVERY'],
				value: DEVICE_TYPES.DISCOVERY
			}, {
				label: locale['page.device.ARCOBEAT'],
				value: DEVICE_TYPES.ARCOBEAT
			}, {
				label: locale['page.device.ARCO40EVO'],
				value: DEVICE_TYPES.ARCO40EVO
			}, {
				label: locale['page.device.EXTERNAL_DEVICE'],
				value: DEVICE_TYPES.EXTERNAL_DEVICE
			}, {
				label: locale['page.device.GX_SOLUTIONS'],
				value: DEVICE_TYPES.GX_SOLUTIONS
			}, {
				label: locale['page.device.SEPAN_ELTE_GPS'],
				value: DEVICE_TYPES.SEPAN_ELTE_GPS
			}, {
				label: locale['page.device.RADIUM_DEVICE'],
				value: DEVICE_TYPES.RADIUM_DEVICE
			}]
		}

		const deviceNumberInputField = {
			label: i18next.t('loc:Kód zariadenia'),
			required: true,
			showLabel: true
		}

		const assignedFromFieldOptions = {
			label: i18next.t('loc:Priradené od'),
			required: true,
			showLabel: true,
			minDate: moment().startOf('day'),
			format: locale['common.date.format.date']
		}

		const assignedToFieldOptions = {
			label: i18next.t('loc:Priradené do'),
			required: true,
			showLabel: true,
			minDate: moment().startOf('day'),
			format: locale['common.date.format.date']
		}

		return (
			<form onSubmit={handleSubmit} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							{municipality && <div className={'row'}>
								<div className={'col-12'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Obec')}</label>
										<div>{municipality}</div>
									</div>
								</div>
							</div>}
							<div className={'row'}>
								<div className={'col-12'}>
									<Field
										name='name'
										placeholder={locale['field.placeholder.title']}
										component={TextInputField}
										props={titleInputField}
										validate={validateRequiredString}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='type'
										placeholder={locale['field.placeholder.type']}
										component={SelectField}
										props={typeSelectField}
										validate={validateSelectRequired}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='number'
										placeholder={locale['field.placeholder.deviceNumber']}
										component={TextInputField}
										props={deviceNumberInputField}
										validate={validateRequiredString}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='UUID'
										placeholder={'UUID'}
										label={'UUID'}
										component={TextInputField}
										showLabel={true}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='SIM'
										placeholder={'SIM'}
										label={'SIM'}
										component={TextInputField}
										showLabel={true}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='assignedFrom'
										placeholder={locale['field.placeholder.assigned.from']}
										component={DateTimePickerField}
										props={assignedFromFieldOptions}
										validate={this.validateDateFrom}
										normalize={this.normalizeDateFrom}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='assignedTo'
										placeholder={locale['field.placeholder.assigned.to']}
										component={DateTimePickerField}
										props={assignedToFieldOptions}
										validate={this.validateDateTo}
										normalize={this.normalizeDateTo}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Posledná komunikácia')}</label>
										<div>
											{formatDatetime(get(device, 'data.lastCommunication'))}
										</div>
									</div>
								</div>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Úroveň batérie')}</label>
										<div>
											{formatBatteryLevel(get(device, 'data.batteryLevel'))}
										</div>
									</div>
								</div>
							</div>
							<InfoFooter
								createdAt={get(device, 'data.createdAt')}
								creator={get(device, 'data.creator')}
								updatedAt={get(device, 'data.updatedAt')}
								editor={get(device, 'data.editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Permission allowed={[PERMISSIONS.ADMINISTRATOR]}>
							<Col order={1}>
								<Button
									onClick={deleteBtnCallback}
									className={'danger'}
								>
									{locale['common.delete']}
								</Button>
							</Col>
							<Col order={4}>
								<Button
									type="submit"
								>
									{locale['common.save']}
								</Button>
							</Col>
						</Permission>
						<Col order={2} flex={1}/>
						<Col order={3}>
							<Button
								className={'secondary'}
								onClick={backBtnCallback}
							>
								{locale['common.back']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	municipalities: getAuthUser(state).data?.municipalities,
	user: getAuthUser(state),
	wasteTypes: state.selectedMunicipality.wasteTypes
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch)
})

const form = reduxForm({
	form: FORMS.UPDATE_CONTAINER_TYPE_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true
})(DeviceForm)

export default connect(mapStateToProps, mapDispatchToProps)(form)
