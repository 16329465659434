import React from 'react'
import styled from 'styled-components'

type Props = {
	title: string
}

const Wrapper = styled.div`
	text-align: center;
	font-size: 16px;
	color: ${({ theme }) => theme.colors.lightText};
	padding: 20px;
`

export const EmptyTableState = ({ title }: Props) => (
	<Wrapper>
		<span>{title}</span>
	</Wrapper>
)
