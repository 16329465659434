import React, { PureComponent } from 'react'
import cx from 'classnames'
import { get } from 'lodash'
import * as PropTypes from 'prop-types'

import Popover from 'react-tiny-popover'

import { SORT_ASC, SORT_DESC } from '../../types/table'

import TextFilter from './TextFilter'
import NumberRangeFilter from './NumberRangeFilter'
import SelectFilter from './SelectFilter'
import { columnPropTypes } from './Table'
import DateRangeFilter from './DateRangeFilter'

const FILTER_TYPE = {
	FULL_TEXT: 'FULL_TEXT',
	SELECT: 'SELECT',
	DATE_RANGE: 'DATE_RANGE',
	NUMBER_RANGE: 'NUMBER_RANGE'
}

class TableHeader extends PureComponent {
	static propTypes = {
		id: PropTypes.string.isRequired,
		column: PropTypes.shape(columnPropTypes),
		filters: PropTypes.object,
		direction: PropTypes.oneOf([SORT_ASC, SORT_DESC]),
		onSort: PropTypes.func.isRequired,
		onFilter: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
		style: PropTypes.any
	}

	state = {
		filterVisible: false
	}

	node = null
	filter = React.createRef()

	constructor(props) {
		super(props)

		this.handleClickOutside = this.handleClickOutside.bind(this)
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	handleClickOutside(event) {
		if (!this.state.filterVisible) {
			return
		}
		const filterNode = get(this.filter, 'current.node.current')
		if ((this.node && !this.node.contains(event.target)) && (filterNode && !filterNode.contains(event.target))) {
			this.setState({ filterVisible: false })
		}
	}

	toggleSort = () => {
		const { direction, onSort, column } = this.props
		if (column.sortable) {
			let sort
			if (direction === SORT_ASC) {
				sort = SORT_DESC
			} else {
				sort = SORT_ASC
			}

			onSort({ sort, name: column.name })
		}
	}

	toggleFilter = (e) => {
		this.setState({ filterVisible: !this.state.filterVisible })
		e.stopPropagation()
	}

	handleFilter = (e) => {
		this.setState({ filterVisible: false })
		this.props.onFilter(e)
	}

	render() {
		const { column, direction, style, onReset, filters, id } = this.props
		const { filterVisible } = this.state

		let icon
		if (column.sortable) {
			if (direction === SORT_ASC) {
				icon = <div className={'sort-icon asc'}/>
			} else if (direction === SORT_DESC) {
				icon = <div className={'sort-icon desc'}/>
			} else {
				icon = <div className={'sort-icon'}/>
			}
		}

		let filterIcon
		let activeFilter = false
		if (column.filter) {
			const filterName = column.filter.name || column.name
			const filterTitle = column.filter.title || column.title
			let filterComponent
			if (column.filter.type === FILTER_TYPE.FULL_TEXT) {
				activeFilter = !!filters[filterName]
				filterComponent = (
					<TextFilter
						id={id}
						ref={this.filter}
						position={column.filter.position}
						name={filterName}
						visible={filterVisible}
						onSearch={this.handleFilter}
						onReset={() => onReset([filterName])}
						title={filterTitle}
					/>
				)
			} else if (column.filter.type === FILTER_TYPE.NUMBER_RANGE) {
				activeFilter = !!filters[`${filterName}From`] || !!filters[`${filterName}To`]
				filterComponent = (
					<NumberRangeFilter
						id={id}
						ref={this.filter}
						position={column.filter.position}
						name={filterName}
						title={filterTitle}
						onFilter={this.handleFilter}
						onReset={() => onReset([`${filterName}From`, `${filterName}To`])}
						visible={filterVisible}
					/>
				)
			} else if (column.filter.type === FILTER_TYPE.DATE_RANGE) {
				activeFilter = !!filters[`${filterName}From`] || !!filters[`${filterName}To`]
				filterComponent = (
					<DateRangeFilter
						id={id}
						ref={this.filter}
						position={column.filter.position}
						name={filterName}
						title={filterTitle}
						onFilter={this.handleFilter}
						onReset={() => onReset([`${filterName}From`, `${filterName}To`])}
						visible={filterVisible}
					/>
				)
			} else if (column.filter.type === FILTER_TYPE.SELECT) {
				activeFilter = !!filters[filterName] && filters[filterName].length
				filterComponent = (
					<SelectFilter
						id={id}
						ref={this.filter}
						position={column.filter.position}
						renderLabel={column.filter.renderLabel}
						title={filterTitle}
						visible={filterVisible}
						name={filterName}
						values={column.filter.values}
						isLoading={column.filter.isLoading}
						onFilter={this.handleFilter}
						onReset={() => onReset([filterName])}
					/>
				)
			}

			const filterIconStyles = {}

			if (column.filter.icon) {
				filterIconStyles.background = `url(${column.filter.icon}) no-repeat center`
				filterIconStyles.backgroundSize = 'contain'
			}

			filterIcon = (
				<Popover
					isOpen={filterVisible}
					position={'bottom'}
					containerClassName={'table-filter-wrapper'}
					content={filterComponent}
					arrowColor={'white'}
					arrowSize={10}
				>
					<div
						// eslint-disable-next-line no-return-assign
						ref={(ref) => this.node = ref}
						onClick={this.toggleFilter}
						className={'filter-icon'}
					>
						<div className={cx('icon', { active: activeFilter })} style={filterIconStyles}/>
					</div>
				</Popover>
			)
		}

		return (
			<th
				style={style}
				className={cx('sortable regular', column.className, {
					filterable: !!column.filter,
					filtering: !!get(column, 'filter.value')
				})}
				onClick={() => this.toggleSort()}
			>
				{filterIcon}
				<span>{column.title}</span>
				{icon}
			</th>
		)
	}
}

export default TableHeader
