import React from 'react'
import {
	debounce,
	get,
	isEqual,
	head,
	last,
	trim
} from 'lodash'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQueryParams from 'react-query-params'
import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// atoms
import Pagination from '../../atoms/Pagination'
import SearchInputField from '../../atoms/SearchInputField'
import ExportButton from '../../components/buttons/ExportButton'

// recources
import locale from '../../resources/locale'

// utils
import { MODAL, ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO } from '../../utils/enums'
import { getReq, postReq, deleteReq, patchReq } from '../../utils/request'
import { history } from '../../utils/history'

// components
import CreateContainerTypeModal from '../../components/modals/CreateContainerType'
import ContainerTypesTable from '../../components/containerTypes/ContainerTypesTable'

// pages
import Breadcrumbs from '../../components/Breadcrumb'

// actions
import * as SelectedFiltersOrdersActions from '../../actions/selectedFiltersOrdersActions'
import * as MunicipalityActions from '../../actions/municipalityActions'
import { statusPush } from '../../actions/statusActions'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'wasteType', 'capacity', 'type', 'isActive']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class ContainerTypesPage extends ReactQueryParams {
	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			wasteTypes: {
				data: [],
				isLoading: true,
				isFailure: false
			},
			containerTypes: {
				data: null,
				isLoading: true,
				isFailure: false
			},
			modalData: null
		}
	}

	_mounted = false

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersActions, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default: break
		}
		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		this.setQueryParams({
			...filterOrder
		})

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		// fetch data for table
		this.getContainerTypes(filterOrder)
		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CONTAINER_TYPES_PAGE, filterOrder)
	}

	searchContainerTypes = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	createHandler = async (values) => {
		const { selectedMunicipality, selectedFiltersOrders, reset, pushStatus } = this.props

		const body = {
			name: values.name,
			wasteTypeID: values.wasteTypeID,
			capacity: values.capacity,
			type: values.type
		}
		try {
			await postReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPES(selectedMunicipality.municipality.data.id), null, body)
			pushStatus({
				type: INFO,
				msg: locale['page.containerTypes.create.success']
			})
			reset(FORMS.CREATE_CONTAINER_TYPE)
			this.dismissModal()
			this.getContainerTypes(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	getContainerTypes = async (query) => {
		const { match } = this.props
		try {
			if (!this._mounted) {
				return
			}
			this.setState({
				containerTypes: {
					data: [],
					isLoading: true,
					isFailure: false
				}
			})
			const { municipalityID } = match.params
			const res = await getReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPES(municipalityID), query)
			if (!this._mounted) {
				return
			}

			const context = get(res, 'data.context', {})
			if (query.page > 1 && query.page > context.pages) {
				this.onChangeFilterOrder(queryParams.page, 1)
			} else {
				this.setState({
					containerTypes: {
						data: get(res, 'data'),
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				containerTypes: {
					data: [],
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidMount = async () => {
		this._mounted = true
		const { selectedFiltersOrdersActions, selectedFiltersOrders, municipalityActions, match } = this.props

		const { municipalityID } = match.params
		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// loading data
		if (municipalityID) {
			municipalityActions.loadMunicipalityWasteTypes()
		}

		this.getContainerTypes(filtersOrder)

		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CONTAINER_TYPES_PAGE, filtersOrder)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onContainerTypeDetail = (containerTypeId) => {
		const { match } = this.props
		history.push(locale.formatString(locale['path.municipality.containerTypes.detail'], match.params.municipalityID, containerTypeId))
	}

	onDeleteContainerType = async (containerType) => {
		try {
			const municipalityID = this.props.selectedMunicipality.municipality.data.id
			const endpoint = ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerType.id)
			await deleteReq(endpoint)
			this.props.pushStatus({
				type: INFO,
				msg: locale['page.containerType.delete.success']
			})
			this.dismissModal()
			this.getContainerTypes(this.props.selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onSetActiveContainerType = async (containerType) => {
		try {
			const data = { isActive: !containerType.isActive }
			const municipalityID = this.props.selectedMunicipality.municipality.data.id
			const endpoint = ENDPOINTS.MUNICIPALITY_CONTAINER_TYPE(municipalityID, containerType.id)

			await patchReq(endpoint, null, data)
			this.props.pushStatus({
				type: INFO,
				msg: locale['page.containerType.update.success']
			})
			this.getContainerTypes(this.props.selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	render = () => {
		const { selectedFiltersOrders, selectedMunicipality } = this.props
		const { containerTypes, modal } = this.state

		const order = selectedFiltersOrders.order || ''
		const isSortingColumn = head(order.split(':'))
		const isSortingDirection = last(order.split(':'))
		const breadcrumbsItems = [{
			key: 'page.municipality',
			name: locale['page.municipality.settings.breadcrumbs']
		}, {
			key: 'page.municipality.detail',
			name: get(selectedMunicipality, 'municipality.data.name')
		}, {
			key: 'page.containerTypes.breadcrumbs',
			name: locale['page.containerTypes.breadcrumbs']
		}]

		const municipalityID = get(selectedMunicipality, 'municipality.data.id')

		return (
			<>
				<Breadcrumbs
					items={breadcrumbsItems}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.containerTypes.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={[16, 16]} justify={'space-between'}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchContainerTypes, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton
										url={ENDPOINTS.CONTAINER_TYPES_EXPORT(municipalityID)}
										context={selectedFiltersOrders}
									/>
								</Col>
								<Col>
									<Button
										onClick={this.openModal(MODAL.CREATE_CONTAINER_TYPE)}
									>
										<PlusOutlined/>
										<span>{locale['page.containerType.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<ContainerTypesTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onDelete={this.onDeleteContainerType}
										onSetActive={this.onSetActiveContainerType}
										onDetail={this.onContainerTypeDetail}
										containerTypes={get(containerTypes, 'data.containerTypes', [])}
										loading={containerTypes.isLoading}
										isSortingColumn={isSortingColumn}
										isSortingDirection={isSortingDirection}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(containerTypes, 'data.context.pages')}
										page={get(containerTypes, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<CreateContainerTypeModal
						wasteTypes={selectedMunicipality.wasteTypes}
						dismissHandler={this.dismissModal}
						onSubmit={this.createHandler}
						shown={modal === MODAL.CREATE_CONTAINER_TYPE}
					/>
				</div>
			</>

		)
	}
}

const mapStateToProps = (state) => ({
	selectedMunicipality: state.selectedMunicipality,
	municipality: state.selectedMunicipality.municipality.data,
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CONTAINER_TYPES_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	selectedFiltersOrdersActions: bindActionCreators(SelectedFiltersOrdersActions, dispatch),
	municipalityActions: bindActionCreators(MunicipalityActions, dispatch),
	reset: bindActionCreators(reset, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ContainerTypesPage)
