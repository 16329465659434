import { toNumber, isNumber, includes } from 'lodash'
import moment from 'moment-timezone'
import dayjs from 'dayjs'

import locale from '../resources/locale'
import { COLLECTION_PLACE_TYPE, CONTAINER_TYPES, DATE_TIME_FORMAT } from './enums'

const dateFormat = locale['common.date.format.date']

export const getContainerTypesByCollectionPlaceType = (type) => {
	switch (type) {
		case COLLECTION_PLACE_TYPE.COLLECTION_YARD:
			return [CONTAINER_TYPES.COLLECTION_YARD]
		default:
			return [CONTAINER_TYPES.ONE_TIME, CONTAINER_TYPES.PERMANENT, CONTAINER_TYPES.UNIVERSAL]
	}
}

export function parseNumber(value) {
	if (isNumber(value)) {
		return value
	}
	if (value && value.length === 1 && value[0] === '-') {
		return value
	}
	if (value && value.toString().length > 0) {
		return toNumber(value)
	}
	return null
}

export const formatDatetime = (date) => {
	if (!date) {
		return '-'
	}

	return dayjs(date).format(DATE_TIME_FORMAT)
}

export function formatBatteryLevel(level) {
	if (typeof level === 'undefined' || level === null) {
		return '-'
	}

	return `${parseNumber(level) * 100}%`
}

export function formatDate(date) {
	if (date) {
		return moment(date).format(dateFormat)
	}
	return '-'
}

export function formatAddress(place) {
	if (place) {
		let address = place.street || place.city
		if (place.streetNumber || place.number) {
			let number = ''
			if (place.number) {
				({ number } = place)
				if (place.streetNumber) {
					number = `${number}/${place.streetNumber}`
				}
			} else if (place.streetNumber) {
				number = place.streetNumber
			}
			address = `${address} ${number}`
		}
		if (place.city) {
			address = `${address}, ${place.city}`
		}

		if (place.flatNumber && place.type && place.type === COLLECTION_PLACE_TYPE.FLAT) {
			address = `${address} - ${place.flatNumber}`
		}
		return address
	}
	return '-'
}

export function isSameDate(date, other) {
	const momentDate = moment(date)
	const momentOther = moment(other)
	if (momentDate.isValid() && momentOther.isValid()) {
		return momentDate.year() === momentOther.year() && momentDate.month() === momentOther.month() && momentDate.date() === momentOther.date()
	}
	return false
}

/**
 * @param {Object} object form extraction
 * @param {Array} allowedProperty allowed keys for new object
 *
 * @return {Object} newObject only with allowed keys
 */
export function filterAllowedProperties(object, allowedProperty) {
	return Object.keys(object)
		.filter((key) => includes(allowedProperty, key))
		.reduce((obj, key) => ({
			...obj,
			[key]: object[key]
		}), {})
}
