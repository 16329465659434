import { get, find, map, mapValues } from 'lodash'
import {
	SELECTED_MUNICIPALITY_LOAD_DONE,
	SELECTED_MUNICIPALITY_LOAD_FAIL,
	SELECTED_MUNICIPALITY_LOAD_START,
	SELECTED_MUNICIPALITY_DESELECTED,
	MUNICIPALITY_WASTE_TYPES_LOAD_DONE,
	MUNICIPALITY_WASTE_TYPES_LOAD_FAIL,
	MUNICIPALITY_WASTE_TYPES_LOAD_START,
	MUNICIPALITIES_UPDATE,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE,
	MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL,
	MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE,
	MUNICIPALITIES_LOAD_FAIL,
	MUNICIPALITIES_LOAD_DONE,
	MUNICIPALITIES_LOAD_START, SET_LAST_COLLECTION_YARD
} from '../types/municipality'

import { getReq } from '../utils/request'
import { ENDPOINTS, COLLECTION_PLACE_TYPE } from '../utils/enums'
import { parseNumber } from '../utils/utils'
import { history } from '../utils/history'
import locale from '../resources/locale'
import { getAuthUser } from '../redux/authentication'

export const municipalityLoadStart = () => ({
	type: SELECTED_MUNICIPALITY_LOAD_START
})

export const municipalityLoadFail = () => ({
	type: SELECTED_MUNICIPALITY_LOAD_FAIL
})

export const municipalityLoadDone = (municipality) => ({
	type: SELECTED_MUNICIPALITY_LOAD_DONE,
	payload: {
		municipality
	}
})

export const municipalitiesUpdate = (municipalities) => ({
	type: MUNICIPALITIES_UPDATE,
	payload: {
		municipalities
	}
})

export const municipalityWasteTypesLoadStart = () => ({
	type: MUNICIPALITY_WASTE_TYPES_LOAD_START
})

export const municipalityWasteTypesLoadFail = () => ({
	type: MUNICIPALITY_WASTE_TYPES_LOAD_FAIL
})

export const municipalityCollectionPlacesLoadStart = () => ({
	type: MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_START
})

export const municipalityCollectionPlacesLoadDone = (municipalityCollectionPlacesYard) => ({
	type: MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_DONE,
	payload: {
		municipalityCollectionPlacesYard
	}
})

export const municipalityCollectionPlacesLoadFail = () => ({
	type: MUNICIPALITY_COLLECTION_PLACES_YARD_LOAD_FAIL
})

export const municipalityWasteTypesLoadDone = (municipalityWasteTypes) => ({
	type: MUNICIPALITY_WASTE_TYPES_LOAD_DONE,
	payload: {
		municipalityWasteTypes
	}
})

export const municipalityCollectionPlaceContainersStart = () => ({
	type: MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_START
})

export const municipalityCollectionPlaceContainersFail = () => ({
	type: MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_FAIL
})

export const municipalityCollectionPlaceContainersDone = (municipalityCollectionPlaceContainers) => ({
	type: MUNICIPALITY_COLLECTION_PLACE_CONTAINERS_DONE,
	payload: {
		municipalityCollectionPlaceContainers
	}
})

export const deselectMunicipality = () => ({
	type: SELECTED_MUNICIPALITY_DESELECTED
})

export const setLastCollectionYard = (payload) => ({
	type: SET_LAST_COLLECTION_YARD, payload
})

export const updateMunicipality = (municipality) => async (dispatch, getStore) => {
	const store = getStore()
	const authUser = getAuthUser(store)
	const isAdmin = authUser.data?.isAdmin

	const selectedMunicipality = get(store, 'selectedMunicipality.municipality.data')
	if (isAdmin) {
		dispatch(municipalityLoadDone(municipality))
	} else {
		const authUser = getAuthUser(store)
		const municipalities = authUser.data?.municipalities || []

		const updatedMunicipalities = map(municipalities, (item) => (
			parseNumber(item.id) === parseNumber(municipality.id) ? { ...item, ...municipality } : item
		))
		dispatch(municipalitiesUpdate(updatedMunicipalities))
		if (selectedMunicipality && selectedMunicipality.id === municipality.id) {
			dispatch(municipalityLoadDone({
				...selectedMunicipality,
				...municipality
			}))
		}
	}
}

export const selectMunicipality = (municipalityID) => async (dispatch, getStore) => {
	const store = getStore()
	const authUser = getAuthUser(store)
	const isAdmin = authUser.data?.isAdmin

	const selectedMunicipalityID = get(store, 'selectedMunicipality.municipality.data.id')
	if (selectedMunicipalityID !== municipalityID) {
		dispatch(setLastCollectionYard(null))
	}

	if (!municipalityID) {
		dispatch(deselectMunicipality())
	} else if (isAdmin) {
		try {
			dispatch(municipalityLoadStart())
			const municipality = await getReq(ENDPOINTS.MUNICIPALITY(municipalityID))
			dispatch(municipalityLoadDone(municipality.data))
		} catch (e) {
			dispatch(municipalityLoadFail())
		}
	} else {
		try {
			const authUser = getAuthUser(store)
			const municipalities = authUser.data?.municipalities || []

			const profileMunicipality = find(municipalities, (municipality) => municipality.id === parseNumber(municipalityID))
			const municipality = await getReq(ENDPOINTS.MUNICIPALITY(profileMunicipality.id))
			if (municipality) {
				dispatch(municipalityLoadDone(municipality.data))
			} else {
				dispatch(municipalityLoadFail())
			}
		} catch (error) {
			history.push(locale['path.index'])
		}
	}
}

export const loadMunicipalityWasteTypes = (municipalityID, callback) => async (dispatch, getStore) => {
	try {
		const store = getStore()
		const selectedMunicipalityID = municipalityID || get(store, 'selectedMunicipality.municipality.data.id')
		dispatch(municipalityWasteTypesLoadStart())
		const municipalityWasteTypes = await getReq(ENDPOINTS.MUNICIPALITY_WASTE_TYPES(selectedMunicipalityID), { limit: 'ALL' })
		dispatch(municipalityWasteTypesLoadDone(municipalityWasteTypes.data))
		if (callback) {
			callback()
		}
	} catch (e) {
		dispatch(municipalityWasteTypesLoadFail())
	}
}

export const loadMunicipalities = (queryParams) => async (dispatch) => {
	try {
		dispatch({ type: MUNICIPALITIES_LOAD_START })
		const normalizeQueryParams = mapValues(queryParams, (queryParam) => queryParam || undefined)

		const { data } = await getReq(ENDPOINTS.MUNICIPALITIES, normalizeQueryParams)

		dispatch({ type: MUNICIPALITIES_LOAD_DONE, payload: { data } })
	} catch (e) {
		dispatch({ type: MUNICIPALITIES_LOAD_FAIL })
	}
}

export const loadMunicipalityCollectionPlaces = (municipalityID) => async (dispatch, getStore) => {
	try {
		const store = getStore()
		dispatch(municipalityCollectionPlacesLoadStart())
		const selectedMunicipalityID = municipalityID || get(store, 'selectedMunicipality.municipality.data.id')
		const municipalityCollectionPlaces = await getReq(ENDPOINTS.COLLECTION_PLACES, {
			municipalityID: selectedMunicipalityID,
			type: COLLECTION_PLACE_TYPE.COLLECTION_YARD,
			limit: 'ALL'
		})
		dispatch(municipalityCollectionPlacesLoadDone(municipalityCollectionPlaces.data))

		return municipalityCollectionPlaces.data
	} catch (e) {
		dispatch(municipalityCollectionPlacesLoadFail())
		return null
	}
}

export const loadMunicipalityCollectionPlaceContainers = (municipalityID, collectionPlaceID) => async (dispatch, getStore) => {
	try {
		const store = getStore()
		dispatch(municipalityCollectionPlaceContainersStart())
		const selectedMunicipalityID = municipalityID || get(store, 'selectedMunicipality.municipality.data.id')

		const res = await getReq(ENDPOINTS.COLLECTION_PLACE_CONTAINERS(selectedMunicipalityID, collectionPlaceID), { limit: 'ALL' })

		dispatch(municipalityCollectionPlaceContainersDone(res.data))
	} catch (e) {
		dispatch(municipalityCollectionPlaceContainersFail())
	}
}
