import React, { PureComponent } from 'react'
import { Col, Row } from 'antd'

import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { map, indexOf } from 'lodash'
import { Field, fieldPropTypes } from 'redux-form'
import i18next from 'i18next'

import locale from '../../resources/locale'
import { FILTER_POSITION, FILTER_POSITIONS } from '../../utils/enums'
import Button from '../buttons/Button'

const SelectField = ({ input, toggleValue, values, renderLabel, onChange }) => (
	<div className={'values-wrapper'}>
		{map(values, (value) => {
			const active = indexOf(input.value, value.value) >= 0
			return (
				<div key={value.value} className={'checkbox-wrapper'}>
					<label>{renderLabel ? renderLabel(value) : value.label}</label>
					<div onClick={() => toggleValue(input, value.value, onChange)} className={cx('checkbox', { active })}/>
				</div>
			)
		})}
	</div>
)

SelectField.propTypes = {
	...fieldPropTypes,
	toggleValue: PropTypes.func.isRequired,
	values: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	renderLabel: PropTypes.func
}

const toggleValue = (input, value, onChange) => {
	const selectedValues = input.value
	if (selectedValues) {
		const index = indexOf(selectedValues, value)

		if (index >= 0) {
			const values = [...selectedValues]
			values.splice(index, 1)
			input.onChange(values)
			if (onChange) {
				onChange(values)
			}
		} else {
			input.onChange([...selectedValues, value])
			if (onChange) {
				onChange([...selectedValues, value])
			}
		}
	} else {
		input.onChange([value])
		if (onChange) {
			onChange([value])
		}
	}
}

class SelectFilter extends PureComponent {
	static propTypes = {
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		onFilter: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
		onChange: PropTypes.func,
		title: PropTypes.string.isRequired,
		visible: PropTypes.bool.isRequired,
		renderLabel: PropTypes.func,
		values: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.any,
			value: PropTypes.any
		})).isRequired,
		position: PropTypes.oneOf(FILTER_POSITIONS),
		isLoading: PropTypes.bool.isRequired
	}

	node = React.createRef()

	render() {
		const { onFilter, onReset, title, visible, name, values, isLoading, position, onChange, renderLabel } = this.props

		let content

		if (isLoading) {
			content = (
				<div className={'row'}><div className={'col-12'}>{i18next.t('loc:Načítavam možnosti')}...</div></div>
			)
		} else {
			content = (
				<Field
					name={name}
					component={SelectField}
					props={{
						toggleValue,
						values,
						renderLabel,
						onChange
					}}
				/>
			)
		}

		return (
			<div
				ref={this.node}
				className={cx('table-filter values', { visible, left: position === FILTER_POSITION.LEFT })}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={'row'} style={{ marginBottom: '15px' }}>
					<div className={'col-6 title'}>{title}</div>
					<div className={'col-6 reset'}>
						<span onClick={onReset}>{locale['common.reset']}</span>
					</div>
				</div>
				{content}
				<Row justify={'end'} style={{ marginTop: 16 }}>
					<Col>
						<Button
							onClick={onFilter}
						>
							{locale['common.use']}
						</Button>
					</Col>
				</Row>
			</div>
		)
	}
}

export default SelectFilter
