import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AnyObject } from 'final-form'

import { Col, Row } from 'antd'

import { getFormValues, initialize } from 'redux-form'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
	ArrayParam, NumberParam, NumericArrayParam, StringParam, useQueryParams
} from 'use-query-params'

import { findIndex, forEach, indexOf, map, reduce, sortBy } from 'lodash'

import { faTrashAlt, faTruck } from '@fortawesome/free-solid-svg-icons'

import { getSelectedMunicipality } from '../../redux/municipalities/selectors'
import { useDataLoader } from '../../hooks/useDataLoader'

import { DEFAULT_WASTE_TYPE_COLOR, FILTER, FILTER_POSITION, MODAL } from '../../utils/enums'
import { formatContainerName } from '../../utils/formatters'
import { collectionRounds, collections, containers, containerTypes, devices, wasteTypes } from '../../api'

import { Box, BoxContent, BoxHeader, BoxTitle } from '../../layouts/Box'
import { history } from '../../utils/history'

import Breadcrumb, { BreadcrumbItem } from '../../components/Breadcrumb'
import SpinLoading from '../../components/SpinLoading'
import InfoField from '../../components/InfoField'
import WasteTypeLabel from '../../components/WasteTypeLabel'
import Dot from '../../components/Dot'
import Table from '../../components/table/Table'

import ListItemsLimit from '../../atoms/ListImtesLimit'
import Pagination from '../../atoms/Pagination'

import { columns, defaultColumns } from './columns'
import { objectHasNoValues } from '../../utils/helpers'
import { Collection, defaultModalState } from '../../types/data'

import DeleteModal from '../../components/DeleteModal'
import ChangeCollectionRoundModal from '../../components/collections/ChangeCollectionRoundModal'

type Props = RouteComponentProps<{ containerID: string }>

const filtersSelector = getFormValues(FILTER.CONTAINER_COLLECTION_FILTERS)

const ContainerPage = ({ match }: Props) => {
	const [t] = useTranslation()

	const [{ data, isLoading }, loadContainer] = useDataLoader(containers.loadContainer)

	const [collectionsState, loadCollections] = useDataLoader(collections.loadCollections)
	const [collectionRoundsState, loadCollectionRounds] = useDataLoader(collectionRounds.loadCollectionRounds)
	const [containerTypesState, loadContainerTypes] = useDataLoader(containerTypes.loadContainerTypes)
	const [wasteTypesState, loadWasteTypes] = useDataLoader(wasteTypes.loadMunicipalityWasteTypes)
	const [devicesState, loadDevices] = useDataLoader(devices.loadDevices)

	const [selected, setSelected] = useState<{ [id: string]: Collection }>({})
	const [modal, setModal] = useState(defaultModalState)

	const dispatch = useDispatch()

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		page: NumberParam,
		limit: NumberParam,
		order: StringParam,
		codeSearch: StringParam,
		serialNumberSearch: StringParam,
		rfidSerialSearch: StringParam,
		rfidSearch: StringParam,
		scanDatetimeFrom: StringParam,
		scanDatetimeTo: StringParam,
		containerTypeIDs: NumericArrayParam,
		municipalityWasteTypeIDs: NumericArrayParam,
		collectionRoundIDs: NumericArrayParam,
		deviceIDs: NumericArrayParam,
		quantityFrom: NumberParam,
		quantityTo: NumberParam,
		weightFrom: NumberParam,
		weightTo: NumberParam,
		createdAtFrom: StringParam,
		createdAtTo: StringParam,
		customerNameSearch: StringParam,
		collectionPlaceNameSearch: StringParam,
		municipalityNameSearch: StringParam,
		collectionPlaceStreetSearch: StringParam,
		collectionPlaceStreetNumberSearch: StringParam,
		collectionPlaceNumberSearch: StringParam,
		state: ArrayParam,
		columns: ArrayParam
	})

	const collectionsFilters = (useSelector(filtersSelector) || {}) as AnyObject

	const municipality = useSelector(getSelectedMunicipality)

	const allColumns = useMemo(() => columns(), [])
	const allDefaultColumns = useMemo(() => defaultColumns(allColumns), [allColumns])
	const columnsOptions = useMemo(() => map(allColumns, (col) => ({
		value: col.name,
		label: col.title
	})), [allColumns])

	const collectionRoundsOptions = useMemo(() => {
		if (collectionRoundsState.data) {
			return [{
				value: 0,
				label: t('ContainerPage.noCollectionRound')
			},
			...map(collectionRoundsState.data.collectionRounds, (round) => ({ label: round.name, value: round.id }))]
		}
		return []
	}, [collectionRoundsState.data, t])

	const wasteTypeOptions = useMemo(() => {
		if (wasteTypesState.data) {
			return map(wasteTypesState.data.wasteTypes, (type) => ({
				value: type.id,
				label: <WasteTypeLabel><Dot color={`#${type.color}`}/><span>{type.name}</span></WasteTypeLabel>
			}))
		}

		return []
	}, [wasteTypesState.data])

	const containerTypeOptions = useMemo(() => {
		if (containerTypesState.data) {
			return map(containerTypesState.data.containerTypes, (type) => ({
				value: type.id,
				label: <WasteTypeLabel><Dot color={`#${type.wasteType?.color || DEFAULT_WASTE_TYPE_COLOR}`}/><span>{type.name}</span></WasteTypeLabel>
			}))
		}

		return []
	}, [containerTypesState.data])

	const deviceOptions = useMemo(() => {
		if (devicesState.data) {
			return map(devicesState.data.devices, (device) => ({
				value: device.id,
				label: `${device.name} - ${device.number}`
			}))
		}

		return []
	}, [devicesState.data])

	const defaultQuery = useMemo(() => ({
		page: 1,
		limit: 20,
		order: 'scanDatetime:DESC',
		columns: allDefaultColumns
	}), [allDefaultColumns])

	const breadcrumbs = useMemo(() => {
		let items: BreadcrumbItem[] = []

		if (municipality) {
			items = [{
				key: 'path.municipalities',
				name: t('paths:MUNICIPALITY.title')
			}, {
				key: 'path.municipalities.detail',
				name: municipality.name
			}]
		}

		return [
			...items, {
				key: 'path.lists',
				name: t('paths:LISTS.title')
			}, {
				key: 'path.lists.containers',
				name: t('paths:LISTS.containers.title'),
				link: municipality
					? t('paths:LISTS.containers.municipalityPath', { municipalityID: municipality.id })
					: t('paths:LISTS.containers.path')
			}, {
				key: 'paths.lists.containers.detail',
				name: data ? formatContainerName(data) : t('loading')
			}
		]
	}, [municipality, t, data])

	const loadCollectionsData = useCallback((filters: AnyObject) => {
		setQuery(filters, 'replace')
		dispatch(initialize(FILTER.CONTAINER_COLLECTION_FILTERS, filters))
		loadCollections({ ...filters, containerID: match.params.containerID })
	}, [dispatch, loadCollections, match.params.containerID, setQuery])

	const handleFilter = useCallback((filters: AnyObject) =>
		loadCollectionsData({ ...filters }), [loadCollectionsData])

	const handleSort = useCallback((order) =>
		loadCollectionsData({ ...collectionsFilters, order }), [collectionsFilters, loadCollectionsData])

	const handleChangeColumns = useCallback((columns: string[]) =>
		loadCollectionsData({ ...collectionsFilters, columns }), [collectionsFilters, loadCollectionsData])

	const handleResetFilters = useCallback(() =>
		loadCollectionsData({ ...defaultQuery, columns: collectionsFilters.columns, order: collectionsFilters.order }), [collectionsFilters.order, defaultQuery, loadCollectionsData])

	const getActions = useCallback((item: Collection) => {
		const selectedArray = Object.values(selected)

		// faTrashAlt,
		return [{
			key: 'common.delete',
			title: t('ContainerPage.deleteCollection', { count: selectedArray.length || 1 }),
			icon: faTrashAlt,
			callback: () => selectedArray.length
				? setModal({ key: MODAL.DELETE_COLLECTIONS, data: null })
				: setModal({ key: MODAL.DELETE_COLLECTION, data: item.id })
		}, {
			key: 'collection.round',
			title: t('ContainerPage.changeCollectionRound', { count: selectedArray.length || 1 }),
			icon: faTruck,
			callback: () => selectedArray.length
				? setModal({ key: MODAL.COLLECTION_SET_COLLECTION_ROUND, data: null })
				: setModal({ key: MODAL.COLLECTION_SET_COLLECTION_ROUND, data: item.id })
		}]
	}, [selected, t])

	const handleCloseModal = useCallback((reload?: boolean) => {
		setModal(defaultModalState)

		if (reload) {
			setSelected({})
			loadContainer(match.params.containerID)
			loadCollectionsData(collectionsFilters)
		}
	}, [collectionsFilters, loadCollectionsData, loadContainer, match.params.containerID])

	const handleSelect = useCallback((items) => {
		const newItems = { ...selected }

		forEach(items, (item) => {
			if (newItems[`${item.id}`]) {
				delete newItems[`${item.id}`]
			} else {
				newItems[`${item.id}`] = item
			}
		})

		setSelected(newItems)
	}, [selected])

	const handleGetCollections = useCallback(() => {
		if (modal.key === MODAL.COLLECTION_SET_COLLECTION_ROUND) {
			const selectedArray = Object.values(selected)

			return selectedArray.length
				? map(selectedArray, (item) => item.id)
				: [modal.data]
		}
		return []
	}, [modal.data, modal.key, selected])

	const activeColumns = useMemo(() => {
		const filtersValues: AnyObject = {
			'containerType.name': containerTypeOptions,
			'collectionRound.name': collectionRoundsOptions,
			'containerType.wasteType': wasteTypeOptions,
			deviceID: deviceOptions
		}

		const sortedColumns = sortBy(collectionsFilters.columns, (item) => findIndex(allColumns, { name: item }))

		return reduce(allColumns, (result, item) => {
			const filterIndex = indexOf(sortedColumns, item.name)

			if (filterIndex >= 0) {
				if (filtersValues[item.name]) {
					item.filter = {
						...item.filter,
						// @ts-ignore
						isLoading: filtersValues[item.name].length ? false : item.filter,
						values: filtersValues[item.name],
						// @ts-ignore
						position: FILTER_POSITION.RIGHT
					}
				}

				if (item.filter && filterIndex >= sortedColumns.length - 2) {
					// @ts-ignore
					item.filter.position = FILTER_POSITION.LEFT
				}

				return [
					...result,
					item
				]
			}
			return result
		}, [] as AnyObject[])
	}, [allColumns, collectionRoundsOptions, collectionsFilters.columns, containerTypeOptions, wasteTypeOptions])

	const deleteParams = useMemo(() => {
		if (data) {
			if (modal.key === MODAL.DELETE_COLLECTION) {
				return [modal.data, data.municipalityID]
			}

			if (modal.key === MODAL.DELETE_COLLECTIONS) {
				return [data.municipalityID, { collectionIDs: Object.keys(selected) }]
			}
		}

		return []
	}, [data, modal.data, modal.key, selected])

	const handleDetail = useCallback((collection: Collection) => {
		history.push(municipality
			? t('paths:REGISTRY.collections.detail.municipalityPath', { municipalityID: municipality.id, collectionID: collection.id })
			: t('paths:REGISTRY.collections.detail.path', { municipalityID: municipality.id, collectionID: collection.id }))
	}, [municipality, t])

	useEffect(() => {
		loadContainer(match.params.containerID)
	}, [loadCollections, loadContainer, match.params.containerID])

	useEffect(() => {
		let filters: AnyObject = { ...defaultQuery }

		if (objectHasNoValues(query)) {
			filters = { ...filters, ...collectionsFilters }
		} else {
			filters = { ...filters, ...query }
		}

		dispatch(initialize(FILTER.CONTAINER_COLLECTION_FILTERS, filters))
		setQuery(filters, 'replace')

		loadCollections({ ...filters, containerID: match.params.containerID })
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (data) {
			loadCollectionRounds({ municipalityID: data.municipalityID, limit: 'ALL', withoutCollections: true, order: 'date:DESC' })
			loadContainerTypes(data.municipalityID, { limit: 'ALL', order: 'name:ASC' })
			loadWasteTypes({ limit: 'ALL', order: 'name', direction: 'ASC', municipalityID: data.municipalityID })
			loadDevices(data.municipalityID, { limit: 'ALL', order: 'name:ASC' })
		}
	}, [data, loadCollectionRounds, loadContainerTypes, loadDevices, loadWasteTypes])

	let content

	if (isLoading) {
		content = <SpinLoading/>
	} else if (data) {
		content = (
			<Row>
				<Col span={24} md={4}>
					<InfoField
						title={t('ContainerPage.containerID')}
						value={data.id}
					/>
				</Col>
				<Col span={24} md={5}>
					<InfoField
						title={t('ContainerPage.code')}
						value={data.code || data.rfid || '-'}
					/>
				</Col>
				<Col span={24} md={5}>
					<InfoField
						title={t('ContainerPage.serial')}
						value={data.serialNumber || data.rfidSerial || '-'}
					/>
				</Col>
				<Col span={24} md={5}>
					<InfoField
						title={t('ContainerPage.containerType')}
						value={(
							<WasteTypeLabel>
								{!!data.containerType?.wasteType &&
								<Dot color={`#${data.containerType.wasteType.color}`}/>}
								<span>{data.containerType?.name || t('ContainerPage.unassignedContainerType')}</span>
							</WasteTypeLabel>
						)}
					/>
				</Col>
				<Col span={24} md={5}>
					<InfoField
						title={t('ContainerPage.collectionPlace')}
						value={data.collectionPlace.name || t('ContainerPage.unassignedCollectionPlace')}
					/>
				</Col>
			</Row>
		)
	}

	return (
		<>
			<Box>
				<BoxHeader>
					<Breadcrumb items={breadcrumbs}/>
					<BoxTitle>{t('ContainerPage.title')}</BoxTitle>
				</BoxHeader>
				<BoxContent className={'padded'}>
					{content}
				</BoxContent>
			</Box>
			<Box>
				<BoxHeader>
					<BoxTitle>{t('ContainerPage.collectionsTitle')}</BoxTitle>
				</BoxHeader>
				<BoxContent>
					<Table
						tableColumns={columnsOptions}
						isFailure={collectionsState.isFailure}
						isSelectable={true}
						selectedItems={selected}
						onSelect={handleSelect}
						isLoading={collectionsState.isLoading}
						onFilter={handleFilter}
						onDetail={handleDetail}
						columns={activeColumns}
						getActions={getActions}
						onChangeColumns={handleChangeColumns}
						defaultColumns={allDefaultColumns}
						data={collectionsState.data?.collections}
						onSort={handleSort}
						filters={collectionsFilters}
						onResetFilters={handleResetFilters}
						name={FILTER.CONTAINER_COLLECTION_FILTERS}
					/>
					<ListItemsLimit
						limit={collectionsFilters.limit}
						onLimit={(limit: number) => handleFilter({ ...collectionsFilters, limit })}
					/>
					<Pagination
						pages={collectionsState.data?.context.pages}
						page={collectionsState.data?.context.page}
						onPage={(page: number) => handleFilter({ ...collectionsFilters, page })}
					/>
				</BoxContent>
			</Box>
			{!!data &&
			<DeleteModal
				modalKey={modal.key}
				onClose={handleCloseModal}
				params={deleteParams}
			/>}
			{!!data &&
			<ChangeCollectionRoundModal
				onClose={handleCloseModal}
				container={data}
				visible={modal.key === MODAL.COLLECTION_SET_COLLECTION_ROUND}
				getCollections={handleGetCollections}
			/>}
		</>
	)
}

export default ContainerPage
