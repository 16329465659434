import React from 'react'
import styled from 'styled-components'
import { Col, Row, Tooltip } from 'antd'
import { Text, TextSmall } from '../../../../components/Typography'

export const Color = styled.div<{ color: string }>`
	background: ${({ color }) => color};
	width: 2px;
	height: 100%;
`

const Wrapper = styled.div`
	margin-bottom: 12px;
	padding-right: 8px;
`

const RightText = styled(Col)<{ color?: string }>`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	flex: 1;

	text-align: right;
	font-size: 1rem;
	color: ${({ theme, color }) => color || theme.colors.darkText};
`

export const TitleText = styled(Text)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const Overlay = styled.div`
	padding: 10px;
`

export type Props = {
	color: string
	title: string
	subtitle: string
	rightText: string
	rightTextColor?: string
}

const ListStatisticsRow = ({ color, title, subtitle, rightTextColor, rightText }: Props) => (
	<Wrapper>
		<Row gutter={[16, 16]} wrap={false}>
			<Col>
				<Color color={color}/>
			</Col>
			<Col style={{ minWidth: 150 }}>
				<Tooltip
					overlay={<Overlay>{title}</Overlay>}
				>
					<TitleText className={'medium'}>
						{title}
					</TitleText>
				</Tooltip>
				<TextSmall>
					{subtitle}
				</TextSmall>
			</Col>
			<RightText>
				<Tooltip
					overlay={<Overlay>{rightText}</Overlay>}
				>
					<RightText color={rightTextColor}>
						{rightText}
					</RightText>
				</Tooltip>
			</RightText>
		</Row>
	</Wrapper>
)

export default ListStatisticsRow
