import React from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PERMISSIONS } from '../../utils/enums'

import AuthRoute from '../AuthRoute'
import BaseRoute from '../BaseRoute'

import CollectionsPage from '../../pages/collections/CollectionsPage'
import CollectionPage from '../../pages/collections/CollectionPage'
import CollectionRoundsPage from '../../pages/collectionRounds/CollectionRoundsPage'
import CollectionRoundPage from '../../pages/collectionRounds/CollectionRoundPage'
import NotFoundPage from '../../pages/NotFoundPage'
import BulkCollectionPage from '../../pages/collectionYards/BulkCollectionPage'
import BulkCollectionsPage from '../../pages/collectionYards/BulkCollectionsPage'

const Registry = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collections'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_WORKER]}
				path={t('paths:REGISTRY.collections.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CollectionsPage}
			/>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collections'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_WORKER]}
				path={t('paths:REGISTRY.collections.detail.municipalityPath', { municipalityID: ':municipalityID', collectionID: ':collectionID' })}
				component={CollectionPage}
			/>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collectionRounds'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_WORKER]}
				path={t('paths:REGISTRY.collectionRounds.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CollectionRoundsPage}
			/>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collectionRounds'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_WORKER]}
				path={t('paths:REGISTRY.collectionRounds.detail.municipalityPath', { municipalityID: ':municipalityID', collectionRoundID: ':collectionRoundID' })}
				component={CollectionRoundPage}
			/>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collectionYard'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_YARD_WORKER]}
				path={t('paths:REGISTRY.collectionYard.municipalityPath', { municipalityID: ':municipalityID' })}
				component={BulkCollectionsPage}
			/>
			<AuthRoute
				exact
				menuItem={'REGISTRY.collectionYard'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER, PERMISSIONS.COLLECTION_YARD_WORKER]}
				path={t('paths:REGISTRY.collectionYard.detail.municipalityPath', { municipalityID: ':municipalityID', bulkCollectionID: ':bulkCollectionID' })}
				component={BulkCollectionPage}
			/>
			{/* Other Routes */}
			<BaseRoute
				path={'/'}
				component={NotFoundPage}
			/>
		</Switch>
	)
}

export default Registry
