import React, { FocusEvent, useState } from 'react'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'

import { Info, Input, InputWrapper, Label } from '../components/form/Styled'

type Props = WrappedFieldProps & {
	name: string
	placeholder?: string
	autoComplete?: string
	showLabel?: boolean
	label?: string
	required?: boolean
	type?: string
}

const TextInputField = ({ input, name, label, placeholder, required, autoComplete, showLabel = true, meta, type = 'text' }: Props) => {
	const [focused, setFocused] = useState(!!input.value)

	const error = meta.touched && !!meta.error
	const info = meta.error && focused

	const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
		input.onFocus(e as any)
		setFocused(true)
	}

	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		input.onBlur(e)
		setFocused(false)
	}

	const componentClass = cx({ error, info })

	return (
		<InputWrapper className={componentClass}>
			{showLabel && <Label htmlFor={name}>{label}</Label>}
			<Input
				{...input}
				className={componentClass}
				placeholder={placeholder}
				type={type}
				required={required}
				autoComplete={autoComplete}
				name={`form-${new Date().valueOf()}`}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default TextInputField
