import { createSelector } from 'reselect'
import { get, map } from 'lodash'

const getCollectionPlacesSelect = (state) => state.collectionPlaces.select

export const getCollectionPlacesList = (state) => state.collectionPlaces.list

export const getCollectionPlacesSelectOptions = createSelector([getCollectionPlacesSelect], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.collectionPlaces'), (item) => ({
		value: item.id,
		label: item.customer?.fullName ? `${item.name} - ${item.customer.fullName}` : item.name
	}))
}))
