import i18next from 'i18next'

import { BaseUser, Container } from '../types/data'

export const formatContainerName = (container: Container, withCode: boolean = false) => {
	let name: string

	if (container.containerType && container.collectionPlace) {
		name = `${container.containerType.name} - ${container.collectionPlace.name}`
	} else if (container.collectionPlace) {
		name = `${i18next.t('unknownContainer')} - ${container.collectionPlace.name}`
	} else if (container.containerType) {
		name = `${container.containerType.name} - ${i18next.t('unassignedContainer')}`
	} else {
		name = i18next.t('unknownContainer')
	}

	if (withCode) {
		const code = container.code || container.serialNumber || container.rfid || container.rfidSerial

		name = `${name} - ${code}`
	}

	return name
}

export const formatUserName = (user: BaseUser) => {
	let name: string = ''

	if (user?.name) {
		name = `${user.name}`
	}

	if (user?.surname) {
		name = `${name} ${user.surname}`
	}

	return name
}
