import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { some, intersection } from 'lodash'

import { MunicipalityBase, Profile } from '../../../types/data'

import { PERMISSIONS } from '../../../utils/enums'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import File from '../../../resources/images/ic-file.svg'
import Truck from '../../../resources/images/ic-truck.svg'
import Factory from '../../../resources/images/ic-factory.svg'
import MenuLink from './MenuLink'

type Props = {
	authUser?: Profile | null
	municipality: MunicipalityBase | null
}

const permissions = [
	PERMISSIONS.ADMINISTRATOR,
	PERMISSIONS.MUNICIPALITY_ADMIN,
	PERMISSIONS.MUNICIPALITY_WORKER
]

const allPermissions = [
	...permissions,
	PERMISSIONS.COLLECTION_WORKER,
	PERMISSIONS.COLLECTION_YARD_WORKER
]

const Registry = ({ authUser, municipality, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin && some(authUser?.municipalities, (item) => !intersection(item.permissions, allPermissions).length)) {
		return null
	}

	const isCollectionWorker = useMemo(() => !!authUser?.isAdmin
		|| some(authUser?.municipalities, (item) => intersection(item.permissions, [...permissions, PERMISSIONS.COLLECTION_WORKER]).length), [authUser])

	const isCollectionYardWorker = useMemo(() => !!authUser?.isAdmin
		|| some(authUser?.municipalities, (item) => intersection(item.permissions, [...permissions, PERMISSIONS.COLLECTION_YARD_WORKER]).length), [authUser])

	return (
		<StyledItemGroup
			{...props}
			key={'REGISTRY'}
			title={t('paths:REGISTRY.title')}
		>
			{isCollectionWorker &&
			<StyledItem key={'REGISTRY.collections'}>
				<Icon icon={File}/>
				<MenuLink basePath={'paths:REGISTRY.collections'} municipality={municipality}>
					{t('paths:REGISTRY.collections.title')}
				</MenuLink>
			</StyledItem>}
			{isCollectionWorker &&
			<StyledItem key={'REGISTRY.collectionRounds'}>
				<Icon icon={Truck}/>
				<MenuLink basePath={'paths:REGISTRY.collectionRounds'} municipality={municipality}>
					{t('paths:REGISTRY.collectionRounds.title')}
				</MenuLink>
			</StyledItem>}
			{isCollectionYardWorker &&
			<StyledItem key={'REGISTRY.collectionYard'}>
				<Icon icon={Factory}/>
				<MenuLink basePath={'paths:REGISTRY.collectionYard'} municipality={municipality}>
					{t('paths:REGISTRY.collectionYard.title')}
				</MenuLink>
			</StyledItem>}
		</StyledItemGroup>
	)
}

export default Registry
