import React from 'react'
import cx from 'classnames'

import { FieldRenderProps } from 'react-final-form'

import { Info, Input, InputWrapper, Label } from './Styled'

type Props = FieldRenderProps<string> & {
	label?: string
	placeholder?: string
	required?: boolean
	className?: string

	min?: number
	max?: number
	precision?: number
}

const NumberField = ({ input, meta, className, label, required, ...rest }: Props) => {
	const error = meta.touched && meta.error

	return (
		<InputWrapper className={cx(className, { error })}>
			{!!label &&
			<Label>
				{label}{required ? '*' : ''}
			</Label>}
			<Input
				{...input}
				{...rest}
				className={cx(className, { error })}
				type={'number'}
			/>
			<Info className={cx({ error })}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default NumberField
