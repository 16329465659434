import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'

const Wrapper = styled.div<{ height?: string }>`
	height: ${({ height }) => height || '100%'};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

type Props = {
	height?: string
}

const SpinLoading = ({ height }: Props) => (
	<Wrapper height={height}>
		<Spin/>
	</Wrapper>
)

export default SpinLoading
