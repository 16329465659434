import React from 'react'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'
import i18next from 'i18next'

import InfoFooter from '../../../components/InfoFooter'

class CollectionRoundGeneralForm extends React.Component {
	static propTypes = {
		cancelHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired
	}

	render() {
		const { initialValues } = this.props

		return (
			<div className={'box'}>
				<div className={'box-content with-padding'}>
					<div className={'box-head'}/>
					<div className={'box-body'}>
						<div className={'row'}>
							<div className={'col-md-4'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Názov')}</label>
									<div>{initialValues.name}</div>
								</div>
							</div>
							<div className={'col-md-4'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Hmotnosť')}</label>
									<div>{`${get(initialValues, 'totalWeight', 0).toFixed(2)}kg`}</div>
								</div>
							</div>
							<div className={'col-md-4'}>
								<div className={'info-wrapper'}>
									<label>{i18next.t('loc:Počet záznamov')}</label>
									<div>{get(initialValues, 'collectionsCount', 0)}</div>
								</div>
							</div>
						</div>
						<InfoFooter
							createdAt={get(initialValues, 'createdAt')}
							creator={get(initialValues, 'creator')}
							updatedAt={get(initialValues, 'updatedAt')}
							editor={get(initialValues, 'editor')}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default CollectionRoundGeneralForm
