import { getReq, patchReq } from '../../utils/request'
import { ENDPOINTS, INFO } from '../../utils/enums'
import { CUSTOMER_CODES_ACTIONS } from './constants'
import { statusPush } from '../../actions/statusActions'
import locale from '../../resources/locale'
import { uploadFile } from '../../utils/upload'

export const customerCodesLoadStart = {
	type: CUSTOMER_CODES_ACTIONS.LIST_LOAD_START
}

export const customerCodesLoadFail = {
	type: CUSTOMER_CODES_ACTIONS.LIST_LOAD_FAIL
}

export const customerCodesLoadDone = (data) => ({
	type: CUSTOMER_CODES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const customerCodesSelectLoadStart = {
	type: CUSTOMER_CODES_ACTIONS.SELECT_LOAD_START
}

export const customerCodesSelectLoadFail = {
	type: CUSTOMER_CODES_ACTIONS.SELECT_LOAD_FAIL
}

export const customerCodesSelectLoadDone = (data) => ({
	type: CUSTOMER_CODES_ACTIONS.SELECT_LOAD_DONE,
	payload: data
})

export const loadCustomerCodes = (filters) => async (dispatch) => {
	dispatch(customerCodesLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.CUSTOMER_CODES, query)

		dispatch(customerCodesLoadDone(response.data))
	} catch (e) {
		dispatch(customerCodesLoadFail)
	}
}

export const loadCustomerCodesSelect = (filters) => async (dispatch) => {
	dispatch(customerCodesSelectLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.CUSTOMER_CODES, query)

		dispatch(customerCodesSelectLoadDone(response.data))
	} catch (e) {
		dispatch(customerCodesSelectLoadFail)
	}
}

export const importCustomerCodes = (municipality, file) => async (dispatch) => {
	try {
		dispatch(statusPush({
			type: INFO,
			msg: locale['pages.customerCodes.import.loading']
		}))
		await uploadFile(file, {}, file.name, ENDPOINTS.CUSTOMER_CODES_IMPORT(municipality.id))
		dispatch(statusPush({
			type: INFO,
			msg: locale['pages.customerCodes.import.success']
		}))
	} catch (error) {
		// Error
	}
}

export const assignCustomerCodes = (municipality, data, meta) => async (dispatch) => {
	try {
		await patchReq(ENDPOINTS.CUSTOMER_CODES_ASSIGN(municipality.id), {}, data, {})
		dispatch(statusPush({
			type: INFO,
			msg: locale['pages.customerCodes.assign.success']
		}))

		if (meta && meta.onSuccess) {
			meta.onSuccess()
		}
	} catch (error) {
		// Error
	}
}

export const expireCustomerCode = (code, data, meta) => async (dispatch) => {
	try {
		await patchReq(ENDPOINTS.CUSTOMER_CODE(code.id), {}, data, {})
		dispatch(statusPush({
			type: INFO,
			msg: locale['pages.customerCodes.expire.success']
		}))

		if (meta && meta.onSuccess) {
			meta.onSuccess()
		}
	} catch (error) {
		// Error
	}
}
