import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { createForm } from 'final-form'
import { Field, Form, FormSpy } from 'react-final-form'
import { Col, Row } from 'antd'

import { useDispatch } from 'react-redux'
import { debounce, filter, map, find, join } from 'lodash'
import { useTranslation } from 'react-i18next'

import { CollectionPlace, MunicipalityBase } from '../../../types/data'

import { statusPush } from '../../../actions/statusActions'

import { useDataLoader } from '../../../hooks/useDataLoader'

import { collectionPlaces } from '../../../api'

import { COLLECTION_PLACE_TYPE } from '../../../utils/enums'

import { Modal, ModalContent, ModalFooter, ModalHead } from '../../../components/Modal'

import Button from '../../../components/buttons/Button'
import SelectField from '../../../components/form/SelectField'
import { HeadingFive } from '../../../components/Typography'

import validateNestParentForm from '../validators/validateNestParentForm'

export type FormValues = {
	nest: { value: number, label: string } | null
	places: { value: number, label: string }[]
}

type Props = {
	municipality: MunicipalityBase | null
	visible: boolean
	onClose: () => void
	selected: CollectionPlace[]
}

const validPlaceTypes = [COLLECTION_PLACE_TYPE.COTTAGE, COLLECTION_PLACE_TYPE.FLAT_HOUSE, COLLECTION_PLACE_TYPE.FAMILY_HOUSE, COLLECTION_PLACE_TYPE.OTHER]

const NestParentModal = ({
	visible,
	municipality,
	onClose,
	selected
}: Props) => {
	const [t] = useTranslation()

	const dispatch = useDispatch()
	const [nestsState, loadNests] = useDataLoader(collectionPlaces.loadCollectionPlaces)
	const [placesState, loadPlaces] = useDataLoader(collectionPlaces.loadCollectionPlaces)
	const [childrenState, loadChildren] = useDataLoader(collectionPlaces.loadCollectionPlaces)

	const nestRef = useRef<number>()

	const handleSubmit = useCallback(async (values: FormValues) => {
		try {
			const submitData = { collectionPlaceIDs: map(values.places, (item) => item.value) }

			const { data } = await collectionPlaces.setCollectionPlaceChildren(values.nest!.value, submitData)

			dispatch(statusPush(data.messages[0]))

			onClose()
			return Promise.resolve()
		} catch (e) {
			return Promise.resolve({})
		}
	}, [dispatch, onClose])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit,
		validate: validateNestParentForm
	}), [handleSubmit])

	const children = useMemo(() => {
		if (childrenState.isLoading) {
			return t('NestParentModal.loadingChildren')
		}

		if (childrenState.data?.collectionPlaces.length) {
			const names = join(map(childrenState.data.collectionPlaces, (place) => place.name), ', ')
			return t('NestParentModal.nestChildren', { names })
		}

		return ''
	}, [childrenState, t])

	useEffect(() => {
		if (visible && selected.length) {
			const nest = find(selected, (item) => item.type === COLLECTION_PLACE_TYPE.NEST)
			form.restart({
				nest: nest ? { value: nest.id, label: nest.name } : null,
				places: map(filter(selected, (item) => validPlaceTypes.includes(item.type)), (item) => ({
					value: item.id,
					label: item.name
				}))
			})
		}
	}, [visible, selected, form])

	useEffect(() => {
		loadNests({
			types: [COLLECTION_PLACE_TYPE.NEST],
			limit: 'ALL',
			municipalityID: municipality?.id
		})
	}, [loadNests, municipality])

	const filterPlaces = useMemo(() => debounce((search: string) => {
		if (municipality) {
			const filters = {
				nameSearch: search,
				types: [COLLECTION_PLACE_TYPE.COTTAGE, COLLECTION_PLACE_TYPE.FLAT_HOUSE, COLLECTION_PLACE_TYPE.FAMILY_HOUSE, COLLECTION_PLACE_TYPE.OTHER],
				municipalityID: municipality.id
			}
			loadPlaces(filters)
		}
	}, 150), [loadPlaces, municipality])

	const nestOptions = useMemo(() => map(nestsState.data?.collectionPlaces, (item) => ({
		value: item.id,
		label: item.name
	})), [nestsState.data])

	const placesOptions = useMemo(() => map(placesState.data?.collectionPlaces, (item) => ({
		value: item.id,
		label: item.name
	})), [placesState.data])

	return (
		<Modal
			onCancel={onClose}
			visible={visible}
			footer={null}
		>
			{/* @ts-ignore */}
			<Form
				subscription={{ submitting: true }}
				form={form}
				render={({ handleSubmit, submitting }) => (
					<>
						<FormSpy
							subscription={{ values: true }}
							onChange={({ values }) => {
								if (values.nest?.value && nestRef.current !== values.nest?.value) {
									loadChildren({ limit: 'ALL', parentID: values.nest.value })
									nestRef.current = values.nest?.value
								}
							}}
						/>
						<ModalHead>
							{t('NestParentModal.title')}
						</ModalHead>
						<ModalContent>
							<HeadingFive style={{ marginBottom: 16 }}>
								{t('NestParentModal.description')}
							</HeadingFive>
							<form onSubmit={handleSubmit}>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Field
											name={'nest'}
											render={(props) => (
												<>
													<SelectField
														{...props}
														labelInValue={true}
														label={t('fields.nest.label')}
														placeholder={t('fields.nest.placeholder')}
														options={nestOptions}
													/>
													{!!children &&
													<HeadingFive>
														{children}
													</HeadingFive>}
												</>
											)}
										/>
									</Col>
									<Col span={24}>
										<Field
											name={'places'}
											render={(props) => (
												<SelectField
													{...props}
													label={t('fields.places.label')}
													placeholder={t('fields.places.placeholder')}
													mode={'multiple'}
													onSearch={filterPlaces}
													labelInValue={true}
													showSearch={true}
													filterOption={false}
													isLoading={placesState.isLoading}
													options={placesOptions}
												/>
											)}
										/>
									</Col>
								</Row>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row gutter={16} justify={'end'}>
								<Col>
									<Button
										disabled={submitting}
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose()
										}}
									>
										{t('common.cancel')}
									</Button>
								</Col>
								<Col>
									<Button
										disabled={submitting}
										loading={submitting}
										onClick={handleSubmit}
									>
										{t('common.submit')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default NestParentModal
