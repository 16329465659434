import React from 'react'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'

class CodesTableRow extends React.Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.number.isRequired,
			code: PropTypes.string.isRequired,
			serialNumber: PropTypes.string.isRequired,
			collectionPlace: PropTypes.string
		})
	}

	render() {
		const { item } = this.props
		return (
			<tr>
				<td>{get(item, 'code')}</td>
				<td>{get(item, 'serialNumber')}</td>
				<td className={'text-right'}>{get(item, 'collectionPlace') || '-'}</td>
			</tr>
		)
	}
}

export default CodesTableRow
