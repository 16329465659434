import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get, isEmpty } from 'lodash'
import i18next from 'i18next'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

// components
import DevicesTableRow from './DevicesTableRow'

// atoms
import TableHead from '../../atoms/TableHead'
import { EmptyTableState } from '../EmptyTableState'

class DevicesTable extends React.Component {
	static propTypes = {
		showMunicipality: PropTypes.bool,
		devices: PropTypes.arrayOf(PropTypes.object),
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		isSorting: PropTypes.string,
		isSortingColumn: PropTypes.string,
		isSortingDirection: PropTypes.string,
		onDelete: PropTypes.func.isRequired
	}

	render() {
		const { devices, loading, onSetActive, onDetail, onSort, isSortingColumn, isSortingDirection, onDelete, showMunicipality } = this.props
		const rows = map(devices, (device, index) =>
			<DevicesTableRow
				showMunicipality={showMunicipality}
				key={get(device, 'id')}
				device={device}
				onDetail={onDetail}
				onSetActive={onSetActive}
				countIndex={index}
				onDelete={onDelete}
			/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={isSortingColumn === 'name'}
								title={i18next.t('loc:Názov')}
								initDirection={isSortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'type'}
								isSorting={isSortingColumn === 'type'}
								title={i18next.t('loc:Typ')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'number'}
								isSorting={isSortingColumn === 'number'}
								title={i18next.t('loc:Kód')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'UUID'}
								isSorting={isSortingColumn === 'UUID'}
								title={'UUID'}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'SIM'}
								isSorting={isSortingColumn === 'SIM'}
								title={'SIM'}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'assignedFrom'}
								isSorting={isSortingColumn === 'assignedFrom'}
								title={i18next.t('loc:Priradené od')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'assignedTo'}
								isSorting={isSortingColumn === 'assignedTo'}
								title={i18next.t('loc:Priradené do')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							{showMunicipality &&
							<TableHead
								name={'municipality'}
								isSorting={isSortingColumn === 'municipality'}
								title={i18next.t('loc:Obec')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							}
							<th>{i18next.t('loc:Posledná komunikácia')}</th>
							<th>{i18next.t('loc:Úroveň batérie')}</th>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && isEmpty(devices) && <EmptyTableState title={locale['page.devices.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.devices.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default DevicesTable
