import React, { ReactNode } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { InfoCircleOutlined } from '@ant-design/icons'

import ProfileDropdown from './ProfileDropdown'
import MunicipalitySelect from './MunicipalitySelect'

import LogoImage from '../../resources/images/logo.png'
import { toggleMenuShown } from '../../actions/menuActions'

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	background-color: white;

	&.shadow {
		flex: 1;
		box-shadow: 0 5px 40px rgba(0, 0, 0, 0.25);
	}
`

const Logo = styled.div`
	width: 250px;
	height: 100%;
	background: url("${LogoImage}") center no-repeat;
	background-size: auto calc(100% - 20px);
	background-color: ${({ theme }) => theme.colors.darkBackground};

	@media only screen and (max-width: 992px) {
		width: 64px;
		background-size: auto calc(100% - 18px);
		background-position-x: 10px;
	}
`

const InfoLink = styled.a`
	margin-right: 16px;
	font-size: 16px;

	& > .anticon {
		margin-bottom: 5px;
	}
`

const ProfileWrap = styled.div`
	display: flex;
	align-items: center;
`

const MobileMenuWrap = styled.div`
	position: absolute;
	width: 260px;
	top: 64px;
	right: 0;
	height: calc(100vh - 64px);
	overflow-y: auto;
	box-shadow: 0 40px 40px rgb(0 0 0 / 25%);
	display: none;

	@media only screen and (max-width: 992px) {
		display: block;
	}
`

type Props = {
	menu: ReactNode
}

const HeaderContent = ({ menu }: Props) => {
	const dispatch = useDispatch()
	const visibleMobileMenu = useSelector((state: any) => state?.menu?.visible)

	return <Wrapper className='layout-header'>
		<Logo/>
		<Wrapper className={cx('shadow', { 'visible-mobile-menu': visibleMobileMenu })}>
			<MunicipalitySelect/>
			<Wrapper>
				<InfoLink href={'https://wiki.moderneodpady.sk/'} target={'_blank'}>
					<InfoCircleOutlined/>
				</InfoLink>
				<ProfileWrap>
					<ProfileDropdown/>
					<div className='toggle-menu' onClick={() => dispatch(toggleMenuShown(!visibleMobileMenu))} />
				</ProfileWrap>
			</Wrapper>
			{visibleMobileMenu && (
				<MobileMenuWrap>
					{menu}
				</MobileMenuWrap>
			)}
		</Wrapper>
	</Wrapper>
}

export default HeaderContent
