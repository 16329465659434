import React, { Suspense, useEffect } from 'react'
import dayjs from 'dayjs'

import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AUDIENCE, PERMISSIONS } from '../utils/enums'

import { authenticationActions, getTokenData } from '../redux/authentication'

import MainLayout from '../layouts/MainLayout'
import SpinLoading from '../components/SpinLoading'
import SimpleLayout from '../layouts/SimpleLayout'
import LoginPage from '../pages/authentication/LoginPage'
import { selectMenuItem } from '../actions/menuActions'

type Props = {
	exact?: boolean
	pathKey?: string
	path: string
	menuItem: string
	permissions?: PERMISSIONS[]
	component: any
}

const AuthRoute = ({ component: Component, menuItem, pathKey, ...rest }: Props) => {
	const [t, i18next] = useTranslation()
	const dispatch = useDispatch()
	const tokenData = useSelector(getTokenData)
	const selectedMunicipality = useSelector((state: any) => state.selectedMunicipality.municipality)

	useEffect(() => {
		const now = dayjs().unix()

		if (!tokenData || tokenData.exp < now || tokenData.aud !== AUDIENCE.API) {
			dispatch(authenticationActions.logoutUser())
		}
	}, [dispatch, t, i18next, tokenData])

	useEffect(() => {
		dispatch(selectMenuItem(menuItem))
	}, [dispatch, menuItem])

	if (!tokenData) {
		return (
			<Route
				{...rest}
				render={() => (
					<SimpleLayout>
						<Suspense fallback={<SpinLoading/>}>
							<LoginPage redirect={false}/>
						</Suspense>
					</SimpleLayout>
				)}
			/>
		)
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<MainLayout {...props} path={rest.path}>
					{selectedMunicipality.isLoading &&
					<SpinLoading/>}
					{!selectedMunicipality.isLoading &&
					<Suspense fallback={<SpinLoading/>}>
						<Component key={pathKey || selectedMunicipality.data?.id || 'ALL'} {...props}/>
					</Suspense>}
				</MainLayout>
			)}
		/>
	)
}

export default AuthRoute
