import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { filter, includes } from 'lodash'
import i18next from 'i18next'

import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import { hasError, validate, validatorOptions } from '../../utils/validator'

import { statusPush } from '../../actions/statusActions'

import { FORMS, PERMISSIONS } from '../../utils/enums'
import { permissions } from '../../utils/select'
import SelectField from '../../atoms/SelectField'
import { getAuthUser } from '../../redux/authentication'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateSurname = validate([validatorOptions.REQUIRED])
const validateEmail = validate([validatorOptions.REQUIRED, validatorOptions.EMAIL])
const validatePermissions = validate([validatorOptions.ARRAY])

class UserProfileForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		pushStatus: PropTypes.func.isRequired,
		resendHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired
	}

	handleSubmit = (values) => {
		const errors = {}
		errors.email = validateEmail(values.email)
		errors.name = validateName(values.name)
		errors.surname = validateSurname(values.surname)

		if (hasError(errors)) {
			throw new SubmissionError(errors)
		}

		const data = {
			name: values.name,
			surname: values.surname,
			email: values.email,
			permissions: values.permissions
		}

		const { submitHandler } = this.props
		submitHandler(data)
	}

	validateUserPermissions = (value) => {
		if (value && value.length > 1 && includes(value, PERMISSIONS.MUNICIPALITY_DUSTMAN)) {
			return locale['error.validation.dustman.multiple.roles']
		}
		return validatePermissions(value)
	}

	emailFieldOptions = {
		label: i18next.t('loc:Email'),
		required: true
	}

	nameFieldOptions = {
		label: i18next.t('loc:Meno'),
		required: true
	}

	surnameFieldOptions = {
		label: i18next.t('loc:Priezvisko'),
		required: true
	}

	permissionsSelectFieldOptions = {
		label: i18next.t('loc:Oprávnenia'),
		placeholder: locale['field.placeholder.permissions'],
		required: true,
		showLabel: true,
		isMulti: true,
		options: filter(permissions, ((p) => p.value !== PERMISSIONS.ADMINISTRATOR))
	}

	returnToList = (e) => {
		e.preventDefault()
		const { cancelHandler } = this.props
		cancelHandler()
	}

	getUserState = () => {
		const { initialValues, resendHandler } = this.props

		if (!initialValues.isConfirmed) {
			const resend = (e) => {
				e.preventDefault()
				resendHandler(initialValues)
			}
			return (
				<>
					<div className={'info-wrapper pull-left'} style={{ marginLeft: '-40px' }}>
						<label>
							{locale['page.users.detail.confirmed.not']}&nbsp;
							<span onClick={resend}>{locale['page.users.detail.invitation.resend']}</span>
						</label>
					</div>
				</>
			)
		}
		return null
	}

	render() {
		const { initialValues, handleSubmit } = this.props
		return (
			<form onSubmit={handleSubmit((data) => this.handleSubmit(data))} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}>
							<div className={'row'}>
								<div className={'col-12'}>
									{this.getUserState()}
								</div>
							</div>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='name'
										type={'text'}
										placeholder={locale['field.placeholder.name']}
										component={TextInputField}
										props={this.nameFieldOptions}
										validate={validateName}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='surname'
										type={'text'}
										placeholder={locale['field.placeholder.surname']}
										component={TextInputField}
										props={this.surnameFieldOptions}
										validate={validateSurname}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='email'
										type={'email'}
										placeholder={locale['field.placeholder.email']}
										component={TextInputField}
										props={this.emailFieldOptions}
										validate={validateEmail}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name={'permissions'}
										component={SelectField}
										props={this.permissionsSelectFieldOptions}
										validate={this.validateUserPermissions}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.created']}</label>
										<div>{moment(initialValues.createdAt).format(locale['common.date.format.date'])}</div>
									</div>
								</div>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.login']}</label>
										<div>{initialValues.lastLoginAt ? moment(initialValues.lastLoginAt).format(locale['common.date.format']) : '-'}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
						<Col>
							<Button
								className={'secondary'}
								onClick={this.returnToList}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button
								type="submit"
							>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state)
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch)
})

export default reduxForm({
	form: FORMS.USER_PROFILE_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(UserProfileForm))
