import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, BoxHeader } from '../../layouts/Box'
import Breadcrumb from '../../components/Breadcrumb'
import ContainerTransferForm from './components/ContainerTransferForm'
import UndeleteForm from './components/UndeleteForm'
import AverageWasteForm from './components/AverateWastesForm'
import DataMigrationTool from './components/DataMigrationTool'

const ToolsPage = () => {
	const [t] = useTranslation()

	const breadcrumbs = useMemo(() => [{
		key: 'path.ADMINISTRATION',
		name: t('paths:ADMINISTRATION.title')
	}, {
		key: 'path.ADMINISTRATION.tools',
		name: t('paths:ADMINISTRATION.tools.title')
	}], [t])

	return (
		<Box>
			<BoxHeader>
				<Breadcrumb items={breadcrumbs}/>
			</BoxHeader>
			<ContainerTransferForm/>
			<UndeleteForm/>
			<DataMigrationTool />
			<AverageWasteForm/>
		</Box>
	)
}

export default ToolsPage
