import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Checkbox as AntCheckbox, Col, Row } from 'antd'

import { useSelector } from 'react-redux'
import { find, map, without } from 'lodash'

import { FiltersState, getFilters } from '../../redux/filters'

import Button from '../buttons/Button'

const Wrapper = styled.div`
	padding: 15px;
`

const Content = styled.div`
	max-height: 200px;
	overflow: auto;
	display: flex;
	flex-direction: column;
`

const Checkbox = styled(AntCheckbox)`
	:hover {
		background-color: ${({ theme }) => theme.colors.neutral['50']};
	}
`

type Props<Key extends keyof FiltersState> = {
	visible: boolean
	filtersName: Key
	name: keyof FiltersState[Key]
	options: { value: string, text: string }[]
	onFilter: (name: keyof FiltersState[Key], value: string[]) => void
}

function SelectFilter<Key extends keyof FiltersState>({ name, filtersName, options, visible, onFilter }: Props<Key>) {
	const [t] = useTranslation()

	const [filteredValues, setFilteredValues] = useState<string[]>([])
	const filters = useSelector(getFilters(filtersName))

	// @ts-ignore
	const filterValue = filters[name]

	useEffect(() => setFilteredValues(filterValue || []), [filterValue, visible])

	const handleCheck = (event: CheckboxChangeEvent) => {
		setFilteredValues([...filteredValues, event.target.value])
	}

	const handleUncheck = (event: CheckboxChangeEvent) => {
		setFilteredValues(without(filteredValues, event.target.value))
	}

	return (
		<Wrapper>
			<Row justify={'end'} gutter={[16, 16]} style={{ width: 250 }}>
				<Col span={24}>
					<Content>
						{map(options, (option) => {
							const checked = !!find(filteredValues, (value) => option.value === value)

							return (
								<Checkbox
									key={option.value}
									value={option.value}
									onChange={checked ? handleUncheck : handleCheck}
									checked={checked}
									style={{ marginLeft: 0 }}
								>
									{option.text}
								</Checkbox>
							)
						})}
					</Content>
				</Col>
				<Col>
					<Button
						className={'small secondary'}
						onClick={() => onFilter(name, [])}
					>
						{t('SelectFilter.clear')}
					</Button>
				</Col>
				<Col>
					<Button
						className={'small'}
						onClick={() => onFilter(name, filteredValues)}
					>
						{t('SelectFilter.search')}
					</Button>
				</Col>
			</Row>
		</Wrapper>
	)
}

export default SelectFilter
