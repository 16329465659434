import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { debounce, map } from 'lodash'

import { Col, Row } from 'antd'
import { Field, Form, FormSpy } from 'react-final-form'

import { COLLECTION_ROUND_TYPE } from '../../utils/enums'
import { CollectionRound, Container, MunicipalityBase } from '../../types/data'

import { validateRequired } from '../../utils/form'

import { collectionRounds, collections as collectionsApi } from '../../api'

import { useDataLoader } from '../../hooks/useDataLoader'

import Button from '../buttons/Button'
import DateField from '../form/DateField'
import SelectField from '../form/SelectField'
import { Modal, ModalFooter, ModalContent, ModalHead } from '../Modal'
import { statusPush } from '../../actions/statusActions'

export type FormValues = {
	collectionRound: { value: number, label: string }
}

type Props = {
	visible: boolean
	onClose: (reload?: boolean) => void
	getCollections: () => number[]
	municipality?: MunicipalityBase
	collectionRound?: CollectionRound
	container?: Container
}

const baseDate = dayjs().toISOString()

const ChangeCollectionRoundModal = ({ visible, onClose, getCollections, collectionRound, container, municipality }: Props) => {
	const [t] = useTranslation()
	const [date, setDate] = useState(baseDate)
	const [rounds, loadRounds] = useDataLoader(collectionRounds.loadCollectionRounds)
	const dispatch = useDispatch()

	const handleSubmit = useCallback(async (values: FormValues) => {
		const collections = getCollections()
		const municipalityID = municipality?.id || collectionRound?.municipalityID || container?.municipalityID

		if (collections.length && municipalityID) {
			const submitData = {
				collectionRoundID: values.collectionRound.value,
				collections
			}

			try {
				const { data } = await collectionsApi.updateCollections(municipalityID, submitData)

				dispatch(statusPush(data.messages[0]))

				onClose(true)

				return Promise.resolve()
			} catch (e) {
				return Promise.resolve({})
			}
		} else {
			onClose()
			return Promise.resolve()
		}
	}, [getCollections, municipality?.id, collectionRound?.municipalityID, container?.municipalityID, dispatch, onClose])

	const handleChangeDate = useMemo(() => debounce((date: string) => {
		setDate(date)
	}, 50), [])

	const roundsOptions = useMemo(() => rounds.data
		? map(rounds.data.collectionRounds, (round) => ({ value: round.id, label: round.name }))
		: [],
	[rounds.data])

	useEffect(() => {
		if (municipality || collectionRound || container) {
			const filters = {
				fromDate: dayjs(date).startOf('month').toDate(),
				toDate: dayjs(date).endOf('month').toDate(),
				limit: 'ALL',
				withoutCollections: true,
				type: [COLLECTION_ROUND_TYPE.STANDARD, COLLECTION_ROUND_TYPE.GENERAL],
				municipalityID: municipality?.id || collectionRound?.municipalityID || container?.municipalityID
			}
			loadRounds(filters)
		}
	}, [date, loadRounds, municipality, collectionRound, container?.municipalityID, container])

	return (
		<Modal
			visible={visible}
			onCancel={() => onClose()}
			footer={null}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={{ date: baseDate }}
				subscription={{ submitting: true }}
				render={({ handleSubmit, submitting }) => (
					<>
						<ModalHead>
							{t('ChangeCollectionRoundModal.title')}
						</ModalHead>
						<ModalContent>
							<FormSpy
								subscription={{ values: true }}
								onChange={({ values }) => {
									if (date !== values.date) {
										handleChangeDate(values.date)
									}
								}}
							/>
							<form onSubmit={handleSubmit}>
								<Field
									name={'date'}
									validateFields={[]}
									render={(props) => (
										<DateField
											{...props}
											picker={'month'}
											format={'MMMM YYYY'}
											allowClear={false}
											label={t('fields.collectionRoundDate.label')}
										/>
									)}
								/>
								<Field
									name={'collectionRound'}
									validate={validateRequired}
									render={(props) => (
										<SelectField
											{...props}
											labelInValue={true}
											isLoading={rounds.isLoading}
											options={roundsOptions}
											label={t('fields.collectionRound.label')}
											placeholder={t('fields.collectionRound.placeholder')}
										/>
									)}
								/>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row justify={'end'} gutter={[16, 8]}>
								<Col>
									<Button
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose()
										}}
									>
										{t('common.close')}
									</Button>
								</Col>
								<Col>
									<Button
										loading={submitting}
										disabled={submitting}
										onClick={handleSubmit}
									>
										{t('common.submit')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default React.memo(ChangeCollectionRoundModal)
