import moment from 'moment-timezone'
import { reduce, split, indexOf, get } from 'lodash'
import { SORT_ASC, SORT_DESC } from '../types/table'

export const QUERY_PARAMS_TYPES = {
	STRING: 'STRING',
	BOOLEAN: 'BOOLEAN',
	ORDER: 'ORDER',
	NUMBER: 'NUMBER',
	ARRAY: 'ARRAY',
	DATE: 'DATE'
}

const allowedSorts = [SORT_ASC, SORT_DESC]

function processString(type, value) {
	if (value) {
		if (type.allowedValues && type.allowedValues.length && !type.allowedValues.includes(value)) {
			return type.defaultValue
		}
		return `${value}`
	}
	return type.defaultValue
}

function processBoolean(type, value) {
	if (value === 'true') {
		return true
	}

	if (value === 'false') {
		return false
	}

	if (typeof value === 'boolean') {
		return value
	}
	return type.defaultValue
}

function processOrder(type, value) {
	const splitValue = split(value, ':')
	if (splitValue.length === 2 && indexOf(type.allowedValues, splitValue[0]) > -1 && indexOf(allowedSorts, splitValue[1]) > -1) {
		return value
	}
	return type.defaultValue
}

function processNumber(type, value) {
	if (value && !Number.isNaN(value)) {
		const floatValue = parseFloat(value)
		if (Number.isInteger(floatValue)) {
			return `${parseInt(value, 10)}`
		}

		return `${floatValue}`
	}
	if (value === null || value === 'null' || type.defaultValue === null) {
		return undefined
	}
	return `${type.defaultValue}`
}

function processArray(type, value) {
	if (Array.isArray(value)) {
		return value
	}
	return type.defaultValue
}

function processDate(type, value) {
	const date = moment(value)
	if (date.isValid()) {
		return date.toISOString()
	}
	return undefined
}

function processValue(type, value) {
	switch (type.type) {
		case QUERY_PARAMS_TYPES.ORDER:
			return processOrder(type, value)
		case QUERY_PARAMS_TYPES.BOOLEAN:
			return processBoolean(type, value)
		case QUERY_PARAMS_TYPES.STRING:
			return processString(type, value)
		case QUERY_PARAMS_TYPES.NUMBER:
			return processNumber(type, value)
		case QUERY_PARAMS_TYPES.ARRAY:
			return processArray(type, value)
		case QUERY_PARAMS_TYPES.DATE:
			return processDate(type, value)
		default:
			return undefined
	}
}

export const normalizeQueryParams = (types, params) => reduce(params, (result, value, key) => {
	const type = get(types, key)
	if (type) {
		const newValue = processValue(type, value)
		if (newValue) {
			return {
				...result,
				[key]: newValue
			}
		}
	}
	return result
}, reduce(types, (result, value, key) => ({
	...result, [key]: value.defaultValue
}), {}))

export const mergeQueryParams = (params, override) => reduce(override, (result, value, key) => {
	if (value === null || value === undefined) {
		return result
	}
	return {
		...result,
		[key]: value
	}
}, params)
