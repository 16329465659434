import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Supercluster from 'supercluster'

import { debounce, forEach, get, map } from 'lodash'

import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api'

import { CollectionPlaceMapItem } from '../../../types/data'

import { collectionPlaces } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import MapPin from './MapPin'

import ClusterIcon from '../../../resources/images/ic-marker-uncollected.svg'

type Props = {
	municipalityID?: string | number
}

const ClusterItem = styled.div`
	position: relative;
	height: 35px;
	width: 35px;
	background-size: contain;
	background: url("${ClusterIcon}") center no-repeat;
	background-size: contain !important;

	&::before {
		content: attr(data-count);
		font-size: 10px;
		font-weight: 600;
		position: absolute;
		top: 50%;
		color: white;
		transform: translateY(-60%);
		left: 0;
		width: 100%;
		text-align: center;
	}
`

const mapStyle = {
	width: '100%',
	height: '650px'
}

const defaultBounds: [{ lat: number, lng: number }, { lat: number, lng: number }] = [
	{
		lng: 17.032417,
		lat: 48.195171
	},
	{
		lng: 17.216055,
		lat: 48.122503
	}
]

const createPin = (pin: CollectionPlaceMapItem): Supercluster.PointFeature<any> => ({
	type: 'Feature',
	properties: {
		...pin
	},
	geometry: {
		type: 'Point',
		coordinates: [pin.lon, pin.lat]
	}
})

const getFitBounds = (items: CollectionPlaceMapItem[]) => {
	const bounds = new window.google.maps.LatLngBounds()
	if (items.length) {
		forEach(items, (pin) => {
			bounds.extend({
				lng: pin.lon,
				lat: pin.lat
			})
		})
	} else {
		forEach(defaultBounds, (pin) => {
			bounds.extend({
				lng: pin.lng,
				lat: pin.lat
			})
		})
	}

	return bounds
}

const renderMapPins = (pin: any, index: number) => {
	if (pin.properties.point_count > 1) {
		return (
			<OverlayView
				key={index}
				mapPaneName={'overlayMouseTarget'}
				position={{
					lat: get(pin, 'geometry.coordinates[1]'),
					lng: get(pin, 'geometry.coordinates[0]')
				}}
			>
				<ClusterItem
					data-count={pin.properties.point_count}
				/>
			</OverlayView>
		)
	}

	return (
		<OverlayView
			key={index}
			mapPaneName={'overlayMouseTarget'}
			position={{
				lat: get(pin, 'geometry.coordinates[1]'),
				lng: get(pin, 'geometry.coordinates[0]')
			}}
		>
			<MapPin pin={get(pin, 'properties')}/>
		</OverlayView>
	)
}

const Wrapper = styled.div`
	position: relative;
	display: flex;
	height: 650px;
	background: white;
`

type Box = [number, number, number, number]

const Map = ({ municipalityID }: Props) => {
	const mapRef = useRef<any>()

	const [zoom, setZoom] = useState(8)
	const [bounds, setBounds] = useState<Box>(() => [defaultBounds[0].lng, defaultBounds[1].lat, defaultBounds[1].lng, defaultBounds[0].lat])
	const [position, setPosition] = useState(() => ({
		lat: 48.73946,
		lng: 19.15349
	}))

	const [mapState, loadMap] = useDataLoader(collectionPlaces.loadMap)

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyA95cBM6BycpYSdc918ut_HOlbr0kR-p0s'
	})

	const mapOptions = useMemo(() => ({
		fullscreenControl: false,
		streetViewControl: false,
		mapTypeControlOptions: isLoaded
			? {
				position: window.google.maps.ControlPosition.BOTTOM_LEFT,
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
			}
			: undefined
	}), [isLoaded])

	const handleLoad = useCallback((map) => {
		mapRef.current = map
	}, [])

	const handleUnload = useCallback(() => {
		mapRef.current = null
	}, [])

	const handleBoundsChange = useMemo(() => debounce(() => {
		if (mapRef.current) {
			const bounds = mapRef.current.getBounds()
			const sw = bounds.getSouthWest()
			const ne = bounds.getNorthEast()

			setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()])
		}
	}, 500), [])

	const handleChangeZoom = useMemo(() => debounce(() => {
		if (mapRef.current) {
			setZoom(mapRef.current.zoom)
		}
	}, 500), [])

	const cluster = useMemo(() => {
		const cluster = new Supercluster({
			maxZoom: 18,
			radius: 75
		})
		cluster.load([])

		return cluster
	}, [])

	const mapPins = useMemo(() => {
		const clusters = cluster?.getClusters(bounds, zoom) || []
		return map(clusters, (pin, index) => renderMapPins(pin, index))
	}, [cluster, bounds, zoom])

	useEffect(() => {
		if (isLoaded && mapState.data) {
			const { collectionPlaces } = mapState.data
			cluster.load(map(collectionPlaces, createPin))

			if (collectionPlaces.length === 1) {
				setPosition({
					lat: collectionPlaces[0].lat,
					lng: collectionPlaces[0].lon
				})
				setZoom(17)
			} else if (collectionPlaces.length) {
				const bounds = getFitBounds(collectionPlaces)
				const sw = bounds.getSouthWest()
				const ne = bounds.getNorthEast()

				if (sw && ne) {
					mapRef.current?.fitBounds(bounds)
				}
			} else {
				setZoom(zoom - 1)
			}
		}
		// eslint-disable-next-line
	}, [cluster, isLoaded, mapState.data])

	useEffect(() => {
		loadMap({ municipalityID })
	}, [loadMap, municipalityID])

	return (
		<Wrapper>
			{isLoaded &&
			<GoogleMap
				onLoad={handleLoad}
				onUnmount={handleUnload}
				mapContainerStyle={mapStyle}
				options={mapOptions}
				zoom={zoom}
				center={position}
				onZoomChanged={handleChangeZoom}
				onBoundsChanged={handleBoundsChange}
			>
				{mapPins}
			</GoogleMap>}
		</Wrapper>
	)
}

export default React.memo(Map)
