import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faTrashAlt, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

import ReactTooltip from 'react-tooltip'
import locale from '../../../resources/locale'
import PopupTool from '../../../atoms/PopupTool'
import { formatDate } from '../../../utils/utils'

class CustomerTableRow extends React.Component {
	static propTypes = {
		customer: PropTypes.object.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		countIndex: PropTypes.number,
		onDelete: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = { sort: props.defaultSort || null }
	}

	customerActiveString = (customer, isActive) => {
		if (get(customer, 'activeTo')) {
			if (isActive) {
				return locale.formatString(locale['page.customers.active.to'], formatDate(customer.activeTo))
			}
			return locale.formatString(locale['page.customers.inactive.from'], formatDate(customer.activeTo))
		}
		return locale['page.customers.active']
	}

	getActivateTitle = (customer) => {
		if (customer.activeTo) {
			if (moment(customer.activeTo) > moment()) {
				return locale['page.customers.remove.active.to']
			}
			return locale['common.activate']
		}
		return locale['common.deactivate']
	}

	render() {
		const { customer, onDetail, onSetActive, onDelete, countIndex } = this.props
		// check if activeTo timestamp was expired until now
		// if activeTo is not set yet or if is not expire yet
		const isActive = get(customer, 'activeTo', null) ? moment() < moment(customer.activeTo) : true

		const items = [{
			key: 'common.delete',
			title: locale['common.delete'],
			icon: faTrashAlt,
			callback: () => onDelete(customer)
		}, {
			key: 'common.activate',
			title: this.getActivateTitle(customer),
			icon: faPowerOff,
			callback: () => onSetActive(customer)
		}]

		return (
			<tr onClick={() => onDetail(get(customer, 'id'))}>
				<td>{customer.isAnonymous ? locale.formatString(locale['page.customers.anonymous'], customer.id) : get(customer, 'name')}</td>
				<td>{get(customer, 'address')}</td>
				<td>{get(customer, 'legalForm')}</td>
				<td className={'text-center icon'} style={{ color: isActive ? '#AFCA0B' : '#F03E41' }}>
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${get(customer, 'id')}`} icon={isActive ? faCheckCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${get(customer, 'id')}`} place={'left'} effect={'solid'}>
						<span>{this.customerActiveString(customer, isActive)}</span>
					</ReactTooltip>
				</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					<PopupTool
						items={items}
					/>
				</td>
			</tr>
		)
	}
}

export default CustomerTableRow
