import React from 'react'
import moment from 'moment-timezone'
import i18next from 'i18next'

import {
	get, isEqual, head, last, map, trim
} from 'lodash'
import { reset, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQueryParams from 'react-query-params'

// recources
import locale from '../../resources/locale'

// utils
import { MODAL, ENDPOINTS, FILTER_ORDER_SELECTORS, INFO } from '../../utils/enums'
import { getReq, deleteReq, patchReq } from '../../utils/request'
import { history } from '../../utils/history'

// components
import DialogModal from '../../components/modals/DialogModal'
import ContainersTable from '../../components/containers/ContainersTable'
import DeactivateModal from '../../components/modals/DeactivateModal'
import ContainerTypeChangeModal from '../../components/modals/ContainerTypeChangeModal'

// pages
import Breadcrumbs from '../../components/Breadcrumb'

// actions
import * as SelectedFiltersOrdersActions from '../../actions/selectedFiltersOrdersActions'
import { statusPush } from '../../actions/statusActions'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import TransferContainerModal from '../../components/containers/TransferContaineModal'
import { getAuthUser } from '../../redux/authentication'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'containerType:ASC',
		allowedValues: ['containerType', 'capacity', 'type', 'assignedFrom', 'assignedTo', 'code', 'serialNumber', 'place', 'customerName']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class ContainersPage extends ReactQueryParams {
	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null
		}

		this.state = {
			modal: null,
			containers: {
				data: [],
				isLoading: true,
				isFailure: false
			},
			modalData: null
		}
	}

	_mounted = false

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersActions, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default:
				break
		}
		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		this.setQueryParams({
			...filterOrder
		})

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		// fetch data for table
		this.getContainers(filterOrder)
		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CONTAINERS_PAGE, filterOrder)
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	getContainers = async (query) => {
		const { municipality } = this.props
		try {
			if (!this._mounted) {
				return
			}
			this.setState({
				containers: {
					data: [],
					isLoading: true,
					isFailure: false
				}
			})

			const params = {
				...query,
				all: true
			}

			if (municipality) {
				params.municipalityID = municipality.id
			}

			const res = await getReq(ENDPOINTS.CONTAINERS, params)
			if (!this._mounted) {
				return
			}
			const context = get(res, 'data.context', {})
			if (query.page > 1 && query.page > context.pages) {
				this.onChangeFilterOrder(queryParams.page, 1)
			} else {
				this.setState({
					containers: {
						data: get(res, 'data'),
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				containers: {
					data: [],
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidMount = async () => {
		this._mounted = true
		const { selectedFiltersOrdersActions, selectedFiltersOrders, municipality } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// loading data
		this.getContainers(filtersOrder)

		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CONTAINERS_PAGE, filtersOrder)
		if (municipality) {
			this.loadContainerTypes(municipality)
		}
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = (prevProps) => {
		if (this._mounted) {
			const { selectedFiltersOrders, municipality } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}

			if (prevProps.municipality?.id !== municipality?.id) {
				this.getContainers(selectedFiltersOrders)

				if (municipality) {
					this.loadContainerTypes(municipality)
				}
			}
		}
	}

	loadContainerTypes = async (municipality) => {
		try {
			const response = await getReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPES(municipality.id))
			const containerTypes = map(response.data.containerTypes, (item) => ({
				value: item.id,
				label: item.name,
				type: item.type,
				key: item.id
			}))
			this.setState({ containerTypes })
		} catch (error) {
			// Error
		}
	}

	changeContainerType = async (data) => {
		try {
			const { pushStatus, municipality, selectedFiltersOrders } = this.props
			const { modalData } = this.state

			const containerData = {
				containerTypeID: data.containerTypeID
			}

			await patchReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipality.id, get(modalData, 'id')), null, containerData)

			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.container.update.success']
			})

			this.dismissModal()
			const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

			// loading data
			this.getContainers(filtersOrder)
		} catch (e) {
			// Error
		}
	}

	onEditContainer = async (data) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData } = this.state

			const containerData = {
				assignedTo: data.activeTo ? moment(data.activeTo).endOf('day').toISOString() : null
			}
			const { municipalityID } = modalData
			await patchReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipalityID, get(modalData, 'id')), null, containerData)
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.container.update.success']
			})
			this.dismissModal()
			this.getContainers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onDeleteContainer = async () => {
		try {
			this.dismissModal()
			const { pushStatus, selectedFiltersOrders, municipality } = this.props
			const { modalData } = this.state

			await deleteReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipality.id, get(modalData, 'id')))

			pushStatus({
				type: INFO,
				msg: locale['page.containers.delete.success']
			})

			this.getContainers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onTransferContainer = async (values) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData } = this.state
			const { municipalityID, id } = modalData

			const url = ENDPOINTS.CONTAINER_TRANSFER(municipalityID, id)

			await patchReq(url, {}, { ...values, collectionPlaceID: values.collectionPlaceID.value })

			pushStatus({
				type: INFO,
				msg: locale['page.container.transfer.success']
			})

			this.dismissModal()
			this.getContainers(selectedFiltersOrders)

			return true
		} catch (e) {
			return Promise.reject(new SubmissionError({}))
		}
	}

	openEditContainer = (container) => {
		if (!container.assignedTo) {
			this.openModal(MODAL.SET_INACTIVE_CONTAINER)(container)
		} else {
			this.openModal(MODAL.SET_ACTIVE_CONTAINER)(container)
		}
	}

	openContainerDetail = (containerID) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.containers.detail.municipalityPath', { municipalityID: municipality.id, containerID })
			: i18next.t('paths:LISTS.containers.detail.path', { municipalityID: municipality.id, containerID }))
	}

	render = () => {
		const { selectedFiltersOrders, municipality, user } = this.props
		const { containers, containerTypes, modal, modalData } = this.state

		const order = selectedFiltersOrders.order || ''
		const isSortingColumn = head(order.split(':'))
		const isSortingDirection = last(order.split(':'))

		const breadcrumbsItems = [{
			key: 'page.municipality',
			name: locale['page.municipality.settings.breadcrumbs']
		}, {
			key: 'page.municipality.detail',
			name: get(municipality, 'name')
		}, {
			key: 'page.containers.breadcrumbs',
			name: locale['page.containers.breadcrumbs']
		}]

		return (
			<>
				<Breadcrumbs
					items={breadcrumbsItems}
				/>
				<ContainersTable
					showPlace={true}
					creatable={false}
					municipality={municipality}
					onDetail={this.openContainerDetail}
					containers={get(containers, 'data.containers', [])}
					loading={containers.isLoading}
					sortingColumn={isSortingColumn}
					sortingDirection={isSortingDirection}
					search={selectedFiltersOrders.search}
					context={get(containers, 'data.context', {})}
					onTransfer={this.openModal(MODAL.TRANSFER_CONTAINER)}
					onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
					onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
					onSearch={(val) => this.onChangeFilterOrder(queryParams.search, trim(val))}
					onDelete={user.isAdmin ? this.openModal(MODAL.DELETE_CONTAINER) : null}
					onChangeContainerType={municipality ? this.openModal(MODAL.CHANGE_CONTAINER_TYPE) : null}
					onEdit={this.openEditContainer}
					onSetActive={this.openModal(MODAL.SET_ACTIVE_CONTAINER_TYPE)}
					onAdd={() => {}}
					limit={parseInt(selectedFiltersOrders.limit, 10)}
					onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
				/>
				{!!municipality &&
				<ContainerTypeChangeModal
					shown={modal === MODAL.CHANGE_CONTAINER_TYPE}
					cancelHandler={this.dismissModal}
					title={locale['page.collections.change.container.type.selected.title']}
					acceptTitle={locale['page.collections.change.container.accept']}
					municipality={municipality}
					selectOptions={containerTypes}
					onSubmit={this.changeContainerType}
				/>}
				<DialogModal
					shown={modal === MODAL.DELETE_CONTAINER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteContainer}
					message={locale.formatString(locale['page.containers.remove.message'])}
					title={locale.formatString(locale['page.containers.remove.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.containers.remove.accept']}
				/>
				<DeactivateModal
					shown={modal === MODAL.SET_INACTIVE_CONTAINER}
					dismissHandler={this.dismissModal}
					message={locale.formatString(locale['page.collectionPlaces.containers.deactivate.message'], get(modalData, 'name', ''))}
					title={locale.formatString(locale['page.collectionPlaces.containers.deactivate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.containers.deactivate.accept']}
					onSubmit={this.onEditContainer}
				/>
				<DialogModal
					shown={modal === MODAL.SET_ACTIVE_CONTAINER}
					cancelHandler={this.dismissModal}
					acceptHandler={() => this.onEditContainer({ activeTo: null })}
					message={locale['page.collectionPlaces.containers.activate.message']}
					title={locale.formatString(locale['page.collectionPlaces.containers.activate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.containers.activate.accept']}
				/>
				<TransferContainerModal
					shown={modal === MODAL.TRANSFER_CONTAINER}
					onDismiss={this.dismissModal}
					municipalityID={modalData?.municipalityID}
					onSubmit={this.onTransferContainer}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state),
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CONTAINERS_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	selectedFiltersOrdersActions: bindActionCreators(SelectedFiltersOrdersActions, dispatch),
	reset: bindActionCreators(reset, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ContainersPage)
