import i18next from 'i18next'
import { filter } from 'lodash'
import { FormValues } from '../components/CollectionRoundFormNew'

export const validateWasteType = (value: { value: number }, allValues: FormValues) => {
	if (value && allValues) {
		const { wasteTypes } = allValues

		const filteredWasteTypes = filter(wasteTypes, (item) => item.wasteType?.value === value.value)

		return filteredWasteTypes.length > 1 ? i18next.t('errors.wasteTypeDuplicity') : undefined
	}

	return undefined
}
