import React from 'react'
import { Field, FormSection } from 'redux-form'
import i18next from 'i18next'

import locale from '../../../resources/locale'

import TextInputField from '../../../atoms/TextInputField'

const AnonymousCodePortion = () => (
	<div className={'row'}>
		<div className={'col-12'}>
			<FormSection name={'anonymousCodes'}>
				<div className={'row'}>
					<div className={'col-12'}>
						<Field
							name={'oneTime'}
							placeholder={locale['field.placeholder.oneTimeCodes']}
							component={TextInputField}
							type={'number'}
							label={i18next.t('loc:Jednorázové kódy')}
							min={0}
							max={1000000}
							required
							showLabel
						/>
					</div>
					<div className={'col-12'}>
						<Field
							name={'fixed'}
							placeholder={locale['field.placeholder.fixedCodes']}
							component={TextInputField}
							type={'number'}
							label={i18next.t('loc:Fixné kódy')}
							min={0}
							max={1000000}
							required
							showLabel
						/>
					</div>
				</div>
			</FormSection>
		</div>
	</div>
)

export default AnonymousCodePortion
