import React from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import { map, get } from 'lodash'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import i18next from 'i18next'
import locale from '../../../resources/locale'

// components

import CodePortionTableRow from './CodePortionTableRow'
// atoms
import TableHead from '../../../atoms/TableHead'
import { EmptyTableState } from '../../../components/EmptyTableState'

class CodePortionsTable extends React.Component {
	static propTypes = {
		portions: PropTypes.shape({
			data: PropTypes.shape({
				portions: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					containersCount: PropTypes.string.isRequired,
					municipality: PropTypes.string,
					createdAt: PropTypes.string.isRequired,
					state: PropTypes.string.isRequired
				})).isRequired
			}),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		onSort: PropTypes.func.isRequired,
		onDownload: PropTypes.func.isRequired,
		onPrint: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string
	}

	render() {
		const { portions, onSort, sortingColumn, sortingDirection, onDownload, onPrint, onDetail, onDelete } = this.props
		const rows = map(get(portions, 'data.portions'), (item, index) =>
			<CodePortionTableRow
				key={get(item, 'id')}
				item={item}
				onDownload={onDownload}
				onUpdate={onPrint}
				onDelete={onDelete}
				countIndex={index}
				onDetail={onDetail}
			/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'createdAt'}
								isSorting={sortingColumn === 'createdAt'}
								title={i18next.t('loc:Dátum vytvorenia')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'municipality'}
								isSorting={sortingColumn === 'municipality'}
								title={i18next.t('loc:Obec')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'containersCount'}
								isSorting={sortingColumn === 'containersCount'}
								title={i18next.t('loc:Počet zberných nádob')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'note'}
								isSorting={sortingColumn === 'note'}
								title={i18next.t('loc:Poznámka')}
								onSort={onSort}
								initDirection={sortingDirection}
								className={'text-left'}
							/>
							<TableHead
								name={'state'}
								isSorting={sortingColumn === 'state'}
								title={i18next.t('loc:Stav')}
								onSort={onSort}
								initDirection={sortingDirection}
								className={'text-center'}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!portions.isLoading && get(portions, 'data.portions') && !get(portions, 'data.portions.length') &&
				<EmptyTableState title={locale['page.codePortions.none']}/>}
				<div className={cx('table-loading', { hidden: !portions.isLoading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.codePortions.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default CodePortionsTable
