import React from 'react'
import ReactQueryParams from 'react-query-params'
import * as PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
	reduce, get, map, every, indexOf, isEmpty, findIndex, sortBy, filter, debounce
} from 'lodash'
import {
	formValueSelector, reset, initialize, change, submit
} from 'redux-form'
import { Col, Row } from 'antd'

import { faTrashAlt, faCogs, faTruck } from '@fortawesome/free-solid-svg-icons'

import { PlusOutlined } from '@ant-design/icons'

import {
	CONTAINER_TYPES, ENDPOINTS, FILTER_POSITION, FILTER, FORMS, INFO, INVALID_STATES, MODAL, UNSUITABLE_CONDITION
} from '../../utils/enums'

import locale from '../../resources/locale'

import { loadDevices } from '../../redux/devices/actions'
import { deselectAllCollections, loadCollections, toggleSelectCollections } from '../../redux/collections/actions'
import { loadContainerTypes } from '../../redux/containerTypes/actions'
import { loadCollectionRounds } from '../../redux/collectionRounds/actions'
import { loadWasteTypes } from '../../redux/wasteTypes/actions'
import { getDevicesSelectOptions } from '../../redux/devices/selectors'
import { getContainerTypesSelectOptionsWithNoTypeOption, getOneTimeContainerTypesSelectOptions } from '../../redux/containerTypes/selectors'
import { getCollectionRoundsSelectOptions } from '../../redux/collectionRounds/selectors'
import { getWasteTypesSelectOptions } from '../../redux/wasteTypes/selectors'

import { statusPush } from '../../actions/statusActions'

import { uploadFile } from '../../utils/upload'
import { listPropTypes } from '../../utils/propTypes'
import { deleteReq, patchReq, postReq } from '../../utils/request'
import { history } from '../../utils/history'
import { mergeQueryParams, normalizeQueryParams } from '../../utils/queryParams'

import Breadcrumbs from '../../components/Breadcrumb'
import DialogModal from '../../components/modals/DialogModal'
import ContainerTypeChangeModal from '../../components/modals/ContainerTypeChangeModal'
import ExportButton from '../../components/buttons/ExportButton'
import Pagination from '../../atoms/Pagination'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Table, { selectFilterProps } from '../../components/table/Table'

import { queryParams, queryTypes, columns, defaultColumns } from './constants'
import SearchInputField from '../../atoms/SearchInputField'
import ImportModal from '../../components/modals/ImportModal'
import CreateCollectionModal from './components/CreateCollectionModal'
import ChangeCollectionRoundModal from '../../components/collections/ChangeCollectionRoundModal'
import Button from '../../components/buttons/Button'

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

class CollectionsPage extends ReactQueryParams {
	static propTypes = {
		municipality: PropTypes.object,
		dispatch: PropTypes.func.isRequired,
		actions: PropTypes.shape({
			resetForm: PropTypes.func.isRequired,
			pushStatus: PropTypes.func.isRequired,
			loadCollections: PropTypes.func.isRequired,
			loadContainerTypes: PropTypes.func.isRequired,
			loadCollectionRounds: PropTypes.func.isRequired,
			loadDevices: PropTypes.func.isRequired,
			loadWasteTypes: PropTypes.func.isRequired,
			initialize: PropTypes.func.isRequired,
			change: PropTypes.func.isRequired,
			submit: PropTypes.func.isRequired,
			toggleSelectCollections: PropTypes.func.isRequired,
			deselectAllCollections: PropTypes.func.isRequired
		}),
		list: listPropTypes('collections', {
			id: PropTypes.number.isRequired,
			code: PropTypes.string,
			quantity: PropTypes.number
		}),
		listContainerTypes: PropTypes.shape(selectFilterProps).isRequired,
		selectedCollections: PropTypes.objectOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			code: PropTypes.string
		})).isRequired,
		listCollectionRounds: listPropTypes('collectionRounds', {
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		filtersValues: PropTypes.shape({
			'containerType.name': PropTypes.shape(selectFilterProps).isRequired,
			'collectionRound.name': PropTypes.shape(selectFilterProps).isRequired,
			deviceID: PropTypes.shape(selectFilterProps).isRequired,
			'containerType.wasteType': PropTypes.shape(selectFilterProps).isRequired
		})
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = reduce(queryParams, (result, item) => ({ [item]: null, ...result }), { columns: null })

		const date = new Date()
		this.state = {
			createCollection: `${date.valueOf()}`,
			modal: null,
			modalData: null
		}
	}

	componentDidMount() {
		const { actions, filters } = this.props
		this._mounted = true

		const queryFilters = normalizeQueryParams(queryTypes, mergeQueryParams(filters, this.queryParams))

		// Remove unwanted query
		history.replace(history.location.pathname, { search: null })
		this.setQueryParams(queryFilters)

		actions.initialize(FILTER.COLLECTIONS_FILTERS, queryFilters)
		this.setupCollections(queryFilters)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		if (this._mounted) {
			const { municipality, filters } = this.props
			if (prevProps.municipality?.id !== municipality?.id) {
				this.setupCollections(filters)
			}
		}
	}

	setupCollections = (filters) => {
		const { municipality, actions } = this.props

		const municipalityID = municipality ? municipality.id : undefined

		actions.deselectAllCollections()
		actions.loadCollections(this.buildFilters(filters))
		actions.loadContainerTypes({ municipalityID, limit: 'ALL' })
		actions.loadCollectionRounds({ municipalityID, limit: 'ALL', withoutCollections: true, order: 'date:DESC' })
		actions.loadDevices({ municipalityID, limit: 'ALL' })
		actions.loadWasteTypes({ municipalityID, limit: 'ALL' })
	}

	buildFilters = (filters) => {
		const { municipality } = this.props

		const municipalityID = municipality ? municipality.id : undefined
		const unsuitableConditions = Object.values(UNSUITABLE_CONDITION)

		return {
			...filters,
			municipalityID,
			state: undefined,
			columns: undefined,
			invalidStates: filter(filters.state, (state) => INVALID_STATES.includes(state)),
			unsuitableConditions: filter(filters.state, (state) => unsuitableConditions.includes(state))
		}
	}

	changeContainerType = async (values) => {
		const { filters, actions, selectedCollections } = this.props
		const { modalData } = this.state
		const data = {
			containerTypeID: values.containerTypeID
		}

		if (selectedCollections && !isEmpty(selectedCollections)) {
			data.collections = map(selectedCollections, (item) => item.id)
		} else {
			data.collections = [modalData.id]
		}
		this.dismissModal()

		try {
			await patchReq(ENDPOINTS.CHANGE_CONTAINER_TYPES, null, data)

			actions.deselectAllCollections()
			actions.loadCollections(this.buildFilters(filters))

			this.setState({
				selectedCollections: []
			})

			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	setRemovedCollection = (collection) => {
		const { filters, actions } = this.props
		return async () => {
			this.dismissModal()
			try {
				await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION(collection.municipalityID, collection.id), null)

				actions.deselectAllCollections()
				actions.loadCollections(this.buildFilters(filters))
				this.setState({
					selectedCollections: []
				})

				actions.pushStatus({
					type: INFO,
					msg: locale['page.collections.delete.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	setRemovedSelectedCollections = async () => {
		const { filters, actions, municipality, selectedCollections } = this.props
		this.dismissModal()
		const data = {
			collectionIDs: map(selectedCollections, (item) => item.id)
		}
		try {
			await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTIONS(get(municipality, 'id')), null, data)

			actions.loadCollections(this.buildFilters(filters))
			actions.deselectAllCollections()

			this.setState({
				selectedCollections: []
			})
			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.delete.selected.success']
			})
		} catch (error) {
			// Error
		}
	}

	syncSelectedCollections = async () => {
		const { filters, actions, selectedCollections } = this.props

		this.dismissModal()
		try {
			await postReq(ENDPOINTS.SYNC_COLLECTIONS, null, { collections: map(selectedCollections, (item) => item.id) })

			actions.loadCollections(this.buildFilters(filters))
			actions.deselectAllCollections()

			this.setState({
				selectedCollections: []
			})

			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.sync.success']
			})
		} catch (error) {
			// Error
		}
	}

	syncCollection = (collection) => {
		const { filters, actions } = this.props

		return async () => {
			this.dismissModal()
			try {
				await postReq(ENDPOINTS.SYNC_COLLECTIONS, null, { collections: [collection.id] })

				actions.loadCollections(this.buildFilters(filters))
				actions.deselectAllCollections()

				this.setState({
					selectedCollections: []
				})

				actions.pushStatus({
					type: INFO,
					msg: locale['page.collections.sync.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	getCollectionActions = (collection) => {
		const deleteTitle = this.props.isSelecting ? locale['common.delete.selected'] : locale['common.delete']
		const changeContainerTypeTitle = locale['common.changeContainerType.selected']

		const items = [{
			key: 'common.delete',
			title: deleteTitle,
			icon: faTrashAlt,
			callback: () => this.openModal(MODAL.REMOVE_COLLECTION)(collection)
		}]

		if (this.props.municipality) {
			const title = this.props.isSelecting ? locale['page.collections.set.collection.round'] : locale['page.collections.collection.round']
			return [{
				key: 'common.changeContainerType',
				title: changeContainerTypeTitle,
				icon: faCogs,
				callback: () => this.openModal(MODAL.CHANGE_CONTAINER_TYPE)(collection)
			}, {
				key: 'collection.round',
				title,
				icon: faTruck,
				callback: () => this.openModal(MODAL.COLLECTION_SET_COLLECTION_ROUND)(collection)
			}, ...items]
		}

		return items
	}

	getSelectedCollectionsIDs = () => {
		const { modalData } = this.state
		const { selectedCollections } = this.props

		if (isEmpty(selectedCollections) && modalData?.id) {
			return [modalData.id]
		}

		if (!isEmpty(selectedCollections)) {
			return map(selectedCollections, (item) => item.id)
		}

		return []
	}

	createCollection = (created) => {
		if (created) {
			const { filters, actions } = this.props

			actions.deselectAllCollections()
			actions.loadCollections(this.buildFilters(filters))
		}

		this.dismissModal()
	}

	resetCreateCollection = () => {
		const { actions } = this.props
		actions.resetForm(FORMS.CREATE_COLLECTION)
		const date = new Date()
		this.setState({
			createCollection: `${date.valueOf()}`
		})
	}

	openCollectionDetail = (collection) => {
		const { municipality } = this.props
		if (municipality) {
			history.push(locale.formatString(locale['path.municipality.collections.detail'], municipality.id, collection.id))
		} else {
			history.push(locale.formatString(locale['path.registry.collections.detail'], collection.id))
		}
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	handleDismissCollectionRoundModal = () => {
		const { filters, actions } = this.props

		this.dismissModal()

		actions.deselectAllCollections()
		actions.loadCollections(this.buildFilters(filters))
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.registry.breadcrumbs',
			name: locale['page.registry.breadcrumbs']
		}, {
			key: 'page.collections.breadcrumbs',
			name: locale['page.collections.breadcrumbs']
		}]
	}

	onFilter = (values) => {
		const { actions } = this.props
		const query = normalizeQueryParams(queryTypes, values)
		this.setQueryParams(query)
		actions.initialize(FILTER.COLLECTIONS_FILTERS, query)
		actions.deselectAllCollections()
		actions.loadCollections(this.buildFilters(query))
	}

	onResetFilters = () => {
		const { filters } = this.props
		const defaultFilters = {
			columns: filters.columns,
			order: filters.order,
			limit: filters.limit,
			page: 1
		}

		const newFilters = reduce(filters, (result, filter, key) => ({
			...result,
			[key]: defaultFilters[key] || null
		}), {})

		this.onFilter(newFilters)
	}

	onChangeFilter = (filter) => (value) => {
		const { actions, filters } = this.props
		actions.change(FILTER.COLLECTIONS_FILTERS, filter, value)
		this.onFilter({ ...filters, [filter]: value })
	}

	onChangeColumns = (columns) => {
		const { filters } = this.props
		const query = normalizeQueryParams(queryTypes, { ...filters, columns })
		this.setQueryParams(query)
	}

	getSelectedColumns = () => {
		const { filters, filtersValues } = this.props

		const sortedColumns = sortBy(filters.columns, (item) => findIndex(columns, { name: item }))

		return reduce(columns, (result, item) => {
			const filterIndex = indexOf(sortedColumns, item.name)

			if (filterIndex >= 0) {
				if (filtersValues[item.name]) {
					item.filter = {
						...item.filter,
						...filtersValues[item.name],
						position: FILTER_POSITION.RIGHT
					}
				}

				if (filterIndex >= sortedColumns.length - 2) {
					item.filter.position = FILTER_POSITION.LEFT
				}

				return [
					...result,
					item
				]
			}
			return result
		}, [])
	}

	debounceSearch = debounce((search) => {
		const { filters } = this.props

		this.onFilter({ ...filters, page: 1, search })
	}, 150)

	onSearch = (search) => {
		const { dispatch } = this.props
		dispatch(change(FILTER.COLLECTIONS_FILTERS, 'search', search))
		dispatch(change(FILTER.COLLECTIONS_FILTERS, 'page', 1))
		this.debounceSearch(search)
	}

	importCollections = async (file) => {
		const { actions, municipality } = this.props
		try {
			this.dismissModal()
			actions.pushStatus({
				type: INFO,
				msg: 'Načítavam súbor'
			})

			let query = ''

			if (municipality) {
				query = `?municipalityID=${municipality.id}`
			}

			await uploadFile(file, {}, file.name, `/api/v1/collections/import${query}`)
			actions.pushStatus({
				type: INFO,
				msg: locale['page.municipalities.detail.import.success']
			})
		} catch (error) {
			// Error
		}
	}

	render() {
		const { municipality, list, listContainerTypes, filters, selectedCollections, actions } = this.props
		const { modal, modalData } = this.state

		const selectedList = Object.values(selectedCollections)

		const deleteTitle = selectedList.length
			? locale['page.collections.delete.selected.title']
			: locale.formatString(locale['page.collections.delete.title'], get(modalData, 'name', ''))

		const canChangeContainerType = selectedList.length
			? every(selectedList, (item) => get(item, 'containerType.type') === CONTAINER_TYPES.ONE_TIME)
			: modalData && get(modalData, 'containerType.type') === CONTAINER_TYPES.ONE_TIME

		const changeContainerTypeTitle = selectedList.length
			? locale['page.collections.change.container.type.selected.title']
			: locale.formatString(locale['page.collections.change.container.type.selected.title'], get(modalData, 'name', ''))

		const deleteMessage = selectedList.length
			? locale['page.collections.delete.selected.message']
			: locale['page.collections.delete.message']

		const changeContainerTypeMessage = canChangeContainerType
			? locale['page.collections.change.container.type.selected.message']
			: locale['page.collections.change.container.type.one.time.only']

		const deleteAction = selectedList.length
			? this.setRemovedSelectedCollections
			: this.setRemovedCollection(modalData)

		const syncAction = selectedList.length
			? this.syncSelectedCollections
			: this.syncCollection(modalData)

		const syncMessage = locale['page.collections.sync.message']

		const municipalityID = municipality ? municipality.id : undefined

		const selectedColumns = this.getSelectedColumns()

		return (
			<>
				<Breadcrumbs items={this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.collections.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={[0, 16]} justify={'space-between'}>
								<Col>
									<Row gutter={16}>
										<Col>
											<SearchInputField
												name={'search'}
												onChange={this.onSearch}
												value={filters.search || ''}
											/>
										</Col>
										<Col>
											<ExportButton
												url={ENDPOINTS.COLLECTIONS_EXPORT}
												context={{ ...this.buildFilters(filters), columns: map(selectedColumns, (col) => col.name), municipalityID }}
											/>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row gutter={16}>
										<Col>
											<Button
												onClick={this.openModal(MODAL.IMPORT_COLLECTIONS)}
											>
												Import záznamov
											</Button>
										</Col>
										<Col>
											<Button
												onClick={this.openModal(MODAL.CREATE_COLLECTION)}
												disabled={!municipality}
											>
												<PlusOutlined/>
												<span>{locale['page.collections.add']}</span>
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<Table
										onSelect={(items) => actions.toggleSelectCollections(items)}
										onResetFilters={this.onResetFilters}
										selectedItems={selectedCollections}
										getActions={this.getCollectionActions}
										onDetail={this.openCollectionDetail}
										name={FILTER.COLLECTIONS_FILTERS}
										columns={selectedColumns}
										tableColumns={tableColumns}
										defaultColumns={defaultColumns}
										filters={filters}
										isLoading={list.isLoading}
										isFailure={list.isFailure}
										isSelectable={!!municipality}
										data={get(list, 'data.collections')}
										onSort={this.onChangeFilter(queryParams.order)}
										onFilter={this.onFilter}
										onChangeColumns={this.onChangeColumns}
									/>
									<ListItemsLimit
										limit={filters.limit}
										onLimit={this.onChangeFilter(queryParams.limit)}
									/>
									{selectedList.length > 0 &&
									<>
										<Row gutter={16} style={{ marginTop: 16, marginLeft: 32 }}>
											<Col>
												<Button
													onClick={this.openModal(MODAL.SYNC_COLLECTIONS)}
												>
													{locale['page.collections.sync.title']}
												</Button>
											</Col>
											<Col>
												<Button
													onClick={this.openModal(MODAL.CHANGE_CONTAINER_TYPE)}
												>
													{locale['page.collections.button.mass.change.container.type']}
												</Button>
											</Col>
											<Col>
												<Button
													className={'danger'}
													onClick={this.openModal(MODAL.REMOVE_COLLECTION)}
												>
													{locale['page.collections.button.mass.change.delete']}
												</Button>
											</Col>
										</Row>
									</>}
									<Pagination
										pages={get(list, 'data.context.pages')}
										page={get(list, 'data.context.page')}
										onPage={this.onChangeFilter(queryParams.page)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION}
					cancelHandler={this.dismissModal}
					acceptHandler={deleteAction}
					message={deleteMessage}
					title={deleteTitle}
					acceptTitle={locale['page.collections.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.SYNC_COLLECTIONS}
					cancelHandler={this.dismissModal}
					acceptHandler={syncAction}
					message={syncMessage}
					title={locale['page.collections.sync.title']}
					acceptTitle={locale['page.collections.sync.accept']}
				/>
				{municipality &&
				<ContainerTypeChangeModal
					shown={modal === MODAL.CHANGE_CONTAINER_TYPE}
					cancelHandler={this.dismissModal}
					message={changeContainerTypeMessage}
					title={changeContainerTypeTitle}
					acceptTitle={locale['page.collections.change.container.accept']}
					municipality={municipality}
					selectOptions={get(listContainerTypes, 'values', [])}
					showError={!canChangeContainerType}
					onSubmit={this.changeContainerType}
				/>}

				{!!municipality &&
				<ChangeCollectionRoundModal
					visible={modal === MODAL.COLLECTION_SET_COLLECTION_ROUND}
					onClose={this.handleDismissCollectionRoundModal}
					getCollections={this.getSelectedCollectionsIDs}
					municipality={municipality}
				/>}

				{!!municipality &&
				<CreateCollectionModal
					visible={modal === MODAL.CREATE_COLLECTION}
					onClose={this.createCollection}
				/>}
				<ImportModal
					title={'Import záznamov'}
					shown={modal === MODAL.IMPORT_COLLECTIONS}
					dismissHandler={this.dismissModal}
					createHandler={this.importCollections}
				/>
			</>
		)
	}
}

const filtersSelector = formValueSelector(FILTER.COLLECTIONS_FILTERS)

const mapStateToProps = (state) => {
	const collectionRoundsSelectOptions = getCollectionRoundsSelectOptions(state)

	return {
		list: state.collections.list,
		listContainerTypes: getOneTimeContainerTypesSelectOptions(state),
		selectedCollections: state.collections.selectedCollections,
		filtersValues: {
			'containerType.name': getContainerTypesSelectOptionsWithNoTypeOption(state),
			'collectionRound.name': {
				...collectionRoundsSelectOptions,
				values: [{
					value: 0,
					label: locale['page.collections.no.collectionRound']
				}, ...collectionRoundsSelectOptions.values]
			},
			deviceID: getDevicesSelectOptions(state),
			'containerType.wasteType': getWasteTypesSelectOptions(state)
		},
		filters: filtersSelector(state, ...Object.values(queryParams)),
		municipality: get(state, 'selectedMunicipality.municipality.data')
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	actions: {
		loadDevices: bindActionCreators(loadDevices, dispatch),
		loadWasteTypes: bindActionCreators(loadWasteTypes, dispatch),
		loadCollections: bindActionCreators(loadCollections, dispatch),
		loadContainerTypes: bindActionCreators(loadContainerTypes, dispatch),
		loadCollectionRounds: bindActionCreators(loadCollectionRounds, dispatch),
		initialize: bindActionCreators(initialize, dispatch),
		resetForm: bindActionCreators(reset, dispatch),
		change: bindActionCreators(change, dispatch),
		submit: bindActionCreators(submit, dispatch),
		pushStatus: bindActionCreators(statusPush, dispatch),
		toggleSelectCollections: bindActionCreators(toggleSelectCollections, dispatch),
		deselectAllCollections: bindActionCreators(deselectAllCollections, dispatch)
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage)
