import React, { useCallback, useMemo } from 'react'
import i18next from 'i18next'
import dayjs from 'dayjs'

import { filter, find } from 'lodash'

import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { CopyOutlined } from '@ant-design/icons'

import { MotivationModel } from '../../../types/data'

import { EmptyState, HeadingFive } from '../../../components/Typography'
import Table, { TableActions } from '../../../components/Table'
import Button from '../../../components/buttons/Button'

type Props = {
	motivationModels: MotivationModel[]

	onDuplicate: (model: MotivationModel) => void
	onUpdate: (model: MotivationModel) => void
}

const formatActiveModel = (model: MotivationModel) => {
	const name = i18next.t(`MOTIVATION_MODEL.${model.type}`)

	if (model.validTo) {
		return `${name} (${i18next.t('MunicipalityPage.validModelTo', { validTo: dayjs(model.validTo).format(i18next.t('dateFormat')) })})`
	}

	return `${name} (${i18next.t('MunicipalityPage.validModelToNone')}`
}

const formatModel = (model: MotivationModel) => {
	const name = dayjs(model.validFrom).format(i18next.t('dateFormat'))

	if (model.validTo) {
		return `${name} - ${dayjs(model.validTo).format(i18next.t('dateFormat'))} ${i18next.t(`MOTIVATION_MODEL.${model.type}`)}`
	}

	return `${name} - ${i18next.t('MunicipalityPage.validModelToNone')} ${i18next.t(`MOTIVATION_MODEL.${model.type}`)}`
}

const MotivationModelsTable = ({ motivationModels, onUpdate, onDuplicate }: Props) => {
	const [t] = useTranslation()

	const activeModel = useMemo(() => find(motivationModels, (model) => {
		const now = dayjs()

		return dayjs(model.validFrom) < now && (!model.validTo || dayjs(model.validTo) > now)
	}), [motivationModels])

	const filteredModels = useMemo(() => activeModel
		? filter(motivationModels, (model) => model.id !== activeModel.id)
		: motivationModels,
	[motivationModels, activeModel])

	const columns = useMemo(() => [{
		key: 'offset',
		title: '',
		width: 16
	}, {
		key: 'name',
		title: '',
		render: (value: MotivationModel) => formatModel(value)
	}, {
		key: 'actions',
		width: 50,
		render: (value: MotivationModel) => (
			<TableActions
				actions={[{
					icon: <CopyOutlined/>,
					title: t('MunicipalityPage.duplicate'),
					callback: () => onDuplicate(value)
				}]}
			/>
		)
	}], [onDuplicate, t])

	const onRow = useCallback((model) => ({
		onClick: () => onUpdate(model)
	}), [onUpdate])

	return (
		<Row>
			{!motivationModels.length &&
			<Col span={24}>
				<EmptyState>
					{t('MunicipalityPage.noMotivationModels')}
				</EmptyState>
			</Col>}
			{!!activeModel &&
			<>
				<Col flex={1} style={{ padding: '32px 16px 16px 32px' }}>
					<HeadingFive>
						{t('MunicipalityPage.activeModel')}
					</HeadingFive>
					<span>{formatActiveModel(activeModel)}</span>
				</Col>
				<Col style={{ padding: '32px 16px 16px 32px' }}>
					<Button className={'secondary'} onClick={() => onUpdate(activeModel)}>
						{t('MunicipalityPage.updateModel')}
					</Button>
				</Col>
			</>}
			{!!filteredModels.length &&
			<>
				<Col span={24} style={{ paddingBottom: 32 }}>
					<HeadingFive style={{ padding: '32px 16px 16px 32px' }}>
						{t('MunicipalityPage.modelsHistory')}
					</HeadingFive>
					<Table
						rowKey={'id'}
						className={'without-header'}
						columns={columns}
						dataSource={filteredModels}
						pagination={false}
						onRow={onRow}
					/>
				</Col>
			</>}
		</Row>
	)
}

export default React.memo(MotivationModelsTable)
