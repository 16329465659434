import React from 'react'
import PropTypes from 'prop-types'
import { map, uniqBy } from 'lodash'

class CalendarLegend extends React.Component {
	static propTypes = {
		events: PropTypes.arrayOf(PropTypes.object)
	}

	render() {
		const { events } = this.props
		const uniqueItems = uniqBy(events, 'name')
		return (
			<div className={'row'}>
				{map(uniqueItems, (item, index) =>
					<div key={index} className={'col-md-3'}>
						<div className={'dot'} style={{ backgroundColor: `#${item.color}` }}/>
						<span>{item.name}</span>
					</div>)}
			</div>
		)
	}
}

export default CalendarLegend
