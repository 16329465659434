import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { Col, Dropdown, Menu, Row } from 'antd'

import { DownOutlined } from '@ant-design/icons'

import { statistics } from '../../../api'

import { createYearsList } from '../../../utils/helpers'

import { useDataLoader } from '../../../hooks/useDataLoader'

import Button from '../../../components/buttons/Button'
import SpinLoading from '../../../components/SpinLoading'

import { SubHeading, Text, TextSmall } from '../../../components/Typography'
import { history } from '../../../utils/history'

const years = createYearsList()

const RowText = styled(Text)`
	margin: 12px 0;
`

const ResultText = styled(RowText)`
	&.best {
		color: ${({ theme }) => theme.colors.primary['900']};
	}

	&.worst {
		color: ${({ theme }) => theme.colors.error};
	}
`

const Divider = styled.div`
	width: 1px;
	content: "";
	height: 80%;
	margin-top: 40px;
	background: ${({ theme }) => theme.colors.neutral['100']};
`

const ListHeading = styled(TextSmall)`
	margin-bottom: 16px;
`

type Props = {
	municipalityID?: number
}

const HouseholdsSummary = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const [year, setYear] = useState(() => dayjs().year().toString())
	const [summaryState, loadSummary] = useDataLoader(statistics.loadHouseholdsSummary)

	useEffect(() => {
		loadSummary({ year, municipalityID })
	}, [year, municipalityID, loadSummary])

	let loader

	if (summaryState.isLoading) {
		loader = <SpinLoading height={'220px'}/>
	}

	const handleClickHousehold = useCallback((collectionPlaceID: number) => {
		if (municipalityID) {
			history.push(t('paths:LISTS.collectionPlaces.detail.path', { collectionPlaceID, municipalityID }))
		} else {
			history.push(t('paths:LISTS.collectionPlaces.detail.path', { collectionPlaceID }))
		}
	}, [municipalityID, t])

	return (
		<Row justify={'space-between'} gutter={[16, 16]}>
			<Col>
				<SubHeading>{t('DashboardPage.topHouseholds')}</SubHeading>
			</Col>
			<Col>
				<Dropdown
					overlay={(
						<Menu selectedKeys={[year]}>
							{map(years, (year) => (
								<Menu.Item
									key={year}
									onClick={() => setYear(year.toString())}
								>
									{year}
								</Menu.Item>
							))}
						</Menu>
					)}
				>
					<Button className={'secondary center'} style={{ width: 75 }}>
						<span>{year}</span>
						<DownOutlined/>
					</Button>
				</Dropdown>
			</Col>
			<Col span={24}>
				<Row gutter={32}>
					<Col flex={1}>
						<ListHeading className={'bold'}>{t('DashboardPage.bestHouseholds')}</ListHeading>
						{summaryState.isLoading && loader}
						{map(summaryState.data?.best, (household, index) => (
							<Row
								key={household.id}
								justify={'space-between'}
								className={'cursor-pointer'}
								wrap={false}
								gutter={8}
								onClick={() => handleClickHousehold(household.id)}
							>
								<Col>
									<RowText>{index + 1}. {household.name}</RowText>
								</Col>
								<Col>
									<ResultText className={'best'}>{household.rate.toFixed(2)}%</ResultText>
								</Col>
							</Row>
						))}
					</Col>
					<Col>
						<Divider/>
					</Col>
					<Col flex={1}>
						<ListHeading className={'bold'}>{t('DashboardPage.worstHouseholds')}</ListHeading>
						{summaryState.isLoading && loader}
						{map(summaryState.data?.worst, (household, index) => (
							<Row
								key={household.id}
								justify={'space-between'}
								className={'cursor-pointer'}
								wrap={false}
								gutter={8}
								onClick={() => handleClickHousehold(household.id)}
							>
								<Col>
									<RowText>{index + 1}. {household.name}</RowText>
								</Col>
								<Col>
									<ResultText className={'worst'}>{household.rate.toFixed(2)}%</ResultText>
								</Col>
							</Row>
						))}
					</Col>
					<Col>
						<Divider/>
					</Col>
					<Col flex={1}>
						<ListHeading className={'bold'}>{t('DashboardPage.topMunicipalWasteProducers')}</ListHeading>
						{summaryState.isLoading && loader}
						{map(summaryState.data?.largestZKOProducers, (household, index) => (
							<Row
								key={household.id}
								justify={'space-between'}
								className={'cursor-pointer'}
								wrap={false}
								gutter={8}
								onClick={() => handleClickHousehold(household.id)}
							>
								<Col>
									<RowText>{index + 1}. {household.name}</RowText>
								</Col>
								<Col>
									<ResultText className={'worst'}>{household.municipal.toFixed(2)} kg</ResultText>
								</Col>
							</Row>
						))}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default React.memo(HouseholdsSummary)
