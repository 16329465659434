import React from 'react'
import cx from 'classnames'

import * as PropTypes from 'prop-types'
import { get } from 'lodash'

import { faPrint, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import { formatDatetime } from '../../../utils/utils'
import { CODE_PORTION_STATES } from '../../../utils/enums'

import locale from '../../../resources/locale'
import PopupTool from '../../../atoms/PopupTool'

class CodePortionTableRow extends React.Component {
	static propTypes = {
		item: PropTypes.shape({
			id: PropTypes.number.isRequired,
			containersCount: PropTypes.string.isRequired,
			municipality: PropTypes.string,
			createdAt: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired
		}),
		countIndex: PropTypes.number,
		onDetail: PropTypes.func.isRequired,
		onUpdate: PropTypes.func.isRequired,
		onDownload: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired
	}

	renderPopup = () => {
		const { item, onUpdate, onDownload, onDelete } = this.props

		const items = [{
			key: 'common.download',
			title: locale['common.download'],
			icon: faDownload,
			callback: () => onDownload(item)
		}]

		if (item.state === CODE_PORTION_STATES.CREATED) {
			items.push({
				key: 'common.print',
				title: locale['common.print'],
				icon: faPrint,
				callback: () => onUpdate(item)
			})
		}

		items.push({
			key: 'common.delete',
			title: locale['common.delete'],
			icon: faTrash,
			callback: () => onDelete(item)
		})

		return <PopupTool items={items} />
	}

	render() {
		const { item, countIndex, onDetail } = this.props
		return (
			<tr onClick={() => onDetail(item)} className={cx('', { created: item.state === CODE_PORTION_STATES.CREATED, printed: item.state === CODE_PORTION_STATES.PRINTED })}>
				<td>{formatDatetime(get(item, 'createdAt'))}</td>
				<td>{get(item, 'municipality') || '-'}</td>
				<td>{get(item, 'containersCount')}</td>
				<td className={'text-left'}>{get(item, 'note') || '-'}</td>
				<td className={'text-center'}>{locale[`codePortion.state.${get(item, 'state')}`]}</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					{this.renderPopup()}
				</td>
			</tr>
		)
	}
}

export default CodePortionTableRow
