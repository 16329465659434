import {
	LOADING_START,
	LOADING_END
} from '../types/loading'
import { RESET_STORE } from '../types/global'

export const initState = {
	visible: false
}

export default (state = initState, action) => {
	switch (action.type) {
		case LOADING_START:
			return {
				visible: true,
				message: action.payload.message
			}
		case LOADING_END:
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
