import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { CONTAINER_TYPES_ACTIONS } from './constants'

export const containerTypesLoadStart = {
	type: CONTAINER_TYPES_ACTIONS.LIST_LOAD_START
}

export const containerTypesLoadFail = {
	type: CONTAINER_TYPES_ACTIONS.LIST_LOAD_FAIL
}

export const containerTypesLoadDone = (data) => ({
	type: CONTAINER_TYPES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadContainerTypes = (filters) => async (dispatch) => {
	dispatch(containerTypesLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.CONTAINER_TYPES, query)

		dispatch(containerTypesLoadDone(response.data))
	} catch (e) {
		dispatch(containerTypesLoadFail)
	}
}
