import Joi from 'joi-browser'
import { each, reduce, join } from 'lodash'
import moment from 'moment'

import locale from '../resources/locale'

export const validatorOptions = {
	EMAIL: 'EMAIL',
	REQUIRED_STRING: 'REQUIRED_STRING',
	REQUIRED_NUMBER: 'REQUIRED_NUMBER',
	REQUIRED_ANY_NUMBER: 'REQUIRED_ANY_NUMBER',
	ARRAY: 'ARRAY',
	REQUIRED: 'REQUIRED',
	PASSWORD_LENGTH: 'PASSWORD_LENGTH',
	PASSWORD_UPPERCASE: 'PASSWORD_UPPERCASE',
	PASSWORD_LOWERCASE: 'PASSWORD_LOWERCASE',
	PASSWORD_NUMBER: 'PASSWORD_NUMBER',
	PASSWORD_SPECIAL: 'PASSWORD_SPECIAL',
	NUMBER: 'NUMBER',
	PERCENT: 'PERCENT',
	MAX_NUMBER: 'MAX_NUMBER',
	ZIP: 'ZIP',
	VAT: 'VAT',
	DATE: 'DATE',
	LAT: 'LAT',
	LON: 'LON',
	MOTIVATION_MODEL_COLLECTIONS_COUNT: 'MOTIVATION_MODEL_COLLECTIONS_COUNT',
	MOTIVATION_MODEL_CAPACITY: 'MOTIVATION_MODEL_CAPACITY',
	MOTIVATION_MODEL_PRICE: 'MOTIVATION_MODEL_PRICE',
	MOTIVATION_MODEL_DISCOUNT: 'MOTIVATION_MODEL_DISCOUNT',
	MOTIVATION_MODEL_MAX_DISCOUNT: 'MOTIVATION_MODEL_MAX_DISCOUNT',
	ADDRESS_NUMBER: 'ADDRESS_NUMBER'
}

const validators = {
	REQUIRED: Joi.any().invalid(null).required(),
	REQUIRED_STRING: Joi.string().min(1).required(),
	REQUIRED_NUMBER: Joi.number().min(0).required(),
	ADDRESS_NUMBER: Joi.number().min(1).allow('', null),
	MAX_NUMBER: Joi.number().max(999999999),
	REQUIRED_ANY_NUMBER: Joi.number().required(),
	ARRAY: Joi.array().min(1).required(),
	EMAIL: Joi.string().email(),
	NUMBER: Joi.number().allow(null),
	PERCENT: Joi.number().min(0).max(100),
	VAT: Joi.string().regex(/^[0-9]{8}$/),
	PASSWORD_LENGTH: Joi.string().min(8),
	PASSWORD_UPPERCASE: Joi.string().regex(/[A-Z]/),
	PASSWORD_LOWERCASE: Joi.string().regex(/[a-z]/),
	PASSWORD_NUMBER: Joi.string().regex(/[0-9]/),
	PASSWORD_SPECIAL: Joi.string().regex(/[^a-zA-Z\d\s]/),
	ZIP: Joi.string().regex(/^[0-9]{3}\s?[0-9]{2}$/).allow(null, ''),
	DATE: Joi.date().allow(null),
	MOTIVATION_MODEL_COLLECTIONS_COUNT: Joi.number().integer().strict().min(1),
	MOTIVATION_MODEL_CAPACITY: Joi.number().precision(2).strict().min(0).less(1000000),
	MOTIVATION_MODEL_PRICE: Joi.number().precision(2).strict().min(0).less(1000),
	MOTIVATION_MODEL_DISCOUNT: Joi.number().precision(3).strict().min(0).less(1),
	MOTIVATION_MODEL_MAX_DISCOUNT: Joi.number().integer().strict().min(0).max(100),
	LAT: Joi.number().min(-90).max(90).allow(null),
	LON: Joi.number().min(-180).max(180).allow(null)
}

function validateValue(value, schema) {
	if (schema) {
		return schema.validate(value)
	}
	return { error: null }
}

export function validate(options) {
	return (value) => {
		const messages = []
		each(options, (item) => {
			const schema = validators[item]
			if (schema) {
				const result = validateValue(value, schema)
				if (result.error) {
					messages.push(locale[`error.validation.${item}`] || locale['error.validation.DEFAULT'])
				}
			}
		})
		return join(messages, ' ')
	}
}

export function validateSelect(options) {
	return (value) => {
		const messages = []
		each(options, (item) => {
			const schema = validators[item]
			if (schema) {
				const result = validateValue(value, schema)
				if (result.error) {
					messages.push(locale[`error.validation.${item}`] || locale['error.validation.DEFAULT'])
				}
			}
		})
		return join(messages, ' ')
	}
}

export function hasError(errors) {
	return reduce(errors, (result, value) => result || !!value, false)
}

export const normalizeDate = (value) => {
	const date = moment(value)

	if (value && date.isValid()) {
		return date.startOf('day').toDate()
	}
	return value
}
