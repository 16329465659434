import React, { useCallback, useMemo, useState } from 'react'
import i18next from 'i18next'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Col, Row } from 'antd'

import { MunicipalityDetail } from '../types/data'
import { MESSAGE_TYPE } from '../types/enums'
import { MODAL } from '../utils/enums'

import { municipalities } from '../api'

import { statusPush } from '../actions/statusActions'

import Button from './buttons/Button'

import { Modal, ModalContent, ModalFooter, ModalHead } from './Modal'
import { InputWrapper, Label, UploadDragger } from './form/Styled'

const Placeholder = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: ${({ theme }) => theme.colors.inputPlaceholder}
`

const FileName = styled(Placeholder)`
	color: ${({ theme }) => theme.colors.input}
`

const getAction = (key?: MODAL | null) => {
	switch (key) {
		case MODAL.IMPORT_CUSTOMERS:
			return { api: municipalities.importCustomers, title: i18next.t('MunicipalityPage.importCustomers') }
		case MODAL.IMPORT_CALENDAR:
			return { api: municipalities.importCalendar, title: i18next.t('MunicipalityPage.importCalendar') }
		case MODAL.IMPORT_MUNICIPALITY:
			return { api: municipalities.importMunicipality, title: i18next.t('MunicipalityPage.importMunicipality') }
		case MODAL.IMPORT_CONTAINERS:
			return { api: municipalities.importContainers, title: i18next.t('MunicipalityPage.importContainers') }
		case MODAL.IMPORT_MIGRATION_PZO:
			return { api: municipalities.importMigrationPZO, title: i18next.t('ToolsPage.importFile') }
		default:
			return null
	}
}

type Props = {
	modalKey?: MODAL | null
	onClose: () => void

	municipality?: MunicipalityDetail
}

const ImportModal = ({ onClose, modalKey, municipality }: Props) => {
	const [t] = useTranslation()

	const [file, setFile] = useState<File | null>(null)
	const [submitting, setSubmitting] = useState(false)

	const dispatch = useDispatch()
	const action = useMemo(() => getAction(modalKey), [modalKey])

	const handleSelectFile = useCallback((file: File) => {
		setFile(file)
		return false
	}, [])

	const handleClose = useCallback(() => {
		setFile(null)
		onClose()
	}, [onClose])

	const handleSubmit = async () => {
		if (action && file) {
			try {
				setSubmitting(true)

				await action.api(municipality?.id || 0, file, file.name)

				dispatch(statusPush({
					type: MESSAGE_TYPE.SUCCESS,
					msg: t('ImportModal.uploadSuccess')
				}))

				setFile(null)
				onClose()
			} catch (e) {
				// Error
			} finally {
				setSubmitting(false)
			}
		}
	}

	return (
		<Modal
			onCancel={handleClose}
			visible={!!action}
			footer={null}
		>
			<ModalHead>
				{action?.title}
			</ModalHead>
			<ModalContent className={'padded'}>
				<InputWrapper>
					<Label>{t('ImportModal.file')}</Label>
					<UploadDragger
						type={'drag'}
						multiple={false}
						fileList={[]}
						beforeUpload={handleSelectFile}
						accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'}
					>
						{!!file && <FileName>{file.name}</FileName>}
						{!file && <Placeholder>{t('ImportModal.placeholder')}</Placeholder>}
					</UploadDragger>
				</InputWrapper>
			</ModalContent>
			<ModalFooter>
				<Row justify={'end'} gutter={[16, 8]}>
					<Col>
						<Button
							className={'secondary'}
							onClick={(e) => {
								e.preventDefault()
								handleClose()
							}}
						>
							{t('common.close')}
						</Button>
					</Col>
					<Col>
						<Button
							loading={submitting}
							disabled={submitting || !file}
							onClick={handleSubmit}
						>
							{t('common.submit')}
						</Button>
					</Col>
				</Row>
			</ModalFooter>
		</Modal>
	)
}

export default React.memo(ImportModal)
