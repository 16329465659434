import styled from 'styled-components'

export const HeadingOne = styled.h1`
	font-size: 42px;
	line-height: 56px;
	font-weight: bold;
`

export const SubHeading = styled.h6`
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;

	color: ${({ theme }) => theme.colors.neutral['900']};
	
	&.grey {
		color: ${({ theme }) => theme.colors.neutral['400']}
	}
`

export const HeadingTwo = styled.h2`
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
`

export const HeadingThree = styled.h3`
	font-size: 27px;
	line-height: 32px;
	font-weight: bold;
`

export const HeadingFour = styled.h2`
	font-size: 22px;
	line-height: 30px;
	font-weight: bold;
`

export const HeadingFive = styled.h2`
	font-size: 12px;
	line-height: 18px;
	font-weight: bold;
`

export const TextLarge = styled.p`
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	
	&.grey {
		color: #A6A6A6;
	}
`

export const Text = styled.p`
	font-size: 14px;
	line-height: 20px;
	
	&.grey {
		color: ${({ theme }) => theme.colors.neutral['500']};
	}
`

export const TextTiny = styled.p`
	margin: 0;
	font-size: 0.75rem;
	line-height: 150%;
`

export const TextSmall = styled.p`
	margin: 0;
	font-size: 12px;
	line-height: 15px;
`

export const EmptyState = styled.p`
	padding: 32px;
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.neutral[200]};
	margin: 0;
`
