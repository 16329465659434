import React, { PureComponent } from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Field, fieldPropTypes } from 'redux-form'

import locale from '../../resources/locale'
import { FILTER_POSITION, FILTER_POSITIONS } from '../../utils/enums'
import Button from '../buttons/Button'
import { Input } from '../form/Styled'

const NumberField = ({ input, placeholder }) => (
	<Input
		{...input}
		type={'number'}
		placeholder={placeholder}
	/>
)

NumberField.propTypes = {
	...fieldPropTypes,
	placeholder: PropTypes.string
}

class NumberRangeFilter extends PureComponent {
	static propTypes = {
		name: PropTypes.string.isRequired,
		onFilter: PropTypes.func.isRequired,
		onReset: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
		visible: PropTypes.bool.isRequired,
		position: PropTypes.oneOf(FILTER_POSITIONS)
	}

	node = React.createRef()

	render() {
		const { onFilter, onReset, title, visible, name, position } = this.props

		return (
			<div
				ref={this.node}
				className={cx('table-filter', { visible, left: position === FILTER_POSITION.LEFT })}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={'row'}>
					<div className={'col-6 title'}>{title}</div>
					<div className={'col-6 reset'}>
						<span onClick={onReset}>{locale['common.reset']}</span>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-6'}>
						<Field
							name={`${name}From`}
							component={NumberField}
							props={{
								placeholder: locale['field.placeholder.from']
							}}
						/>
					</div>
					<div className={'col-6'}>
						<Field
							name={`${name}To`}
							component={NumberField}
							props={{
								placeholder: locale['field.placeholder.to']
							}}
						/>
					</div>
				</div>
				<div className={'row'} style={{ marginTop: 16 }}>
					<div className={'col-12'}>
						<Button
							className={cx('pull-right')}
							onClick={onFilter}
						>
							{locale['common.use']}
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default NumberRangeFilter
