import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	position: relative;
	text-align: left;
	margin: auto;
	padding-bottom: 25px;

	> label {
		display: block;
		position: relative;
		font-size: 12px;
		margin-bottom: 10px;
		font-weight: 600;
		color: ${({ theme }) => theme.colors.text};
		transition: all 200ms ease-in-out;
	  	a {
			text-decoration: underline;
			color: ${({ theme }) => theme.colors.primary['500']}
	 	}
	}
`

type Props = {
	title: string
	value: string | number | React.ReactNode
}

const InfoField = ({ title, value }: Props) => (
	<Wrapper>
		<label>{title}</label>
		<span>{value}</span>
	</Wrapper>
)

export default InfoField
