import React from 'react'
import ReactQueryParams from 'react-query-params'
import cx from 'classnames'

import * as PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, filter, reduce, isEmpty, map, indexOf, every, join } from 'lodash'
import { reset, initialize, formValueSelector, change, submit } from 'redux-form'
import { Tab, Tabs } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faTrashAlt, faCogs, faTruck } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

import { deleteReq, patchReq, postReq } from '../../utils/request'
import {
	COLLECTION_ROUND_TYPE,
	ENDPOINTS,
	FILTER_POSITION,
	FILTER,
	INFO,
	MODAL,
	FORMS,
	CONTAINER_TYPES, UNSUITABLE_CONDITION, INVALID_STATES
} from '../../utils/enums'
import { history } from '../../utils/history'

import { mergeQueryParams, normalizeQueryParams } from '../../utils/queryParams'
import { detailPropTypes, listPropTypes } from '../../utils/propTypes'
import Table, { selectFilterProps } from '../../components/table/Table'

import { queryParams, queryTypes, columns as collectionsColumns, defaultColumns } from '../collections/constants'

import { statusPush } from '../../actions/statusActions'
import { getContainerTypesSelectOptions, getOneTimeContainerTypesSelectOptions } from '../../redux/containerTypes/selectors'
import { getDevicesSelectOptions } from '../../redux/devices/selectors'
import { getWasteTypesSelectOptions } from '../../redux/wasteTypes/selectors'
import { loadDevices } from '../../redux/devices/actions'
import { loadWasteTypes } from '../../redux/wasteTypes/actions'
import { deselectAllCollections, loadCollections, toggleSelectCollections } from '../../redux/collections/actions'
import { loadContainerTypes } from '../../redux/containerTypes/actions'
import { loadCollectionRound } from '../../redux/collectionRounds/actions'

import ListItemsLimit from '../../atoms/ListImtesLimit'
import Pagination from '../../atoms/Pagination'
import ExportButton from '../../components/buttons/ExportButton'

import Breadcrumbs from '../../components/Breadcrumb'
import CollectionRoundForm from './components/CollectionRoundFormNew'
import DialogModal from '../../components/modals/DialogModal'
import CollectionRoundGeneralForm from './components/CollectionRoundGeneralForm'
import ContainerTypeChangeModal from '../../components/modals/ContainerTypeChangeModal'
import CollectionRoundMap from './components/CollectionRoundMap'
import CollectionRoundOverview from './components/CollectionRoundOverview'
import ChangeCollectionRoundModal from '../../components/collections/ChangeCollectionRoundModal'

const columns = filter(collectionsColumns, (col) => col.name !== 'collectionRound.name')

const tableColumns = map(columns, (col) => ({
	value: col.name,
	label: col.title
}))

class CollectionRoundPage extends ReactQueryParams {
	static propTypes = {
		municipality: PropTypes.object,
		actions: PropTypes.shape({
			resetForm: PropTypes.func.isRequired,
			pushStatus: PropTypes.func.isRequired,
			loadCollectionRound: PropTypes.func.isRequired,
			loadContainerTypes: PropTypes.func.isRequired,
			loadDevices: PropTypes.func.isRequired,
			loadWasteTypes: PropTypes.func.isRequired,
			initialize: PropTypes.func.isRequired,
			change: PropTypes.func.isRequired,
			submit: PropTypes.func.isRequired,
			toggleSelectCollections: PropTypes.func.isRequired,
			deselectAllCollections: PropTypes.func.isRequired
		}),
		detail: detailPropTypes({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired
		}),
		list: listPropTypes('collections', {
			id: PropTypes.number.isRequired,
			code: PropTypes.string,
			quantity: PropTypes.number
		}),
		listContainerTypes: PropTypes.shape(selectFilterProps).isRequired,
		selectedCollections: PropTypes.objectOf(PropTypes.shape({
			id: PropTypes.number.isRequired,
			code: PropTypes.string
		})).isRequired,
		filtersValues: PropTypes.shape({
			'containerType.name': PropTypes.shape(selectFilterProps).isRequired,
			deviceID: PropTypes.shape(selectFilterProps).isRequired,
			'containerType.wasteType': PropTypes.shape(selectFilterProps).isRequired
		})
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = reduce(queryParams, (result, item) => ({ [item]: null, ...result }), { columns: defaultColumns })

		this.state = {
			modal: null,
			modalData: null
		}
	}

	async componentDidMount() {
		this._mounted = true
		const { actions, filters, municipality } = this.props

		if (municipality) {
			this.returnPath = locale.formatString(locale['path.municipality.collections.rounds'], municipality.id)
		} else {
			this.returnPath = locale['path.registry.collections.rounds']
		}

		const { match } = this.props
		const id = match.params.collectionRoundID

		const round = await actions.loadCollectionRound(id)

		actions.deselectAllCollections()

		if (!(round instanceof Error)) {
			const { municipalityID } = round

			const queryFilters = normalizeQueryParams(queryTypes, mergeQueryParams(filters, this.queryParams))

			const newFilters = { ...queryFilters, page: '1' }

			// Remove unwanted query
			history.replace(history.location.pathname, { search: null })
			this.setQueryParams(newFilters)
			actions.initialize(FILTER.COLLECTION_ROUNDS_FILTERS, newFilters)
			actions.initialize(FORMS.COLLECTION_ROUND_FORM, this.getInitialValues(round))

			actions.loadCollections(this.buildFilters(newFilters))
			actions.loadContainerTypes({ municipalityID, limit: 'ALL' })
			actions.loadDevices({ municipalityID, limit: 'ALL' })
			actions.loadWasteTypes({ municipalityID, limit: 'ALL' })
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		const { municipality } = this.props

		if (this._mounted) {
			if (municipality?.id !== prevProps.municipality?.id) {
				history.replace(municipality
					? locale.formatString(locale['path.municipality.collections.rounds'], municipality.id)
					: locale['path.registry.collections.rounds'])
			}
		}
	}

	changeContainerType = async (values) => {
		const { filters, actions, selectedCollections } = this.props
		const { modalData } = this.state
		const data = {
			containerTypeID: values.containerTypeID
		}

		if (selectedCollections && !isEmpty(selectedCollections)) {
			data.collections = map(selectedCollections, (item) => item.id)
		} else {
			data.collections = [modalData.id]
		}
		this.dismissModal()

		try {
			await patchReq(ENDPOINTS.CHANGE_CONTAINER_TYPES, null, data)

			actions.loadCollections(this.buildFilters(filters))
			actions.deselectAllCollections()

			this.setState({
				selectedCollections: []
			})

			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	setRemovedCollection = (collection) => {
		const { filters, actions } = this.props
		return async () => {
			this.dismissModal()
			try {
				await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION(collection.municipalityID, collection.id), null)

				actions.loadCollections(this.buildFilters(filters))
				actions.deselectAllCollections()

				this.setState({
					selectedCollections: []
				})

				actions.pushStatus({
					type: INFO,
					msg: locale['page.collections.delete.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	setRemovedSelectedCollections = async () => {
		const { filters, actions, detail, selectedCollections } = this.props
		this.dismissModal()
		const data = {
			collectionIDs: map(selectedCollections, (item) => item.id)
		}
		try {
			await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTIONS(get(detail, 'data.municipalityID')), null, data)

			actions.loadCollections(this.buildFilters(filters))
			actions.deselectAllCollections()

			this.setState({
				selectedCollections: []
			})
			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.delete.selected.success']
			})
		} catch (error) {
			// Error
		}
	}

	syncSelectedCollections = async () => {
		const { filters, actions, selectedCollections } = this.props

		this.dismissModal()
		try {
			await postReq(ENDPOINTS.SYNC_COLLECTIONS, null, { collections: map(selectedCollections, (item) => item.id) })

			actions.loadCollections(this.buildFilters(filters))
			actions.deselectAllCollections()

			this.setState({
				selectedCollections: []
			})

			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.sync.success']
			})
		} catch (error) {
			// Error
		}
	}

	syncCollection = (collection) => {
		const { filters, actions } = this.props

		return async () => {
			this.dismissModal()
			try {
				await postReq(ENDPOINTS.SYNC_COLLECTIONS, null, { collections: [collection.id] })

				actions.loadCollections(this.buildFilters(filters))
				actions.deselectAllCollections()

				this.setState({
					selectedCollections: []
				})

				actions.pushStatus({
					type: INFO,
					msg: locale['page.collections.sync.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	getCollectionActions = (collection) => {
		const deleteTitle = this.props.isSelecting ? locale['common.delete.selected'] : locale['common.delete']
		const changeContainerTypeTitle = locale['common.changeContainerType.selected']

		const items = [{
			key: 'common.delete',
			title: deleteTitle,
			icon: faTrashAlt,
			callback: () => this.openModal(MODAL.REMOVE_COLLECTION)(collection)
		}]

		if (this.props.municipality) {
			return [{
				key: 'common.changeContainerType',
				title: changeContainerTypeTitle,
				icon: faCogs,
				callback: () => this.openModal(MODAL.CHANGE_CONTAINER_TYPE)(collection)
			}, {
				key: 'collection.round',
				title: 'Zmeniť priradený zvoz',
				icon: faTruck,
				callback: () => this.openModal(MODAL.COLLECTION_SET_COLLECTION_ROUND)(collection)
			}, ...items]
		}

		return items
	}

	returnToList = () => {
		history.push(this.returnPath)
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	handleDismissCollectionRoundModal = () => {
		const { actions, filters } = this.props

		actions.deselectAllCollections()
		actions.loadCollections(this.buildFilters(filters))

		this.dismissModal()
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	setCollectionRemoved = (collection) => async () => {
		const { actions, detail } = this.props
		this.dismissModal()
		try {
			const collectionRound = detail.data
			await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION(collectionRound.municipalityID, collection.id), null)
			history.replace(this.returnPath)
			actions.pushStatus({
				type: INFO,
				msg: locale['page.collections.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	setCollectionRoundRemoved = async () => {
		const { actions, detail } = this.props
		this.dismissModal()
		try {
			const collectionRound = detail.data
			await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION_ROUND(collectionRound.municipalityID, collectionRound.id), null)
			history.replace(this.returnPath)

			actions.pushStatus({
				type: INFO,
				msg: locale['page.collectionRounds.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	breadcrumbsItems = (collectionRound) => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return {
			items: [...items, {
				key: 'page.registry.breadcrumbs',
				name: locale['page.registry.breadcrumbs']
			}, {
				key: 'page.collectionRounds.breadcrumbs',
				name: locale['page.collectionRounds.breadcrumbs'],
				link: municipality ?
					locale.formatString(locale['path.municipality.collections.rounds'], municipality.id)
					: locale['path.registry.collections.rounds']
			}, {
				key: 'page.collectionRounds.detail.breadcrumbs',
				name: collectionRound.name
			}]
		}
	}

	buildFilters = (filters) => {
		const { detail } = this.props
		const unsuitableConditions = Object.values(UNSUITABLE_CONDITION)
		const collectionRoundID = get(detail, 'data.id')

		return {
			...filters,
			collectionRoundIDs: [collectionRoundID],
			state: undefined,
			columns: undefined,
			invalidStates: filter(filters.state, (state) => INVALID_STATES.includes(state)),
			unsuitableConditions: filter(filters.state, (state) => unsuitableConditions.includes(state))
		}
	}

	onFilter = (values) => {
		const { actions } = this.props
		const query = normalizeQueryParams(queryTypes, values)
		this.setQueryParams(query)
		actions.initialize(FILTER.COLLECTION_ROUNDS_FILTERS, query)
		actions.deselectAllCollections()
		actions.loadCollections(this.buildFilters(query))
	}

	onResetFilters = () => {
		const { filters } = this.props
		const defaultFilters = {
			columns: filters.columns,
			order: filters.order,
			limit: filters.limit,
			page: 1
		}

		const newFilters = reduce(filters, (result, filter, key) => ({
			...result,
			[key]: defaultFilters[key] || null
		}), {})

		this.onFilter(newFilters)
	}

	onChangeFilter = (filter) => (value) => {
		const { actions, filters } = this.props
		actions.change(FILTER.COLLECTION_ROUNDS_FILTERS, filter, value)
		this.onFilter({ ...filters, [filter]: value })
	}

	onChangeColumns = (columns) => {
		const { filters } = this.props
		const query = normalizeQueryParams(queryTypes, { ...filters, columns })
		this.setQueryParams(query)
	}

	getSelectedColumns = () => {
		const { filters, filtersValues } = this.props

		return reduce(columns, (result, item) => {
			const filterIndex = indexOf(filters.columns, item.name)
			if (filterIndex >= 0) {
				if (filtersValues[item.name]) {
					item.filter = {
						...item.filter,
						...filtersValues[item.name]
					}
				}

				if (filterIndex >= filters.columns.length - 2) {
					item.filter.position = FILTER_POSITION.LEFT
				}

				return [
					...result,
					item
				]
			}
			return result
		}, [])
	}

	getSelectedCollectionsIDs = () => {
		const { modalData } = this.state
		const { selectedCollections } = this.props

		if (isEmpty(selectedCollections) && modalData?.id) {
			return [modalData.id]
		}

		if (!isEmpty(selectedCollections)) {
			return map(selectedCollections, (item) => item.id)
		}

		return []
	}

	getInitialValues = (round) => ({
		id: round.id,
		name: round.name,
		comment: round.comment,
		wasteType: get(round, 'wasteType.id'),
		weightBefore: round.weightBefore,
		weightAfter: round.weightAfter,
		totalWeight: round.totalWeight,
		realWeight: round.realWeight,
		collectionsCount: round.collectionsCount,
		createdAt: round.createdAt,
		updatedAt: round.updatedAt,
		creator: round.creator,
		editor: round.editor,
		startDatetime: round.startDatetime,
		stopDatetime: round.stopDatetime,
		municipalityID: round.municipalityID,
		parentMunicipalityID: round.parentMunicipalityID,
		streets: join(round.streets, ', ')
	})

	render() {
		const { filters, actions, selectedCollections, list, detail, listContainerTypes, municipality } = this.props
		const { modal, modalData } = this.state

		if (detail.isFailure) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: false })}>
							<div>
								<span>{locale['page.collectionRounds.detail.failure']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		} if (!detail.data || detail.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: false })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.collectionRounds.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const collectionRound = detail.data
		const selectedList = Object.values(selectedCollections)
		const selectedColumns = this.getSelectedColumns()

		const deleteTitle = selectedList.length
			? locale['page.collections.delete.selected.title']
			: locale.formatString(locale['page.collections.delete.title'], get(modalData, 'name', ''))

		const canChangeContainerType = selectedList.length
			? every(selectedList, (item) => get(item, 'containerType.type') === CONTAINER_TYPES.ONE_TIME)
			: modalData && get(modalData, 'containerType.type') === CONTAINER_TYPES.ONE_TIME

		const changeContainerTypeTitle = selectedList.length
			? locale['page.collections.change.container.type.selected.title']
			: locale.formatString(locale['page.collections.change.container.type.selected.title'], get(modalData, 'name', ''))

		const deleteMessage = selectedList.length
			? locale['page.collections.delete.selected.message']
			: locale['page.collections.delete.message']

		const changeContainerTypeMessage = canChangeContainerType
			? locale['page.collections.change.container.type.selected.message']
			: locale['page.collections.change.container.type.one.time.only']

		const deleteAction = selectedList.length
			? this.setRemovedSelectedCollections
			: this.setRemovedCollection(modalData)

		const canSync = every(selectedList, (item) => item.containerID === null)

		const syncAction = selectedList.length
			? this.syncSelectedCollections
			: this.syncCollection(modalData)

		const syncMessage = canSync
			? locale['page.collections.sync.message']
			: locale['page.collections.sync.invalid.only']

		let form

		if (collectionRound.type === COLLECTION_ROUND_TYPE.GENERAL) {
			form = (
				<CollectionRoundGeneralForm
					cancelHandler={this.returnToList}
					initialValues={this.getInitialValues(collectionRound)}
				/>
			)
		} else {
			form = (
				<CollectionRoundForm
					onSubmit={this.returnToList}
					detail={collectionRound}
				/>
			)
		}

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems(collectionRound)}
				/>
				<Tabs
					id="collection-round-page-tabs"
					activeKey={this.state.key}
					onSelect={(key) => this.setState({ key })}
				>
					<Tab eventKey="common-basic-info" title={locale['common.basic.info']}>
						{form}
					</Tab>
					<Tab
						eventKey="collectionRounds-detail"
						title={locale['page.collectionRounds.detail.collections']}
					>
						<div className={'box'}>
							<div className={'box-content'}>
								<div className={'box-head'}>
									<div className={'row'}>
										<div className={'col-md-6'}>
											<ExportButton
												url={ENDPOINTS.COLLECTIONS_EXPORT}
												context={{ ...this.buildFilters(filters), columns: filters.columns, collectionRoundIDs: [get(detail, 'data.id')] }}
											/>
										</div>
									</div>
								</div>
								<div className={'box-body'}>
									<div className={'row'}>
										<div className={'col-12'}>
											<Table
												onResetFilters={this.onResetFilters}
												onSelect={(items) => actions.toggleSelectCollections(items)}
												selectedItems={selectedCollections}
												getActions={this.getCollectionActions}
												onDetail={this.openCollectionDetail}
												name={FILTER.COLLECTION_ROUNDS_FILTERS}
												columns={selectedColumns}
												tableColumns={tableColumns}
												defaultColumns={defaultColumns}
												filters={filters}
												isLoading={list.isLoading}
												isFailure={list.isFailure}
												isSelectable={true}
												data={get(list, 'data.collections')}
												onSort={this.onChangeFilter(queryParams.order)}
												onFilter={this.onFilter}
												onChangeColumns={this.onChangeColumns}
											/>
											<ListItemsLimit
												limit={filters.limit || 20}
												onLimit={this.onChangeFilter(queryParams.limit)}
											/>
											{selectedList.length > 0 &&
											<>
												<div className={'row'} style={{ marginTop: '15px' }}>
													<div className={'col'}>
														<button
															type="button"
															onClick={this.openModal(MODAL.SYNC_COLLECTIONS)}
															className={'button pull-right'}
														>
															{locale['page.collections.sync.title']}
														</button>
														<button
															type="button"
															onClick={this.openModal(MODAL.CHANGE_CONTAINER_TYPE)}
															style={{ marginRight: '15px' }}
															className={'button pull-right'}
														>
															{locale['page.collections.button.mass.change.container.type']}
														</button>
													</div>
													<div className={'col'}>
														<button
															type="button"
															onClick={this.openModal(MODAL.REMOVE_COLLECTION)}
															className={'button red-bg pull-left'}
														>
															{locale['page.collections.button.mass.change.delete']}
														</button>
													</div>
												</div>
											</>}
											<Pagination
												pages={get(list, 'data.context.pages')}
												page={get(list, 'data.context.page')}
												onPage={this.onChangeFilter(queryParams.page)}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Tab>
					<Tab eventKey={'map'} title={locale['page.collectionRounds.detail.map']}>
						<CollectionRoundMap active={this.state.key === 'map'}/>
					</Tab>
					<Tab eventKey={'overview'} title={locale['page.collectionRounds.detail.overview']}>
						<CollectionRoundOverview/>
					</Tab>
				</Tabs>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION_ROUND}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setCollectionRoundRemoved}
					message={locale['page.collectionRounds.delete.message']}
					title={locale.formatString(locale['page.collectionRounds.delete.title'], collectionRound.name)}
					acceptTitle={locale['page.collectionRounds.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setCollectionRemoved(modalData)}
					message={locale['page.collections.delete.message']}
					title={locale.formatString(locale['page.collections.delete.title'], get(modalData, 'container.code', ''))}
					acceptTitle={locale['page.collectionRounds.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION}
					cancelHandler={this.dismissModal}
					acceptHandler={deleteAction}
					message={deleteMessage}
					title={deleteTitle}
					acceptTitle={locale['page.collections.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.SYNC_COLLECTIONS}
					cancelHandler={this.dismissModal}
					acceptHandler={canSync ? syncAction : null}
					message={syncMessage}
					title={locale['page.collections.sync.title']}
					acceptTitle={locale['page.collections.sync.accept']}
				/>
				<ContainerTypeChangeModal
					shown={modal === MODAL.CHANGE_CONTAINER_TYPE}
					cancelHandler={this.dismissModal}
					message={changeContainerTypeMessage}
					title={changeContainerTypeTitle}
					acceptTitle={locale['page.collections.change.container.accept']}
					municipality={municipality}
					selectOptions={get(listContainerTypes, 'values', [])}
					showError={!canChangeContainerType}
					onSubmit={this.changeContainerType}
				/>
				<ChangeCollectionRoundModal
					visible={modal === MODAL.COLLECTION_SET_COLLECTION_ROUND}
					onClose={this.handleDismissCollectionRoundModal}
					collectionRound={collectionRound}
					getCollections={this.getSelectedCollectionsIDs}
				/>
			</>
		)
	}
}

const filtersSelector = formValueSelector(FILTER.COLLECTION_ROUNDS_FILTERS)

const mapStateToProps = (state) => ({
	detail: state.collectionRounds.detail,
	list: state.collections.list,
	listContainerTypes: getOneTimeContainerTypesSelectOptions(state),
	selectedCollections: state.collections.selectedCollections,
	filtersValues: {
		'containerType.name': getContainerTypesSelectOptions(state),
		deviceID: getDevicesSelectOptions(state),
		'containerType.wasteType': getWasteTypesSelectOptions(state)
	},
	filters: filtersSelector(state, ...Object.values(queryParams)),
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	actions: {
		loadCollectionRound: bindActionCreators(loadCollectionRound, dispatch),
		loadDevices: bindActionCreators(loadDevices, dispatch),
		loadWasteTypes: bindActionCreators(loadWasteTypes, dispatch),
		loadCollections: bindActionCreators(loadCollections, dispatch),
		loadContainerTypes: bindActionCreators(loadContainerTypes, dispatch),
		initialize: bindActionCreators(initialize, dispatch),
		resetForm: bindActionCreators(reset, dispatch),
		change: bindActionCreators(change, dispatch),
		submit: bindActionCreators(submit, dispatch),
		pushStatus: bindActionCreators(statusPush, dispatch),
		toggleSelectCollections: bindActionCreators(toggleSelectCollections, dispatch),
		deselectAllCollections: bindActionCreators(deselectAllCollections, dispatch)
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionRoundPage)
