import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faCheckCircle, faTimesCircle, faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { get, join, filter, reduce, uniq } from 'lodash'
import ReactTooltip from 'react-tooltip'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'
import { PERMISSIONS } from '../../utils/enums'

class UsersTableRow extends React.Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		countIndex: PropTypes.number
	}

	getUserPermissions = (user) => {
		if (user.isAdmin) {
			return locale[`role.${PERMISSIONS.ADMINISTRATOR}`]
		}
		const { municipality } = this.props
		const municipalities = municipality ? filter(user.municipalities, ((m) => m.id === municipality.id)) : user.municipalities
		const permissions = uniq(reduce(municipalities, (value, municipality) => [...value, ...municipality.permissions], []))

		if (permissions.length === 1) {
			return locale[`role.${permissions[0]}`]
		}

		if (!permissions.length) {
			return locale['role.NONE']
		}
		const items = permissions.map((p) => (
			<li key={`${user.id}.${p}`}>{locale[`role.${p}`]}</li>
		))

		return (
			<>
				<span data-tip data-for={`permissions-tooltip-${user.id}`}>{locale['role.MULTIPLE']}</span>
				<ReactTooltip id={`permissions-tooltip-${user.id}`} aria-haspopup={'true'} place={'left'} effect={'solid'}>
					<ul className={'tooltip-list'}>
						{items}
					</ul>
				</ReactTooltip>
			</>
		)
	}

	popupItems = () => {
		const { municipality, onSetActive, onRemove, user } = this.props
		const items = []
		if (!municipality) {
			items.push({
				key: 'common.edit',
				title: user.isActive ? locale['common.deactivate'] : locale['common.activate'],
				icon: faPowerOff,
				callback: () => onSetActive(user)
			})
		}
		items.push({
			key: 'common.delete',
			title: locale['common.delete'],
			icon: faTrashAlt,
			callback: () => onRemove(user)
		})
		return items
	}

	render() {
		const { user, onDetail, countIndex } = this.props
		return (
			<tr onClick={() => onDetail(user)}>
				<td>{join([get(user, 'name'), get(user, 'surname')], ' ')}</td>
				<td>{get(user, 'email')}</td>
				<td>{this.getUserPermissions(user)}</td>
				{/* eslint-disable-next-line no-nested-ternary */}
				<td className={'text-center icon'} style={{ color: user.isActive ? user.isConfirmed ? '#AFCA0B' : '#FC754B' : '#F03E41' }}>
					{/* eslint-disable-next-line no-nested-ternary */}
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${user.id}`} icon={user.isActive ? user.isConfirmed ? faCheckCircle : faQuestionCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${user.id}`} place={'left'} effect={'solid'}>
						<span>
							{/* eslint-disable-next-line no-nested-ternary */}
							{user.isActive ? user.isConfirmed ? locale['page.users.confirmed.tooltip'] : locale['page.users.waiting.tooltip'] : locale['page.users.inactive.tooltip']}
						</span>
					</ReactTooltip>
				</td>
				<td className={'action'} style={{ zIndex: `${countIndex}` }}>
					<PopupTool
						items={this.popupItems()}
					/>
				</td>
			</tr>
		)
	}
}

export default UsersTableRow
