import React from 'react'
import { Field, FieldArray } from 'redux-form'
import * as PropTypes from 'prop-types'
import i18next from 'i18next'

import { map, get, debounce } from 'lodash'
import locale from '../../resources/locale'

import SelectField from '../../atoms/SelectField'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import { normalizeDate, validate, validatorOptions } from '../../utils/validator'

import { eventTypes } from '../../utils/select'
import { ENDPOINTS, EVENT_TYPE, MUNICIPALITY_TYPE } from '../../utils/enums'
import TextAreaInputField from '../../atoms/TextAreaInputField'
import CheckBoxField from '../../atoms/CheckBoxField'
import DateTimePickerField from '../../atoms/DateTimePickerField'
import { getReq } from '../../utils/request'
import { collectionPlaces } from '../../api'

const validateText = validate([validatorOptions.REQUIRED_STRING])
const validateNumber = validate([validatorOptions.REQUIRED_NUMBER])
const validateArray = validate([validatorOptions.ARRAY])
const validateDate = validate([validatorOptions.REQUIRED, validatorOptions.DATE])

class EventFormFields extends React.Component {
	static propTypes = {
		wasteTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
		municipality: PropTypes.object,
		type: PropTypes.string,
		isCreate: PropTypes.bool,
		onTypeChange: PropTypes.func.isRequired
	}

	searchWasteTypes = (search, callback) => {
		getReq(ENDPOINTS.MUNICIPALITIES, {
			search,
			order: 'name:ASC',
			page: 1,
			limit: 'ALL',
			parentID: get(this.props, 'municipality.id')
		}).then((response) => {
			const items = map(get(response, 'data.municipalities'), (item) => ({
				label: item.name,
				value: item.id,
				key: item.id
			}))
			return callback(items)
		}).catch(() => callback([]))
	}

	searchStreets = (search, callback) => {
		const { municipality } = this.props
		collectionPlaces.loadStreets({ search, municipalityID: municipality?.id })
			.then((response) => {
				const items = map(get(response, 'data.streets'), (item) => ({
					label: item,
					value: item,
					key: item
				}))

				return callback(items)
			})
			.catch(() => callback([]))
	}

	dateFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Dátum'),
		format: locale['common.date.format.date'],
		required: true
	}

	typeFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Typ'),
		required: true,
		options: eventTypes
	}

	notificationFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Dátum notifikácie'),
		minDate: new Date(),
		format: locale['common.date.format'],
		timeFormat: locale['common.time.format']
	}

	commentFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Komentár')
	}

	municipalitiesSelectFieldOptions = {
		label: i18next.t('loc:Obce'),
		required: true,
		isMulti: true,
		showLabel: true,
		loadOptions: debounce(this.searchWasteTypes, 300)
	}

	streetsSelectFieldOptions = {
		label: 'Ulice',
		required: true,
		isMulti: true,
		showLabel: true,
		loadOptions: debounce(this.searchStreets, 300)
	}

	renderWasteTypes = ({ fields }) => {
		const { isCreate, wasteTypes } = this.props

		const wasteTypeFieldOptions = {
			showLabel: true,
			options: map(wasteTypes, (type) => ({
				value: type.id,
				label: type.name
			}))
		}

		if (isCreate) {
			return (
				<div className={'row'}>
					<div className={'col-md-12'}>
						<Field
							name='wasteTypes'
							placeholder={locale['field.placeholder.wasteTypes']}
							component={SelectField}
							props={wasteTypeFieldOptions}
							validate={validateArray}
							label={i18next.t('loc:Druhy odpadov')}
							isMulti={true}
						/>
					</div>
				</div>
			)
		}

		const wasteTypeFields = fields.map((item, index) => {
			const value = fields.get(index)

			return (
				<div key={index} className={'col-md-6'}>
					<Field
						name={`${item}.id`}
						component={'input'}
						type={'hidden'}
					/>
					<Field
						name={`${item}.wasteTypeID`}
						placeholder={locale['field.placeholder.wasteType']}
						component={SelectField}
						props={wasteTypeFieldOptions}
						validate={validateNumber}
						label={value.name}
					/>
				</div>

			)
		})

		return (
			<div className={'row'}>
				{wasteTypeFields}
			</div>
		)
	}

	render() {
		const { onTypeChange, type, isCreate, municipality } = this.props

		const showNotificationFields = type === EVENT_TYPE.OTHER
		const showWasteTypeFields = type === EVENT_TYPE.COLLECTION_ROUND
		const showMunicipalitiesField = get(municipality, 'type') === MUNICIPALITY_TYPE.WASTE_COMPANY

		return (
			<>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<Field
							name='date'
							placeholder={locale['field.placeholder.date']}
							component={DateTimePickerField}
							props={this.dateFieldOptions}
							validate={validateDate}
							normalize={normalizeDate}
						/>
					</div>
					{isCreate &&
					<div className={'col-md-6'}>
						<Field
							name='type'
							placeholder={locale['field.placeholder.type']}
							component={SelectField}
							props={this.typeFieldOptions}
							onChange={onTypeChange}
							validate={validateText}
						/>
					</div>}
				</div>
				{!isCreate &&
				<div className={'row'}>
					<div className={'col-md-12'} style={{ fontWeight: 'bold', marginBottom: '10px' }}>
						<label>{locale['page.collectionRounds.breadcrumbs']}:</label>
					</div>
				</div>}
				{showWasteTypeFields && isCreate && this.renderWasteTypes({})}
				{showWasteTypeFields && !isCreate &&
				<FieldArray name="collectionRounds" component={this.renderWasteTypes}/>}
				<div className={'row'}>
					{type === EVENT_TYPE.OTHER && <div className={'col-md-6'}>
						<Field
							name='isVisibleForStatistics'
							component={CheckBoxField}
							label={i18next.t('loc:Viditeľný pre štatistiky')}
						/>
					</div>}
					{type === EVENT_TYPE.COLLECTION_ROUND && <>
						{showMunicipalitiesField &&
						<div className={'col-12'}>
							<Field
								name='municipalities'
								placeholder={locale['field.placeholder.municipalities']}
								component={AsyncSelectField}
								props={this.municipalitiesSelectFieldOptions}
								validate={validateArray}
							/>
						</div>}
						{(isCreate && !showMunicipalitiesField) &&
						<div className={'col-12'}>
							<Field
								name='streets'
								placeholder={'Vyberte ulice'}
								component={AsyncSelectField}
								props={this.streetsSelectFieldOptions}
							/>
						</div>}
						<div className={'col-md-6'}>
							<Field
								name='isDoorToDoor'
								component={CheckBoxField}
								label={i18next.t('loc:Od dverí k dverám')}
							/>
						</div>
						<div className={'col-md-6'}>
							<Field
								name='isPublic'
								component={CheckBoxField}
								label={i18next.t('loc:Publikovať pre občanov')}
							/>
						</div>
						{type === EVENT_TYPE.COLLECTION_ROUND && <>
							<div className={'col-md-6'}>
								<Field
									name='useQuantity'
									component={CheckBoxField}
									label={i18next.t('loc:Používať naplnenosť')}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='useUnsuitableConditions'
									component={CheckBoxField}
									label={i18next.t('loc:Používať nezhodné stavy')}
								/>
							</div>
						</>}
					</>}
				</div>
				<div className={'row'}>
					{showNotificationFields &&
					<div className={'col-md-6'}>
						<Field
							name='notificationDateTime'
							placeholder={locale['field.placeholder.notification.datetime']}
							component={DateTimePickerField}
							props={this.notificationFieldOptions}
						/>
					</div>}
					<div className={'col-md-12'}>
						<Field
							name='comment'
							placeholder={locale['field.placeholder.comment']}
							component={TextAreaInputField}
							props={this.commentFieldOptions}
						/>
					</div>
				</div>
			</>
		)
	}
}

export default EventFormFields
