import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import locale from '../../resources/locale'
import { MOTIVATION_MODEL } from '../../utils/enums'
import { formatDate } from '../../utils/utils'

class MotivationTableRow extends React.Component {
	static propTypes = {
		item: PropTypes.shape({
			name: PropTypes.string.isRequired,
			isAnonymous: PropTypes.bool,
			period: PropTypes.string.isRequired,
			motivation: PropTypes.number.isRequired
		}).isRequired
	}

	onDetail = () => {
		const { item, onDetail } = this.props

		if (onDetail) {
			onDetail(item)
		}
	}

	render() {
		const { item } = this.props
		return (
			<tr onClick={this.onDetail}>
				<td>{item.isAnonymous ? locale.formatString(locale['page.customers.anonymous'], get(item, 'id', 0)) : get(item, 'name') }</td>
				<td className={'text-center'}>
					{item.type === MOTIVATION_MODEL.FEE
						? `${formatDate(item.validFrom)} - ${formatDate(item.validTo)}`
						: `${get(item, 'period', '-')}`}
				</td>
				<td className={'text-center'}>
					{`${(item.plannedVolume || 0).toFixed(1)} l`}
				</td>
				<td className={'text-center'}>
					{`${(item.totalVolume || 0).toFixed(1)} l`}
				</td>
				<td className={'text-center'}>{`${get(item, 'motivation', 0).toFixed(2)}`}</td>
			</tr>
		)
	}
}

export default MotivationTableRow
