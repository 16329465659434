import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { reset, initialize } from 'redux-form'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { get } from 'lodash'
import { MODAL, INFO, ERROR, FORMS, ENDPOINTS } from '../../utils/enums'

import { statusPush } from '../../actions/statusActions'
import locale from '../../resources/locale'
import WasteTypeForm from '../../components/wasteTypes/WasteTypeForm'
import { history } from '../../utils/history'
import { deleteReq, getReq, patchReq } from '../../utils/request'
import Breadcrumbs from '../../components/Breadcrumb'
import DialogModal from '../../components/modals/DialogModal'

class WasteTypePageOld extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func,
		municipality: PropTypes.object,
		initialize: PropTypes.func,
		reset: PropTypes.func,
		updateUser: PropTypes.func,
		loadTypes: PropTypes.func,
		resetForm: PropTypes.func,
		match: PropTypes.shape({
			params: PropTypes.shape({
				municipalityID: PropTypes.string.isRequired,
				wasteTypeID: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			modal: null,
			modalData: null,
			loading: true,
			wasteType: null
		}
	}

	componentDidMount() {
		const { municipality } = this.props
		this.returnPath = locale.formatString(locale['path.municipality.wastes'], municipality.id)
		this.loadWasteType()
	}

	loadWasteType = async () => {
		const { match } = this.props
		const { municipalityID, wasteTypeID } = match.params
		try {
			const response = await getReq(ENDPOINTS.WASTE_TYPE(municipalityID, wasteTypeID))
			this.setupForm(response.data)
		} catch (error) {
			if (!municipalityID) {
				history.replace('/')
			} else {
				history.replace(locale.formatString(locale['path.municipality.wastes'], municipalityID))
			}
		}
	}

	setupForm = (wasteType) => {
		const { initialize, reset } = this.props
		this.setState({
			wasteType,
			loading: false
		})
		initialize(FORMS.WASTE_TYPE_FORM, this.getInitialValues(wasteType))
		reset(FORMS.WASTE_TYPE_FORM)
	}

	setActiveMotivationModel = async (isActiveMotivationModel) => {
		const { match, pushStatus } = this.props
		const { municipalityID, wasteTypeID } = match.params
		try {
			await patchReq(ENDPOINTS.WASTE_TYPE(municipalityID, wasteTypeID), null, { isActiveMotivationModel })
			pushStatus({
				type: INFO,
				msg: isActiveMotivationModel ? locale['page.waste.types.activate.success'] : locale['page.waste.types.deactivate.success']
			})
		} catch (error) {
			pushStatus({
				type: ERROR,
				msg: isActiveMotivationModel ? locale['page.waste.types.deactivate.failed'] : locale['page.waste.types.activate.failed']
			})
		}
	}

	removeWasteType = async () => {
		const { pushStatus, municipality } = this.props
		const { wasteType } = this.state
		this.dismissModal()
		try {
			await deleteReq(ENDPOINTS.WASTE_TYPE(municipality.id, wasteType.id))
			history.replace(this.returnPath)
			pushStatus({
				type: INFO,
				msg: locale['page.waste.types.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	saveUpdates = async (data) => {
		const { wasteType } = this.state
		const { pushStatus, municipality } = this.props
		try {
			await patchReq(ENDPOINTS.WASTE_TYPE(municipality.id, wasteType.id), null, data)
			history.push(this.returnPath)
			pushStatus({
				type: INFO,
				msg: locale['page.waste.types.detail.update.success']
			})
		} catch (error) {
			// Error
		}
	}

	returnToList = (e) => {
		e.preventDefault()
		history.push(this.returnPath)
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	openModal = (modal) => () => {
		this.setState({
			modal
		})
	}

	getInitialValues = (wasteType) => ({
		name: wasteType.name,
		code: {
			value: wasteType.wasteType.id,
			label: `${wasteType.wasteType.code} - ${wasteType.wasteType.name}`,
			key: wasteType.wasteType.id
		},
		collectionYardWeightLimit: wasteType.collectionYardWeightLimit,
		color: wasteType.color,
		coefficient: wasteType.coefficient,
		type: wasteType.type,
		weightCalculateType: wasteType.weightCalculateType
	})

	breadcrumbsItems = (wasteType) => {
		const { municipality } = this.props
		const items = [{
			key: 'page.municipality.settings.breadcrumbs',
			name: locale['page.municipality.settings.breadcrumbs']
		}, {
			key: 'page.municipality.detail.',
			name: municipality.name
		}, {
			key: 'page.waste.types.breadcrumbs',
			name: locale['page.waste.types.breadcrumbs'],
			link: locale.formatString(locale['path.municipality.wastes'], municipality.id)
		}, {
			key: 'page.waste.type.detail.breadcrumbs',
			name: wasteType.name
		}]

		const action = {
			...this.switchAction,
			checked: wasteType.isActiveMotivationModel
		}
		return { items, action }
	}

	action = (wasteType) => {
		const action = {
			...this.switchAction,
			checked: wasteType.isActiveMotivationModel
		}
		return { action }
	}

	switchAction = {
		onChange: this.setActiveMotivationModel,
		onColor: '#AFCA0B',
		offColor: '#F03E41',
		onLabel: locale['page.waste.types.detail.motivation.deactivate'],
		offLabel: locale['page.waste.types.detail.motivation.activate']
	}

	render() {
		const { loading, wasteType, modal, modalData } = this.state

		if (loading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !loading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.waste.types.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<Breadcrumbs {...this.breadcrumbsItems(wasteType)}/>
				<WasteTypeForm
					initialValues={this.getInitialValues(wasteType)}
					submitHandler={this.saveUpdates}
					resendHandler={this.resendInvitation}
					cancelHandler={this.returnToList}
					deleteHandler={this.openModal(MODAL.REMOVE_WASTE_TYPE)}
					{...this.action(wasteType)}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_WASTE_TYPE}
					cancelHandler={this.dismissModal}
					acceptHandler={this.removeWasteType}
					message={locale.formatString(locale['page.waste.types.delete.message'])}
					title={locale.formatString(locale['page.waste.types.delete.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.waste.types.delete.accept']}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	reset: bindActionCreators(reset, dispatch),
	initialize: bindActionCreators(initialize, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WasteTypePageOld)
