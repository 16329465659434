import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { Col, Dropdown, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import Chart from 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'

import { DownOutlined } from '@ant-design/icons'

import { createYearsList } from '../../../utils/helpers'
import theme from '../../../utils/theme'

import { statistics } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import Button from '../../../components/buttons/Button'
import SpinLoading from '../../../components/SpinLoading'

import { SubHeading } from '../../../components/Typography'

Chart.register(annotationPlugin)

type Props = {
	municipalityID?: string | number
}

const ChartWrapper = styled.div`
	height: 542px;
`

const years = createYearsList()

const baseOptions = {
	borderRadius: 2,
	pointStyle: 'circle',
	pointRadius: 2,
	maxBarThickness: 150,
	skipNull: true
}

type TextAlign = 'start' | 'end' | 'center'

const annotationBaseOptions = {
	type: 'line' as 'line',
	borderWidth: 2,
	borderColor: 'black',
	borderDash: [10],
	label: {
		textAlign: 'start' as TextAlign,
		backgroundColor: 'black',
		xPadding: 12,
		yPadding: 8,
		font: {
			size: 14,
			lineHeight: '20px'
		},
		enabled: true
	}
}

const WastesPerCustomer = ({ municipalityID }: Props) => {
	const [t] = useTranslation()
	const [year, setYear] = useState(() => dayjs().year().toString())

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const chartRef = useRef<Chart | null>(null)

	const [summaryState, loadSummary] = useDataLoader(statistics.loadWastesPerCustomer)

	useEffect(() => {
		if (summaryState.data) {
			const { unsortedWeight, sortedWeight, sortedWeightAverage, unsortedWeightAverage, averageSortedGlobal, averageUnsortedGlobal } = summaryState.data

			const data = {
				labels: [t('DashboardPage.sortedWaste'), t('DashboardPage.unsortedWaste')],
				datasets: [{
					...baseOptions,
					label: t('DashboardPage.sortedWaste'),
					data: [sortedWeight, null],
					backgroundColor: theme.colors.primary['300']
				}, {
					...baseOptions,
					label: t('DashboardPage.unsortedWaste'),
					data: [null, unsortedWeight],
					backgroundColor: theme.colors.neutral['300']
				}]
			}

			chartRef.current?.destroy()
			const context = canvasRef.current?.getContext('2d')

			if (context) {
				chartRef.current = new Chart(context, {
					type: 'bar',
					data: data as any,
					options: {
						maintainAspectRatio: false,
						plugins: {
							datalabels: {
								display: false
							},
							tooltip: {
								callbacks: {
									label: ({ formattedValue }) => `${formattedValue} kg`
								}
							},
							legend: {
								labels: {
									usePointStyle: true
								}
							},
							annotation: {
								annotations: {
									line1: {
										...annotationBaseOptions,
										xMin: -1,
										xMax: 0.5,
										yMin: averageSortedGlobal,
										yMax: averageSortedGlobal,
										label: {
											...annotationBaseOptions.label,
											xAdjust: -160,
											content: [t('DashboardPage.slovakAvg'), `${averageSortedGlobal}kg`]
										}
									},
									line2: {
										...annotationBaseOptions,
										xMin: -1,
										xMax: 0.5,
										yMin: sortedWeightAverage,
										yMax: sortedWeightAverage,
										borderColor: theme.colors.primary['900'],
										label: {
											...annotationBaseOptions.label,
											xAdjust: 160,
											backgroundColor: theme.colors.primary['900'],
											content: [t('DashboardPage.elwisAvg'), `${sortedWeightAverage.toFixed(2)}kg`]
										}
									},
									line3: {
										...annotationBaseOptions,
										xMin: 0.5,
										xMax: 2,
										yMin: averageUnsortedGlobal,
										yMax: averageUnsortedGlobal,
										label: {
											...annotationBaseOptions.label,
											xAdjust: -160,
											content: [t('DashboardPage.slovakAvg'), `${averageUnsortedGlobal}kg`]
										}
									},
									line4: {
										...annotationBaseOptions,
										xMin: 0.5,
										xMax: 2,
										borderColor: theme.colors.primary['900'],
										yMin: unsortedWeightAverage,
										yMax: unsortedWeightAverage,
										label: {
											...annotationBaseOptions.label,
											xAdjust: 160,
											backgroundColor: theme.colors.primary['900'],
											content: [t('DashboardPage.elwisAvg'), `${unsortedWeightAverage.toFixed(2)}kg`]
										}
									}
								}
							}
						}
					}
				})
			}
		}
	}, [summaryState, t, year])

	useEffect(() => {
		loadSummary({ year, municipalityID })
	}, [year, municipalityID, loadSummary])

	let content

	if (summaryState.isLoading) {
		content = <SpinLoading height={'100%'}/>
	} else if (summaryState.data) {
		content = <canvas height={'100%'} ref={canvasRef}/>
	}

	return (
		<Row gutter={[16, 16]} justify={'space-between'}>
			<Col>
				<SubHeading>{t('DashboardPage.wastesPerCustomerTitle')}</SubHeading>
			</Col>
			<Col>
				<Dropdown
					overlay={(
						<Menu selectedKeys={[year]}>
							{map(years, (year) => (
								<Menu.Item
									key={year}
									onClick={() => setYear(year.toString())}
								>
									{year}
								</Menu.Item>
							))}
						</Menu>
					)}
				>
					<Button className={'secondary center'} style={{ width: 75 }}>
						<span>{year}</span>
						<DownOutlined/>
					</Button>
				</Dropdown>
			</Col>
			<Col span={24}>
				<ChartWrapper>
					{content}
				</ChartWrapper>
			</Col>
		</Row>
	)
}

export default React.memo(WastesPerCustomer)
