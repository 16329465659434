import { Theme } from 'styled-components'

const theme: Theme = {
	colors: {
		input: '#000000',
		inputPlaceholder: '#727272',
		label: '#3f4047',
		error: '#f03e41',
		warn: '#f0a03e',
		primary: {
			50: '#F4F9E7',
			100: '#E4F0C4',
			300: '#C1DD74',
			500: '#A6CE39',
			700: '#95C22C',
			900: '#7BB017'
		},
		border: '#ccc',
		text: '#1C1C1C',
		lightText: '#979797',
		darkBackground: '#262845',
		neutral: {
			50: '#F9FAF9',
			100: '#EFF2F3',
			200: '#D9DFE4',
			300: '#B1BEC3',
			400: '#80969A',
			500: '#627375',
			600: '#4E5857',
			700: '#3C4241',
			800: '#292C2E',
			900: '#181A1D'
		}
	},
	focusShadow: '0 2px 13px 0 hsl(0deg 0% 70% / 13%)',
	errorShadow: '0 2px 13px 0 rgb(233 187 187 / 50%)',
	boxShadow: '5px 5px 25px 0 rgba(179, 179, 179, 0.25)'
}

export default theme
