import React, { useCallback } from 'react'
import { Col, Row } from 'antd'
import * as PropTypes from 'prop-types'

import { debounce, map } from 'lodash'
import { Field, reduxForm, propTypes } from 'redux-form'

import { useTranslation } from 'react-i18next'

import locale from '../../../resources/locale'

import { ENDPOINTS, FORMS } from '../../../utils/enums'
import Button from '../../../components/buttons/Button'
import AsyncSelectField from '../../../atoms/AsyncSelectField'
import { validate, validatorOptions } from '../../../utils/validator'
import { getReq } from '../../../utils/request'

const searchPromise = async (search, callback, municipalityID) => {
	const context = { order: 'name:ASC', municipalityID }

	if (search && search.length) {
		context.selectSearch = search
	}

	try {
		const response = await getReq(ENDPOINTS.CUSTOMERS, context)
		const items = map(response.data.customers, (item) => ({
			label: `${item.name}${item.address ? ` - ${item.address}` : ''}`,
			value: item.id
		}))

		return callback(items)
	} catch (e) {
		return callback([])
	}
}

const debouncedSearch = debounce((search, callback, municipalityID) => {
	searchPromise(search, callback, municipalityID)
}, 200)

const validateRequired = validate([validatorOptions.REQUIRED])

const CustomerCodesAssignForm = ({ handleCancel, handleSubmit, municipality }) => {
	const [t] = useTranslation()

	const loadCustomers = useCallback((search, callback) => {
		debouncedSearch(search, callback, municipality.id)
	}, [municipality.id])

	const customerFieldOptions = {
		label: t('loc:Platca'),
		required: true,
		showLabel: true,
		loadOptions: loadCustomers
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={'form-horizontal'}
			noValidate
			autoComplete={'off'}
		>
			<div className={'modal-header'}>
				{locale['pages.customerCodes.assign.title']}
			</div>
			<div className={'modal-content'}>
				<div className="row" style={{ marginBottom: '20px' }}>
					<Field
						name='customerID'
						placeholder={locale['field.placeholder.customer']}
						component={AsyncSelectField}
						validate={validateRequired}
						props={customerFieldOptions}
					/>
				</div>
			</div>
			<div className={'modal-footer clearfix'}>
				<Row gutter={16} style={{ marginTop: 16 }}>
					<Col>
						<Button
							type="submit"
						>
							{locale['pages.customerCodes.assign']}
						</Button>
					</Col>
					<Col>
						<Button
							className={'secondary'}
							onClick={handleCancel}
						>
							{locale['common.cancel']}
						</Button>
					</Col>
				</Row>
			</div>
		</form>
	)
}

CustomerCodesAssignForm.propTypes = {
	handleCancel: PropTypes.func.isRequired,
	municipality: PropTypes.object.isRequired,
	...propTypes
}

export default reduxForm({
	form: FORMS.ASSIGN_CUSTOMERS_CODES,
	destroyOnUnmount: true,
	touchOnChange: true,
	forceUnregisterOnUnmount: true
})(CustomerCodesAssignForm)
