import React, { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { map } from 'lodash'

import { Col, Row } from 'antd'
import { FormApi } from 'final-form'

import { Field, Form, FormSpy } from 'react-final-form'

import { ASSIGN_TYPE } from '../../../types/enums'

import { containers, containerTypes as containersTypesApi } from '../../../api'
import { getContainerTypesByCollectionPlaceType } from '../../../utils/utils'
import { composeValidators, validateArrayMin, validateRequired } from '../../../utils/form'
import { COLLECTION_PLACE_TYPE, DEFAULT_WASTE_TYPE_COLOR } from '../../../utils/enums'

import { useDataLoader } from '../../../hooks/useDataLoader'

import Button from '../../../components/buttons/Button'
import WasteTypeLabel from '../../../components/WasteTypeLabel'
import Dot from '../../../components/Dot'

import SelectField from '../../../components/form/SelectField'
import DateField from '../../../components/form/DateField'
import RadioButtonField from '../../../components/form/RadioButtonField'
import TextField from '../../../components/form/TextField'
import { Modal, ModalContent, ModalFooter, ModalHead } from '../../../components/Modal'

import { HeadingFive } from '../../../components/Typography'

export type FormValues = {
	containerType: { value: number, label: string }
	assignedFrom: string
	type: ASSIGN_TYPE

	codes: string[]

	firstCode: string
	lastCode: string
	count: number
}

type Props = {
	municipalityID: number
	collectionPlaceType: COLLECTION_PLACE_TYPE
	visible: boolean
	onClose: () => void
	handleSubmit: (values: FormValues, form: FormApi<FormValues>) => void
}

type CodesCountProps = {
	type: ASSIGN_TYPE
	codes: string[]
	firstCode: string
	lastCode: string
}

const CodesCount = React.memo(({ type, codes, firstCode, lastCode }: CodesCountProps) => {
	const [t] = useTranslation()

	const [count, setCount] = useState(0)

	useEffect(() => {
		const loadCount = async () => {
			try {
				if (firstCode && lastCode) {
					const { data } = await containers.loadContainerCodesCount(firstCode, lastCode)

					if (data.count) {
						setCount(data.count)
					}
				} else {
					setCount(0)
				}
			} catch (e) {
				// Error
			}
		}

		if (type === ASSIGN_TYPE.DEFAULT) {
			if (codes?.length && codes.length !== count) {
				setCount(codes.length)
			} else if (!codes?.length) {
				setCount(0)
			}
		} else if (type === ASSIGN_TYPE.FIRST_AND_LAST) {
			loadCount()
		}
	}, [type, codes?.length, firstCode, lastCode, count])

	return (
		<HeadingFive>
			{t('CollectionPlaceContainerModal.codesCount', { count })}
		</HeadingFive>
	)
})

const CollectionPlaceContainerModal = (props: Props) => {
	const {
		municipalityID,
		collectionPlaceType,
		visible,
		onClose,
		handleSubmit
	} = props

	const [t] = useTranslation()

	const [containerTypesState, loadContainerTypes] = useDataLoader(containersTypesApi.loadContainerTypes)

	const lastCodeRef = useRef<HTMLInputElement>(null)

	const containerTypes = useMemo(() => getContainerTypesByCollectionPlaceType(collectionPlaceType), [collectionPlaceType])

	const handleCodeEnter = (e: KeyboardEvent) => {
		if (e.code === 'Enter') {
			e.preventDefault()
			lastCodeRef.current?.select()
			lastCodeRef.current?.focus()
		}
	}

	const containerTypesOptions = useMemo(() => map(containerTypesState.data?.containerTypes, (item) => ({
		value: item.id,
		label: <WasteTypeLabel><Dot color={`#${item.wasteType?.color || DEFAULT_WASTE_TYPE_COLOR}`}/><span>{item.name}</span></WasteTypeLabel>,
		extra: {
			containerTypeName: item.name
		}
	})), [containerTypesState.data])

	const typeOptions = useMemo(() => [{
		value: ASSIGN_TYPE.DEFAULT, label: t('ASSIGN_TYPE.DEFAULT')
	}, {
		value: ASSIGN_TYPE.FIRST_AND_LAST, label: t('ASSIGN_TYPE.FIRST_AND_LAST')
	}], [t])

	useEffect(() => {
		loadContainerTypes(municipalityID, { limit: 'ALL', types: containerTypes })
	}, [containerTypes, municipalityID, loadContainerTypes])

	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			width={650}
			footer={null}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={{ type: ASSIGN_TYPE.DEFAULT, assignedFrom: dayjs().startOf('day').toISOString(), count: 1 }}
				render={({ handleSubmit, submitting }) => (
					<>
						<ModalHead>
							{t('CollectionPlaceContainerModal.title')}
						</ModalHead>
						<ModalContent>
							<form onSubmit={handleSubmit}>
								<Row gutter={[16, 16]}>
									<Col span={24}>
										<Field
											name={'containerType'}
											validate={validateRequired}
											render={(props) => (
												<SelectField
													{...props}
													required
													labelInValue={true}
													isLoading={containerTypesState.isLoading}
													options={containerTypesOptions}
													label={t('fields.containerType.label')}
													placeholder={t('fields.containerType.placeholder')}
													hasExtra
												/>
											)}
										/>
									</Col>
									<Col span={24} md={12}>
										<Field
											name={'assignedFrom'}
											validate={validateRequired}
											render={(props) => (
												<DateField
													{...props}
													required
													allowClear={false}
													format={t('dateFormat')}
													label={t('fields.assignedFrom.label')}
													placeholder={t('fields.assignedFrom.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24}>
										<Field
											name={'type'}
											render={(props) => (
												<RadioButtonField
													{...props}
													options={typeOptions}
												/>
											)}
										/>
									</Col>
									<Col span={24}>
										<Field
											name={'type'}
											render={({ input }) => {
												if (input.value === ASSIGN_TYPE.DEFAULT) {
													return (
														<Field
															name={'codes'}
															validateFields={[]}
															validate={composeValidators(validateRequired, validateArrayMin(1))}
															render={(props) => (
																<SelectField
																	{...props}
																	mode={'tags'}
																	options={[]}
																	tokenSeparators={[',', ' ']}
																	required
																	label={t('fields.codes.label')}
																	placeholder={t('fields.codes.placeholder')}
																/>
															)}
														/>
													)
												}

												if (input.value === ASSIGN_TYPE.FIRST_AND_LAST) {
													return (
														<Row gutter={[16, 16]}>
															<Col span={24} md={12}>
																<Field
																	name={'firstCode'}
																	validateFields={[]}
																	validate={validateRequired}
																	render={(props) => (
																		<TextField
																			{...props}
																			required
																			onKeyPress={handleCodeEnter}
																			label={t('fields.firstCode.label')}
																			placeholder={t('fields.firstCode.placeholder')}
																		/>
																	)}
																/>
															</Col>
															<Col span={24} md={12}>
																<Field
																	name={'lastCode'}
																	validateFields={[]}
																	validate={validateRequired}
																	render={(props) => (
																		<TextField
																			{...props}
																			required
																			onKeyPress={handleCodeEnter}
																			inputRef={lastCodeRef}
																			label={t('fields.lastCode.label')}
																			placeholder={t('fields.lastCode.placeholder')}
																		/>
																	)}
																/>
															</Col>
														</Row>
													)
												}

												return null
											}}
										/>
									</Col>
									<Col span={24}>
										<FormSpy
											subscription={{ values: true }}
											render={({ values }) => (
												<CodesCount
													type={values.type}
													codes={values.codes}
													firstCode={values.firstCode}
													lastCode={values.lastCode}
												/>
											)}
										/>
									</Col>
								</Row>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row justify={'end'} gutter={[16, 8]}>
								<Col>
									<Button
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose()
										}}
									>
										{t('common.close')}
									</Button>
								</Col>
								<Col>
									<Button
										loading={submitting}
										disabled={submitting}
										onClick={handleSubmit}
									>
										{t('common.submit')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default CollectionPlaceContainerModal
