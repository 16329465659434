import React, { Suspense } from 'react'

// @ts-ignore
import moment from 'moment-timezone'
import { ThemeProvider } from 'styled-components'

import { has, get } from 'lodash'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router'
import { ConfigProvider } from 'antd'

import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/es/integration/react'
import { ExtendedStringifyOptions, QueryParamProvider, transformSearchStringJsonSafe } from 'use-query-params'

import axios from 'axios'

import skSK from 'antd/es/locale/sk_SK'

import { ERROR } from './utils/enums'
import theme from './utils/theme'
import i18n from './utils/i18n'
import { history } from './utils/history'

import configureStore from './store/configureStore'

import { statusPush } from './actions/statusActions'
import { authenticationActions, getToken } from './redux/authentication'

import GlobalStyles from './components/GlobalStyles'
import Routes from './routes'

moment.tz.setDefault('Europe/Bratislava')

const {
	store,
	persistor
} = configureStore()

const queryStringifyOptions: ExtendedStringifyOptions = {
	transformSearchString: transformSearchStringJsonSafe
}

axios.interceptors.request.use((config) => {
	const state = store.getState()
	const token = getToken(state)

	if (token && !config.headers.Authorization) {
		config.headers.Authorization = `Bearer ${token}`
	}
	return config
})

axios.interceptors.response.use((response) => response, (error) => {
	if (has(error, 'response.status')) {
		if (error.response.status === 401) {
			store.dispatch(authenticationActions.logoutUser())
		} else if (error.response.data instanceof Blob) {
			error.response.data.text().then((text: string) => {
				const json = JSON.parse(text)
				const status = {
					type: ERROR,
					code: error.response.status,
					data: error.response.data,
					msg: get(json, 'messages[0].message')
				}

				store.dispatch(statusPush(status))
			}).catch((error: any) => {
				if (error && error.response) {
					const status = {
						type: ERROR,
						code: error?.response?.status,
						data: error?.response?.data
					}

					store.dispatch(statusPush(status))
				}
			})
		} else {
			const status = {
				type: ERROR,
				code: error.response.status,
				data: error.response.data,
				msg: get(error, 'response.data.messages[0].message')
			}

			store.dispatch(statusPush(status))
		}
	}
	return Promise.reject(error)
})

const App = () => (
	<ThemeProvider theme={theme}>
		<GlobalStyles/>
		<Suspense fallback={<div/>}>
			<ConfigProvider locale={skSK}>
				<I18nextProvider i18n={i18n}>
					<PersistGate loading={<div/>} persistor={persistor}>
						<Provider store={store}>
							<Router history={history}>
								<QueryParamProvider ReactRouterRoute={Route} stringifyOptions={queryStringifyOptions}>
									<Routes/>
								</QueryParamProvider>
							</Router>
						</Provider>
					</PersistGate>
				</I18nextProvider>
			</ConfigProvider>
		</Suspense>
	</ThemeProvider>
)

export default App
