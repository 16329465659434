import React from 'react'
import i18next from 'i18next'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../../resources/locale'

// components
import CustomersTableRow from './CustomersTableRow'

// atoms
import TableHead from '../../../atoms/TableHead'
import { EmptyTableState } from '../../../components/EmptyTableState'

class CustomersTable extends React.Component {
	static propTypes = {
		customers: PropTypes.arrayOf(PropTypes.object),
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		isSorting: PropTypes.string,
		isSortingColumn: PropTypes.string,
		isSortingDirection: PropTypes.string,
		onDelete: PropTypes.func.isRequired
	}

	render() {
		const { customers, loading, onSetActive, onDetail, onSort, isSortingColumn, isSortingDirection, onDelete } = this.props
		const rows = map(customers, (customer, index) =>
			<CustomersTableRow
				key={get(customer, 'id')}
				customer={customer}
				onDetail={onDetail}
				onSetActive={onSetActive}
				countIndex={index}
				onDelete={onDelete}
			/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={isSortingColumn === 'name'}
								title={i18next.t('loc:Názov platcu')}
								initDirection={isSortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'address'}
								isSorting={isSortingColumn === 'address'}
								title={i18next.t('loc:Adresa')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'legalForm'}
								isSorting={isSortingColumn === 'legalForm'}
								title={i18next.t('loc:Právna forma')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'activeTo'}
								isSorting={isSortingColumn === 'activeTo'}
								title={i18next.t('loc:Stav')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && customers && !customers.length && <EmptyTableState title={locale['page.customers.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.users.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default CustomersTable
