if (!HTMLCanvasElement.prototype.toBlob) {
	Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
		value(callback, type, quality) {
			const canvas = this
			setTimeout(() => {
				const binStr = atob(canvas.toDataURL(type, quality).split(',')[1])
				const len = binStr.length
				const arr = new Uint8Array(len)

				for (let i = 0; i < len; i++) {
					arr[i] = binStr.charCodeAt(i)
				}

				callback(new Blob([arr], { type: type || 'image/png' }))
			})
		}
	})
}

export function readImage(file) {
	return new Promise((resolve, reject) => {
		const _URL = window.URL || window.webkitURL
		const image = new Image()
		image.onload = function onLoad() {
			return resolve(image)
		}
		image.onerror = (error) => reject(error)
		image.src = _URL.createObjectURL(file)
	})
}

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} percentCrop - pixelCrop Object provided by react-image-crop
 */
export function getCroppedImage(image, percentCrop) {
	const canvas = document.createElement('canvas')
	canvas.width = image.width * percentCrop.width / 100
	canvas.height = image.height * percentCrop.height / 100
	const ctx = canvas.getContext('2d')

	ctx.drawImage(
		image,
		image.naturalWidth * percentCrop.x / 100,
		image.naturalHeight * percentCrop.y / 100,
		image.naturalWidth * percentCrop.width / 100,
		image.naturalHeight * percentCrop.height / 100,
		0,
		0,
		image.naturalWidth * percentCrop.width / 100,
		image.naturalHeight * percentCrop.height / 100
	)

	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			resolve(blob)
		}, 'image/jpeg', 1)
	})
}

/**
 * @return {String} UUID - UUID string
 */
export function guid() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	}
	return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}
