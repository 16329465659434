import React from 'react'
import Creatable from 'react-select/creatable'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, uniq } from 'lodash'
import { customStyles } from '../utils/select'

import locale from '../resources/locale'
import { KEYS } from '../utils/enums'

class CreatableSelectField extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			inputValue: ''
		}
	}

	handleChange = (option) => {
		this.setState({ inputValue: '' })
		const { input, handleOnChange } = this.props
		const value = map(option, (o) => o.value)
		input.onChange(value)
		if (handleOnChange) {
			handleOnChange(value)
		}
	}

	handleOnBlur = () => {
		const { input } = this.props
		input.onBlur()
	}

	handleInputChange = (inputValue) => {
		this.setState({ inputValue })
	}

	handleKeyDown = (event) => {
		const { input } = this.props
		const { inputValue } = this.state
		let value = null
		switch (event.key) {
			case KEYS.ENTER:
			case KEYS.TAB:
				value = map(uniq([...input.value, inputValue]), (val) => ({ value: val, label: val })) || []

				this.handleChange(value)
				event.preventDefault()
				break
			default: break
		}
	}

	render() {
		const { input, name, label, placeholder, showLabel, isDisabled, meta: { touched, error } } = this.props
		const { inputValue } = this.state
		const value = map(input.value, (val) => ({ value: val, label: val }))

		return (
			<div className={cx('input-wrapper select-input', { error: touched && error })}>
				{showLabel && <label htmlFor={name}>{label}</label>}
				<Creatable
					isMulti
					isClearable={false}
					name={name}
					placeholder={placeholder}
					onChange={this.handleChange}
					onBlur={this.handleOnBlur}
					onKeyDown={this.handleKeyDown}
					onInputChange={this.handleInputChange}
					// clearValue={this.clearValue}
					value={value}
					inputValue={inputValue}
					isDisabled={isDisabled}
					menuIsOpen={false}
					styles={customStyles}
					className={'react-select'}
				/>
				<div className={'close-error'}>
					<div className={'tooltip'}>
						{touched ? error : ''}
					</div>
				</div>
			</div>
		)
	}
}

CreatableSelectField.propTypes = {
	input: PropTypes.object,
	options: PropTypes.object,
	isMulti: PropTypes.bool,
	meta: PropTypes.object,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	value: PropTypes.object,
	handleOnChange: PropTypes.func
}

CreatableSelectField.defaultProps = {
	showLabel: false,
	placeholder: locale['field.placeholder.search'],
	label: ''
}

export default CreatableSelectField
