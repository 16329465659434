import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { join, get, map, find } from 'lodash'
import { reset, initialize } from 'redux-form'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { MODAL, INFO, FORMS, ENDPOINTS } from '../../utils/enums'

import { statusPush } from '../../actions/statusActions'
import { updateUser } from '../../actions/userActions'
import locale from '../../resources/locale'
import DialogModal from '../../components/modals/DialogModal'
import UserForm from '../../components/users/UserForm'
import { history } from '../../utils/history'
import UserProfileForm from '../../components/users/UserProfileForm'
import UserInfo from '../../components/users/UserInfo'
import { canUpdateUser } from '../../utils/permissions'
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'
import UserMunicipalityForm from '../../components/users/UserMunicipalityForm'
import Breadcrumbs from '../../components/Breadcrumb'
import ChangePassword from '../../components/modals/ChangePassword'
import { getAuthUser } from '../../redux/authentication'

class UserPage extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		user: PropTypes.object.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		updateUser: PropTypes.func.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				userID: PropTypes.string
			}).isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)
		this.state = {
			modal: null,
			modalData: null,
			loading: true,
			user: null
		}
	}

	componentDidMount() {
		this.loadUser()
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate() {
		if (this._mounted) {
			const { user, loading } = this.state
			const { user: me } = this.props
			if (!loading) {
				if (window.location.pathname === locale['path.profile'] && get(user, 'id') !== me.data?.id) {
					this.setupForm(me)
				} else if (window.location.pathname !== locale['path.profile'] && get(user, 'id') === me.data?.id) {
					this.loadUser()
				}
			}
		}
	}

	loadUser = async () => {
		const { match, user } = this.props
		if (window.location.pathname === locale['path.profile']) {
			this.setupForm(user.data)
		} else {
			const id = match.params.userID
			const { municipalityID } = match.params
			try {
				const endpoint = municipalityID ? ENDPOINTS.USER_MUNICIPALITY(municipalityID, id) : ENDPOINTS.USER(id)
				const response = await getReq(endpoint)
				this.setupForm(response.data)
			} catch (error) {
				history.push(locale['path.users'])
			}
		}
	}

	setupForm = (user) => {
		const { initialize, reset } = this.props
		this.setState({
			user,
			loading: false
		})
		initialize(FORMS.USER_FORM, this.getInitialValues(user))
		reset(FORMS.USER_FORM)
	}

	setActiveUser = async (isActive) => {
		const { user } = this.state
		const { pushStatus } = this.props
		try {
			await patchReq(ENDPOINTS.USER(user.id), null, { isActive })
			pushStatus({
				type: INFO,
				msg: isActive ? locale['page.users.activate.success'] : locale['page.users.deactivate.success']
			})
		} catch (error) {
			// Error
		}
	}

	setUserRemoved = async () => {
		const { pushStatus } = this.props
		const { user } = this.state
		this.dismissModal()
		try {
			await deleteReq(ENDPOINTS.USER(user.id), null)
			history.replace(this.returnPath)
			pushStatus({
				type: INFO,
				msg: locale['page.users.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	resendInvitation = async () => {
		const { pushStatus } = this.props
		const { user } = this.state
		try {
			await postReq(ENDPOINTS.USER_RESEND(user.id), null, null)
			pushStatus({
				type: INFO,
				msg: locale['page.users.detail.invitation.resend.success']
			})
		} catch (error) {
			// Error
		}
	}

	saveUpdates = async (values) => {
		const { user: me, municipality, updateUser, pushStatus } = this.props
		const data = {
			name: values.name,
			surname: values.surname,
			email: values.email
		}

		const { user } = this.state
		if (user.id === me.data?.id || (me.data?.isAdmin && !municipality)) {
			try {
				data.municipalities = map(values.municipalities, (item) => ({
					id: item.municipality.value,
					permissions: item.permissions
				}))
				if (user.id === me.data?.id) {
					delete data.municipalities
					data.notifications = values.notifications
				}
				const endpoint = user.id === me.data?.id ? ENDPOINTS.PROFILE : ENDPOINTS.USER(user.id)
				await patchReq(endpoint, null, data)
				this.returnToList()
				if (user.id === me.data?.id) {
					updateUser(data)
					pushStatus({
						type: INFO,
						msg: locale['page.users.detail.profile.update.success']
					})
				} else {
					pushStatus({
						type: INFO,
						msg: locale['page.users.detail.update.success']
					})
				}
			} catch (error) {
				// Error
			}
		} else {
			try {
				data.permissions = values.permissions
				await patchReq(ENDPOINTS.USER_MUNICIPALITY(municipality.id, user.id), null, data)
				this.returnToList()
				pushStatus({
					type: INFO,
					msg: locale['page.users.detail.update.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	changePassword = async (values) => {
		try {
			const { pushStatus } = this.props
			const data = {
				oldPassword: values.oldPassword,
				newPassword: values.newPassword
			}
			await postReq(ENDPOINTS.CHANGE_PASSWORD, null, data)
			this.dismissModal()
			pushStatus({
				type: INFO,
				msg: locale['page.users.detail.change.password.success']
			})
		} catch (e) {
			// Error
		}
		const { reset } = this.props
		reset(FORMS.CHANGE_PASSWORD)
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	openModal = (modal) => () => {
		this.setState({
			modal
		})
	}

	getInitialValues = (user) => {
		const { user: me, municipality: managed } = this.props
		const data = {
			name: user.name,
			surname: user.surname,
			email: user.email,
			createdAt: user.createdAt,
			lastLoginAt: user.lastLoginAt,
			isConfirmed: user.isConfirmed
		}

		if (user.id === me.data?.id) {
			data.notifications = me.data?.notifications
			return data
		}

		if (managed) {
			const municipality = find(user.municipalities, (municipality) => municipality.id === managed.id)
			data.permissions = municipality.permissions
		} else if (me.data?.isAdmin) {
			data.municipalities = map(user.municipalities, (municipality) => ({
				municipality: { value: municipality.id, label: municipality.name },
				permissions: municipality.permissions
			}))
		}

		return data
	}

	breadcrumbsItems = (user) => {
		const { municipality, user: me, match } = this.props
		const { municipalityID } = match.params

		const items = []
		if (user.id === me.data?.id) {
			this.returnPath = get(history, 'location.state.returnPath', locale['path.index'])

			items.push({
				key: 'page.users.detail.profile',
				name: locale['page.users.detail.profile']
			})
			items.push({
				key: 'page.users.detail.breadcrumbs',
				name: join([user.name, user.surname], ' ')
			})
		} else {
			if (municipalityID) {
				this.returnPath = locale.formatString(locale['path.municipality.users'], municipalityID)
				items.push({
					key: 'menu.section.municipality',
					name: locale['menu.section.municipality']
				})
				items.push({
					key: 'page.municipalities.detail',
					name: municipality.name
				})
			} else {
				this.returnPath = locale['path.administration.users']
				items.push({
					key: 'page.administration.breadcrumbs',
					name: locale['page.administration.breadcrumbs']
				})
			}
			items.push({
				key: 'page.users.breadcrumbs',
				name: locale['page.users.breadcrumbs'],
				link: municipality ? locale.formatString(locale['path.municipality.users'], municipality.id) : locale['path.administration.users']
			})
			items.push({
				key: 'page.users.detail.breadcrumbs',
				name: user.name || user.surname ? join([user.name, user.surname], ' ') : user.email
			})
		}
		let action = null
		if (user.id !== me.data?.id && !municipality) {
			action = {
				...this.action(user).action,
				checked: user.isActive
			}
		}

		return { items, action }
	}

	returnToList = () => {
		history.push(this.returnPath)
	}

	switchAction = {
		onChange: this.setActiveUser,
		onColor: '#AFCA0B',
		offColor: '#F03E41',
		onLabel: locale['page.users.detail.deactivate'],
		offLabel: locale['page.users.detail.activate']
	}

	action = (user) => {
		const { match, user: me } = this.props
		const { municipalityID } = match.params

		if (user.id !== me.data?.id && !municipalityID) {
			const action = {
				...this.switchAction,
				checked: user.isActive
			}
			return { action }
		}

		return undefined
	}

	render() {
		const { loading, user, modal } = this.state
		const { user: me, match } = this.props

		if (loading) {
			return (
				<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
					<div className={cx('table-loading', { hidden: !loading })}>
						<div>
							<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
							<span>{locale['page.users.detail.loading']}</span>
						</div>
					</div>
				</div>
			)
		}

		let form = null
		let modalComponent = null

		if (user.id === me.data?.id) {
			form = (
				<UserProfileForm
					initialValues={this.getInitialValues(user)}
					passwordHandler={this.openModal(MODAL.CHANGE_PASSWORD)}
					submitHandler={this.saveUpdates}
					cancelHandler={this.returnToList}
				/>
			)
			modalComponent = (
				<ChangePassword
					shown={modal === MODAL.CHANGE_PASSWORD}
					submitHandler={this.changePassword}
					dismissHandler={this.dismissModal}
				/>
			)
		} else if (!canUpdateUser(user, me)) {
			form = (
				<UserInfo
					user={user}
					resendHandler={this.resendInvitation}
				/>
			)
		} else if (match.params.municipalityID) {
			form = (
				<UserMunicipalityForm
					initialValues={this.getInitialValues(user)}
					submitHandler={this.saveUpdates}
					resendHandler={this.resendInvitation}
					cancelHandler={this.returnToList}
					deleteHandler={this.openModal(MODAL.REMOVE_USER)}
				/>
			)
		} else {
			form = (
				<UserForm
					initialValues={this.getInitialValues(user)}
					submitHandler={this.saveUpdates}
					resendHandler={this.resendInvitation}
					cancelHandler={this.returnToList}
					deleteHandler={this.openModal(MODAL.REMOVE_USER)}
					{...this.action(user)}
				/>
			)
			modalComponent = (
				<DialogModal
					shown={modal === MODAL.REMOVE_USER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setUserRemoved}
					message={locale['page.users.delete.message']}
					title={locale.formatString(locale['page.users.delete.title'], join([get(user, 'name'), get(user, 'surname')], ' '))}
					acceptTitle={locale['page.users.delete.accept']}
				/>
			)
		}

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems(user)}
				/>
				{form}
				{modalComponent}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state),
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	reset: bindActionCreators(reset, dispatch),
	updateUser: bindActionCreators(updateUser, dispatch),
	initialize: bindActionCreators(initialize, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
