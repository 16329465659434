import { map } from 'lodash'
import { FILTER_TYPE } from '../../utils/enums'
import { QUERY_PARAMS_TYPES } from '../../utils/queryParams'

export const queryParams = {
	order: 'order',
	page: 'page',
	limit: 'limit',
	columns: 'columns',

	collectionsCountFrom: 'collectionsCountFrom',
	collectionsCountTo: 'collectionsCountTo',
	codeSearch: 'codeSearch',
	serialNumberSearch: 'serialNumberSearch',
	rfidSearch: 'rfidSearch',
	rfidSerialSearch: 'rfidSerialSearch',
	addressSearch: 'addressSearch',
	nameSearch: 'nameSearch',
	containerTypeNameSearch: 'containerTypeNameSearch',
	municipalityNameSearch: 'municipalityNameSearch',
	containerTypeIDs: 'containerTypeIDs',
	dateFrom: 'dateFrom',
	dateTo: 'dateTo'
}

export const columns = [
	{
		name: 'municipalityName',
		sortable: true,
		title: 'Obec',
		filter: {
			name: 'municipalityNameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'subjectName',
		sortable: true,
		title: 'Meno',
		filter: {
			name: 'nameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'subjectAddress',
		sortable: true,
		title: 'Adresa',
		filter: {
			name: 'addressSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'containerType',
		sortable: true,
		title: 'Typ nádoby',
		filter: {
			name: 'containerTypeIDs',
			type: FILTER_TYPE.SELECT,
			isLoading: true
		}
	}, {
		name: 'code',
		sortable: true,
		title: 'Kód',
		filter: {
			name: 'codeSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'serialNumber',
		sortable: true,
		title: 'Číslo',
		filter: {
			name: 'serialNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'rfid',
		sortable: true,
		className: 'wide',
		title: 'RFID',
		filter: {
			name: 'rfidSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'rfidSerial',
		sortable: true,
		title: 'RFID sériové číslo',
		filter: {
			name: 'rfidSerialSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'collectionsCount',
		sortable: true,
		title: 'Počet záznamov',
		render: (val) => val || 0,
		filter: {
			type: FILTER_TYPE.NUMBER_RANGE
		}
	}
]

export const defaultColumns = [columns[1].name, columns[2].name, columns[3].name, columns[5].name, columns[7].name, columns[8].name]

export const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'collectionsCount:ASC',
		allowedValues: [
			'municipalityName', 'subjectName', 'subjectAddress', 'containerType', 'code', 'serialNumber', 'rfid', 'rfidSerial', 'collectionsCount'
		]
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	columns: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: map(defaultColumns, (col) => col.name)
	},

	collectionsCountFrom: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	collectionsCountTo: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	codeSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	serialNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	rfidSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	rfidSerialSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	addressSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	nameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	containerTypeIDs: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	municipalityNameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	dateFrom: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	},
	dateTo: {
		type: QUERY_PARAMS_TYPES.DATE,
		defaultValue: null
	}
}
