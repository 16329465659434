import React from 'react'
import Async from 'react-select/async'
import cx from 'classnames'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { customStyles } from '../utils/select'

import locale from '../resources/locale'

class AsyncSelectField extends React.Component {
	handleOnChange = (value) => {
		const { input } = this.props
		input.onChange(value)
	}

	render() {
		const { key, input, name, label, placeholder, showLabel, isMulti, loadOptions, meta: { touched, error }, defaultOptions } = this.props

		return (
			<div className={cx('input-wrapper select-input', { error: touched && error })}>
				{showLabel && <label htmlFor={name}>{label}</label>}
				<Async
					key={key}
					defaultOptions={defaultOptions}
					isClearable
					onChange={this.handleOnChange}
					className={'react-select'}
					name={name}
					placeholder={placeholder}
					isMulti={isMulti}
					loadOptions={loadOptions}
					styles={customStyles}
					value={input.value}
					noOptionsMessage={() => i18next.t('loc:Žiadne možnosti')}
					loadingMessage={() => `${i18next.t('loc:Načítavam možnosti')}...`}
				/>
				<div className={'close-error'}>
					<div className={'tooltip'}>
						{touched ? error : ''}
					</div>
				</div>
			</div>
		)
	}
}

AsyncSelectField.propTypes = {
	key: PropTypes.string,
	input: PropTypes.object,
	options: PropTypes.object,
	isMulti: PropTypes.bool,
	meta: PropTypes.object,
	loadOptions: PropTypes.func.isRequired,
	defaultOptions: PropTypes.oneOfType([
		PropTypes.array, PropTypes.bool
	]),
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	onInputChange: PropTypes.func
}

AsyncSelectField.defaultProps = {
	defaultOptions: true,
	showLabel: false,
	placeholder: locale['field.placeholder.search'],
	label: ''
}

export default AsyncSelectField
