const production = {
	baseUrl: ''
}

const development = {
	baseUrl: '',
	cacheTime: 0
}

let writeConfig = {

}

switch (process.env.NODE_ENV) {
	case 'production':
		writeConfig = {
			...writeConfig,
			...production
		}
		break
	case 'development':
		writeConfig = {
			...writeConfig,
			...development
		}
		break
	default:
		break
}

export default { ...writeConfig }
