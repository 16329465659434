import { createSelector } from 'reselect'
import { get, map } from 'lodash'

export const getWasteTypesList = (state) => state.wasteTypes.list
export const getWasteTypesSelectOptions = createSelector([getWasteTypesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.types'), (item) => ({
		value: item.id,
		label: item.name,
		color: item.color
	}))
}))
