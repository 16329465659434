import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import { Col, Row } from 'antd'
import Modal from './Modal'

import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'

import { validate, validatorOptions } from '../../utils/validator'
import { municipalityTypes } from '../../utils/select'
import { FORMS, MUNICIPALITY_TYPE } from '../../utils/enums'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateMunicipalityTypes = validate([validatorOptions.REQUIRED])

class CreateMunicipality extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		createHandler: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		municipalityType: PropTypes.oneOf(Object.values(MUNICIPALITY_TYPE)),
		wasteCompanies: PropTypes.shape({
			isLoading: PropTypes.bool.isRequired,
			values: PropTypes.array.isRequired
		}).isRequired
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	handleSubmit = (values) => {
		const { createHandler } = this.props
		createHandler({
			name: values.name,
			type: values.type,
			zip: values.zip,
			parentID: this.props.municipalityType !== MUNICIPALITY_TYPE.WASTE_COMPANY ? values.parentID : undefined
		})
	}

	municipalityTypeSelectFieldOptions = {
		label: i18next.t('loc:Typ obce'),
		required: true,
		showLabel: true,
		options: municipalityTypes
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov obce'),
		required: true
	}

	zipFieldOptions = {
		label: i18next.t('loc:PSČ'),
		required: true
	}

	parentIDSelectFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Priradená zvozová spoločnosť'),
		placeholder: i18next.t('loc:Priradená zvozová spoločnosť')
	}

	validateZip = (value, allValues) => {
		const options = [validatorOptions.ZIP]
		if (allValues.type !== MUNICIPALITY_TYPE.WASTE_COMPANY) {
			options.push(validatorOptions.REQUIRED_STRING)
		}
		return validate(options)(value)
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	render() {
		const { handleSubmit, dismissHandler, shown, municipalityType, wasteCompanies } = this.props
		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit((data) => this.handleSubmit(data))} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{i18next.t('loc:Pridať obec')}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='name'
									placeholder={i18next.t('loc:Zadajte názov obce')}
									component={TextInputField}
									props={this.nameFieldOptions}
									validate={validateName}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='type'
									placeholder={i18next.t('loc:Vyberte typ obce')}
									component={SelectField}
									props={this.municipalityTypeSelectFieldOptions}
									validate={validateMunicipalityTypes}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='zip'
									placeholder={i18next.t('loc:Zadajte PSČ')}
									component={TextInputField}
									props={this.zipFieldOptions}
									validate={this.validateZip}
								/>
							</div>
							{municipalityType !== MUNICIPALITY_TYPE.WASTE_COMPANY &&
							<div className={'col-12'}>
								<Field
									name='parentID'
									component={SelectField}
									props={this.parentIDSelectFieldOptions}
									options={wasteCompanies.values}
									isLoading={wasteCompanies.isLoading}
								/>
							</div>}
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{i18next.t('loc:Pridať obec')}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{i18next.t('loc:Zrušiť')}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CREATE_MUNICIPALITY,
	touchOnChange: true,
	destroyOnUnmount: true
})(CreateMunicipality)
