import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { QUANTITATIVE_COLLECTIONS_ACTIONS } from './constants'

export const quantitativeCollectionsLoadStart = {
	type: QUANTITATIVE_COLLECTIONS_ACTIONS.LIST_LOAD_START
}

export const quantitativeCollectionsLoadFail = {
	type: QUANTITATIVE_COLLECTIONS_ACTIONS.LIST_LOAD_FAIL
}

export const quantitativeCollectionsLoadDone = (data) => ({
	type: QUANTITATIVE_COLLECTIONS_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadQuantitativeCollections = (filters) => async (dispatch) => {
	dispatch(quantitativeCollectionsLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.STATISTICS_QUANTITATIVE_COLLECTIONS, query)

		dispatch(quantitativeCollectionsLoadDone(response.data))
	} catch (e) {
		dispatch(quantitativeCollectionsLoadFail)
	}
}
