import React, { useCallback } from 'react'
import cx from 'classnames'

import { FieldRenderProps } from 'react-final-form'
import { Info, InputWrapper, Label } from './Styled'
import RadioButton from '../buttons/RadioButton'

type Props = FieldRenderProps<string | number> & {
	options: { value: string | number, label: string }[]

	label?: string
	required?: boolean
	className?: string
	disabled?: boolean
}

const RadioButtonField = ({ meta, input, label, required, className, options }: Props) => {
	const error = meta.touched && meta.error

	const handleChange = useCallback((value: string | number | (string | number)[]) => {
		input.onBlur()
		input.onChange(value)
	}, [input])

	const componentClass = cx(className, { error })

	return (
		<InputWrapper className={className}>
			<Label>
				{label}{required ? '*' : ''}
			</Label>
			<RadioButton
				{...input}
				className={className}
				onChange={handleChange}
				options={options}
				type={'radio'}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default RadioButtonField
