import React from 'react'
import * as PropTypes from 'prop-types'
import { reduxForm, propTypes } from 'redux-form'
import { map, split } from 'lodash'

import cx from 'classnames'
import TableHeader from './TableHeader'

import { columnPropTypes } from './Table'
import TableColumns from './TableColumns'

const TableHead = ({ columns, change, handleSubmit, filters, onChangeColumns, tableColumns, onSort, defaultColumns, isSelectable, onSelect, isSelected, id }) => {
	const resetColumns = async (columns) => {
		// eslint-disable-next-line no-restricted-syntax
		for (const col of columns) {
			await change(col, null)
		}
		handleSubmit()
	}

	const order = async (value) => {
		if (onSort) {
			onSort(value)
		} else {
			await change('order', value)
			handleSubmit()
		}
	}

	const resetSelectedColumns = () => {
		change('columns', defaultColumns)
		onChangeColumns(defaultColumns)
	}

	const [orderColumn, direction] = split(filters.order, ':')

	return (
		<thead>
			<tr>
				{isSelectable && <th style={{ width: '70px' }}>
					<div className="checkbox-wrapper">
						<div
							className={cx('checkbox', { active: isSelected })}
							onClick={onSelect}
						/>
					</div>
				</th>}
				{map(columns, (column) =>
					<TableHeader
						id={id}
						key={column.name}
						direction={orderColumn === column.name ? direction || filters.direction : null}
						filters={filters}
						column={column}
						onSort={({ name, sort }) => order(`${name}:${sort}`)}
						onReset={resetColumns}
						onFilter={handleSubmit}
					/>)}
				<TableColumns
					id={id}
					columns={tableColumns}
					onChange={onChangeColumns}
					onResetColumns={resetSelectedColumns}
				/>
			</tr>
		</thead>
	)
}

TableHead.propTypes = {
	...propTypes,
	id: PropTypes.string.isRequired,
	filters: PropTypes.object.isRequired,
	defaultColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChangeColumns: PropTypes.func.isRequired,
	onSelect: PropTypes.func,
	onSort: PropTypes.func,
	isSelectable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool.isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired
	})),
	columns: PropTypes.arrayOf(PropTypes.shape(columnPropTypes)).isRequired
}

const createHead = (name) => reduxForm({
	form: name,
	destroyOnUnmount: false,
	updateUnregisteredFields: false
})(TableHead)

export default createHead
