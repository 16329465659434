import { createGlobalStyle, Theme } from 'styled-components'
import theme from '../utils/theme'

export default createGlobalStyle`
	#root {
		height: 100%;
	}

	#modal-root {
		position: relative;
		z-index: 999;
	}

	* {
		box-sizing: border-box;
	}

	html, body {
		font-family: 'Inter', sans-serif;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		z-index: 1;
		position: relative;
		color: #47525E;
	}

	button {
		font-family: 'Inter', sans-serif;
	}

	h1, h2, h3, h4, p {
		color: ${theme.colors.text};
	}

	.title-margin {
		margin-bottom: 32px;
	}

	.bold {
		font-weight: bold;
	}

	.medium {
		font-weight: 500;
	}

	.without-margin {
		margin: 0 !important;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.mo-flex-row {
		display: flex;

		&.mo-flex-end {
			justify-content: flex-end;
		}

		&.mo-flex-center {
			justify-content: center;
		}

		&.mo-flex-align-center {
			align-items: center;
		}
	}

	button:focus {
		outline: none !important;
	}

	input, textarea, button {
		font-family: 'Public Sans', sans-serif;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	input::-webkit-contacts-auto-fill-button,
	input::-webkit-credentials-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		height: 0;
		width: 0;
		margin: 0;
	}

	.ant-picker-ranges {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;

		.ant-picker-ok button {
			border: none;
			background: ${({ theme }: { theme: Theme }) => theme.colors.primary['500']};
			color: white;
			border-radius: 15px;
			padding: 5px 10px;
			cursor: pointer;
		}
	}

	.ant-table-wrapper {
		.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
			position: absolute;
			top: 50%;
			right: 0;
			width: 1px;
			height: 1.6em;
			background-color: ${({ theme }) => theme.colors.neutral['200']};
			transform: translateY(-50%);
			transition: background-color .3s;
			content: "";
		}

		.ant-table {
			.ant-table-thead > tr > th {
				background: white;
				border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['200']};
			}

			.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
				color: ${({ theme }) => theme.colors.neutral['900']};
			}

			.ant-table-filter-trigger.active {
				color: ${({ theme }) => theme.colors.warn};
			}

			.ant-table-tbody {
				> tr:nth-of-type(2n + 1) > td {
					background-color: ${({ theme }) => theme.colors.neutral['50']};
				}

				> tr > td {
					border-bottom: none;
				}

				> tr:hover > td {
					background-color: ${({ theme }) => theme.colors.neutral['100']};
				}
			}

			td.ant-table-column-sort {
				background: white;
			}
		}
	}

	ul.ant-pagination {
		li > button.ant-pagination-item-link {
			display: flex;
			justify-content: center;
			align-items: center;

			border: none;
			border-radius: 4px;
			background: ${({ theme }) => theme.colors.neutral['100']};
		}

		li.ant-pagination-item {
			border: none;
			border-radius: 4px;
			background: ${({ theme }) => theme.colors.neutral['100']};

			&.ant-pagination-item-active {
				background: ${({ theme }) => theme.colors.primary['700']};
			}
		}

		li.ant-pagination-item, li.ant-pagination-jump-next, li.ant-pagination-jump-prev {
			display: flex;
			justify-content: center;
			align-items: center;

			a {
				width: 100%;
			}
		}

		li.ant-pagination-options .ant-select .ant-select-selector {
			border: none;
			background: ${({ theme }) => theme.colors.neutral['100']};
		}
	}
`
