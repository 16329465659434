import React, { Suspense } from 'react'

import { Route, RouteProps } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { Spin } from 'antd'

import SimpleLayout from '../layouts/SimpleLayout'

type Props = RouteProps & {
	path: string
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const BaseRoute = ({ component: Component, ...rest }: Props) => (
	<Route
		{...rest}
		render={(props) => (
			<SimpleLayout>
				<Suspense fallback={<Spin style={{ marginTop: 4 }}/>}>
					<Component {...props}/>
				</Suspense>
			</SimpleLayout>
		)}
	/>
)

export default BaseRoute
