import locale from '../../../resources/locale'

export default (values) => {
	const errors = {}

	if (!values.codes || !values.codes.length) {
		errors.codes = locale['error.validation.REQUIRED']
	}

	return errors
}
