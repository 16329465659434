import React from 'react'
import PropTypes from 'prop-types'
import { get, map, join, uniqBy } from 'lodash'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'
import { formatDate } from '../../utils/utils'
import { MUNICIPALITY_TYPE } from '../../utils/enums'

class CalendarTableRow extends React.Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
		onDetail: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		countIndex: PropTypes.number
	}

	renderWasteTypes = () => {
		const { collectionRounds } = this.props.event
		if (collectionRounds && collectionRounds.length) {
			const roundsByWasteTypes = uniqBy(collectionRounds, 'wasteType.id')

			if (roundsByWasteTypes.length === 1) {
				return (
					<td>
						<div className={'dot'} style={{ background: `#${roundsByWasteTypes[0].wasteType.color}` }}/>
						<span>{roundsByWasteTypes[0].wasteType.name}</span>
					</td>
				)
			}

			const items = map(roundsByWasteTypes, (item, index) => (
				<li key={`${item.id}.${index}`}>
					<div className={'dot'} style={{ backgroundColor: `#${item.wasteType.color}` }}/>
					{item.wasteType.name}
				</li>
			))

			return (
				<td>
					<span
						data-tip
						data-for={`wasteType-tooltip-${this.props.event.id}`}
					>
						{locale['page.collectionYards.wasteTypes.multiple']}
					</span>
					<ReactTooltip
						id={`wasteType-tooltip-${this.props.event.id}`}
						aria-haspopup={'true'} place={'left'}
						effect={'solid'}
					>
						<ul className={'tooltip-list'}>
							{items}
						</ul>
					</ReactTooltip>
				</td>
			)
		}
		return <td>-</td>
	}

	render() {
		const { event, onDetail, onDelete, countIndex, municipality } = this.props

		return (
			<tr onClick={() => onDetail(event)}>
				<td>{formatDate(get(event, 'date'))}</td>
				<td>{locale[`event.types.${get(event, 'type')}`] || '-'}</td>
				{municipality && municipality.type === MUNICIPALITY_TYPE.WASTE_COMPANY &&
				<td>
					{join(map(uniqBy(event.collectionRounds, 'municipality'), (round) => round.municipality), ', ')}
				</td>
				}
				{this.renderWasteTypes()}
				<td className={'text-center'}>{get(event, 'isDoorToDoor') ? locale['common.yes'] : locale['common.no']}</td>
				<td className={'text-center'}>{get(event, 'isPublic') ? locale['common.yes'] : locale['common.no']}</td>
				<td className={'text-center'}>{get(event, 'isVisibleForStatistics') ? locale['common.yes'] : locale['common.no']}</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					<PopupTool
						items={[{
							key: 'common.delete',
							title: locale['common.delete'],
							icon: faTrashAlt,
							callback: () => onDelete(event)
						}]}
					/>
				</td>
			</tr>
		)
	}
}

export default CalendarTableRow
