import React from 'react'
import styled from 'styled-components'

import { Col, Row } from 'antd'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { createYearsList } from '../../../utils/helpers'

import { Text } from '../../../components/Typography'

const StepButton = styled.button`
	padding: 0;
	width: 32px;
	height: 32px;
	border-radius: 8px;
	outline: none;
	background: ${({ theme }) => theme.colors.neutral['100']};
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
`

const years = createYearsList()

type Props = {
	year: number
	showPreviousYear: boolean
	onChange: (year: number) => void
}

const YearStepper = ({ year, onChange, showPreviousYear }: Props) => (
	<Row gutter={8} align={'middle'}>
		<Col>
			<Text className={'without-margin'}>{showPreviousYear ? `${year - 1} - ` : ''}{year}</Text>
		</Col>
		<Col>
			<StepButton
				disabled={year <= years[0]}
				onClick={() => onChange(year - 1)}
			>
				<LeftOutlined/>
			</StepButton>
		</Col>
		<Col>
			<StepButton
				disabled={year >= years[years.length - 1]}
				onClick={() => onChange(year + 1)}
			>
				<RightOutlined/>
			</StepButton>
		</Col>
	</Row>
)

export default React.memo(YearStepper)
