import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { map } from 'lodash'

import { Col, Dropdown, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import Chart from 'chart.js/auto'

import { DownOutlined } from '@ant-design/icons'

import { createYearsList } from '../../../utils/helpers'

import { statistics } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SpinLoading from '../../../components/SpinLoading'
import Button from '../../../components/buttons/Button'

import { HeadingFour, SubHeading } from '../../../components/Typography'

const ChartWrapper = styled.div`
	height: 400px;
	width: 400px;
	
	display: flex;
	align-items: center;
`

const EmptyPlaceholder = styled(HeadingFour)`
	text-align: center;
	margin: 0;
	color: ${({ theme }) => theme.colors.neutral['400']}
`

type Props = {
	title: string
	useYear: boolean
	municipalityID: string | number
}

const years = createYearsList()

const SortingRateSummary = ({ title, municipalityID, useYear }: Props) => {
	const [t] = useTranslation()

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const chartRef = useRef<Chart | null>(null)

	const [year, setYear] = useState(() => dayjs().year().toString())
	const [sortingRateState, loadSortingRate] = useDataLoader(statistics.loadSortingRate)

	useEffect(() => {
		if (sortingRateState.data) {
			const { total, wasteTypes } = sortingRateState.data

			const data = {
				labels: map(wasteTypes, (wasteType) => wasteType.name),
				datasets: [{
					data: map(wasteTypes, (wasteTypes) => wasteTypes.weight),
					backgroundColor: map(wasteTypes, (wasteType) => `#${wasteType.color}`),
					pointStyle: 'circle'
				}]
			}

			chartRef.current?.destroy()
			const context = canvasRef.current?.getContext('2d')

			if (context) {
				chartRef.current = new Chart(context, {
					type: 'doughnut',
					data,
					options: {
						cutout: '60%',
						plugins: {
							datalabels: {
								display: false
							},
							tooltip: {
								callbacks: {
									title: ([{ label }]) => label,
									footer: ([{ raw }]) => {
										if (typeof raw === 'number') {
											return `${(raw / total * 100).toFixed(2)}%`
										}

										return ''
									},
									label: ({ formattedValue }) => `${formattedValue} kg`
								}
							},
							legend: {
								position: 'bottom',
								labels: {
									usePointStyle: true
								}
							}
						}
					}
				})
			}
		}
	}, [sortingRateState, sortingRateState.data])

	useEffect(() => {
		loadSortingRate({ year: useYear ? year : undefined, municipalityID })
	}, [year, municipalityID, loadSortingRate, useYear])

	let content

	if (sortingRateState.isLoading) {
		content = <SpinLoading height={'100%'}/>
	} else if (sortingRateState.data?.total === 0) {
		content = <EmptyPlaceholder>{t('DashboardPage.noCollectionsInPeriod')}</EmptyPlaceholder>
	} else if (sortingRateState.data) {
		content = <canvas height={'300px'} width={'300px'} ref={canvasRef}/>
	}

	return (
		<Row justify={'end'} gutter={[16, 16]}>
			<Col span={24}>
				<SubHeading>{title}</SubHeading>
			</Col>
			<Col style={{ height: 32 }}>
				{useYear &&
				<Dropdown
					overlay={(
						<Menu selectedKeys={[year]}>
							{map(years, (year) => (
								<Menu.Item
									key={year}
									onClick={() => setYear(year.toString())}
								>
									{year}
								</Menu.Item>
							))}
						</Menu>
					)}
				>
					<Button className={'secondary center'} style={{ width: 75 }}>
						<span>{year}</span>
						<DownOutlined/>
					</Button>
				</Dropdown>}
			</Col>
			<Col span={24} className={'mo-flex-row mo-flex-center'}>
				<ChartWrapper>
					{content}
				</ChartWrapper>
			</Col>
		</Row>
	)
}

export default React.memo(SortingRateSummary)
