import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPowerOff, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import ReactTooltip from 'react-tooltip'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'
import { DEFAULT_WASTE_TYPE_COLOR } from '../../utils/enums'

class ContainerTypesTableRow extends React.Component {
	static propTypes = {
		containerType: PropTypes.object.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		countIndex: PropTypes.number,
		onDelete: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = { sort: props.defaultSort || null }
	}

	buildContainerTypeActions = (containerType) => {
		if (containerType.isActive) {
			return [{
				key: 'common.deactivate',
				title: locale['common.deactivate'],
				icon: faPowerOff,
				callback: () => this.props.onSetActive(containerType)
			}]
		}

		return [{
			key: 'common.activate',
			title: locale['common.activate'],
			icon: faPowerOff,
			callback: () => this.props.onSetActive(containerType)
		}, {
			key: 'common.delete',
			title: locale['common.delete'],
			icon: faTrashAlt,
			callback: () => this.props.onDelete(containerType)
		}]
	}

	render() {
		const { containerType, onDetail, countIndex } = this.props
		return (
			<tr onClick={() => onDetail(get(containerType, 'id'))}>
				<td>{get(containerType, 'name')}</td>
				<td>
					<div className={'dot'} style={{ backgroundColor: `#${get(containerType, 'wasteType.color', DEFAULT_WASTE_TYPE_COLOR)}` }}/>
					{get(containerType, 'wasteType.name', '-')}
				</td>
				<td>{get(containerType, 'capacity')}</td>
				<td>{locale[`container.type.${get(containerType, 'type')}`]}</td>
				<td className={'text-center icon'} style={{ color: get(containerType, 'isActive') ? '#AFCA0B' : '#F03E41' }}>
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${get(containerType, 'id')}`} icon={get(containerType, 'isActive') ? faCheckCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${get(containerType, 'id')}`} place={'left'} effect={'solid'}>
						<span>
							{get(containerType, 'isActive') ? locale['page.containerTypes.active.tooltip'] : locale['page.containerTypes.inactive.tooltip']}
						</span>
					</ReactTooltip>
				</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					<PopupTool
						items={this.buildContainerTypeActions(containerType)}
					/>
				</td>
			</tr>
		)
	}
}

export default ContainerTypesTableRow
