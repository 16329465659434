import React from 'react'
import ReactQueryParams from 'react-query-params'
import moment from 'moment-timezone'
import i18next from 'i18next'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
	head,
	last,
	get,
	isEqual,
	forEach,
	find,
	map,
	debounce,
	trim
} from 'lodash'

import PropTypes from 'prop-types'
import Select from 'react-select'
import Breadcrumbs from '../../components/Breadcrumb'
import locale from '../../resources/locale'

import Pagination from '../../atoms/Pagination'
import { ENDPOINTS, FILTER_ORDER_SELECTORS } from '../../utils/enums'
import { getReq } from '../../utils/request'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import MotivationTable from '../../components/statistics/MotivationTable'
import ExportButton from '../../components/buttons/ExportButton'
import { customStyles } from '../../utils/select'
import { parseNumber } from '../../utils/utils'
import { EmptyTableState } from '../../components/EmptyTableState'
import SearchInputField from '../../atoms/SearchInputField'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import { municipalities } from '../../api'
import { history } from '../../utils/history'

const queryParams = {
	page: 'page',
	order: 'order',
	motivationModelID: 'motivationModelID',
	search: 'search',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'wasteType', 'capacity', 'type', 'isActive']
	},
	motivationModelID: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class MotivationPage extends ReactQueryParams {
	static propTypes = {
		municipality: PropTypes.object
	}

	constructor(props) {
		super(props)

		this.state = {
			motivationModels: {
				isLoading: true,
				isFailure: false,
				data: null
			},
			statistics: {
				isLoading: true,
				isFailure: false,
				data: null
			}
		}

		this.defaultQueryParams = {
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.motivationModelID]: null,
			[queryParams.search]: null,
			[queryParams.limit]: null
		}
	}

	async componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders, municipality } = this.props
		if (municipality) {
			const motivationModels = await this.loadMotivationModels()

			let filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

			const motivationModel = find(motivationModels, (o) => o.id === parseNumber(filtersOrder.motivationModelID)) || null
			if (!motivationModel) {
				if (motivationModels && motivationModels.length) {
					filtersOrder = {
						...filtersOrder,
						[queryParams.motivationModelID]: `${motivationModels[0].id}`
					}
				} else {
					filtersOrder = {
						...filtersOrder,
						[queryParams.motivationModelID]: null
					}
				}
			}

			this.loadStatistics(filtersOrder)
			selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.MOTIVATION_PAGE, filtersOrder)
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate = () => {
		const { municipality, selectedFiltersOrders } = this.props
		if (this._mounted && municipality) {
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	loadMotivationModels = async () => {
		const { municipality } = this.props

		if (this._mounted) {
			try {
				this.setState({
					motivationModels: {
						isLoading: true,
						isFailure: false,
						data: null
					}
				})
				const { data } = await municipalities.loadMotivationModels(municipality.id)

				this.setState({
					motivationModels: {
						isLoading: true,
						isFailure: false,
						data
					}
				})

				return data
			} catch (e) {
				this.setState({
					motivationModels: {
						isLoading: false,
						isFailure: true,
						data: null
					}
				})
			}
		}

		return []
	}

	onChangeFilterOrder = (filters) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		const filterOrder = { ...selectedFiltersOrders }
		forEach(filters, ({ type, value }) => {
			let newFilterOrder
			switch (type) {
				case queryParams.order:
					newFilterOrder = `${value.name}:${value.sort}`
					break
				case queryParams.search:
				case queryParams.page:
				case queryParams.limit:
				case queryParams.motivationModelID:
					newFilterOrder = (value) ? `${value}` : null
					break
				default:
					break
			}
			filterOrder[type] = newFilterOrder

			if (type !== queryParams.page && type !== queryParams.order) {
				filterOrder[queryParams.page] = '1'
			}
		})

		this.setQueryParams({
			...filterOrder
		})

		this.loadStatistics(filterOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.MOTIVATION_PAGE, filterOrder)
	}

	formatModel = (model) => {
		let text = locale[`motivation.model.${model.type}`]
		text += ` (${moment(model.validFrom).format(locale['common.date.format.date'])}`
		if (model.validTo) {
			text += ` - ${moment(model.validTo).format(locale['common.date.format.date'])})`
		} else {
			text += ` - ${locale['page.municipalities.detail.motivation.models.valid.to.none']})`
		}
		return {
			value: model.id,
			label: text
		}
	}

	onChangeMotivationModel = (item) => {
		this.onChangeFilterOrder([{
			type: queryParams.motivationModelID,
			value: item.value
		}])
	}

	onChangeSearch = (search) => {
		this.onChangeFilterOrder([{
			type: queryParams.search,
			value: trim(search)
		}])
	}

	loadStatistics = async (filters) => {
		const { municipality } = this.props
		if (filters.motivationModelID && this._mounted && municipality) {
			this.setState({
				statistics: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const query = {
					...filters
				}

				const response = await getReq(ENDPOINTS.MOTIVATION, query)

				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder([{ type: queryParams.page, value: 1 }])
				} else {
					this.setState({
						...this.state,
						statistics: {
							data: get(response, 'data'),
							isLoading: false,
							isFailure: false
						}
					})
				}
			} catch (error) {
				this.setState({
					...this.state,
					statistics: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.statistics.breadcrumbs',
			name: locale['page.statistics.breadcrumbs']
		}, {
			key: 'page.statistics.motivation',
			name: locale['page.statistics.motivation']
		}]
	}

	openCustomerDetail = (customer) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.customers.detail.municipalityPath', { municipalityID: municipality.id, customerID: customer.customerID })
			: i18next.t('paths:LISTS.customers.detail.path', { customerID: customer.customerID }))
	}

	render() {
		const { statistics, motivationModels } = this.state
		const { selectedFiltersOrders, municipality } = this.props

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		const filters = { ...selectedFiltersOrders }

		const motivationModel = find(motivationModels.data, (o) => o.id === parseNumber(filters.motivationModelID)) || null

		let body
		let limitComponent

		if (!municipality) {
			body = <div className={'col-12'}>
				<EmptyTableState title={locale['page.statistics.motivation.select.municipality']}/>
			</div>

			limitComponent = null
		} else if (selectedFiltersOrders.motivationModelID) {
			body = <div className={'col-12'}>
				<MotivationTable
					onSort={(value) => this.onChangeFilterOrder([{
						type: queryParams.order, value
					}])}
					onDetail={this.openCustomerDetail}
					items={get(statistics, 'data.stats')}
					sortingColumn={sortingColumn}
					sortingDirection={sortingDirection}
					loading={get(statistics, 'isLoading')}
				/>
				<Pagination
					pages={get(statistics, 'data.context.pages')}
					page={get(statistics, 'data.context.page')}
					onPage={(value) => this.onChangeFilterOrder([{
						type: queryParams.page, value
					}])}
				/>
			</div>

			limitComponent = <div className={'col-12'}>
				<ListItemsLimit
					limit={selectedFiltersOrders.limit}
					onLimit={(limit) => this.onChangeFilterOrder([{ type: queryParams.limit, value: limit }])}
				/>
			</div>
		} else {
			body = <div className={'col-12'}>
				<EmptyTableState title={locale['page.statistics.motivation.model']}/>
			</div>

			limitComponent = null
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.statistics.motivation']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<div className={'row flex-row justify-content-between'}>
								<div className={'flex-box'}>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.onChangeSearch, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
									{selectedFiltersOrders.motivationModelID && <div className={'flex-item'}>
										<ExportButton
											url={ENDPOINTS.MOTIVATION_EXPORT}
											context={{
												...filters,
												municipalityID: municipality ? municipality.id : null
											}}
										/>
									</div>}
								</div>
								{!selectedFiltersOrders.motivationModelID && <div className={'flex-item'}/>}
								{municipality && <div className={'flex-item'} style={{ width: '300px' }}>
									<Select
										onChange={this.onChangeMotivationModel}
										className={'react-select'}
										placeholder={locale['page.statistics.motivation.model']}
										options={map(motivationModels.data, this.formatModel)}
										styles={customStyles}
										noOptionsMessage={() => i18next.t('loc:Žiadne možnosti')}
										loadingMessage={() => `${i18next.t('loc:Načítavam možnosti')}...`}
										value={motivationModel ? this.formatModel(motivationModel) : null}
									/>
								</div>}
							</div>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								{limitComponent}
								{body}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.MOTIVATION_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MotivationPage)
