import i18next from 'i18next'

import { ValidationErrors } from 'final-form'

import { FormValues } from '../components/CreateCollectionModal'

export default (values: FormValues) => {
	const errors: ValidationErrors = {}

	if (!values.containers || !values.containers.length) {
		errors.containers = i18next.t('errors.selectContainer')
	}

	if (!values.collectionRound) {
		errors.collectionRound = i18next.t('errors.required')
	}

	if (!values.quantity) {
		errors.quantity = i18next.t('errors.required')
	} else if (parseFloat(`${values.quantity}`) < 0) {
		errors.quantity = i18next.t('errors.gte', { value: 0 })
	}

	if (values.weight && parseFloat(`${values.weight}`) < 0) {
		errors.weight = i18next.t('errors.gte', { value: 0 })
	}

	if (!values.scanDatetime) {
		errors.scanDatetime = i18next.t('errors.required')
	}

	return errors
}
