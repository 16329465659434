import React from 'react'
import * as PropTypes from 'prop-types'

import { Col, Row } from 'antd'

import { filter, find } from 'lodash'
import { useSelector } from 'react-redux'
import { Field, reduxForm, propTypes } from 'redux-form'

import locale from '../../../resources/locale'

import { FORMS } from '../../../utils/enums'
import { getCustomerCodesSelectOptions } from '../../../redux/customerCodes/selectors'

import CreatableSelectField from '../../../atoms/CreatableSelectField'
import Button from '../../../components/buttons/Button'

const CustomerCodesAssignForm = ({ handleCancel, handleSubmit, codes }) => {
	const selectOptions = useSelector(getCustomerCodesSelectOptions)

	selectOptions.values = filter(selectOptions.values, (val) => !find(codes, (code) => code.code === val.value))

	return (
		<form
			onSubmit={handleSubmit}
			className={'form-horizontal'}
			noValidate
			autoComplete={'off'}
		>
			<div className={'modal-header'}>
				{locale['page.customers.detail.assign.code']}
			</div>
			<div className={'modal-content'}>
				<div className="row" style={{ marginBottom: '20px' }}>
					<Field
						name={'codes'}
						component={CreatableSelectField}
						props={{
							label: locale['page.customers.detail.codes.select'],
							placeholder: 'Kódy oddelené enterom',
							isMulti: true,
							showLabel: true
						}}
					/>
				</div>
			</div>
			<div className={'modal-footer clearfix'}>
				<Row gutter={16} style={{ marginTop: 16 }}>
					<Col>
						<Button
							onClick={handleCancel}
							className={'secondary'}
						>
							{locale['common.cancel']}
						</Button>
					</Col>
					<Col>
						<Button
							type="submit"
							onClick={(e) => {
								e.preventDefault()
								handleSubmit(e)
							}}
						>
							{locale['pages.customerCodes.assign']}
						</Button>
					</Col>
				</Row>
			</div>
		</form>
	)
}

CustomerCodesAssignForm.propTypes = {
	codes: PropTypes.arrayOf(PropTypes.object).isRequired,
	handleCancel: PropTypes.func.isRequired,
	municipality: PropTypes.object.isRequired,
	...propTypes
}

export default reduxForm({
	form: FORMS.ASSIGN_CUSTOMER_CODES,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true
})(CustomerCodesAssignForm)
