import React from 'react'
import i18next from 'i18next'
import { Col, Row } from 'antd'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, map, indexOf, pick, keys, values, isEmpty, reduce, forEach, filter, head, last, debounce } from 'lodash'

import { Doughnut } from 'react-chartjs-2'
import Async from 'react-select/async'

import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumb'
import locale from '../../resources/locale'

import { getReq } from '../../utils/request'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'

import {
	COLLECTION_ROUND_TYPE,
	ENDPOINTS,
	FILTER_ORDER_SELECTORS
} from '../../utils/enums'

import ChartLegend from '../../components/statistics/ChartLegend'
import { customStyles } from '../../utils/select'
import Pagination from '../../atoms/Pagination'
import UncollectedTable from '../../components/statistics/UncollectedTable'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import SearchInputField from '../../atoms/SearchInputField'
import ExportButton from '../../components/buttons/ExportButton'
import { history } from '../../utils/history'

const queryParams = {
	collectionRounds: 'collectionRounds',
	wasteTypes: 'wasteTypes',
	search: 'search',
	page: 'page',
	order: 'order',
	limit: 'limit'
}

class UncollectedPage extends React.Component {
	static propTypes = {
		selectedFiltersOrdersChanged: PropTypes.func.isRequired,
		selectedFiltersOrders: PropTypes.object.isRequired,
		municipality: PropTypes.object
	}

	constructor(props) {
		super(props)

		this.state = {
			defaultWasteTypes: true,
			containers: {
				isLoading: true,
				isFailure: false,
				data: null
			},
			statistics: null,
			collectionSelectKey: 'collectionRounds'
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props

		const filteredParams = pick(this.queryParams, keys(this.queryParams).filter((key) => indexOf(values(queryParams), key) !== -1 && !isEmpty(this.queryParams[key])))
		const filtersOrder = {
			...selectedFiltersOrders,
			...filteredParams
		}

		this.loadStatistics(filtersOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.UNCOLLECTED_PAGE, filtersOrder)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	onChangeFilterOrder = (filters) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		const filterOrder = { ...selectedFiltersOrders }

		forEach(filters, ({ type, value }) => {
			let newFilterOrder
			switch (type) {
				case queryParams.order:
					newFilterOrder = `${value.name}:${value.sort}`
					break
				case queryParams.page:
				case queryParams.limit:
				case queryParams.search:
					newFilterOrder = value ? `${value}` : null
					break
				case queryParams.collectionRounds:
				case queryParams.wasteTypes:
					if (value && Array.isArray(value)) {
						newFilterOrder = value
					} else if (value) {
						newFilterOrder = [value]
					} else {
						newFilterOrder = null
					}
					break
				default:
					break
			}
			filterOrder[type] = newFilterOrder

			if (type !== queryParams.page && type !== queryParams.order) {
				filterOrder[queryParams.page] = '1'
			}
		})

		this.loadStatistics(filterOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.UNCOLLECTED_PAGE, filterOrder)
	}

	openContainerDetail = (containerID) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.containers.detail.municipalityPath', { municipalityID: municipality.id, containerID })
			: i18next.t('paths:LISTS.containers.detail.path', { municipalityID: municipality.id, containerID }))
	}

	loadStatistics = async (filters) => {
		if (this._mounted) {
			try {
				this.setState({
					...this.state,
					containers: {
						data: null,
						isLoading: true,
						isFailure: false
					}
				})
				const { municipality } = this.props
				const query = {}
				forEach(filters.wasteTypes, (item, index) => {
					query[`wasteTypes[${index}]`] = item.value
				})

				forEach(filters.collectionRounds, (item, index) => {
					query[`collectionRounds[${index}]`] = item.value
				})

				query.page = filters.page
				query.order = filters.order
				query.limit = filters.limit
				query.search = filters.search
				if (municipality) {
					query.municipalityID = municipality.id
				}
				const response = await getReq(ENDPOINTS.UNCOLLECTED, query)
				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder([{ type: queryParams.page, value: 1 }])
				} else {
					this.setState({
						...this.state,
						containers: {
							data: {
								containers: get(response, 'data.containers'),
								context: get(response, 'data.context')
							},
							isLoading: false,
							isFailure: false
						},
						statistics: get(response, 'data.stats')
					})
				}
			} catch (error) {
				this.setState({
					...this.state,
					containers: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	loadWasteTypes = (keyword, callback) => {
		const { municipality } = this.props
		const context = {
			limit: 60
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		if (municipality) {
			context.municipalityID = municipality.id
		}

		getReq(ENDPOINTS.WASTE_TYPES_MUNICIPALITY, context).then((response) => {
			const items = map(get(response, 'data.types', []), (item) => ({
				value: item.id,
				label: `${item.name} - ${item.municipality.name}`
			}))
			callback(items)
		}).catch(() => callback([]))
	}

	onChangeWasteTypes = (value) => {
		const { selectedFiltersOrders } = this.props
		const wasteTypeIds = map(value, (item) => item.value)
		const collectionRounds = filter(selectedFiltersOrders.collectionRounds, (item) => indexOf(wasteTypeIds, item.wasteType) >= 0)
		this.onChangeFilterOrder([{
			type: queryParams.wasteTypes, value
		}, {
			type: queryParams.collectionRounds, value: collectionRounds
		}, {
			type: queryParams.page, value: 1
		}])
		this.setState({
			collectionSelectKey: JSON.stringify(wasteTypeIds)
		})
	}

	onChangeSearch = (value) => {
		this.onChangeFilterOrder([{ type: queryParams.search, value }])
	}

	loadCollectionRounds = (keyword, callback) => {
		const { municipality, selectedFiltersOrders } = this.props
		const context = {
			limit: 60,
			type: COLLECTION_ROUND_TYPE.STANDARD
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		if (municipality) {
			context.municipalityID = municipality.id
		}

		forEach(selectedFiltersOrders.wasteTypes, (item, index) => {
			context[`wasteTypes[${index}]`] = item.value
		})

		getReq(ENDPOINTS.COLLECTION_ROUNDS, context).then((response) => {
			const items = map(get(response, 'data.collectionRounds', []), (item) => ({
				value: item.id,
				label: item.name,
				wasteType: item.wasteType.id
			}))
			callback(items)
		}).catch(() => callback())
	}

	onChangeCollectionRounds = (value) => {
		this.onChangeFilterOrder([{
			type: queryParams.collectionRounds, value
		}, {
			type: queryParams.page, value: 1
		}])
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.statistics.breadcrumbs',
			name: locale['page.statistics.breadcrumbs']
		}, {
			key: 'page.statistics.uncollected',
			name: locale['page.statistics.uncollected']
		}]
	}

	chartOptions = {
		plugins: {
			datalabels: {
				display: false
			},
			legend: {
				labels: {
					usePointStyle: true
				}
			}
		},
		cutoutPercentage: 55,
		tooltips: {
			backgroundColor: '#FFF',
			titleFontSize: 16,
			titleFontColor: '#000',
			titleFontStyle: 'bold',
			bodyFontColor: '#188efd',
			bodyFontSize: 14,
			footerFontColor: '#BBB',
			borderColor: '#c2c2c2',
			borderWidth: 1,
			displayColors: false,
			callbacks: {
				title: (tooltipItem, chartData) => {
					const string = chartData.datasets[0].data[tooltipItem[0].index]
					return string ? string.toLocaleString() : ''
				},
				label: (tooltipItem, chartData) => chartData.labels[tooltipItem.index]
			}
		}
	}

	render() {
		const { statistics, containers, collectionSelectKey } = this.state
		const { selectedFiltersOrders, municipality } = this.props

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		let graph = null
		let table = null
		if (!containers.isFailure) {
			table = (
				<div className={'row'}>
					<div className={'col-12'}>
						<UncollectedTable
							isMunicipality={false}
							onDetail={this.openContainerDetail}
							onSort={(value) => this.onChangeFilterOrder([{ type: queryParams.order, value }])}
							items={get(containers, 'data.containers')}
							sortingColumn={sortingColumn}
							sortingDirection={sortingDirection}
							loading={get(containers, 'isLoading')}
						/>
						<ListItemsLimit
							limit={selectedFiltersOrders.limit}
							onLimit={(limit) => this.onChangeFilterOrder([{ type: queryParams.limit, value: limit }])}
						/>
						<Pagination
							pages={get(containers, 'data.context.pages')}
							page={get(containers, 'data.context.page')}
							onPage={(value) => this.onChangeFilterOrder([{ type: queryParams.page, value }])}
						/>
					</div>
				</div>
			)
		}

		if (statistics) {
			const data = reduce(statistics, (result, item) => ({
				datasets: [{
					data: [...result.datasets[0].data, item.count],
					backgroundColor: [...result.datasets[0].backgroundColor, `#${item.color}`]
				}],
				labels: [...result.labels, item.name]
			}), { datasets: [{ data: [], backgroundColor: [] }], labels: [] })

			const legend = map(get(statistics, 'data.stats'), (item) => ({
				label: item.name,
				color: `#${item.color}`
			}))

			if (data.datasets[0].data.length) {
				graph = (
					<div style={{ maxWidth: '300px', margin: 'auto' }}>
						<Doughnut
							data={data}
							options={this.chartOptions}
							width={250}
							height={250}
						/>
						<ChartLegend stats={legend}/>
					</div>
				)
			}
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.statistics.uncollected']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={[16, 16]}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.onChangeSearch, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton
										url={ENDPOINTS.UNCOLLECTED_EXPORT}
										context={{
											...selectedFiltersOrders,
											wasteTypes: selectedFiltersOrders.wasteTypes ? map(selectedFiltersOrders.wasteTypes, (item) => item.value) : [],
											municipalityID: municipality ? municipality.id : null
										}}
									/>
								</Col>
								<Col style={{ width: 250 }}>
									<Async
										defaultOptions
										isClearable
										isMulti
										onChange={this.onChangeWasteTypes}
										className={'react-select'}
										placeholder={locale['page.statistics.uncollected.wasteTypes']}
										loadOptions={this.loadWasteTypes}
										styles={customStyles}
										noOptionsMessage={() => i18next.t('loc:Žiadne možnosti')}
										loadingMessage={() => `${i18next.t('loc:Načítavam možnosti')}...`}
										value={get(selectedFiltersOrders, 'wasteTypes')}
									/>
								</Col>
								<Col style={{ width: 250 }}>
									<Async
										key={collectionSelectKey}
										defaultOptions
										isClearable
										isMulti
										onChange={this.onChangeCollectionRounds}
										className={'react-select'}
										placeholder={locale['page.statistics.uncollected.collectionRounds']}
										loadOptions={this.loadCollectionRounds}
										styles={customStyles}
										value={get(selectedFiltersOrders, 'collectionRounds')}
										noOptionsMessage={() => i18next.t('loc:Žiadne možnosti')}
										loadingMessage={() => `${i18next.t('loc:Načítavam možnosti')}...`}
									/>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-12'} style={{ marginTop: '15px' }}>
									<div className={'chart-title'}>
										<span>{locale['page.statistics.uncollected.title']}</span>
									</div>
									{graph}
								</div>
							</div>
							{table}
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.UNCOLLECTED_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UncollectedPage)
