import React from 'react'
import { useTranslation } from 'react-i18next'
import { intersection, some } from 'lodash'

import { MunicipalityBase, Profile } from '../../../types/data'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import HomeIcon from '../../../resources/images/ic-home.svg'
import MenuLink from './MenuLink'
import { PERMISSIONS } from '../../../utils/enums'

type Props = {
	authUser?: Profile | null
	municipality: MunicipalityBase | null
}

const permissions = [
	PERMISSIONS.ADMINISTRATOR,
	PERMISSIONS.MUNICIPALITY_ADMIN,
	PERMISSIONS.MUNICIPALITY_WORKER
]

const Home = ({ authUser, municipality, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin && some(authUser?.municipalities, (item) => !intersection(item.permissions, permissions).length)) {
		return null
	}

	return (
		<StyledItemGroup
			{...props}
			key={'HOME'}
			title={t('paths:HOME.title')}
		>
			<StyledItem key={'HOME.dashboard'}>
				<Icon icon={HomeIcon}/>
				<MenuLink basePath={'paths:HOME.dashboard'} municipality={municipality}>
					{t('paths:HOME.dashboard.title')}
				</MenuLink>
			</StyledItem>
		</StyledItemGroup>
	)
}

export default Home
