import { createSelector } from 'reselect'
import { get, map } from 'lodash'

export const getCustomerCodesList = (state) => state.customerCodes.list
export const getCustomerCodesSelect = (state) => state.customerCodes.select

export const getCustomerCodesSelectOptions = createSelector([getCustomerCodesSelect], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.customersCodes'), (item) => ({
		value: item.code,
		label: `${item.code} - ${item.serial}`
	}))
}))
