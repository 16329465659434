import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

class UncollectedTableRow extends React.Component {
	static propTypes = {
		onDetail: PropTypes.func.isRequired,
		item: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			collectionPlace: PropTypes.string.isRequired
		}).isRequired
	}

	render() {
		const { item, onDetail } = this.props
		return (
			<tr onClick={() => onDetail(item.id)}>
				<td>{get(item, 'name', '-')}</td>
				<td>{get(item, 'code', '-')}</td>
				<td>{get(item, 'serialNumber', '-')}</td>
				<td>{get(item, 'customer', '-')}</td>
				<td className={'text-center'}>{get(item, 'collectionPlace', '-')}</td>
			</tr>
		)
	}
}

export default UncollectedTableRow
