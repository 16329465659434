import React, { useCallback } from 'react'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'

import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import { Col, Row } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'

import { authentication } from '../../api'
import { TokenPayload } from '../../types/data'

import { authenticationActions } from '../../redux/authentication'

import TextField from '../../components/form/TextField'
import CheckboxField from '../../components/form/CheckboxField'
import Button from '../../components/buttons/Button'
import { HeadingOne } from '../../components/Typography'

import validateLoginForm from './validators/validateLoginForm'
import { statusPush } from '../../actions/statusActions'
import { MESSAGE_TYPE } from '../../types/enums'

const ForgotPasswordLink = styled(Link)`
	color: lightgray;
	display: flex;
	align-items: center;

	span {
		font-size: 10px;
		font-weight: 500;
		line-height: 16px;
		text-decoration: underline;
		margin-bottom: 15px;
		padding-top: 2px;
		padding-left: 10px;
	}
`

export type FormValues = {
	email: string
	password: string
	remember: boolean
}

type Props = {
	redirect?: boolean
}

const LoginPage = ({ redirect = true }: Props) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [t] = useTranslation()

	const handleSubmit = useCallback(async (values: FormValues) => {
		try {
			const { data } = await authentication.loginUser(values)
			const tokenData: TokenPayload = jwtDecode(data.token)

			dispatch(authenticationActions.saveUser({
				profile: data.profile,
				accessToken: data.token,
				tokenData
			}))

			if (redirect) {
				history.push(t('paths:HOME.dashboard.path'))
			}

			return Promise.resolve()
		} catch (e) {
			dispatch(statusPush({
				msg: t('LoginPage.loginFail'),
				type: MESSAGE_TYPE.ERROR
			}))
			return Promise.resolve()
		}
	}, [dispatch, history, redirect, t])

	return (
		<Form
			onSubmit={handleSubmit}
			subscription={{ submitting: true }}
			validate={validateLoginForm}
			render={({ handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					<HeadingOne style={{ marginBottom: 32 }}>{t('LoginPage.title')}</HeadingOne>
					<Field
						name={'email'}
						type={'email'}
						render={(props) => (
							<TextField
								{...props}
								label={t('fields.email.label')}
								placeholder={t('fields.email.placeholder')}
							/>
						)}
					/>
					<Field
						name={'password'}
						type={'password'}
						render={(props) => (
							<TextField
								{...props}
								label={t('fields.password.label')}
								placeholder={t('fields.password.placeholder')}
							/>
						)}
					/>
					<Row justify={'space-between'} align={'top'}>
						<Col>
							<Field
								name={'remember'}
								type={'checkbox'}
								render={(props) => (
									<CheckboxField {...props} label={t('fields.remember.label')}/>
								)}
							/>
						</Col>
						<Col>
							<ForgotPasswordLink to={t('paths:AUTHENTICATION.forgotPassword.path')}>
								<QuestionCircleFilled/>
								<span>{t('LoginPage.forgotPassword')}</span>
							</ForgotPasswordLink>
						</Col>
					</Row>
					<Button
						disabled={submitting}
						loading={submitting}
						onClick={handleSubmit}
					>
						{t('LoginPage.login')}
					</Button>
				</form>
			)}
		/>
	)
}

export default LoginPage
