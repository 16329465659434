import React from 'react'
import { Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PERMISSIONS } from '../../utils/enums'

import AuthRoute from '../AuthRoute'
import BaseRoute from '../BaseRoute'

import MunicipalitySortingPage from '../../pages/statistics/MunicipalitySortingPage'
import CollectionPlacesSortingPage from '../../pages/statistics/CollectionPlacesSortingPage'
import CustomersSortingPage from '../../pages/statistics/CustomersSortingPage'
import MotivationPage from '../../pages/statistics/MotivationPage'
import QuantitativeCollectionsPage from '../../pages/statistics/QuantitativeCollectionsPage'
import QuantityPage from '../../pages/statistics/QuantityPage'
import UncollectedPage from '../../pages/statistics/UncollectedPage'
import WeightPage from '../../pages/statistics/new/WeightPage'
import NotFoundPage from '../../pages/NotFoundPage'

const Statistics = () => {
	const [t] = useTranslation()

	return (
		<Switch>
			<AuthRoute
				exact
				menuItem={'STATISTICS.municipalitySorting'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.municipalitySorting.municipalityPath', { municipalityID: ':municipalityID' })}
				component={MunicipalitySortingPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.collectionPlacesSorting'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.collectionPlacesSorting.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CollectionPlacesSortingPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.customersSorting'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.customersSorting.municipalityPath', { municipalityID: ':municipalityID' })}
				component={CustomersSortingPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.motivation'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.motivation.municipalityPath', { municipalityID: ':municipalityID' })}
				component={MotivationPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.quantitativeCollections'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.quantitativeCollections.municipalityPath', { municipalityID: ':municipalityID' })}
				component={QuantitativeCollectionsPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.quantity'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.quantity.municipalityPath', { municipalityID: ':municipalityID' })}
				component={QuantityPage}
			/>
			<AuthRoute
				exact
				menuItem={'STATISTICS.uncollected'}
				permissions={[PERMISSIONS.MUNICIPALITY_ADMIN, PERMISSIONS.MUNICIPALITY_WORKER]}
				path={t('paths:STATISTICS.uncollected.municipalityPath', { municipalityID: ':municipalityID' })}
				component={UncollectedPage}
			/>
			{/* Other Routes */}
			<BaseRoute
				path={'/'}
				component={NotFoundPage}
			/>
		</Switch>
	)
}

export default Statistics
