import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'

import { Col, Row, TablePaginationConfig } from 'antd'
import { ColumnTitle, FilterValue, SorterResult } from 'antd/es/table/interface'
import { NumberParam, StringParam, useQueryParams, createEnumParam } from 'use-query-params'

import { SORTING_PERIOD } from '../../utils/enums'

import { Sorting, OrderDirections } from '../../types/data'

import { getOrderFromSorter, omitNilFilters, omitNotDefinedKeys } from '../../utils/helpers'

import { statistics } from '../../api'

import {
	filtersActions, FiltersState, getMunicipalitySortingFilters
} from '../../redux/filters'
import { getSelectedMunicipality } from '../../redux/municipalities/selectors'

import { useDataLoader } from '../../hooks/useDataLoader'

import Breadcrumb from '../../components/Breadcrumb'
import ExportButton from '../../components/buttons/ExportButton'
import Table from '../../components/Table'
import { Box, BoxContent, BoxHeader } from '../../layouts/Box'
import { InputWrapper, Label, Select } from '../../components/form/Styled'

const queryConfig = {
	current: NumberParam,
	pageSize: NumberParam,
	order: StringParam,
	period: createEnumParam(Object.values(SORTING_PERIOD)),
	direction: createEnumParam(['ascend', 'descend'])
}

const CollectionPlacesSortingPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const municipality = useSelector(getSelectedMunicipality)
	const sortingFilters = useSelector(getMunicipalitySortingFilters)
	const statsLoadable = useRef(false)

	const [statsState, loadStats] = useDataLoader(statistics.loadMunicipalitySorting)

	const [query, setQuery] = useQueryParams(queryConfig)

	const municipalityID = municipality?.id

	const breadcrumbs = useMemo(() => {
		const items = [{
			key: 'path.STATISTICS',
			name: t('paths:STATISTICS.title')
		}, {
			key: 'path.STATISTICS.municipalitySorting',
			name: t('paths:STATISTICS.municipalitySorting.title')
		}]

		if (municipality) {
			return [{
				key: 'path.MUNICIPALITY',
				name: t('paths:MUNICIPALITY.title')
			}, {
				key: 'path.MUNICIPALITY.name',
				name: municipality.name
			}, ...items]
		}

		return items
	}, [municipality, t])

	const numberCol = useCallback((name: string, title: string) => ({
		key: name,
		dataIndex: name,
		title: title as ColumnTitle<Sorting>,
		sorter: true,
		showSorterTooltip: false,
		width: '10%',
		ellipsis: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: sortingFilters.order === name ? sortingFilters.direction : null,
		render: (item: number) => item?.toFixed(2)
	}), [sortingFilters.direction, sortingFilters.order])

	const columns = useMemo(() => [
		{
			...numberCol('year', t('table.columns.period')),
			dataIndex: 'period',
			width: 105,
			render: (item: string) => item
		},
		{
			...numberCol('name', t('table.columns.title')),
			width: '16%',
			ellipsis: true,
			render: (item: string) => <strong>{item}</strong>
		},
		numberCol('total', t('table.columns.total')),
		numberCol('municipal', t('table.columns.municipal')),
		numberCol('plastic', t('table.columns.plastic')),
		numberCol('paper', t('table.columns.paper')),
		numberCol('bio', t('table.columns.bio')),
		numberCol('other', t('table.columns.other')),
		{
			...numberCol('rate', t('table.columns.rate')),
			width: 'auto'
		}
	], [numberCol, t])

	const locale = useMemo(() => ({
		emptyText: statsState.isLoading ? '' : t('common.noData')
	}), [statsState.isLoading, t])

	const pagination = useMemo(() => ({
		current: sortingFilters.current,
		total: statsState.data?.context.totalCount,
		pageSize: sortingFilters.pageSize,
		pageSizeOptions: ['20', '50', '100']
	}), [sortingFilters, statsState.data?.context.totalCount])

	const filterCollections = useCallback((filters: Partial<FiltersState['municipalitySorting']>) => {
		dispatch(filtersActions.savePartialFilters({
			key: 'municipalitySorting',
			filters
		}))
	}, [dispatch])

	const periodOptions = useMemo(() => [{
		value: SORTING_PERIOD.MONTH,
		label: t('SORTING_PERIOD.MONTH')
	}, {
		value: SORTING_PERIOD.QUARTER,
		label: t('SORTING_PERIOD.QUARTER')
	}, {
		value: SORTING_PERIOD.HALF_YEAR,
		label: t('SORTING_PERIOD.HALF_YEAR')
	}, {
		value: SORTING_PERIOD.YEAR,
		label: t('SORTING_PERIOD.YEAR')
	}], [t])

	const handleChangePeriod = useCallback((period) => filterCollections({
		period
	}), [filterCollections])

	const handleChangeTable = useCallback((pagination: TablePaginationConfig, tableFilters: Record<string, FilterValue | null>, sorter: SorterResult<Sorting> | SorterResult<Sorting>[]) => {
		const [order, direction] = getOrderFromSorter(sorter)

		filterCollections({
			direction,
			current: pagination.current,
			pageSize: pagination.pageSize,
			order: order || 'name'
		})
	}, [filterCollections])

	useEffect(() => {
		if (statsState.data) {
			const { stats, context } = statsState.data
			if (!stats.length && context.current > 1) {
				filterCollections({ current: 1 })
			}
		}
	}, [filterCollections, statsState.data])

	useEffect(() => {
		let filters: object = sortingFilters

		// Do load stats until query and redux is merged
		if (!statsLoadable.current) {
			// Filter keys that are not used
			filters = omitNotDefinedKeys({
				...sortingFilters,
				...omitNilFilters(query)
			}, Object.keys(queryConfig))

			dispatch(filtersActions.saveFilters({
				key: 'municipalitySorting',
				filters
			}))

			setQuery(filters)
			statsLoadable.current = true
		} else {
			loadStats({
				...sortingFilters,
				municipalityID
			})
			setQuery(filters)
		}
		// eslint-disable-next-line
	}, [dispatch, sortingFilters, loadStats, municipalityID, setQuery])

	const statsDataSource = map(statsState?.data?.stats, (municipalityItem: any) => ({
		key: `${municipalityItem.id}-${municipalityItem.period}`,
		...municipalityItem
	}))

	return (
		<Box>
			<BoxHeader>
				<Breadcrumb items={breadcrumbs}/>
			</BoxHeader>
			<BoxContent className={'padded'}>
				<Row gutter={[16, 16]}>
					<Col flex={1}>
						<ExportButton
							url={'/api/admin/statistics/municipality-sorting/export'}
							context={omitNilFilters({
								...sortingFilters,
								municipalityID
							})}
						/>
					</Col>
					<Col>
						<InputWrapper className={'without-margin inline'}>
							<Label className={'without-margin'}>{t('fields.sortingPeriod.placeholder')}</Label>
							<Select
								style={{ width: 200 }}
								allowClear={false}
								value={sortingFilters.period || undefined}
								onChange={handleChangePeriod}
								options={periodOptions}
								placeholder={t('fields.sortingPeriod.placeholder')}
							/>
						</InputWrapper>
					</Col>
					<Col span={24}>
						<Table
							rowKey={'key'}
							dataSource={statsDataSource || []}
							loading={statsState.isLoading}
							// @ts-ignore
							onChange={handleChangeTable}
							columns={columns}
							pagination={pagination}
							locale={locale}
							scroll={{ x: 1180 }}
						/>
					</Col>
				</Row>
			</BoxContent>
		</Box>
	)
}

export default CollectionPlacesSortingPage
