import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import i18next from 'i18next'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

// components
import UncollectedTableRow from './UncollectedTableRow'

// atoms
import TableHead from '../../atoms/TableHead'
import { EmptyTableState } from '../EmptyTableState'

class UncollectedTable extends React.Component {
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			collectionPlace: PropTypes.string.isRequired
		})),
		isMunicipality: PropTypes.bool,
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string
	}

	render() {
		const { items, loading, onSort, onDetail, sortingColumn, sortingDirection } = this.props
		const rows = map(items, (item, index) =>
			<UncollectedTableRow key={index} item={item} onDetail={onDetail}/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={sortingColumn === 'name'}
								title={i18next.t('loc:Typ zbernej nádoby')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'code'}
								isSorting={sortingColumn === 'code'}
								title={i18next.t('loc:Kód nádoby')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'serialNumber'}
								isSorting={sortingColumn === 'serialNumber'}
								title={i18next.t('loc:Sériové číslo')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'customer'}
								isSorting={sortingColumn === 'customer'}
								title={i18next.t('loc:Platca')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'collectionPlace'}
								isSorting={sortingColumn === 'collectionPlace'}
								title={i18next.t('loc:Zberné miesto')}
								onSort={onSort}
								initDirection={sortingDirection}
								className={'text-center'}
							/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && items && !items.length && <EmptyTableState title={locale['page.statistics.uncollected.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.statistics.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default UncollectedTable
