import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { join, map } from 'lodash'

import arrayMutators from 'final-form-arrays'
import { createForm } from 'final-form'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Col, Row } from 'antd'

import { CloseOutlined } from '@ant-design/icons'

import { collectionRounds, wasteTypes } from '../../../api'
import { CollectionRoundDetail } from '../../../types/data'
import { COLLECTION_ROUND_STATE, DATE_TIME_FORMAT } from '../../../utils/enums'
import { formatUserName } from '../../../utils/formatters'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SelectField from '../../../components/form/SelectField'
import NumberField from '../../../components/form/NumberField'
import TextField from '../../../components/form/TextField'
import TextAreaField from '../../../components/form/TextAreaField'

import InfoField from '../../../components/InfoField'
import Button from '../../../components/buttons/Button'

import { Box, BoxContent, BoxFooter } from '../../../layouts/Box'
import { HeadingTwo } from '../../../components/Typography'

import { validateWasteType } from '../validators/validateCollectionRound'
import { validateRequired, validateGte, composeValidators } from '../../../utils/form'

const WasteTypeCol = styled(Col)`
	width: calc(50% - 38px);
`

const DeleteCol = styled(Col)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 76px;
`

export type FormValues = {
	name: string
	wasteTypes: {
		weight: number
		wasteType: {
			value: number,
			label: React.ReactNode
		}
	}[]
	comment: string | null
}

type Props = {
	onSubmit: () => void
	detail: CollectionRoundDetail
}

const CollectionRoundForm = ({ detail, onSubmit }: Props) => {
	const [t] = useTranslation()

	const [wasteTypesState, loadWasteTypes] = useDataLoader(wasteTypes.loadWasteTypes)
	const [submitting, setSubmitting] = useState(false)

	const handleSubmit = useCallback(async (values: FormValues) => {
		const data = {
			name: values.name,
			comment: values.comment,
			state: COLLECTION_ROUND_STATE.APPROVED,
			wasteTypes: map(values.wasteTypes, (wasteType) => ({
				wasteTypeID: wasteType.wasteType.value,
				weight: wasteType.weight
			}))
		}

		try {
			setSubmitting(true)

			await collectionRounds.updateCollectionRound(detail.id, detail.municipalityID, data)

			onSubmit()
			return Promise.resolve()
		} catch (error) {
			return Promise.resolve({})
		} finally {
			setSubmitting(false)
		}
	}, [detail, onSubmit])

	const form = useMemo(() => createForm({
		mutators: { ...arrayMutators },
		initialValues: {
			name: detail.name,
			comment: detail.comment,
			wasteTypes: map(detail.wasteTypes, (item) => ({
				weight: item.weight,
				wasteType: {
					value: item.wasteTypeID,
					label: `${item.wasteType.code} - ${item.wasteType.name}`
				}
			}))
		},
		onSubmit: handleSubmit
	}), [detail, handleSubmit])

	useEffect(() => {
		loadWasteTypes()
	}, [loadWasteTypes])

	const wasteTypeOptions = useMemo(() => map(wasteTypesState.data, (wasteType) => ({
		value: wasteType.id, label: `${wasteType.code} - ${wasteType.name}`
	})), [wasteTypesState.data])

	return (
		<Box>
			<BoxContent className={'padded tab'}>
				{/* @ts-ignore */}
				<Form
					form={form}
					subscription={{}}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Row gutter={[32, 16]}>
								<Col span={24} md={12}>
									<HeadingTwo>{t('CollectionRoundPage.basicInfoTitle')}</HeadingTwo>
									<Row>
										<Col span={24}>
											<Field
												name={'name'}
												validate={validateRequired}
												validateFields={[]}
												render={(props) => (
													<TextField
														{...props}
														label={t('fields.name.label')}
														placeholder={t('fields.name.placeholder')}
													/>
												)}
											/>
										</Col>
										<Col span={24}>
											<Field
												name={'comment'}
												render={(props) => (
													<TextAreaField
														{...props}
														label={t('fields.comment.label')}
														placeholder={t('fields.comment.placeholder')}
													/>
												)}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={24} md={12}>
									<HeadingTwo>{t('CollectionRoundPage.wasteTypesTitle')}</HeadingTwo>
									<Row gutter={[16, 16]}>
										<FieldArray name={'wasteTypes'} subscription={{}}>
											{({ fields }) => (
												<>
													{fields.map((name, index) => (
														<>
															<WasteTypeCol>
																<Field
																	name={`${name}.wasteType`}
																	validate={composeValidators(validateRequired, validateWasteType)}
																	validateFields={[]}
																	render={(props) => (
																		<SelectField
																			{...props}
																			labelInValue={true}
																			isLoading={wasteTypesState.isLoading}
																			options={wasteTypeOptions}
																			label={t('fields.wasteType.label')}
																			placeholder={t('fields.wasteType.placeholder')}
																		/>
																	)}
																/>
															</WasteTypeCol>
															<WasteTypeCol>
																<Field
																	name={`${name}.weight`}
																	validate={composeValidators(validateRequired, validateGte(0))}
																	validateFields={[]}
																	render={(props) => (
																		<NumberField
																			{...props}
																			required={true}
																			min={0}
																			label={t('fields.weight.label')}
																			placeholder={t('fields.weight.placeholder')}
																		/>
																	)}
																/>
															</WasteTypeCol>
															{index > 0 &&
															<DeleteCol>
																<Button
																	className={'transparent'}
																	style={{ fontSize: 16 }}
																	onClick={() => fields.remove(index)}
																>
																	<CloseOutlined/>
																</Button>
															</DeleteCol>}
														</>
													))}
													<Col span={24}>
														<Button onClick={() => fields.push({ weight: null, wasteType: null })}>
															{t('CollectionRoundPage.addWasteType')}
														</Button>
													</Col>
												</>
											)}
										</FieldArray>
									</Row>
								</Col>
							</Row>
						</form>
					)}
				/>
				<Row gutter={[16, 16]} style={{ marginTop: 32 }}>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.streets')}
							value={detail.streets?.length ? join(detail.streets, ', ') : '-'}
						/>
					</Col>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.weight')}
							value={`${detail.totalWeight.toFixed(2)}kg`}
						/>
					</Col>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.coefficientWeight')}
							value={`${detail.realWeight.toFixed(2)}kg`}
						/>
					</Col>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.start')}
							value={detail.startDatetime ? dayjs(detail.startDatetime).format(DATE_TIME_FORMAT) : '-'}
						/>
					</Col>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.end')}
							value={detail.stopDatetime ? dayjs(detail.stopDatetime).format(DATE_TIME_FORMAT) : '-'}
						/>
					</Col>
					<Col span={24} md={6} sm={12}>
						<InfoField
							title={t('CollectionRoundPage.collectionsCount')}
							value={detail.collectionsCount}
						/>
					</Col>
					<Col span={24}>
						<Row>
							<Col>
								<InfoField
									title={t('common.created')}
									value={(
										<>
											<div>{formatUserName(detail?.creator)}</div>
											<div>{dayjs(detail.createdAt).format(DATE_TIME_FORMAT)}</div>
										</>
									)}
								/>
							</Col>
							{!!detail.editor &&
							<Col>
								<InfoField
									title={t('common.updated')}
									value={(
										<>
											<div>{formatUserName(detail.editor)}</div>
											<div>{dayjs(detail.updatedAt).format(DATE_TIME_FORMAT)}</div>
										</>
									)}
								/>
							</Col>}
						</Row>
					</Col>
				</Row>
			</BoxContent>
			<BoxFooter>
				<Row justify={'end'} gutter={[16, 16]}>
					<Col>
						<Button
							disabled={submitting}
							onClick={onSubmit}
							className={'secondary'}
						>
							{t('common.back')}
						</Button>
					</Col>
					<Col>
						<Button
							disabled={submitting}
							loading={submitting}
							onClick={form.submit}
						>
							{t('common.save')}
						</Button>
					</Col>
				</Row>
			</BoxFooter>
		</Box>
	)
}

export default React.memo(CollectionRoundForm)
