import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, FieldArray, reduxForm, formValueSelector, unregisterField, SubmissionError } from 'redux-form'
import { debounce, map, filter, findIndex, get, some, includes } from 'lodash'
import i18next from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'

import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import { validate, validatorOptions } from '../../utils/validator'

import { statusPush } from '../../actions/statusActions'

import { permissions } from '../../utils/select'
import { PERMISSIONS, FORMS, ENDPOINTS, ERROR } from '../../utils/enums'
import { getReq } from '../../utils/request'
import SwitchField from '../../atoms/SwichField'
import { getAuthUser } from '../../redux/authentication'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateMunicipality = validate([validatorOptions.REQUIRED])
const validateSurname = validate([validatorOptions.REQUIRED])
const validateEmail = validate([validatorOptions.REQUIRED, validatorOptions.EMAIL])
const validatePermissions = validate([validatorOptions.ARRAY])

class UserForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		resendHandler: PropTypes.func.isRequired,
		deleteHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		pushStatus: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		unregisterField: PropTypes.func.isRequired,
		municipalities: PropTypes.arrayOf(PropTypes.object),
		action: PropTypes.shape({
			onChange: PropTypes.func.isRequired,
			checked: PropTypes.bool.isRequired
		})
	}

	searchMunicipalities = (search, callback) => {
		const { municipalities } = this.props
		const context = { page: 1, order: 'name:ASC' }
		if (get(search, 'length')) {
			context.search = search
		}
		getReq(ENDPOINTS.MUNICIPALITIES, context).then((response) => {
			const items = map(response.data.municipalities, (item) => ({
				label: item.name,
				value: item.id,
				key: item.id
			}))
			const filtered = filter(items, (item) => findIndex(municipalities, (m) => (
				get(m, 'municipality.value') === item.value
			)) === -1)
			return callback(filtered)
		}).catch(() => callback([]))
	}

	handleSubmit = (values) => {
		const { initialValues, pushStatus } = this.props

		if (!initialValues.isAdmin && (!values.municipalities || !values.municipalities.length)) {
			pushStatus({
				type: ERROR,
				msg: locale['page.users.detail.municipalities.required']
			})
			throw new SubmissionError({})
		}

		const { submitHandler } = this.props
		submitHandler(values)
	}

	emailFieldOptions = {
		label: i18next.t('loc:Email'),
		required: true
	}

	nameFieldOptions = {
		label: i18next.t('loc:Meno'),
		required: true
	}

	surnameFieldOptions = {
		label: i18next.t('loc:Priezvisko'),
		required: true
	}

	municipalitySelectFieldOptions = {
		label: i18next.t('loc:Obec'),
		placeholder: locale['field.placeholder.municipality'],
		required: true,
		showLabel: true,
		loadOptions: debounce(this.searchMunicipalities, 300),
		onMenuOpen: this.searchMunicipalities
	}

	permissionsSelectFieldOptions = {
		label: i18next.t('loc:Oprávnenia'),
		placeholder: locale['field.placeholder.permissions'],
		required: true,
		showLabel: true,
		isMulti: true,
		options: filter(permissions, ((p) => p.value !== PERMISSIONS.ADMINISTRATOR))
	}

	deleteUser = (e) => {
		e.preventDefault()
		const { deleteHandler } = this.props
		deleteHandler()
	}

	returnToList = (e) => {
		e.preventDefault()
		const { cancelHandler } = this.props
		cancelHandler()
	}

	validateUserPermissions = (value, allValues) => {
		if (allValues.municipalities && allValues.municipalities.length) {
			if (allValues.municipalities.length > 1 && some(allValues.municipalities, (item) => includes(item.permissions, PERMISSIONS.MUNICIPALITY_DUSTMAN))) {
				return locale['error.validation.dustman.multiple.roles']
			} if (value && value.length > 1 && includes(value, PERMISSIONS.MUNICIPALITY_DUSTMAN)) {
				return locale['error.validation.dustman.multiple.roles']
			}
		}
		return validatePermissions(value)
	}

	getMunicipalities = ({ fields }) => {
		function addField() {
			fields.push({
				municipality: null,
				permissions: []
			})
		}

		function removeField(index) {
			return () => {
				fields.remove(index)
			}
		}

		const municipalities = fields.map((municipality, index) => (
			<div key={index} className={'row'}>
				<div className={'col-md-5'}>
					<Field
						name={`${municipality}.municipality`}
						component={AsyncSelectField}
						props={this.municipalitySelectFieldOptions}
						validate={validateMunicipality}
					/>
				</div>
				<div className={'col-md-6'}>
					<Field
						name={`${municipality}.permissions`}
						component={SelectField}
						props={this.permissionsSelectFieldOptions}
						validate={this.validateUserPermissions}
					/>
				</div>
				<div className={'col-1'} style={{ marginTop: '30px' }}>
					<Button onClick={removeField(index, municipality)} className={'secondary'}>
						<FontAwesomeIcon icon={faTrashAlt}/>
					</Button>
				</div>
			</div>
		))

		return (
			<>
				{municipalities}
				<div className={'row'}>
					<div className={'col-12'}>
						<Button onClick={addField}>
							<FontAwesomeIcon icon={faPlusCircle}/>
							<span>{locale['page.users.detail.permissions.add']}</span>
						</Button>
					</div>
				</div>
			</>
		)
	}

	getUserState = () => {
		const { initialValues, resendHandler } = this.props

		if (!initialValues.isConfirmed) {
			const resend = (e) => {
				e.preventDefault()
				resendHandler(initialValues)
			}
			return (
				<>
					<div className={'info-wrapper pull-left'} style={{ marginLeft: '-40px' }}>
						<label>
							{locale['page.users.detail.confirmed.not']}&nbsp;
							<span onClick={resend}>{locale['page.users.detail.invitation.resend']}</span>
						</label>
					</div>
				</>
			)
		}
		return null
	}

	render() {
		const { initialValues, handleSubmit, action } = this.props

		return (
			<form onSubmit={handleSubmit((data) => this.handleSubmit(data))} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}>
							<div className={'row'}>
								<div className={'col-12'}>
									{this.getUserState()}
								</div>
							</div>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<div className={'input-switch'}>
										<SwitchField action={action} className={'pull-right'}/>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='name'
										type={'text'}
										placeholder={locale['field.placeholder.name']}
										component={TextInputField}
										props={this.nameFieldOptions}
										validate={validateName}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='surname'
										type={'text'}
										placeholder={locale['field.placeholder.surname']}
										component={TextInputField}
										props={this.surnameFieldOptions}
										validate={validateSurname}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='email'
										type={'email'}
										placeholder={locale['field.placeholder.email']}
										component={TextInputField}
										props={this.emailFieldOptions}
										validate={validateEmail}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.created']}</label>
										<div>{moment(initialValues.createdAt).format(locale['common.date.format.date'])}</div>
									</div>
								</div>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.login']}</label>
										<div>{initialValues.lastLoginAt ? moment(initialValues.lastLoginAt).format(locale['common.date.format']) : '-'}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.permissions']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<FieldArray name="municipalities" component={this.getMunicipalities}/>
								</div>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button
								onClick={this.deleteUser}
								className={'danger'}
							>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								className={'secondary'}
								onClick={this.returnToList}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
						<Col>
							<Button
								type="submit"
							>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const selector = formValueSelector(FORMS.USER_FORM)

const mapStateToProps = (state) => ({
	user: getAuthUser(state),
	municipalities: selector(state, 'municipalities')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	unregisterField: bindActionCreators(unregisterField, dispatch)
})

export default reduxForm({
	form: FORMS.USER_FORM,
	destroyOnUnmount: true,
	updateUnregisteredFields: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(UserForm))
