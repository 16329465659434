import React from 'react'
import moment from 'moment-timezone'
import cx from 'classnames'
import i18next from 'i18next'

import * as PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import dayjs from 'dayjs'

import {
	get, reduce, startsWith, isEqual, head, last, replace, map
} from 'lodash'

import {
	reset, initialize, change, SubmissionError
} from 'redux-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import ReactQueryParams from 'react-query-params'
import { Tabs, Tab } from 'react-bootstrap'
import locale from '../../resources/locale'

import { statusPush } from '../../actions/statusActions'

import {
	formatAddress, getContainerTypesByCollectionPlaceType, parseNumber
} from '../../utils/utils'

import {
	deleteReq, getReq, patchReq, postReq
} from '../../utils/request'

import { ASSIGN_TYPE } from '../../types/enums'
import { collectionPlaces } from '../../api'

import Breadcrumbs from '../../components/Breadcrumb'
import CollectionPlaceForm from '../../components/collectionPlaces/CollectionPlaceForm'

import {
	ANONYMOUS,
	COLLECTION_PLACE_TYPE,
	CUSTOMER_LEGAL_FORM,
	ENDPOINTS,
	FILTER_ORDER_SELECTORS,
	FORMS,
	INFO,
	MODAL
} from '../../utils/enums'

import { history } from '../../utils/history'
import CollectionPlaceCustomersTable from '../../components/collectionPlaces/CollectionPlaceCustomersTable'
import CollectionPlaceCustomer from '../../components/modals/CollectionPlaceCustomer'
import { resetFiltersOrders, selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import ContainersTable from '../../components/containers/ContainersTable'
import DialogModal from '../../components/modals/DialogModal'
import DeactivateModal from '../../components/modals/DeactivateModal'

import ContainerTypeChangeModal from '../../components/modals/ContainerTypeChangeModal'
import CreateCustomerModal from '../../components/modals/CreateCustomer'
import { formatCustomer } from '../../utils/customer'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import TransferContainerModal from '../../components/containers/TransferContaineModal'
import { getAuthUser } from '../../redux/authentication'
import CollectionPlaceContainerModal from './components/CollectionPlaceContainerModal'

const CONTAINERS_PREFIX = 'containers-'
const CUSTOMERS_PREFIX = 'customers-'

const PERMANENT = 'PERMANENT'
const ONE_TIME_VALID = 'ONE_TIME_VALID'

const queryParams = {
	'customers-search': 'customers-search',
	'customers-order': 'customers-order',
	'customers-page': 'customers-page',
	'customers-limit': 'customers-limit',
	'containers-search': 'containers-search',
	'containers-order': 'containers-order',
	'containers-page': 'containers-page',
	'containers-limit': 'containers-limit',
	'containers-filters': 'containers-filters'
}

const queryTypes = {
	'customers-search': {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	'customers-order': {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'membersCount', 'activeFrom', 'activeTo']
	},
	'customers-page': {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	'customers-limit': {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	'containers-search': {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	'containers-order': {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['containerType', 'capacity', 'type', 'assignedFrom', 'assignedTo', 'code', 'serialNumber']
	},
	'containers-page': {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	'containers-limit': {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	'containers-filters': {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: [PERMANENT, ONE_TIME_VALID]
	}
}

const {
	COLLECTION_PLACE,
	COLLECTION_PLACE_CONTAINERS,
	COLLECTION_PLACE_CUSTOMERS,
	MUNICIPALITY_COLLECTION_PLACE
} = ENDPOINTS

class CollectionPlacePage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		user: PropTypes.object.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				collectionPlaceID: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams['customers-search']]: null,
			[queryParams['customers-order']]: null,
			[queryParams['customers-page']]: null,
			[queryParams['customers-limit']]: null,
			[queryParams['containers-search']]: null,
			[queryParams['containers-order']]: null,
			[queryParams['containers-page']]: null,
			[queryParams['containers-limit']]: null,
			[queryParams['containers-filters']]: [PERMANENT, ONE_TIME_VALID]
		}

		this.state = {
			defaultCustomers: null,
			modal: null,
			modalData: null,
			loading: true,
			place: null,
			containerTypes: [],
			customers: {
				isLoading: true,
				isFailure: false,
				data: null
			},
			containers: {
				isLoading: true,
				isFailure: false,
				data: null
			},
			selectedContainersBulk: false,
			selectedContainersIDs: [],
			key: 'basic-info'
		}
	}

	componentDidMount() {
		this._mounted = true

		const { municipality } = this.props
		if (municipality) {
			this.returnPath = locale.formatString(locale['path.municipality.collections.places'], municipality.id)
		} else {
			this.returnPath = locale['path.lists.collections.places']
		}

		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		this.loadPlace()
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.COLLECTION_PLACE_PAGE, filtersOrder)
	}

	componentWillUnmount() {
		const { resetFiltersOrders } = this.props
		resetFiltersOrders(FILTER_ORDER_SELECTORS.COLLECTION_PLACE_PAGE)
		this._mounted = false
	}

	componentDidUpdate() {
		if (this._mounted) {
			const { place, loading } = this.state
			const { match, selectedFiltersOrders } = this.props
			if (!loading) {
				if (get(match, 'params.collectionPlaceID') !== place.id.toString()) {
					this.loadPlace()
				}
			}
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (prefix) => (type, value) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		let newFilterOrder = null
		switch (type) {
			case queryParams[`${prefix}order`]:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams[`${prefix}page`]:
			case queryParams[`${prefix}limit`]:
			case queryParams[`${prefix}search`]:
				newFilterOrder = (value) ? `${value}` : null
				break
			case queryParams[`${prefix}filters`]:
				newFilterOrder = value
				break
			default:
				break
		}

		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type === `${prefix}search`) {
			filterOrder[`${prefix}page`] = '1'
		}

		this.setQueryParams({
			...filterOrder
		})

		if (prefix === CONTAINERS_PREFIX) {
			this.loadContainers(filterOrder)
		} else if (prefix === CUSTOMERS_PREFIX) {
			this.loadCustomers(filterOrder)
		}

		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.COLLECTION_PLACE_PAGE, filterOrder)
	}

	loadPlace = async () => {
		this.setState({
			loading: true
		})
		const { match } = this.props
		const id = match.params.collectionPlaceID
		try {
			const endpoint = COLLECTION_PLACE(id)
			const response = await getReq(endpoint)
			this.setupForm(response.data)
			this.loadContainerTypes(response.data)
		} catch (error) {
			history.push(locale['path.users'])
		}
	}

	loadContainerTypes = async (place) => {
		try {
			const context = { types: getContainerTypesByCollectionPlaceType(place.type), limit: 'ALL' }
			const response = await getReq(ENDPOINTS.MUNICIPALITY_CONTAINER_TYPES(place.municipalityID), context)
			const containerTypes = map(response.data.containerTypes, (item) => ({
				value: item.id,
				label: item.name,
				type: item.type,
				key: item.id
			}))

			this.setState({ containerTypes })
		} catch (error) {
			// Error
		}
	}

	loadTable = (type, prefix, endpoint) => async (filterOrder) => {
		const { place } = this.state
		const { id } = place
		const { municipalityID } = place

		this.setState({
			[type]: {
				isLoading: true,
				isFailure: false,
				data: get(this.state, `${type}.data`)
			}
		})
		try {
			const context = reduce(filterOrder, (context, value, key) => {
				if (startsWith(key, prefix) && value) {
					context[replace(key, prefix, '')] = value
				}
				return context
			}, { municipalityID })
			const response = await getReq(endpoint(municipalityID, id), context)
			this.setState({
				[type]: {
					isLoading: false,
					isFailure: false,
					data: get(response, 'data')
				}
			})
		} catch (error) {
			this.setState({
				[type]: {
					isLoading: false,
					isFailure: true,
					data: null
				}
			})
		}
	}

	loadContainers = this.loadTable('containers', CONTAINERS_PREFIX, COLLECTION_PLACE_CONTAINERS)
	loadCustomers = this.loadTable('customers', CUSTOMERS_PREFIX, COLLECTION_PLACE_CUSTOMERS)

	clearSelectedContainers = () => this.setState({
		selectedContainersIDs: [],
		selectedContainersBulk: false
	})

	onChangeCustomersFilterOrder = this.onChangeFilterOrder(CUSTOMERS_PREFIX)
	onChangeContainersFilterOrder = (queryParam, value) => {
		this.clearSelectedContainers()
		this.onChangeFilterOrder(CONTAINERS_PREFIX)(queryParam, value)
	}

	setupForm = (place) => {
		const { initialize, reset } = this.props
		initialize(FORMS.COLLECTION_PLACE_FORM, this.getInitialValues(place))
		reset(FORMS.COLLECTION_PLACE_FORM)

		this.setState({
			place,
			loading: false
		}, () => {
			const { selectedFiltersOrders } = this.props
			this.loadCustomers(selectedFiltersOrders)
			this.loadContainers(selectedFiltersOrders)

			initialize(FORMS.CREATE_CUSTOMER_FORM, {
				legalForm: CUSTOMER_LEGAL_FORM.INDIVIDUAL,
				zip: place.zip,
				city: place.city
			})
			reset(FORMS.CREATE_CUSTOMER_FORM)
		})
	}

	collectionPlaceName = (place) => {
		if (place.type === COLLECTION_PLACE_TYPE.COLLECTION_YARD || place.type === COLLECTION_PLACE_TYPE.NEST || place.type === COLLECTION_PLACE_TYPE.OTHER || place.type === COLLECTION_PLACE_TYPE.FLAT_HOUSE) {
			return place.name
		}
		return formatAddress(place)
	}

	saveUpdates = async (values) => {
		const data = {
			name: this.collectionPlaceName(values),
			type: values.type,
			street: values.street,
			streetNumber: values.streetNumber,
			number: values.number,
			parentID: parseNumber(get(values, 'parent.value', null)) || null,
			flatNumber: values.type === COLLECTION_PLACE_TYPE.FLAT ? values.flatNumber : null,
			city: values.city,
			zip: values.zip,
			lat: values.lat,
			lon: values.lon,
			isComposter: values.isComposter,
			description: values.description
		}

		try {
			const { pushStatus } = this.props
			const { place } = this.state
			await patchReq(MUNICIPALITY_COLLECTION_PLACE(place.municipalityID, place.id), null, data)
			this.returnToList()
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.detail.update.success']
			})
		} catch (error) {
			// Error
		}
	}

	setCustomer = async (values) => {
		const { pushStatus, reset, selectedFiltersOrders } = this.props
		const { place } = this.state

		const data = {
			membersCount: place.type !== COLLECTION_PLACE_TYPE.COTTAGE
				? get(values, 'membersCount', 0)
				: 1,
			activeFrom: get(values, 'activeFrom'),
			activeTo: get(values, 'activeTo'),
			isAnonymous: get(values, 'customer.value') === ANONYMOUS
		}

		if (!data.isAnonymous) {
			data.customerID = get(values, 'customer.value')
		}

		const { municipalityID } = place
		const { id } = place
		try {
			await postReq(COLLECTION_PLACE_CUSTOMERS(municipalityID, id), null, data)
			this.dismissModal()
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.detail.customer.set.success']
			})
			reset(FORMS.SET_COLLECTION_PLACE_CUSTOMER_FORM)
			this.loadCustomers(selectedFiltersOrders)
		} catch (error) {
			// Error
		}
	}

	updateCurrentCustomer = (values) => {
		const customers = get(this.state.customers, 'data.customers')
		if (customers && customers.length) {
			this.openModal(MODAL.EXPIRE_PREVIOUS_CUSTOMER)({
				customer: values
			})
		} else {
			this.setCustomer(values)
		}
	}

	returnToList = () => {
		history.push(this.returnPath)
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	dismissContainerModal = () => {
		const { selectedFiltersOrders } = this.props

		this.dismissModal(null)
		this.clearSelectedContainers()
		this.loadContainers(selectedFiltersOrders)
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	getInitialValues = (place) => ({
		id: place.id,
		municipalityID: place.municipalityID,
		name: place.name,
		parent: place.parent ? {
			value: place.parent.id,
			label: place.parent.name
		} : null,
		city: place.city,
		street: place.street,
		isComposter: place.isComposter,
		streetNumber: place.streetNumber,
		number: place.number,
		flatNumber: place.flatNumber,
		type: place.type,
		lat: parseNumber(place.lat),
		lon: parseNumber(place.lon),
		zip: place.zip,
		description: place.description,
		isActive: place.isActive,
		createdAt: place.createdAt,
		creator: place.creator,
		updatedAt: place.updatedAt,
		editor: place.editor
	})

	setPlaceRemoved = async () => {
		const { pushStatus } = this.props
		const { place } = this.state
		this.dismissModal()
		try {
			await deleteReq(MUNICIPALITY_COLLECTION_PLACE(place.municipalityID, place.id), null)
			history.replace(this.returnPath)
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.delete.success']
			})
		} catch (error) {
			// Error
		}
	}

	onDeleteContainer = async () => {
		try {
			this.dismissModal()
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData, place } = this.state

			const { municipalityID } = place
			await deleteReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipalityID, get(modalData, 'id')))

			pushStatus({
				type: INFO,
				msg: locale['page.containers.delete.success']
			})

			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onDeleteCustomer = async () => {
		try {
			this.dismissModal()
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData, place } = this.state

			const { id } = place
			const { municipalityID } = place
			await deleteReq(ENDPOINTS.COLLECTION_PLACE_CUSTOMER(municipalityID, id, get(modalData, 'id')))
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.customer.remove.success']
			})
			this.loadCustomers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onEditCustomer = async (data) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData, place } = this.state

			const { id } = place
			const { municipalityID } = place
			await patchReq(ENDPOINTS.COLLECTION_PLACE_CUSTOMER(municipalityID, id, get(modalData, 'id')), null, data)
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.customer.update.success']
			})
			this.dismissModal()
			this.loadCustomers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onEditContainer = async (data) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData, place } = this.state

			const containerData = {
				assignedTo: moment(data.activeTo).endOf('day')
			}
			const { municipalityID } = place
			await patchReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipalityID, get(modalData, 'id')), null, containerData)
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.container.update.success']
			})
			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	onEditContainersBulk = async (data) => {
		const { pushStatus, selectedFiltersOrders } = this.props

		try {
			const params = {
				containerIDs: this.state.selectedContainersIDs,
				deactivationDate: moment(data.activeTo).endOf('day')
			}

			const response = await patchReq(ENDPOINTS.CONTAINERS_DEACTIVATE, null, params)
			pushStatus(response?.data?.messages?.[0])

			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	changeContainerType = async (data) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData, place } = this.state

			const containerData = {
				containerTypeID: data.containerTypeID
			}

			const { municipalityID } = place
			await patchReq(ENDPOINTS.MUNICIPALITY_CONTAINER(municipalityID, get(modalData, 'id')), null, containerData)

			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.container.update.success']
			})

			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	changeContainersTypeBulk = async ({ containerTypeID }) => {
		const { pushStatus, selectedFiltersOrders } = this.props

		try {
			const params = {
				containerIDs: this.state.selectedContainersIDs,
				containerTypeID
			}

			const response = await patchReq(ENDPOINTS.CONTAINERS_CHANGE_TYPE, null, params)
			pushStatus(response?.data?.messages?.[0])

			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	createCustomer = async (values) => {
		const { pushStatus, reset, change } = this.props
		const { place } = this.state

		try {
			const body = {
				legalForm: values.legalForm,
				name: values.name,
				surname: values.surname,
				titleBefore: values.titleBefore,
				titleAfter: values.titleAfter,
				companyName: values.companyName,
				city: values.city,
				zip: values.zip,
				ico: values.ico,
				street: values.street,
				streetNumber: values.streetNumber,
				number: values.number,
				activeFrom: values.activeFrom,
				activeTo: values.activeTo
			}
			const response = await postReq(ENDPOINTS.MUNICIPALITY_CUSTOMERS(place.municipalityID), null, body)

			const context = {
				exclude: 'ANONYMOUS',
				order: 'name:ASC',
				municipalityID: place.municipalityID
			}

			const optionsResponse = await getReq(ENDPOINTS.CUSTOMERS, context)

			const defaultCustomers = map(optionsResponse.data.customers, (item) => ({
				label: `${item.name}, ${item.address}`,
				value: item.id,
				key: item.id,
				activeTo: item.activeTo,
				item
			}))

			pushStatus({
				type: INFO,
				msg: locale['page.customers.create.success']
			})

			const value = {
				key: response.data.id,
				value: response.data.id,
				label: formatCustomer(response.data),
				item: response.data
			}

			this.setState({ defaultCustomers })

			reset(FORMS.CREATE_CUSTOMER_FORM)
			change(FORMS.SET_COLLECTION_PLACE_CUSTOMER_FORM, 'customer', value)
			this.openModal(MODAL.SET_COLLECTION_PLACE_CUSTOMER)()
		} catch (e) {
			// Error
		}
	}

	onSelectCustomerAddress = (value) => {
		const { change } = this.props

		change(FORMS.CREATE_CUSTOMER_FORM, 'street', value.street)
		change(FORMS.CREATE_CUSTOMER_FORM, 'zip', value.zip)
		change(FORMS.CREATE_CUSTOMER_FORM, 'city', value.city)
	}

	openEditCustomer = (customer) => {
		if (!customer.activeTo) {
			this.openModal(MODAL.SET_INACTIVE_CUSTOMER)(customer)
		} else {
			this.openModal(MODAL.SET_ACTIVE_CUSTOMER)(customer)
		}
	}

	openEditContainer = (container) => {
		if (!container.assignedTo) {
			this.openModal(MODAL.SET_INACTIVE_CONTAINER)(container)
		} else {
			this.openModal(MODAL.SET_ACTIVE_CONTAINER)(container)
		}
	}

	onTransferContainer = async (values) => {
		try {
			const { pushStatus, selectedFiltersOrders } = this.props
			const { modalData } = this.state
			const url = ENDPOINTS.CONTAINER_TRANSFER(get(modalData, 'municipalityID'), get(modalData, 'id'))

			await patchReq(url, {}, { ...values, collectionPlaceID: values.collectionPlaceID.value })

			pushStatus({
				type: INFO,
				msg: locale['page.container.transfer.success']
			})

			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(selectedFiltersOrders)

			return true
		} catch (e) {
			return Promise.reject(new SubmissionError({}))
		}
	}

	onTransferContainersBulk = async (values) => {
		const { pushStatus, selectedFiltersOrders } = this.props

		try {
			const params = {
				containers: this.state.selectedContainersIDs,
				collectionPlaceID: values.collectionPlaceID.value
			}

			const response = await patchReq(ENDPOINTS.CONTAINERS_TRANSFER, null, params)
			pushStatus(response?.data?.messages?.[0])

			const newFilterOrder = {
				selectedFiltersOrders,
				'containers-page': 1
			}

			this.dismissModal()
			this.clearSelectedContainers()
			this.loadContainers(newFilterOrder)
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	breadcrumbsItems = (place) => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return {
			items: [...items, {
				key: 'page.lists.breadcrumbs',
				name: locale['page.lists.breadcrumbs']
			}, {
				key: 'page.collectionPlaces.breadcrumbs',
				name: locale['page.collectionPlaces.breadcrumbs'],
				link: municipality ?
					locale.formatString(locale['path.municipality.collections.places'], municipality.id)
					: locale['path.lists.collections.places']
			}, {
				key: 'page.collectionPlaces.detail.breadcrumbs',
				name: place.name
			}]
		}
	}

	openCustomerDetail = (customer) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.customers.detail.municipalityPath', { municipalityID: municipality.id, customerID: customer.customerID })
			: i18next.t('paths:LISTS.customers.detail.path', { customerID: customer.customerID }))
	}

	openContainerDetail = (containerID) => {
		const { municipality } = this.props

		history.push(municipality
			? i18next.t('paths:LISTS.containers.detail.municipalityPath', { municipalityID: municipality.id, containerID })
			: i18next.t('paths:LISTS.containers.detail.path', { municipalityID: municipality.id, containerID }))
	}

	collectionPlaceContainerModalSubmit = async (values, form) => {
		try {
			const { place } = this.state
			const { pushStatus } = this.props

			const submitData = {
				containerTypeID: values.containerType.value,
				assignedFrom: dayjs(values.assignedFrom).startOf('day'),
				codes: values.type === ASSIGN_TYPE.FIRST_AND_LAST ? [values.firstCode, values.lastCode] : values.codes,
				assignType: values.type
			}

			const { data } = await collectionPlaces.assignContainers(place.id, place.municipalityID, submitData)

			pushStatus(data.messages[0])
			form.restart()

			this.dismissContainerModal()
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	render() {
		const { selectedFiltersOrders, user, municipality } = this.props
		const { loading, place, customers, containers, modal, modalData, containerTypes, defaultCustomers } = this.state

		const customersOrder = selectedFiltersOrders[`${CUSTOMERS_PREFIX}order`] || ''
		const customersSortingColumn = head(customersOrder.split(':'))
		const customersSortingDirection = last(customersOrder.split(':'))

		const containersOrder = selectedFiltersOrders[`${CONTAINERS_PREFIX}order`] || ''
		const containersSortingColumn = head(containersOrder.split(':'))
		const containersSortingDirection = last(containersOrder.split(':'))

		if (loading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !loading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.collectionPlaces.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems(place)}
				/>
				<Tabs
					id="collection-place-tabs"
					activeKey={this.state.key}
					onSelect={(key) => this.setState({ key })}
				>
					<Tab eventKey="basic-info" title={locale['common.basic.info']}>
						<CollectionPlaceForm
							initialValues={this.getInitialValues(place)}
							submitHandler={this.saveUpdates}
							cancelHandler={this.returnToList}
							deleteHandler={this.openModal(MODAL.REMOVE_COLLECTION_PLACE)}
							municipalityID={get(place, 'municipalityID')}
						/>
					</Tab>

					{place && (place.type === COLLECTION_PLACE_TYPE.FAMILY_HOUSE || place.type === COLLECTION_PLACE_TYPE.FLAT || place.type === COLLECTION_PLACE_TYPE.OTHER || place.type === COLLECTION_PLACE_TYPE.COTTAGE) &&
					<Tab eventKey="customer-list" title={locale['page.collectionPlaces.detail.customers']}>
						<CollectionPlaceCustomersTable
							customers={get(customers, 'data.customers')}
							context={get(customers, 'data.context')}
							loading={get(customers, 'isLoading')}
							search={selectedFiltersOrders['customers-search']}
							sortingColumn={customersSortingColumn}
							sortingDirection={customersSortingDirection}
							onEdit={this.openEditCustomer}
							onDetail={this.openCustomerDetail}
							onDelete={this.openModal(MODAL.DELETE_CUSTOMER)}
							onSet={this.openModal(MODAL.SET_COLLECTION_PLACE_CUSTOMER)}
							onSort={(val) => this.onChangeCustomersFilterOrder(queryParams['customers-order'], val)}
							onSearch={(val) => this.onChangeCustomersFilterOrder(queryParams['customers-search'], val)}
							onPage={(val) => this.onChangeCustomersFilterOrder(queryParams['customers-page'], val)}
							onCreateCustomer={this.openModal(MODAL.CREATE_CUSTOMER)}
							createHandler={this.openModal(MODAL.SET_COLLECTION_PLACE_CUSTOMER)}
							limit={parseInt(selectedFiltersOrders[queryParams['customers-limit']], 10)}
							onLimit={(limit) => this.onChangeCustomersFilterOrder(queryParams['customers-limit'], limit)}
						/>
					</Tab>}
					<Tab eventKey="list-of-containers" title={locale['page.collectionPlaces.detail.containers']}>
						<ContainersTable
							creatable={true}
							isSelectableContainers
							selectedContainersBulk={this.state.selectedContainersBulk}
							selectedContainersIDs={this.state.selectedContainersIDs}
							onSelectContainers={(selectedContainersIDs, selectedContainersBulk) => this.setState({
								selectedContainersIDs,
								selectedContainersBulk
							})}
							containers={get(containers, 'data.containers')}
							permanentCount={get(containers, 'data.otherContainersSummary.permanentCount') || '0'}
							universalCount={get(containers, 'data.otherContainersSummary.universalCount') || '0'}
							oneTimeContainersSummary={get(containers, 'data.oneTimeContainersSummary')}
							sortingSummary={get(containers, 'data.sortingSummary')}
							context={get(containers, 'data.context')}
							loading={get(containers, 'isLoading')}
							search={selectedFiltersOrders['containers-search']}
							sortingColumn={containersSortingColumn}
							sortingDirection={containersSortingDirection}
							onDetail={this.openContainerDetail}
							onEdit={this.openEditContainer}
							onEditBulk={this.openModal(MODAL.SET_INACTIVE_CONTAINERS_BULK)}
							onTransfer={this.openModal(MODAL.TRANSFER_CONTAINER)}
							onTransferBulk={this.openModal(MODAL.TRANSFER_CONTAINERS_BULK)}
							onChangeContainerType={this.openModal(MODAL.CHANGE_CONTAINER_TYPE)}
							onChangeContainersTypeBulk={this.openModal(MODAL.CHANGE_CONTAINERS_TYPE_BULK)}
							onDelete={user.isAdmin ? this.openModal(MODAL.DELETE_CONTAINER) : null}
							onAdd={this.openModal(MODAL.SET_COLLECTION_PLACE_CONTAINER)}
							onSort={(val) => this.onChangeContainersFilterOrder(queryParams['containers-order'], val)}
							onFilter={(val) => this.onChangeContainersFilterOrder(queryParams['containers-filters'], val)}
							onSearch={(val) => this.onChangeContainersFilterOrder(queryParams['containers-search'], val)}
							onPage={(val) => this.onChangeContainersFilterOrder(queryParams['containers-page'], val)}
							filter={selectedFiltersOrders[queryParams['containers-filters']]}
							limit={parseInt(selectedFiltersOrders[queryParams['containers-limit']], 10)}
							onLimit={(limit) => this.onChangeContainersFilterOrder(queryParams['containers-limit'], limit)}
						/>
					</Tab>
				</Tabs>
				<CollectionPlaceCustomer
					shown={modal === MODAL.SET_COLLECTION_PLACE_CUSTOMER}
					title={locale['page.collectionPlaces.detail.customers.set']}
					municipalityID={place.municipalityID}
					dismissHandler={this.dismissModal}
					submitHandler={this.updateCurrentCustomer}
					cancelHandler={this.dismissModal}
					hideMembersCount={place.type === COLLECTION_PLACE_TYPE.COTTAGE}
					defaultCustomers={defaultCustomers}
				/>
				<CollectionPlaceContainerModal
					municipalityID={place.municipalityID}
					collectionPlaceType={place.type}
					visible={modal === MODAL.SET_COLLECTION_PLACE_CONTAINER}
					handleSubmit={this.collectionPlaceContainerModalSubmit}
					onClose={this.dismissContainerModal}
				/>
				<DeactivateModal
					shown={modal === MODAL.SET_INACTIVE_CUSTOMER}
					dismissHandler={this.dismissModal}
					message={locale.formatString(locale['page.collectionPlaces.customers.deactivate.message'], get(modalData, 'name', ''))}
					title={locale.formatString(locale['page.collectionPlaces.customers.deactivate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.customers.deactivate.accept']}
					onSubmit={this.onEditCustomer}
				/>
				<DialogModal
					shown={modal === MODAL.SET_ACTIVE_CUSTOMER}
					cancelHandler={this.dismissModal}
					acceptHandler={() => this.onEditCustomer({ activeTo: null })}
					message={locale['page.collectionPlaces.customers.activate.message']}
					title={locale.formatString(locale['page.collectionPlaces.customers.activate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.customers.activate.accept']}
				/>
				<DeactivateModal
					shown={modal === MODAL.SET_INACTIVE_CONTAINERS_BULK}
					dismissHandler={this.dismissModal}
					message={i18next.t('CollectionPlacePage.deactivateBulk.message')}
					title={i18next.t('CollectionPlacePage.deactivateBulk.title')}
					acceptTitle={i18next.t('CollectionPlacePage.deactivateBulk.accept')}
					onSubmit={this.onEditContainersBulk}
				/>
				<DeactivateModal
					shown={modal === MODAL.SET_INACTIVE_CONTAINER}
					dismissHandler={this.dismissModal}
					message={locale.formatString(locale['page.collectionPlaces.containers.deactivate.message'], get(modalData, 'name', ''))}
					title={locale.formatString(locale['page.collectionPlaces.containers.deactivate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.containers.deactivate.accept']}
					onSubmit={this.onEditContainer}
				/>
				<DialogModal
					shown={modal === MODAL.SET_ACTIVE_CONTAINER}
					cancelHandler={this.dismissModal}
					acceptHandler={() => this.onEditContainer({ activeTo: null })}
					message={locale['page.collectionPlaces.containers.activate.message']}
					title={locale.formatString(locale['page.collectionPlaces.containers.activate.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.containers.activate.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION_PLACE}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setPlaceRemoved}
					message={locale['page.collectionPlaces.delete.message']}
					title={locale.formatString(locale['page.collectionPlaces.delete.title'], place.name)}
					acceptTitle={locale['page.collectionPlaces.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_CONTAINER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteContainer}
					message={locale.formatString(locale['page.containers.remove.message'])}
					title={locale.formatString(locale['page.containers.remove.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.containers.remove.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_CUSTOMER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteCustomer}
					message={locale.formatString(locale['page.collectionPlaces.customer.remove.message'])}
					title={locale.formatString(locale['page.collectionPlaces.customer.remove.title'], get(modalData, 'name', ''))}
					acceptTitle={locale['page.collectionPlaces.delete.accept']}
				/>
				<DialogModal
					shown={modal === MODAL.EXPIRE_PREVIOUS_CUSTOMER}
					cancelHandler={this.dismissModal}
					acceptHandler={() => this.setCustomer(get(modalData, 'customer', {}))}
					message={locale['page.collectionPlaces.customer.expire.message']}
					title={locale['page.collectionPlaces.customer.expire.title']}
					acceptTitle={locale['page.collectionPlaces.containers.customer.expire.accept']}
				/>
				<ContainerTypeChangeModal
					shown={modal === MODAL.CHANGE_CONTAINER_TYPE}
					cancelHandler={this.dismissModal}
					title={locale['page.collections.change.container.type.selected.title']}
					acceptTitle={locale['page.collections.change.container.accept']}
					municipality={municipality}
					selectOptions={containerTypes}
					onSubmit={this.changeContainerType}
				/>
				<ContainerTypeChangeModal
					shown={modal === MODAL.CHANGE_CONTAINERS_TYPE_BULK}
					cancelHandler={this.dismissModal}
					title={i18next.t('CollectionPlacePage.changeType.title')}
					acceptTitle={i18next.t('CollectionPlacePage.changeType.accept')}
					municipality={municipality}
					selectOptions={containerTypes}
					onSubmit={this.changeContainersTypeBulk}
				/>
				<CreateCustomerModal
					dismissHandler={this.dismissModal}
					createHandler={this.createCustomer}
					shown={modal === MODAL.CREATE_CUSTOMER}
					municipalityID={place.municipalityID}
					onSelectSuggestion={this.onSelectCustomerAddress}
				/>
				{/* NEPoužíva nový spôsob transferu nádob https://goodrequest.atlassian.net/browse/MO-1378?focusedCommentId=62573 */}
				<TransferContainerModal
					onDismiss={this.dismissModal}
					onSubmit={this.onTransferContainer}
					municipalityID={place?.municipalityID}
					shown={modal === MODAL.TRANSFER_CONTAINER}
				/>
				{/* NOTE: Používa nový spôsob transferu nádob https://goodrequest.atlassian.net/browse/MO-1378?focusedCommentId=62573 */}
				<TransferContainerModal
					onDismiss={this.dismissModal}
					onSubmit={this.onTransferContainersBulk}
					municipalityID={place?.municipalityID}
					hideFieldAssignedFrom
					shown={modal === MODAL.TRANSFER_CONTAINERS_BULK}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state),
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.COLLECTION_PLACE_PAGE]

})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	change: bindActionCreators(change, dispatch),
	reset: bindActionCreators(reset, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch),
	resetFiltersOrders: bindActionCreators(resetFiltersOrders, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPlacePage)
