import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'

import { map } from 'lodash'

import locale from '../../resources/locale'

import { Modal, ModalHead, ModalContent, ModalFooter } from '../Modal'
import Button from '../buttons/Button'

class DialogModal extends React.Component {
	static propTypes = {
		cancelHandler: PropTypes.func.isRequired,
		acceptHandler: PropTypes.func,
		message: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		acceptTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		buttons: PropTypes.arrayOf(PropTypes.shape({
			title: PropTypes.string.isRequired,
			callback: PropTypes.func.isRequired
		})),
		shown: PropTypes.bool
	}

	render() {
		const { cancelHandler, acceptHandler, shown, title, message, acceptTitle, cancelTitle, buttons } = this.props
		return (
			<Modal
				width={600}
				onCancel={cancelHandler}
				footer={null}
				visible={shown}
			>
				<ModalHead className={'modal-header'}>
					{title}
				</ModalHead>
				<ModalContent className={'modal-content'}>
					{message}
				</ModalContent>
				<ModalFooter>
					<Row gutter={16} justify={'end'}>
						<Col>
							<Button onClick={cancelHandler} className={'secondary'}>
								{cancelTitle || locale['dialog.cancel.title']}
							</Button>
						</Col>
						{!!buttons &&
						map(buttons, (button) => (
							<Col>
								<Button key={button.title} onClick={button.callback}>
									{button.title}
								</Button>
							</Col>
						))}
						<Col>
							{!!acceptHandler &&
							<Button onClick={acceptHandler}>
								{acceptTitle || locale['dialog.accept.title']}
							</Button>}
						</Col>
					</Row>
				</ModalFooter>
			</Modal>
		)
	}
}

export default DialogModal
