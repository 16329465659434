import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get, debounce, some, filter } from 'lodash'
import i18next from 'i18next'

import { Col, Row } from 'antd'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

// components
import ContainerTableRow from './ContainerTableRow'
import TableHead from '../../atoms/TableHead'
import Pagination from '../../atoms/Pagination'
import SearchInputField from '../../atoms/SearchInputField'
import { EmptyTableState } from '../EmptyTableState'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import ContainersSummaryTable from '../collectionPlaces/ContainersSummaryTable'
import CollectionPlaceSortingGraph from '../collectionPlaces/CollectionPlaceSortingGraph'
import { ENDPOINTS } from '../../utils/enums'
import ExportButton from '../buttons/ExportButton'
import RadioButton from '../buttons/RadioButton'
import Button from '../buttons/Button'
import { CheckBoxRaw } from '../../atoms/CheckBoxField'

class ContainersTable extends React.Component {
	static propTypes = {
		containers: PropTypes.arrayOf(PropTypes.object),
		oneTimeContainersSummary: PropTypes.arrayOf(PropTypes.object),
		permanentCount: PropTypes.string,
		universalCount: PropTypes.string,
		sortingSummary: PropTypes.arrayOf(PropTypes.object),
		context: PropTypes.object,
		error: PropTypes.string,
		search: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onAdd: PropTypes.func.isRequired,
		onSort: PropTypes.func.isRequired,
		onPage: PropTypes.func.isRequired,
		onSearch: PropTypes.func.isRequired,
		onFilter: PropTypes.func,
		onDelete: PropTypes.func,
		onEdit: PropTypes.func.isRequired,
		onEditBulk: PropTypes.func,
		onDetail: PropTypes.func,
		onChangeContainerType: PropTypes.func.isRequired,
		onChangeContainersTypeBulk: PropTypes.func,
		onTransfer: PropTypes.func.isRequired,
		onTransferBulk: PropTypes.func.isRequired,
		filter: PropTypes.arrayOf(PropTypes.string),
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string,
		showPlace: PropTypes.bool,
		creatable: PropTypes.bool,
		limit: PropTypes.number,
		onLimit: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		isSelectableContainers: PropTypes.bool,
		selectedContainersBulk: PropTypes.bool,
		selectedContainersIDs: PropTypes.array,
		onSelectContainers: PropTypes.func
	}

	handleBulkChange = (selectedBulk) => {
		const { containers, onSelectContainers } = this.props
		const selectedContainersIDs = selectedBulk ? map(containers, (item) => item.id) : []

		onSelectContainers?.(selectedContainersIDs, selectedBulk)
	}

	handleRowSelected = (containerID, value) => {
		const { onSelectContainers } = this.props
		let selectedContainersIDs
		if (value) {
			selectedContainersIDs = [...this.props.selectedContainersIDs, containerID]
		} else {
			selectedContainersIDs = filter(this.props.selectedContainersIDs, (id) => id !== containerID)
		}
		onSelectContainers?.(selectedContainersIDs, false)
	}

	render() {
		const {
			containers, limit, onLimit, creatable, showPlace, context, search, loading, onAdd, onSort, onSearch, onTransfer, onTransferBulk, onDetail, filter, onFilter,
			onPage, sortingColumn, sortingDirection, onDelete, onEdit, onEditBulk, onChangeContainerType, onChangeContainersTypeBulk, oneTimeContainersSummary, sortingSummary, municipality,
			permanentCount, universalCount, isSelectableContainers, selectedContainersBulk, selectedContainersIDs
		} = this.props

		const rows = map(containers, (container, index) =>
			<ContainerTableRow
				isSelectable={isSelectableContainers}
				isSelected={some(selectedContainersIDs, (id) => id === container.id)}
				onSelected={this.handleRowSelected}
				key={get(container, 'id')}
				container={container}
				showPlace={showPlace}
				countIndex={index}
				onDelete={onDelete}
				onEdit={onEdit}
				onTransfer={onTransfer}
				onDetail={onDetail}
				onChangeContainerType={onChangeContainerType}
			/>)
		return (
			<div className={'box'}>
				<div className={'box-content'}>
					<div className={'box-head'}>
						{!!oneTimeContainersSummary && <div className={'row'}>
							<div className={'col-xl-8'}>
								<ContainersSummaryTable
									permanentCount={permanentCount}
									universalCount={universalCount}
									oneTimeContainersSummary={oneTimeContainersSummary}
								/>
							</div>
							<div className={'col-xl-4'}>
								<CollectionPlaceSortingGraph sortingSummary={sortingSummary}/>
							</div>
						</div>}
						<Row gutter={[16, 16]} justify={'space-between'}>
							<Col>
								<SearchInputField
									name={'search'}
									onChange={debounce(onSearch, 300)}
									defaultValue={search || ''}
								/>
							</Col>
							{!!municipality &&
							<Col>
								<ExportButton url={ENDPOINTS.CONTAINERS_EXPORT(municipality.id)} context={{ municipalityID: municipality.id }}/>
							</Col>}
							<Col flex={1}/>
							<Col>
								{creatable &&
								<Button
									onClick={onAdd}
								>
									<span>{locale['page.collectionPlaces.detail.containers.add']}</span>
								</Button>}
							</Col>
							{onFilter &&
							<Col span={24}>
								<RadioButton
									value={filter}
									type={'checkbox'}
									onChange={(value) => onFilter ? onFilter(value) : undefined}
									options={[{
										value: 'PERMANENT',
										label: 'Fixné'
									}, {
										value: 'ONE_TIME_VALID',
										label: 'Jednorázové - platné'
									}, {
										value: 'ONE_TIME_EXPIRED',
										label: 'Jednorázové - expirované'
									}]}
								/>
							</Col>}
						</Row>
					</div>
					<div className={'box-body'}>
						<div className={'table-wrapper'}>
							<table>
								<thead>
									<tr>
										{isSelectableContainers && <th style={{ width: '50px' }}>
											<CheckBoxRaw
												name={'bulk'}
												checked={selectedContainersBulk}
												onChange={this.handleBulkChange}
											/>
										</th>}
										<TableHead
											name={'containerType'}
											title={i18next.t('loc:Typ zbernej nádoby')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'containerType'}
										/>
										<TableHead
											name={'capacity'}
											title={i18next.t('loc:Objem')}
											className={'text-center'}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'capacity'}
										/>
										<TableHead
											name={'type'}
											title={i18next.t('loc:Typ')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'type'}
										/>
										<TableHead
											name={'assignedFrom'}
											title={i18next.t('loc:Priradená od')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'assignedFrom'}
										/>
										<TableHead
											name={'assignedTo'}
											title={i18next.t('loc:Priradená do')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'assignedTo'}
										/>
										<TableHead
											name={'code'}
											title={i18next.t('loc:Kód')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'code'}
										/>
										<TableHead
											name={'serialNumber'}
											title={i18next.t('loc:Číslo')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'serialNumber'}
										/>
										<TableHead
											name={'rfid'}
											title={i18next.t('loc:RFID')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'rfid'}
										/>
										<TableHead
											name={'rfidSerial'}
											title={i18next.t('loc:RFID sériové číslo')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'rfidSerial'}
										/>
										{showPlace &&
										<TableHead
											name={'place'}
											title={i18next.t('loc:Zberné miesto')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'place'}
										/>}
										{showPlace &&
										<TableHead
											name={'customerName'}
											title={i18next.t('loc:Platca')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'customerName'}
										/>}
										<th/>
									</tr>
								</thead>
								<tbody>
									{rows}
								</tbody>
							</table>
							{!loading && containers && !containers.length && <EmptyTableState title={locale['page.collectionPlaces.detail.containers.none']}/>}
							<div className={cx('table-loading', { hidden: !loading })}>
								<div>
									<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
									<span>{locale['page.containers.loading']}</span>
								</div>
							</div>
						</div>
						<ListItemsLimit
							limit={limit || 20}
							onLimit={onLimit}
						/>
						{isSelectableContainers && selectedContainersIDs?.length > 0 && <Row gutter={16} style={{ marginTop: 16, marginLeft: 32 }}>
							<Col>
								<Button onClick={onEditBulk}>
									{i18next.t('ContainersTable.deactivate')}
								</Button>
							</Col>
							<Col>
								<Button onClick={onChangeContainersTypeBulk}>
									{i18next.t('ContainersTable.changeType')}
								</Button>
							</Col>
							<Col>
								<Button onClick={onTransferBulk}>
									{i18next.t('ContainersTable.transferBulk')}
								</Button>
							</Col>
						</Row>}
						<Pagination
							pages={get(context, 'pages')}
							page={get(context, 'page')}
							onPage={onPage}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default ContainersTable
