import React, { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { capitalize, find, map, reduce, times, uniqBy } from 'lodash'

import Chart from 'chart.js/auto'

import { Col, Row } from 'antd'

import theme from '../../../utils/theme'
import { PERIOD } from '../../../utils/enums'

import { statistics } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SegmentedButton from '../../../components/buttons/SegmentedButton'
import SpinLoading from '../../../components/SpinLoading'

import YearStepper from './YearStepper'

import { SubHeading } from '../../../components/Typography'
import { Checkbox } from '../../../components/form/Styled'
import { WasteTypeBase } from '../../../types/data'

const ChartWrapper = styled.div`
	margin-top: 8px;
	height: 542px;
`

enum CHART_TYPE {
	CONTAINERS = 'CONTAINERS',
	WEIGHT = 'WEIGHT'
}

enum SORTED_TYPE {
	SORTED = 'SORTED',
	UNSORTED = 'UNSORTED'
}

export type Props = {
	municipalityID: number
}

const baseOptions = {
	borderRadius: 2,
	pointStyle: 'circle',
	pointRadius: 2
}

const WasteTypesSummary = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const chartRef = useRef<Chart | null>(null)

	const [type, setType] = useState<string | null>(CHART_TYPE.CONTAINERS)
	const [period, setPeriod] = useState<string | null>(PERIOD.MONTH)
	const [yearComparisonType, setYearComparisonType] = useState<string | null>(SORTED_TYPE.SORTED)
	const [year, setYear] = useState(() => dayjs().year())
	const [yearsComparison, setYearsComparison] = useState(false)

	const [summaryState, loadSummary] = useDataLoader(statistics.loadCollectionsSummary)
	const [yearComparisonSummaryState, loadYearComparisonSummary, resetLastYearSummary] = useDataLoader(statistics.loadCollectionsSummary)

	const typeOptions = useMemo(() => [{
		value: CHART_TYPE.CONTAINERS, label: t('fields.containers.label')
	}, {
		value: CHART_TYPE.WEIGHT, label: t('fields.weight.label')
	}], [t])

	const periodOptions = useMemo(() => [{
		value: PERIOD.MONTH, label: t('PERIOD.MONTH')
	}, {
		value: PERIOD.QUARTER, label: t('PERIOD.QUARTER')
	}], [t])

	const sortedOptions = useMemo(() => [{
		value: SORTED_TYPE.SORTED, label: t('SORTED_TYPE.SORTED')
	}, {
		value: SORTED_TYPE.UNSORTED, label: t('SORTED_TYPE.UNSORTED')
	}], [t])

	useEffect(() => {
		if (summaryState.data && !yearsComparison) {
			const { collections, period } = summaryState.data

			const day = dayjs().startOf('year')

			const labels = period === PERIOD.MONTH
				? times(12, (index) => capitalize(day.add(index, 'month').format('MMMM')))
				: times(4, (index) => t('common.quarter', { quarter: index + 1 }))

			const sortedWasteTypes = uniqBy(reduce(collections, (result, { sorted }) => {
				const wasteTypes = map(sorted, (item) => item.wasteType)

				return [...result, ...wasteTypes]
			}, [] as WasteTypeBase[]), 'id')

			const data = {
				labels,
				datasets: [{
					...baseOptions,
					label: t('DashboardPage.unsorted'),
					maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
					data: map(collections, ({ unsorted }) => type === CHART_TYPE.WEIGHT
						? unsorted.weight
						: unsorted.count),
					backgroundColor: theme.colors.neutral['400'],
					stack: 'unsorted'
				}, ...map(sortedWasteTypes, (wasteType) => ({
					...baseOptions,
					label: wasteType.name,
					maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
					backgroundColor: `#${wasteType.color}`,
					data: map(collections, ({ sorted }) => {
						const item = find(sorted, (item) => item.wasteType.id === wasteType.id)

						return type === CHART_TYPE.WEIGHT
							? item?.weight || 0
							: item?.count || 0
					}),
					stack: 'sorted'
				}))]
			}

			chartRef.current?.destroy()
			const context = canvasRef.current?.getContext('2d')

			if (context) {
				chartRef.current = new Chart(context, {
					type: 'bar',
					data: data as any,
					options: {
						maintainAspectRatio: false,
						plugins: {
							datalabels: {
								display: false
							},
							tooltip: {
								callbacks: {
									label: ({ formattedValue, raw }) => type === CHART_TYPE.WEIGHT
										? `${formattedValue} kg`
										: t('DashboardPage.containersCount', { count: Number(raw) })
								}
							},
							legend: {
								labels: {
									usePointStyle: true
								}
							}
						}
					}
				})
			}
		}
	}, [summaryState, t, type, yearsComparison])

	useEffect(() => {
		if (summaryState.data && yearComparisonSummaryState.data && yearsComparison) {
			const { collections, year, period } = summaryState.data
			const { collections: prevCollections, year: prevYear } = yearComparisonSummaryState.data

			const day = dayjs().startOf('year')

			const labels = period === PERIOD.MONTH
				? times(12, (index) => capitalize(day.add(index, 'month').format('MMMM')))
				: times(4, (index) => t('common.quarter', { quarter: index + 1 }))

			const sortedWasteTypes = uniqBy(reduce(collections, (result, { sorted }) => {
				const wasteTypes = map(sorted, (item) => item.wasteType)

				return [...result, ...wasteTypes]
			}, [] as WasteTypeBase[]), 'id')

			const prevSortedWasteTypes = uniqBy(reduce(collections, (result, { sorted }) => {
				const wasteTypes = map(sorted, (item) => item.wasteType)

				return [...result, ...wasteTypes]
			}, [] as WasteTypeBase[]), 'id')

			const datasets = yearComparisonType === SORTED_TYPE.UNSORTED
				? [{
					...baseOptions,
					backgroundColor: theme.colors.neutral['400'],
					maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
					stack: `${prevYear}`,
					label: `${t('DashboardPage.unsorted')} ${prevYear}`,
					data: map(prevCollections, ({ unsorted }) => type === CHART_TYPE.WEIGHT
						? unsorted.weight
						: unsorted.count)
				}, {
					...baseOptions,
					backgroundColor: theme.colors.primary['500'],
					maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
					stack: `${year}`,
					label: `${t('DashboardPage.unsorted')} ${year}`,
					data: map(collections, ({ unsorted }) => type === CHART_TYPE.WEIGHT
						? unsorted.weight
						: unsorted.count)
				}]
				: [
					...map(prevSortedWasteTypes, (wasteType) => ({
						...baseOptions,
						label: wasteType.name,
						maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
						backgroundColor: `#${wasteType.color}`,
						data: map(prevCollections, ({ sorted }) => {
							const item = find(sorted, (item) => item.wasteType.id === wasteType.id)

							return type === CHART_TYPE.WEIGHT
								? item?.weight || 0
								: item?.count || 0
						}),
						stack: `${prevYear}`
					})),
					...map(sortedWasteTypes, (wasteType) => ({
						...baseOptions,
						maxBarThickness: period === PERIOD.MONTH ? 10 : 32,
						backgroundColor: `#${wasteType.color}`,
						data: map(collections, ({ sorted }) => {
							const item = find(sorted, (item) => item.wasteType.id === wasteType.id)
							return type === CHART_TYPE.WEIGHT
								? item?.weight || 0
								: item?.count || 0
						}),
						stack: `${year}`
					}))
				]

			const data = {
				labels,
				datasets
			}

			chartRef.current?.destroy()
			const context = canvasRef.current?.getContext('2d')

			if (context) {
				chartRef.current = new Chart(context, {
					type: 'bar',
					data: data as any,
					options: {
						maintainAspectRatio: false,
						plugins: {
							datalabels: {
								display: false
							},
							tooltip: {
								callbacks: {
									title: (tooltipItems) => {
										const [item] = tooltipItems
										return `${item.label} ${item.dataset.stack}`
									},
									label: ({ formattedValue, raw }) => type === CHART_TYPE.WEIGHT
										? `${formattedValue} kg`
										: t('DashboardPage.containersCount', { count: Number(raw) })
								}
							},
							legend: {
								labels: {
									filter: (item) => !!item.text,
									usePointStyle: true
								}
							}
						}
					}
				})
			}
		}
	}, [yearsComparison, summaryState, yearComparisonSummaryState, yearComparisonType, t, type])

	useEffect(() => {
		loadSummary({ period, year, municipalityID })
	}, [period, year, municipalityID, loadSummary])

	useEffect(() => {
		if (yearsComparison) {
			loadYearComparisonSummary({ period, year: year - 1, municipalityID })
		} else {
			resetLastYearSummary()
		}
	}, [year, municipalityID, yearsComparison, loadYearComparisonSummary, period, resetLastYearSummary])

	let content

	if (summaryState.isLoading || yearComparisonSummaryState.isLoading) {
		content = <SpinLoading height={'100%'}/>
	} else if (summaryState.data) {
		content = <canvas height={'100%'} ref={canvasRef}/>
	}

	return (
		<Row gutter={[16, 16]} justify={'space-between'}>
			<Col xxl={24} xl={24} md={24} sm={24} xs={24}>
				<SubHeading>{t('DashboardPage.wasteTypesSummaryTitle')}</SubHeading>
			</Col>
			<Col xxl={12} xl={12} md={24} sm={24} xs={24}>
				<SegmentedButton
					value={type}
					onChange={setType}
					items={typeOptions}
				/>
			</Col>
			<Col xxl={12} xl={12} md={24} sm={24} xs={24}>
				<Row gutter={16} justify={'end'}>
					<Col>
						<YearStepper
							year={year}
							onChange={setYear}
							showPreviousYear={yearsComparison}
						/>
					</Col>
					<Col>
						<SegmentedButton
							value={period}
							onChange={setPeriod}
							items={periodOptions}
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<ChartWrapper>
					{content}
				</ChartWrapper>
				<Row justify={'space-between'} align={'middle'} style={{ height: 32 }}>
					<Col span={8}/>
					<Col>
						<Checkbox checked={yearsComparison} onChange={(e) => setYearsComparison(e.target.checked)}>
							{t('DashboardPage.yearComparison')}
						</Checkbox>
					</Col>
					<Col span={8} className={'mo-flex-row mo-flex-end'}>
						{yearsComparison &&
						<SegmentedButton
							value={yearComparisonType}
							onChange={setYearComparisonType}
							items={sortedOptions}
						/>}
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default React.memo(WasteTypesSummary)
