import React from 'react'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, Row } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import Modal from './Modal'

import locale from '../../resources/locale'

import { ERROR } from '../../utils/enums'
import { statusPush } from '../../actions/statusActions'
import Button from '../buttons/Button'

class ImportModal extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		createHandler: PropTypes.func.isRequired,
		wasteTypes: PropTypes.func,
		title: PropTypes.string.isRequired,
		shown: PropTypes.bool.isRequired,
		type: PropTypes.string
	}

	constructor(props) {
		super(props)

		this.state = {
			dragging: false,
			file: null
		}
	}

	onDragEnter = () => {
		this.setState({
			dragging: true
		})
	}

	onDragLeave = () => {
		this.setState({
			dragging: false
		})
	}

	onDropRejected = () => {
		const { statusPush } = this.props
		this.setState({
			dragging: false
		})
		statusPush({
			type: ERROR,
			msg: locale['common.upload.invalid.excel']
		})
	}

	onDropAccepted = async (files) => {
		if (files.length) {
			this.setState({
				...this.state,
				dragging: false,
				file: files[0]
			})
		}
	}

	removeFile = () => {
		this.setState({
			dragging: false,
			file: null
		})
	}

	cancelHandler = (e) => {
		if (e) {
			e.preventDefault()
		}

		const { dismissHandler } = this.props
		dismissHandler()
		this.removeFile()
	}

	fileItem = () => {
		const { file } = this.state

		if (!file) {
			return <><FontAwesomeIcon icon={faFileUpload}/>&nbsp; {locale['common.select.file']}
			</>
		}

		return file.name
	}

	submitImport = () => {
		const { file } = this.state

		if (!file) {
			const { statusPush } = this.props
			statusPush({
				type: ERROR,
				msg: locale['common.upload.missing.file']
			})
		} else {
			const { createHandler } = this.props
			createHandler(file)
			this.removeFile()
		}
	}

	render() {
		const { dismissHandler, shown, title } = this.props
		const { dragging } = this.state

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<div className={'modal-header'}>
					{title}
				</div>
				<div className={'modal-content'}>
					<div className="input-wrapper dropzone-wrapper" style={{ minHeight: '50px' }}>
						<Dropzone
							style={{ width: '100%', height: '50px', textAlign: 'center', padding: '15px' }}
							className={cx('dropzone file', { dragging })}
							multiple={false}
							accept={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
							onDrop={this.onDrop}
							onDropRejected={this.onDropRejected}
							onDropAccepted={this.onDropAccepted}
							onDragEnter={this.onDragEnter}
							onDragLeave={this.onDragLeave}
						>
							{this.fileItem()}
						</Dropzone>
					</div>
				</div>
				<div className={'modal-footer clearfix'}>
					<Row gutter={16} style={{ marginTop: 20 }}>
						<Col>
							<Button
								className={'secondary'}
								onClick={this.cancelHandler}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={this.submitImport}
							>
								{locale['page.municipalities.detail.import.button']}
							</Button>
						</Col>
					</Row>
				</div>
			</Modal>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	statusPush: bindActionCreators(statusPush, dispatch)
})

export default connect(null, mapDispatchToProps)(ImportModal)
