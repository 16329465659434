import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { AntInputNumber, Info, InputWrapper, Label } from '../components/form/Styled'
import { MAX_NUMBER, MIN_NUMBER } from '../utils/enums'
import { transformNumberFieldValue } from '../utils/helpers'

const NumericInputField = ({ input, meta, showLabel, placeholder, label, min, max, step, precision, distinguishNaNOnBlur = false }) => {
	const [focused, setFocused] = useState(!!input.value)

	const error = meta.touched && meta.error
	const info = meta.error && focused

	const onFocus = async (e) => {
		if (input.onFocus) {
			setFocused(true)
			const val = transformNumberFieldValue(e.target.value)
			input.onFocus(val)
		}
	}

	const onBlur = (e) => {
		setFocused(false)
		const val = transformNumberFieldValue(e.target.value, Number(min), Number(max), precision, undefined, distinguishNaNOnBlur)
		input.onBlur(val)
	}

	const componentClass = cx({ error, info })

	return (
		<InputWrapper className={componentClass}>
			{showLabel && <Label>{label}</Label>}
			<AntInputNumber
				{...input}
				min={min}
				max={max}
				value={input.value}
				placeholder={placeholder}
				precision={precision}
				step={step}
				type={'text'}
				onFocus={onFocus}
				decimalSeparator=','
				// NOTE: Prevent proti posielaniu BLUR akcie so string payloadom - posiela Ant na pozadi
				onBlur={onBlur}
				onChange={input.onChange}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

NumericInputField.propTypes = {
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	placeholder: PropTypes.string
}

NumericInputField.defaultProps = {
	showLabel: true,
	min: MIN_NUMBER,
	max: MAX_NUMBER,
	input: {
		value: null
	}
}

export default NumericInputField
