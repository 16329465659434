import React from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ToastMessage from '../components/ToasMessage'
import Loading from '../components/Loading'

import BaseRoute from './BaseRoute'
import AuthRoute from './AuthRoute'

import Administration from './Administration'
import Home from './Home'
import Statistics from './Statistics'
import Registry from './Resgitry'
import Lists from './Lists'
import Municipality from './Municipality'

import UserPage from '../pages/users/UserPage'

const LoginPage = React.lazy(() => import('../pages/authentication/LoginPage'))
const ForgotPasswordPage = React.lazy(() => import('../pages/authentication/ForgotPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('../pages/authentication/ResetPasswordPage'))
const CompleteRegistrationPage = React.lazy(() => import('../pages/authentication/CompleteRegistrationPage'))

const Routes = () => {
	const [t] = useTranslation()

	return (
		<div id="body" style={{ height: '100%' }}>
			<ToastMessage/>
			<Loading/>
			<Switch>
				{/* Authorization Routes */}
				<BaseRoute
					exact
					path={t('paths:AUTHENTICATION.login.path')}
					component={LoginPage}
				/>

				<BaseRoute
					exact
					path={t('paths:AUTHENTICATION.forgotPassword.path')}
					component={ForgotPasswordPage}
				/>

				<BaseRoute
					exact
					path={t('paths:AUTHENTICATION.resetPassword.path')}
					component={ResetPasswordPage}
				/>

				<BaseRoute
					exact
					path={t('paths:AUTHENTICATION.completeRegistration.path')}
					component={CompleteRegistrationPage}
				/>

				<AuthRoute
					exact
					path={t('paths:PROFILE.path')}
					menuItem={'PROFILE'}
					component={UserPage}
				/>

				<Route
					path={t('paths:ADMINISTRATION.path')}
					component={Administration}
				/>

				<Route
					path={t('paths:STATISTICS.path')}
					component={Statistics}
				/>

				<Route
					path={t('paths:REGISTRY.path')}
					component={Registry}
				/>

				<Route
					path={t('paths:LISTS.path')}
					component={Lists}
				/>

				<Route
					path={t('paths:MUNICIPALITY.path', { municipalityID: ':municipalityID' })}
					component={Municipality}
				/>

				<Route
					path={t('paths:HOME.path')}
					component={Home}
				/>
			</Switch>
		</div>
	)
}

export default withRouter(Routes)
