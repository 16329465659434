import locale from '../resources/locale'
import {
	PERMISSIONS,
	MUNICIPALITY_TYPE,
	MOTIVATION_MODEL,
	REFERENCE_PERIOD,
	WASTE_TYPE,
	COLORS,
	COLORS_HEX,
	COLLECTION_PLACE_TYPE,
	CONTAINER_TYPES,
	EVENT_TYPE,
	UNSUITABLE_CONDITION,
	STATISTICS_PERIODS,
	DUPLICITY_HANDLE, DUPLICITY_OVERRIDE_INTERVAL
} from './enums'

export const customStyles = {
	container: (base, { isFocused }) => ({
		...base,
		borderRadius: '2px',
		border: isFocused ? '1px solid #AFCA0B' : '1px solid #CCCCCC'
	}),
	control: (base) => ({
		...base,
		border: 'none',
		boxShadow: 'none',
		minHeight: '32px',
		backgroundColor: 'white'
	}),
	valueContainer: (base) => ({
		...base,
		padding: '0px 8px'
	}),
	indicatorsContainer: (base) => ({
		...base,
		minHeight: '32px'
	}),
	option: (base, { isSelected, isFocused }) => ({
		...base,
		minHeight: '32px',
		borderTop: '1px solid #E7E7E7E',
		margin: 0,
		':hover': {
			color: 'white',
			backgroundColor: '#AFCA0B'
		},
		// eslint-disable-next-line no-nested-ternary
		color: isFocused && isSelected ? 'white' : isSelected ? 'white' : 'black',
		// eslint-disable-next-line no-nested-ternary
		backgroundColor: isFocused ? '#AFCA0B' : isSelected ? '#647f0b' : 'white'
	}),
	placeholder: (base) => ({
		...base,
		color: '#919191'
	}),
	singleValue: (base) => ({
		...base,
		color: 'black'
	}),
	input: (base) => ({
		...base
	}),
	menu: (base) => ({
		...base,
		marginTop: '0',
		'z-index': '11',
		borderRadius: '0',
		boxShadow: 'none',
		border: '1px solid #AFCA0B',
		borderTop: 'none',
		marginLeft: '-1px',
		width: 'calc(100% + 2px)'
	}),
	menuList: (base) => ({
		...base,
		marginTop: '0',
		'z-index': '11',
		borderRadius: '0',
		padding: '0'
	})
}

export const permissions = [{
	value: PERMISSIONS.ADMINISTRATOR,
	label: locale[`role.${PERMISSIONS.ADMINISTRATOR}`],
	key: PERMISSIONS.ADMINISTRATOR
}, {
	value: PERMISSIONS.MUNICIPALITY_ADMIN,
	label: locale[`role.${PERMISSIONS.MUNICIPALITY_ADMIN}`],
	key: PERMISSIONS.MUNICIPALITY_ADMIN
}, {
	value: PERMISSIONS.COLLECTION_WORKER,
	label: locale[`role.${PERMISSIONS.COLLECTION_WORKER}`],
	key: PERMISSIONS.COLLECTION_WORKER
}, {
	value: PERMISSIONS.COLLECTION_YARD_WORKER,
	label: locale[`role.${PERMISSIONS.COLLECTION_YARD_WORKER}`],
	key: PERMISSIONS.COLLECTION_YARD_WORKER
}, {
	value: PERMISSIONS.MUNICIPALITY_DUSTMAN,
	label: locale[`role.${PERMISSIONS.MUNICIPALITY_DUSTMAN}`],
	key: PERMISSIONS.MUNICIPALITY_DUSTMAN
}]

export const municipalityTypes = [{
	value: MUNICIPALITY_TYPE.CITY,
	label: locale[`municipality.type.${MUNICIPALITY_TYPE.CITY}`],
	key: MUNICIPALITY_TYPE.CITY
}, {
	value: MUNICIPALITY_TYPE.CITY_PART,
	label: locale[`municipality.type.${MUNICIPALITY_TYPE.CITY_PART}`],
	key: MUNICIPALITY_TYPE.CITY_PART
}, {
	value: MUNICIPALITY_TYPE.REGION,
	label: locale[`municipality.type.${MUNICIPALITY_TYPE.REGION}`],
	key: MUNICIPALITY_TYPE.REGION
}, {
	value: MUNICIPALITY_TYPE.WASTE_COMPANY,
	label: locale[`municipality.type.${MUNICIPALITY_TYPE.WASTE_COMPANY}`],
	key: MUNICIPALITY_TYPE.WASTE_COMPANY
}]

export const duplicityHandles = [{
	value: DUPLICITY_HANDLE.OVERRIDE,
	label: 'Prepísať starý záznam',
	key: DUPLICITY_HANDLE.OVERRIDE
}, {
	value: DUPLICITY_HANDLE.IGNORE,
	label: 'Ignorovať nový záznam',
	key: DUPLICITY_HANDLE.IGNORE
}, {
	value: DUPLICITY_HANDLE.PROCESS,
	label: 'Spracovať záznam',
	key: DUPLICITY_HANDLE.PROCESS
}]

export const duplicityOverrideIntervals = [{
	value: DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS,
	label: 'V rámci 2 hodín',
	key: DUPLICITY_OVERRIDE_INTERVAL.TWO_HOURS
}, {
	value: DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS,
	label: 'V rámci 4 hodín',
	key: DUPLICITY_OVERRIDE_INTERVAL.FOUR_HOURS
}, {
	value: DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY,
	label: 'V rámci jednéh dňa',
	key: DUPLICITY_OVERRIDE_INTERVAL.SAME_DAY
}]

export const motivationModels = [{
	value: MOTIVATION_MODEL.DISCOUNT,
	label: locale[`motivation.model.${MOTIVATION_MODEL.DISCOUNT}`],
	key: MOTIVATION_MODEL.DISCOUNT
}, {
	value: MOTIVATION_MODEL.FEE,
	label: locale[`motivation.model.${MOTIVATION_MODEL.FEE}`],
	key: MOTIVATION_MODEL.FEE
	// Don't remove commented motivation models!!!
// },
// {
// 	value: MOTIVATION_MODEL.COMBINED_FEE,
// 	label: locale[`motivation.model.${MOTIVATION_MODEL.COMBINED_FEE}`],
// 	key: MOTIVATION_MODEL.COMBINED_FEE
// }, {
// 	value: MOTIVATION_MODEL.QUANTITY,
// 	label: locale[`motivation.model.${MOTIVATION_MODEL.QUANTITY}`],
// 	key: MOTIVATION_MODEL.QUANTITY
// }, {
// 	value: MOTIVATION_MODEL.NONE,
// 	label: locale[`motivation.model.${MOTIVATION_MODEL.NONE}`],
// 	key: MOTIVATION_MODEL.NONE
}]

export const referencePeriods = [{
	value: REFERENCE_PERIOD.MONTHLY,
	label: locale[`reference.period.${REFERENCE_PERIOD.MONTHLY}`],
	key: REFERENCE_PERIOD.MONTHLY
}, {
	value: REFERENCE_PERIOD.QUARTERLY,
	label: locale[`reference.period.${REFERENCE_PERIOD.QUARTERLY}`],
	key: REFERENCE_PERIOD.QUARTERLY
}, {
	value: REFERENCE_PERIOD.HALFYEARLY,
	label: locale[`reference.period.${REFERENCE_PERIOD.HALFYEARLY}`],
	key: REFERENCE_PERIOD.HALFYEARLY
}, {
	value: REFERENCE_PERIOD.YEARLY,
	label: locale[`reference.period.${REFERENCE_PERIOD.YEARLY}`],
	key: REFERENCE_PERIOD.YEARLY
}]

export const wasteTypes = [{
	value: WASTE_TYPE.COLLECTION_PLACE,
	label: locale[`waste.type.enum.${WASTE_TYPE.COLLECTION_PLACE}`],
	key: WASTE_TYPE.COLLECTION_PLACE
}, {
	value: WASTE_TYPE.COLLECTION_YARD,
	label: locale[`waste.type.enum.${WASTE_TYPE.COLLECTION_YARD}`],
	key: WASTE_TYPE.COLLECTION_YARD
}, {
	value: WASTE_TYPE.DOOR_TO_DOOR,
	label: locale[`waste.type.enum.${WASTE_TYPE.DOOR_TO_DOOR}`],
	key: WASTE_TYPE.DOOR_TO_DOOR
}]

export const colors = [{
	value: COLORS_HEX.BLUE,
	label: locale[`colors.${COLORS.BLUE}`],
	key: COLORS.BLUE
}, {
	value: COLORS_HEX.BLACK,
	label: locale[`colors.${COLORS.BLACK}`],
	key: COLORS.BLACK
}, {
	value: COLORS_HEX.GREEN,
	label: locale[`colors.${COLORS.GREEN}`],
	key: COLORS.GREEN
}, {
	value: COLORS_HEX.RED,
	label: locale[`colors.${COLORS.RED}`],
	key: COLORS.RED
}, {
	value: COLORS_HEX.YELLOW,
	label: locale[`colors.${COLORS.YELLOW}`],
	key: COLORS.YELLOW
}, {
	value: COLORS_HEX.BROWN,
	label: locale[`colors.${COLORS.BROWN}`],
	key: COLORS.BROWN
}, {
	value: COLORS_HEX.GREY,
	label: locale[`colors.${COLORS.GREY}`],
	key: COLORS.GREY
}, {
	value: COLORS_HEX.ORANGE,
	label: locale[`colors.${COLORS.ORANGE}`],
	key: COLORS.ORANGE
}]

export const collectionPlacesTypes = [{
	value: COLLECTION_PLACE_TYPE.FAMILY_HOUSE,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.FAMILY_HOUSE}`],
	key: COLLECTION_PLACE_TYPE.FAMILY_HOUSE
}, {
	value: COLLECTION_PLACE_TYPE.FLAT_HOUSE,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.FLAT_HOUSE}`],
	key: COLLECTION_PLACE_TYPE.FLAT_HOUSE
}, {
	value: COLLECTION_PLACE_TYPE.FLAT,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.FLAT}`],
	key: COLLECTION_PLACE_TYPE.FLAT
}, {
	value: COLLECTION_PLACE_TYPE.COLLECTION_YARD,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.COLLECTION_YARD}`],
	key: COLLECTION_PLACE_TYPE.COLLECTION_YARD
}, {
	value: COLLECTION_PLACE_TYPE.NEST,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.NEST}`],
	key: COLLECTION_PLACE_TYPE.NEST
}, {
	value: COLLECTION_PLACE_TYPE.OTHER,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.OTHER}`],
	key: COLLECTION_PLACE_TYPE.OTHER
}, {
	value: COLLECTION_PLACE_TYPE.COTTAGE,
	label: locale[`collectionPlace.type.${COLLECTION_PLACE_TYPE.COTTAGE}`],
	key: COLLECTION_PLACE_TYPE.COTTAGE
}]

export const containerTypes = [{
	value: CONTAINER_TYPES.PERMANENT,
	key: CONTAINER_TYPES.PERMANENT,
	label: locale[`container.types.${CONTAINER_TYPES.PERMANENT}`]
}, {
	value: CONTAINER_TYPES.ONE_TIME,
	key: CONTAINER_TYPES.ONE_TIME,
	label: locale[`container.types.${CONTAINER_TYPES.ONE_TIME}`]
}, {
	value: CONTAINER_TYPES.COLLECTION_YARD,
	key: CONTAINER_TYPES.COLLECTION_YARD,
	label: locale[`container.types.${CONTAINER_TYPES.COLLECTION_YARD}`]
}]

export const eventTypes = [{
	value: EVENT_TYPE.COLLECTION_ROUND,
	key: EVENT_TYPE.COLLECTION_ROUND,
	label: locale[`event.types.${EVENT_TYPE.COLLECTION_ROUND}`]
}, {
	value: EVENT_TYPE.OTHER,
	key: EVENT_TYPE.OTHER,
	label: locale[`event.types.${EVENT_TYPE.OTHER}`]
}]

export const unsuitableConditions = [{
	value: UNSUITABLE_CONDITION.CONTAMINATED,
	key: UNSUITABLE_CONDITION.CONTAMINATED,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.CONTAMINATED}`]
}, {
	value: UNSUITABLE_CONDITION.INCORRECT_MATERIAL,
	key: UNSUITABLE_CONDITION.INCORRECT_MATERIAL,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.INCORRECT_MATERIAL}`]
}, {
	value: UNSUITABLE_CONDITION.UNCOMPRESSED,
	key: UNSUITABLE_CONDITION.UNCOMPRESSED,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.UNCOMPRESSED}`]
}, {
	value: UNSUITABLE_CONDITION.DAMAGED_BIN,
	key: UNSUITABLE_CONDITION.DAMAGED_BIN,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.DAMAGED_BIN}`]
}, {
	value: UNSUITABLE_CONDITION.MARKER100,
	key: UNSUITABLE_CONDITION.MARKER100,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.MARKER100}`]
}, {
	value: UNSUITABLE_CONDITION.MARKER200,
	key: UNSUITABLE_CONDITION.MARKER200,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.MARKER200}`]
}, {
	value: UNSUITABLE_CONDITION.MARKER300,
	key: UNSUITABLE_CONDITION.MARKER300,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.MARKER300}`]
}, {
	value: UNSUITABLE_CONDITION.MARKER400,
	key: UNSUITABLE_CONDITION.MARKER400,
	label: locale[`unsuitable.conditions.${UNSUITABLE_CONDITION.MARKER400}`]
}]

export const statisticsPeriods = [{
	value: STATISTICS_PERIODS.MONTH,
	label: locale[`statistics.period.${STATISTICS_PERIODS.MONTH}`],
	key: STATISTICS_PERIODS.MONTH
}, {
	value: STATISTICS_PERIODS.QUARTER,
	label: locale[`statistics.period.${STATISTICS_PERIODS.QUARTER}`],
	key: STATISTICS_PERIODS.QUARTER
}, {
	value: STATISTICS_PERIODS.HALF_YEAR,
	label: locale[`statistics.period.${STATISTICS_PERIODS.HALF_YEAR}`],
	key: STATISTICS_PERIODS.HALF_YEAR
}, {
	value: STATISTICS_PERIODS.YEAR,
	label: locale[`statistics.period.${STATISTICS_PERIODS.YEAR}`],
	key: STATISTICS_PERIODS.YEAR
}]
