import { createSelector } from 'reselect'
import { get, map } from 'lodash'

export const getCollectionRoundsList = (state) => state.collectionRounds.list
export const getCollectionRoundDetail = (state) => state.collectionRounds.detail
export const getCollectionRoundMap = (state) => state.collectionRounds.map
export const getCollectionRoundOverview = (state) => state.collectionRounds.overview

export const getCollectionRoundsSelectOptions = createSelector([getCollectionRoundsList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.collectionRounds'), (item) => ({
		value: item.id,
		label: item.name
	}))
}))
