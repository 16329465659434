import { map } from 'lodash'
import locale from '../../resources/locale'
import { COLLECTION_PLACE_TYPE, FILTER_TYPE
} from '../../utils/enums'
import { QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { formatAddress } from '../../utils/utils'

export const queryParams = {
	order: 'order',
	direction: 'direction',
	page: 'page',
	limit: 'limit',
	columns: 'columns',

	search: 'search',
	nameSearch: 'nameSearch',
	types: 'types',
	addressSearch: 'addressSearch',
	customerSearch: 'customerSearch',

	containersFrom: 'containersFrom',
	containersTo: 'containersTo',

	streetSearch: 'streetSearch',
	numberSearch: 'numberSearch',
	streetNumberSearch: 'streetNumberSearch',
	parentSearch: 'parentSearch',
	flatNumberSearch: 'flatNumberSearch',
	municipalitySearch: 'municipalitySearch'
}

export const columns = [
	{
		name: 'name',
		sortable: true,
		title: 'Meno',
		filter: {
			name: 'nameSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'type',
		sortable: true,
		title: 'Typ',
		dataNames: ['type'],
		render: ({ type }) => locale[`collectionPlace.type.${type}`],
		filter: {
			name: 'types',
			type: FILTER_TYPE.SELECT,
			values: map(Object.values(COLLECTION_PLACE_TYPE), (state) => ({
				value: state,
				label: locale[`collectionPlace.type.${state}`]
			})),
			isLoading: false
		}
	}, {
		name: 'address',
		sortable: true,
		title: 'Adresa',
		dataNames: ['street', 'city', 'number', 'streetNumber', 'flatNumber'],
		render: (item) => formatAddress(item),
		filter: {
			name: 'addressSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'customer.fullName',
		sortable: true,
		title: 'Platca',
		filter: {
			name: 'customerSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'containersCount',
		sortable: true,
		title: 'Počet zberných nádob',
		dataNames: ['containersCount'],
		render: ({ containersCount }) => containersCount,
		filter: {
			name: 'containersCount',
			type: FILTER_TYPE.NUMBER_RANGE
		}
	}, {
		name: 'gps',
		sortable: false,
		title: 'GPS poloha',
		dataNames: ['lat', 'lon'],
		render: ({ lat, lon }) => lat && lon ? `https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=16` : '-'
	}, {
		name: 'street',
		sortable: true,
		title: 'Ulica',
		filter: {
			name: 'streetSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'number',
		sortable: true,
		title: 'Súpisné číslo',
		filter: {
			name: 'numberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'streetNumber',
		sortable: true,
		title: 'Orientačné číslo',
		filter: {
			name: 'streetNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'parent.name',
		sortable: true,
		title: 'Bytový dom',
		filter: {
			name: 'parentSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'flatNumber',
		sortable: true,
		title: 'Číslo bytu',
		filter: {
			name: 'flatNumberSearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}, {
		name: 'municipality.name',
		sortable: true,
		title: 'Obec',
		filter: {
			name: 'municipalitySearch',
			type: FILTER_TYPE.FULL_TEXT
		}
	}
]

export const defaultColumns = [columns[0].name, columns[1].name, columns[2].name, columns[3].name, columns[4].name]

export const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: 'name',
		allowedValues: ['name', 'type', 'address', 'customer.fullName', 'street', 'number', 'streetNumber', 'flatNumber', 'parent.name', 'municipality.name', 'containersCount']
	},
	direction: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: 'ASC',
		allowedValues: ['ASC', 'DESC', 'asc', 'desc']
	},
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	columns: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: defaultColumns
	},
	nameSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	types: {
		type: QUERY_PARAMS_TYPES.ARRAY,
		defaultValue: null
	},
	containersCountFrom: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	containersCountTo: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: null
	},
	addressSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	customerSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	streetSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	numberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	streetNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	flatNumberSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	parentSearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	municipalitySearch: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	}
}
