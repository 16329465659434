import React from 'react'
import * as PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

export const StatisticsNoDataPlaceholder = ({ title }) => (
	<div className="flex-box vertical empty" style={{ color: '#c2c2c2' }}>
		<div style={{ fontSize: '100px' }}>
			<FontAwesomeIcon icon={faInfoCircle}/>
		</div>
		<h2>{title || locale['page.statistics.no.data']}</h2>
	</div>
)

StatisticsNoDataPlaceholder.propTypes = {
	title: PropTypes.string
}
