import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset, change, initialize, getFormValues, isDirty } from 'redux-form'
import { get, map } from 'lodash'
import dayjs from 'dayjs'
import { statusPush } from '../../actions/statusActions'

import locale from '../../resources/locale'

import Breadcrumbs from '../../components/Breadcrumb'
import CollectionPlaceCreateForm from '../../components/collectionPlaces/CollectionPlaceCreateForm'
import CreateCustomerModal from '../../components/modals/CreateCustomer'

import {
	COLLECTION_PLACE_TYPE, CUSTOMER_LEGAL_FORM, ENDPOINTS, FORMS, INFO, MODAL
} from '../../utils/enums'

import { saveFormData, removeSavedData } from '../../actions/formActions'
import { postReq } from '../../utils/request'
import { history } from '../../utils/history'
import { formatAddress } from '../../utils/utils'
import { formatCustomer } from '../../utils/customer'

class CollectionPlaceCreatePage extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		formData: PropTypes.object,
		isDirty: PropTypes.bool.isRequired,
		formValues: PropTypes.object,
		saveFormData: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		removeSavedData: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			modal: null
		}
	}

	componentDidMount() {
		const { municipality, initialize, reset, formData, isDirty } = this.props

		if (!isDirty) {
			const initialValues = formData || {
				legalForm: CUSTOMER_LEGAL_FORM.INDIVIDUAL,
				zip: get(municipality, 'zip'),
				city: get(municipality, 'name'),
				isComposter: false
			}

			initialize(FORMS.CREATE_COLLECTION_PLACE, initialValues)
			reset(FORMS.CREATE_COLLECTION_PLACE)
		}

		this.unlisten = history.listen(this.saveFormIfNeeded)
	}

	componentWillUnmount() {
		this.unlisten()
	}

	saveFormIfNeeded = () => {
		const { isDirty, formValues, municipality, saveFormData } = this.props
		if (isDirty) {
			saveFormData(FORMS.CREATE_COLLECTION_PLACE, formValues, municipality)
		}
	}

	collectionPlaceName = (place) => {
		if (place.type === COLLECTION_PLACE_TYPE.COLLECTION_YARD || place.type === COLLECTION_PLACE_TYPE.NEST || place.type === COLLECTION_PLACE_TYPE.OTHER || place.type === COLLECTION_PLACE_TYPE.FLAT_HOUSE) {
			return place.name
		}
		return formatAddress(place)
	}

	saveCollectionPlace = async (values) => {
		const data = {
			name: this.collectionPlaceName(values),
			type: get(values, 'type', null),
			street: get(values, 'street', null),
			number: get(values, 'number', null),
			streetNumber: get(values, 'streetNumber', null),
			parentID: get(values, 'parent.value', null) || null,
			flatNumber: values.type === COLLECTION_PLACE_TYPE.FLAT ? values.flatNumber : null,
			city: get(values, 'city', null),
			zip: get(values, 'zip', null),
			description: values.description,
			lat: values.lat,
			lon: values.lon,
			customerID: get(values, 'customer.value', null),
			membersCount: values.type !== COLLECTION_PLACE_TYPE.COTTAGE ? get(values, 'membersCount', 0) : 1,
			isComposter: values.isComposter,
			containers: map(values.containers, (item) => ({
				containerTypeID: get(item, 'containerType.value'),
				assignedFrom: dayjs(item.assignedFrom).toISOString(),
				assignedTo: item.assignedTo ? dayjs(item.assignedTo).toISOString() : null,
				code: item.code
			}))
		}

		const { municipality, pushStatus, removeSavedData } = this.props
		try {
			await postReq(ENDPOINTS.MUNICIPALITY_COLLECTION_PLACES(municipality.id), null, data)
			history.push(locale.formatString(locale['path.municipality.collections.places'], municipality.id))
			removeSavedData(FORMS.CREATE_COLLECTION_PLACE, municipality)
			pushStatus({
				type: INFO,
				msg: locale['page.collectionPlaces.create.success']
			})
		} catch (error) {
			// Error
		}
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		return {
			items: [{
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			}, {
				key: 'page.municipalities.detail',
				name: municipality.name
			}, {
				key: 'page.lists.breadcrumbs',
				name: locale['page.lists.breadcrumbs']
			}, {
				key: 'page.collectionPlaces.breadcrumbs',
				name: locale['page.collectionPlaces.breadcrumbs'],
				link: locale.formatString(locale['path.municipality.collections.places'], municipality.id)
			}, {
				key: 'page.collectionPlaces.create.breadcrumbs',
				name: locale['page.collectionPlaces.create.breadcrumbs']
			}]
		}
	}

	createCustomer = async (values) => {
		const { pushStatus, municipality, reset, change } = this.props
		try {
			const body = {
				legalForm: values.legalForm,
				name: values.name,
				surname: values.surname,
				titleBefore: values.titleBefore,
				titleAfter: values.titleAfter,
				companyName: values.companyName,
				city: values.city,
				zip: values.zip,
				ico: values.ico,
				street: values.street,
				streetNumber: values.streetNumber,
				number: values.number,
				activeFrom: values.activeFrom,
				activeTo: values.activeTo
			}
			const response = await postReq(ENDPOINTS.MUNICIPALITY_CUSTOMERS(municipality.id), null, body)

			pushStatus({
				type: INFO,
				msg: locale['page.customers.create.success']
			})
			this.dismissModal()
			const value = {
				key: response.data.id,
				value: response.data.id,
				label: formatCustomer(response.data),
				item: response.data
			}

			change(FORMS.CREATE_COLLECTION_PLACE, 'customer', value)
			reset(FORMS.CREATE_CUSTOMER_FORM)
		} catch (e) {
			// Error
		}
	}

	getInitialValues = () => {
		const { municipality } = this.props
		return {
			zip: municipality.zip,
			city: municipality.name
		}
	}

	returnToList = () => {
		const { municipality } = this.props
		history.push(locale.formatString(locale['path.municipality.collections.places'], municipality.id))
	}

	onSelectCustomerAddress = (value) => {
		const { change } = this.props

		change(FORMS.CREATE_CUSTOMER_FORM, 'street', value.street)
		change(FORMS.CREATE_CUSTOMER_FORM, 'zip', value.zip)
		change(FORMS.CREATE_CUSTOMER_FORM, 'city', value.city)
	}

	render() {
		const { modal } = this.state
		const { municipality } = this.props

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems()}
				/>
				<CollectionPlaceCreateForm
					initialValues={this.getInitialValues()}
					submitHandler={this.saveCollectionPlace}
					cancelHandler={this.returnToList}
					customerCreateHandler={this.openModal(MODAL.CREATE_CUSTOMER)}
				/>
				<CreateCustomerModal
					dismissHandler={this.dismissModal}
					createHandler={this.createCustomer}
					shown={modal === MODAL.CREATE_CUSTOMER}
					municipalityID={municipality.id}
					onSelectSuggestion={this.onSelectCustomerAddress}
				/>
			</>
		)
	}
}

const formValues = getFormValues(FORMS.CREATE_COLLECTION_PLACE)
const dirty = isDirty(FORMS.CREATE_COLLECTION_PLACE)

const mapStateToProps = (state) => ({
	formValues: formValues(state),
	isDirty: dirty(state),
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	formData: get(state, `savedForms.municipalities.${`${get(state, 'selectedMunicipality.municipality.data.id')}`}.${FORMS.CREATE_COLLECTION_PLACE}`)
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	reset: bindActionCreators(reset, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	change: bindActionCreators(change, dispatch),
	saveFormData: bindActionCreators(saveFormData, dispatch),
	removeSavedData: bindActionCreators(removeSavedData, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPlaceCreatePage)
