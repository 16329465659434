import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Col, Row, Table, TablePaginationConfig } from 'antd'
import { useTranslation } from 'react-i18next'
import { join } from 'lodash'

import { SearchOutlined } from '@ant-design/icons'

import { BulkCollectionCustomer, OrderDirections } from '../../../types/data'

import { filtersActions, FiltersState, getBulkCollectionsCustomersFilters } from '../../../redux/filters'

import { bulkCollections } from '../../../api'

import { createYearsList, getOrderFromSorter } from '../../../utils/helpers'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SearchFilter from '../../../components/TableFilters/SearchFilter'
import { SubHeading } from '../../../components/Typography'
import { InputWrapper, Label, Select } from '../../../components/form/Styled'
import { history } from '../../../utils/history'

type Props = {
	municipalityID?: string
}

const years = createYearsList()

const CustomersSummary = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const dispatch = useDispatch()
	const customersFilters = useSelector(getBulkCollectionsCustomersFilters)

	const [summaryState, loadSummary] = useDataLoader(bulkCollections.loadCustomersSummary)

	const [visibleFilter, setVisibleFilter] = useState<string | null>('')

	const periodOptions = useMemo(() => years.map((year) => ({
		value: year, label: `${year}`
	})), [])

	const filterSummary = useCallback((filters: object) => {
		loadSummary({ ...filters, municipalityID })

		dispatch(filtersActions.saveFilters({ key: 'bulkCollectionsCustomersSummary', filters }))
	}, [dispatch, loadSummary, municipalityID])

	const handleFilter = useCallback((name: keyof FiltersState['bulkCollectionsCustomersSummary'], value: any) => {
		const filters = {
			...customersFilters,
			[name]: value
		}

		filterSummary(filters)
		setVisibleFilter(null)
	}, [customersFilters, filterSummary])

	const onRow = useCallback((model) => ({
		onClick: () => history.push(municipalityID
			? t('paths:LISTS.customers.detail.municipalityPath', { municipalityID, customerID: model.id })
			: t('paths:LISTS.customers.detail.path', { customerID: model.id }))
	}), [municipalityID, t])

	const handleChangeTable = useCallback((pagination: TablePaginationConfig, tableFilters: Record<string, FilterValue | null>, sorter: SorterResult<BulkCollectionCustomer> | SorterResult<BulkCollectionCustomer>[]) => {
		const [order, direction] = getOrderFromSorter(sorter)

		const filters = {
			direction,
			nameSearch: customersFilters.nameSearch,
			codeSearch: customersFilters.codeSearch,
			year: customersFilters.year,
			order: order || 'name',
			current: pagination.current,
			pageSize: pagination.pageSize
		}

		filterSummary(filters)
	}, [customersFilters.codeSearch, customersFilters.nameSearch, customersFilters.year, filterSummary])

	const pagination = useMemo(() => ({
		current: customersFilters.current,
		total: summaryState.data?.context.totalCount,
		pageSize: customersFilters.pageSize,
		showSizeChanger: false
	}), [customersFilters, summaryState.data?.context.totalCount])

	const locale = useMemo(() => ({
		emptyText: summaryState.isLoading ? '' : t('common.noData')
	}), [summaryState.isLoading, t])

	const columns = useMemo(() => [{
		key: 'name',
		dataIndex: 'name',
		title: t('table.columns.name'),
		sorter: true,
		showSorterTooltip: false,
		ellipsis: true,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: customersFilters.order === 'name' ? customersFilters.direction : null,
		filterDropdownVisible: visibleFilter === 'nameSearch',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'nameSearch' : null),
		filteredValue: customersFilters.nameSearch ? [customersFilters.nameSearch] : [],
		filterIcon: <SearchOutlined style={{ fontSize: 16 }}/>,
		filterDropdown: (
			<SearchFilter
				filtersName={'bulkCollectionsCustomersSummary'}
				name={'nameSearch'}
				onFilter={handleFilter}
			/>
		),
		render: (value: string) => <strong>{value}</strong>
	}, {
		key: 'visitCount',
		width: '14%',
		dataIndex: 'visitCount',
		title: t('table.columns.visitCount'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: customersFilters.order === 'visitCount' ? customersFilters.direction : null
	}, {
		key: 'totalWeight',
		dataIndex: 'totalWeight',
		width: '15%',
		title: t('table.columns.totalWeight'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: customersFilters.order === 'totalWeight' ? customersFilters.direction : null,
		render: (totalWeight: string) => `${Number(totalWeight).toFixed(2)} kg`
	}, {
		key: 'unsortedWeight',
		width: '15%',
		dataIndex: 'unsortedWeight',
		title: t('table.columns.unsortedWeight'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: customersFilters.order === 'unsortedWeight' ? customersFilters.direction : null,
		render: (totalWeight: string) => `${Number(totalWeight).toFixed(2)} kg`
	}, {
		key: 'sortedWeight',
		width: '15%',
		dataIndex: 'sortedWeight',
		title: t('table.columns.sortedWeight'),
		sorter: true,
		showSorterTooltip: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as OrderDirections,
		sortOrder: customersFilters.order === 'sortedWeight' ? customersFilters.direction : null,
		render: (totalWeight: string) => `${Number(totalWeight).toFixed(2)} kg`
	}, {
		key: 'customerCodes',
		width: '15%',
		dataIndex: 'customerCodes',
		title: t('table.columns.customerCodes'),
		render: (customerCodes: string) => join(customerCodes, ', ') || '-',
		filterDropdownVisible: visibleFilter === 'codeSearch',
		onFilterDropdownVisibleChange: (visible: boolean) => setVisibleFilter(visible ? 'codeSearch' : null),
		filteredValue: customersFilters.codeSearch ? [customersFilters.codeSearch] : [],
		filterIcon: <SearchOutlined style={{ fontSize: 16 }}/>,
		filterDropdown: (
			<SearchFilter
				filtersName={'bulkCollectionsCustomersSummary'}
				name={'codeSearch'}
				onFilter={handleFilter}
			/>
		)
	}], [customersFilters.codeSearch, customersFilters.direction, customersFilters.nameSearch, customersFilters.order, handleFilter, t, visibleFilter])

	useEffect(() => {
		loadSummary({ ...customersFilters, municipalityID })
		// eslint-disable-next-line
	}, [municipalityID])

	return (
		<Row justify={'space-between'} gutter={[16, 16]}>
			<Col span={24}>
				<SubHeading>{t('BulkCollectionsPage.customersSummaryTitle')}</SubHeading>
			</Col>
			<Col style={{ width: 200 }}>
				<InputWrapper className={'without-margin'}>
					<Label>{t('BulkCollectionsPage.period')}</Label>
					<Select
						value={customersFilters.year}
						onChange={(value) => handleFilter('year', value)}
						options={periodOptions}
						allowClear={false}
					/>
				</InputWrapper>
			</Col>
			<Col span={24}>
				<Table
					columns={columns}
					rowKey={'id'}
					locale={locale}
					onRow={onRow}
					onChange={handleChangeTable}
					loading={summaryState.isLoading}
					dataSource={summaryState.data?.customers}
					pagination={pagination}
					scroll={{ x: 1150 }}
				/>
			</Col>
		</Row>
	)
}

export default React.memo(CustomersSummary)
