export default {
	'document.title': 'Moderné odpady',

	'path.index': '/',
	'path.login': '/prihlasenie',
	'path.profile': '/profil',
	'path.forgot': '/zabudnute-heslo',
	'path.reset': '/obnovit-heslo',
	'path.complete': '/dokoncenie-registracie',

	'path.municipality.index': '/obce/{0}',

	'path.stats.municipality': '/statistiky/obce',
	'path.municipality.stats.municipality': '/obce/{0}/statistiky/obce',

	'path.stats.households': '/statistiky/domacnosti',
	'path.municipality.stats.households': '/obce/{0}/statistiky/domacnosti',

	'path.stats.customers': '/statistiky/platcovia',
	'path.municipality.stats.customers': '/obce/{0}/statistiky/platcovia',

	'path.municipality.stats.containers': '/obce/{0}/statistiky/zberne-nadoby',
	'path.stats.containers': '/statistiky/zberne-nadoby',

	'path.stats.motivation': '/statistiky/motivacia',
	'path.municipality.stats.motivation': '/obce/{0}/statistiky/motivacia',

	'path.stats.quantitativeCollections': '/statistiky/mnozstvovy-zber',
	'path.municipality.stats.quantitativeCollections': '/obce/{0}/statistiky/mnozstvovy-zber',

	'path.stats.uncollected': '/statistiky/nevyvezene-nadoby',
	'path.municipality.stats.uncollected': '/obce/{0}/statistiky/nevyvezene-nadoby',

	'path.stats.weight': '/statistiky/vyvoj-mnozstva-odpadov',
	'path.municipality.stats.weight': '/obce/{0}/statistiky/vyvoj-mnozstva-odpadov',

	'path.registry.yard': '/evidencia/zberny-dvor',
	'path.registry.yard.detail': '/evidencia/zberny-dvor/{0}',

	'path.lists.collections.places': '/zoznamy/zberne-miesta',
	'path.lists.collections.places.detail': '/zoznamy/zberne-miesta/{0}',

	'path.lists.customers': '/zoznamy/platcovia',
	'path.lists.customers.detail': '/zoznamy/platcovia/{0}',

	'path.lists.calendar': '/zoznamy/kalendar',
	'path.lists.calendar.event': '/zoznamy/kalendar/udalosti/{0}',

	'path.registry.collections.rounds': '/evidencia/zvozy',
	'path.registry.collections.rounds.detail': '/evidencia/zvozy/{0}',

	'path.registry.collections': '/evidencia/zaznamy',
	'path.registry.collections.detail': '/evidencia/zaznamy/{0}',

	'path.municipality.containers': '/obce/{0}/zoznamy/nadoby',
	'path.list.containers': '/zoznamy/nadoby',

	'path.list.customerCodes': '/zoznamy/kody-platcov',
	'path.municipality.customerCodes': '/obce/{0}/zoznamy/kody-platcov',

	'path.municipality': '/obce/:municipalityID',
	'path.municipality.info': '/obce/{0}/nastavenia-obce/zakladne-udaje',
	'path.municipality.wastes': '/obce/{0}/nastavenia-obce/typy-odpadov',
	'path.municipality.wastes.detail': '/obce/{0}/nastavenia-obce/typy-odpadov/{1}',
	'path.municipality.containerTypes': '/obce/{0}/nastavenia-obce/zberne-nadoby',
	'path.municipality.containerTypes.detail': '/obce/{0}/nastavenia-obce/zberne-nadoby/{1}',
	'path.municipality.users': '/obce/{0}/nastavenia-obce/pouzivatelia',
	'path.municipality.users.detail': '/obce/{0}/nastavenia-obce/pouzivatelia/{1}',
	'path.municipality.devices': '/obce/{0}/nastavenia-obce/zariadenia',
	'path.municipality.devices.detail': '/obce/{0}/nastavenia-obce/zariadenia/{1}',
	'path.administration.municipalities': '/administracia/obce',
	'path.administration.users': '/administracia/pouzivatelia',
	'path.administration.devices': '/administracia/zariadenia',
	'path.administration.devices.detail': '/administracia/zariadenia/{0}',
	'path.administration.users.detail': '/administracia/pouzivatelia/{0}',
	'path.administration.municipalities.detail': '/administracia/obce/{0}',
	'path.administration.codePortions': '/administracia/kody',
	'path.administration.codePortions.detail': '/administracia/kody/{0}',
	'path.administration.containersTransfer': '/administracia/presun-nadob',

	'path.municipality.collections.places': '/obce/{0}/zoznamy/zberne-miesta',
	'path.municipality.collections.places.create': '/obce/{0}/zoznamy/zberne-miesta/vytvorenie',
	'path.municipality.collections.places.detail': '/obce/{0}/zoznamy/zberne-miesta/{1}',

	'path.municipality.customers': '/obce/{0}/zoznamy/platcovia',
	'path.municipality.customers.detail': '/obce/{0}/zoznamy/platcovia/{1}',

	'path.municipality.calendar': '/obce/{0}/zoznamy/kalendar',
	'path.municipality.calendar.event': '/obce/{0}/zoznamy/kalendar/udalosti/{1}',

	'path.municipality.collections.rounds': '/obce/{0}/evidencia/zvozy',
	'path.municipality.collections.rounds.detail': '/obce/{0}/evidencia/zvozy/{1}',

	'path.municipality.collections': '/obce/{0}/evidencia/zaznamy',
	'path.municipality.collections.detail': '/obce/{0}/evidencia/zaznamy/{1}',

	'path.municipality.yard': '/obce/{0}/evidencia/zberny-dvor',
	'path.municipality.yard.detail': '/obce/{0}/evidencia/zberny-dvor/{1}',

	// MENU

	'menu.municipality.select.none': 'Vybrať obec',
	'menu.municipality.select.all': 'Všetky obce',

	'menu.section.home': 'Domov',
	'menu.section.stats': 'Štatistiky',
	'menu.section.registry': 'Evidencia odpadov',
	'menu.section.lists': 'Zoznamy',
	'menu.section.municipality': 'Nastavenia obce',
	'menu.section.administration': 'Administrácia',

	'menu.item.dashboard': 'Nástenka',

	'menu.item.stats.municipality': 'Triedenie za obec',
	'menu.item.stats.households': 'Triedenie za domácnosti',
	'menu.item.stats.customers': 'Triedenie za platcov',
	'menu.item.stats.motivation': 'Motivácia',
	'menu.item.stats.containers': 'Naplnenosť nádob',
	'menu.item.stats.uncollected': 'Nevyvezené nádoby',
	'menu.item.stats.weight': 'Vývoj množstva odpadov',
	'menu.item.stats.quantitativeCollections': 'Množstvový zber',

	'menu.item.registry.collections': 'Záznamy',
	'menu.item.registry.collections.rounds': 'Zvozy',
	'menu.item.registry.yard': 'Zberný dvor',

	'menu.item.lists.collections.places': 'Zberné miesta',
	'menu.item.lists.customers': 'Platcovia',
	'menu.item.lists.calendar': 'Kalendár',

	'menu.item.municipality.info': 'Základné údaje',
	'menu.item.municipality.wastes': 'Typy odpadov',
	'menu.item.municipality.containerTypes': 'Typy zberných nádob',
	'menu.item.municipality.users': 'Používatelia',
	'menu.item.municipality.devices': 'Zariadenia',
	'menu.item.municipality.containers': 'Zberné nádoby',
	'menu.item.municipality.customerCodes': 'Kódy platcov',

	'menu.item.administration.municipalities': 'Obce',
	'menu.item.administration.users': 'Používatelia',
	'menu.item.administration.codePortions': 'Generovanie kódov',
	'menu.item.administration.containersTransfer': 'Presun nádob',

	// ROLES

	'role.ADMINISTRATOR': 'Administrátor',
	'role.MUNICIPALITY_ADMIN': 'Pracovník obce',
	'role.COLLECTION_WORKER': 'Pracovník zberu',
	'role.COLLECTION_YARD_WORKER': 'Pracovník zberného dvora',
	'role.CITIZEN': 'Občan',
	'role.MUNICIPALITY_DUSTMAN': 'Smetiar',
	'role.MULTIPLE': 'Viacero oprávnení',
	'role.NONE': 'Žiadne oprávnenia',

	// MOTIVATION MODELS

	'motivation.model.DISCOUNT': 'Zľava za triedenie',
	'motivation.model.FEE': 'Paušál',
	'motivation.model.COMBINED_FEE': 'Kombinovaný paušál',
	'motivation.model.QUANTITY': 'Množstvový zber',
	'motivation.model.NONE': 'Žiadny',
	'motivation.models.valid.to': 'Platný do {0}',

	// MONTHS

	'month.0': 'Január',
	'month.1': 'Február',
	'month.2': 'Marec',
	'month.3': 'Apríl',
	'month.4': 'Máj',
	'month.5': 'Jún',
	'month.6': 'Júl',
	'month.7': 'August',
	'month.8': 'September',
	'month.9': 'Október',
	'month.10': 'November',
	'month.11': 'December',

	'month.short.0': 'Jan',
	'month.short.1': 'Feb',
	'month.short.2': 'Mar',
	'month.short.3': 'Apr',
	'month.short.4': 'Máj',
	'month.short.5': 'Jún',
	'month.short.6': 'Júl',
	'month.short.7': 'Aug',
	'month.short.8': 'Sep',
	'month.short.9': 'Okt',
	'month.short.10': 'Nov',
	'month.short.11': 'Dec',

	'day.1': 'Pondelok',
	'day.2': 'Utorok',
	'day.3': 'Streda',
	'day.4': 'Štvrtok',
	'day.5': 'Piatok',
	'day.6': 'Sobota',
	'day.7': 'Nedeľa',

	'day.short.1': 'Po',
	'day.short.2': 'Ut',
	'day.short.3': 'St',
	'day.short.4': 'Št',
	'day.short.5': 'Pi',
	'day.short.6': 'So',
	'day.short.7': 'Ne',

	// REFERENCE PERIODS

	'reference.period.MONTHLY': 'Mesiac',
	'reference.period.QUARTERLY': 'Štvrťrok',
	'reference.period.HALFYEARLY': 'Polrok',
	'reference.period.YEARLY': 'Rok',
	'reference.period.OTHER': 'Nedefinované',

	// FIELDS
	'field.placeholder.collectionPlaces': 'Vyberte zberné miesta',
	'field.placeholder.username': 'Zadajte prihlasovacie meno',
	'field.placeholder.table': 'Tabuľka',
	'field.placeholder.id': 'ID',
	'field.placeholder.collectionPlaceTypes': 'Vyberte typy zberných miest',
	'field.placeholder.codePortionType': 'Vyberte typ kódov',
	'field.placeholder.oneTimeCodes': 'Zadajte počet',
	'field.placeholder.fixedCodes': 'Zadajte počet',
	'field.placeholder.codesCount': 'Zadajte počet kódov',
	'field.placeholder.weight': 'Zadajte hmotnosť',
	'field.placeholder.weight.calculate.type': 'Vyberte spôsob počítania hmotnosti',
	'field.placeholder.date.range': 'Vyberte časový rozsah',
	'field.placeholder.flat.house': 'Vyberte bytový dom',
	'field.placeholder.container': 'Vyhľadať nádobu',
	'field.placeholder.scan.datetime': 'Vyberte dátum a čas',
	'field.placeholder.unsuitableConditionState': 'Vyberte typ',
	'field.placeholder.collectionRound': 'Priradený zvoz',
	'field.placeholder.comment': 'Napíšte komentár',
	'field.placeholder.notification.datetime': 'Dátum notifikácie',
	'field.placeholder.date': 'Vyberte dátum',
	'field.placeholder.password.old': 'Zadajte staré heslo',
	'field.placeholder.weight.before': 'Zadajte hmotnosť pred',
	'field.placeholder.weight.after': 'Zadajte hmotnosť po',
	'field.placeholder.address': 'Adresa',
	'field.placeholder.location.gps': 'GPS poloha',
	'field.placeholder.address.street': 'Ulica',
	'field.placeholder.address.numbers': 'Súpisné Číslo',
	'field.placeholder.address.numbers.street': 'Orientačné Číslo',
	'field.placeholder.address.lat': 'Zemepisná šírka',
	'field.placeholder.address.lon': 'Zemepisná dĺžka',
	'field.placeholder.address.city': 'Zadajte Obec',
	'field.placeholder.address.numbers.flat': 'Zadajte číslo bytu',
	'field.placeholder.opening.hours': 'Otváracie hodiny',
	'field.placeholder.note': 'Sem napíšte poznámku',
	'field.placeholder.email': 'Zadajte e-mail',
	'field.placeholder.name': 'Zadajte Meno',
	'field.placeholder.municipalities': 'Priradené obce',

	'field.placeholder.surname': 'Zadajte Priezvisko',
	'field.placeholder.password': 'Zadajte heslo',
	'field.placeholder.password.new': 'Zadajte nové heslo',
	'field.placeholder.password.confirm': 'Opakujte nové heslo',
	'field.placeholder.search': 'Vyhľadávanie',
	'field.placeholder.municipality': 'Obec',
	'field.placeholder.container.type': 'Typ nádoby',
	'field.placeholder.permissions': 'Oprávnenia',
	'field.placeholder.municipality.name': 'Zadajte názov obce',
	'field.placeholder.municipality.type': 'Vyberte typ obce',
	'field.placeholder.zip': 'Zadajte PSČ',
	'field.placeholder.type': 'Vyberte typ',
	'field.placeholder.title': 'Zadajte názov',
	'field.placeholder.wasteType': 'Zvoľte druh odpadu',
	'field.placeholder.capacity': 'Zadajte objem nádoby',
	'field.placeholder.motivation.model': 'Motivačný model',
	'field.placeholder.reference.period': 'Sledované obdobie',
	'field.placeholder.collections.count.monthly': 'Plánovaný počet vývozov za obdobie',
	'field.placeholder.planned.capacity': 'Plánovaný objem na 1 obyvateľa',
	'field.placeholder.price.one': 'Cena 1',
	'field.placeholder.price.two': 'Cena 2',
	'field.placeholder.base.fee': 'Základný poplatok',
	'field.placeholder.discount.waste': 'Zľava',
	'field.placeholder.discount.max': 'Maximálna zľava',
	'field.placeholder.date.from': 'Dátum od',
	'field.placeholder.date.to': 'Dátum do',
	'field.placeholder.waste.type.name': 'Zadajte názov odpadu',
	'field.placeholder.waste.type.code': 'Vyberte katalógové číslo',
	'field.placeholder.waste.type.type': 'Vyberte spôsob zberu',
	'field.placeholder.waste.type.coefficient': 'Zadajte váhový koeficient',
	'field.placeholder.waste.type.color': 'Vyberte farbu',
	'field.placeholder.customer': 'Vyberte platcu',
	'field.placeholder.waste.type': 'Vyberte typ',
	'field.placeholder.assigned.from': 'Priradená od',
	'field.placeholder.assigned.to': 'Priradená do',
	'field.placeholder.members.count': 'Počet členov',
	'field.placeholder.active.from': 'Aktívny od',
	'field.placeholder.active.to': 'Aktívny do',
	'field.placeholder.deviceNumber': 'Kód zariadenia',
	'field.placeholder.quantity': 'Zadajte naplnenosť',
	'field.placeholder.collection.place': 'Vyberte zberné miesto',
	'field.placeholder.registrationType': 'Vyberte spôsob',

	'field.placeholder.from': 'Od',
	'field.placeholder.to': 'Do',

	// ERROR

	'error.message.default': 'Akciu sa nepodarilo vykonať',

	// ERROR VALIDATION

	'error.webservice.username.required': 'Musíte vyplniť obidve hodnoty',
	'error.webservice.password.required': 'Musíte vyplniť obidve hodnoty',

	'error.validation.dustman.multiple.roles': 'Smetiar môže byť priradený iba k jednej obci bez dodatočných oprávnení',
	'error.validation.DEFAULT': 'Zadaná hodnota je nesprávna.',
	'error.validation.REQUIRED': 'Toto pole je povinné',
	'error.validation.EMAIL': 'Nesprávny formát e-mailu.',
	'error.validation.MAX_NUMBER': 'Hodnota musí byť menšia ako 1 000 000 000',
	'error.validation.PASSWORD_LENGTH': 'Heslo musí obsahovať aspoň 8 znakov.',
	'error.validation.PASSWORD_UPPERCASE': 'Heslo musí obsahovať aspoň jedno veľké písmeno.',
	'error.validation.PASSWORD_LOWERCASE': 'Heslo musí obsahovať aspoň jedno malé písmeno.',
	'error.validation.PASSWORD_NUMBER': 'Heslo musí obsahovať aspoň jedno číslo.',
	'error.validation.PASSWORD_SPECIAL': 'Heslo musí obsahovať aspoň jeden špeciálny znak.',
	'error.validation.PASSWORD_MATCH': 'Zadané heslá sa nezhodujú.',
	'error.validation.ARRAY': 'Vyberte aspoň jednu položku.',
	'error.validation.REQUIRED_STRING': 'Toto pole je povinné',
	'error.validation.REQUIRED_NUMBER': 'Toto pole je povinné',
	'error.validation.ADDRESS_NUMBER': 'Hodnota musí byť kladné celé číslo.',
	'error.validation.REQUIRED_ANY_NUMBER': 'Toto pole je povinné',
	'error.validation.ZIP': 'PSČ je v nesprávnom formáte',
	'error.validation.MOTIVATION_MODEL_COLLECTIONS_COUNT': 'Hodnota musí byť celé číslo od 0 do 9',
	'error.validation.MOTIVATION_MODEL_CAPACITY': 'Hodnota musí byť číslo od 0 do 999999 s presnosťou na 2 desatinné miesta',
	'error.validation.MOTIVATION_MODEL_PRICE': 'Hodnota musí byť číslo od 0 do 999 s presnosťou na 2 desatinné miesta',
	'error.validation.MOTIVATION_MODEL_DISCOUNT': 'Hodnota musí byť číslo od 0 do 1 s presnosťou na 3 desatinné miesta',
	'error.validation.MOTIVATION_MODEL_MAX_DISCOUNT': 'Hodnota musí byť celé číslo od 0 do 100',
	'error.validation.LAT': 'Hodnota musí byť od -90 do 90',
	'error.validation.LON': 'Hodnota musí byť od -180 do 180',
	'error.validation.number': 'Hodnota musí byť číslo',
	'error.validation.DUPLICITY': 'Nemôžete zadať duplicitné hodnoty',

	'error.validation.LT': 'Hodnota musí byť menšia ako {0}',
	'error.validation.GT': 'Hodnota musí byť vyššia ako {0}',
	'error.validation.FROM_DATE': 'Dátum od musí byť skôr ako dátum do',
	'error.validation.TO_DATE': 'Dátum do musí byť neskôr ako dátum od',
	'error.validation.DATE_RANGE_YEAR': 'Rozdiel dátumov nemôže byť väčší ako 1 rok',
	'error.container.invalid.place': 'Nádoba nie je priradená k vybranému zbernému dvoru',
	'error.validation.EMPTY_VALUE': 'Nemôžete pridávať prázdnu hodnotu',

	'error.validation.AT_LEAST_ONE_NUMBER': 'Minimálne 1 hodnota musí byť väčšia ako 0',
	'error.validation.AT_LEAST_ONE_CONTAINER_TYPE': 'Pridajte aspoň jeden typ nádoby',

	'error.validation.customer.number': 'Vyplňte prosím buď súpisné číslo alebo orientačné číslo',

	// COMMON

	'common.reset': 'Reset',
	'common.use': 'Použiť',
	'common.back': 'Späť',
	'common.close': 'Zatvoriť',
	'common.delete': 'Odstrániť',
	'common.changeContainerType': 'Zmeniť',
	'common.delete.selected': 'Odstrániť označené',
	'common.changeContainerType.selected': 'Zmeniť typ nádoby pre označené',
	'common.edit': 'Upraviť',
	'common.deactivate': 'Deaktivovať',
	'common.activate': 'Aktivovať',
	'common.save': 'Uložiť',
	'common.cancel': 'Zrušiť',
	'common.update': 'Upraviť',
	'common.permissions': 'Oprávnenia',
	'common.date.format': 'D.M.YYYY HH:mm',
	'common.date.format.seconds': 'D.M.YYYY HH:mm:ss',
	'common.date.format.date': 'D.M.YYYY',
	'common.time.format': 'HH:mm',
	'common.basic.info': 'Základné informácie',
	'common.upload.image': 'Presunúť obrázok alebo {0}',
	'common.upload': 'Nahrať',
	'common.upload.image.loading': 'Nahrávam obrázok...',
	'common.select.file': 'Vybrať súbor',
	'common.upload.invalid': 'Vybraný súbor nie je obrázok!',
	'common.upload.failed': 'Súbor sa nepodarilo načítať!',
	'common.upload.error': 'Nepodarilo sa nahrať obrázok!',
	'common.upload.remove': 'Odstrániť',
	'common.upload.invalid.excel': 'Súbor musú byť vo formáte xlsx alebo xls!',
	'common.upload.missing.file': 'Vyberte súbor',
	'common.motivation.model': 'Motivačný model',
	'common.crop.title': 'Orezať obrázok',
	'common.add': 'Pridať',
	'common.duplicate': 'Duplikovať',
	'common.loading': 'Načítavam...',
	'common.version': 'Verzia',
	'common.createdAt': 'Vytvorené',
	'common.updatedAt': 'Upravené',
	'common.today': 'Dnes',
	'common.yes': 'Áno',
	'common.no': 'Nie',
	'common.decimal.separator': ',',
	'common.month.0': 'Január',
	'common.month.1': 'Február',
	'common.month.2': 'Marec',
	'common.month.3': 'Apríl',
	'common.month.4': 'Máj',
	'common.month.5': 'Jún',
	'common.month.6': 'Júl',
	'common.month.7': 'August',
	'common.month.8': 'September',
	'common.month.9': 'Október',
	'common.month.10': 'November',
	'common.month.11': 'December',
	'common.state': 'Stav',
	'common.print': 'Vytlačiť',
	'common.download': 'Stiahnuť',
	'common.limit': 'Počet prvkov na stranu:',

	'common.quarter.1': '1. kvartál',
	'common.quarter.2': '2. kvartál',
	'common.quarter.3': '3. kvartál',
	'common.quarter.4': '4. kvartál',

	'common.half-year.1': '1. polrok',
	'common.half-year.2': '2. polrok',

	'pagination.of.pages': 'Celkový počet strán: {0}',
	'table.loading.data': 'Načítavam dáta',
	'table.loading.failure': 'Nepodarilo sa načítať dáta',
	'table.loading.no.data': 'Nenašli sa žiadne dáta',
	'table.loading.reset.filter': 'Vymazať filtre',

	// DIALOG

	'dialog.cancel.title': 'Zatvoriť',
	'dialog.accept.title': 'Potvrdiť',

	// LOGIN

	'login.header.title': 'Prihlásenie',
	'login.remember.title': 'Zapamätať prihlásenie',
	'login.password.forgot': 'Zabudli ste heslo?',
	'login.submit.title': 'PRIHLÁSIŤ',
	'login.loading.message': 'Prihlasujem používateľa...',
	'login.failed.message': 'Nepodarilo sa prihlásiť. Skontrolujte prosím Vaše prihlasovacie údaje',

	// FORGOT PASSWORD

	'forgot.header.title': 'Zabudnuté heslo',
	'forgot.submit.title': 'OBNOVIŤ HESLO',
	'forgot.loading.message': 'Obnovujem heslo...',
	'forgot.success.message': 'Vaše heslo bolo obnovené. Bol Vám zaslaný e-mail s inštrukciami k nastaveniu nového hesla',

	// RESET PASSWORD

	'reset.header.title': 'Obnovenie hesla',
	'reset.submit.title': 'NASTAVIŤ HESLO',
	'reset.loading.message': 'Nastavujem heslo...',
	'reset.success.message': 'Vaše heslo bolo nastavené',
	'reset.expired.title': 'Čas uplynul',
	'reset.expired.message': 'Čas platnosti e-mailu pre nastavenie nového hesla už uplynul. Pre obnovenie hesla odošlite žiadosť opäť.',
	'reset.expired.return': 'Návrat na prihlásenie',

	// COMPLETE REGISTRATION

	'complete.header.title': 'Dokončenie registrácie',
	'complete.header.message': 'Práve dokončujete registráciu pod účtom {0} s právami {1}',
	'complete.submit.title': 'DOKONČIŤ',
	'complete.loading.message': 'Dokončujem registráciu...',
	'complete.success.message': 'Registrácia bola dokončená',
	'complete.expired.title': 'Neplatná pozvánka',
	'complete.expired.message': 'Čas platnosti pozvánky pre dokončenie registrácie už uplynul. Kontaktuje prosím administrátora o opätovné zaslanie pozvánky.',
	'complete.expired.contact': 'Kontaktovať administrátora',
	'complete.contact.loading.message': 'Konataktujem administrátora...',
	'complete.contact.success.message': 'Administrátor bol kontaktovaný. O výsledku Vás budeme informovať',

	// LOGOUT

	'logout.loading.message': 'Odhlasujem používateľa...',
	'logout.title': 'Odhlásiť',

	'page.administration.breadcrumbs': 'Administrácia',
	'page.administration.containersTransfer': 'Presun nádob',
	'page.lists.breadcrumbs': 'Zoznamy',
	'page.registry.breadcrumbs': 'Evidencia odpadov',

	'pages.transferContainers.title': 'Presun nádob',

	// USERS PAGE

	'page.users.registrationWithEmail': 'S emailovým potvrdením',
	'page.users.registrationWithoutEmail': 'Bez emailového potvrdenia',
	'page.users.title': 'Zoznam používateľov',
	'page.users.breadcrumbs': 'Používatelia',
	'page.users.add': 'Pridať používateľa',
	'page.users.loading': 'Načítavam používateľov...',
	'page.users.none': 'Nenašli sa žiadni užívatelia',
	'page.users.send.invitation': 'Odoslať pozvánku',
	'page.users.deactivate.title': 'Deaktivovať používateľa {0}',
	'page.users.deactivate.message': 'Naozaj chcete deaktivovať tohoto používateľa?',
	'page.users.deactivate.accept': 'Deaktivovať',
	'page.users.activate.title': 'Aktivovať používateľa {0}',
	'page.users.activate.message': 'Naozaj chcete aktivovať tohoto používateľa?',
	'page.users.activate.accept': 'Aktivovať',
	'page.users.deactivate.success': 'Používateľ bol úspešne deaktivovaný',
	'page.users.activate.success': 'Používateľ bol úspešne aktivovaný',
	'page.users.confirmed.tooltip': 'Používateľ je aktívny',
	'page.users.waiting.tooltip': 'Čaká na dokončenie registrácie',
	'page.users.inactive.tooltip': 'Neaktívny používateľ',
	'page.users.delete.title': 'Odstrániť používateľa {0}',
	'page.users.delete.message': 'Naozaj chcete odstrániť tohoto používateľa?',
	'page.users.delete.accept': 'Odstrániť',
	'page.users.delete.success': 'Používateľ bol úspešne odstránený',
	'page.users.add.success': 'Používateľ bol úspešne pozvaný do aplikácie',

	// USER DETAIL

	'page.users.detail.loading': 'Načítavam používateľa...',
	'page.users.detail.permissions': 'Pridelené oprávnenia',
	'page.users.detail.permissions.add': 'Pridať oprávnenia',
	'page.users.detail.created': 'Dátum vytvorenia',
	'page.users.detail.login': 'Posledné prihlásenie',
	'page.users.detail.invitation.resend': 'Znovu odoslať pozvánku',
	'page.users.detail.invitation.resend.success': 'Pozvánka bola odoslaná',
	'page.users.detail.confirmed.not': 'Používateľ ešte nedokončil registráciu. ',
	'page.users.detail.municipalities.required': 'Používateľ musí mať pridelenú aspoň jednu obec',
	'page.users.detail.profile': 'Profil',
	'page.users.detail.activate': 'Aktivovať užívateľa',
	'page.users.detail.deactivate': 'Deaktivovať užívateľa',
	'page.users.detail.update.success': 'Užívateľ bol úpešne upravený',
	'page.users.detail.profile.update.success': 'Profil bol úpešne upravený',
	'page.users.detail.change': 'Zmeniť',
	'page.users.detail.change.password': 'Zmena hesla',
	'page.users.detail.change.password.success': 'Heslo bolo úspešne zmenené',

	// MUNICIPALITY

	'page.municipalities.add.title': 'Pridať obec',
	'page.municipalities.breadcrumbs': 'Obce',
	'page.municipality.settings.breadcrumbs': 'Nastavenia obce',

	// MUNICIPALITIES PAGE

	'page.municipalities.title': 'Správa obcí',
	'page.municipalities.none': 'Nenašli sa žiadne obce',
	'page.municipalities.section': 'Administrácia',
	'page.municipalities.add': 'Pridať obec',
	'page.municipalities.loading': 'Načítavam obce...',
	'page.municipalities.active.tooltip': 'Obec je aktívna',
	'page.municipalities.inactive.tooltip': 'Obec je neaktívna',
	'page.municipalities.useGeneralCollectionRound': 'Obec využíva všeobecný zvoz',
	'page.municipalities.useGeneralCollectionRound.inactive': 'Obec nevyužíva všeobecný zvoz',
	'page.municipalities.deactivate.title': 'Deaktivovať obec {0}',
	'page.municipalities.activate.title': 'Aktivovať obec {0}',
	'page.municipalities.deactivate.success': 'Obec bola úspešne deaktivovaná',
	'page.municipalities.activate.success': 'Obec bola úspešne aktivovaná',
	'page.municipalities.activate.message': 'Naozaj chcete aktivovať túto obec?',
	'page.municipalities.deactivate.message': 'Naozaj chcete deaktivovať túto obec?',
	'page.municipalities.add.success': 'Obec bola úspešne vytvorená',
	'page.municipalities.activate.accept': 'Aktivovať',
	'page.municipalities.deactivate.accept': 'Deaktivovať',
	'page.municipalities.deactivate.failed': 'Nepodarilo sa deaktivovať používateľa',
	'page.municipalities.activate.failed': 'Nepodarilo sa aktivovať používateľa',

	// MUNICIPALITY DETAIL PAGE

	'page.municipalities.detail.generalCollectionRound': 'Nastavenia všeobecného zvozu',
	'page.municipalities.detail.ALTARES': 'Altares Web service údaje',
	'page.municipalities.detail.deactivate': 'Deaktivovať obec',
	'page.municipalities.detail.activate': 'Aktivovať obec',
	'page.municipalities.detail.activate.success': 'Obec bola úspešne aktivovaná',
	'page.municipalities.detail.deactivate.success': 'Obec bola úspešne deaktivovaná',
	'page.municipalities.detail.update.success': 'Obec bola úspešne upravená',
	'page.municipalities.detail.motivation.model.add': 'Pridať motivačný model',
	'page.municipalities.detail.motivation.model.update': 'Upraviť motivačný model',
	'page.municipalities.detail.motivation.update.success': 'Motivačný model bol úspenšne upravený',
	'page.municipalities.detail.motivation.create.success': 'Motivačný model bol úspenšne vytvorený',
	'page.municipalities.detail.motivation.models.history': 'História motivačných modelov',
	'page.municipalities.detail.motivation.models.active': 'Aktívny motivačný model',
	'page.municipalities.detail.motivation.models.valid.to': 'Platný do',
	'page.municipalities.detail.motivation.models': 'Motivačné modely',
	'page.municipalities.detail.motivation.models.none': 'Nie sú evidované žiadne motivačné modely',
	'page.municipalities.detail.motivation.models.valid.to.none': 'Bez ukončenia',
	'page.municipalities.detail.import': 'Importovať zberné miesta a platcov',
	'page.municipalities.detail.import.containers': 'Importovať nádoby',
	'page.municipalities.detail.import.rfid': 'Importovať RFID kódy',
	'page.municipalities.detail.import.rfid.button': 'Importovať RFID kódy',
	'page.municipalities.detail.import.button': 'Importovať',
	'page.municipalities.detail.import.municipality.button': 'Importovať zberné miesta a platcov',
	'page.municipalities.detail.import.containers.button': 'Importovať nádoby',
	'page.municipalities.detail.import.calendar': 'Importovať kalendár',
	'page.municipalities.detail.import.calendar.button': 'Importovať kalendár',
	'page.municipalities.detail.import.loading': 'Nahrávam súbor',
	'page.municipalities.detail.import.success': 'Súbor bol úspešne nahraný. Po dokončení importu obdržíte email s výsledkom. Môže to trvať aj niekoľko minút',
	'page.municipalities.detail.loading': 'Načítavam obec...',

	// CONTAINER TYPES PAGE

	'page.containerType.add': 'Pridať typ zbernej nádoby',
	'page.containerType.add.title': 'Pridať nádobu',
	'page.containerTypes.title': 'Správa typov zberných nádob',
	'page.containerTypes.active.tooltip': 'Zberna nádoba aktívna',
	'page.containerTypes.inactive.tooltip': 'Zberna nádoba neaktívna',
	'page.containerTypes.create.success': 'Zberná nádoba bola úspešné vytvorená',
	'page.containerTypes.activate.accept': 'Aktivovať',
	'page.containerTypes.deactivate.accept': 'Deaktivovať',
	'page.containerTypes.activate.message': 'Naozaj chcete aktivovať typ zbernej nádoby?',
	'page.containerTypes.deactivate.message': 'Naozaj chcete deaktivovať typ zbernej nádoby?',
	'page.containerTypes.deactivate.success': 'Typ zbernej nádoby bol úspešne deaktivovaný',
	'page.containerTypes.activate.success': 'Typ zbernej nádoby bol úspešne aktivovaný',
	'page.containerTypes.breadcrumbs': 'Typy zberných nádob',
	'page.containerTypes.deactivate.title': 'Deaktivovať typ zbernej nádoby {0}',
	'page.containerTypes.activate.title': 'Aktivovať typ zbernej nádoby {0}',
	'page.containersType.none': 'Neevidujeme žiadne typy zberných nádob.',
	'page.containersTypes.loading': 'Načítavam typy zbiernych miest ...',

	// STATISTICS

	'statistics.period.MONTH': 'Mesiac',
	'statistics.period.QUARTER': 'Štvťrok',
	'statistics.period.HALF_YEAR': 'Polrok',
	'statistics.period.YEAR': 'Rok',

	// CONTAINER TYPE DETAIL PAGE

	'page.containerType.remove.message': 'Chcete naozaj vymazať tento typ zbernej nádoby a zároveň expirovať všetky nádoby tohto typu priradené k domácnostiam?',
	'page.containerType.remove.title': 'Zmazať typ zbernej nádoby',
	'page.containerType.remove.accept': 'Zmazať',
	'page.containerType.delete.success': 'Zmazanie typu zbernej nádoby bolo úspešné',
	'page.containerType.detail.activate': 'Aktivovať',
	'page.containerType.detail.deactivate': 'Deaktivovať',
	'page.containerType.update.success': 'Typ zbernej nádoby bol úspešne aktualizovaný',
	'page.containerType.delete.expire': 'Vymazať a expirovať',
	'page.containerType.delete.not.expire': 'Vymazať bez expirovania',
	'page.containerType.delete.loading': 'Načítavam typ nádoby...',

	// CONTAINER TYPE

	'container.types.ONE_TIME': 'Jednorázová',
	'container.types.PERMANENT': 'Fixná',
	'container.types.UNIVERSAL': 'Univerzálna',
	'container.types.COLLECTION_YARD': 'Zberný dvor',

	// MUNICIPALITY TYPE

	'municipality.type.CITY': 'Obec',
	'municipality.type.WASTE_COMPANY': 'Zvozná spoločnosť',
	'municipality.type.REGION': 'Región',
	'municipality.type.CITY_PART': 'Mestská časť',

	// COLLECTION PLACE TYPE

	'collectionPlace.type.FAMILY_HOUSE': 'Rodinný dom',
	'collectionPlace.type.FLAT_HOUSE': 'Bytový dom',
	'collectionPlace.type.FLAT': 'Byt',
	'collectionPlace.type.COLLECTION_YARD': 'Zberný dvor',
	'collectionPlace.type.NEST': 'Stojisko',
	'collectionPlace.type.OTHER': 'Iné',
	'collectionPlace.type.COTTAGE': 'Chata',

	// WASTE TYPE

	'page.waste.types.detail.loading': 'Načítavam typ odpadov...',
	'page.waste.types.add.title': 'Pridať typ odpadu',
	'page.waste.types.breadcrumbs': 'Typy odpadov',
	'page.waste.types.detail.motivation.activate': 'Aktivovať motivačný model',
	'page.waste.types.detail.motivation.deactivate': 'Deaktivovať motivačný model',

	// WASTE TYPES PAGE

	'page.waste.types.title': 'Správa typov odpadov',
	'page.waste.types.section': 'Nastavenia obce',
	'page.waste.types.add': 'Pridať typ odpadu',
	'page.waste.types.loading': 'Načítavam typy odpadov...',
	'page.waste.types.none': 'Nenašli sa žiadne typy odpadov',
	'page.waste.types.add.success': 'Typ odpadu bol úspešne vytvorený',
	'page.waste.types.active.motivation': 'Motivačný model je aktívny',
	'page.waste.types.inactive.motivation': 'Motivačný model je neaktívny',
	'page.waste.types.detail.update.success': 'Typ odpadu bol úspešne upravený',
	'page.waste.types.delete.message': 'Chcete vymazať tento typ odpadu?',
	'page.waste.types.delete.title': 'Odstrániť typ odpadu {0}',
	'page.waste.types.delete.accept': 'Odstrániť',
	'page.waste.types.delete.success': 'Typ odpadov bol úspešne odstránený',
	'page.waste.types.motivation.model.activate.success': 'Motivačný model bol úspešne aktivovaný',
	'page.waste.types.motivation.model.deactivate.success': 'Motivačný bol úspešne deaktivovaný',
	'page.waste.types.motivation.model.deactivate.title': 'Deaktivovať motivačný model pre typ odpadov {0}',
	'page.waste.types.motivation.model.activate.title': 'Aktivovať motivačný model pre typ odpadov {0}',
	'page.waste.types.activate.accept': 'Aktivovať',
	'page.waste.types.deactivate.accept': 'Deaktivovať',
	'page.waste.types.activate.message': 'Naozaj chcete aktivovať motivačný model pre tento typ odpadov?',
	'page.waste.types.deactivate.message': 'Naozaj chcete deaktivovať motivačný model pre tento typ odpadov?',
	'page.waste.types.deactivate.success': 'Motivačný model bol úspešne deaktivovaný',
	'page.waste.types.activate.success': 'Motivačný model bol úspešne aktivovaný',

	// CUSTOMERS

	'page.customers.breadcrumbs': 'Platcovia',
	'page.customers.person': 'Obyvateľ',
	'page.customers.company': 'Podnikateľský subjekt',
	'page.customers.add': 'Pridať platcu',
	'page.customers.create.success': 'Platca bol úspešne vytvorený',
	'page.customers.deactivate.message': 'Tento platca je priradený k ({0}) domácnostiam. Deaktiváciou platcu dôjde k expirovaniu týchto záznamov. Chcete naozaj pokračovať?',
	'page.customers.deactivate.title': 'Deaktivovať platcu {0}',
	'page.customers.deactivate.accept': 'Deaktivovať',
	'page.customers.activate.message': 'Chcete aktivovat tohoto platcu?',
	'page.customers.activate.accept': 'Aktivovať',
	'page.customers.activate.title': 'Aktivovať platcu {0}',
	'page.customers.update.success': 'Platca bol úspešne aktualizovaný',
	'page.customers.remove.active.to': 'Reaktivovať',
	'page.customer.remove.title': 'Odstránenie platcu {0}',
	'page.customer.remove.message': 'Vymazaním platcu dôjde k odstráneniu platcu zo všetkých priradených zberných miest ({0}). Chcete pokračovať?',
	'page.customer.remove.accept': 'Zmazať',
	'page.customers.remove.success': 'Platca bol úspešne zmazaný',
	'page.customers.none': 'Nenašli sa žiadni platcovia',
	'page.customers.update': 'Upraviť',
	'page.customers.create': 'Vytvoriť nového platcu',
	'page.customers.anonymous': 'Anonymný platca {0}',
	'page.customers.detail.deactivate': 'Deaktivovať platcu',
	'page.customers.detail.activate': 'Aktivovať platcu',
	'page.customers.active': 'Platca je aktívny',
	'page.customers.inactive': 'Platca je neaktívny',
	'page.customers.active.to': 'Platca je aktívny do {0}',
	'page.customers.inactive.from': 'Platca je neaktívny od {0}',
	'page.customers.detail.loading': 'Načítavam platcu...',
	'page.customers.detail': 'Platca',
	'page.customers.detail.codes.select': 'Kódy',
	'page.customers.detail.select.codes': 'Vyberte kódy',
	'page.customers.detail.expire.code': 'Nastaviť expiráciu',
	'page.customers.detail.validTo.select': 'Vyberte dátum expirácie',
	'page.customers.detail.validTo.none': 'Žiadna expirácia',

	'page.customers.detail.codes': 'Priradené kódy',
	'page.customers.detail.assign.code': 'Priradiť kódy',

	// COLLECTION PLACES

	'page.collectionPlaces.title': 'Zberné miesta',
	'page.collectionPlaces.none': 'Nenašli sa žiadne zberné miesta',
	'page.collectionPlaces.breadcrumbs': 'Zberné miesta',
	'page.collectionPlaces.add': 'Pridať zberné miesto',
	'page.collectionPlaces.active.tooltip': 'Aktívne miesto',
	'page.collectionPlaces.inactive.tooltip': 'Neaktívne miesto',
	'page.collectionPlaces.deactivate.success': 'Zberné miesto bolo úspenšne deaktivované',
	'page.collectionPlaces.activate.success': 'Zberné miesto bolo úspenšne aktivované',
	'page.collectionPlaces.deactivate.title': 'Deaktivovať zberné miesto {0}',
	'page.collectionPlaces.activate.title': 'Aktivovať zberné miesto {0}',
	'page.collectionPlaces.activate.accept': 'Aktivovať',
	'page.collectionPlaces.deactivate.accept': 'Deaktivovať',
	'page.collectionPlaces.activate.message': 'Naozaj chcete aktivovať toto zberné miesto?',
	'page.collectionPlaces.deactivate.message': 'Naozaj chcete deaktivovať toto zberné miesto?',
	'page.collectionPlaces.delete.title': 'Odstrániť zberné miesto {0}',
	'page.collectionPlaces.delete.message': 'Naozaj chcete odstrániť toto zberné miesto?',
	'page.collectionPlaces.delete.accept': 'Odstrániť',
	'page.collectionPlaces.delete.success': 'Zberné miesto bol úspešne odstránené',
	'page.collectionPlaces.create.breadcrumbs': 'Nové zberné miesto',
	'page.collectionPlaces.create.containers': 'Zberné nádoby',
	'page.collectionPlaces.create.containers.none': 'Nie sú pridané žiadne nádoby',
	'page.collectionPlaces.create.success': 'Zberné miesto bolo úspešne vytvorené',
	'page.collectionPlaces.customer.remove.title': 'Ostráninť platcu {0}',
	'page.collectionPlaces.customer.remove.message': 'Naozaj chcete odstrániť platcu z tototo zberného miesta?',
	'page.collectionPlaces.customer.remove.success': 'Platca bol odstránený',
	'page.collectionPlaces.customers.deactivate.message': 'Naozaj chcete nastaviť dátum platnosti platcu',
	'page.collectionPlaces.customers.deactivate.title': 'Nastaviť dátum platnosti {0}',
	'page.collectionPlaces.customers.deactivate.accept': 'Nastaviť',
	'page.collectionPlaces.customers.activate.message': 'Naozaj chcete ostrániť dátum platnosti platcu',
	'page.collectionPlaces.customers.activate.title': 'Ostrániť dátum platnosti {0}',
	'page.collectionPlaces.customers.activate.accept': 'Odstrániť',
	'page.collectionPlaces.customer.update.success': 'Platca bol úspešne upravený',
	'page.collectionPlaces.containers.deactivate.message': 'Naozaj chcete nastaviť dátum platnosti nádoby',
	'page.collectionPlaces.containers.deactivate.title': 'Nastaviť dátum platnosti {0}',
	'page.collectionPlaces.containers.deactivate.accept': 'Nastaviť',
	'page.collectionPlaces.containers.activate.message': 'Naozaj chcete ostrániť dátum platnosti nádoby',
	'page.collectionPlaces.containers.activate.title': 'Ostrániť dátum platnosti {0}',
	'page.collectionPlaces.containers.activate.accept': 'Odstrániť',
	'page.collectionPlaces.containers.update.success': 'Nádoba bola úspešne upravená',
	'page.collectionPlaces.customer.expire.message': 'Nastavením nového platcu expirujete predchádzjúceho aktívneho platcu',
	'page.collectionPlaces.customer.expire.title': 'Expirovať platcu',
	'page.collectionPlaces.containers.customer.expire.accept': 'Expirovať a nastaviť',
	'page.collectionPlaces.container.update.success': 'Nádoba bola úspešne upravená',

	'page.containers.consumption': 'Spotreba',
	'page.containers.consumption.three.months': '3 Mes',
	'page.containers.consumption.six.months': '6 Mes',
	'page.containers.consumption.twelve.months': '12 Mes',
	'page.containers.consumption.rest': 'Celý čas',
	'page.containers.consumption.active': 'Aktívne',
	'page.containers.consumption.permanent': 'Fixné nádoby',
	'page.containers.consumption.universal': 'Univerzálne nádoby',

	'page.containers.consumption.no.containers': 'Nie sú priradené žiadne nádoby',
	'page.containers.consumption.title': 'Prehľad nádob',
	'page.containers.sorting.title': 'Rozdelenie odpadov',

	'page.container.transfer.success': 'Nádoba bola úspešne presunutá',

	// COLLECTION PLACE

	'page.collectionPlaces.detail.loading': 'Načítavam zberné miesto',
	'page.collectionPlaces.detail.customers': 'Zoznam platcov',
	'page.collectionPlaces.detail.customers.set': 'Nastaviť platcu',
	'page.collectionPlaces.detail.customers.create': 'Vytvoriť platcu',
	'page.collectionPlaces.detail.customer.anonymous': 'Anonymný platca',
	'page.collectionPlaces.detail.customer.set.success': 'Platca bol úspešne priradený',
	'page.collectionPlaces.detail.customer.set.failed': 'Platcu sa nepodarilo priradiť',
	'page.collectionPlaces.detail.containers': 'Zoznam zberných nádob',
	'page.collectionPlaces.detail.containers.add': 'Pridať nádobu',
	'page.collectionPlaces.detail.containers.add.success': 'Nádoba bola úspešne pridaná',
	'page.collectionPlaces.detail.containers.none': 'Nenašli sa žiadne zberné nádoby',
	'page.collectionPlaces.detail.remove.success': 'Zberné miesto bolo odstránené',
	'page.collectionPlaces.detail.update.success': 'Zberné miesto bolo upravené',
	'page.collectionPlaces.detail.transfer': 'Presunúť',
	'page.collectionPlaces.detail.transfer.title': 'Presunúť na iné zberné miesto',

	// COLLECTION ROUNDS

	'page.collectionRounds.none': 'Neevidujeme žiadne zvozy',
	'page.collectionRounds.breadcrumbs': 'Zvozy',
	'page.collectionRounds.title': 'Zoznam zvozov',
	'page.collectionRounds.add': 'Pridať zvoz',
	'page.collectionRounds.delete.title': 'Odstrániť zvoz {0}',
	'page.collectionRounds.delete.message': 'Naozaj chcete odstrániť tento zvoz?',
	'page.collectionRounds.delete.accept': 'Odstrániť',
	'page.collectionRounds.delete.success': 'Zvoz bol úspešne odstránený',
	'page.collectionRounds.loading': 'Načítavam zvozy...',
	'page.collectionRounds.detail.map': 'Mapa',
	'page.collectionRounds.detail.map.filter': 'Filter',
	'page.collectionRounds.detail.map.invalidGPS': 'Nádoby bez validnej GPS hodnoty',
	'page.collectionRounds.detail.overview': 'Súhrn',
	'pages.collectionRounds.detail.map.filter.collected': 'Vyvezená nádoba',
	'pages.collectionRounds.detail.map.filter.notCollected': 'Nevyvezená nádoba',
	'pages.collectionRounds.detail.map.filter.invalid': 'Chybový stav',

	// COLLECTION ROUND

	'page.collectionRounds.detail.collections': 'Zoznam záznamov',
	'page.collectionRounds.detail.loading': 'Načítavam zvoz',
	'page.collectionRounds.detail.update.success': 'Zvoz bol úspešne upravený',

	// COLLECTIONS

	'page.collections.title': 'Zoznam záznamov',
	'page.collections.delete.title': 'Odstrániť záznam {0}',
	'page.collections.delete.message': 'Naozaj chcete odstrániť tento záznam?',
	'page.collections.change.container.type.message': 'Naozaj chcete zmeniť typ kontajnera pre tento záznam?',
	'page.collections.delete.selected.title': 'Odstrániť označené záznamy',
	'page.collections.change.container.type.selected.title': 'Zmeniť typ nádoby',
	'page.collections.delete.selected.message': 'Naozaj chcete odstrániť všetky označené záznamy?',
	'page.collections.change.container.type.selected.message': 'Vyberte typ kontajnera:',
	'page.collections.delete.accept': 'Odstrániť',
	'page.collections.change.container.accept': 'Zmeniť typ nádoby',
	'page.collections.delete.success': 'Záznam bol úspešne odstránený',
	'page.collections.delete.selected.success': 'Označené záznamy boli úspešne odstránené',
	'page.collections.breadcrumbs': 'Záznamy',
	'page.collections.collection.round.update.success': 'Zber bol úspešne pridený ku záznamu',
	'page.collections.editing.stop': 'Ukončiť úpravy',
	'page.collections.editing.start': 'Začať úpravy',
	'page.collections.set.collection.round': 'Priradený zvoz označených záznamov',
	'page.collections.collection.round': 'Priradený zvoz',
	'page.collections.add': 'Pridať záznam',
	'page.collections.create.success': 'Záznam bol úspešne vytvorený',
	'page.collections.none': 'Neevidujeme žiadne záznamy',
	'page.collections.loading': 'Načítavam záznamy...',
	'page.collections.detail.update.success': 'Záznam bol úspešne upravený',
	'page.collections.detail.loading': 'Načítavam záznam...',
	'page.collections.button.mass.change.container.type': 'Zmeniť typ nádoby',
	'page.collections.button.mass.change.delete': 'Odstrániť označené',
	'page.collections.button.mass.changes': 'Hromadné úpravy',
	'page.collections.button.mass.changes.actions': 'Akcie pre označené záznamy',
	'page.collections.change.container.type.one.time.only': 'Zmeniť typ sa dá iba pre jednorázové nádoby',
	'page.collections.sync.accept': 'Synchronizovať',
	'page.collections.sync.success': 'Vybrané záznamy boli označné pre synchronizáciu',
	'page.collections.sync.title': 'Synchronizovať záznamy',
	'page.collections.sync.message': 'Naozaj chcete znovu synchronivať vybrané záznamy?',
	'page.collections.sync.invalid.only': 'Synchronizovať záznamy je možné iba pre záznamy, ktoré nemajú priradenú nádobu',
	'page.collections.no.collectionRound': 'Bez zvozu',
	// CALENDAR

	'page.calendar.title': 'Kalendár udalostí',
	'page.calendar.breadcrumbs': 'Kalendár udalostí',
	'page.calendar.none': 'Neevidujeme žiadne udalosti',
	'page.calendar.events': 'Udalosti',
	'page.calendar.events.add': 'Pridať udalosť',
	'page.calendar.events.create.success': 'Udalosť bola úspešne pridaná',
	'page.calendar.events.delete.success': 'Udalosť bola úspešne odstránená',
	'page.calendar.events.delete.title': 'Odstrániť udalosť {0}',
	'page.calendar.events.delete.message': 'Naozaj chcete odstrániť túto udalosť?',
	'page.calendar.events.delete.accept': 'Odstrániť',
	'page.calendar.events.detail.loading': 'Načítavam udalosť...',
	'page.calendar.events.update.success': 'Udalosť bola úspešne upravená',

	// CONTAINERS

	'page.containers.breadcrumbs': 'Zberné nádoby',
	'page.containers.remove.message': 'Naozaj chcete odstániť túto nádobu',
	'page.containers.remove.title': 'Odstrániť nádobu {0}',
	'page.containers.remove.accept': 'Odstrániť',
	'page.containers.delete.success': 'Nádoba bola úspešne odstránená',
	'page.containers.loading': 'Načítavam zberné nádoby',

	// DEVICES

	'page.devices.breadcrumbs': 'Zariadenia',
	'page.device.title': 'Správa Zariadení',
	'page.device.add': 'Pridať zariadenie',
	'page.devices.none': ' Neevidujeme žiadne zariadenia.',
	'page.devices.loading': 'Načítavam zariadenia ...',
	'page.device.remove.message': 'Naozaj chcete odstrániť toto zariadenie?',
	'page.device.remove.title': 'Odstrániť zariadenie {0}',
	'page.device.remove.accept': 'Odstrániť',
	'page.device.delete.success': 'Zariadenie bolo úspešne odstránené',
	'page.device.create.success': 'Zariadenie bolo úspešne vytvorené',
	'page.device.TERMINAL': 'Terminál',
	'page.device.DISCOVERY': 'Discovery',
	'page.device.ARCOBEAT': 'Arcobeat',
	'page.device.ARCO40EVO': 'Arco40evo',
	'page.device.RADIUM_DEVICE': 'Radium zariadenie',
	'page.device.GX_SOLUTIONS': 'Gx Solutions',
	'page.device.EXTERNAL_DEVICE': 'Externé zariadenie',
	'page.device.SEPAN_ELTE_GPS': 'ELTE GPS',
	'page.device.breadcrumbs': 'Zariadenia',
	'page.device.update.success': 'Zariadenie bolo úspešne upravené',

	// DASHBOARD

	'page.dashboard.title.welcome': 'Vitajte',
	'page.dashboard.subTitle.inYourEvidenceOfWaste': 'vo vašom konte evidencie odpadov',
	'page.dashboard.counts.title1': 'Celkový počet nádob',
	'page.dashboard.counts.title2': 'Počet zaevidovaných odberov',
	'page.dashboard.counts.title3': 'Celkový počet zberných miest',
	'page.dashboard.cumulativeWeight.title': 'Kumulatívna hmotnosť vyzbieraných odpadov (celkovo)',
	// 'page.dashboard.' : 'Dosiahnutá miera triedenia za predchádzajúce obdobie',
	'page.dashboard.topHouseholds.best.title': 'Top 5 najlepšie triediacich domácností',
	'page.dashboard.topHouseholds.worst.title': 'Top 5 najhoršie triediacich domácností',
	'page.dashboard.progressCollections.title': 'Prehľad zberu odpadov a komodít',
	'page.dashboard.collectionPlaces.title': 'Mapa zberných miest',

	// STATTISTICS
	'page.statistics.breadcrumbs': 'Štatistiky',
	'page.statistics.sorting.municipality': 'Triedenie za obce',
	'page.statistics.sorting.customers': 'Triedenie za platcov',
	'page.statistics.sorting.customers.count': 'Počet platcov podľa miery triedenia',
	'page.statistics.sorting.collection.places': 'Triedenie za domácnosti',
	'page.statistics.sorting.collection.places.count': 'Počet domácností podľa miery triedenia',
	'page.statistics.sorting.collection.places.types': 'Typ zberného miesta',
	'page.statistics.sorting.municipal.weight': 'Hmotnosť ZKO(kg) podľa miery triedenia',
	'page.statistics.loading': 'Načítavam štatistiky...',
	'page.statistics.select.period': 'Vyberte obdobie',
	'page.statistics.period': 'Sledované obdobie',
	'page.statistics.sorting.quantity': 'Naplnenosť nádob podľa zvozov',
	'page.statistics.motivation': 'Motivácia',
	'page.statistics.uncollected': 'Nevyvezené nádoby',
	'page.statistics.uncollected.title': 'Nevyvezené nádoby podľa typu odpadu',
	'page.statistics.motivation.none': 'Neevidujeme žiadne motivačné modely v tomto období',
	'page.statistics.uncollected.collectionRounds': 'Vyhľadajte zvozy',
	'page.statistics.uncollected.wasteTypes': 'Vyhľadajte typy odpadov',
	'page.statistics.uncollected.none': 'Neevidujeme žiadne nevyložené nádoby.',
	'page.statistics.wasteTypes.required': 'Vyberte prosím typy odpadov',
	'page.statistics.weight': 'Vývoj množstva odpadov',
	'page.statistics.no.data': 'Neexistujú žiadne dáta',
	'page.statistics.motivation.model': 'Vyberte motivačný model',
	'page.statistics.motivation.select.municipality': 'Pre motiváciu musíte mať vybratú obec',
	'page.statistics.sorting': 'Triedenie',
	'page.statistics.municipal': 'Komunálny odpad',
	'page.statistics.sorted': 'Triedený odpad',
	'page.statistics.sorting.none': 'Nie sú evidované žiadne dáta',

	'page.quantitativeCollections.breadcrumbs': 'Množstvový zber',

	'pages.customerCodes.assign': 'Priradiť kód',
	'pages.customerCodes.import': 'Importovať kódy',
	'pages.customerCodes.title': 'Kódy platcov',
	'pages.customerCodes.import.title': 'Vyberte súbor, ktorý sa má importovať',
	'pages.customerCodes.import.loading': 'Nahrávam súbor',
	'pages.customerCodes.import.success': 'Súbor bol úspešne nahraný. Po dokončení importu obdržíte email s výsledkom. Môže to trvať aj niekoľko minút',
	'pages.customerCode.customer': 'Platca',
	'pages.customerCode.select.customer': 'Vyberte platcu',
	'pages.customerCodes.assign.title': 'Vyberte platcu, ktorému chcete priradiť kód',
	'pages.customerCodes.assign.success': 'Kód bol úšpešne priradený',
	'pages.customerCodes.expire.success': 'Expirácia bola úspešne nastavená',

	'stats.ZERO': '0%',
	'stats.ONE_TO_FIVE': '1% - 5%',
	'stats.SIX_TO_TEN': '6% - 10%',
	'stats.ELEVEN_TO_FIFTEEN': '11% - 15%',
	'stats.SIXTEEN_TO_TWENTY': '16% - 20%',
	'stats.TWENTY_ONE_AND_MORE': '21+%',

	'stats.ONE_TO_TEN': '1% - 10%',
	'stats.ELEVEN_TO_TWENTY': '11% - 20%',
	'stats.TWENTY_ONE_TO_THIRTY': '21% - 30%',
	'stats.THIRTY_ONE_TO_FIFTY': '31% - 50%',
	'stats.FIFTY_ONE_AND_MORE': '51+%',

	'quantity.label.LESS_THEN_QUARTER': '0% - 24%',
	'quantity.label.LESS_THEN_HALF': '25% - 49%',
	'quantity.label.MORE_THEN_HALF': '50% - 74%',
	'quantity.label.LESS_THEN_FULL': '75% - 99%',
	'quantity.label.FULL_AND_MORE': '100+%',
	'quantity.label.NOT_COLLECTED': 'Nevyložené',

	// WASTE TYPES ENUM

	'waste.type.enum.DOOR_TO_DOOR': 'Od dverí k dverám',
	'waste.type.enum.COLLECTION_YARD': 'Zberný dvor',
	'waste.type.enum.COLLECTION_PLACE': 'Zberné miesto',

	'weight.calculate.type.enum.COEFFICIENT': 'Koeficient',
	'weight.calculate.type.enum.COLLECTION_ROUND_WEIGHT': 'Celková hmotnosť zvozu',

	// COLLECTION YARDS

	'page.collectionYards.wasteTypes.none': 'Žiadny typ odpadu',
	'page.collectionYards.wasteTypes.multiple': 'Viac druhov odpadu',
	'page.collectionYards.title': 'Zoznam záznamov',
	'page.collectionYards.add': 'Pridať záznam',
	'page.collectionYards.breadcrumbs': 'Záznamy',
	'page.collectionYard.remove.message': 'Naozaj chete odstrániť záznam ?',
	'page.collectionYard.remove.title': 'Odstrániť záznam {0}',
	'page.collectionYard.remove.accept': 'Odstrániť',
	'page.collectionYard.remove.success': 'Záznam bol úspešne odstránený',
	'page.collectionYards.create.success': 'Záznam bol úspešne vytvorený',
	'page.collectionYard.add': 'Pridať záznam',
	'page.collectionYards.create.error.collectionLength': 'Záznam musí obsahovať aspoň 1 druh odpadu',
	'page.collectionYard.breadcrumbs': 'Záznam',
	'page.collectionYards.update.success': 'Záznam bol úspešne aktualizovaný',
	'page.collectionYards.none': 'Neevidujeme žiadne záznamy zberného dvora.',

	// CODE PORTIONS

	'page.codePortions.breadcrumbs': 'Kódy',
	'page.codePortions.add': 'Vytvoriť kódy',
	'page.codePortions.none': 'Žiadne dávky kódov sa nenašli.',
	'page.codePortions.loading': 'Načítavam dávky kódov...',
	'pages.codePortions.new': 'Vygenerovať nové kódy',
	'pages.codePortions.addressCodes': 'Adresné kódy',
	'pages.codePortions.anonymousCodes': 'Anonymné kódy',
	'pages.codePortions.print.title': 'Nastaviť stav',
	'pages.codePortions.print.message': 'Naozaj chcete označiť túto dávku ako vytlačenú?',
	'pages.codePortions.download.modal': 'Stiahnuť kódy',
	'pages.codePortions.download.modal.label': 'Stránka dávky kódov',

	'pages.codePortions.export.title': 'Stiahnuť zoznam kódov',
	'pages.codePortions.export.message': 'Vyberte rozsah kódov, ktoré chcete stiahnuť.',

	'pages.codePortions.print.accept': 'Označiť',
	'pages.codePortions.submit.title': 'Vytvoriť kódy?',
	'pages.codePortions.submit.message': 'Potvrdením akcie vytvoríte {0} kódov. Chcete ich vytvoriť?',
	'pages.codePortions.submit.loading': 'Načítavam počet kódov...',
	'pages.codePortions.delete.unable': 'Posledná dávka kódov sa nedá odstrániť, pretože je už vytlačená',
	'pages.codePortions.delete.not.exists': 'Posledná dávka kódov sa nedá odstrániť, pretože už žiadna neexistuje',
	'pages.codePortions.delete.msg': 'Vymazať dávku: {0}, vytvorenú {1}',
	'pages.codePortions.delete.title': 'Vymazať dávku',
	'page.codePortions.delete.success': 'Dávka bola úspešne vymazaná',
	'page.codePortions.delete.invalid': 'Vymazať sa dá iba posledná vytvorená dávka',
	'pages.codePortions.export.with.address': 'S adresou',
	'pages.codePortions.export.without.address': 'Bez adresy',

	'codePortion.state.CREATED': 'Vytvorený',
	'codePortion.state.PRINTED': 'Vytlačený',
	'codePortion.type.ADDRESS': 'Adresné',
	'codePortion.type.ANONYMOUS': 'Anonymné',

	'pages.transferContainers.undelete': 'Odstrániť vymazanie',

	// DATES

	'dateRange.from': 'Od {0}',
	'dateRange.to': 'Do {0}',
	'dateRange.from.to': 'Od {0} do {1}',

	// CONTAINERS TRASNFER

	'pages.containersTransfer.success.title': 'Nádoby boli úspešne presunuté',
	'pages.containersTransfer.undelete.success.title': 'Príznak odstránenia bol zrušený',

	'undelete.table.collections': 'Záznamy',
	'undelete.table.collectionPlaces': 'Zberné miesta',
	'undelete.table.customers': 'Platcovia',
	'undelete.table.containerTypes': 'Typy nádob',
	'undelete.table.municipalityWasteTypes': 'Typy odpadov',
	'undelete.table.containers': 'Zberné nádoby',
	'undelete.table.collectionRounds': 'Zvozy',
	'undelete.table.events': 'Kalendár',

	// COLORS

	'colors.BLUE': 'Modrá',
	'colors.RED': 'Červená',
	'colors.YELLOW': 'Žltá',
	'colors.GREEN': 'Zelená',
	'colors.BROWN': 'Hnedá',
	'colors.BLACK': 'Čierna',
	'colors.GREY': 'Sivá',
	'colors.ORANGE': 'Oranžová',

	// EVENT TYPES

	'event.types.COLLECTION_ROUND': 'Zber',
	'event.types.OTHER': 'Iné',

	// CONTAINER TYPE

	'container.type.ONE_TIME': 'Jednorázová',
	'container.type.PERMANENT': 'Fixná',
	'container.type.UNIVERSAL': 'Univerzálna',
	'container.type.COLLECTION_YARD': 'Zberný dvor',

	// UNSUITABLE CONDITIONS

	'unsuitable.conditions.CONTAMINATED': 'Kontaminované',
	'unsuitable.conditions.INCORRECT_MATERIAL': 'Nesprávný obsah',
	'unsuitable.conditions.UNCOMPRESSED': 'Nestlačené',
	'unsuitable.conditions.DAMAGED_BIN': 'Poškodená nádoba',
	'unsuitable.conditions.MARKER100': 'Neoznačená nádoba',
	'unsuitable.conditions.MARKER200': 'Nadrozmerný odpad',
	'unsuitable.conditions.MARKER300': 'Zablokovaný prístup',
	'unsuitable.conditions.MARKER400': 'Nebezpečný odpad',

	'invalid.state.CONTAINER': 'Nepriradená nádoba',
	'invalid.state.DUPLICITY': 'Duplicitný záznam',
	'invalid.state.WASTE_TYPE': 'Nesprávny druh odpadu',
	'invalid.state.EXPIRED_CONTAINER': 'Expirovaná nádoba',
	'invalid.state.OTHER': 'Iná chyba',

	// NOT FOUND

	'page.not.found.header': 'Oops',
	'page.not.found.message': 'Stránka nebola nájdená. Skúste sa vrátiť na domovskú stránku',

	'calendar.waste': 'Kalendár odpadov:',
	'calendar.otherAction': 'Iná udalosť'
}
