import React, { useState, useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { times } from 'lodash'

import locale from '../../../resources/locale'

import Modal from '../../../components/modals/Modal'

import SelectField from '../../../atoms/SelectField'
import { CODE_PORTION_TYPES } from '../../../utils/enums'
import Button from '../../../components/buttons/Button'

const pageSize = 50000

const DownloadModal = ({ shown, dismissHandler, onDownload, count, type }) => {
	const [page, setPage] = useState(0)

	const options = useMemo(() => {
		const pages = Math.ceil(count / pageSize)

		return times(pages, (page) => {
			const max = (page + 1) * pageSize
			const label = max > count
				? `${page * pageSize} - ${count}`
				: `${page * pageSize} - ${max}`

			return { value: page + 1, label }
		})
	}, [count])

	return (
		<Modal dismiss={dismissHandler} shown={shown} size="l">
			<div className={'form-horizontal'} >
				<div className={'modal-header'}>
					{locale['pages.codePortions.export.title']}
				</div>
				<div className={'modal-content'}>
					<div style={{ margin: '15px 0' }}>
						{locale['pages.codePortions.export.message']}
					</div>
					<SelectField
						showLabel={true}
						label={locale['pages.codePortions.download.modal.label']}
						input={{
							value: page,
							onChange: setPage,
							onBlur: () => {}
						}}
						meta={{
							touched: true
						}}
						options={options}
						isLoading={false}
					/>
				</div>
				<div className={'modal-footer clearfix'}>
					<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
						{type === CODE_PORTION_TYPES.ADDRESS &&
						<>
							<Col>
								<Button
									onClick={() => onDownload(page, pageSize, true)}
								>
									{locale['pages.codePortions.export.with.address']}
								</Button>
							</Col>
							<Col>
								<Button
									onClick={() => onDownload(page, pageSize, false)}
								>
									{locale['pages.codePortions.export.without.address']}
								</Button>
							</Col>
						</>}
						{type === CODE_PORTION_TYPES.ANONYMOUS &&
						<Col>
							<Button
								onClick={() => onDownload(page, pageSize, false)}
							>
								{locale['common.download']}
							</Button>
						</Col>}
						<Col>
							<Button
								className={'secondary'}
								onClick={dismissHandler}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</Modal>
	)
}

DownloadModal.propTypes = {
	onDownload: PropTypes.func.isRequired,
	dismissHandler: PropTypes.func.isRequired,
	shown: PropTypes.bool,
	type: PropTypes.string,
	count: PropTypes.number.isRequired
}

export default DownloadModal
