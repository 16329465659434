import React from 'react'

import { map } from 'lodash'

import { Col, Dropdown, Menu, Row } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

export type Action = {
	icon: React.ReactNode
	title: string
	callback: () => void
}

type Props = {
	actions: Action[]
}

const TableActions = ({ actions }: Props) => (
	<Dropdown
		placement={'bottomRight'}
		overlay={(
			<Menu>
				{map(actions, ({ icon, title, callback }, index) => (
					<Menu.Item key={index}>
						<Row
							onClick={(e) => {
								e.stopPropagation()
								callback()
							}}
							gutter={8}
							justify={'space-between'}
							align={'middle'}
						>
							<Col className={'mo-flex-row mo-flex-align-center'}>
								{icon}
							</Col>
							<Col>
								{title}
							</Col>
						</Row>
					</Menu.Item>
				))}
			</Menu>
		)}
	>
		<MoreOutlined style={{ fontSize: 20 }}/>
	</Dropdown>
)

export default React.memo(TableActions)
