import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { Form, Field, reduxForm } from 'redux-form'
import i18next from 'i18next'

import Modal from './Modal'
import locale from '../../resources/locale'
import SelectField from '../../atoms/SelectField'
import { FORMS } from '../../utils/enums'
import { validate, validatorOptions } from '../../utils/validator'
import Button from '../buttons/Button'

const validateContainerType = validate([validatorOptions.REQUIRED])

class ContainerTypeChangeModal extends React.Component {
	static propTypes = {
		cancelHandler: PropTypes.func.isRequired,
		title: PropTypes.string.isRequired,
		acceptTitle: PropTypes.string,
		cancelTitle: PropTypes.string,
		shown: PropTypes.bool,
		collection: PropTypes.object,
		selectOptions: PropTypes.array,
		handleSubmit: PropTypes.func,
		showError: PropTypes.bool
	}

	containerTypeSelectFieldOptions = {
		label: i18next.t('loc:Typ zbernej nádoby'),
		placeholder: locale['field.placeholder.container.type'],
		required: true,
		showLabel: true
	}

	closeModal = (e) => {
		e.preventDefault()
		this.props.cancelHandler()
	}

	render() {
		const { shown, title, handleSubmit, acceptTitle, cancelTitle, showError } = this.props

		let content

		if (showError) {
			content = (
				<div className={'row'}>
					<div className={'col-12'}>
						{locale['page.collections.change.container.type.one.time.only']}
					</div>
				</div>
			)
		} else {
			content = (
				<Field
					name={'containerTypeID'}
					component={SelectField}
					props={{
						...this.containerTypeSelectFieldOptions,
						options: this.props.selectOptions
					}}
					validate={validateContainerType}
				/>
			)
		}

		return (
			<Modal shown={shown} size="s">
				<Form onSubmit={handleSubmit}>
					<div className={'modal-header'}>
						{title}
					</div>
					<div className={'modal-content'}>
						{content}
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
							<Col>
								<Button
									onClick={this.closeModal}
									className={'secondary'}
								>
									{cancelTitle || locale['dialog.cancel.title']}
								</Button>
							</Col>
							{!showError &&
							<Col>
								<Button>
									{acceptTitle || locale['dialog.accept.title']}
								</Button>
							</Col>}
						</Row>
					</div>
				</Form>
			</Modal>
		)
	}
}

export default reduxForm({
	destroyOnUnmount: true,
	form: FORMS.CHANGE_CONTAINER_TYPE
})(ContainerTypeChangeModal)
