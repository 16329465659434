import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import { WrappedFieldProps } from 'redux-form'

type BaseProps = {
	label?: string
	showLabel?: boolean
}

type Props = WrappedFieldProps & BaseProps

type RawProps = BaseProps & {
	name: string
	checked: any
	onChange: (val: boolean) => void
}

export const CheckBoxRaw = ({ showLabel, name, label, checked, onChange }: RawProps) => (
	<div className="checkbox-wrapper">
		{showLabel && <label htmlFor={name}>{label}</label>}
		<div
			className={cx('checkbox', { active: !!checked })}
			onClick={() => onChange(!checked)}
		>
			<label>{!showLabel && label}</label>
		</div>
	</div>
)

const CheckBoxField: FunctionComponent<Props> = ({
	label,
	showLabel,
	input
}: Props) => (
	<CheckBoxRaw
		showLabel={showLabel}
		label={label}
		name={input.name}
		checked={input.value}
		onChange={input.onChange}
	/>
)

export default CheckBoxField
