import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { reduce, values } from 'lodash'
import { Doughnut } from 'react-chartjs-2'
import locale from '../../resources/locale'
import { StatisticsNoDataPlaceholder } from '../statistics/StatisticsNoDataPlaceholder'

class CollectionPlaceSortingGraph extends Component {
	static propTypes = {
		sortingSummary: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			color: PropTypes.string,
			weight: PropTypes.number.isRequired
		}))
	}

	chartOptions = {
		plugins: {
			datalabels: {
				display: false
			},
			legend: {
				labels: {
					usePointStyle: true
				}
			},
			tooltip: {
				callbacks: {
					title: ([{ label }]) => label,
					footer: ([{ raw }]) => {
						if (typeof raw === 'number') {
							return `${(raw / total * 100).toFixed(2)}%`
						}

						return ''
					},
					label: ({ formattedValue }) => `${formattedValue} kg`
				}
			}
		},
		cutoutPercentage: 55
	}

	render() {
		const { sortingSummary } = this.props

		const isEmptyWeight = reduce(values(sortingSummary), (result, item) => result && item.weight === 0, true)

		let graph

		if (isEmptyWeight) {
			graph = <StatisticsNoDataPlaceholder/>
		} else {
			const weightData = reduce(sortingSummary, (result, value) => ({
				datasets: [{
					pointStyle: 'circle',
					data: [...result.datasets[0].data, `${value.weight}`],
					backgroundColor: [...result.datasets[0].backgroundColor, `#${value.color}`]
				}],
				labels: [...result.labels, `${value.name}`]
			}), { datasets: [{ data: [], backgroundColor: [], borderColor: [], borderWidth: [] }], labels: [] })

			graph = <div style={{ display: 'flex' }}>
				<div style={{ maxWidth: '250px', minWidth: '230px', height: '250px', flex: 1 }}>
					<Doughnut
						data={weightData}
						options={this.chartOptions}
						width={250}
						height={250}
					/>
				</div>
			</div>
		}

		return (
			<div className={'chart-wrapper'} style={{ paddingTop: 0 }}>
				<div className={'chart-title collection-place'}>
					<span>{locale['page.containers.sorting.title']}</span>
				</div>
				{graph}
			</div>
		)
	}
}

export default CollectionPlaceSortingGraph
