import i18next from 'i18next'
import { ValidationErrors } from 'final-form'

import { FormValues } from '../LoginPage'

import { emailRegex } from '../../../utils/regex'

export default (values: FormValues) => {
	const errors: ValidationErrors = {}

	if (!values.email) {
		errors.email = i18next.t('errors.required')
	} else if (!emailRegex.test(values.email)) {
		errors.email = i18next.t('errors.emailFormat')
	}

	if (!values.password) {
		errors.password = i18next.t('errors.required')
	}

	return errors
}
