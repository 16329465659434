import React from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import { map, get, reduce } from 'lodash'

import PopupTool from '../../atoms/PopupTool'
import { columnPropTypes } from './Table'

const getColumnData = (column, data) => {
	if (column.dataNames && column.dataNames.length) {
		return reduce(column.dataNames, (result, item) => ({
			...result,
			[item]: data[item]
		}), {})
	}

	return get(data, column.name)
}

const TableRow = ({ onDetail, data, columns, countIndex, getActions, isSelectable, isSelected, onSelect }) => {
	const tableColumns = map(columns, (column) => (
		<td
			key={column.name}
			style={column.style}
			className={cx('regular', column.className)}
		>
			{column.render ? column.render(getColumnData(column, data), data) : getColumnData(column, data) || '-'}
		</td>
	))

	return (
		<tr onClick={() => onDetail && onDetail(data)}>
			{isSelectable && <td style={{ width: '30px' }}>
				<div className="checkbox-wrapper">
					<div
						className={cx('checkbox', { active: isSelected })}
						onClick={(e) => {
							e.stopPropagation()
							onSelect([data])
						}}
					/>
				</div>
			</td>}
			{tableColumns}
			<td className={'action'} style={{ zIndex: countIndex }}>
				<PopupTool
					items={getActions ? () => getActions(data) : []}
				/>
			</td>
		</tr>
	)
}

TableRow.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape(columnPropTypes)).isRequired,
	isSelectable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	data: PropTypes.object.isRequired,
	onDetail: PropTypes.func,
	onSelect: PropTypes.func,
	getActions: PropTypes.func,
	countIndex: PropTypes.number.isRequired
}

export default TableRow
