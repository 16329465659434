import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get, debounce } from 'lodash'
import { Col, Row } from 'antd'
import i18next from 'i18next'

// resources

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

// components
import CollectionPlaceCustomerTableRow from './CollectionPlaceCustomerTableRow'
import TableHead from '../../atoms/TableHead'
import Pagination from '../../atoms/Pagination'
import SearchInputField from '../../atoms/SearchInputField'
import { EmptyTableState } from '../EmptyTableState'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import Button from '../buttons/Button'

class CollectionPlaceCustomersTable extends React.Component {
	static propTypes = {
		customers: PropTypes.arrayOf(PropTypes.object),
		context: PropTypes.object,
		error: PropTypes.string,
		search: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onPage: PropTypes.func.isRequired,
		onSet: PropTypes.func.isRequired,
		onCreateCustomer: PropTypes.func.isRequired,
		onSearch: PropTypes.func.isRequired,
		onEdit: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		onDetail: PropTypes.func,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string,
		limit: PropTypes.number.isRequired,
		onLimit: PropTypes.func.isRequired
	}

	render() {
		const {
			customers, onDetail, context, search, loading, onSet, onSort, onSearch, onPage, onEdit, onDelete, sortingColumn, sortingDirection, onCreateCustomer, limit, onLimit
		} = this.props
		const rows = map(customers, (customer, index) =>
			<CollectionPlaceCustomerTableRow
				onDetail={onDetail}
				key={get(customer, 'id')}
				customer={customer}
				onEdit={onEdit}
				onDelete={onDelete}
				countIndex={index}
			/>)
		return (
			<div className={'box'}>
				<div className={'box-content'}>
					<div className={'box-head'}>
						<Row gutter={16}>
							<Col>
								<SearchInputField
									name={'search'}
									onChange={debounce(onSearch, 300)}
									defaultValue={search || ''}
								/>
							</Col>
							<Col flex={1}/>
							<Col>
								<Button
									onClick={onCreateCustomer}
									style={{ marginRight: '5px' }}
								>
									<span>{locale['page.collectionPlaces.detail.customers.create']}</span>
								</Button>
							</Col>
							<Col>
								<Button
									onClick={onSet}
								>
									<span>{locale['page.collectionPlaces.detail.customers.set']}</span>
								</Button>
							</Col>
						</Row>
					</div>
					<div className={'box-body'}>
						<div className={'table-wrapper'}>
							<table>
								<thead>
									<tr>
										<TableHead
											name={'name'}
											title={i18next.t('loc:Meno')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'name'}
										/>
										<TableHead
											name={'membersCount'}
											title={i18next.t('loc:Počet členov domácnosti')}
											className={'text-center'}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'membersCount'}
										/>
										<TableHead
											name={'activeFrom'}
											title={i18next.t('loc:Aktívny od')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'activeFrom'}
										/>
										<TableHead
											name={'activeTo'}
											title={i18next.t('loc:Aktívny do')}
											onSort={onSort}
											initDirection={sortingDirection}
											isSorting={sortingColumn === 'activeTo'}
										/>
										<th/>
									</tr>
								</thead>
								<tbody>
									{rows}
								</tbody>
							</table>
							{!loading && customers && !customers.length && <EmptyTableState title={locale['page.customers.none']}/>}
							<div className={cx('table-loading', { hidden: !loading })}>
								<div>
									<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
									<span>{locale['page.customers.loading']}</span>
								</div>
							</div>
						</div>
						<ListItemsLimit
							limit={limit}
							onLimit={onLimit}
						/>
						<Pagination
							pages={get(context, 'pages')}
							page={get(context, 'page')}
							onPage={onPage}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default CollectionPlaceCustomersTable
