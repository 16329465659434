import React from 'react'
import i18next from 'i18next'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../../resources/locale'

// components
import CollectionRoundTableRow from './CollectionRoundTableRow'

// atoms
import TableHead from '../../../atoms/TableHead'
import { EmptyTableState } from '../../../components/EmptyTableState'

class CollectionRoundsTable extends React.Component {
	static propTypes = {
		collectionRounds: PropTypes.arrayOf(PropTypes.object),
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string
	}

	render() {
		const { collectionRounds, loading, onDelete, onDetail, onSort, sortingColumn, sortingDirection } = this.props
		const rows = map(collectionRounds, (collectionRound, index) =>
			<CollectionRoundTableRow
				key={get(collectionRound, 'id')}
				collectionRound={collectionRound}
				onDetail={onDetail}
				onDelete={onDelete}
				countIndex={index}
			/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<th/>
							<TableHead
								name={'name'}
								isSorting={sortingColumn === 'name'}
								title={i18next.t('loc:Dátum a názov')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'wasteType'}
								isSorting={sortingColumn === 'wasteType'}
								title={i18next.t('loc:Druh odpadu')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'startDatetime'}
								isSorting={sortingColumn === 'startDatetime'}
								title={i18next.t('loc:Začiatok')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'stopDatetime'}
								isSorting={sortingColumn === 'stopDatetime'}
								title={i18next.t('loc:Koniec')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'collectionsCount'}
								isSorting={sortingColumn === 'collectionsCount'}
								title={i18next.t('loc:Počet záznamov')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'realWeight'}
								isSorting={sortingColumn === 'realWeight'}
								title={i18next.t('loc:Celková hmotnosť')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'totalWeight'}
								isSorting={sortingColumn === 'totalWeight'}
								title={i18next.t('loc:Fakturovaná hmotnosť')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && collectionRounds && !collectionRounds.length && <EmptyTableState title={locale['page.collectionRounds.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.collectionRounds.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default CollectionRoundsTable
