import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { MunicipalityBase } from '../../../types/data'
import { toggleMenuShown } from '../../../actions/menuActions'

type Props = PropsWithChildren<{
	municipality: MunicipalityBase | null
	basePath: string
}>

const Link = styled.a`

`

const MenuLink = ({ children, basePath, municipality }: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const href = municipality ? t(`${basePath}.municipalityPath`, { municipalityID: municipality.id }) : t(`${basePath}.path`)

	return (
		<Link onClick={(e) => {
			e.preventDefault()
			dispatch(toggleMenuShown(false))
		}} href={href}>{children}</Link>
	)
}

export default MenuLink
