import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'

class MunicipalitiesTableRow extends React.Component {
	static propTypes = {
		municipality: PropTypes.object.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		countIndex: PropTypes.number
	}

	render() {
		const { municipality, onSetActive, onDetail, countIndex } = this.props
		return (
			<tr key={municipality.id} onClick={() => onDetail(municipality)}>
				<td>{municipality.name}</td>
				<td>{locale[`municipality.type.${municipality.type}`]}</td>
				<td>{municipality.usersCount}</td>
				<td>{municipality.collectionRoundsCount}</td>
				<td>{municipality.lastCollectionRound ? moment(municipality.lastCollectionRound).format(locale['common.date.format.date']) : '-'}</td>
				<td className={'text-center icon'} style={{ color: municipality.useGeneralCollectionRound ? '#AFCA0B' : '#F03E41' }}>
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${municipality.id}-general-collection-round`} icon={municipality.useGeneralCollectionRound ? faCheckCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${municipality.id}-general-collection-round`} place={'left'} effect={'solid'}>
						<span>
							{municipality.useGeneralCollectionRound ? locale['page.municipalities.useGeneralCollectionRound'] : locale['page.municipalities.useGeneralCollectionRound.inactive']}
						</span>
					</ReactTooltip>
				</td>
				<td className={'text-center icon'} style={{ color: municipality.isActive ? '#AFCA0B' : '#F03E41' }}>
					<FontAwesomeIcon data-tip data-for={`state-tooltip-${municipality.id}`} icon={municipality.isActive ? faCheckCircle : faTimesCircle}/>
					<ReactTooltip id={`state-tooltip-${municipality.id}`} place={'left'} effect={'solid'}>
						<span>
							{municipality.isActive ? locale['page.municipalities.active.tooltip'] : locale['page.municipalities.inactive.tooltip']}
						</span>
					</ReactTooltip>
				</td>
				<td className={'action'} style={{ zIndex: `${countIndex}` }}>
					<PopupTool
						items={[{
							key: 'common.delete',
							title: municipality.isActive ? locale['common.deactivate'] : locale['common.activate'],
							icon: faPowerOff,
							callback: () => onSetActive(municipality)
						}]}
					/>
				</td>
			</tr>
		)
	}
}

export default MunicipalitiesTableRow
