import React, { CSSProperties, useCallback } from 'react'
import cx from 'classnames'

import { FieldRenderProps } from 'react-final-form'

import { Slider } from 'antd'

import { Info, InputWrapper, Label } from './Styled'
import theme from '../../utils/theme'

type Props = FieldRenderProps<number> & {
	label?: string
	required?: boolean
	className?: string
	disabled?: boolean

	step?: number | null
	dots?: boolean
	marks: {
		[value: number]: React.ReactNode | { style?: CSSProperties, label: React.ReactNode }
	}

	min: number
	max: number
}

const SliderField = ({ meta, input, label, required, className, ...rest }: Props) => {
	const error = meta.touched && meta.error

	const handleChange = useCallback((value: string | number) => {
		input.onBlur()
		input.onChange(value)
	}, [input])

	const componentClass = cx(className, { error })

	return (
		<InputWrapper className={className} style={{ height: 99 }}>
			<Label>
				{label}{required ? '*' : ''}
			</Label>
			<Slider
				{...input}
				{...rest}
				trackStyle={{ backgroundColor: theme.colors.primary['500'] }}
				handleStyle={{ borderColor: theme.colors.primary['500'] }}
				className={className}
				onChange={handleChange}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default SliderField
