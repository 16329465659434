import { RESET_STORE } from '../types/global'

export const CODE_PORTIONS_ACTIONS = {
	PORTIONS_LOAD_START: 'PORTIONS_LOAD_START',
	PORTIONS_LOAD_DONE: 'PORTIONS_LOAD_DONE',
	PORTIONS_LOAD_FAIL: 'PORTIONS_LOAD_FAIL',

	PORTION_LOAD_START: 'PORTION_LOAD_START',
	PORTION_LOAD_DONE: 'PORTION_LOAD_DONE',
	PORTION_LOAD_FAIL: 'PORTION_LOAD_FAIL',

	CODES_LOAD_START: 'CODES_LOAD_START',
	CODES_LOAD_DONE: 'CODES_LOAD_DONE',
	CODES_LOAD_FAIL: 'CODES_LOAD_FAIL'
}

export const initState = {
	portions: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	portion: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	codes: {
		data: null,
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_START:
			return {
				...state,
				portions: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_DONE:
			return {
				...state,
				portions: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_FAIL:
			return {
				...state,
				portions: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case CODE_PORTIONS_ACTIONS.PORTION_LOAD_START:
			return {
				...state,
				portion: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.PORTION_LOAD_DONE:
			return {
				...state,
				portion: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.PORTION_LOAD_FAIL:
			return {
				...state,
				portion: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case CODE_PORTIONS_ACTIONS.CODES_LOAD_START:
			return {
				...state,
				codes: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.CODES_LOAD_DONE:
			return {
				...state,
				codes: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				}
			}
		case CODE_PORTIONS_ACTIONS.CODES_LOAD_FAIL:
			return {
				...state,
				codes: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return { ...state }
	}
}
