import { postReq } from '../utils/request'

import { Response } from '../types/api'
import { Profile } from '../types/data'

export default {
	loginUser: (data: object): Response<{ profile: Profile, token: string }> => postReq('/api/v0/login', {}, data),
	forgotPassword: (data: object) => postReq('/api/v0/forgot-password', {}, data),
	resetPassword: (data: object, headers: object) => postReq('/api/v0/forgot-password/confirm', {}, data, headers),
	completeRegistration: (data: object, headers: object) => postReq('/api/v0/users/confirm', {}, data, headers),
	contactAdmin: (data: object) => postReq('/api/v0/users/confirm/contact', {}, data)
}
