import React from 'react'
import * as PropTypes from 'prop-types'
import ReactQueryParams from 'react-query-params'
import moment from 'moment-timezone'
import qs from 'qs'

import { get, range, map, isEqual, head, last, forEach, trim } from 'lodash'
import { connect } from 'react-redux'
import { reset, initialize } from 'redux-form'
import { bindActionCreators } from 'redux'

import CalendarView from '../../components/calendar/CalendarView'

import { ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO, MODAL, MUNICIPALITY_TYPE } from '../../utils/enums'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import { parseNumber } from '../../utils/utils'
import locale from '../../resources/locale'
import Breadcrumbs from '../../components/Breadcrumb'
import { deleteReq, getReq, postReq } from '../../utils/request'
import CalendarTable from '../../components/calendar/CalendarTable'
import CreateEvent from '../../components/modals/CreateEvent'
import { statusPush } from '../../actions/statusActions'
import { loadMunicipalityWasteTypes } from '../../actions/municipalityActions'
import DialogModal from '../../components/modals/DialogModal'
import { history } from '../../utils/history'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	selectedDate: 'selectedDate',
	visibleDate: 'visibleDate'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'date:ASC',
		allowedValues: ['date', 'type', 'wasteType', 'isDoorToDoor', 'isPublic', 'isVisibleForStatistics']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	visibleDate: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: new Date().valueOf()
	},
	selectedDate: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: new Date().valueOf()
	}
}

class CalendarPage extends ReactQueryParams {
	static propTypes = {
		selectedFiltersOrders: PropTypes.object,
		municipality: PropTypes.object
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.selectedDate]: null,
			[queryParams.visibleDate]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			events: {
				data: null,
				isLoading: false,
				isFailure: false
			},
			dayEvents: {
				data: null
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { municipality, selectedFiltersOrdersChanged, selectedFiltersOrders, loadMunicipalityWasteTypes } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// Remove unwanted query params from query string
		const query = qs.stringify(filtersOrder)
		history.replace(history.location.pathname, { search: query })

		if (municipality) {
			loadMunicipalityWasteTypes()
		}

		this.setupCalendarDate(filtersOrder)
		this.setupDayEvents(filtersOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CALENDAR_PAGE, filtersOrder)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (filters) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		const filterOrder = { ...selectedFiltersOrders }
		forEach(filters, ({ type, value }) => {
			let newFilterOrder
			switch (type) {
				case queryParams.order:
					newFilterOrder = `${value.name}:${value.sort}`
					break
				case queryParams.page:
				case queryParams.selectedDate:
				case queryParams.visibleDate:
				case queryParams.search:
					newFilterOrder = (value) ? `${value}` : null
					break
				default:
					break
			}
			filterOrder[type] = newFilterOrder
			if (type === queryParams.search) {
				filterOrder[queryParams.page] = 1
			}
		})

		const normalized = normalizeQueryParams(queryTypes, { ...filterOrder })
		this.setQueryParams(normalized)

		const types = map(filters, (item) => item.type)
		if (types.indexOf(queryParams.visibleDate) >= 0) {
			this.setupCalendarDate(normalized)
		}
		if (types.indexOf(queryParams.visibleDate) === -1 || types.length > 1) {
			this.setupDayEvents(normalized)
		}
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CALENDAR_PAGE, normalized)
	}

	setupCalendarDate = async (filterOrder) => {
		const { municipality } = this.props
		const visibleDate = moment(parseNumber(filterOrder.visibleDate) || filterOrder.visibleDate)
		const fromDate = moment(visibleDate).startOf('month').startOf('week').startOf('day').toISOString()
		const toDate = moment(visibleDate).endOf('month').endOf('week').endOf('day').toISOString()
		const context = {
			fromDate,
			toDate
		}
		if (municipality) {
			context.municipalityID = municipality.id
		}
		this.loadEvents('events', context, ENDPOINTS.CALENDAR)
	}

	setupDayEvents = async (filterOrder) => {
		const { municipality } = this.props
		const selectedDate = moment(parseNumber(filterOrder.selectedDate) || filterOrder.selectedDate)
		const fromDate = moment(selectedDate).startOf('day').toISOString()
		const toDate = moment(selectedDate).endOf('day').toISOString()
		const context = {
			fromDate,
			toDate,
			order: filterOrder.order,
			search: filterOrder.search,
			page: filterOrder.page
		}
		if (municipality) {
			context.municipalityID = municipality.id
		}

		this.loadEvents('dayEvents', context, ENDPOINTS.CALENDAR_EVENTS)
	}

	loadEvents = async (type, query, endpoint) => {
		this.setState({
			[type]: {
				isLoading: true,
				isFailure: false,
				data: null
			}
		})
		try {
			const response = await getReq(endpoint, query)
			const context = get(response, 'data.context', {})
			if (query.page > 1 && query.page > context.pages) {
				this.onChangeFilterOrder(queryParams.page, 1)
			} else {
				this.setState({
					...this.state,
					[type]: {
						isLoading: false,
						isFailure: false,
						data: get(response, 'data')
					}
				})
			}
		} catch (error) {
			this.setState({
				...this.state,
				[type]: {
					isLoading: false,
					isFailure: true,
					data: null
				}
			})
		}
	}

	selectCalendarDay = (day) => {
		const { selectedFiltersOrders } = this.props
		const selectedDate = {
			date: day.day,
			month: day.month,
			year: day.year
		}
		const date = moment().set(selectedDate)
		const filters = [{ type: queryParams.selectedDate, value: date.valueOf() }]
		const visibleDate = moment(parseNumber(get(selectedFiltersOrders, queryParams.visibleDate)))
		if (visibleDate.month() !== selectedDate.month && visibleDate.month() !== selectedDate.month) {
			filters.push({
				type: queryParams.visibleDate,
				value: date.valueOf()
			})
		}
		this.onChangeFilterOrder(filters)
	}

	setEventRemoved = async (event) => {
		const { selectedFiltersOrders, pushStatus } = this.props
		try {
			this.dismissModal()
			await deleteReq(ENDPOINTS.MUNICIPALITY_CALENDAR_EVENT(event.municipalityID, event.id), null, null)
			pushStatus({
				type: INFO,
				msg: locale['page.calendar.events.delete.success']
			})
			this.setupCalendarDate(selectedFiltersOrders)
			this.setupDayEvents(selectedFiltersOrders)
		} catch (error) {
			// Error
		}
	}

	openEventDetail = (event) => {
		const { municipality } = this.props
		if (municipality) {
			history.push(locale.formatString(locale['path.municipality.calendar.event'], municipality.id, event.id))
		} else {
			history.push(locale.formatString(locale['path.lists.calendar.event'], event.id))
		}
	}

	createEvent = async (values) => {
		const { municipality, pushStatus, reset } = this.props
		if (municipality) {
			try {
				const data = {
					...values,
					streets: map(values.streets, (item) => item.value),
					useUnsuitableConditions: values.useUnsuitableConditions || false,
					useQuantity: values.useQuantity || false,
					isDoorToDoor: values.isDoorToDoor || false,
					isPublic: values.isPublic || false,
					isVisibleForStatistics: values.isVisibleForStatistics || false,
					municipalities: municipality.type === MUNICIPALITY_TYPE.WASTE_COMPANY ? map(values.municipalities, (item) => item.value) : undefined
				}
				const response = await postReq(ENDPOINTS.MUNICIPALITY_CALENDAR_EVENTS(municipality.id), null, data)
				this.dismissModal()
				reset(FORMS.CREATE_EVENT)
				pushStatus({
					type: INFO,
					msg: locale['page.calendar.events.create.success']
				})
				const date = moment(get(response, 'data.date'))
				this.onChangeFilterOrder([{
					type: queryParams.visibleDate,
					value: date.valueOf()
				}, {
					type: queryParams.selectedDate,
					value: date.valueOf()
				}])
			} catch (error) {
				// Error
			}
		}
	}

	getMonthDays = (date) => {
		const startDate = moment(date).startOf('month').startOf('isoWeek')
		const endDate = moment(date).endOf('month').endOf('isoWeek')
		const difference = endDate.diff(startDate, 'days') + 1

		return map(range(difference), (item, index) => {
			const date = moment(startDate).add(index, 'd')
			return {
				day: date.date(),
				month: date.month(),
				year: date.year(),
				week: date.isoWeek()
			}
		})
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return {
			items: [...items, {
				key: 'page.lists.breadcrumbs',
				name: locale['page.lists.breadcrumbs']
			}, {
				key: 'page.calendar.breadcrumbs',
				name: locale['page.calendar.breadcrumbs']
			}]
		}
	}

	openCreateModal = (date) => {
		const { municipality } = this.props
		if (municipality) {
			if (date) {
				const { initialize, reset } = this.props
				initialize(FORMS.CREATE_EVENT, {
					date: date.toDate()
				})
				reset(FORMS.CREATE_EVENT)
			}
			this.openModal(MODAL.CREATE_EVENT)()
		}
	}

	render() {
		const { selectedFiltersOrders, municipality } = this.props
		const visibleDate = moment(parseNumber(selectedFiltersOrders.visibleDate) || selectedFiltersOrders.visibleDate)
		const selectedDate = moment(parseNumber(selectedFiltersOrders.selectedDate) || selectedFiltersOrders.selectedDate)

		const days = this.getMonthDays(visibleDate)
		const selectedDay = {
			day: selectedDate.date(),
			month: selectedDate.month(),
			year: selectedDate.year(),
			week: selectedDate.isoWeek()
		}

		const month = visibleDate.month()
		const year = visibleDate.year()

		const { events, dayEvents, modal, modalData } = this.state

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		const exportContext = {
			...selectedFiltersOrders,
			fromDate: visibleDate.startOf('month').toISOString(),
			toDate: visibleDate.endOf('month').toISOString()
		}
		if (municipality) {
			exportContext.municipalityID = municipality.id
		}

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems()}
				/>
				<CalendarView
					days={days}
					munixipality={municipality}
					selectedDay={selectedDay}
					onCreate={this.openCreateModal}
					month={month}
					year={year}
					events={get(events, 'data.events')}
					onSelectNextMonth={() => this.onChangeFilterOrder([{
						type: queryParams.visibleDate,
						value: moment(visibleDate).add(1, 'M').valueOf()
					}])}
					onSelectPreviousMonth={() => this.onChangeFilterOrder([{
						type: queryParams.visibleDate,
						value: moment(visibleDate).subtract(1, 'M').valueOf()
					}])}
					onSelect={this.selectCalendarDay}
				/>
				<CalendarTable
					sortingColumn={sortingColumn}
					sortingDirection={sortingDirection}
					search={selectedFiltersOrders.search}
					events={get(dayEvents, 'data.events')}
					context={get(dayEvents, 'data.context')}
					loading={get(dayEvents, 'isLoading', false)}
					onSort={(value) => this.onChangeFilterOrder([{ type: queryParams.order, value }])}
					onSearch={(value) => this.onChangeFilterOrder([{ type: queryParams.search, value: trim(value) }])}
					onPage={(value) => this.onChangeFilterOrder([{ type: queryParams.page, value }])}
					onDetail={this.openEventDetail}
					onDelete={this.openModal(MODAL.REMOVE_EVENT)}
					municipality={municipality}
					exportContext={exportContext}
				/>
				<CreateEvent
					shown={modal === MODAL.CREATE_EVENT}
					dismissHandler={this.dismissModal}
					createHandler={this.createEvent}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_EVENT}
					cancelHandler={this.dismissModal}
					acceptHandler={() => this.setEventRemoved(modalData)}
					message={locale['page.calendar.events.delete.message']}
					title={locale.formatString(locale['page.calendar.events.delete.title'], modalData && modalData.type ? locale[`event.types.${modalData.type}`] : '')}
					acceptTitle={locale['page.calendar.events.delete.accept']}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CALENDAR_PAGE],
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	reset: bindActionCreators(reset, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	loadMunicipalityWasteTypes: bindActionCreators(loadMunicipalityWasteTypes, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage)
