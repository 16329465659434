import { getReq, patchReq } from '../utils/request'
import { ListResponse, Response } from '../types/api'
import { Container } from '../types/data'

export default {
	loadContainers: (filters: object): ListResponse<{ containers: Container[] }> => getReq('/api/v1/containers', filters),
	loadContainer: (id: string | number): Response<Container> => getReq(`/api/v1/containers/${id}`, {}),
	loadContainerCodesCount: (from: string, to: string): Response<{ count: number, error: string }> => getReq(`/api/v1/containers/codes-count/from/${from}/to/${to}`, {}),
	transferContainer: (data: object) => patchReq('/api/admin/containers/transfer', {}, data)
}
