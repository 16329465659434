import React from 'react'
import * as PropTypes from 'prop-types'
import { get } from 'lodash'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from 'antd'
import i18next from 'i18next'

import locale from '../../../resources/locale'
import PopupTool from '../../../atoms/PopupTool'
import { formatDatetime } from '../../../utils/utils'
import { COLLECTION_ROUND_STATE, COLLECTION_ROUND_TYPE, DEFAULT_WASTE_TYPE_COLOR } from '../../../utils/enums'

import AutoIcon from '../../../resources/images/ic-automatic-round.svg'

class CollectionRoundTableRow extends React.Component {
	static propTypes = {
		collectionRound: PropTypes.object.isRequired,
		onDetail: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
		countIndex: PropTypes.number
	}

	renderPopup = () => {
		const { collectionRound, onDelete } = this.props

		if (collectionRound.type === COLLECTION_ROUND_TYPE.STANDARD) {
			return (
				<PopupTool
					items={[{
						key: 'common.delete',
						title: locale['common.delete'],
						icon: faTrashAlt,
						callback: () => onDelete(collectionRound)
					}]}
				/>
			)
		}

		return undefined
	}

	render() {
		const { collectionRound, onDetail, countIndex } = this.props
		return (
			<tr onClick={() => onDetail(collectionRound)} style={{ height: '72px' }}>
				<td>
					{collectionRound.state === COLLECTION_ROUND_STATE.WAITING_FOR_APPROVAL &&
					<Tooltip
						overlay={i18next.t('CollectionRoundsPage.waitingForApproval')}
					>
						<img src={AutoIcon} alt={'Auto'}/>
					</Tooltip>}
				</td>
				<td>{get(collectionRound, 'name', '-')}</td>
				<td>
					<div
						className={'dot'}
						style={{ backgroundColor: `#${get(collectionRound, 'wasteType.color', DEFAULT_WASTE_TYPE_COLOR)}` }}
					/>
					{get(collectionRound, 'wasteType.name', '-')}
				</td>
				<td>{formatDatetime(get(collectionRound, 'startDatetime'))}</td>
				<td>{formatDatetime(get(collectionRound, 'stopDatetime'))}</td>
				<td>{get(collectionRound, 'collectionsCount', 0)}</td>
				<td>{
					collectionRound.type === COLLECTION_ROUND_TYPE.GENERAL ?
						'-' :
						get(collectionRound, 'realWeight', 0).toFixed(2)
				}</td>
				<td>{
					get(collectionRound, 'totalWeight', 0).toFixed(2)
				}</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					{this.renderPopup()}
				</td>
			</tr>
		)
	}
}

export default CollectionRoundTableRow
