import React, { useCallback, useEffect, useMemo, useState } from 'react'
import arrayMutators from 'final-form-arrays'

import { map, replace } from 'lodash'
import { createForm, FormApi } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Col, Row } from 'antd'

import { getMessages } from '../../../utils/helpers'

import { statusPush } from '../../../actions/statusActions'
import { setLastCollectionYard } from '../../../actions/municipalityActions'
import { getLastCollectionYard } from '../../../redux/municipalities/selectors'

import { bulkCollections } from '../../../api'

import Button from '../../../components/buttons/Button'
import { Modal, ModalContent, ModalFooter, ModalHead } from '../../../components/Modal'

import BulkCollectionForm, { defaultValues, FormValues } from './BulkCollectionForm'

type Props = {
	municipalityID: string | number
	visible: boolean
	onClose: (refresh: boolean) => void
}

const BulkCollectionModal = ({ municipalityID, visible, onClose }: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const collectionYard = useSelector(getLastCollectionYard)
	const [submitting, setSubmitting] = useState(false)

	const handleClose = useCallback((refresh: any) => {
		onClose(refresh === true)
	}, [onClose])

	const handleSubmit = useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
		try {
			setSubmitting(true)

			const submitData = {
				scanDatetime: values.scanDatetime,
				collectionYardID: values.collectionYard?.value,
				customerID: values.customer?.value,
				collections: map(values.collections, (collection) => ({
					containerID: collection.container?.value,
					weight: replace(`${collection?.weight || 0}`, ',', '.')
				}))
			}

			const response = await bulkCollections.createBulkCollection(municipalityID, submitData)

			const { messages } = getMessages(response)

			handleClose(true)

			form.restart({ ...defaultValues, collectionYard: values.collectionYard })

			dispatch(statusPush(messages[0]))
			dispatch(setLastCollectionYard(values.collectionYard))

			return Promise.resolve()
		} catch (e: any) {
			const { messages } = getMessages(e.response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve({})
		} finally {
			setSubmitting(false)
		}
	}, [municipalityID, handleClose, dispatch])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit,
		mutators: { ...arrayMutators }
	}), [handleSubmit])

	useEffect(() => {
		if (visible) {
			form.restart({ ...defaultValues, collectionYard })
		}
	}, [collectionYard, form, visible])

	return (
		<Modal
			width={600}
			onCancel={handleClose}
			visible={visible}
			footer={null}
		>
			<>
				<ModalHead>{t('BulkCollectionModal.title')}</ModalHead>
				<ModalContent>
					<BulkCollectionForm key={`${visible}`} municipalityID={municipalityID} form={form} modal={true}/>
				</ModalContent>
				<ModalFooter>
					<Row justify={'end'} gutter={[16, 8]}>
						<Col>
							<Button
								className={'secondary'}
								onClick={(e) => {
									e.preventDefault()
									onClose(false)
								}}
							>
								{t('common.close')}
							</Button>
						</Col>
						<Col>
							<Button
								loading={submitting}
								disabled={submitting}
								onClick={form.submit}
							>
								{t('BulkCollectionModal.create')}
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</>
		</Modal>
	)
}

export default React.memo(BulkCollectionModal)
