import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, FieldArray, reduxForm, unregisterField, reset, formValueSelector, change } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce, get, forEach, map } from 'lodash'
import dayjs from 'dayjs'
import { Col, Row } from 'antd'
import i18next from 'i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { validate, validatorOptions } from '../../utils/validator'

import { statusPush } from '../../actions/statusActions'

import { ASSIGN_TYPE } from '../../types/enums'
import {
	ANONYMOUS,
	COLLECTION_PLACE_TYPE, CUSTOMER_LEGAL_FORM,
	CUSTOMER_MAX_MEMBERS_COUNT,
	ENDPOINTS,
	FORMS,
	MODAL
} from '../../utils/enums'
import { getReq } from '../../utils/request'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import locale from '../../resources/locale'
import { EmptyTableState } from '../EmptyTableState'
import CollectionPlaceFormFields from './CollectionPlaceFormFields'
import NumericInputField from '../../atoms/NumericInputField'
import Button from '../buttons/Button'
import CollectionPlaceContainerModal from '../../pages/collectionPlaces/components/CollectionPlaceContainerModal'

const validateNumber = validate([validatorOptions.REQUIRED_NUMBER])

const showCustomerTypes = [COLLECTION_PLACE_TYPE.FAMILY_HOUSE, COLLECTION_PLACE_TYPE.FLAT, COLLECTION_PLACE_TYPE.OTHER, COLLECTION_PLACE_TYPE.COTTAGE]

class CollectionPlaceCreateForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		customerCreateHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		pushStatus: PropTypes.func.isRequired,
		unregisterField: PropTypes.func.isRequired,
		municipality: PropTypes.object.isRequired,
		customer: PropTypes.object,
		type: PropTypes.string,
		array: PropTypes.object.isRequired,
		resetForm: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			modal: null
		}
	}

	anonymousCustomer = {
		label: locale['page.collectionPlaces.detail.customer.anonymous'],
		value: ANONYMOUS,
		key: ANONYMOUS
	}

	loadCustomers = (search, callback) => {
		const { municipality } = this.props
		const context = {
			order: 'name:ASC',
			municipalityID: municipality.id,
			exclude: 'ANONYMOUS'
		}
		if (search && search.length) {
			context.search = search
		}
		getReq(ENDPOINTS.CUSTOMERS, context).then((response) => {
			const items = map(response.data.customers, (item) => ({
				label: `${item.name}, ${item.address}`,
				value: item.id,
				key: item.id,
				item
			}))
			return callback([this.anonymousCustomer, ...items])
		}).catch(() => callback([]))
	}

	customerFieldOptions = {
		label: i18next.t('loc:Platca'),
		required: true,
		showLabel: true,
		loadOptions: debounce(this.loadCustomers, 300)
	}

	membersCountFieldOptions = {
		label: i18next.t('loc:Počet členov domácnosti'),
		min: 1,
		max: 19,
		required: true
	}

	validateMembersCountField = (value, allValues) => {
		if (allValues.customer) {
			let error = validateNumber(value)
			if (!error && value >= CUSTOMER_MAX_MEMBERS_COUNT) {
				error = locale.formatString(locale['error.validation.LT'], CUSTOMER_MAX_MEMBERS_COUNT)
			}
			if (!error && value <= 0) {
				error = locale.formatString(locale['error.validation.GT'], 0)
			}
			return error
		}
		return undefined
	}

	createCustomer = (e) => {
		e.preventDefault()
		const { customerCreateHandler } = this.props
		customerCreateHandler()
	}

	getContainers = ({ fields }) => {
		function removeField(index) {
			return () => {
				fields.remove(index)
			}
		}

		const rows = fields.map((container, index) => (
			<tr key={index}>
				<Field
					name={container}
					component={this.containerField}
				/>
				<td className={'col-1'}>
					<Button onClick={removeField(index)} className={'secendary'}>
						<FontAwesomeIcon icon={faTrashAlt}/>
					</Button>
				</td>
			</tr>
		))

		return (
			<div className={'table-wrapper'}>
				<table style={{ tableLayout: 'fixed' }}>
					<thead>
						<tr>
							<th>{i18next.t('loc:Typ nádoby')}</th>
							<th>{i18next.t('loc:Priradená od')}</th>
							<th>{i18next.t('loc:Priradená do')}</th>
							<th>{i18next.t('loc:Kód')}</th>
							<th style={{ width: '50px' }}/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!rows.length && <EmptyTableState title={locale['page.collectionPlaces.create.containers.none']}/>}
			</div>
		)
	}

	containerField = ({ input }) => {
		const { value } = input
		return (
			<>
				<td>{get(value, 'containerType.label')}</td>
				<td>{(get(value, 'assignedFrom')).format('DD.MM.YYYY')}</td>
				<td>{value.assignedTo ? value.assignedTo?.format('DD.MM.YYYY') : '-'}</td>
				<td>{get(value, 'code')}</td>
			</>
		)
	}

	submitAddContainers = async (values, form) => {
		try {
			const { array } = this.props
			let res

			if (values.type === ASSIGN_TYPE.FIRST_AND_LAST) {
				res = await getReq(`/api/v1/containers/codes-interval/from/${values.firstCode}/to/${values.lastCode}`)
			} else if (values?.codes?.length > 0) {
				res = await getReq('/api/v1/containers/codes-translate', { codes: values.codes })
			}

			form.restart()
			this.dismissModal()

			forEach(res?.data?.codes, (codeItem) => {
				array.push('containers', {
					code: codeItem.encoded,
					containerType: {
						label: values?.containerType?.extra?.containerTypeName,
						value: values?.containerType?.value,
						key: values?.containerType?.value
					},
					assignedFrom: dayjs(values.assignedFrom).startOf('day'),
					assignedTo: null
				})
			})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}

	loadFlatHouses = (keyword, callback) => {
		const { municipality } = this.props
		const context = { municipalityID: municipality.id, type: COLLECTION_PLACE_TYPE.FLAT_HOUSE }
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.COLLECTION_PLACES, context).then((response) => {
			const items = map(get(response, 'data.collectionPlaces'), (item) => ({
				value: item.id,
				label: item.name,
				item
			}))
			callback(items)
		}).catch(() => callback())
	}

	loadNests = (keyword, callback) => {
		const { municipality } = this.props
		const context = { municipalityID: municipality.id, type: COLLECTION_PLACE_TYPE.NEST }
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.COLLECTION_PLACES, context).then((response) => {
			const items = map(get(response, 'data.collectionPlaces'), (item) => ({
				value: item.id,
				label: item.name,
				item
			}))
			callback(items)
		}).catch(() => callback())
	}

	handleTypeChange = (value) => {
		const { unregisterField, change } = this.props
		change(FORMS.CREATE_COLLECTION_PLACE, 'parent', null)

		if (value !== COLLECTION_PLACE_TYPE.COLLECTION_YARD && value !== COLLECTION_PLACE_TYPE.NEST) {
			unregisterField(FORMS.CREATE_COLLECTION_PLACE, 'name')
		} else {
			unregisterField(FORMS.CREATE_COLLECTION_PLACE, 'customer')
			unregisterField(FORMS.CREATE_COLLECTION_PLACE, 'membersCount')
		}
		if (value !== COLLECTION_PLACE_TYPE.COLLECTION_YARD) {
			unregisterField(FORMS.CREATE_COLLECTION_PLACE, 'openingHours')
		}
	}

	handleParentChange = (value) => {
		const { change } = this.props
		const address = get(value, 'item.address')
		if (address) {
			change('street', address.street)
			change('number', address.number)
			change('streetNumber', address.streetNumber)
			change('city', address.city)
			change('zip', address.zip)
		}
	}

	openModal = (modal) => (e, modalData) => {
		e.preventDefault()
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { cancelHandler } = this.props
		cancelHandler()
	}

	onSelectAddress = (value) => {
		const { change } = this.props

		change(FORMS.CREATE_COLLECTION_PLACE, 'street', value.street)
		change(FORMS.CREATE_COLLECTION_PLACE, 'number', value.number)
		change(FORMS.CREATE_COLLECTION_PLACE, 'streetNumber', value.streetNumber)
		change(FORMS.CREATE_COLLECTION_PLACE, 'zip', value.zip)
		change(FORMS.CREATE_COLLECTION_PLACE, 'city', value.city)
	}

	render() {
		const { handleSubmit, submitHandler, municipality, customer, type } = this.props
		const { modal } = this.state

		const membersCountField = customer && get(customer, 'item.legal') !== CUSTOMER_LEGAL_FORM.LEGAL && type !== COLLECTION_PLACE_TYPE.COTTAGE
			? (
				<Field
					name='membersCount'
					placeholder={locale['field.placeholder.members.count']}
					component={NumericInputField}
					props={this.membersCountFieldOptions}
					validate={this.validateMembersCountField}
				/>
			)
			: null

		return (
			<>
				<form onSubmit={handleSubmit(submitHandler)} noValidate>
					<div className={'box'}>
						<div className={'box-title'}>
							{locale['common.basic.info']}
						</div>
						<div className={'box-content with-padding'}>
							<div className={'box-head'}/>
							<div className={'box-body'}>
								<CollectionPlaceFormFields
									onParentChange={this.handleParentChange}
									onTypeChange={this.handleTypeChange}
									loadFlatHouses={this.loadFlatHouses}
									loadNests={this.loadNests}
									municipalityID={municipality.id}
									onSelectSuggestion={this.onSelectAddress}
									type={type}
								/>
								{type && showCustomerTypes.includes(type) &&
								<div className={'row'}>
									<div className={'col-md-6'}>
										<Field
											name='customer'
											placeholder={locale['field.placeholder.customer']}
											component={AsyncSelectField}
											props={this.customerFieldOptions}
										/>
									</div>
									<div className={'col-md-6'}>
										{membersCountField}
									</div>
									<div className={'col-md-6'}>
										<Button
											onClick={this.createCustomer}
										>
											<FontAwesomeIcon icon={faUserPlus}/>
											<span>{locale['page.customers.create']}</span>
										</Button>
									</div>
								</div>}
							</div>
						</div>
					</div>
					<div className={'box'}>
						<div className={'box-title'}>
							{locale['page.collectionPlaces.create.containers']}
						</div>
						<div className={'box-content'}>
							<div className={'box-head'}>
								<div className={'row justify-content-end'}>
									<Button
										onClick={this.openModal(MODAL.SET_COLLECTION_PLACE_CONTAINER)}
									>
										<FontAwesomeIcon icon={faPlus}/>
										<span>{locale['page.collectionPlaces.detail.containers.add']}</span>
									</Button>
								</div>
							</div>
							<div className={'box-body'}>
								<FieldArray name="containers" component={this.getContainers}/>
							</div>
						</div>
						<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
							<Col>
								<Button
									onClick={this.cancelForm}
									className={'secondary'}
								>
									{locale['common.back']}
								</Button>
							</Col>
							<Col>
								<Button>
									{locale['common.save']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
				<CollectionPlaceContainerModal
					municipalityID={municipality.id}
					collectionPlaceType={type}
					visible={modal === MODAL.SET_COLLECTION_PLACE_CONTAINER}
					handleSubmit={this.submitAddContainers}
					onClose={this.dismissModal}
				/>
			</>
		)
	}
}

const selector = formValueSelector(FORMS.CREATE_COLLECTION_PLACE)

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	customer: selector(state, 'customer'),
	type: selector(state, 'type')
})

const mapDispatchToProps = (dispatch) => ({
	change: bindActionCreators(change, dispatch),
	resetForm: bindActionCreators(reset, dispatch),
	pushStatus: bindActionCreators(statusPush, dispatch),
	unregisterField: bindActionCreators(unregisterField, dispatch)
})

export default reduxForm({
	form: FORMS.CREATE_COLLECTION_PLACE,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(CollectionPlaceCreateForm))
