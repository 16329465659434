import React from 'react'
import { useTranslation } from 'react-i18next'
import { concat, intersection, some } from 'lodash'

import { MUNICIPALITY_TYPE, PERMISSIONS } from '../../../utils/enums'

import { MunicipalityBase, Profile } from '../../../types/data'

import MenuLink from './MenuLink'

import { Icon, StyledItem, StyledItemGroup } from './Styled'

import Container from '../../../resources/images/ic-container.svg'
import Marker from '../../../resources/images/ic-marker.svg'
import Card from '../../../resources/images/ic-credit-card.svg'
import Calendar from '../../../resources/images/ic-calendar.svg'
import QRCode from '../../../resources/images/ic-qr-code.svg'

const permissions = [
	PERMISSIONS.ADMINISTRATOR,
	PERMISSIONS.MUNICIPALITY_ADMIN,
	PERMISSIONS.MUNICIPALITY_WORKER
]

type Props = {
	authUser?: Profile | null
	municipality: MunicipalityBase | null
}

const Lists = ({ authUser, municipality, ...props }: Props) => {
	const [t] = useTranslation()

	if (!authUser?.isAdmin && some(authUser?.municipalities, (item) => !intersection(item.permissions, permissions).length)) {
		return null
	}

	let items: React.ReactNode[] = []

	if (municipality?.type !== MUNICIPALITY_TYPE.WASTE_COMPANY) {
		items = concat(items, [
			<StyledItem key={'LISTS.collectionPlaces'}>
				<Icon icon={Marker}/>
				<MenuLink basePath={'paths:LISTS.collectionPlaces'} municipality={municipality}>
					{t('paths:LISTS.collectionPlaces.title')}
				</MenuLink>
			</StyledItem>,
			<StyledItem key={'LISTS.customers'}>
				<Icon icon={Card}/>
				<MenuLink municipality={municipality} basePath={'paths:LISTS.collectionPlaces'}>
					{t('paths:LISTS.customers.title')}
				</MenuLink>
			</StyledItem>,
			<StyledItem key={'LISTS.customersCodes'}>
				<Icon icon={QRCode}/>
				<MenuLink municipality={municipality} basePath={'paths:LISTS.customersCodes'}>
					{t('paths:LISTS.customersCodes.title')}
				</MenuLink>
			</StyledItem>
		])
	}

	items = concat(items, [
		<StyledItem key={'LISTS.calendar'}>
			<Icon icon={Calendar}/>
			<MenuLink municipality={municipality} basePath={'paths:LISTS.calendar'}>
				{t('paths:LISTS.calendar.title')}
			</MenuLink>
		</StyledItem>,
		<StyledItem key={'LISTS.containers'}>
			<Icon icon={Container}/>
			<MenuLink municipality={municipality} basePath={'paths:LISTS.containers'}>
				{t('paths:LISTS.containers.title')}
			</MenuLink>
		</StyledItem>
	])

	return (
		<StyledItemGroup
			{...props}
			key={'LISTS'}
			title={t('paths:LISTS.title')}
		>
			{items}
		</StyledItemGroup>
	)
}

export default React.memo(Lists)
