import moment from 'moment'
import {
	RESET_ALL_FILTERS,
	SELECTED_FILTERS_ORDERS_CHANGED
} from '../types/selectedFiltersOrders'
import { FILTER_ORDER_SELECTORS } from '../utils/enums'
import { RESET_STORE } from '../types/global'

// NOTE: filter and sorter init values it always should be a string if are using also in query param as navigation !!!

export const initState = {
	[FILTER_ORDER_SELECTORS.CONTAINER_TYPES_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.CUSTOMERS_PAGE]: {
		search: null,
		order: null,
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_PLACES_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.USERS_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.MUNICIPALITIES_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.WASTE_TYPES_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_PLACE_PAGE]: {
		'customers-search': null,
		'customers-order': 'name:ASC',
		'customers-page': '1',
		'customers-limit': '20',
		'containers-search': null,
		'containers-order': 'containerType:ASC',
		'containers-page': '1',
		'containers-limit': '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_ROUNDS_PAGE]: {
		search: null,
		order: 'name:DESC',
		page: '1',
		withoutCollections: false,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_ROUND_PAGE]: {
		search: null,
		order: 'containerCode:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.CALENDAR_PAGE]: {
		search: null,
		order: 'date:DESC',
		page: '1',
		visibleDate: `${moment().valueOf()}`,
		selectedDate: `${moment().valueOf()}`,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.DEVICES_PAGE]: {
		search: null,
		order: 'name:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTIONS_PAGE]: {
		search: null,
		order: 'containerCode:ASC',
		page: '1',
		filter: null,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_YARDS_PAGE]: {
		search: null,
		order: null,
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.MUNICIPALITIES_SORTING_PAGE]: {
		order: 'year:DESC',
		page: '1',
		period: 'MONTH',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.COLLECTION_PLACES_SORTING_PAGE]: {
		order: 'name:ASC',
		page: '1',
		fromDate: null,
		toDate: null,
		filter: null,
		searchTerm: null,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.CUSTOMERS_SORTING_PAGE]: {
		order: 'name:ASC',
		page: '1',
		fromDate: null,
		toDate: null,
		filter: null,
		searchTerm: null,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.QUANTITY_PAGE]: {
		page: '1',
		fromDate: `${moment().startOf('month').valueOf()}`,
		toDate: `${moment().endOf('month').valueOf()}`,
		wasteTypes: [],
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.MOTIVATION_PAGE]: {
		page: '1',
		motivationModelID: null,
		order: 'fromDate:DESC',
		search: null,
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.UNCOLLECTED_PAGE]: {
		wasteTypes: null,
		collectionRounds: null,
		search: '',
		page: '1',
		order: 'name:ASC',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.WEIGHT_PAGE]: {
		wasteTypes: null,
		fromDate: null,
		toDate: null
	},
	[FILTER_ORDER_SELECTORS.CONTAINERS_PAGE]: {
		search: null,
		order: 'containerType:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.CODE_PORTIONS_PAGE]: {
		order: 'createdAt:DESC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE]: {
		order: 'code:ASC',
		page: '1',
		limit: '20'
	},
	[FILTER_ORDER_SELECTORS.DASHBOARD]: {
		calendarEvents: {
			selectedDate: moment().startOf('date').toISOString()
		},
		progressCollections: {
			dateFrom: moment().startOf('month').toISOString(),
			dateTo: moment().endOf('month').toISOString()
		},
		sortingRate: {
			dateFrom: moment().startOf('month').toISOString(),
			dateTo: moment().endOf('month').toISOString()
		}
	}
}

export default (state = initState, action) => {
	switch (action.type) {
		case SELECTED_FILTERS_ORDERS_CHANGED:
			return {
				...state,
				[action.payload.selector]: action.payload.selectedFiltersSorters
			}
		case RESET_STORE:
		case RESET_ALL_FILTERS:
			return {
				...initState
			}
		default:
			return state
	}
}
