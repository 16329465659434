import { STATUS_POP, STATUS_PUSH } from '../types/status'
import { RESET_STORE } from '../types/global'

export const initState = {
	statuses: []
}

export default (state = initState, action) => {
	switch (action.type) {
		case STATUS_POP:
			return {
				...state,
				statuses: state.statuses.slice(action.payload.numberOfStatuses)
			}
		case STATUS_PUSH:
			return {
				...state,
				statuses: [...state.statuses, action.payload.status]
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return { ...state }
	}
}
