import React from 'react'
import { Form } from 'antd'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

import NumberRangeFilter from '../../../components/table/NumberRangeFilter'
import { FILTER_POSITION, FORMS } from '../../../utils/enums'

type ComponentProps = {}

export interface IWeightFilter {
	weightFrom?: string | null
	weightTo?: string | null
}

type Props = InjectedFormProps<IWeightFilter, ComponentProps> & ComponentProps

const WeightFilter = (props: Props) => {
	const { handleSubmit, reset } = props
	const [t] = useTranslation()

	return <Form layout='vertical'>
		<NumberRangeFilter
			title={t('table.columns.weight')}
			name='weight'
			onFilter={handleSubmit}
			onReset={reset}
			position={FILTER_POSITION.LEFT}
			visible
		/>
	</Form>
}

const form = reduxForm<IWeightFilter, ComponentProps>({
	form: FORMS.BULK_COLLECTIONS_PAGE_WEIGHT_FILTER,
	forceUnregisterOnUnmount: false, // NOTE: do not destroy form data
	touchOnChange: true,
	destroyOnUnmount: false // NOTE: do not destroy form data
})(WeightFilter)

export default form
