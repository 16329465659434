import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Text } from '../../../../components/Typography'

import ListStatisticsRow, { Props as Data } from './ListStatisticsRow'
import StatisticsTooltip from './StatisticsTooltip'
import Button from '../../../../components/buttons/Button'

const StyledButton = styled(Button)``

export const ListWrapper = styled.div`
	flex: 1;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	margin-top: 16px;
	height: calc(100% - 40px);

	@media(max-width: 767px) {
		overflow: hidden;
	}
`

export const EmptyTextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	& > ${StyledButton} {
		display: none;
	}

	@media(max-width: 767px) {
		& > ${StyledButton} {
			display: block;
		}
	}
`

type Props = {
	title: string
	tooltip?: {
		title: string
		description: string
	}
	emptyText: string
	expanded: boolean
	section: ExpandableSection
	onExpand: (section: ExpandableSection) => void
	data: Data[]
}

export type ExpandableSection = 'collections' | 'collectionYard' | 'wasteTypes' | null

const ListStatistics = ({ title, tooltip, data, emptyText, expanded, onExpand, section }: Props) => {
	const [t] = useTranslation()

	return (
		<Wrapper>
			<Row gutter={8}>
				<Col>
					<Text className={'bold'}>{title}</Text>
				</Col>
				{!!tooltip &&
				<Col>
					<StatisticsTooltip {...tooltip}/>
				</Col>}
			</Row>
			{!data.length &&
			<EmptyTextWrapper>
				<Text>{emptyText}</Text>
			</EmptyTextWrapper>}
			{!!data.length &&
			<ListWrapper>
				{map(data, (item, index) => <ListStatisticsRow key={index} {...item}/>)}
			</ListWrapper>}
			{(data.length > 7 && !expanded) &&
			<StyledButton className={'transparent citrus expand'} onClick={() => onExpand(section)}>
				{t('CustomerPage.showAll')}
			</StyledButton>}
		</Wrapper>
	)
}

export default React.memo(ListStatistics)
