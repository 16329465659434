import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import qs from 'qs'
import i18next from 'i18next'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reset } from 'redux-form'
import { Col, Row } from 'antd'

import ReactQueryParams from 'react-query-params'

import {
	debounce, get, isEqual, head, last, trim
} from 'lodash'

import { ENDPOINTS, FILTER_ORDER_SELECTORS, INFO, MODAL } from '../../utils/enums'

import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import { statusPush } from '../../actions/statusActions'

import { deleteReq, getReq } from '../../utils/request'
import { normalizeQueryParams, mergeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { history } from '../../utils/history'

import locale from '../../resources/locale'

import SearchInputField from '../../atoms/SearchInputField'
import Pagination from '../../atoms/Pagination'

import Breadcrumbs from '../../components/Breadcrumb'

import CollectionRoundsTable from './components/CollectionRoundsTable'
import DialogModal from '../../components/modals/DialogModal'
import ExportButton from '../../components/buttons/ExportButton'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import CollectionRoundsMergeModal from './components/CollectionRoundsMergeModal'
import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit',
	withoutCollections: 'withoutCollections'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:DESC',
		allowedValues: ['name', 'wasteType', 'startDatetime', 'stopDatetime', 'collectionsCount', 'realWeight', 'totalWeight']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	},
	withoutCollections: {
		type: QUERY_PARAMS_TYPES.BOOLEAN,
		defaultValue: false
	}
}

class CollectionRoundsPage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		resetForm: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.withoutCollections]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			collectionRounds: {
				data: [],
				isLoading: false,
				isFailure: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// Remove unwanted query params from query string
		const query = qs.stringify(filtersOrder)
		history.replace(history.location.pathname, { search: query })

		this.loadCollectionRounds(filtersOrder)
		this.setQueryParams(filtersOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.COLLECTION_ROUNDS_PAGE, filtersOrder)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			case queryParams.withoutCollections:
				newFilterOrder = !!value
				break
			default:
				break
		}

		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type !== queryParams.page && type !== queryParams.order) {
			filterOrder[queryParams.page] = '1'
		}

		const normalized = normalizeQueryParams(queryTypes, { ...filterOrder })
		this.setQueryParams(normalized)
		this.loadCollectionRounds(normalized)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.COLLECTION_ROUNDS_PAGE, normalized)
	}

	searchCollectionPlaces = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	loadCollectionRounds = async (filters) => {
		if (this._mounted) {
			this.setState({
				collectionRounds: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const { municipality } = this.props
				const query = {
					...filters
				}
				if (municipality) {
					query.municipalityID = municipality.id
				}
				const response = await getReq(ENDPOINTS.COLLECTION_ROUNDS, query)
				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder(queryParams.page, 1)
				} else {
					this.setState({
						...this.state,
						collectionRounds: {
							data: get(response, 'data'),
							isLoading: false,
							isFailure: false
						}
					})
				}
			} catch (error) {
				this.setState({
					...this.state,
					collectionRounds: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	setRemovedCollectionRound = (collectionRound) => {
		const { pushStatus, selectedFiltersOrders } = this.props
		return async () => {
			this.dismissModal()
			try {
				await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION_ROUND(collectionRound.municipalityID, collectionRound.id), null)
				this.loadCollectionRounds(selectedFiltersOrders)
				pushStatus({
					type: INFO,
					msg: locale['page.collectionRounds.delete.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	openCollectionRoundDetail = (collectionRound) => {
		const { municipality } = this.props
		if (municipality) {
			history.push(locale.formatString(locale['path.municipality.collections.rounds.detail'], municipality.id, collectionRound.id))
		} else {
			history.push(locale.formatString(locale['path.registry.collections.rounds.detail'], collectionRound.id))
		}
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = (reload) => {
		if (typeof reload === 'boolean' && reload) {
			const { selectedFiltersOrders } = this.props

			this.loadCollectionRounds(selectedFiltersOrders)
		}

		this.setState({
			modal: null,
			modalData: null
		})
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.registry.breadcrumbs',
			name: locale['page.registry.breadcrumbs']
		}, {
			key: 'page.collectionRounds.breadcrumbs',
			name: locale['page.collectionRounds.breadcrumbs']
		}]
	}

	debounceSearch = debounce(this.searchCollectionPlaces, 300)

	render() {
		const { selectedFiltersOrders, municipality } = this.props
		const { collectionRounds, modal, modalData } = this.state

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))
		const withoutCollections = selectedFiltersOrders[queryParams.withoutCollections]

		const context = { ...selectedFiltersOrders }
		if (municipality) {
			context.municipalityID = municipality.id
		}

		return (
			<>
				<Breadcrumbs items={this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.collectionRounds.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row justify={'space-between'} gutter={[16, 16]}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={this.debounceSearch}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton url={ENDPOINTS.COLLECTION_ROUNDS_EXPORT} context={context}/>
								</Col>
								<Col>
									<Button
										className={'secondary'}
										onClick={() => this.openModal(MODAL.MERGE_COLLECTION_ROUNDS)()}
									>
										Zlúčiť zvozy
									</Button>
								</Col>
								<Col span={24}>
									<div className="checkbox-wrapper" style={{ padding: '0 10px' }}>
										<div
											className={cx('checkbox', { active: withoutCollections })}
											onClick={() => this.onChangeFilterOrder(queryParams.withoutCollections, !withoutCollections)}
										>
											<label>{i18next.t('loc:Zobraziť zvozy bez záznamov')}</label>
										</div>
									</div>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<CollectionRoundsTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onDetail={this.openCollectionRoundDetail}
										onDelete={this.openModal(MODAL.REMOVE_COLLECTION_ROUND)}
										loading={collectionRounds.isLoading}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										collectionRounds={get(collectionRounds, 'data.collectionRounds')}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(collectionRounds, 'data.context.pages')}
										page={get(collectionRounds, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION_ROUND}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setRemovedCollectionRound(modalData)}
					message={locale['page.collectionRounds.delete.message']}
					title={modalData ? locale.formatString(locale['page.collectionRounds.delete.title'], get(modalData, 'name')) : ''}
					acceptTitle={locale['page.collectionRounds.delete.accept']}
				/>
				<CollectionRoundsMergeModal
					visible={modal === MODAL.MERGE_COLLECTION_ROUNDS}
					onClose={this.dismissModal}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.COLLECTION_ROUNDS_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	resetForm: bindActionCreators(reset, dispatch),
	pushStatus: bindActionCreators(statusPush, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionRoundsPage)
