import * as PropTypes from 'prop-types'

export const listPropTypes = (name, dataShape) => PropTypes.shape({
	data: PropTypes.shape({
		[name]: PropTypes.arrayOf(PropTypes.shape(dataShape)),
		context: PropTypes.shape({
			page: PropTypes.number.isRequired,
			count: PropTypes.number.isRequired,
			pages: PropTypes.number.isRequired
		})
	}),
	isLoading: PropTypes.bool.isRequired,
	isFailure: PropTypes.bool.isRequired
})

export const detailPropTypes = (dataShape) => PropTypes.shape({
	data: PropTypes.shape(dataShape),
	isLoading: PropTypes.bool.isRequired,
	isFailure: PropTypes.bool.isRequired
})
