import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import i18next from 'i18next'
import locale from '../../resources/locale'

import SelectField from '../../atoms/SelectField'
import { validate, validatorOptions } from '../../utils/validator'

import { collectionPlacesTypes } from '../../utils/select'
import { COLLECTION_PLACE_TYPE } from '../../utils/enums'
import TextAreaInputField from '../../atoms/TextAreaInputField'
import TextInputField from '../../atoms/TextInputField'
import { parseNumber } from '../../utils/utils'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import CheckBoxField from '../../atoms/CheckBoxField'
import SuggestionsInputField from '../../atoms/SuggestionsInputField'
import { searchAddresses } from '../../utils/address'

const validateText = validate([validatorOptions.REQUIRED_STRING])
const validateType = validate([validatorOptions.REQUIRED_STRING])
const validateZip = validate([validatorOptions.REQUIRED_STRING, validatorOptions.ZIP])
const validateNumber = validate([validatorOptions.REQUIRED_ANY_NUMBER])
const validateLat = validate([validatorOptions.LAT])
const validateLon = validate([validatorOptions.LON])

class CollectionPlaceFormFields extends React.Component {
	static propTypes = {
		type: PropTypes.string,
		onTypeChange: PropTypes.func.isRequired,
		onParentChange: PropTypes.func.isRequired,
		loadFlatHouses: PropTypes.func.isRequired,
		loadNests: PropTypes.func.isRequired,
		onSelectSuggestion: PropTypes.func.isRequired,
		municipalityID: PropTypes.number
	}

	loadAddresses = (search, callback) => {
		const { municipalityID } = this.props
		const filter = { municipalityID, search }
		searchAddresses(filter, callback)
	}

	nameFieldOptions = {
		label: i18next.t('loc:Názov'),
		required: true
	}

	flatHouseFieldOptions = {
		label: i18next.t('loc:Bytový dom'),
		loadOptions: this.props.loadFlatHouses,
		showLabel: true
	}

	nestFieldOptions = {
		label: i18next.t('loc:Stojisko'),
		loadOptions: this.props.loadNests,
		showLabel: true
	}

	numberFieldOptions = {
		label: i18next.t('loc:Súpisné Číslo'),
		required: true,
		min: 1,
		max: 9999999,
		precision: 0
	}

	streetNumberFieldOptions = {
		label: i18next.t('loc:Orientačné Číslo'),
		required: true
	}

	flatNumberFieldOptions = {
		label: i18next.t('loc:Číslo bytu'),
		required: true
	}

	streetFieldOptions = {
		onSelectSuggestion: this.props.onSelectSuggestion,
		loadOptions: debounce(this.loadAddresses, 300),
		label: i18next.t('loc:Ulica'),
		required: true
	}

	cityFieldOptions = {
		label: i18next.t('loc:Obec'),
		required: true
	}

	typeFieldOptions = {
		label: i18next.t('loc:Typ'),
		required: true,
		showLabel: true,
		options: collectionPlacesTypes
	}

	zipFieldOptions = {
		label: i18next.t('loc:PSČ'),
		required: true
	}

	latFieldOptions = {
		label: i18next.t('loc:Zemepisná šírka'),
		required: true,
		min: -90,
		max: 90,
		precision: 10
	}

	lonFieldOptions = {
		label: i18next.t('loc:Zemepisná dĺžka'),
		required: true,
		min: -180,
		max: 180,
		precision: 10
	}

	descriptionFieldOptions = {
		label: i18next.t('loc:Poznámka'),
		required: false
	}

	composterFieldOptions = {
		label: i18next.t('loc:Domáci kompostér'),
		required: true,
		showLabel: true
	}

	validateNumber = (value, allValues) => {
		const { type } = allValues
		let error
		switch (type) {
			case COLLECTION_PLACE_TYPE.COTTAGE:
			case COLLECTION_PLACE_TYPE.FAMILY_HOUSE:
			case COLLECTION_PLACE_TYPE.OTHER:
			case COLLECTION_PLACE_TYPE.FLAT:
				if (parseNumber(value) && Number.isInteger(parseNumber(value))) {
					error = validateText(value.toString())
				} else {
					error = locale['error.validation.number']
				}
				break
			default: error = undefined
		}
		if (!error && value) {
			if (!parseNumber(value)) {
				error = locale['error.validation.number']
			}
		}
		return error
	}

	validateFlatHouse = (value, allValues) => {
		const { type } = allValues
		let error
		if (type === COLLECTION_PLACE_TYPE.FLAT && !value) {
			error = locale['error.validation.REQUIRED']
		}

		return error
	}

	validateAddressField = (value, allValues) => {
		const { type } = allValues
		switch (type) {
			case COLLECTION_PLACE_TYPE.COTTAGE:
			case COLLECTION_PLACE_TYPE.FAMILY_HOUSE:
			case COLLECTION_PLACE_TYPE.OTHER:
			case COLLECTION_PLACE_TYPE.FLAT:
				return validateText(value)
			default: return undefined
		}
	}

	validateLocationField = (value, allValues) => {
		const { type } = allValues
		switch (type) {
			case COLLECTION_PLACE_TYPE.NEST:
			case COLLECTION_PLACE_TYPE.COLLECTION_YARD:
				return validateNumber(value)
			default: return undefined
		}
	}

	render() {
		const { onTypeChange, onParentChange, type } = this.props

		const showNameField = type === COLLECTION_PLACE_TYPE.COLLECTION_YARD || type === COLLECTION_PLACE_TYPE.NEST || type === COLLECTION_PLACE_TYPE.OTHER || type === COLLECTION_PLACE_TYPE.FLAT_HOUSE
		const showFlatHouseField = type === COLLECTION_PLACE_TYPE.FLAT
		const showFlatNumberField = type === COLLECTION_PLACE_TYPE.FLAT
		const showIsComposter = type === COLLECTION_PLACE_TYPE.FLAT_HOUSE || type === COLLECTION_PLACE_TYPE.FAMILY_HOUSE || type === COLLECTION_PLACE_TYPE.FLAT
		const showNestField = type === COLLECTION_PLACE_TYPE.FLAT_HOUSE || type === COLLECTION_PLACE_TYPE.FAMILY_HOUSE || type === COLLECTION_PLACE_TYPE.OTHER || type === COLLECTION_PLACE_TYPE.COTTAGE

		return (
			<>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<Field
							name={'type'}
							component={SelectField}
							placeholder={locale['field.placeholder.type']}
							props={this.typeFieldOptions}
							onChange={onTypeChange}
							validate={validateType}
						/>
					</div>
					{showNestField && <div className={'col-md-6'}>
						<Field
							name='parent'
							placeholder={'Stojisko'}
							component={AsyncSelectField}
							onChange={onParentChange}
							props={this.nestFieldOptions}
							validate={this.validateFlatHouse}
						/>
					</div>}
					{showNameField && <div className={'col-md-6'}>
						<Field
							name='name'
							type={'text'}
							placeholder={locale['field.placeholder.title']}
							component={TextInputField}
							props={this.nameFieldOptions}
							validate={validateText}
						/>
					</div>}
					{showFlatHouseField && <div className={'col-md-6'}>
						<Field
							name='parent'
							placeholder={locale['field.placeholder.flat.house']}
							component={AsyncSelectField}
							onChange={onParentChange}
							props={this.flatHouseFieldOptions}
							validate={this.validateFlatHouse}
						/>
					</div>}
					{showIsComposter && <div className={'col-md-4'}>
						<Field
							name={'isComposter'}
							component={CheckBoxField}
							props={this.composterFieldOptions}
						/>
					</div>}
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<Field
							name='street'
							type={'text'}
							placeholder={locale['field.placeholder.address.street']}
							component={SuggestionsInputField}
							props={this.streetFieldOptions}
							validate={this.validateAddressField}
						/>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<Field
							name='number'
							type={'text'}
							placeholder={locale['field.placeholder.address.numbers']}
							component={TextInputField}
							props={this.numberFieldOptions}
							validate={this.validateNumber}
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='streetNumber'
							type={'text'}
							placeholder={locale['field.placeholder.address.numbers.street']}
							component={TextInputField}
							props={this.streetNumberFieldOptions}
							validate={this.validateAddressField}
						/>
					</div>
					{showFlatNumberField && <div className={'col-md-6'}>
						<Field
							name='flatNumber'
							type={'text'}
							placeholder={locale['field.placeholder.address.numbers.flat']}
							component={TextInputField}
							props={this.flatNumberFieldOptions}
							validate={validateText}
						/>
					</div>}
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<Field
							name='city'
							type={'text'}
							placeholder={locale['field.placeholder.address.city']}
							component={TextInputField}
							props={this.cityFieldOptions}
							validate={validateText}
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='zip'
							type={'text'}
							placeholder={locale['field.placeholder.zip']}
							component={TextInputField}
							props={this.zipFieldOptions}
							validate={validateZip}
						/>
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-3'}>
						<Field
							name='lat'
							type={'number'}
							placeholder={locale['field.placeholder.address.lat']}
							component={TextInputField}
							props={this.latFieldOptions}
							validate={[this.validateLocationField, validateLat]}
						/>
					</div>
					<div className={'col-md-3'}>
						<Field
							name='lon'
							type={'number'}
							placeholder={locale['field.placeholder.address.lon']}
							component={TextInputField}
							props={this.lonFieldOptions}
							validate={[this.validateLocationField, validateLon]}
						/>
					</div>
					<div className={'col-md-6'}>
						<Field
							name='description'
							placeholder={'Zadajte poznámku'}
							component={TextAreaInputField}
							props={this.descriptionFieldOptions}
						/>
					</div>
				</div>
			</>
		)
	}
}

export default CollectionPlaceFormFields
