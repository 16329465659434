import React from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import i18next from 'i18next'

import { connect } from 'react-redux'
import { debounce, map, get } from 'lodash'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import locale from '../../resources/locale'

import Modal from './Modal'
import DateTimePickerField from '../../atoms/DateTimePickerField'
import { validate, validatorOptions } from '../../utils/validator'

import { ANONYMOUS, CUSTOMER_LEGAL_FORM, CUSTOMER_MAX_MEMBERS_COUNT, ENDPOINTS, FORMS } from '../../utils/enums'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import TextInputField from '../../atoms/TextInputField'
import { getReq } from '../../utils/request'
import { parseNumber } from '../../utils/utils'
import Button from '../buttons/Button'

const validateDate = validate([validatorOptions.REQUIRED])
const validateType = validate([validatorOptions.REQUIRED])
const validateMembers = validate([validatorOptions.REQUIRED, validatorOptions.NUMBER])

class CollectionPlaceCustomer extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		activeFrom: PropTypes.any,
		activeTo: PropTypes.any,
		dismissHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object,
		municipalityID: PropTypes.number,
		title: PropTypes.string,
		shown: PropTypes.bool,
		customer: PropTypes.object,
		hideMembersCount: PropTypes.bool,
		defaultCustomers: PropTypes.array
	}

	state = {
		activeTo: null,
		test: null
	}

	anonymousCustomer = {
		label: locale['page.collectionPlaces.detail.customer.anonymous'],
		value: ANONYMOUS,
		key: ANONYMOUS
	}

	searchCustomers = (search, callback) => {
		const { municipalityID } = this.props
		const context = {
			exclude: 'ANONYMOUS',
			order: 'name:ASC',
			municipalityID
		}
		if (search && search.length) {
			context.search = search
		}
		getReq(ENDPOINTS.CUSTOMERS, context).then((response) => {
			const items = map(response.data.customers, (item) => ({
				label: `${item.name}, ${item.address}`,
				value: item.id,
				key: item.id,
				activeTo: item.activeTo,
				item
			}))
			return callback([this.anonymousCustomer, ...items])
		}).catch(() => callback([]))
	}

	membersFieldOptions = {
		label: i18next.t('loc:Počet členov domácnosti'),
		showLabel: true,
		required: true
	}

	customerFieldOptions = {
		label: i18next.t('loc:Platca'),
		showLabel: true,
		required: true,
		loadOptions: debounce(this.searchCustomers, 300)
	}

	activeFromFieldOptions = {
		showLabel: true,
		minDate: new Date(),
		label: i18next.t('loc:Aktívny od'),
		format: locale['common.date.format.date']
	}

	activeToFieldOptions = {
		showLabel: true,
		minDate: new Date(),
		label: i18next.t('loc:Aktívny do'),
		format: locale['common.date.format.date']
	}

	validateMembersCount = (value) => {
		let error = validateMembers(value)
		if (!error && value >= CUSTOMER_MAX_MEMBERS_COUNT) {
			error = locale.formatString(locale['error.validation.LT'], CUSTOMER_MAX_MEMBERS_COUNT)
		}
		if (!error && value <= 0) {
			error = locale.formatString(locale['error.validation.GT'], 0)
		}
		return error
	}

	validateDateFrom = (value, allValues) => {
		const error = validateDate(value)
		if (!error && allValues.activeTo) {
			const fromDate = moment(value)
			const toDate = moment(allValues.activeTo)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.FROM_DATE']
			}
		}
		return error
	}

	normalizeDateFrom = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.startOf('day').toDate()
		}
		return value
	}

	validateDateTo = (value, allValues) => {
		if (value && allValues.activeFrom) {
			const fromDate = moment(allValues.activeFrom)
			const toDate = moment(value)
			if (fromDate.isValid() && toDate.isValid() && fromDate >= toDate) {
				return locale['error.validation.TO_DATE']
			}
		}
		return null
	}

	normalizeDateTo = (value) => {
		const date = moment(value)
		if (value && date.isValid()) {
			return date.endOf('day').toDate()
		}
		return value
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { cancelHandler } = this.props
		cancelHandler()
	}

	handleCustomerChange = (event, value) => {
		const { change, activeFrom, activeTo } = this.props
		if (value && value.activeTo) {
			const activeToCustomer = moment(value.activeTo)
			if (activeTo && moment(activeTo) > activeToCustomer) {
				change('activeTo', activeToCustomer.toDate())
			}
			if (activeFrom && moment(activeFrom) > activeToCustomer) {
				change('activeFrom', activeToCustomer.startOf('day').toDate())
			}
			this.setState({
				activeTo: activeToCustomer.toDate()
			})
		} else {
			this.setState({
				activeTo: null
			})
		}
	}

	render() {
		const { dismissHandler, handleSubmit, shown, title, submitHandler, customer, defaultCustomers, hideMembersCount } = this.props
		const { activeTo } = this.state

		const membersCountField = !hideMembersCount && customer && get(customer, 'item.legal') !== CUSTOMER_LEGAL_FORM.LEGAL
			? <div className={'col-md-6'}>
				<Field
					name='membersCount'
					placeholder={locale['field.placeholder.members.count']}
					component={TextInputField}
					props={this.membersFieldOptions}
					normalize={parseNumber}
					validate={this.validateMembersCount}
				/>
			</div>
			: null

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit((data) => submitHandler(data))} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{title}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='customer'
									placeholder={locale['field.placeholder.customer']}
									component={AsyncSelectField}
									validate={validateType}
									props={{
										...this.customerFieldOptions,
										defaultOptions: defaultCustomers ? [this.anonymousCustomer, ...defaultCustomers] : true
									}}
									onChange={this.handleCustomerChange}
								/>
							</div>
							{membersCountField}
						</div>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<Field
									name='activeFrom'
									placeholder={locale['field.placeholder.active.from']}
									component={DateTimePickerField}
									props={{
										...this.activeFromFieldOptions,
										maxDate: activeTo
									}}
									validate={this.validateDateFrom}
									normalize={this.normalizeDateFrom}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='activeTo'
									placeholder={locale['field.placeholder.active.to']}
									component={DateTimePickerField}
									props={{
										...this.activeToFieldOptions,
										maxDate: activeTo
									}}
									validate={this.validateDateTo}
									normalize={this.normalizeDateTo}
								/>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
						<Col>
							<Button
								onClick={this.cancelForm}
								className={'secondary'}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</form>
			</Modal>
		)
	}
}

const selector = formValueSelector(FORMS.SET_COLLECTION_PLACE_CUSTOMER_FORM)

const mapStateToProps = (state) => ({
	customer: selector(state, 'customer'),
	activeFrom: selector(state, 'activeFrom'),
	activeTo: selector(state, 'activeTo')
})

export default reduxForm({
	form: FORMS.SET_COLLECTION_PLACE_CUSTOMER_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps)(CollectionPlaceCustomer))
