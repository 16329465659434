import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'
import { debounce, map, pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { createForm, FormApi } from 'final-form'

import { Field, Form, FormSpy } from 'react-final-form'
import { Col, Row } from 'antd'

import { CONTAINER_TYPE } from '../../../types/enums'
import { COLLECTION_ROUND_TYPE, DATE_TIME_FORMAT, UNSUITABLE_CONDITION } from '../../../utils/enums'
import { formatContainerName } from '../../../utils/formatters'

import { statusPush } from '../../../actions/statusActions'

import { getSelectedMunicipality } from '../../../redux/municipalities/selectors'
import { collectionRounds, collections, containers as containersApi } from '../../../api'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SelectField from '../../../components/form/SelectField'
import DateField from '../../../components/form/DateField'
import NumberField from '../../../components/form/NumberField'
import Button from '../../../components/buttons/Button'
import { Modal, ModalContent, ModalFooter, ModalHead } from '../../../components/Modal'

import validateCollection from '../validators/validateCollection'

export type FormValues = {
	date: string
	collectionRound: { value: number, label: string }
	containers: { value: number, label: string }[]
	quantity: number
	weight: number
	scanDatetime: string
	isUnsuitableConditionsState: boolean
	unsuitableConditionsState: UNSUITABLE_CONDITION
}

type Props = {
	visible: boolean
	onClose: (created: boolean) => void
}

const baseDate = dayjs().toISOString()

const CreateCollectionModal = ({ visible, onClose }: Props) => {
	const [t] = useTranslation()
	const [date, setDate] = useState(baseDate)
	const [rounds, loadRounds] = useDataLoader(collectionRounds.loadCollectionRounds)
	const [containers, loadContainers] = useDataLoader(containersApi.loadContainers)

	const dispatch = useDispatch()
	const municipality = useSelector(getSelectedMunicipality)

	const handleSubmit = useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
		try {
			const submitData = {
				containerIDs: map(values.containers, (container) => container.value),
				collectionRoundID: values.collectionRound.value,
				isUnsuitableConditions: !!values.unsuitableConditionsState,
				unsuitableConditionState: values.unsuitableConditionsState,
				quantity: parseFloat(`${values.quantity}`) / 100,
				...pick(values, 'scanDatetime', 'weight')
			}

			const { data } = await collections.createCollection(municipality.id, submitData)

			dispatch(statusPush(data.messages[0]))

			form.restart()

			onClose(true)

			return Promise.resolve()
		} catch (e) {
			return Promise.resolve({})
		}
	}, [municipality.id, dispatch, onClose])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit,
		validate: validateCollection,
		initialValues: { date: baseDate, scanDatetime: dayjs().toISOString(), quantity: 100 }
	}), [handleSubmit])

	const roundsOptions = useMemo(() => rounds.data
		? map(rounds.data.collectionRounds, (round) => ({ value: round.id, label: round.name }))
		: [],
	[rounds.data])

	const containersOptions = useMemo(() => containers.data
		? map(containers.data.containers, (container) => ({ value: container.id, label: formatContainerName(container, true) }))
		: [],
	[containers.data])

	const filterContainers = useMemo(() => debounce((search: string) => {
		if (municipality) {
			const filters = {
				search,
				types: [CONTAINER_TYPE.ONE_TIME, CONTAINER_TYPE.PERMANENT, CONTAINER_TYPE.UNIVERSAL],
				municipalityID: municipality.id
			}
			loadContainers(filters)
		}
	}, 150), [loadContainers, municipality])

	const unsuitableOptions = useMemo(() => [{
		value: UNSUITABLE_CONDITION.INCORRECT_MATERIAL, label: t('UNSUITABLE_CONDITION.INCORRECT_MATERIAL')
	}, {
		value: UNSUITABLE_CONDITION.CONTAMINATED, label: t('UNSUITABLE_CONDITION.CONTAMINATED')
	}, {
		value: UNSUITABLE_CONDITION.UNCOMPRESSED, label: t('UNSUITABLE_CONDITION.UNCOMPRESSED')
	}, {
		value: UNSUITABLE_CONDITION.DAMAGED_BIN, label: t('UNSUITABLE_CONDITION.DAMAGED_BIN')
	}, {
		value: UNSUITABLE_CONDITION.MARKER100, label: t('UNSUITABLE_CONDITION.MARKER100')
	}, {
		value: UNSUITABLE_CONDITION.MARKER200, label: t('UNSUITABLE_CONDITION.MARKER200')
	}, {
		value: UNSUITABLE_CONDITION.MARKER300, label: t('UNSUITABLE_CONDITION.MARKER300')
	}, {
		value: UNSUITABLE_CONDITION.MARKER400, label: t('UNSUITABLE_CONDITION.MARKER400')
	}], [t])

	const handleChangeDate = useMemo(() => debounce((date: string) => {
		setDate(date)
	}, 50), [])

	useEffect(() => {
		if (municipality) {
			const filters = {
				fromDate: dayjs(date).startOf('month').toDate(),
				toDate: dayjs(date).endOf('month').toDate(),
				limit: 'ALL',
				withoutCollections: true,
				type: [COLLECTION_ROUND_TYPE.STANDARD, COLLECTION_ROUND_TYPE.GENERAL],
				municipalityID: municipality.id
			}
			loadRounds(filters)
		}
	}, [date, loadRounds, municipality])

	useEffect(() => {
		if (municipality) {
			const filters = {
				types: [CONTAINER_TYPE.ONE_TIME, CONTAINER_TYPE.PERMANENT, CONTAINER_TYPE.UNIVERSAL],
				municipalityID: municipality.id
			}
			loadContainers(filters)
		}
	}, [loadContainers, municipality])

	if (!municipality) {
		return null
	}

	return (
		<Modal
			width={600}
			onCancel={() => onClose(false)}
			visible={visible}
			footer={null}
		>
			{/* @ts-ignore */}
			<Form
				form={form}
				subscription={{ submitting: true }}
				render={({ handleSubmit, submitting }) => (
					<>
						<ModalHead>{t('CreateCollectionModal.title')}</ModalHead>
						<ModalContent>
							<form onSubmit={handleSubmit}>
								<FormSpy
									subscription={{ values: true }}
									onChange={({ values }) => {
										if (date !== values.date) {
											handleChangeDate(values.date)
										}
									}}
								/>
								<Field
									name={'containers'}
									render={(props) => (
										<SelectField
											{...props}
											labelInValue={true}
											showSearch={true}
											filterOption={false}
											mode={'multiple'}
											isLoading={containers.isLoading}
											onSearch={filterContainers}
											options={containersOptions}
											label={t('fields.container.label')}
											placeholder={t('fields.container.placeholder')}
										/>
									)}
								/>
								<Field
									name={'date'}
									validateFields={[]}
									render={(props) => (
										<DateField
											{...props}
											picker={'month'}
											format={'MMMM YYYY'}
											allowClear={false}
											label={t('fields.collectionRoundDate.label')}
										/>
									)}
								/>
								<Field
									name={'collectionRound'}
									render={(props) => (
										<SelectField
											{...props}
											labelInValue={true}
											isLoading={rounds.isLoading}
											options={roundsOptions}
											label={t('fields.collectionRound.label')}
											placeholder={t('fields.collectionRound.placeholder')}
										/>
									)}
								/>
								<Row gutter={[16, 16]}>
									<Col md={12} span={24}>
										<Field
											name={'quantity'}
											render={(props) => (
												<NumberField
													{...props}
													min={0}
													max={1000}
													required={true}
													label={t('fields.quantity.label')}
													placeholder={t('fields.quantity.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col md={12} span={24}>
										<Field
											name={'weight'}
											render={(props) => (
												<NumberField
													{...props}
													min={0}
													max={1000}
													label={t('fields.weight.label')}
													placeholder={t('fields.weight.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24}>
										<Field
											name={'scanDatetime'}
											render={(props) => (
												<DateField
													{...props}
													showTime={true}
													format={DATE_TIME_FORMAT}
													label={t('fields.scanDatetime.label')}
													placeholder={t('fields.scanDatetime.placeholder')}
												/>
											)}
										/>
									</Col>
								</Row>
								<Field
									name={'unsuitableConditionsState'}
									render={(props) => (
										<SelectField
											{...props}
											options={unsuitableOptions}
											allowClear={true}
											label={t('fields.unsuitableConditionsState.label')}
											placeholder={t('fields.unsuitableConditionsState.placeholder')}
										/>
									)}
								/>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row justify={'end'} gutter={[16, 8]}>
								<Col>
									<Button
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose(false)
										}}
									>
										{t('CreateCollectionModal.close')}
									</Button>
								</Col>
								<Col>
									<Button
										loading={submitting}
										disabled={submitting}
										onClick={handleSubmit}
									>
										{t('CreateCollectionModal.create')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default React.memo(CreateCollectionModal)
