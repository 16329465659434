import i18next from 'i18next'

import { AnyObject } from 'final-form'
import { MUNICIPALITY_TYPE } from '../../../utils/enums'

export const validateZip = (value: string, allValues: AnyObject) => {
	if (!value && allValues.type !== MUNICIPALITY_TYPE.WASTE_COMPANY) {
		return i18next.t('errors.required')
	}

	return undefined
}

export const validateWebserviceUsername = (name: 'altares' | 'gx' | 'sepan' | 'radium') => (value: string, allValues: AnyObject) => {
	if (allValues[name]?.password && !value) {
		return i18next.t('errors.required')
	}

	return undefined
}

export const validateWebservicePassword = (name: 'altares' | 'gx' | 'sepan' | 'radium') => (value: string, allValues: AnyObject) => {
	if (allValues[name]?.username && !value) {
		return i18next.t('errors.required')
	}

	return undefined
}

export const validateWebserviceCustomerNumber = (name: 'sepan') => (value: string, allValues: AnyObject) => {
	if (allValues[name]?.customerNumber && !value) {
		return i18next.t('errors.required')
	}

	return undefined
}
