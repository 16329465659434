import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { MUNICIPALITIES_ACTIONS } from './constants'

export const municipalitiesLoadStart = {
	type: MUNICIPALITIES_ACTIONS.LIST_LOAD_START
}

export const municipalitiesLoadFail = {
	type: MUNICIPALITIES_ACTIONS.LIST_LOAD_FAIL
}

export const municipalitiesLoadDone = (data) => ({
	type: MUNICIPALITIES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadMunicipalities = (filters) => async (dispatch) => {
	dispatch(municipalitiesLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.MUNICIPALITIES, query)

		dispatch(municipalitiesLoadDone(response.data))
	} catch (e) {
		dispatch(municipalitiesLoadFail)
	}
}
