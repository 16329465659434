import React from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'
import { reset, initialize } from 'redux-form'
import ReactQueryParams from 'react-query-params'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { statusPush } from '../../actions/statusActions'
import locale from '../../resources/locale'
import Breadcrumbs from '../../components/Breadcrumb'
import { deleteReq, getReq, patchReq } from '../../utils/request'
import { ENDPOINTS, FORMS, INFO, MODAL } from '../../utils/enums'
import { history } from '../../utils/history'
import DialogModal from '../../components/modals/DialogModal'
import CollectionForm from '../../components/collections/CollectionForm'

const dateFormat = locale['common.date.format']
const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page'
}

class CollectionPage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		match: PropTypes.shape({
			params: PropTypes.shape({
				collectionID: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			collection: {
				data: null,
				isLoading: true,
				isFailure: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { municipality } = this.props

		if (municipality) {
			this.returnPath = locale.formatString(locale['path.municipality.collections'], municipality.id)
		} else {
			this.returnPath = locale['path.registry.collections']
		}

		this.loadCollection()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		if (this._mounted) {
			const { collection } = this.state
			const { match, municipality } = this.props
			if (!collection.isLoading && !collection.isFailure) {
				if (get(match, 'params.collectionID') !== collection.data.id.toString()) {
					this.loadCollection()
				}
			}

			if (municipality?.id !== prevProps.municipality?.id) {
				history.replace(municipality
					? locale.formatString(locale['path.municipality.collections'], municipality.id)
					: locale['path.registry.collections'])
			}
		}
	}

	loadCollection = async () => {
		this.setState({
			collection: {
				data: null,
				isLoading: true,
				isFailure: false
			}
		})
		const { match } = this.props
		const id = match.params.collectionID
		try {
			const response = await getReq(ENDPOINTS.COLLECTION(id))
			this.setupForm(response.data)
		} catch (error) {
			this.setState({
				...this.state,
				collection: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	setupForm = (collection) => {
		const { initialize, reset } = this.props
		this.setState({
			collection: {
				data: collection,
				isLoading: false,
				isFailure: true
			}
		})

		initialize(FORMS.COLLECTION_FORM, this.getInitialValues(collection))
		reset(FORMS.COLLECTION_FORM)
	}

	getInitialValues = (collection) => ({
		id: collection.id,
		container: get(collection, 'container.id') ? {
			value: get(collection, 'container.id'),
			label: `${get(collection, 'container.code') || get(collection, 'container.rfid')} - ${get(collection, 'container.name') || '?'}`
		} : null,
		collectionRound: get(collection, 'collectionRound.id') ? {
			value: get(collection, 'collectionRound.id'),
			label: get(collection, 'collectionRound.name'),
			type: get(collection, 'collectionRound.type')
		} : null,
		scanDatetime: collection.scanDatetime,
		weight: collection.weight,
		quantity: parseFloat(collection.quantity) * 100.0,
		isUnsuitableConditions: collection.isUnsuitableConditions,
		unsuitableConditionState: collection.unsuitableConditionState || null,
		wasteType: collection.wasteType,
		address: collection.address,
		marker: collection.marker,
		lat: collection.lat,
		lon: collection.lon,
		googleMapsUrl: collection.googleMapsUrl,
		createdAt: collection.createdAt,
		updatedAt: collection.updatedAt,
		creator: collection.creator,
		editor: collection.editor,
		isInvalid: collection.isInvalid,
		invalidState: collection.invalidState
	})

	saveUpdates = async (values) => {
		const data = {
			collectionRoundID: get(values, 'collectionRound.value', null),
			containerID: get(values, 'container.value', null),
			quantity: parseFloat(values.quantity) / 100.0,
			weight: parseFloat(values.weight),
			scanDatetime: moment(values.scanDatetime).toDate(),
			isUnsuitableConditions: values.isUnsuitableConditions,
			unsuitableConditionState: values.isUnsuitableConditions ? values.unsuitableConditionState : null
		}
		const { collection } = this.state
		const item = get(collection, 'data', null)
		if (item) {
			try {
				const { pushStatus } = this.props
				await patchReq(ENDPOINTS.MUNICIPALITY_COLLECTION(item.municipalityID, item.id), null, data)
				this.returnToList()
				pushStatus({
					type: INFO,
					msg: locale['page.collections.detail.update.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	returnToList = () => {
		history.push(this.returnPath)
	}

	dismissModal = () => {
		this.setState({
			modal: null
		})
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	setCollectionRemoved = async () => {
		const { pushStatus } = this.props
		const { collection } = this.state
		const item = get(collection, 'data', null)
		this.dismissModal()
		if (item) {
			try {
				await deleteReq(ENDPOINTS.MUNICIPALITY_COLLECTION(item.municipalityID, item.id), null)
				history.replace(this.returnPath)
				pushStatus({
					type: INFO,
					msg: locale['page.collections.delete.success']
				})
			} catch (error) {
				// Error
			}
		}
	}

	loadCollectionRounds = (keyword, collection, callback) => {
		const context = {
			municipalityID: collection.municipalityID,
			withoutCollections: true
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.COLLECTION_ROUNDS, context).then((response) => {
			const rounds = map(get(response, 'data.collectionRounds', []), (item) => ({
				value: item.id,
				label: item.name,
				type: item.type
			}))
			callback(rounds)
		}).catch(() => callback([]))
	}

	breadcrumbsItems = (collection) => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return {
			items: [...items, {
				key: 'page.registry.breadcrumbs',
				name: locale['page.registry.breadcrumbs']
			}, {
				key: 'page.collections.breadcrumbs',
				name: locale['page.collections.breadcrumbs'],
				link: municipality ?
					locale.formatString(locale['path.municipality.collections'], municipality.id)
					: locale['path.registry.collections']
			}, {
				key: 'page.collections.detail.breadcrumbs',
				name: `${moment(collection.scanDatetime).format(dateFormat)} - ${get(collection, 'container.code', '?')}`
			}]
		}
	}

	render() {
		const { loading, modal, collection } = this.state

		if (collection.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !loading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.collections.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<Breadcrumbs
					{...this.breadcrumbsItems(get(collection, 'data'))}
				/>
				<CollectionForm
					submitHandler={this.saveUpdates}
					cancelHandler={this.returnToList}
					deleteHandler={this.openModal(MODAL.REMOVE_COLLECTION)}
					initialValues={this.getInitialValues(get(collection, 'data'))}
					municipalityID={get(collection, 'data.municipalityID')}
					loadCollectionRounds={this.loadCollectionRounds}
				/>
				<DialogModal
					shown={modal === MODAL.REMOVE_COLLECTION}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setCollectionRemoved}
					message={locale['page.collections.delete.message']}
					title={locale.formatString(locale['page.collections.delete.title'], get(collection, 'data.container.code') || '')}
					acceptTitle={locale['page.collections.delete.accept']}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	reset: bindActionCreators(reset, dispatch),
	initialize: bindActionCreators(initialize, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPage)
