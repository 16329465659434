import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { SearchOutlined } from '@ant-design/icons'
import { Input, InputWrapper, Label } from '../components/form/Styled'

const Wrapper = styled(InputWrapper)`
	position: relative;
	height: 32px;
	margin-bottom: 0;
	
	& > ${Input} {
		padding-left: 32px;
	}
	
	& > .anticon {
		position: absolute;
		left: 12px;
		font-size: 16px;
		top: 50%;
		transform: translateY(-50%);
	}
`

type Props = {
	name: string
	value?: string
	placeholder?: string
	showLabel?: boolean
	label?: boolean
	type?: string
	onChange: (value: string) => void
	defaultValue?: string
}

const SearchInputField = ({ name, label, defaultValue, value, placeholder, showLabel, type = 'text', onChange }: Props) => {
	const [t] = useTranslation()

	return (
		<Wrapper>
			{showLabel && <Label>{label}</Label>}
			<SearchOutlined size={3}/>
			<Input
				name={name}
				className={'search'}
				placeholder={placeholder || t('common.search')}
				type={type}
				onChange={(e) => onChange(e.target.value)}
				value={value}
				defaultValue={defaultValue}
			/>
		</Wrapper>
	)
}

export default SearchInputField
