import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from './DateRangePicker'
import locale from '../resources/locale'

class StatisticsRangePicker extends React.Component {
	static propTypes = {
		range: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired
	}

	handleChange = (value) => {
		const { onChange } = this.props
		onChange(value)
	}

	render() {
		const { range } = this.props

		return (
			<div className={'stats-range-picker'}>
				<DateRangePicker
					format={locale['common.date.format.date']}
					label={''}
					showLabel={false}
					placeholder={locale['field.placeholder.date.range']}
					input={{
						onBlur: () => {},
						onFocus: () => {},
						onChange: this.handleChange,
						value: range
					}}
					meta={{
						error: null,
						touched: false
					}}
				/>
			</div>
		)
	}
}

export default StatisticsRangePicker
