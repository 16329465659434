import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { debounce, get, isEqual, head, last, trim } from 'lodash'
import { reset } from 'redux-form'

import ReactQueryParams from 'react-query-params'
import { Col, Row } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { ENDPOINTS, FILTER_ORDER_SELECTORS, FORMS, INFO, MODAL } from '../../utils/enums'

import SearchInputField from '../../atoms/SearchInputField'
import Pagination from '../../atoms/Pagination'
import WasteTypesTable from '../../components/wasteTypes/WasteTypesTable'

import { statusPush } from '../../actions/statusActions'

import locale from '../../resources/locale'
import CreateWasteType from '../../components/modals/CreateWasteType'
import { history } from '../../utils/history'
import { deleteReq, getReq, postReq } from '../../utils/request'
import Breadcrumbs from '../../components/Breadcrumb'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import DialogModal from '../../components/modals/DialogModal'
import ExportButton from '../../components/buttons/ExportButton'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import ListItemsLimit from '../../atoms/ListImtesLimit'

import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'code', 'color', 'coefficient', 'weightCalculateType', 'isActiveMotivationModel']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class WasteTypesPage extends ReactQueryParams {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		resetForm: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			modalData: null,
			wasteTypes: {
				data: null,
				isLoading: false,
				isFailure: false
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		this.loadWasteTypes(filtersOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.WASTE_TYPES_PAGE, filtersOrder)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (type, value) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default:
				break
		}
		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		this.setQueryParams({
			...filterOrder
		})
		this.loadWasteTypes(filterOrder)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.WASTE_TYPES_PAGE, filterOrder)
	}

	createWasteType = async (data) => {
		const { pushStatus, municipality, selectedFiltersOrders } = this.props
		try {
			await postReq(ENDPOINTS.WASTE_TYPES(municipality.id), null, data)
			pushStatus({
				type: INFO,
				msg: locale['page.waste.types.add.success']
			})
			this.dismissModal()
			this.loadWasteTypes(selectedFiltersOrders)
		} catch (error) {
			this.loadWasteTypes(selectedFiltersOrders)
		}
	}

	loadWasteTypes = async (filters) => {
		if (this._mounted) {
			this.setState({
				wasteTypes: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const { municipality } = this.props
				const query = { ...filters }
				const response = await getReq(ENDPOINTS.WASTE_TYPES(municipality.id), query)
				const context = get(response, 'data.context', {})
				if (query.page > 1 && query.page > context.pages) {
					this.onChangeFilterOrder(queryParams.page, 1)
				} else {
					this.setState({
						wasteTypes: {
							data: get(response, 'data'),
							isLoading: false,
							isFailure: false
						}
					})
				}
			} catch (error) {
				this.setState({
					wasteTypes: {
						data: null,
						isLoading: false,
						isFailure: true
					}
				})
			}
		}
	}

	searchWasteTypes = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	openWasteTypeDetail = (wasteType) => {
		const { municipality } = this.props
		history.push(locale.formatString(locale['path.municipality.wastes.detail'], municipality.id, wasteType.id))
	}

	removeWasteType = async () => {
		const { modalData } = this.state
		const { pushStatus, municipality, selectedFiltersOrders } = this.props
		try {
			await deleteReq(ENDPOINTS.WASTE_TYPE(municipality.id, modalData.id))
			this.dismissModal()
			this.loadWasteTypes(selectedFiltersOrders)
			pushStatus({
				type: INFO,
				msg: locale['page.waste.types.delete.success']
			})
		} catch (error) {
			this.loadWasteTypes(selectedFiltersOrders)
		}
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	resetForm = (modal) => {
		const { resetForm } = this.props
		if (modal === MODAL.CREATE_WASTE_TYPE) {
			resetForm(FORMS.CREATE_WASTE_TYPE)
		}
	}

	openModal = (modal) => (data) => {
		this.resetForm(modal)
		this.setState({
			modal,
			modalData: data
		})
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		return [{
			key: 'page.municipality',
			name: locale['page.municipality.settings.breadcrumbs']
		}, {
			key: 'page.municipality.detail.',
			name: municipality.name
		}, {
			key: 'page.waste.types.breadcrumbs',
			name: locale['page.waste.types.breadcrumbs']
		}]
	}

	render() {
		const { wasteTypes, modal, modalData } = this.state
		const { selectedFiltersOrders, municipality } = this.props

		const order = selectedFiltersOrders.order || ''
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		return (
			<>
				<Breadcrumbs items={this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.waste.types.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={[16, 16]} justify={'space-between'}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchWasteTypes, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								<Col flex={1}>
									<ExportButton
										url={ENDPOINTS.WASTE_TYPES_EXPORT(municipality.id)}
										context={selectedFiltersOrders}
									/>
								</Col>
								<Col>
									<Button
										onClick={this.openModal(MODAL.CREATE_WASTE_TYPE)}
									>
										<PlusOutlined/>
										<span>{locale['page.waste.types.add']}</span>
									</Button>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<WasteTypesTable
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onRemove={this.openModal(MODAL.REMOVE_WASTE_TYPE)}
										onDetail={this.openWasteTypeDetail}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										wasteTypes={get(wasteTypes, 'data.types')}
										loading={get(wasteTypes, 'isLoading')}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(wasteTypes, 'data.context.pages')}
										page={get(wasteTypes, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<CreateWasteType
						shown={modal === MODAL.CREATE_WASTE_TYPE}
						dismissHandler={this.dismissModal}
						createHandler={this.createWasteType}
					/>
					<DialogModal
						shown={modal === MODAL.REMOVE_WASTE_TYPE}
						cancelHandler={this.dismissModal}
						acceptHandler={this.removeWasteType}
						message={locale.formatString(locale['page.waste.types.delete.message'])}
						title={locale.formatString(locale['page.waste.types.delete.title'], get(modalData, 'name', ''))}
						acceptTitle={locale['page.waste.types.delete.accept']}
					/>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.WASTE_TYPES_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	resetForm: bindActionCreators(reset, dispatch),
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WasteTypesPage)
