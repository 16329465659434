import { STATUS_POP, STATUS_PUSH } from '../types/status'

export const statusPushCreator = (status) => ({ type: STATUS_PUSH, payload: { status } })

const statusPopCreator = (numberOfStatuses) => ({ type: STATUS_POP, payload: { numberOfStatuses } })

export const statusPop = (numberOfStatuses) => (dispatch) => {
	dispatch(statusPopCreator(numberOfStatuses))
}

export const statusPush = (status) => (dispatch) => {
	dispatch(statusPushCreator(status))
}
