import { get, map } from 'lodash'
import { getReq } from './request'
import { ENDPOINTS } from './enums'

export const searchAddresses = (filter, callback) => {
	getReq(ENDPOINTS.ADDRESSES, filter).then((response) => {
		const addresses = map(get(response, 'data.addresses', []), (item) => ({
			key: `${item.street}-${item.zip}-${item.city}`,
			value: item,
			label: `${item.street}, ${item.city} ${item.zip}`
		}))
		callback(addresses)
	}).catch(() => callback([]))
}
