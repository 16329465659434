import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { SORT_ASC, SORT_DESC } from '../types/table'

class TableHead extends React.Component {
	static propTypes = {
		name: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		onSort: PropTypes.func.isRequired,
		isSorting: PropTypes.bool.isRequired,
		style: PropTypes.object,
		className: PropTypes.string,
		initDirection: PropTypes.string
	}

	toggleSort = (sort) => {
		const { onSort, name } = this.props
		if (sort === SORT_ASC) {
			sort = SORT_DESC
		} else if (sort === SORT_DESC) {
			sort = SORT_ASC
		} else {
			sort = SORT_ASC
		}
		onSort({ sort, name })
	}

	render() {
		const { title, isSorting, className, initDirection, style } = this.props
		const sort = initDirection || SORT_ASC
		let icon = null
		if (isSorting && sort === SORT_ASC) {
			icon = <div className={'sort-icon asc'}/>
		} else if (isSorting && sort === SORT_DESC) {
			icon = <div className={'sort-icon desc'}/>
		} else {
			icon = <div className={'sort-icon'}/>
		}
		const content = <><span>{title}</span>{icon}</>

		return (
			<th style={style} className={cx('sortable', className)} onClick={() => this.toggleSort(sort)}>
				{content}
			</th>
		)
	}
}

export default TableHead
