import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Redirect } from 'react-router'
import { checkPermissions } from '../utils/permissions'
import { getAuthUser } from '../redux/authentication'

class Permission extends React.Component {
	render = () => {
		const { user, selectedMunicipalityId, redirect } = this.props
		const allowed = this.props.allowed || []
		const hasPermissions = checkPermissions(allowed, user.data, selectedMunicipalityId)

		if (hasPermissions) {
			return this.props.children
		}

		if (redirect) {
			return <Redirect to={redirect}/>
		}

		return null
	}
}

Permission.propTypes = {
	user: PropTypes.object,
	allowed: PropTypes.array,
	selectedMunicipalityId: PropTypes.number,
	redirect: PropTypes.string
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state),
	selectedMunicipalityId: get(state, 'selectedMunicipality.municipality.data.id')
})

export default connect(mapStateToProps)(Permission)
