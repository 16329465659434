import styled from 'styled-components'

import { Menu } from 'antd'

const { ItemGroup, Item } = Menu

export const StyledItemGroup = styled(ItemGroup)`
	.ant-menu-item-group-title {
		text-transform: uppercase;
		color: white;
		font-weight: bold;
		font-size: 12px;
	}
`

export const Icon = styled.div<{ icon: string }>`
	background: url("${({ icon }) => icon}") center no-repeat;
	background-size: 18px;
	width: 25px;
	height: 25px;
`

export const StyledItem = styled(Item)`
	&.ant-menu-item {
		margin-left: 15px;
		
		border-bottom-left-radius: 17px;
		border-top-left-radius: 17px;
		
		transition: color 0s;
		
		color: #dedede;
		font-size: 12px;
		padding: 5px 20px;
		height: 35px;
		
		display: flex;
		align-items: center;
		
		> a {
			color: #dedede;
			font-size: 12px;
		}
		
		${Icon} {
			filter: brightness(0%) invert(1);
			margin-right: 15px;
		}
		
		&.ant-menu-item-active {
		 	background-color: ${({ theme }) => theme.colors.primary['500']}50;
		 	color: white;
			
			& > a {
				color: white;
			}
		}
		
		&.ant-menu-item-selected {
			background-color: ${({ theme }) => theme.colors.primary['500']};
			color: white;

			& > a {
				color: white;
			}
		}
	}
`
