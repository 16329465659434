import i18next from 'i18next'

import { MutableState, Tools } from 'final-form'
import { reduce, trim } from 'lodash'

export function insertAt<T extends object>(args: [string, number, object], state: MutableState<T>, { changeValue }: Tools<T>) {
	const [name, index, value] = args
	changeValue(state, name, (array) => {
		const copy = [...(array || [])]
		copy.splice(index, 0, value)
		return copy
	})
}

export function copyValueAt<T extends object>(args: [string, number], state: MutableState<T>, { changeValue, getIn }: Tools<T>) {
	const [name, index] = args
	changeValue(state, name, (array) => {
		const copy = [...(array || [])]
		const value = getIn(state.formState.values, `${name}[${index}]`)
		copy.splice(index, 0, value)
		return copy
	})
}

export const validateArrayMin = (count: number) => (value: Array<any>) => Array.isArray(value) && !value.length ? i18next.t('errors.arrayMin', { count }) : undefined
export const validateRequired = (value: any) => value === null || value === undefined || value === '' ? i18next.t('errors.required') : undefined
export const validateImageRequired = (value: any) => (Array.isArray(value) && !value.length) || value === null || value === undefined || value === '' ? i18next.t('errors.required') : undefined
export const validateGte = (min: number) => (value: any) => parseFloat(`${value}`) < min ? i18next.t('errors.gte', { value: min }) : undefined
export const validateNumber = (value: any) => Number.isNaN(Number(value)) ? i18next.t('errors.number') : undefined

export const validateStringLength = (min: number, max?: number) => (value: string) => {
	const trimmedValue = trim(value)
	if (trimmedValue.length < min) {
		return i18next.t('errors.stringMin', { count: min })
	}

	if (max && trimmedValue.length > max) {
		return i18next.t('errors.stringMax', { count: max })
	}

	return undefined
}

export const composeValidators = (...validators: Function[]) =>
	(value: any, allValues?: object) => reduce(validators, (error, validator) => error || validator(value, allValues), undefined)
