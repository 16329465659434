import React from 'react'
import moment from 'moment-timezone'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initialize, reset } from 'redux-form'
import { get, join, map, uniq } from 'lodash'
import cx from 'classnames'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// components
import CalendarEventForm from '../../components/calendar/CalenadarEventForm'
import Breadcrumbs from '../../components/Breadcrumb'
import DialogModal from '../../components/modals/DialogModal'

// utils
import { ENDPOINTS, FORMS, INFO, MODAL } from '../../utils/enums'
import { getReq, patchReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'

// actions
import { statusPush } from '../../actions/statusActions'
import { loadMunicipalityWasteTypes } from '../../actions/municipalityActions'

// recources
import locale from '../../resources/locale'

const dateFormat = locale['common.date.format.date']

function formatEvent(event) {
	return `${locale[`event.types.${event.type}`]} - ${moment(event.date).format(dateFormat)}`
}

class CalendarEventPage extends React.Component {
	static propTypes = {
		pushStatus: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		match: PropTypes.object.isRequired,
		loadMunicipalityWasteTypes: PropTypes.func.isRequired,
		municipality: PropTypes.object
	}

	_mounted = false

	constructor(props) {
		super(props)
		this.state = {
			modal: null,
			modalData: null,
			event: {
				isLoading: false,
				isFailure: false,
				data: null
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		this.loadEvent()
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate(prevProps) {
		if (this._mounted) {
			const { event } = this.state
			const { match, municipality } = this.props
			if (!event.isLoading && !event.isFailure) {
				if (get(match, 'params.eventID') !== get(event, 'data.id', 0).toString()) {
					this.loadEvent()
				}
			}

			if (prevProps.municipality?.id !== municipality?.id) {
				if (municipality) {
					history.replace(locale.formatString(locale['path.municipality.calendar'], municipality.id))
				} else {
					history.replace(locale['path.lists.calendar'])
				}
			}
		}
	}

	loadEvent = async () => {
		const { match } = this.props
		try {
			const { eventID } = match.params
			if (!this._mounted) {
				return
			}
			this.setState({
				event: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			const response = await getReq(ENDPOINTS.CALENDAR_EVENT(eventID))
			if (!this._mounted) {
				return
			}
			this.setupForm(get(response, 'data'))
		} catch (e) {
			if (!this._mounted) {
				return
			}

			this.setState({
				event: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	getInitialValues = (event) => ({
		id: event.id,
		type: event.type,
		collectionRounds: map(event.collectionRounds, (item) => ({
			id: item.id,
			name: item.name,
			wasteTypeID: item.wasteType.id
		})),
		date: moment(event.date).toDate(),
		isPublic: event.isPublic,
		isDoorToDoor: event.isDoorToDoor,
		isVisibleForStatistics: event.isVisibleForStatistics,
		notificationDateTime: moment(event.notificationDateTime).toDate(),
		useQuantity: event.useQuantity,
		useUnsuitableConditions: event.useUnsuitableConditions,
		comment: event.comment
	})

	setupForm = (event) => {
		const { initialize, reset, loadMunicipalityWasteTypes } = this.props
		this.setState({
			event: {
				data: {
					...event,
					streets: join(uniq(map(event.collectionRounds, (round) => map(round.streets, (street) => street))), ', ')
				},
				isLoading: false,
				isFailure: false
			},
			loading: false
		})
		loadMunicipalityWasteTypes(event.municipalityID, () => {
			const values = this.getInitialValues(event)
			initialize(FORMS.CALENDAR_EVENT_FORM, values, false)
			reset(FORMS.CALENDAR_EVENT_FORM)
		})
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	submitHandler = async (values) => {
		const { match, pushStatus } = this.props
		const { event } = this.state
		try {
			const municipalityID = get(event, 'data.municipalityID')
			const eventID = get(event, 'data.id')

			const body = {
				...values
			}
			await patchReq(ENDPOINTS.MUNICIPALITY_CALENDAR_EVENT(municipalityID, eventID), null, body)
			pushStatus({
				type: INFO,
				msg: locale['page.calendar.events.update.success']
			})

			if (get(match, 'params.municipalityID')) {
				history.push(locale.formatString(locale['path.municipality.calendar'], municipalityID))
			} else {
				history.push(locale['path.lists.calendar'])
			}
		} catch (error) {
			// Error
		}
	}

	cancelEditing = () => {
		const { match } = this.props
		const { municipalityID } = match.params

		// redirect to calendar
		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.calendar'], municipalityID))
		} else {
			history.push(locale['path.lists.calendar'])
		}
	}

	onDeleteEvent = async () => {
		try {
			const { pushStatus, municipality } = this.props
			const { event } = this.state
			const eventID = get(event, 'data.id')
			const municipalityID = get(event, 'data.municipalityID')

			await deleteReq(ENDPOINTS.MUNICIPALITY_CALENDAR_EVENT(municipalityID, eventID))
			pushStatus({
				type: INFO,
				msg: locale['page.calendar.events.delete.success']
			})
			this.dismissModal()
			if (municipality) {
				history.push(locale.formatString(locale['path.municipality.calendar'], municipalityID))
			} else {
				history.push(locale['path.lists.calendar'])
			}
		} catch (e) {
			// Error
		}
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const { event } = this.state
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.lists.breadcrumbs',
			name: locale['page.lists.breadcrumbs']
		}, {
			key: 'page.calendar.breadcrumbs',
			name: locale['page.calendar.breadcrumbs'],
			link: municipality ?
				locale.formatString(locale['path.municipality.calendar'], municipality.id)
				: locale['path.lists.calendar']
		}, {
			key: 'page.events.detail.breadcrumbs',
			name: formatEvent(event.data)
		}]
	}

	render() {
		const { event, modal } = this.state

		if (!event.data || event.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !event.isLoading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.calendar.events.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<CalendarEventForm
					deleteHandler={this.openModal(MODAL.DELETE_EVENT)}
					cancelHandler={this.cancelEditing}
					submitHandler={this.submitHandler}
					event={event.data}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_EVENT}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteEvent}
					message={locale['page.calendar.events.delete.message']}
					title={locale.formatString(locale['page.calendar.events.delete.title'], formatEvent(event.data))}
					acceptTitle={locale['page.calendar.events.delete.accept']}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	loadMunicipalityWasteTypes: bindActionCreators(loadMunicipalityWasteTypes, dispatch),
	reset: bindActionCreators(reset, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventPage)
