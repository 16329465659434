import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { debounce, map, uniqBy } from 'lodash'

import { createForm, FormApi } from 'final-form'
import { Field, Form, FormSpy } from 'react-final-form'
import { Col, Row } from 'antd'

import { COLLECTION_ROUND_TYPE } from '../../../utils/enums'

import { getSelectedMunicipality } from '../../../redux/municipalities/selectors'

import { collectionRounds } from '../../../api'

import SelectField from '../../../components/form/SelectField'
import DateField from '../../../components/form/DateField'
import Button from '../../../components/buttons/Button'
import { Text } from '../../../components/Typography'
import { Modal, ModalContent, ModalHead, ModalFooter } from '../../../components/Modal'

import { useDataLoader } from '../../../hooks/useDataLoader'

import validators from '../validators/validateCollectionRoundsMerge'

type FormValues = {
	date: string
	collectionRounds: { value: number, label: string }[]
}

type Props = {
	visible: boolean
	onClose: (reload?: boolean) => void
}

const baseDate = dayjs().toISOString()

const CollectionRoundsMergeModal = ({ visible, onClose }: Props) => {
	const [t] = useTranslation()
	const [date, setDate] = useState(baseDate)
	const [state, load] = useDataLoader(collectionRounds.loadCollectionRounds)
	const selectedMunicipality = useSelector(getSelectedMunicipality)

	const handleSubmit = useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
		try {
			const data = { collectionRoundIDs: map(values.collectionRounds, (item) => item.value) }
			await collectionRounds.mergeCollectionRounds(data)

			form.restart()
			onClose(true)
			return Promise.resolve()
		} catch (e) {
			return Promise.resolve()
		}
	}, [onClose])

	const form = useMemo(() => createForm({
		onSubmit: handleSubmit,
		initialValues: { date: baseDate, collectionRounds: [] }
	}), [handleSubmit])

	const handleChangeDate = useMemo(() => debounce((date: string) => {
		setDate(date)
	}, 50), [])

	useEffect(() => {
		const filters = {
			fromDate: dayjs(date).startOf('month').toDate(),
			toDate: dayjs(date).endOf('month').toDate(),
			limit: 'ALL',
			withoutCollections: true,
			type: COLLECTION_ROUND_TYPE.STANDARD,
			municipalityID: selectedMunicipality?.id
		}
		load(filters)
	}, [date, load, selectedMunicipality?.id])

	const options = useMemo(() => {
		const formState = form.getState()

		const { collectionRounds } = formState.values

		const roundOptions = map(state.data?.collectionRounds, (round) => ({
			value: round.id, label: round.name
		}))

		return uniqBy([...collectionRounds, ...roundOptions], 'value')
	}, [form, state.data?.collectionRounds])

	return (
		<Modal
			visible={visible}
			footer={null}
			onCancel={() => onClose()}
		>
			{/* @ts-ignore */}
			<Form
				form={form}
				subscription={{ submitting: true }}
				render={({ handleSubmit, submitting }) => (
					<>
						<ModalHead>
							{t('CollectionRoundsMergeModal.title')}
						</ModalHead>
						<ModalContent>
							<form onSubmit={handleSubmit}>
								<Text style={{ marginTop: 8 }}>{t('CollectionRoundsMergeModal.description')}</Text>
								<FormSpy
									subscription={{ values: true }}
									onChange={({ values }) => {
										if (date !== values.date) {
											handleChangeDate(values.date)
										}
									}}
								/>
								<Field
									name={'date'}
									validateFields={[]}
									render={(props) => (
										<DateField
											{...props}
											picker={'month'}
											format={'MMMM YYYY'}
											allowClear={false}
											label={t('CollectionRoundsMergeModal.collectionRoundDate')}
										/>
									)}
								/>
								<Field
									name={'collectionRounds'}
									validateFields={[]}
									validate={validators.collectionRounds}
									render={(props) => (
										<SelectField
											{...props}
											labelInValue={true}
											isLoading={state.isLoading}
											mode={'multiple'}
											options={options}
											placeholder={t('CollectionRoundsMergeModal.selectCollectionRounds')}
											label={t('CollectionRoundsMergeModal.collectionRounds')}
										/>
									)}
								/>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row justify={'end'} gutter={16}>
								<Col>
									<Button
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose()
										}}
									>
										{t('CollectionRoundsMergeModal.close')}
									</Button>
								</Col>
								<Col>
									<Button
										loading={submitting}
										disabled={submitting}
										onClick={handleSubmit}
									>
										{t('CollectionRoundsMergeModal.merge')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default React.memo(CollectionRoundsMergeModal)
