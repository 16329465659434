import React from 'react'
import i18next from 'i18next'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

import TableHead from '../../atoms/TableHead'
import { EmptyTableState } from '../EmptyTableState'
import UsersTableRow from './UsersTableRow'

class UsersTable extends React.Component {
	static propTypes = {
		users: PropTypes.arrayOf(PropTypes.object),
		loading: PropTypes.bool.isRequired,
		municipality: PropTypes.object,
		onSort: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		sortingColumn: PropTypes.string,
		sortingDirection: PropTypes.string
	}

	render() {
		const {
			users,
			onSort,
			loading,
			onSetActive,
			onRemove,
			onDetail,
			sortingDirection,
			sortingColumn,
			municipality
		} = this.props

		const rows = map(users, (user, index) => (
			<UsersTableRow
				key={user.id}
				user={user}
				onSetActive={onSetActive}
				onDetail={onDetail}
				onRemove={onRemove}
				countIndex={index}
				municipality={municipality}
			/>
		))

		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={sortingColumn === 'name'}
								title={i18next.t('loc:Meno a priezvisko')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'email'}
								isSorting={sortingColumn === 'email'}
								title={i18next.t('loc:Email')}
								initDirection={sortingDirection}
								onSort={onSort}
							/>
							<th>
								{i18next.t('loc:Oprávnenia')}
							</th>
							<TableHead
								name={'state'}
								isSorting={sortingColumn === 'state'}
								title={i18next.t('loc:Stav')}
								onSort={onSort}
								initDirection={sortingDirection}
								className={'text-center'}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && users && !users.length && <EmptyTableState title={locale['page.users.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.users.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default UsersTable
