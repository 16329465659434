import React, { PropsWithChildren, createContext } from 'react'
import { Field, FieldProps } from 'react-final-form'

const SectionContext = createContext('')

type SectionProps = PropsWithChildren<{
	name: string
}>

const FormSection = ({ name, children }: SectionProps) => (
	<SectionContext.Provider value={name}>
		{children}
	</SectionContext.Provider>
)

export function FormSectionField<FieldValue>({ name, ...props }: FieldProps<FieldValue, any>) {
	return (
		<SectionContext.Consumer>
			{(section) => <Field name={`${section}.${name}`} {...props}/>}
		</SectionContext.Consumer>
	)
}

export default FormSection
