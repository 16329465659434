import i18next from 'i18next'

import { ValidationErrors } from 'final-form'

import { FormValues } from '../components/NestParentModal'

export default (values: FormValues) => {
	const errors: ValidationErrors = {}

	if (!values.nest) {
		errors.nest = i18next.t('errors.required')
	}

	if (!values.places || !values.places.length) {
		errors.places = i18next.t('errors.selectPlace')
	}

	return errors
}
