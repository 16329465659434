import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import { DEVICES_ACTIONS } from './constants'

export const devicesLoadStart = {
	type: DEVICES_ACTIONS.LIST_LOAD_START
}

export const devicesLoadFail = {
	type: DEVICES_ACTIONS.LIST_LOAD_FAIL
}

export const devicesLoadDone = (data) => ({
	type: DEVICES_ACTIONS.LIST_LOAD_DONE,
	payload: data
})

export const loadDevices = (filters) => async (dispatch) => {
	dispatch(devicesLoadStart)

	try {
		const query = {
			...filters,
			columns: undefined
		}

		const response = await getReq(ENDPOINTS.DEVICES, query)

		dispatch(devicesLoadDone(response.data))
	} catch (e) {
		dispatch(devicesLoadFail)
	}
}
