import React from 'react'

import { FieldRenderProps } from 'react-final-form'
import { Checkbox, InputWrapper, Label } from './Styled'

type Props = FieldRenderProps<string> & {
	label?: string
	className?: string
	inlineLabel?: boolean
}

const CheckboxField = ({ input, label, className, inlineLabel = false }: Props) => (
	<InputWrapper style={{ height: 99 }}>
		{!inlineLabel && <Label>{label}</Label>}
		<Checkbox
			className={className}
			checked={input.checked}
			onChange={input.onChange}
		>
			{inlineLabel && <Label className={'inline'}>{label}</Label>}
		</Checkbox>
	</InputWrapper>
)

export default CheckboxField
