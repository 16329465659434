import { Reducer, useCallback, useReducer, useRef } from 'react'
import { AxiosResponse } from 'axios'

enum ACTION_TYPE {
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE'
}

export type State<Data> = {
	data: Data | null
	isLoading: boolean
	isFailure: boolean
}

type Action<Data> = {
	type: ACTION_TYPE
	payload: Data | null
}

export const initialState = {
	data: null,
	isLoading: false,
	isFailure: false
}

const reducer = <Data extends unknown>(state: State<Data>, action: Action<Data>): State<Data> => ({
	data: action.type === ACTION_TYPE.SUCCESS ? action.payload : null,
	isLoading: action.type === ACTION_TYPE.LOADING,
	isFailure: action.type === ACTION_TYPE.FAILURE
})

export function useDataLoader<Data, Args extends Array<any>>(apiCall: (...args: Args) => Promise<AxiosResponse<Data>>): [State<Data>, (...args: Args) => Promise<void>, () => void] {
	const [state, dispatch] = useReducer<Reducer<State<Data>, Action<Data>>>(reducer, initialState)

	const loadedOnce = useRef(false)

	const loadData = useCallback(async (...args: Args) => {
		loadedOnce.current = true

		try {
			dispatch({ type: ACTION_TYPE.LOADING, payload: null })
			const { data } = await apiCall(...args)
			dispatch({ type: ACTION_TYPE.SUCCESS, payload: data })
		} catch (e) {
			dispatch({ type: ACTION_TYPE.FAILURE, payload: null })
		}
	}, [apiCall])

	const resetState = useCallback(() => {
		dispatch({ type: ACTION_TYPE.SUCCESS, payload: null })
	}, [])

	return [state, loadData, resetState]
}
