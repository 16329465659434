import React from 'react'
import ReactQueryParams from 'react-query-params'
import moment from 'moment-timezone'
import cx from 'classnames'
import i18next from 'i18next'

import * as PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { forEach, get, head, isEqual, last } from 'lodash'
import { Col, Row } from 'antd'
import qs from 'qs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faPrint, faDownload } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

import { CODE_PORTION_STATES, ENDPOINTS, FILTER_ORDER_SELECTORS, MODAL } from '../../utils/enums'
import { downloadFile, fileNameFromResponse } from '../../utils/upload'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { downloadReq, patchReq } from '../../utils/request'
import { formatDate } from '../../utils/utils'

import { loadCodePortion, loadCodePortionCodes } from '../../actions/codePortionsActions'

import Breadcrumbs from '../../components/Breadcrumb'
import DialogModal from '../../components/modals/DialogModal'
import CodesTable from './components/CodesTable'

import { history } from '../../utils/history'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'
import Pagination from '../../atoms/Pagination'
import { initState } from '../../reducers/selectedFiltersOrdersReducer'

import ListItemsLimit from '../../atoms/ListImtesLimit'
import DownloadModal from './components/DownloadModal'
import Button from '../../components/buttons/Button'

const queryParams = {
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'code:DESC',
		allowedValues: ['code', 'serialNumber', 'collectionPlace']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class CodePortionsPage extends ReactQueryParams {
	static propTypes = {
		actions: PropTypes.shape({
			loadPortion: PropTypes.func.isRequired
		}),
		portion: PropTypes.shape({
			data: PropTypes.shape({
				id: PropTypes.number.isRequired,
				municipality: PropTypes.string.isRequired,
				containersCount: PropTypes.string.isRequired,
				state: PropTypes.oneOf(Object.values(CODE_PORTION_STATES)).isRequired,
				createdAt: PropTypes.string.isRequired
			}),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		codes: PropTypes.shape({
			data: PropTypes.shape({
				codes: PropTypes.arrayOf(PropTypes.shape({
					id: PropTypes.number.isRequired,
					code: PropTypes.string.isRequired,
					serialNumber: PropTypes.string.isRequired,
					collectionPlace: PropTypes.string
				})).isRequired
			}),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		selectedFiltersOrders: PropTypes.object.isRequired
	}

	state = {
		modal: null,
		modalData: null
	}

	defaultQueryParams = {
		[queryParams.order]: null,
		[queryParams.page]: null,
		[queryParams.limit]: null
	}

	breadcrumbsItems = () => {
		const { portion } = this.props
		const { data } = portion

		return {
			items: [{
				key: 'page.administration.breadcrumbs',
				name: locale['page.administration.breadcrumbs']
			}, {
				key: 'page.codePortions.breadcrumbs',
				name: locale['page.codePortions.breadcrumbs'],
				link: locale['path.administration.codePortions']
			}, {
				key: 'page.codePortions.detail',
				name: data ? `${data.municipality || locale['codePortion.type.ANONYMOUS']} - ${formatDate(data.createdAt)}` : '...'
			}]
		}
	}

	componentDidMount() {
		this._mounted = true
		const { actions, selectedFiltersOrders, match } = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// Remove unwanted query params from query string
		const query = qs.stringify(filtersOrder)
		history.replace(history.location.pathname, { search: query })

		const codePortionID = get(match, 'params.codePortionID')

		actions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE, filtersOrder)
		actions.loadPortion(get(match, 'params.codePortionID'))
		actions.loadCodes(codePortionID, filtersOrder)
	}

	componentWillUnmount() {
		const { actions } = this.props
		actions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE, initState[FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE])

		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onChangeFilterOrder = (filters) => {
		const { actions, selectedFiltersOrders, match } = this.props
		const filterOrder = { ...selectedFiltersOrders }

		forEach(filters, ({ type, value }) => {
			let newFilterOrder
			switch (type) {
				case queryParams.order:
					newFilterOrder = `${value.name}:${value.sort}`
					break
				case queryParams.limit:
				case queryParams.page:
					newFilterOrder = (value) ? `${value}` : null
					break
				default:
					break
			}
			filterOrder[type] = newFilterOrder
			if (type === queryParams.search) {
				filterOrder[queryParams.page] = 1
			}
		})

		const codePortionID = get(match, 'params.codePortionID')
		const normalized = normalizeQueryParams(queryTypes, { ...filterOrder })
		this.setQueryParams(normalized)

		actions.loadCodes(codePortionID, normalized)
		actions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE, normalized)
	}

	openDownloadModal = (portion) => {
		this.setState({ modal: MODAL.CODE_PORTION_EXPORT_DIALOG, modalData: portion })
	}

	downloadCodePortion = async (page, limit, withAddress) => {
		const { portion } = this.props

		try {
			this.closeModal()
			const response = await downloadReq(ENDPOINTS.EXPORT_CODE_PORTION(portion.data?.id), { page, limit, withAddress })
			const name = fileNameFromResponse(response)
			downloadFile({ name }, response.data)
		} catch (e) {
			// Error
		}
	}

	printCodePortion = async () => {
		const { actions, match } = this.props
		const codePortionID = get(match, 'params.codePortionID')

		try {
			const response = await patchReq(ENDPOINTS.CODE_PORTION(codePortionID), null, { isActive: true })

			actions.loadPortion(codePortionID)

			this.closeModal()

			return response
		} catch (error) {
			return Promise.reject(error)
		}
	}

	onPrint = (portion) => {
		this.setState({
			modal: MODAL.PRINT_CODE_PORTION,
			modalData: portion
		})
	}

	closeModal = () => {
		this.setState({ modal: null, modalData: null })
	}

	render() {
		const {
			selectedFiltersOrders, portion, codes
		} = this.props

		const { modal, modalData } = this.state

		if (portion.isLoading || (!portion.data && !portion.isFailure)) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !portion.isLoading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['pages.codePortions.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		if (portion.isFailure) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={'table-loading'}>
							<div>
								<span>{locale['pages.codePortions.detail.failure']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		const order = selectedFiltersOrders.order || ''
		const { data } = portion
		const sortingColumn = head(order.split(':'))
		const sortingDirection = last(order.split(':'))

		return (
			<>
				<Breadcrumbs {...this.breadcrumbsItems()}/>
				<div className={'box'}>
					<div className={'box-title'}>
						{`${data.municipality} - ${formatDate(data.createdAt)}`}
					</div>
					<div className={'box-content'} style={{ padding: '40px' }}>
						<div className={'box-body'} style={{ padding: 0 }}>
							<div className={'row'}>
								<div className={'col-md-4'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Obec')}</label>
										<div>{data.municipality}</div>
									</div>
								</div>
								<div className={'col-md-4'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Stav')}</label>
										<div>{locale[`codePortion.state.${data.state}`]}</div>
									</div>
								</div>
								<div className={'col-md-4'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Počet zberných nádob')}</label>
										<div>{data.containersCount}</div>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-8'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Poznámka')}</label>
										<div>{data.note || '-'}</div>
									</div>
								</div>
								<div className={'col-md-4'}>
									<div className={'info-wrapper'}>
										<label>{locale['common.updatedAt']}</label>
										<div>{moment(data.createdAt).format(locale['common.date.format'])}</div>
									</div>
								</div>
							</div>
							<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
								<Col>
									<Button
										onClick={() => this.openDownloadModal(portion)}
									>
										<FontAwesomeIcon icon={faDownload}/>
										<span>{locale['common.download']}</span>
									</Button>
								</Col>
								{get(portion, 'data.state') === CODE_PORTION_STATES.CREATED &&
								<Col>
									<Button
										onClick={this.onPrint}
									>
										<FontAwesomeIcon icon={faPrint}/>
										<span>{locale['common.print']}</span>
									</Button>
								</Col>}
							</Row>
						</div>
					</div>
				</div>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.codePortions.breadcrumbs']}
					</div>
					<div className={'box-content'}>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<CodesTable
										onSort={(value) => this.onChangeFilterOrder([{
											type: queryParams.order,
											value
										}])}
										sortingColumn={sortingColumn}
										sortingDirection={sortingDirection}
										codes={codes}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder([{ type: queryParams.limit, value: limit }])}
									/>
									<Pagination
										pages={get(codes, 'data.context.pages')}
										page={get(codes, 'data.context.page')}
										onPage={(value) => this.onChangeFilterOrder([{
											type: queryParams.page,
											value
										}])}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DialogModal
					shown={modal === MODAL.PRINT_CODE_PORTION}
					cancelHandler={this.closeModal}
					acceptHandler={() => this.printCodePortion(modalData)}
					message={locale['pages.codePortions.print.message']}
					title={locale['pages.codePortions.print.title']}
					acceptTitle={locale['pages.codePortions.print.accept']}
				/>
				<DownloadModal
					shown={modal === MODAL.CODE_PORTION_EXPORT_DIALOG}
					type={portion.data?.type}
					onDownload={this.downloadCodePortion}
					count={portion.data?.containersCount || 0}
					dismissHandler={this.closeModal}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	portion: state.codePortions.portion,
	codes: state.codePortions.codes,
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.CODE_PORTION_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	actions: {
		loadPortion: bindActionCreators(loadCodePortion, dispatch),
		loadCodes: bindActionCreators(loadCodePortionCodes, dispatch),
		selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(CodePortionsPage)
