import React, { useLayoutEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import Chart from 'chart.js/auto'

import { map } from 'lodash'

import { Col, Row, Select as AntSelect } from 'antd'

import { Text } from '../../../../components/Typography'

import StatisticsTooltip from './StatisticsTooltip'
import ChartLegend from '../../../../components/ChartLegend'

const EmptyTextWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`

const CenterText = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ChartWrapper = styled.div`
	position: relative;
	margin-top: 30px;
	margin-bottom: 15px;
`

const Select = styled(AntSelect)`
	min-width: 100px;

	&.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 16px;
	}
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`

type Data = {
	color: string
	label: string
	value: number
}

type Props = {
	title: string
	tooltip?: {
		title: string
		description: string
	}
	emptyText: string
	data: Data[]
	centerText?: React.ReactNode | string

	filter?: {
		options: { value: string | number, label: string }[]
		value?: string | number
		onChange: (value: any) => void
	}
}

const PieChartStatistics = ({ title, tooltip, emptyText, filter, data, centerText }: Props) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null)

	const { labels, colors, values } = useMemo(() => ({
		labels: map(data, (dataItem) => dataItem.label),
		colors: map(data, (dataItem) => dataItem.color),
		values: map(data, (dataItem) => dataItem.value)
	}), [data])

	const legend = useMemo(() => map(data, (dataItem) => ({
		...dataItem,
		value: `${dataItem.value} %`
	})), [data])

	useLayoutEffect(() => {
		let chart: Chart

		if (chartRef.current) {
			const context = chartRef.current!.getContext('2d')

			chart = new Chart(context!, {
				type: 'doughnut',
				data: {
					labels,
					datasets: [
						{
							data: values,
							backgroundColor: colors,
							borderWidth: 0
						}
					]
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					// cutout: '60%',
					plugins: {
						datalabels: {
							display: false
						},
						legend: {
							display: false
						},
						tooltip: {
							// Disable the on-canvas tooltip
							enabled: false,

							external(context: any) {
								// Tooltip Element
								let tooltipEl = document.getElementById('chartjs-tooltip')
								let labelEl = document.getElementById('chartjs-tooltip-label')
								let valueEl = document.getElementById('chartjs-tooltip-value')

								// Create element on first render
								if (!tooltipEl) {
									tooltipEl = document.createElement('div')
									tooltipEl.id = 'chartjs-tooltip'
									document.body.appendChild(tooltipEl)
								}

								if (!labelEl) {
									labelEl = document.createElement('span')
									labelEl.id = 'chartjs-tooltip-label'
									tooltipEl.appendChild(labelEl)
								}

								if (!valueEl) {
									valueEl = document.createElement('strong')
									valueEl.id = 'chartjs-tooltip-value'
									tooltipEl.appendChild(valueEl)
								}

								// Hide if no tooltip
								const tooltipModel = context.tooltip
								if (tooltipModel.opacity === 0) {
									tooltipEl.style.opacity = '0'
									return
								}

								// Set caret Position
								tooltipEl.classList.remove('above', 'below', 'no-transform')
								if (tooltipModel.yAlign) {
									tooltipEl.classList.add(tooltipModel.yAlign)
								} else {
									tooltipEl.classList.add('no-transform')
								}

								function getBody(bodyItem: any) {
									return bodyItem.lines
								}

								// Set Text
								if (tooltipModel.body) {
									const bodyLines = tooltipModel.body.map(getBody)
									const [label, value] = bodyLines[0][0].split(': ')

									labelEl.innerHTML = label
									valueEl.innerHTML = `${value}%`
								}

								const position = context.chart.canvas.getBoundingClientRect()

								// Display, position, and set styles for font
								tooltipEl.style.opacity = '1'
								tooltipEl.style.position = 'absolute'
								tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`
								tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY - 20}px`
								tooltipEl.style.font = 'Public Sans'
								tooltipEl.style.padding = '4px 8px'
								tooltipEl.style.pointerEvents = 'none'
								tooltipEl.style.backgroundColor = 'white'
								tooltipEl.style.borderRadius = '4px'
								tooltipEl.style.boxShadow = '0px 4px 30px rgba(0, 0, 0, 0.12), 0px 2px 16px rgba(12, 34, 51, 0.15)'
								tooltipEl.style.fontSize = '14px'
								tooltipEl.style.zIndex = '1'

								labelEl.style.color = '#4B5563'
								valueEl.style.color = '#111827'
								valueEl.style.marginLeft = '8px'
							}
						}

					}
				}
			})
		}

		return () => { chart?.destroy() }
	}, [colors, data, labels, values])

	return (
		<Wrapper>
			<Row gutter={8}>
				<Col>
					<Text className={'bold'}>{title}</Text>
				</Col>
				{!!tooltip &&
				<Col flex={'1'}>
					<StatisticsTooltip {...tooltip}/>
				</Col>}
				{!!filter &&
				<Col>
					<Select
						onChange={filter.onChange}
						value={filter.value}
					>
						{map(filter.options, (item) => (
							<Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
						))}
					</Select>
				</Col>}
			</Row>
			{!data.length &&
			<EmptyTextWrapper>
				<Text>{emptyText}</Text>
			</EmptyTextWrapper>}
			{!!data.length &&
			<>
				<ChartWrapper>
					<CenterText>
						{centerText}
					</CenterText>
					<canvas
						height={216}
						ref={chartRef}
					/>
				</ChartWrapper>
				<ChartLegend items={legend}/>
			</>}
		</Wrapper>
	)
}

export default React.memo(PieChartStatistics)
