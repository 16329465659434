import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import styled from 'styled-components'
import Supercluster from 'supercluster'
import { getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, forEach, get, groupBy, map } from 'lodash'
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api'

import { getCollectionRoundDetail, getCollectionRoundMap } from '../../../redux/collectionRounds/selectors'
import { loadCollectionRoundMap } from '../../../redux/collectionRounds/actions'

import locale from '../../../resources/locale'

import InvalidClusterIcon from '../../../resources/images/ic-marker-invalid.svg'
import ValidClusterIcon from '../../../resources/images/ic-marker-valid.svg'
import UncollectedClusterIcon from '../../../resources/images/ic-marker-uncollected.svg'

import MapFilter, { FormValues } from './MapFilter'
import MapPin from './MapPin'
import { FILTER, PIN_TYPE } from '../../../utils/enums'
import { Text } from '../../../components/Typography'

const ClusterItem = styled.div`
	position: relative;
	height: 35px;
	width: 35px;
	background-size: contain;
	background: url("${ValidClusterIcon}") center no-repeat;
	background-size: contain !important;

	&.${PIN_TYPE.INVALID} {
		background: url("${InvalidClusterIcon}") center no-repeat;
	}

	&.${PIN_TYPE.UNCOLLECTED} {
		background: url("${UncollectedClusterIcon}") center no-repeat;
	}

	&::before {
		content: attr(data-count);
		font-size: 10px;
		font-weight: 600;
		position: absolute;
		top: 50%;
		color: white;
		transform: translateY(-60%);
		left: 0;
		width: 100%;
		text-align: center;
	}
`

const Wrapper = styled.div`
	position: relative;
	display: flex;
	height: 650px;
	background: white;
`

const InvalidGPS = styled.div`
	position: absolute;
	top: 32px;
	right: 32px;
	z-index: 2;
	padding: 16px;
	background: white;
	border-radius: 8px;
	box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
`

const mapStyle = {
	width: '100%',
	height: '650px'
}

const defaultBounds: [{ lat: number, lng: number }, { lat: number, lng: number }] = [
	{
		lng: 17.032417,
		lat: 48.195171
	},
	{
		lng: 17.216055,
		lat: 48.122503
	}
]

const getFitBounds = (items: any[]) => {
	const bounds = new window.google.maps.LatLngBounds()
	if (items.length) {
		forEach(items, (pin) => {
			bounds.extend({
				lng: pin.lon,
				lat: pin.lat
			})
		})
	} else {
		forEach(defaultBounds, (pin) => {
			bounds.extend({
				lng: pin.lng,
				lat: pin.lat
			})
		})
	}

	return bounds
}

const selector = getFormValues(FILTER.COLLECTION_ROUND_MAP_FILTERS)

type Box = [number, number, number, number]

type Props = {
	active: boolean
}

const getMapPins = (pin: any, index: number, type: PIN_TYPE) => {
	if (pin.properties.point_count > 1) {
		return (
			<OverlayView
				key={index}
				mapPaneName={'overlayMouseTarget'}
				position={{
					lat: get(pin, 'geometry.coordinates[1]'),
					lng: get(pin, 'geometry.coordinates[0]')
				}}
			>
				<ClusterItem
					className={cx({ [type]: type })}
					data-count={pin.properties.point_count}
				/>
			</OverlayView>
		)
	}

	return (
		<OverlayView
			key={index}
			mapPaneName={'overlayMouseTarget'}
			position={{
				lat: get(pin, 'geometry.coordinates[1]'),
				lng: get(pin, 'geometry.coordinates[0]')
			}}
		>
			<MapPin pin={get(pin, 'properties')}/>
		</OverlayView>
	)
}

const CollectionRoundMap = ({ active }: Props) => {
	const mapRef = useRef<any>()
	const dispatch = useDispatch()
	const detail = useSelector(getCollectionRoundDetail)
	const mapData = useSelector(getCollectionRoundMap)
	const filters = useSelector(selector) as FormValues

	const [zoom, setZoom] = useState(8)
	const [bounds, setBounds] = useState<Box>(() => [defaultBounds[0].lng, defaultBounds[1].lat, defaultBounds[1].lng, defaultBounds[0].lat])
	const [position, setPosition] = useState(() => ({
		lat: 48.73946,
		lng: 19.15349
	}))

	const [validPins, invalidPins, uncollectedPins] = useMemo(() => {
		if (mapData.data?.pins) {
			const grouped = groupBy(mapData.data.pins, 'type')

			return [
				filters?.collected ? grouped[PIN_TYPE.VALID] || [] : [],
				filters?.invalid ? grouped[PIN_TYPE.INVALID] || [] : [],
				filters?.notCollected ? grouped[PIN_TYPE.UNCOLLECTED] || [] : []
			]
		}

		return [[], [], []]
	}, [filters, mapData.data?.pins])

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyA95cBM6BycpYSdc918ut_HOlbr0kR-p0s'
	})

	const mapOptions = useMemo(() => ({
		fullscreenControl: false,
		streetViewControl: false,
		mapTypeControlOptions: isLoaded
			? {
				position: window.google.maps.ControlPosition.BOTTOM_LEFT,
				style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR
			}
			: undefined
	}), [isLoaded])

	const [validCluster, invalidCluster, uncollectedCluster] = useMemo(() => {
		const valid = new Supercluster({
			maxZoom: 18,
			radius: 75
		})
		valid.load([])

		const invalid = new Supercluster({
			maxZoom: 18,
			radius: 75
		})
		invalid.load([])

		const uncollected = new Supercluster({
			maxZoom: 18,
			radius: 75
		})
		uncollected.load([])

		return [valid, invalid, uncollected]
	}, [])

	const handleLoad = useCallback((map) => {
		mapRef.current = map
	}, [])

	const handleUnload = useCallback(() => {
		mapRef.current = null
	}, [])

	const handleBoundsChange = useMemo(() => debounce(() => {
		if (mapRef.current) {
			const bounds = mapRef.current.getBounds()
			const sw = bounds.getSouthWest()
			const ne = bounds.getNorthEast()

			setBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()])
		}
	}, 500), [])

	const handleChangeZoom = useMemo(() => debounce(() => {
		if (mapRef.current) {
			setZoom(mapRef.current.zoom)
		}
	}, 500), [])

	useEffect(() => {
		if (detail.data) {
			dispatch(loadCollectionRoundMap({ collectionRoundID: detail.data.id }))
		}
	}, [detail.data, dispatch])

	useEffect(() => {
		const mapPins = (pin: any): Supercluster.PointFeature<any> => ({
			type: 'Feature',
			properties: {
				...pin
			},
			geometry: {
				type: 'Point',
				coordinates: [pin.lon, pin.lat]
			}
		})

		if (isLoaded) {
			validCluster.load(map(validPins, mapPins))
			invalidCluster.load(map(invalidPins, mapPins))
			uncollectedCluster.load(map(uncollectedPins, mapPins))

			const allPins = [...validPins, ...invalidPins, ...uncollectedPins]

			if (allPins.length === 1) {
				setPosition({
					lat: allPins[0].lat,
					lng: allPins[0].lng
				})
				setZoom(17)
			} else if (allPins.length) {
				const bounds = getFitBounds(allPins)
				const sw = bounds.getSouthWest()
				const ne = bounds.getNorthEast()

				if (sw && ne) {
					mapRef.current?.fitBounds(bounds)
				}
			} else {
				setZoom(zoom - 1)
			}
		}
	}, [validPins, invalidPins, uncollectedPins, validCluster, invalidCluster, uncollectedCluster, isLoaded, active])

	const validMapItems = useMemo(() => {
		const clusters = validCluster?.getClusters(bounds, zoom) || []
		return map(clusters, (pin, index) => getMapPins(pin, index, PIN_TYPE.VALID))
	}, [validCluster, bounds, zoom])

	const invalidMapItems = useMemo(() => {
		const clusters = invalidCluster?.getClusters(bounds, zoom) || []
		return map(clusters, (pin, index) => getMapPins(pin, index, PIN_TYPE.INVALID))
	}, [invalidCluster, bounds, zoom])

	const uncollectedMapItems = useMemo(() => {
		const clusters = uncollectedCluster?.getClusters(bounds, zoom) || []
		return map(clusters, (pin, index) => getMapPins(pin, index, PIN_TYPE.UNCOLLECTED))
	}, [uncollectedCluster, bounds, zoom])

	return (
		<Wrapper>
			<MapFilter/>
			{!!mapData.data &&
			<InvalidGPS>
				<Text>{locale['page.collectionRounds.detail.map.invalidGPS']}: <strong>{mapData.data.invalidItemsCount || 0}</strong></Text>
			</InvalidGPS>}
			{isLoaded &&
			<GoogleMap
				onLoad={handleLoad}
				onUnmount={handleUnload}
				mapContainerStyle={mapStyle}
				options={mapOptions}
				zoom={zoom}
				center={position}
				onZoomChanged={handleChangeZoom}
				onBoundsChanged={handleBoundsChange}
			>
				{validMapItems}
				{invalidMapItems}
				{uncollectedMapItems}
			</GoogleMap>}
		</Wrapper>
	)
}

export default CollectionRoundMap
