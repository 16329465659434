import React from 'react'
import { Field, reduxForm, registerField, unregisterField } from 'redux-form'
import PropTypes from 'prop-types'
import { map, debounce, filter } from 'lodash'
import { Col, Row } from 'antd'
import i18next from 'i18next'
import Modal from './Modal'
import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import SelectField from '../../atoms/SelectField'

import { validate, validatorOptions } from '../../utils/validator'
import { permissions } from '../../utils/select'
import { ENDPOINTS, FORMS, PERMISSIONS } from '../../utils/enums'
import { getReq } from '../../utils/request'
import Button from '../buttons/Button'

const validateEmail = validate([validatorOptions.REQUIRED, validatorOptions.EMAIL])
const validatePermissions = validate([validatorOptions.REQUIRED])
const validateMunicipalities = validate([validatorOptions.ARRAY])

class CreateUser extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		inviteHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		municipality: PropTypes.object,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			role: null
		}
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	searchMunicipalities = (search, callback) => {
		const context = { order: 'name:ASC' }
		if (search && search.length) {
			context.search = search
		}
		getReq(ENDPOINTS.MUNICIPALITIES, context).then((response) => {
			const items = map(response.data.municipalities, (item) => ({
				label: item.name,
				value: item.id,
				key: item.id
			}))
			return callback(items)
		}).catch(() => callback([]))
	}

	handleSubmit = (values) => {
		const { inviteHandler, municipality } = this.props
		const data = {
			email: values.email,
			permissions: values.permissions
		}
		if (!municipality && data.permissions !== PERMISSIONS.ADMINISTRATOR) {
			data.municipalities = map(values.municipalities, (m) => m.value)
		}
		inviteHandler(values)
	}

	availablePermissions = () => {
		const { municipality } = this.props
		if (municipality) {
			return filter(permissions, (p) => p.value !== PERMISSIONS.ADMINISTRATOR)
		}
		return permissions
	}

	municipalitySelectFieldOptions = {
		label: i18next.t('loc:Obce'),
		required: true,
		isMulti: true,
		showLabel: true,
		loadOptions: debounce(this.searchMunicipalities, 300)
	}

	registrationTypeOptions = [{
		value: false, label: locale['page.users.registrationWithEmail']
	}, {
		value: true, label: locale['page.users.registrationWithoutEmail']
	}]

	permissionsSelectFieldOptions = () => ({
		label: i18next.t('loc:Oprávnenia'),
		required: true,
		showLabel: true,
		isMulti: false,
		options: this.availablePermissions()
	})

	emailFieldOptions = {
		label: i18next.t('loc:Email'),
		required: true
	}

	municipalitiesField = (
		<Field
			name='municipalities'
			placeholder={locale['field.placeholder.municipality']}
			component={AsyncSelectField}
			props={this.municipalitySelectFieldOptions}
			validate={validateMunicipalities}
		/>
	)

	updateMunicipalitySelect = (permissions) => {
		const { municipality } = this.props
		if (!municipality) {
			if (permissions === PERMISSIONS.ADMINISTRATOR) {
				unregisterField(FORMS.CREATE_USER, 'municipalities', this.municipalitiesField)
			} else {
				registerField(FORMS.CREATE_USER, 'municipalities')
			}
			this.setState({
				role: permissions
			})
		}
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	render() {
		const { handleSubmit, dismissHandler, shown, inviteHandler } = this.props
		const { role } = this.state

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit((data) => inviteHandler(data))} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['page.users.add']}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='email'
									type={'email'}
									placeholder={locale['field.placeholder.email']}
									component={TextInputField}
									props={this.emailFieldOptions}
									validate={validateEmail}
								/>
							</div>
							<div className={'col-12'}>
								<Field
									name='permissions'
									placeholder={locale['field.placeholder.permissions']}
									component={SelectField}
									props={this.permissionsSelectFieldOptions()}
									validate={validatePermissions}
									handleOnChange={this.updateMunicipalitySelect}
								/>
							</div>
							{/* {(!adminPermissions.includes(role) && !!role) && */}
							{/* <div className={'col-12'}> */}
							{/*	<Field */}
							{/*		name='registrationType' */}
							{/*		placeholder={locale['field.placeholder.registrationType']} */}
							{/*		label={'Spôsob registrácie'} */}
							{/*		component={SelectField} */}
							{/*		options={this.registrationTypeOptions} */}
							{/*	/> */}
							{/* </div>} */}
							<div className={'col-12'}>
								{role && role !== PERMISSIONS.ADMINISTRATOR ? this.municipalitiesField : null}
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{locale['page.users.send.invitation']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.CREATE_USER,
	touchOnChange: true,
	destroyOnUnmount: true,
	initialValues: {
		registrationType: false
	}
})(CreateUser)
