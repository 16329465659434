import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

import TableHead from '../../atoms/TableHead'
import MunicipalitiesTableRow from './MunicipalitiesTableRow'
import { EmptyTableState } from '../EmptyTableState'

class MunicipalitiesTable extends React.Component {
	static propTypes = {
		municipalities: PropTypes.arrayOf(PropTypes.object),
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		municipality: PropTypes.object,
		onSort: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		sortingDirection: PropTypes.string,
		sortingColumn: PropTypes.string
	}

	render() {
		const {
			municipalities,
			loading,
			onSetActive,
			onDetail,
			sortingDirection,
			sortingColumn,
			onSort
		} = this.props

		const rows = map(municipalities, (municipality, index) => (
			<MunicipalitiesTableRow
				key={index}
				municipality={municipality}
				onSetActive={onSetActive}
				onDetail={onDetail}
				countIndex={index}
			/>
		))

		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={sortingColumn === 'name'}
								title={i18next.t('loc:Názov obce')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'type'}
								isSorting={sortingColumn === 'type'}
								title={i18next.t('loc:Typ obce')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'usersCount'}
								isSorting={sortingColumn === 'usersCount'}
								title={i18next.t('loc:Počet používateľov')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'collectionRoundsCount'}
								isSorting={sortingColumn === 'collectionRoundsCount'}
								title={i18next.t('loc:Počet odberov')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'lastCollectionRound'}
								isSorting={sortingColumn === 'lastCollectionRound'}
								title={i18next.t('loc:Dátum posledného zberu')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'useGeneralCollectionRound'}
								className={'text-center'}
								isSorting={sortingColumn === 'useGeneralCollectionRound'}
								title={i18next.t('loc:Všeobecný zvoz')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<TableHead
								name={'isActive'}
								className={'text-center'}
								isSorting={sortingColumn === 'isActive'}
								title={i18next.t('loc:Stav')}
								onSort={onSort}
								initDirection={sortingDirection}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && municipalities && !municipalities.length && <EmptyTableState title={locale['page.municipalities.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.users.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: state.user.municipality
})

export default connect(mapStateToProps)(MunicipalitiesTable)
