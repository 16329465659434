import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Popover from 'react-tiny-popover'

import { getSelectedMunicipality } from '../../../redux/municipalities/selectors'

import { Text } from '../../../components/Typography'

import PinIcon from '../../../resources/images/icon-uncollected-pin.svg'
import { history } from '../../../utils/history'

const Icon = styled.div`
	height: 35px;
	width: 35px;
	position: relative;
	background: url("${PinIcon}") center no-repeat;
	background-color: transparent;
	background-size: contain !important;
`

const InfoWrapper = styled.div`
	padding-bottom: 20px;
`

const Info = styled.div`
	width: 200px;
	background: white;
	padding: 14px;
	border-radius: 8px;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		width: 15px;
		height: 15px;
		border-radius: 4px;
		bottom: 12px;
		background-color: white;
		z-index: 10;
	}
`

type Props = {
	pin: any
}

const MapPin = ({ pin }: Props) => {
	const [t] = useTranslation()
	const [open, setOpen] = useState(false)
	const municipality = useSelector(getSelectedMunicipality)

	const handleClick = () => {
		if (municipality) {
			history.push(t('paths:LISTS.collectionPlaces.detail.municipalityPath', {
				municipalityID: municipality.id,
				collectionPlaceID: pin.id
			}))
		} else {
			history.push(t('paths:LISTS.collectionPlaces.detail.path', {
				collectionPlaceID: pin.id
			}))
		}
	}

	return (
		<Popover
			isOpen={open}
			position={'top'}
			containerStyle={{ zIndex: '10' }}
			content={(
				<InfoWrapper>
					<Info>
						{!!pin.name && <Text>{pin.name}</Text>}
						<Text style={{ marginBottom: 13 }}>{pin.lat}, {pin.lon}</Text>
						<Text className={'grey'}>{t('DashboardPage.containersCount', { count: Number(pin.containersCount) })}</Text>
					</Info>
				</InfoWrapper>
			)}
		>
			<Icon
				onMouseEnter={() => setOpen(true)}
				onMouseLeave={() => setOpen(false)}
				className={pin.type}
				onClick={handleClick}
			/>
		</Popover>
	)
}

export default MapPin
