import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const TextAreaInputField = ({ input, name, label, placeholder, required, showLabel, meta: { touched, error } }) => (
	<div className={cx('input-wrapper', { error: touched && error })}>
		{showLabel && <label htmlFor={name}>{label}</label>}
		<textarea {...input} rows={5} placeholder={placeholder} required={required}/>
		<div className={'close-error'}>
			<div className={'tooltip'}>
				{touched ? error : ''}
			</div>
		</div>
	</div>
)

TextAreaInputField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.object.isRequired,
	required: PropTypes.bool,
	type: PropTypes.string
}

TextAreaInputField.defaultProps = {
	showLabel: true,
	placeholder: '',
	label: '',
	required: false
}

export default TextAreaInputField
