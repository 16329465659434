import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const Loading = styled(Spin)`
	height: 100%;
	width: 100%;
`

const Wrapper = styled.div`
	height: 473px;
	position: relative;
	z-index: 1;
	border: 1px solid ${({ theme }) => theme.colors.border};
	border-radius: 10px;

	&.borderless {
		padding: 0;
		min-height: 242px;
		height: unset;
		border: none;
	}

	@media (min-width: 1441px) {
		padding: 40px 48px;
	}

	@media (max-width: 1440px) {
		padding: 20px 28px;
	}

	@media (max-width: 767px) {
		padding: 20px 0 0;
		border-radius: 0;
		border: none;
		border-top: 1px solid ${({ theme }) => theme.colors.border};

		&.expanded {
			height: auto;
		}

		&.graph {
			min-height: 364px;
			height: auto;
		}
	}
`

type Props = PropsWithChildren<{
	className?: string
	isLoading: boolean
}>

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const StatisticsContainer = ({ isLoading, className, children }: Props) => (
	<Wrapper className={className}>
		{isLoading &&
		<Loading indicator={antIcon}/>}
		{!isLoading && children}
	</Wrapper>
)

export default StatisticsContainer
