import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'

import { map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

import { Col, Row, Tabs } from 'antd'

import { BULK_COLLECTIONS_TAB_PANE } from '../../utils/enums'

import { filtersActions, getBulkCollectionsTab } from '../../redux/filters'

import { getSelectedMunicipality } from '../../redux/municipalities/selectors'

import Breadcrumb from '../../components/Breadcrumb'

import { Box, BoxContent, BoxHeader } from '../../layouts/Box'
import SegmentedButton from '../../components/buttons/SegmentedButton'
import { HeadingThree } from '../../components/Typography'
import WasteTypesSummary from './components/WasteTypesSummary'
import CustomersSummary from './components/CustomersSummary'
import Collections from './components/Collections'
import CollectionYardCollections from './components/CollectionYardCollections'

const filtersOverflowVisibe = { overflow: 'visible' }

const { TabPane } = Tabs

type Props = RouteComponentProps<{ municipalityID?: string }>

const BulkCollectionsPage = ({ match }: Props) => {
	const [t] = useTranslation()
	const tab = useSelector(getBulkCollectionsTab)
	const dispatch = useDispatch()
	const { municipalityID } = match.params

	const municipality = useSelector(getSelectedMunicipality)

	const handleChangeTab = useCallback((tab: BULK_COLLECTIONS_TAB_PANE | null) => {
		dispatch(filtersActions.saveFilters({ key: 'bulkCollectionsTab', filters: tab || BULK_COLLECTIONS_TAB_PANE.STATS }))
	}, [dispatch])

	const breadcrumbs = useMemo(() => {
		if (municipality || municipalityID === `${municipality?.id}`) {
			return [{
				key: 'path.municipalities',
				name: t('paths:MUNICIPALITY.title')
			}, {
				key: 'path.administration.municipalities.detail',
				name: municipality ? municipality.name : t('loading')
			}, {
				key: 'path.REGISTRY',
				name: t('paths:REGISTRY.title')
			}, {
				key: 'path.REGISTRY.collectionYard',
				name: t('paths:REGISTRY.collectionYard.title'),
				link: t('paths:REGISTRY.collectionYard.municipalityPath', { municipalityID })
			}]
		}

		return [{
			key: 'path.REGISTRY',
			name: t('paths:REGISTRY.title')
		}, {
			key: 'path.REGISTRY.collectionYard',
			name: t('paths:REGISTRY.collectionYard.title')
		}]
	}, [municipalityID, municipality, t])

	return (
		<Box className={'padded'}>
			<Tabs
				activeKey={tab}
				style={filtersOverflowVisibe}
				renderTabBar={({ panes }) => (
					<BoxHeader>
						<Row>
							<Col xl={10} md={24} sm={24} xs={24}>
								<HeadingThree>{t('BulkCollectionsPage.title')}</HeadingThree>
							</Col>
							<Col xl={14} md={24} sm={24} xs={24}>
								<SegmentedButton
									value={tab}
									onChange={handleChangeTab}
									items={map(panes, ({ props, key }) => ({
										value: key, label: props.tab
									}))}
								/>
							</Col>
							<Col span={24}>
								<Breadcrumb items={breadcrumbs}/>
							</Col>
						</Row>
					</BoxHeader>
				)}
			>
				<TabPane tab={t('BulkCollectionsPage.statistics')} key={BULK_COLLECTIONS_TAB_PANE.STATS}>
					{!!municipality?.id &&
					<BoxContent className={'padded'}>
						<WasteTypesSummary municipalityID={match.params.municipalityID}/>
					</BoxContent>}
					<BoxContent className={cx('padded', { 'top-margin': !!municipality?.id })}>
						<CollectionYardCollections municipalityID={match.params.municipalityID}/>
					</BoxContent>
					<BoxContent className={'padded top-margin'}>
						<CustomersSummary municipalityID={match.params.municipalityID}/>
					</BoxContent>
				</TabPane>
				<TabPane tab={t('BulkCollectionsPage.collections')} key={BULK_COLLECTIONS_TAB_PANE.COLLECTIONS}>
					<BoxContent className={'padded'} style={filtersOverflowVisibe}>
						<Collections municipalityID={match.params.municipalityID}/>
					</BoxContent>
				</TabPane>
			</Tabs>
		</Box>
	)
}

export default BulkCollectionsPage
