import React from 'react'
import i18next from 'i18next'
import moment from 'moment-timezone'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, isEmpty, reduce, map } from 'lodash'

import Async from 'react-select/async'
import { Bar } from 'react-chartjs-2'

import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'antd'
import Breadcrumbs from '../../components/Breadcrumb'
import locale from '../../resources/locale'

import { getReq } from '../../utils/request'
import { selectedFiltersOrdersChanged } from '../../actions/selectedFiltersOrdersActions'

import {
	QUANTITY_CHART_SECTIONS,
	QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR,
	ENDPOINTS,
	FILTER_ORDER_SELECTORS
} from '../../utils/enums'
import StatisticsRangePicker from '../../atoms/StatisticsRangePicker'
import { parseNumber } from '../../utils/utils'
import { StatisticsNoDataPlaceholder } from '../../components/statistics/StatisticsNoDataPlaceholder'
import { customStyles } from '../../utils/select'

const {
	LESS_THEN_FULL,
	LESS_THEN_HALF,
	LESS_THEN_QUARTER,
	FULL_AND_MORE,
	MORE_THEN_HALF,
	NOT_COLLECTED
} = QUANTITY_CHART_SECTIONS

const queryParams = {
	fromDate: 'fromDate',
	toDate: 'toDate',
	page: 'page',
	wasteTypes: 'wasteTypes'
}

class QuantityPage extends React.Component {
	static propTypes = {
		municipality: PropTypes.object,
		selectedFiltersOrdersChanged: PropTypes.func.isRequired,
		selectedFiltersOrders: PropTypes.object.isRequired
	}

	constructor(props) {
		super(props)

		this.state = {
			statistics: {
				isLoading: true,
				isFailure: false,
				data: null
			}
		}
	}

	componentDidMount() {
		this._mounted = true
		const { selectedFiltersOrders } = this.props

		this.loadStatistics(selectedFiltersOrders)
	}

	componentWillUnmount() {
		this._mounted = false
	}

	loadWasteTypes = (keyword, callback) => {
		const { municipality } = this.props
		const context = {
			limit: 60
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		if (municipality) {
			context.municipalityID = municipality.id
		}

		getReq(ENDPOINTS.WASTE_TYPES_MUNICIPALITY, context).then((response) => {
			const items = map(get(response, 'data.types', []), (item) => ({
				value: item.id,
				label: `${item.name} - ${item.municipality.name}`
			}))
			callback(items)
		}).catch(() => callback([]))
	}

	onChangeWasteTypes = (value) => {
		this.onChangeFilterOrder([{
			type: queryParams.wasteTypes, value
		}, {
			type: queryParams.page, value: 1
		}])
	}

	onChangeFilterOrder = (filters) => {
		const { selectedFiltersOrdersChanged, selectedFiltersOrders } = this.props
		const newFilters = {
			...selectedFiltersOrders,
			...reduce(filters, (result, item) => ({
				...result,
				[item.type]: item.value
			}), {})
		}

		this.loadStatistics(newFilters)
		selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.QUANTITY_PAGE, newFilters)
	}

	onChangeRange = (value) => {
		this.onChangeFilterOrder([{
			type: queryParams.fromDate,
			value: value.from ? value.from.valueOf() : null
		}, {
			type: queryParams.toDate,
			value: value.to ? value.to.valueOf() : null
		}])
	}

	loadStatistics = async (filters) => {
		if (this._mounted) {
			this.setState({
				...this.state,
				statistics: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			try {
				const { municipality } = this.props
				const context = {
					...filters,
					wasteTypes: map(filters.wasteTypes, (item) => item.value)
				}
				if (municipality) {
					context.municipalityID = municipality.id
				}
				const response = await getReq(ENDPOINTS.QUANTITY, context)
				this.setState({
					...this.state,
					statistics: {
						data: get(response, 'data'),
						isLoading: false,
						isFailure: false
					},
					sum: get(response, 'data.sum'),
					weight: get(response, 'data.weight')
				})
			} catch (error) {
				this.setState({
					...this.state,
					statistics: {
						data: null,
						isLoading: false,
						isFailure: true
					},
					sum: null,
					weight: null
				})
			}
		}
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.statistics.breadcrumbs',
			name: locale['page.statistics.breadcrumbs']
		}, {
			key: 'page.statistics.sorting.customers',
			name: locale['page.statistics.sorting.customers']
		}]
	}

	chartOptions = {
		plugins: {
			datalabels: {
				display: false
			},
			legend: {
				labels: {
					usePointStyle: true
				}
			}
		},
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			x: [{
				stacked: true,
				categoryPercentage: 1.0,
				barPercentage: 1,
				gridLines: {
					display: false
				}
			}],
			y: [{
				stacked: true
			}]
		},
		tooltips: {
			backgroundColor: '#FFF',
			titleFontSize: 16,
			titleFontColor: '#000',
			titleFontStyle: 'bold',
			bodyFontColor: '#188efd',
			bodyFontSize: 14,
			footerFontColor: '#BBB',
			borderColor: '#c2c2c2',
			borderWidth: 1,
			displayColors: false,
			callbacks: {
				title: (tooltipItem) => `${tooltipItem[0].yLabel}`,
				label: (tooltipItem, chartData) => `${chartData.labels[tooltipItem.index]} - ${get(chartData, `datasets[${tooltipItem.datasetIndex}].label`)}`
			}
		}
	}

	render() {
		const { statistics } = this.state
		const { selectedFiltersOrders } = this.props

		let body
		if (statistics.isLoading) {
			body = (<div className={'table-loading'}>
				<div>
					<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
					<span>{locale['page.statistics.loading']}</span>
				</div>
			</div>)
		} else if (isEmpty(get(statistics, 'data.collectionRounds', []))) {
			body = (
				<StatisticsNoDataPlaceholder/>
			)
		} else {
			const datasets = reduce(get(statistics, 'data.collectionRounds'), (result, value) => ({
				[LESS_THEN_QUARTER]: [...result[LESS_THEN_QUARTER] || [], value[LESS_THEN_QUARTER]],
				[LESS_THEN_HALF]: [...result[LESS_THEN_HALF] || [], value[LESS_THEN_HALF]],
				[MORE_THEN_HALF]: [...result[MORE_THEN_HALF] || [], value[MORE_THEN_HALF]],
				[LESS_THEN_FULL]: [...result[LESS_THEN_FULL] || [], value[LESS_THEN_FULL]],
				[FULL_AND_MORE]: [...result[FULL_AND_MORE] || [], value[FULL_AND_MORE]],
				[NOT_COLLECTED]: [...result[NOT_COLLECTED] || [], value[NOT_COLLECTED]],
				labels: [...result.labels || [], value.name]
			}), { labels: [] })

			const count = datasets.labels.length

			const sumData = {
				datasets: [{
					data: datasets[LESS_THEN_QUARTER],
					label: locale[`quantity.label.${LESS_THEN_QUARTER}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.LESS_THEN_QUARTER,
					stack: 'stack'
				}, {
					data: datasets[LESS_THEN_HALF],
					label: locale[`quantity.label.${LESS_THEN_HALF}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.LESS_THEN_HALF,
					stack: 'stack'
				}, {
					data: datasets[MORE_THEN_HALF],
					label: locale[`quantity.label.${MORE_THEN_HALF}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.MORE_THEN_HALF,
					stack: 'stack'
				}, {
					data: datasets[LESS_THEN_FULL],
					label: locale[`quantity.label.${LESS_THEN_FULL}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.LESS_THEN_FULL,
					stack: 'stack'
				}, {
					data: datasets[FULL_AND_MORE],
					label: locale[`quantity.label.${FULL_AND_MORE}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.FULL_AND_MORE,
					stack: 'stack'
				}, {
					data: datasets[NOT_COLLECTED],
					label: locale[`quantity.label.${NOT_COLLECTED}`],
					backgroundColor: QUANTITY_CHART_SECTIONS_BACKGROUND_COLOR.NOT_COLLECTED,
					stack: 'stack'
				}],
				labels: datasets.labels
			}

			body = (
				<>
					<div className={'chart-wrapper scrollable'}>
						<div className={'chart'} style={{ width: `${count * 10}px`, minWidth: '100%' }}>
							<Bar
								data={sumData}
								options={this.chartOptions}
								redraw={true}
							/>
						</div>
					</div>
				</>
			)
		}

		const range = {
			from: selectedFiltersOrders.fromDate ? moment(parseNumber(selectedFiltersOrders.fromDate)) : null,
			to: selectedFiltersOrders.toDate ? moment(parseNumber(selectedFiltersOrders.toDate)) : null
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.statistics.sorting.quantity']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row gutter={[8, 8]}>
								<Col xl={10} md={24} sm={24} xs={24}>
									<Async
										defaultOptions
										isClearable
										isMulti
										onChange={this.onChangeWasteTypes}
										className={'react-select'}
										placeholder={locale['page.statistics.uncollected.wasteTypes']}
										loadOptions={this.loadWasteTypes}
										styles={customStyles}
										noOptionsMessage={() => i18next.t('loc:Žiadne možnosti')}
										loadingMessage={() => `${i18next.t('loc:Načítavam možnosti')}...`}
										value={get(selectedFiltersOrders, 'wasteTypes')}
									/>
								</Col>
								<Col xl={14} md={24} sm={24} xs={24}>
									<StatisticsRangePicker
										onChange={this.onChangeRange}
										range={range}
									/>
								</Col>
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-12'}>
									<div className={'chart-title'}>
										<span>{locale['page.statistics.sorting.quantity']}</span>
									</div>
									{body}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data'),
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.QUANTITY_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	selectedFiltersOrdersChanged: bindActionCreators(selectedFiltersOrdersChanged, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QuantityPage)
