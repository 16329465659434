import { RESET_STORE } from '../types/global'
import { SAVE_FORM_DATA, REMOVE_FORM_DATA } from '../types/forms'

export const initState = {
	municipalities: {}
}

export default (state = initState, action) => {
	switch (action.type) {
		case SAVE_FORM_DATA:
			return {
				municipalities: {
					...state.municipalities,
					[action.payload.municipality]: {
						...state.municipalities[action.payload.municipality] || {},
						[action.payload.form]: action.payload.data
					}
				}
			}
		case REMOVE_FORM_DATA:
			return {
				municipalities: {
					...state.municipalities,
					[action.payload.municipality]: {
						...state.municipalities[action.payload.municipality] || {},
						[action.payload.form]: null
					}
				}
			}
		case RESET_STORE:
			return {
				...initState
			}
		default:
			return state
	}
}
