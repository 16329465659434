import React from 'react'
import moment from 'moment-timezone'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import { Col, Row } from 'antd'
import Modal from './Modal'

import locale from '../../resources/locale'

import { validate, validatorOptions } from '../../utils/validator'
import { FORMS } from '../../utils/enums'

import DateTimePickerField from '../../atoms/DateTimePickerField'
import Button from '../buttons/Button'

const validateDate = validate([validatorOptions.REQUIRED])

class DeactivateModal extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		title: PropTypes.string,
		message: PropTypes.string,
		acceptTitle: PropTypes.string,
		initialize: PropTypes.func
	}

	componentDidMount() {
		const { reset, initialize } = this.props
		reset(FORMS.DEACTIVATE_CUSTOMER)

		initialize({
			activeTo: moment().endOf('day')
		})
	}

	render() {
		const { handleSubmit, dismissHandler, shown, title, message, acceptTitle } = this.props
		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{title}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								{message}
							</div>
						</div>
						<div className={'row'} style={{ marginTop: '10px' }}>
							<div className={'col-md-6'}>

								<Field
									name='activeTo'
									placeholder={i18next.t('loc:Aktívny do')}
									component={DateTimePickerField}
									props={{
										format: locale['common.date.format.date']
									}}
									label={i18next.t('loc:Aktívny do')}
									validate={validateDate}
									showLabel
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }} justify={'end'}>
							<Col>
								<Button
									onClick={dismissHandler}
									className={'secondary'}
									type='button'
								>
									{locale['dialog.cancel.title']}
								</Button>
							</Col>
							<Col>
								<Button>
									{acceptTitle}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

export default reduxForm({
	form: FORMS.DEACTIVATE_CUSTOMER,
	forceUnregisterOnUnmount: true
})(DeactivateModal)
