import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormApi } from 'final-form'
import { Field, Form } from 'react-final-form'
import { Col, Row } from 'antd'

import { debounce, map } from 'lodash'
import { useDispatch } from 'react-redux'

import { validateRequired } from '../../../utils/form'
import { getMessages } from '../../../utils/helpers'
import { formatContainerName } from '../../../utils/formatters'

import { statusPush } from '../../../actions/statusActions'

import { useDataLoader } from '../../../hooks/useDataLoader'

import { collectionPlaces, containers } from '../../../api'

import { BoxContent } from '../../../layouts/Box'
import { SubHeading } from '../../../components/Typography'
import SelectField from '../../../components/form/SelectField'
import Button from '../../../components/buttons/Button'

type FormValues = {
	containers: number[]
	collectionPlace: number | null
}

const ContainerTransferForm = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()

	const [containersState, loadContainers] = useDataLoader(containers.loadContainers)
	const [collectionPlacesState, loadCollectionPlaces] = useDataLoader(collectionPlaces.loadCollectionPlaces)

	const handleSubmit = useCallback(async (values: FormValues, form: FormApi<FormValues>) => {
		try {
			const data = {
				containers: map(values.containers, (container) => container),
				collectionPlaceID: values.collectionPlace
			}

			const response = await containers.transferContainer(data)

			form.restart({
				containers: [],
				collectionPlace: null
			})

			const { messages } = getMessages(response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve()
		} catch (e: any) {
			const { messages } = getMessages(e.response)
			dispatch(statusPush(messages[0]))

			return Promise.resolve({})
		}
	}, [dispatch])

	const containersOptions = useMemo(() => map(containersState.data?.containers, (container) => ({
		value: container.id, label: formatContainerName(container)
	})), [containersState.data?.containers])

	const collectionPlacesOptions = useMemo(() => map(collectionPlacesState.data?.collectionPlaces, (place) => ({
		value: place.id, label: place.name
	})), [collectionPlacesState.data?.collectionPlaces])

	const filterPlaces = useMemo(() => debounce((nameSearch: string) => loadCollectionPlaces({ nameSearch }), 150), [loadCollectionPlaces])
	const filterContainers = useMemo(() => debounce((search: string) => loadContainers({ search }), 150), [loadContainers])

	return (
		<Form
			onSubmit={handleSubmit}
			subscription={{ submitting: true }}
			render={({ handleSubmit, submitting }) => (
				<BoxContent className={'top-margin padded'}>
					<SubHeading>{t('ToolsPage.containerTransferTitle')}</SubHeading>
					<form onSubmit={handleSubmit}>
						<Row gutter={[16, 16]}>
							<Col span={24} md={12}>
								<Field
									name={'containers'}
									validate={validateRequired}
									validateFields={[]}
									render={(props) => (
										<SelectField
											{...props}
											options={containersOptions}
											mode={'multiple'}
											onSearch={filterContainers}
											showSearch={true}
											filterOption={false}
											label={t('fields.container.label')}
											placeholder={t('fields.container.placeholder')}
										/>
									)}
								/>
							</Col>
							<Col span={24} md={12}>
								<Field
									name={'collectionPlace'}
									validate={validateRequired}
									validateFields={[]}
									render={(props) => (
										<SelectField
											{...props}
											options={collectionPlacesOptions}
											onSearch={filterPlaces}
											showSearch={true}
											filterOption={false}
											label={t('fields.collectionPlace.label')}
											placeholder={t('fields.collectionPlace.placeholder')}
										/>
									)}
								/>
							</Col>
						</Row>
					</form>
					<Row justify={'end'}>
						<Col>
							<Button
								loading={submitting}
								disabled={submitting}
								onClick={handleSubmit}
							>
								{t('common.save')}
							</Button>
						</Col>
					</Row>
				</BoxContent>
			)}
		/>
	)
}

export default ContainerTransferForm
