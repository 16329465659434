import React from 'react'
import { map } from 'lodash'
import { Link } from 'react-router-dom'

import SwitchField from '../atoms/SwichField'

export type BreadcrumbItem = {
	key: string
	name: string
	link?: string
}

type Props = {
	items: BreadcrumbItem[]

	action?: {
		onChange: () => void
		checked: boolean
	}
}

const Breadcrumbs = ({ items, action }: Props) => (
	<div className="breadcrumbs">
		<ul>
			{map(items, (item) => {
				if (item.link) {
					return (<li key={item.key}><Link to={item.link}>{item.name}</Link></li>)
				}
				return (<li key={item.key}>{item.name}</li>)
			})}
		</ul>
		{action && <SwitchField action={action} className={'pull-right'} style={{ marginTop: '-25px' }}/>}
	</div>
)

export default Breadcrumbs
