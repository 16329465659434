import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { map, some } from 'lodash'
import locale from '../../resources/locale'
import { StatisticsNoDataPlaceholder } from '../statistics/StatisticsNoDataPlaceholder'

class ContainersSummaryTable extends Component {
	static propTypes = {
		permanentCount: PropTypes.string.isRequired,
		universalCount: PropTypes.string.isRequired,
		oneTimeContainersSummary: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			color: PropTypes.string,
			activeCount: PropTypes.string.isRequired,
			lastThreeMonths: PropTypes.string.isRequired,
			lastSixMonths: PropTypes.string.isRequired,
			lastTwelveMonths: PropTypes.string.isRequired,
			restOfTime: PropTypes.string.isRequired
		}))
	}

	render() {
		const { oneTimeContainersSummary, permanentCount, universalCount } = this.props

		const isEmpty = !some(oneTimeContainersSummary, (item) => item.activeCount > 0 || item.restOfTime > 0)
			&& parseInt(permanentCount, 10) === 0 && parseInt(universalCount, 10) === 0

		if (isEmpty) {
			return (
				<>
					<div className={'chart-title collection-place'}>
						<span>{locale['page.containers.consumption.title']}</span>
					</div>
					<StatisticsNoDataPlaceholder title={locale['page.containers.consumption.no.containers']}/>
				</>
			)
		}

		const content = map(oneTimeContainersSummary, (item, index) => (
			<div key={`${item.name}-${item.color}-${index}`} className={'waste-type-column'}>
				<div className={'waste-type-name'}>
					<div className={'dot'} style={{ backgroundColor: `#${item.color}` }}/>
					<span>{item.name}</span>
				</div>
				<div className={'waste-type-value'}>{item.lastThreeMonths}</div>
				<div className={'waste-type-value'}>{item.lastSixMonths}</div>
				<div className={'waste-type-value'}>{item.lastTwelveMonths}</div>
				<div className={'waste-type-value'}>{item.restOfTime}</div>
				<div className={'waste-type-value'}>{item.activeCount}</div>
			</div>
		))

		return (
			<>
				<div className={'chart-title collection-place'}>
					<span>{locale['page.containers.consumption.title']}</span>
				</div>
				<div className={'containers-summary'}>
					<div className={'waste-type-legend'}>
						<div className={'waste-type-legend-value'}/>
						<div className={'waste-type-legend-value'} style={{ height: 160 }}>
							<div className={'waste-type-legend-consumption'}>
								<div className={'waste-type-legend-consumption-title'}>
									<div>{locale['page.containers.consumption']}</div>
								</div>
								<div className={'waste-type-legend-consumption-values'}>
									<div className={'waste-type-legend-value'}>
										{locale['page.containers.consumption.three.months']}
									</div>
									<div className={'waste-type-legend-value'}>
										{locale['page.containers.consumption.six.months']}
									</div>
									<div className={'waste-type-legend-value'}>
										{locale['page.containers.consumption.twelve.months']}
									</div>
									<div className={'waste-type-legend-value'}>
										{locale['page.containers.consumption.rest']}
									</div>
								</div>
							</div>
						</div>
						<div className={'waste-type-legend-value active'}>
							{locale['page.containers.consumption.active']}
						</div>
					</div>
					<div className={'containers-data'}>
						<div className={'one-time'}>
							{content}
						</div>
						<div className={'permanent'}>
							<div className={'waste-type-column'}>
								<div className={'waste-type-name'}>
									<span>{locale['page.containers.consumption.permanent']}</span>
								</div>
								<div className={'waste-type-value'}>
									{parseInt(permanentCount, 10)}
								</div>
								<div className={'waste-type-value'}/>
								<div className={'waste-type-name'} style={{ borderRadius: 0 }}>
									<span>{locale['page.containers.consumption.universal']}</span>
								</div>
								<div className={'waste-type-value'}>
									{parseInt(universalCount, 10)}
								</div>
								<div className={'waste-type-value'}/>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default ContainersSummaryTable
