import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { getMediaQueryStyles } from '../utils/styles'

import BackgroundImage from '../resources/images/login-bg.jpg'
import LogoImage from '../resources/images/elwis.png'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 100%;
`

const Background = styled.div`
	z-index: 2;
	background: url("${BackgroundImage}") center no-repeat;
	background-size: cover;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	position: absolute;
	opacity: 0.3;
`

const ContentWrapper = styled.div`
	z-index: 2;
	padding: 40px;
	border-radius: 10px;
	box-shadow: 5px 5px 25px 0 #929292;
	background: white;

	width: 550px;

	${getMediaQueryStyles({ xs: 'calc(100% - 20px)' }, 'width')}
	${getMediaQueryStyles({ xs: '40px 10px' }, 'padding')}
`

const Logo = styled.div`
	z-index: 2;
	height: 50px;
	width: 200px;
	background: url("${LogoImage}") center no-repeat;
	background-size: contain;
	margin-bottom: 20px;
`

type Props = PropsWithChildren<{}>

const SimpleLayout = ({ children }: Props) => (
	<Wrapper>
		<Background/>
		<Logo/>
		<ContentWrapper>
			{children}
		</ContentWrapper>
	</Wrapper>
)

export default SimpleLayout
