import React, { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'

import { useTranslation } from 'react-i18next'

import { map } from 'lodash'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { Col, Row } from 'antd'

import { municipalities } from '../../../api'

import { MunicipalityDetail } from '../../../types/data'

import { DUPLICITY_HANDLE, FILE_TYPE, HOST, MUNICIPALITY_TYPE } from '../../../utils/enums'
import { getDuplicityHandle, getMunicipalityType } from '../../../utils/helpers'
import { validateRequired } from '../../../utils/form'
import {
	validateWebserviceCustomerNumber,
	validateWebservicePassword, validateWebserviceUsername, validateZip
} from '../validators/validateMunicipalityForm'

import { useDataLoader } from '../../../hooks/useDataLoader'

import SelectField from '../../../components/form/SelectField'
import TextField from '../../../components/form/TextField'
import DropZoneField from '../../../components/form/DropZoneField'
import RadioButtonField from '../../../components/form/RadioButtonField'
import SliderField from '../../../components/form/SliderField'
import CheckboxField from '../../../components/form/CheckboxField'
import FormSection, { FormSectionField } from '../../../components/form/FormSection'
import TextAreaField from '../../../components/form/TextAreaField'
import InfoField from '../../../components/InfoField'

import { HeadingTwo } from '../../../components/Typography'

export type FormValues = {
	logo: { id: number, url: string } | null
	name: string
	zip: string
	type: MUNICIPALITY_TYPE

	useUniversalContainerType: boolean
	allowPublicStatistics: boolean
	useAutomaticCollectionRounds: boolean
	useGeneralCollectionRound: boolean
	useQuantity: boolean
	useUnsuitableConditions: boolean

	duplicityHandle: DUPLICITY_HANDLE
	duplicityOverrideInterval: 2 | 4 | 24

	sepanID?: string

	note: string
	parent: { value: number, label: string } | null
	attachment: { id: number, url: string, name: string } | null

	altares: {
		username: string
		password: string
	} | null

	gx: {
		username: string
		password: string
	} | null

	sepan: {
		username: string
		password: string
		customerNumber: string
	} | null

	radium: {
		username: string
		password: string
	} | null
}

type Props = {
	detail: MunicipalityDetail
	form: FormApi<FormValues>
}

const MunicipalityForm = ({ form, detail }: Props) => {
	const [t] = useTranslation()

	const [wasteCompanies, loadWasteCompanies] = useDataLoader(municipalities.loadMunicipalities)

	const typeOptions = useMemo(() => map(MUNICIPALITY_TYPE, (value) => ({
		value, label: getMunicipalityType(value)
	})), [])

	const wasteCompaniesOptions = useMemo(() => map(wasteCompanies.data?.municipalities, (item) => ({
		value: item.id, label: item.name
	})), [wasteCompanies.data])

	const duplicityHandleOptions = useMemo(() => map(DUPLICITY_HANDLE, (value) => ({
		value, label: getDuplicityHandle(value)
	})), [])

	const duplicityOverrideOptions = useMemo(() => ({
		2: { label: '2 hod.' },
		4: { label: '4 hod.' },
		24: { label: '24 hod.' }
	}), [])

	useEffect(() => {
		loadWasteCompanies({ active: true, type: MUNICIPALITY_TYPE.WASTE_COMPANY })
	}, [loadWasteCompanies])

	return (
		// @ts-ignore
		<Form
			form={form}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Row gutter={[16, 16]}>
						<Col span={24} md={12}>
							<Row gutter={16}>
								<Col span={24} style={{ marginBottom: 52 }}>
									<Field
										name={'logo'}
										render={(props) => (
											<DropZoneField
												{...props}
												className={'image'}
												accept={'.png,.jpg,.jpeg'}
												required={true}
												label={t('fields.logo.label')}
												placeholder={t('fields.logo.placeholder')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'useUniversalContainerType'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.useUniversalContainerType.label')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'allowPublicStatistics'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.allowPublicStatistics.label')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'useAutomaticCollectionRounds'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.useAutomaticCollectionRounds.label')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'useGeneralCollectionRound'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.useGeneralCollectionRound.label')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'useQuantity'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.useQuantity.label')}
											/>
										)}
									/>
								</Col>
								<Col md={8} span={24}>
									<Field
										name={'useUnsuitableConditions'}
										type={'checkbox'}
										render={(props) => (
											<CheckboxField
												{...props}
												label={t('fields.useUnsuitableConditions.label')}
											/>
										)}
									/>
								</Col>
								<Col md={12} span={24}>
									<InfoField
										title={t('MunicipalityPage.containersCount')}
										value={detail.containers}
									/>
								</Col>
								<Col md={12} span={24}>
									<InfoField
										title={t('MunicipalityPage.collectionRoundsCount')}
										value={detail.collectionRoundsCount}
									/>
								</Col>
								<Col md={12} span={24}>
									<InfoField
										title={t('MunicipalityPage.createdAt')}
										value={dayjs(detail.createdAt).format(t('dateFormat'))}
									/>
								</Col>
								<Col md={12} span={24}>
									<InfoField
										title={t('MunicipalityPage.collectionPlacesCount')}
										value={detail.collectionPlaces}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={24} md={12}>
							<Field
								name={'name'}
								validate={validateRequired}
								validateFields={[]}
								render={(props) => (
									<TextField
										{...props}
										required={true}
										label={t('fields.name.label')}
										placeholder={t('fields.name.placeholder')}
									/>
								)}
							/>
							<Field
								name={'type'}
								validate={validateRequired}
								validateFields={[]}
								render={(typeProps) => (
									<>
										<SelectField
											{...typeProps}
											options={typeOptions}
											required={true}
											label={t('fields.type.label')}
											placeholder={t('fields.type.placeholder')}
										/>
										{typeProps.input.value !== MUNICIPALITY_TYPE.WASTE_COMPANY &&
										<Field
											name={'parent'}
											render={(props) => (
												<SelectField
													{...props}
													labelInValue={true}
													isLoading={wasteCompanies.isLoading}
													label={t('fields.wasteCompany.label')}
													placeholder={t('fields.wasteCompany.placeholder')}
													options={wasteCompaniesOptions}
												/>
											)}
										/>}
										<Field
											name={'zip'}
											validate={validateZip}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													required={typeProps.input.value !== MUNICIPALITY_TYPE.WASTE_COMPANY}
													label={t('fields.zip.label')}
													placeholder={t('fields.zip.placeholder')}
												/>
											)}
										/>
										<Field
											name={'sepanID'}
											render={(props) => (
												<TextField
													{...props}
													label={t('fields.sepanID.label')}
													placeholder={t('fields.sepanID.placeholder')}
												/>
											)}
										/>
									</>
								)}
							/>
							<Field
								name={'duplicityHandle'}
								validate={validateRequired}
								validateFields={[]}
								render={(props) => (
									<>
										<RadioButtonField
											{...props}
											required={true}
											label={t('fields.duplicityHandle.label')}
											options={duplicityHandleOptions}
										/>
										{props.input.value === DUPLICITY_HANDLE.OVERRIDE &&
										<Field
											name={'duplicityOverrideInterval'}
											render={(props) => (
												<SliderField
													{...props}
													required={true}
													label={t('fields.duplicityOverrideInterval.label')}
													step={null}
													min={0}
													max={24}
													marks={duplicityOverrideOptions}
												/>
											)}
										/>}
									</>
								)}
							/>
						</Col>
						<Col span={24}>
							<HeadingTwo>{t('MunicipalityPage.altaresLogin')}</HeadingTwo>
							<FormSection name={'altares'}>
								<Row gutter={16}>
									<Col span={24} md={12}>
										<FormSectionField
											name={'username'}
											validate={validateWebserviceUsername('altares')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.username.label')}
													placeholder={t('fields.username.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24} md={12}>
										<FormSectionField
											name={'password'}
											type={'password'}
											validate={validateWebservicePassword('altares')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.password.label')}
													placeholder={t('fields.password.placeholder')}
												/>
											)}
										/>
									</Col>
								</Row>
							</FormSection>
						</Col>
						<Col span={24}>
							<HeadingTwo>{t('MunicipalityPage.gxLogin')}</HeadingTwo>
							<FormSection name={'gx'}>
								<Row gutter={16}>
									<Col span={24} md={12}>
										<FormSectionField
											name={'username'}
											validate={validateWebserviceUsername('gx')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.username.label')}
													placeholder={t('fields.username.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24} md={12}>
										<FormSectionField
											name={'password'}
											type={'password'}
											validate={validateWebservicePassword('gx')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.password.label')}
													placeholder={t('fields.password.placeholder')}
												/>
											)}
										/>
									</Col>
								</Row>
							</FormSection>
						</Col>
						<Col span={24}>
							<HeadingTwo>{t('MunicipalityPage.radiumLogin')}</HeadingTwo>
							<FormSection name={'radium'}>
								<Row gutter={16}>
									<Col span={24} md={12}>
										<FormSectionField
											name={'username'}
											validate={validateWebserviceUsername('radium')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.username.label')}
													placeholder={t('fields.username.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24} md={12}>
										<FormSectionField
											name={'password'}
											type={'password'}
											validate={validateWebservicePassword('radium')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.password.label')}
													placeholder={t('fields.password.placeholder')}
												/>
											)}
										/>
									</Col>
								</Row>
							</FormSection>
						</Col>
						<Col span={24}>
							<HeadingTwo>{t('MunicipalityPage.sepanLogin')}</HeadingTwo>
							<FormSection name={'sepan'}>
								<Row gutter={16}>
									<Col span={24} md={8}>
										<FormSectionField
											name={'username'}
											validate={validateWebserviceUsername('sepan')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													label={t('fields.username.label')}
													placeholder={t('fields.username.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24} md={8}>
										<FormSectionField
											name={'password'}
											type={'password'}
											validate={validateWebservicePassword('sepan')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													autoComplete={'new-password'}
													label={t('fields.password.label')}
													placeholder={t('fields.password.placeholder')}
												/>
											)}
										/>
									</Col>
									<Col span={24} md={8}>
										<FormSectionField
											name={'customerNumber'}
											validate={validateWebserviceCustomerNumber('sepan')}
											validateFields={[]}
											render={(props) => (
												<TextField
													{...props}
													label={t('fields.customerNumber.label')}
													placeholder={t('fields.customerNumber.placeholder')}
												/>
											)}
										/>
									</Col>
								</Row>
							</FormSection>
						</Col>
						<Col md={8} span={24}>
							<Field
								name={'attachment'}
								render={(props) => (
									<DropZoneField
										{...props}
										image={false}
										fileType={FILE_TYPE.ATTACHMENT}
										label={t('fields.attachment.label')}
										placeholder={t('fields.attachment.placeholder')}
									/>
								)}
							/>
						</Col>
						<Col span={24}>
							<Field
								name={'note'}
								render={(props) => (
									<TextAreaField
										{...props}
										label={t('fields.note.label')}
										placeholder={t('fields.note.placeholder')}
									/>
								)}
							/>
						</Col>
					</Row>
				</form>
			)}
		/>
	)
}

export default MunicipalityForm
