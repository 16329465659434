import React from 'react'
import {
	debounce,
	get,
	isEqual,
	head,
	last,
	trim
} from 'lodash'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ReactQueryParams from 'react-query-params'

import { Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

// atoms
import Pagination from '../../atoms/Pagination'
import SearchInputField from '../../atoms/SearchInputField'

// recources
import locale from '../../resources/locale'

// utils
import { MODAL, ENDPOINTS, FILTER_ORDER_SELECTORS, INFO, FORMS, PERMISSIONS } from '../../utils/enums'
import { getReq, deleteReq, postReq } from '../../utils/request'
import { history } from '../../utils/history'

// components
import CreateDeviceModal from '../../components/modals/CreateDeviceModal'
import DevicesTable from '../../components/devices/DevicesTable'
import DialogModal from '../../components/modals/DialogModal'

// pages
import Breadcrumbs from '../../components/Breadcrumb'

// actions
import * as SelectedFiltersOrdersActions from '../../actions/selectedFiltersOrdersActions'
import * as MunicipalityActions from '../../actions/municipalityActions'
import { statusPush } from '../../actions/statusActions'
import Permission from '../../components/Permission'
import ListItemsLimit from '../../atoms/ListImtesLimit'
import { mergeQueryParams, normalizeQueryParams, QUERY_PARAMS_TYPES } from '../../utils/queryParams'
import { getAuthUser } from '../../redux/authentication'
import Button from '../../components/buttons/Button'

const queryParams = {
	search: 'search',
	order: 'order',
	page: 'page',
	limit: 'limit'
}

const queryTypes = {
	search: {
		type: QUERY_PARAMS_TYPES.STRING,
		defaultValue: null
	},
	order: {
		type: QUERY_PARAMS_TYPES.ORDER,
		defaultValue: 'name:ASC',
		allowedValues: ['name', 'type', 'number', 'assignedFrom', 'assignedTo', 'municipality']
	},
	page: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 1
	},
	limit: {
		type: QUERY_PARAMS_TYPES.NUMBER,
		defaultValue: 20
	}
}

class DevicesPage extends ReactQueryParams {
	constructor(props) {
		super(props)

		this.defaultQueryParams = {
			[queryParams.search]: null,
			[queryParams.order]: null,
			[queryParams.page]: null,
			[queryParams.limit]: null
		}

		this.state = {
			modal: null,
			devices: {
				data: [],
				isLoading: true,
				isFailure: false
			},
			modalData: null
		}
	}

	_mounted = false

	onChangeFilterOrder = (type, value) => {
		const {
			selectedFiltersOrdersActions,
			selectedFiltersOrders
		} = this.props
		let newFilterOrder
		switch (type) {
			case queryParams.order:
				newFilterOrder = `${value.name}:${value.sort}`
				break
			case queryParams.page:
			case queryParams.search:
			case queryParams.limit:
				newFilterOrder = (value) ? `${value}` : null
				break
			default:
				break
		}
		const filterOrder = {
			...selectedFiltersOrders,
			[type]: newFilterOrder
		}

		if (type === queryParams.search) {
			filterOrder[queryParams.page] = '1'
		}

		this.setQueryParams({
			...filterOrder
		})
		// fetch data for table
		this.getDevices(filterOrder)
		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.DEVICES_PAGE, filterOrder)
	}

	searchDevices = (value) => {
		this.onChangeFilterOrder(queryParams.search, trim(value))
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	createHandler = async (values) => {
		const {
			selectedMunicipality,
			selectedFiltersOrders,
			reset,
			pushStatus
		} = this.props
		const body = {
			name: values.name,
			type: values.type,
			number: values.number,
			assignedFrom: values.assignedFrom,
			assignedTo: values.assignedTo,
			UUID: values.UUID,
			SIM: values.SIM
		}
		try {
			await postReq(ENDPOINTS.MUNICIPALITY_DEVICES(selectedMunicipality.municipality.data.id), null, body)
			pushStatus({
				type: INFO,
				msg: locale['page.device.create.success']
			})
			reset(FORMS.CREATE_DEVICE_FORM)
			this.dismissModal()
			this.getDevices(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	getDevices = async (query) => {
		const { match } = this.props
		try {
			if (!this._mounted) {
				return
			}
			this.setState({
				devices: {
					data: [],
					isLoading: true,
					isFailure: false
				}
			})

			let endpoint = null
			const { municipalityID } = match.params

			if (municipalityID) {
				const { municipalityID } = match.params
				endpoint = ENDPOINTS.MUNICIPALITY_DEVICES(municipalityID)
			} else {
				endpoint = ENDPOINTS.DEVICES
			}
			const res = await getReq(endpoint, query)
			if (!this._mounted) {
				return
			}

			const context = get(res, 'data.context', {})
			if (query.page > 1 && query.page > context.pages) {
				this.onChangeFilterOrder(queryParams.page, 1)
			} else {
				this.setState({
					devices: {
						data: get(res, 'data'),
						isLoading: false,
						isFailure: false
					}
				})
			}
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				devices: {
					data: [],
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidMount = async () => {
		this._mounted = true
		const {
			selectedFiltersOrdersActions,
			selectedFiltersOrders
		} = this.props

		const filtersOrder = normalizeQueryParams(queryTypes, mergeQueryParams(selectedFiltersOrders, this.queryParams))

		// loading data
		this.getDevices(filtersOrder)

		// dispatch query params
		selectedFiltersOrdersActions.selectedFiltersOrdersChanged(FILTER_ORDER_SELECTORS.DEVICES_PAGE, filtersOrder)
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	componentDidUpdate = () => {
		if (this._mounted) {
			const { selectedFiltersOrders } = this.props
			if (!isEqual(selectedFiltersOrders, this.queryParams)) {
				this.setQueryParams(normalizeQueryParams(queryTypes, selectedFiltersOrders))
			}
		}
	}

	onDeviceDetail = (deviceId) => {
		const { user } = this.props
		if (user.isAdmin) {
			const { match } = this.props
			const { municipalityID } = match.params
			if (municipalityID) {
				history.push(locale.formatString(locale['path.municipality.devices.detail'], municipalityID, deviceId))
			} else {
				history.push(locale.formatString(locale['path.administration.devices.detail'], deviceId))
			}
		}
	}

	onDeleteDevice = async () => {
		try {
			const {
				pushStatus,
				selectedFiltersOrders
			} = this.props
			const { modalData } = this.state

			await deleteReq(ENDPOINTS.MUNICIPALITY_DEVICE(get(modalData, 'municipality.id'), get(modalData, 'id')))

			pushStatus({
				type: INFO,
				msg: locale['page.device.delete.success']
			})

			this.dismissModal()
			this.getDevices(selectedFiltersOrders)
		} catch (e) {
			// Error
		}
	}

	render = () => {
		const {
			selectedFiltersOrders,
			selectedMunicipality
		} = this.props
		const {
			devices,
			modal,
			modalData
		} = this.state

		const order = selectedFiltersOrders.order || ''
		const isSortingColumn = head(order.split(':'))
		const isSortingDirection = last(order.split(':'))
		let items = []

		if (selectedMunicipality.municipality.data) {
			items = [{
				key: 'page.municipality',
				name: locale['page.municipality.settings.breadcrumbs']
			}, {
				key: 'page.municipality.detail',
				name: get(selectedMunicipality, 'municipality.data.name')
			}]
		} else {
			items = [{
				key: 'page.administration.breadcrumbs',
				name: locale['page.administration.breadcrumbs']
			}]
		}
		const breadcrumbsItems = [...items, {
			key: 'page.devices.breadcrumbs',
			name: locale['page.devices.breadcrumbs']
		}]

		return (
			<>
				<Breadcrumbs
					items={breadcrumbsItems}
				/>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['page.device.title']}
					</div>
					<div className={'box-content'}>
						<div className={'box-head'}>
							<Row justify={'space-between'} gutter={16}>
								<Col>
									<SearchInputField
										name={'search'}
										onChange={debounce(this.searchDevices, 300)}
										defaultValue={selectedFiltersOrders.search || ''}
									/>
								</Col>
								{!!selectedMunicipality.municipality.data &&
								<Permission allowed={[PERMISSIONS.ADMINISTRATOR]}>
									<Col>
										<Button
											onClick={this.openModal(MODAL.CREATE_DEVICE)}
										>
											<PlusOutlined/>
											<span>{locale['page.device.add']}</span> </Button>
									</Col>
								</Permission>}
							</Row>
						</div>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-12'}>
									<DevicesTable
										showMunicipality={!selectedMunicipality.municipality.data}
										onSort={(val) => this.onChangeFilterOrder(queryParams.order, val)}
										onDelete={this.openModal(MODAL.DELETE_DEVICE)}
										onSetActive={this.openModal(MODAL.SET_ACTIVE_CONTAINER_TYPE)}
										onDetail={this.onDeviceDetail}
										devices={get(devices, 'data.devices', [])}
										loading={devices.isLoading}
										isSortingColumn={isSortingColumn}
										isSortingDirection={isSortingDirection}
									/>
									<ListItemsLimit
										limit={selectedFiltersOrders.limit}
										onLimit={(limit) => this.onChangeFilterOrder(queryParams.limit, limit)}
									/>
									<Pagination
										pages={get(devices, 'data.context.pages')}
										page={get(devices, 'data.context.page')}
										onPage={(val) => this.onChangeFilterOrder(queryParams.page, val)}
									/>
								</div>
							</div>
						</div>
					</div>
					<DialogModal
						shown={modal === MODAL.DELETE_DEVICE}
						cancelHandler={this.dismissModal}
						acceptHandler={this.onDeleteDevice}
						message={locale.formatString(locale['page.device.remove.message'])}
						title={locale.formatString(locale['page.device.remove.title'], get(modalData, 'name', ''))}
						acceptTitle={locale['page.device.remove.accept']}
					/>
					<CreateDeviceModal
						dismissHandler={this.dismissModal}
						onSubmit={this.createHandler}
						shown={modal === MODAL.CREATE_DEVICE}
					/>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state).data,
	selectedMunicipality: state.selectedMunicipality,
	selectedFiltersOrders: state.selectedFiltersOrders[FILTER_ORDER_SELECTORS.DEVICES_PAGE]
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	selectedFiltersOrdersActions: bindActionCreators(SelectedFiltersOrdersActions, dispatch),
	municipalityActions: bindActionCreators(MunicipalityActions, dispatch),
	reset: bindActionCreators(reset, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage)
