export const COLLECTION_ROUNDS_ACTIONS = {
	LIST_LOAD_START: 'COLLECTION_ROUNDS/LIST_LOAD_START',
	LIST_LOAD_FAIL: 'COLLECTION_ROUNDS/LIST_LOAD_FAIL',
	LIST_LOAD_DONE: 'COLLECTION_ROUNDS/LIST_LOAD_DONE',

	DETAIL_LOAD_START: 'COLLECTION_ROUNDS/DETAIL_LOAD_START',
	DETAIL_LOAD_FAIL: 'COLLECTION_ROUNDS/DETAIL_LOAD_FAIL',
	DETAIL_LOAD_DONE: 'COLLECTION_ROUNDS/DETAIL_LOAD_DONE',

	MAP_LOAD_START: 'COLLECTION_ROUNDS/MAP_LOAD_START',
	MAP_LOAD_FAIL: 'COLLECTION_ROUNDS/MAP_LOAD_FAIL',
	MAP_LOAD_DONE: 'COLLECTION_ROUNDS/MAP_LOAD_DONE',

	OVERVIEW_LOAD_START: 'COLLECTION_ROUNDS/OVERVIEW_LOAD_START',
	OVERVIEW_LOAD_FAIL: 'COLLECTION_ROUNDS/OVERVIEW_LOAD_FAIL',
	OVERVIEW_LOAD_DONE: 'COLLECTION_ROUNDS/OVERVIEW_LOAD_DONE'
}
