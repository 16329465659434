import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'
import PopupTool from '../../atoms/PopupTool'
import { formatBatteryLevel, formatDate, formatDatetime } from '../../utils/utils'
import Permission from '../Permission'
import { PERMISSIONS } from '../../utils/enums'

class DevicesTableRow extends React.Component {
	static propTypes = {
		showMunicipality: PropTypes.bool,
		device: PropTypes.object.isRequired,
		onSetActive: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		countIndex: PropTypes.number,
		onDelete: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		this.state = { sort: props.defaultSort || null }
	}

	render() {
		const { device, onDelete, onDetail, countIndex, showMunicipality } = this.props
		return (
			<tr onClick={() => onDetail(get(device, 'id'))}>
				<td style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'elipsis' }}>{get(device, 'name')}</td>
				<td>{get(device, 'type')}</td>
				<td style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'elipsis' }}>{get(device, 'number')}</td>
				<td style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'elipsis' }}>{get(device, 'UUID') || '-'}</td>
				<td style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'elipsis' }}>{get(device, 'SIM') || '-'}</td>
				<td>{formatDate(get(device, 'assignedFrom'))}</td>
				<td>{formatDate(get(device, 'assignedTo'))}</td>
				{showMunicipality && <td>{get(device, 'municipality.name', '-')}</td>}
				<td>{formatDatetime(get(device, 'lastCommunication'))}</td>
				<td>{formatBatteryLevel(get(device, 'batteryLevel'))}</td>
				<td className={'action'} style={{ zIndex: countIndex }}>
					<Permission allowed={[PERMISSIONS.ADMINISTRATOR]} >
						<PopupTool
							items={[{
								key: 'common.delete',
								title: locale['common.delete'],
								icon: faTrashAlt,
								callback: () => onDelete(device)
							}]}
						/>
					</Permission>
				</td>
			</tr>
		)
	}
}

export default DevicesTableRow
