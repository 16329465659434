import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import cx from 'classnames'
import moment from 'moment-timezone'
import locale from '../resources/locale'

require('moment/locale/sk')

const dateFormat = locale['common.date.format.date']

class DateTimePickerField extends React.Component {
	static propTypes = {
		input: PropTypes.shape({
			onChange: PropTypes.func.isRequired,
			onBlur: PropTypes.func.isRequired
		}),
		meta: PropTypes.shape({
			error: PropTypes.string,
			touched: PropTypes.bool.isRequired
		}),
		name: PropTypes.string,
		format: PropTypes.string.isRequired,
		timeFormat: PropTypes.string,
		placeholder: PropTypes.string,
		showLabel: PropTypes.bool.isRequired,
		disabled: PropTypes.bool,
		label: PropTypes.string
	}

	constructor(props) {
		super(props)
		this.state = {
			active: false,
			input: false,
			mode: 'days'
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node
	}

	handleClickOutside = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.onBlur()
		}
	}

	onChange = (date) => {
		const { input } = this.props
		input.onChange(date)
	}

	onBlur = () => {
		const { input } = this.props
		const { active } = this.state
		if (active) {
			input.onBlur()
			this.setState({
				active: false
			})
		}
	}

	onFocus = () => {
		const { input } = this.props
		input.onFocus()
		this.setState({
			active: true
		})
	}

	formatValue = (value) => {
		const { format } = this.props
		const date = moment(value)
		if (date.isValid()) {
			return date.format(format)
		}
		return ''
	}

	renderYear = (props, year, selectedDate) => {
		let selected = false
		if (selectedDate) {
			const selectedYear = selectedDate.year()
			selected = selectedYear === year
		}
		return (
			<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
				{year}
			</td>
		)
	}

	renderMonth = (props, month, year, selectedDate) => {
		let selected = false
		if (selectedDate) {
			const selectedYear = selectedDate.year()
			const selectedMonth = selectedDate.month()
			selected = selectedYear === year && selectedMonth === month
		}
		return (
			<td {...props} className={cx(props.className, { selected }, 'calendar-tile')}>
				{locale[`month.short.${month}`]}
			</td>
		)
	}

	renderDay = (props, currentDate, selectedDate) => {
		let selected = false
		if (selectedDate) {
			const selectedYear = selectedDate.year()
			const selectedMonth = selectedDate.month()
			const selectedDay = selectedDate.date()
			const currentYear = currentDate.year()
			const currentMonth = currentDate.month()
			const currentDay = currentDate.date()
			selected = selectedYear === currentYear && selectedMonth === currentMonth && selectedDay === currentDay
		}
		const now = moment()
		const today = now.diff(currentDate, 'days') === 0 && now.date() === currentDate.date()
		return (
			<td {...props} className={cx(props.className, { selected, today }, 'calendar-tile')}>
				{currentDate.date()}
			</td>
		)
	}

	onViewModeChange = (mode) => {
		this.setState({
			mode
		})
	}

	onInputChange = (e) => {
		this.setState({
			input: true,
			value: e.target.value
		})
	}

	onInputBlur = () => {
		const { timeFormat, input: { onChange } } = this.props
		const { input, value } = this.state
		if (input) {
			const format = timeFormat ? `${dateFormat} ${timeFormat}` : dateFormat
			const date = moment(value, format)

			if (date.isValid()) {
				onChange(date.toDate())
			} else {
				onChange(null)
			}
		}
		this.setState({
			input: false
		})
	}

	render() {
		const { showLabel, label, name, timeFormat, input, placeholder, meta: { touched, error }, disabled } = this.props
		const { active, input: pickerInput, value: inputValue } = this.state

		return (
			<div ref={this.setWrapperRef} className={cx('input-wrapper mo-datetime-picker-field', { error: touched && error })}>
				{showLabel && <label htmlFor={name}>{label}</label>}
				<input
					{...input}
					placeholder={placeholder}
					value={pickerInput ? inputValue : this.formatValue(input.value)}
					onFocus={this.onFocus}
					onBlur={this.onInputBlur}
					onChange={this.onInputChange}
					type={'text'}
					autoComplete={'off'}
					disabled={disabled}
				/>
				<div className={cx('date-picker', { active })}>
					<Datetime
						className={'date-picker-calendar'}
						locale={'sk'}
						input={false}
						value={input.value}
						dateFormat={dateFormat}
						timeFormat={timeFormat || false}
						onBlur={this.onBlur}
						onChange={this.onChange}
						onViewModeChange={this.onViewModeChange}
						renderYear={this.renderYear}
						renderMonth={this.renderMonth}
						renderDay={this.renderDay}
						closeOnTab={true}
						closeOnSelect={true}
					/>
				</div>
				<div className={'close-error'}>
					<div className={'tooltip'}>
						{touched ? error : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default DateTimePickerField
