import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormApi } from 'final-form'
import { Field, Form } from 'react-final-form'
import { Col, Row } from 'antd'

import { MOTIVATION_MODEL, REFERENCE_PERIOD } from '../../../utils/enums'
import { composeValidators, validateGte, validateRequired } from '../../../utils/form'

import Button from '../../../components/buttons/Button'
import SelectField from '../../../components/form/SelectField'
import DateField from '../../../components/form/DateField'
import NumberField from '../../../components/form/NumberField'

import { Modal, ModalContent, ModalFooter, ModalHead } from '../../../components/Modal'

import { validateDateFrom, validateDateTo } from '../validators/validateMotivationModalForm'

export type FormValues = {
	id?: number | null
	type: MOTIVATION_MODEL | null

	referencePeriod: REFERENCE_PERIOD | null
	validFrom: string | null
	validTo: string | null
	weightDiscount: number | null
	maxDiscount: number | null
	baseFee: number | null
	collectionsCountMonthly: string | number | null
	plannedVolume: string | number | null
}

type Props = {
	form: FormApi<FormValues>
	visible: boolean
	onClose: () => void
}

const MotivationModelModal = ({ visible, onClose, form }: Props) => {
	const [t] = useTranslation()

	const typeOptions = useMemo(() => [{
		value: MOTIVATION_MODEL.DISCOUNT, label: t('MOTIVATION_MODEL.DISCOUNT')
	}, {
		value: MOTIVATION_MODEL.FEE, label: t('MOTIVATION_MODEL.FEE')
	}], [t])

	const referencePeriodOptions = useMemo(() => [{
		value: REFERENCE_PERIOD.MONTHLY, label: t('REFERENCE_PERIOD.MONTHLY')
	}, {
		value: REFERENCE_PERIOD.QUARTERLY, label: t('REFERENCE_PERIOD.QUARTERLY')
	}, {
		value: REFERENCE_PERIOD.HALFYEARLY, label: t('REFERENCE_PERIOD.HALFYEARLY')
	}, {
		value: REFERENCE_PERIOD.YEARLY, label: t('REFERENCE_PERIOD.YEARLY')
	}], [t])

	return (
		<Modal
			visible={visible}
			onCancel={onClose}
			footer={null}
		>
			{/* @ts-ignore */}
			<Form
				form={form}
				render={({ handleSubmit, submitting }) => (
					<>
						<ModalHead>
							{t('MotivationModelModal.title')}
						</ModalHead>
						<ModalContent>
							<form onSubmit={handleSubmit}>
								<Field
									name={'id'}
									render={({ input }) => (
										<input
											{...input}
											type={'hidden'}
										/>
									)}
								/>
								<Field
									name={'type'}
									validate={validateRequired}
									render={(props) => (
										<SelectField
											{...props}
											required
											options={typeOptions}
											label={t('fields.type.label')}
											placeholder={t('fields.type.placeholder')}
										/>
									)}
								/>
								<Field
									name={'type'}
									render={({ input }) => {
										if (input.value === MOTIVATION_MODEL.FEE) {
											return (
												<Row gutter={[16, 16]}>
													<Col span={24} md={12}>
														<Field
															name={'validFrom'}
															validate={composeValidators(validateRequired, validateDateFrom)}
															validateFields={['validTo']}
															render={(props) => (
																<DateField
																	{...props}
																	required
																	format={t('dateFormat')}
																	label={t('fields.dateFrom.label')}
																	placeholder={t('fields.dateFrom.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'validTo'}
															validate={composeValidators(validateRequired, validateDateTo)}
															validateFields={['validFrom']}
															render={(props) => (
																<DateField
																	{...props}
																	required
																	format={t('dateFormat')}
																	label={t('fields.dateTo.label')}
																	placeholder={t('fields.dateTo.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'collectionsCountMonthly'}
															validate={composeValidators(validateRequired, validateGte(1))}
															validateFields={[]}
															render={(props) => (
																<NumberField
																	{...props}
																	required
																	min={1}
																	label={t('fields.plannedCollectionsCount.label')}
																	placeholder={t('fields.plannedCollectionsCount.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'plannedVolume'}
															validate={composeValidators(validateRequired, validateGte(0))}
															validateFields={[]}
															render={(props) => (
																<NumberField
																	{...props}
																	required
																	min={0}
																	label={t('fields.plannedCapacity.label')}
																	placeholder={t('fields.plannedCapacity.placeholder')}
																/>
															)}
														/>
													</Col>
												</Row>
											)
										}

										if (input.value === MOTIVATION_MODEL.DISCOUNT) {
											return (
												<Row gutter={[16, 16]}>
													<Col span={24}>
														<Field
															name={'referencePeriod'}
															validate={validateRequired}
															render={(props) => (
																<SelectField
																	{...props}
																	required
																	options={referencePeriodOptions}
																	label={t('fields.referencePeriod.label')}
																	placeholder={t('fields.referencePeriod.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'validFrom'}
															validate={composeValidators(validateRequired, validateDateFrom)}
															validateFields={['validTo']}
															render={(props) => (
																<DateField
																	{...props}
																	required
																	format={t('dateFormat')}
																	label={t('fields.dateFrom.label')}
																	placeholder={t('fields.dateFrom.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'validTo'}
															validate={composeValidators(validateRequired, validateDateTo)}
															validateFields={['validFrom']}
															render={(props) => (
																<DateField
																	{...props}
																	required
																	format={t('dateFormat')}
																	label={t('fields.dateTo.label')}
																	placeholder={t('fields.dateTo.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'baseFee'}
															validate={composeValidators(validateRequired, validateGte(0))}
															validateFields={[]}
															render={(props) => (
																<NumberField
																	{...props}
																	required
																	min={1}
																	label={t('fields.baseFee.label')}
																	placeholder={t('fields.baseFee.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'weightDiscount'}
															validate={composeValidators(validateRequired, validateGte(0))}
															validateFields={[]}
															render={(props) => (
																<NumberField
																	{...props}
																	required
																	min={0}
																	label={t('fields.discount.label')}
																	placeholder={t('fields.discount.placeholder')}
																/>
															)}
														/>
													</Col>
													<Col span={24} md={12}>
														<Field
															name={'maxDiscount'}
															validate={composeValidators(validateRequired, validateGte(0))}
															validateFields={[]}
															render={(props) => (
																<NumberField
																	{...props}
																	required
																	min={0}
																	label={t('fields.maxDiscount.label')}
																	placeholder={t('fields.maxDiscount.placeholder')}
																/>
															)}
														/>
													</Col>
												</Row>
											)
										}

										return null
									}}
								/>
							</form>
						</ModalContent>
						<ModalFooter>
							<Row justify={'end'} gutter={[16, 8]}>
								<Col>
									<Button
										className={'secondary'}
										onClick={(e) => {
											e.preventDefault()
											onClose()
										}}
									>
										{t('common.close')}
									</Button>
								</Col>
								<Col>
									<Button
										loading={submitting}
										disabled={submitting}
										onClick={handleSubmit}
									>
										{t('common.submit')}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</>
				)}
			/>
		</Modal>
	)
}

export default React.memo(MotivationModelModal)
