import { CONTAINER_TYPE, MESSAGE_TYPE } from './enums'
import {
	PERMISSIONS, MODAL, DIALOG, MUNICIPALITY_TYPE, DUPLICITY_HANDLE, DUPLICITY_OVERRIDE_INTERVAL, CUSTOMER_LEGAL_FORM,
	COLLECTION_PLACE_TYPE, MOTIVATION_MODEL, REFERENCE_PERIOD, WASTE_TYPE, WEIGHT_CALCULATE_TYPE, PERIOD, EVENT_TYPE,
	SETTINGS_KEY, SORTING_GROUP
} from '../utils/enums'

export type Pagination = {
	current: number
	pageSize: number
	totalPages: number
	totalCount: number

	pages: number
	page: number
}

export type ListDataType<T> = {
	[Key in keyof T]: T[Key]
} & {
	context: Pagination
}

export type ModalState = { key: MODAL | null, data?: any | null }
export type DialogState = { key: DIALOG | null, data: { id: number | string, data?: any } | null }

export const defaultModalState: ModalState = { key: null, data: null }
export const defaultDialogState: DialogState = { key: null, data: null }

export type OrderDirection = 'ascend' | 'descend'
export type OrderDirections = OrderDirection[]

export type LabeledValue<T = {}> = {
	value: string | number, label: string, item?: T, slug?: string
}

export type SelectValues<T = {}> = {
	values: LabeledValue<T>[] | null
}

export type SelectDataType<T> = SelectValues<T> & {
	isLoading: boolean
}

export type TokenPayload = {
	id: number
	exp: number
	aud: string
}

export type Message = {
	type: MESSAGE_TYPE
	message: string
	description?: string
}

export type ContainerTypeBase = {
	id: number
	name: string
	capacity: number
	type: CONTAINER_TYPE
}

export type Calendar = {
	events: {
		id: number
		comment: string | null
		date: string
		type: EVENT_TYPE
		municipalityID: number
		collectionRounds: {
			id: number
			name: string
			wasteType: WasteTypeBase
		}[]
	}[]
}

export type ContainerType = ContainerTypeBase & {
	wasteType: WasteTypeBase
}

export type Container = {
	id: number
	code: string | null
	rfid: string | null
	serialNumber: string | null
	rfidSerial: string | null

	assignedFrom: string
	assignedTo: string | null
	containerType: ContainerType

	municipalityID: number
	collectionPlace: {
		id: number
		name: string
	}
}

export type WasteTypeCategory = {
	id: number
	name: string
	code: string
}

export type WasteTypeBase = {
	id: number
	name: string
	color: string
}

export type MunicipalityWasteType = WasteTypeBase & {
	wasteType: WasteTypeCategory
	municipality: {
		id: number
		name: string
	}
}

export type WasteTypeDetail = WasteTypeBase & {
	coefficient: number
	type: WASTE_TYPE
	weightCalculateType: WEIGHT_CALCULATE_TYPE
	isActiveMotivationModel: boolean
}

export type WeightSummary = {
	collections: {
		id: number
		name: string
		color: string
		data: {
			weight: number
			date: string
		}[]
	}[]

	collectionRounds: {
		id: number
		name: string
		color: string
		data: {
			weight: number
			date: string
		}[]
	}[]
}

export type CollectionRound = {
	id: number
	name: string
	date: string
	municipalityID: number
	wasteType: WasteTypeBase
}

export type Device = {
	id: number
	name: string
	number: string
}

export type CollectionRoundDetail = CollectionRound & {
	streets: string[]
	parentMunicipalityID: number | null
	comment: string | null
	totalWeight: number
	realWeight: number
	startDatetime: string | null
	stopDatetime: string | null
	collectionsCount: number

	createdAt: string
	updatedAt: string

	creator: BaseUser
	editor: BaseUser | null

	wasteTypes: {
		wasteTypeID: number
		collectionRoundID: number
		weight: number
		wasteType: WasteTypeCategory
	}[]
}

export type MunicipalityBase = {
	id: number
	name: string
	logo: {
		id: number
		url: string
	}
	type: MUNICIPALITY_TYPE
}

export type MotivationModel = {
	id: number
	type: MOTIVATION_MODEL

	baseFee: number | null
	collectionsCountMonthly: number | null
	maxDiscount: number | null
	plannedVolume: number | null
	referencePeriod: REFERENCE_PERIOD | null
	weightDiscount: number | null

	validFrom: string
	validTo: string

	createdAt: string
	updatedAt: string
	createdBy: number
}

export type MunicipalityDetail = MunicipalityBase & {
	containers: number
	collectionRoundsCount: number
	collectionPlaces: number

	useUniversalContainerType: boolean
	allowPublicStatistics: boolean
	useAutomaticCollectionRounds: boolean
	useGeneralCollectionRound: boolean
	useQuantity: boolean
	useUnsuitableConditions: boolean
	zip: string
	sepanID?: string

	duplicityHandle: DUPLICITY_HANDLE
	duplicityOverrideInterval: DUPLICITY_OVERRIDE_INTERVAL
	note: string
	attachment: { id: number, url: string, name: string } | null

	parent: { id: number, name: string } | null
	webServices: {
		ALTARES?: { username: string, password: string } | null
		GX?: { username: string, password: string } | null
		SEPAN?: { username: string, password: string, customerNumber: string } | null
		RADIUM?: { username: string, password: string } | null
	}

	motivationModels: MotivationModel[]

	createdAt: string
}

export type UserMunicipality = MunicipalityBase & {
	parentID: number | null
	zip: string,
	permissions: PERMISSIONS[]
	isActive: true
}

export type BaseUser = {
	id: number
	name: string
	surname: string
}

export type Profile = {
	id: number
	name: string
	surname: string
	email: string
	isActive: boolean
	isConfirmed: boolean
	isAdmin: boolean
	municipalities: UserMunicipality[] | null
	lastLoginAt: string
	createdAt: string
	updatedAt: string
}

export type Customer = {
	id: number
	name: string | null
	surname: string | null
	companyName: string | null
	titleBefore: string | null
	titleAfter: string | null

	legalForm: CUSTOMER_LEGAL_FORM
	isAnonymous: boolean
	municipalityID: number

	street: string | null
	number: string | null
	streetNumber: string | null
	city: string | null
	zip: string | null

	wasteTypes?: {
		customerID: number
		wasteTypeID: number
		name: string
		color: string
		limit: number | null
	}[]
}

export type ContainersCount = {
	total: string
	110: string
	120: string
	240: string
	1100: string
	other: string
}

export type Household = {
	id: number
	name: string
	total: number
	municipal: number
	rate: number
}

export type TopHouseholds = {
	best: Household[]
	worst: Household[]
	largestZKOProducers: Household[]
}

export type CollectionsSummary = {
	year: number
	municipalityID: number
	period: PERIOD
	collections: {
		[period: string]: {
			unsorted: {
				weight: number
				count: number
			}
			sorted: {
				weight: number
				count: number
				wasteType: WasteTypeBase
			}[]
		}
	}
}

export type MunicipalitySortingRate = {
	total: number
	wasteTypes: (WasteTypeBase & {
		weight: number
	})[]
}

export type BulkCollection = {
	id: number
	scanDatetime: string
	collectionYardID: number
	municipalityID: number
	customerID: number,
	comment: string
	customerCode: string | null
	sign: string | null

	collections: {
		id: number
		weight: number
		containerID: number
		container: Container
	}[]

	customer: Customer
	collectionYard: CollectionPlace

	createdAt: string
	updatedAt: string

	creator: BaseUser
	editor: BaseUser | null
}

export type BulkCollectionListItem = {
	id: number
	scanDatetime: string
	collectionYardID: number
	municipalityID: number
	customerID: number,
	comment: string
	customerCode: string | null

	collections: {
		id: number
		weight: number
		containerID: number
		container: Container
	}[]

	customer: Customer
	collectionYard: CollectionPlace
}

export type BulkCollectionCustomer = {
	id: number
	name: string
	visitCount: number
	totalWeight: number
	unsortedWeight: number
	sortedWeight: number
	customerCodes: string[] | null
}

export type WasteTypesSummary = {
	id: number
	color: string
	name: string
	count: number
	weight: number
}

export type CustomerDetail = {
	id: number
	legalForm: CUSTOMER_LEGAL_FORM
	name: string | null
	surname: string | null
	companyName: string | null
	ico: string
	wasteTypes: {
		customerID: number
		wasteTypeID: number
		name: string
		color: string
		limit: number | null
	}[]
}

export type User = Profile

export type ContainersSummary = {
	oneTimeContainersSummary: {
		name: string
		color: string
		activeCount: number
		lastThreeMonths: number
		lastSixMonths: number
		lastTwelveMonths: number
		restOfTime: number
	}[]
	otherContainersSummary: {
		permanentCount: number
		universalCount: number
	}
}

export type CollectionPlace = {
	id: number
	name: string
	municipalityID: number
	type: COLLECTION_PLACE_TYPE
	street: string
	streetNumber: string
	number: string
	city: string
	zip: string
	parentID: number | null
}

export type CollectionPlaceMapItem = {
	id: number
	name: string
	lat: number
	lon: number
	containersCount: number
}

export type WastesPerCustomer = {
	sortedWeight: number
	unsortedWeight: number
	sortedWeightAverage: number
	unsortedWeightAverage: number
	averageSortedGlobal: number
	averageUnsortedGlobal: number
}

export type SortingRate = {
	sortingRate: number
	wasteTypes: {
		name: string
		weight: number
		color: string
		rate: number
	}[]
}

export type Settings = {
	[SETTINGS_KEY.AVERAGE_WASTES]: {
		sortedWaste: {
			[year: string]: number
		}
		unsortedWaste: {
			[year: string]: number
		}
	}
}

export type Sorting = {
	id: number
	name: string
	total: number
	plastic: number
	paper: number
	bio: number
	municipal: number
	other: number
	rate: number
}

export type SortingGraph = {
	sum: {
		[group in SORTING_GROUP]: number
	}
	weight: {
		[group in SORTING_GROUP]: number
	}
}

export type CollectionYardVisits = {
	[timeUnit: string]: number
}

export type CustomerCollection = {
	id: number
	scanDatetime: string
	weight?: number
	container: {
		id: number
		code: string | null
		rfid: string | null
		serialNumber: string | null
		rfidSerial: string | null
		containerType: {
			id: number
			name: string
			wasteType: WasteTypeBase
		}
	}
}

export type Collection = {
	id: number
	containerType: ContainerType
	collectionRound: {
		id: number,
		name: string
	}
	customer: {
		id: number
		name: string
	}
	collectionPlace: {
		id: number
		name: string
		street: string
		streetNumber: string
		number: string
	}
	municipality: {
		id: number
		name: string
	}
	creator: BaseUser
}
