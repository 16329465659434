import React, { FocusEvent, useState } from 'react'
import cx from 'classnames'

import { FieldRenderProps } from 'react-final-form'
import { Info, TextArea, InputWrapper, Label } from './Styled'

type Props = FieldRenderProps<string> & {
	label?: string
	required?: boolean
	autoComplete?: string
	maxLength?: number
	className?: string
	disabled?: boolean
	autoFocus?: boolean
	placeholder?: string
}

const TextAreaField = ({ meta, input, label, required, className, disabled, autoFocus, placeholder }: Props) => {
	const [focused, setFocused] = useState(!!input.value)

	const error = meta.touched && meta.error
	const info = meta.error && focused

	const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
		input.onFocus(e)
		setFocused(true)
	}

	const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
		input.onBlur(e)
		setFocused(false)
	}

	const componentClass = cx(className, { error, info })

	return (
		<InputWrapper className={className}>
			<Label>
				{label}{required ? '*' : ''}
			</Label>
			<TextArea
				{...input}
				disabled={disabled}
				onFocus={handleFocus}
				autoFocus={autoFocus}
				placeholder={placeholder}
				onBlur={handleBlur}
				className={componentClass}
			/>
			<Info className={componentClass}>{meta.error || ''}&nbsp;</Info>
		</InputWrapper>
	)
}

export default TextAreaField
