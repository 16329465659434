import React, { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { capitalize, debounce, map, reduce, slice, times, toUpper, uniq } from 'lodash'

import { Col, Row } from 'antd'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import { useDataLoader } from '../../../hooks/useDataLoader'

import { calendar } from '../../../api'

import SpinLoading from '../../../components/SpinLoading'
import Dot from '../../../components/Dot'

import { SubHeading, TextSmall } from '../../../components/Typography'
import { ButtonElement } from '../../../components/buttons/Button'

const StepButton = styled(ButtonElement)`
	padding: 5px 10px;
`

const PeriodTitle = styled(SubHeading)`
	margin-bottom: 0;
	width: 150px;
	text-align: center;
`

const DaysWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: 60px;
`

const GradientCover = styled.div`
	position: absolute;
	left: -4px;
	right: -4px;
	top: 0;
	height: 100%;
	background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
	pointer-events: none;
`

const DaysRow = styled(Row)`
	overflow-x: auto;
	overflow-y: hidden;
	height: 72px;
	scroll-padding-left: 50px;
	scroll-padding-right: 50px;
`

const DotsWrapper = styled.div`
	height: 12px;
	padding: 2px 0;
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: center;
`

const DayCol = styled(Col)`
	height: 60px;
	width: 48px;

	flex-shrink: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	
	& > ${TextSmall} {
		margin-bottom: 2px;
	}

	& > ${SubHeading} {
		margin-bottom: 0;
	}
	
	&.empty {
		width: 200px;
	}
`

type Props = {
	municipalityID?: string | number
}

const scrollOffset = 250
const periodFormat = 'MMMM YYYY'

const Calendar = ({ municipalityID }: Props) => {
	const [t] = useTranslation()

	const daysRef = useRef<HTMLDivElement | null>(null)

	const [loading, setLoading] = useState(false)
	const [period, setPeriod] = useState(() => dayjs())
	const [calendarState, loadCalendar] = useDataLoader(calendar.loadCalendar)

	const handleNextPeriod = () => setPeriod(period.add(1, 'month'))

	const handlePrevPeriod = () => setPeriod(period.subtract(1, 'month'))

	const debounceLoadCalendar = useMemo(() => debounce(loadCalendar, 500), [loadCalendar])

	const groupedData = useMemo(() => {
		if (calendarState.data) {
			return reduce(calendarState.data.events, (result, event) => {
				const day = dayjs(event.date).date().toString()
				const roundsColors = map(event.collectionRounds, (round) => `#${round.wasteType.color}`)

				if (result[day]) {
					result[day] = uniq([...result[day], ...roundsColors])
				} else {
					result[day] = roundsColors
				}

				return result
			}, {} as { [day: string]: string[] })
		}

		return {}
	}, [calendarState.data])

	const renderDots = (day: number) => {
		const dayKey = day.toString()
		const dayColors = groupedData[dayKey]

		if (dayColors?.length > 4) {
			return [
				...map(slice(dayColors, 0, 3), (color) => (
					<Dot key={color} className={'small'} color={color}/>
				)),
				<Dot key={'plus'} className={'small'} color={'white'}>
					+{dayColors.length - 4}
				</Dot>
			]
		}

		return map(dayColors, (color) => (
			<Dot key={color} className={'small'} color={color}/>
		))
	}

	const scrollDays = (left: number) => daysRef.current?.scroll({
		top: 0,
		left,
		behavior: 'smooth'
	})

	const handleScrollLeft = () => {
		if (daysRef.current) {
			scrollDays(daysRef.current.scrollLeft - scrollOffset)
		}
	}

	const handleScrollRight = () => {
		if (daysRef.current) {
			scrollDays(daysRef.current.scrollLeft + scrollOffset)
		}
	}

	useEffect(() => {
		setLoading(true)

		debounceLoadCalendar({
			municipalityID,
			fromDate: period.startOf('month').toISOString(),
			toDate: period.endOf('month').toISOString()
		})
	}, [debounceLoadCalendar, municipalityID, period])

	useEffect(() => {
		if (calendarState.isLoading) {
			setLoading(false)
		}
	}, [calendarState.isLoading])

	let content

	if (calendarState.isLoading || loading) {
		content = <SpinLoading height={'60px'}/>
	} else if (calendarState.data) {
		content =
			<DaysWrapper>
				<DaysRow ref={daysRef} wrap={false}>
					<DayCol className={'empty'}/>
					{times(period.daysInMonth(), (day) => (
						<DayCol key={day}>
							<DotsWrapper>
								{renderDots(day + 1)}
							</DotsWrapper>
							<TextSmall>{toUpper(period.date(day + 1).format('ddd'))}</TextSmall>
							<SubHeading>{day + 1}</SubHeading>
						</DayCol>
					))}
					<DayCol className={'empty'}/>
				</DaysRow>
				<GradientCover/>
			</DaysWrapper>
	}

	return (
		<Row gutter={[16, 16]} justify={'center'}>
			<Col span={24}>
				<SubHeading>{t('DashboardPage.calendarTitle')}</SubHeading>
			</Col>
			<Col>
				<Row align={'middle'} gutter={8}>
					<Col>
						<StepButton
							className={'secondary'}
							onClick={handlePrevPeriod}
						>
							<LeftOutlined/>
						</StepButton>
					</Col>
					<Col>
						<PeriodTitle>
							{capitalize(period.format(periodFormat))}
						</PeriodTitle>
					</Col>
					<Col>
						<StepButton
							className={'secondary'}
							onClick={handleNextPeriod}
						>
							<RightOutlined/>
						</StepButton>
					</Col>
				</Row>
			</Col>
			<Col span={24}>
				<Row align={'middle'} wrap={false} gutter={8}>
					<Col flex={'0 0 auto'}>
						<StepButton
							className={'secondary'}
							onClick={handleScrollLeft}
						>
							<LeftOutlined/>
						</StepButton>
					</Col>
					<Col flex={'1 1 auto'} style={{ overflow: 'hidden' }}>
						{content}
					</Col>
					<Col flex={'0 0 auto'}>
						<StepButton
							className={'secondary'}
							onClick={handleScrollRight}
						>
							<RightOutlined/>
						</StepButton>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default React.memo(Calendar)
