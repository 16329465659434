import { mapValues } from 'lodash'

import { CODE_PORTIONS_ACTIONS } from '../reducers/codePortionsReducer'
import { getReq } from '../utils/request'
import { ENDPOINTS } from '../utils/enums'

export const loadCodePortions = (queryParams) => async (dispatch) => {
	dispatch({ type: CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_START })

	try {
		const normalizeQueryParams = mapValues(queryParams, (queryParam) => queryParam || undefined)

		const response = await getReq(ENDPOINTS.CODE_PORTIONS, normalizeQueryParams)

		dispatch({ type: CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_DONE, payload: { data: response.data } })
	} catch (e) {
		dispatch({ type: CODE_PORTIONS_ACTIONS.PORTIONS_LOAD_FAIL })
	}
}

export const loadCodePortion = (id) => async (dispatch) => {
	dispatch({ type: CODE_PORTIONS_ACTIONS.PORTION_LOAD_START })

	try {
		const response = await getReq(ENDPOINTS.CODE_PORTION(id))

		dispatch({ type: CODE_PORTIONS_ACTIONS.PORTION_LOAD_DONE, payload: { data: response.data } })
	} catch (e) {
		dispatch({ type: CODE_PORTIONS_ACTIONS.PORTION_LOAD_FAIL })
	}
}

export const loadCodePortionCodes = (codePortionID, queryParams) => async (dispatch) => {
	dispatch({ type: CODE_PORTIONS_ACTIONS.CODES_LOAD_START })

	try {
		const normalizeQueryParams = mapValues(queryParams, (queryParam) => queryParam || undefined)

		const response = await getReq(ENDPOINTS.CODE_PORTION_CODES(codePortionID), normalizeQueryParams)

		dispatch({ type: CODE_PORTIONS_ACTIONS.CODES_LOAD_DONE, payload: { data: response.data } })
	} catch (e) {
		dispatch({ type: CODE_PORTIONS_ACTIONS.CODES_LOAD_FAIL })
	}
}
