import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import storageSession from 'redux-persist/es/storage/session'
import storageLocal from 'redux-persist/lib/storage'

import userReducer from './userReducer'
import statusReducer from './statusReducer'
import loadingReducer from './loadingReducer'
import menuReducer from './menuReducer'
import selectedMunicipalityReducer from './selectedMunicipalityReducer'
import statisticsReducer from './statisticsReducer'
import selectedFiltersOrdersReducer from './selectedFiltersOrdersReducer'
import savedFormsReducer from './savedFormsReducer'
import codePortionsReducer from './codePortionsReducer'

import containerTypesReducer from '../redux/containerTypes/reducer'
import collectionsReducer from '../redux/collections/reducer'
import collectionRoundsReducer from '../redux/collectionRounds/reducer'
import devicesReducer from '../redux/devices/reducer'
import wasteTypesReducer from '../redux/wasteTypes/reducer'
import quantitativeCollectionsReducer from '../redux/quantitativeCollections/reducer'
import municipalitiesReducer from '../redux/municipalities/reducer'
import customerCodesReducer from '../redux/customerCodes/reducer'
import customersReducer from '../redux/customers/reducer'
import collectionPlacesReducer from '../redux/collectionPlaces/reducer'

import { authenticationSlice } from '../redux/authentication'
import { containersSlice } from '../redux/containers'
import { filtersSlice } from '../redux/filters'

export const REDUCER_KEYS = {
	USER: 'user',
	STATUSES: 'statuses',
	FORM: 'form',
	LOADING: 'loading',
	MENU: 'menu',
	BREADCRUMBS: 'breadcrumbs',
	STATISTICS: 'statistics',
	SELECTED_FILTERS_ORDERS: 'selectedFiltersOrders',
	SELECTED_MUNICIPALITY: 'selectedMunicipality',
	DASHBOARD: 'dashboard',
	SAVED_FORMS: 'savedForms',
	CODE_PORTIONS: 'codePortions',
	CONTAINER_TYPES: 'containerTypes',
	COLLECTIONS: 'collections',
	COLLECTION_ROUNDS: 'collectionRounds',
	DEVICES: 'devices',
	WASTE_TYPES: 'wasteTypes',
	QUANTITATIVE_COLLECTIONS: 'quantitativeCollections',
	MUNICIPALITIES: 'municipalities',
	CUSTOMER_CODES: 'customerCodes',
	CUSTOMERS: 'customers'
}

const rootReducer = combineReducers({
	user: userReducer,
	form: persistReducer({
		key: REDUCER_KEYS.FORM,
		storage: storageSession
	}, formReducer),
	statuses: persistReducer({
		key: REDUCER_KEYS.STATUSES,
		storage: storageSession
	}, statusReducer),
	selectedFiltersOrders: persistReducer({
		key: REDUCER_KEYS.SELECTED_FILTERS_ORDERS,
		storage: storageLocal
	}, selectedFiltersOrdersReducer),
	selectedMunicipality: persistReducer({
		key: REDUCER_KEYS.SELECTED_MUNICIPALITY,
		storage: storageLocal
	}, selectedMunicipalityReducer),
	statistics: persistReducer({
		key: REDUCER_KEYS.STATISTICS,
		storage: storageSession
	}, statisticsReducer),
	savedForms: persistReducer({
		key: REDUCER_KEYS.SAVED_FORMS,
		storage: storageLocal
	}, savedFormsReducer),
	codePortions: persistReducer({
		key: REDUCER_KEYS.CODE_PORTIONS,
		storage: storageSession
	}, codePortionsReducer),
	containerTypes: containerTypesReducer,
	menu: menuReducer,
	loading: loadingReducer,
	collections: collectionsReducer,
	collectionRounds: collectionRoundsReducer,
	devices: devicesReducer,
	wasteTypes: wasteTypesReducer,
	quantitativeCollections: quantitativeCollectionsReducer,
	municipalities: municipalitiesReducer,
	customerCodes: customerCodesReducer,
	customers: customersReducer,
	collectionPlaces: collectionPlacesReducer,

	filters: persistReducer({
		key: 'filters',
		storage: storageLocal
	}, filtersSlice.reducer),
	authentication: persistReducer({
		key: REDUCER_KEYS.SAVED_FORMS,
		storage: storageLocal
	}, authenticationSlice.reducer),
	containers: containersSlice.reducer
})

export default rootReducer
