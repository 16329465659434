import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import { map, without } from 'lodash'
import { Col, Row } from 'antd'

import { ButtonElement } from './Button'

import CheckIcon from '../../resources/images/ic-check.svg'

const RadioItem = styled(ButtonElement)`
	display: flex;
	align-items: center;
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.neutral['600']};
	border: 1px solid ${({ theme }) => theme.colors.neutral['100']};
	background-color: ${({ theme }) => theme.colors.neutral['100']};
	padding: 8px 12px;
	
	&:before {
		background-clip: content-box;
		border-radius: 2px;
		padding: 3px;
		content: '';
		height: 16px;
		width: 16px;
		margin-right: 8px;
		border: 1px solid ${({ theme }) => theme.colors.neutral['500']};
	}
	
	&.radio:before {
		border-radius: 50%;
	}
	
	&:hover:not(:disabled), &:focus:not(:disabled) {
		background-color: ${({ theme }) => theme.colors.neutral['100']};
		border: 1px solid ${({ theme }) => theme.colors.neutral['100']};
		box-shadow: none;
	}
	
	&.active, &.active:focus {
		color: ${({ theme }) => theme.colors.neutral['800']};
		background-color: ${({ theme }) => theme.colors.primary['50']};
		border-color: ${({ theme }) => theme.colors.primary['900']};
		
		&.checkbox:before {
			border: none;
			background: url("${CheckIcon}") center no-repeat;
			background-size: 18px;
		}
		
		&:not(.checkbox):before {
			border-color: ${({ theme }) => theme.colors.primary['500']};
			background-color: ${({ theme }) => theme.colors.primary['500']};
		}
	}
	
	&.error {
		border-color: ${({ theme }) => theme.colors.error};
		color: ${({ theme }) => theme.colors.error};
		
		&:before {
			border-color: ${({ theme }) => theme.colors.primary['500']};
		}
	}
`

type Props = {
	value: string | number | (string | number)[] | null
	type?: 'radio' | 'checkbox'
	className?: string
	onChange: (value: string | number | (string | number)[]) => void
	options: { value: string | number, label: string }[]
}

const getIsActive = (value: string | number | (string | number)[] | null, optionValue: string | number) => {
	if (Array.isArray(value)) {
		return value.includes(optionValue)
	}

	return value === optionValue
}

const RadioButton = ({ value, onChange, options, className, type = 'radio' }: Props) => {
	const handleChange = (e: MouseEvent<HTMLButtonElement>, newValue: string | number) => {
		e.preventDefault()

		if (type === 'checkbox') {
			if (Array.isArray(value)) {
				onChange(value.includes(newValue) ? without(value, newValue) : [...value, newValue])
			} else {
				onChange([newValue])
			}
		} else {
			onChange(newValue)
		}
	}

	return (
		<Row gutter={[16, 16]}>
			{map(options, (option) => (
				<Col key={option.value}>
					<RadioItem
						onClick={(e) => handleChange(e, option.value)}
						className={cx(className, { [type]: type, active: getIsActive(value, option.value) })}
					>
						{option.label}
					</RadioItem>
				</Col>
			))}
		</Row>
	)
}

export default RadioButton
