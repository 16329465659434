import React from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'
import { Col, Row } from 'antd'
import i18next from 'i18next'

// components
import Modal from './Modal'

// recources
import locale from '../../resources/locale'

// atoms
import TextInputField from '../../atoms/TextInputField'
import SelectField from '../../atoms/SelectField'

// utils
import { FORMS } from '../../utils/enums'
import { validate, validateSelect, validatorOptions } from '../../utils/validator'
import { parseNumber } from '../../utils/utils'
import { containerTypes } from '../../utils/select'
import NumericInputField from '../../atoms/NumericInputField'
import Button from '../buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateNumber = validate([validatorOptions.REQUIRED, validatorOptions.NUMBER])
const validateRequired = validateSelect([validatorOptions.REQUIRED])

class CreateContainerType extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		wasteTypes: PropTypes.object
	}

	state = {
		shown: false
	}

	componentDidMount = () => {
		this.props.reset(FORMS.CREATE_CONTAINER_TYPE_FORM)
	}

	cancelForm = (e) => {
		e.preventDefault()
		const { dismissHandler } = this.props
		dismissHandler()
	}

	render = () => {
		const { handleSubmit, dismissHandler, shown, wasteTypes } = this.props

		const wasteTypesOptions = map(get(wasteTypes, 'data.types'), (type) => ({
			value: type.id,
			label: type.name
		}))

		const titleInputField = {
			label: i18next.t('loc:Názov'),
			required: true,
			showLabel: true
		}

		const wasteTypeSelectField = {
			label: i18next.t('loc:Druh odpadu'),
			required: true,
			showLabel: true,
			isLoading: wasteTypes.isLoading,
			options: wasteTypesOptions
		}

		const capacityInputField = {
			label: i18next.t('loc:Objem nádoby (l)'),
			required: true,
			showLabel: true,
			min: 1,
			max: 50000
		}

		const containerTypeSelectField = {
			label: i18next.t('loc:Typ nádoby'),
			required: true,
			showLabel: true,
			options: containerTypes
		}
		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['page.containerType.add']}
					</div>
					<div className={'modal-content'}>
						<div className={'row'}>
							<div className={'col-12'}>
								<Field
									name='name'
									placeholder={locale['field.placeholder.title']}
									component={TextInputField}
									props={titleInputField}
									validate={validateRequiredString}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='wasteTypeID'
									placeholder={locale['field.placeholder.wasteType']}
									component={SelectField}
									props={wasteTypeSelectField}
									validate={validateRequired}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='capacity'
									placeholder={locale['field.placeholder.capacity']}
									component={NumericInputField}
									props={capacityInputField}
									normalize={parseNumber}
									validate={validateNumber}
								/>
							</div>
							<div className={'col-md-6'}>
								<Field
									name='type'
									placeholder={locale['field.placeholder.type']}
									component={SelectField}
									props={containerTypeSelectField}
									validate={validateRequired}
								/>
							</div>
						</div>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{locale['page.waste.types.add']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelForm}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

const formName = FORMS.CREATE_CONTAINER_TYPE

export default reduxForm({
	form: formName,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true
})(CreateContainerType)
