import React from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import ReactTimeout from 'react-timeout'
import { reduce, get } from 'lodash'

import { bindActionCreators } from 'redux'
import * as StatusActions from '../actions/statusActions'
import getColorClassByEnum from '../utils/color'
import locale from '../resources/locale'
import Button from './buttons/Button'

function getMessageFromData(data) {
	const messages = get(data, 'messages')
	if (Array.isArray(messages) && messages.length) {
		return reduce(messages, (value, item) => {
			if (item.message) {
				value += `\n${item.message}`
			}
			return value
		}, '')
	}
	return locale['error.message.default']
}

const modalRoot = document.getElementById('modal-root')

class ToastMessage extends React.Component {
	constructor(props) {
		super(props)

		this.el = document.createElement('div')
		this.state = {
			shown: false,
			timer: null
		}
	}

	toggleShown = (timer) => {
		this.setState({
			shown: true,
			timer
		})
	}

	toggleHide = () => {
		this.setState({
			shown: false,
			timer: null
		})
	}

	onClickHandler = () => {
		if (this.state.timer) {
			this.props.clearTimeout(this.state.timer)
			this.setState({ timer: null })
		}
		this.props.StatusActions.statusPop(1)
	}

	toggle = () => {
		this.setState({ timer: null }, () => {
			this.props.StatusActions.statusPop(1)
		})
	}

	checkStatuses = () => {
		if (this.props.statuses.length > 0 && !this.state.timer) {
			const timer = this.props.setTimeout(this.toggle, 3 * 1000)
			this.toggleShown(timer)
		}
		if (this.props.statuses.length === 0 && this.state.shown) {
			this.toggleHide()
		}
	}

	componentWillUnmount = () => {
		modalRoot.removeChild(this.el)
		this.toggleHide()
	}

	componentDidUpdate = () => {
		this.checkStatuses()
	}

	componentDidMount = () => {
		modalRoot.appendChild(this.el)
		this.checkStatuses()
	}

	render = () => {
		if (this.props.statuses.length < 1) {
			return null
		}

		const { type, data, msg } = this.props.statuses[0]
		const backgroundColor = getColorClassByEnum(type)
		const message = msg || getMessageFromData(data)
		const element = (
			<div className={cx('toast-message', { shown: this.state.shown })} data-color={backgroundColor}>
				<div className="message-text">{message}</div>
				<div>
					<Button className="pull-right" datatype="outline" data-color={backgroundColor} onClick={this.onClickHandler}>{locale['common.close']}</Button>
				</div>
			</div>
		)

		return (
			ReactDOM.createPortal(element, this.el)
		)
	}
}

ToastMessage.propTypes = {
	statuses: PropTypes.array,
	StatusActions: PropTypes.shape({
		statusPop: PropTypes.func.isRequired
	}).isRequired,
	setTimeout: PropTypes.func.isRequired,
	clearTimeout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	statuses: state.statuses.statuses
})

const mapDispatchToProps = (dispatch) => ({
	StatusActions: bindActionCreators(StatusActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReactTimeout(ToastMessage))
