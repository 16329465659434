import { forEach } from 'lodash'
import { postReq } from './request'
import { ENDPOINTS } from './enums'

export function uploadFile(file, data, fileName, endpoint) {
	const formData = new FormData()
	formData.append('file', file, fileName)
	forEach(data, (value, key) => {
		formData.append(key, value)
	})

	return postReq(endpoint || ENDPOINTS.UPLOAD, null, formData, {
		'Content-Type': 'multipart/form-data'
	})
}

export function downloadFile(file, data) {
	const url = window.URL.createObjectURL(new Blob([data]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', file.name)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}

export function fileNameFromResponse(response) {
	let filename = null
	const disposition = response.headers['content-disposition']
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
		const matches = filenameRegex.exec(disposition)
		if (matches != null && matches[1]) {
			filename = matches[1].replace(/['"]/g, '')
		}
	}
	return filename || 'File'
}
