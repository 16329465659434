import { join } from 'lodash'
import locale from '../resources/locale'
import { CUSTOMER_LEGAL_FORM } from './enums'

export const formatCustomer = (customer) => {
	if (!customer) {
		return '-'
	}
	if (customer.isAnonymous) {
		return locale.formatString(locale['page.customers.anonymous'], customer.id)
	}

	const items = []
	if (customer.legalForm === CUSTOMER_LEGAL_FORM.INDIVIDUAL) {
		if (customer.titleBefore) {
			items.push(customer.titleBefore)
		}
		if (customer.name) {
			items.push(customer.name)
		}
		if (customer.surname) {
			items.push(customer.surname)
		}
		if (customer.titleAfter) {
			items.push(customer.titleAfter)
		}
		return join(items, ' ')
	}
	if (customer.companyName) {
		items.push(customer.companyName)
	}
	return join(items, ', ')
}
