import React from 'react'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import i18next from 'i18next'

import { join, get, map } from 'lodash'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import locale from '../../resources/locale'

import TextInputField from '../../atoms/TextInputField'
import { validate, validatorOptions } from '../../utils/validator'

import { statusPush } from '../../actions/statusActions'

import { FORMS } from '../../utils/enums'
import CheckBoxField from '../../atoms/CheckBoxField'
import { authenticationActions, getAuthUser } from '../../redux/authentication'
import Button from '../buttons/Button'

const validateName = validate([validatorOptions.REQUIRED])
const validateSurname = validate([validatorOptions.REQUIRED])
const validateEmail = validate([validatorOptions.REQUIRED, validatorOptions.EMAIL])

class UserProfileForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		passwordHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		pushStatus: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		processLogout: PropTypes.func.isRequired
	}

	emailFieldOptions = {
		label: i18next.t('loc:Email'),
		required: true
	}

	nameFieldOptions = {
		label: i18next.t('loc:Meno'),
		required: true
	}

	surnameFieldOptions = {
		label: i18next.t('loc:Priezvisko'),
		required: true
	}

	checkBoxFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Upozornenia')
	}

	returnToList = (e) => {
		e.preventDefault()
		const { cancelHandler } = this.props
		cancelHandler()
	}

	changePassword = (e) => {
		e.preventDefault()
		const { passwordHandler } = this.props
		passwordHandler()
	}

	render() {
		const { initialValues, user, handleSubmit, submitHandler, processLogout } = this.props

		let permissions = null
		if (user.isAdmin) {
			permissions = (
				<div>{locale['role.ADMINISTRATOR']}</div>
			)
		} else {
			const municipalities = get(user, 'municipalities', [])
			permissions = map(municipalities, (item) => (
				<div>{item.name}: {join(map(item.permissions || ['NONE'], (p) => locale[`role.${p}`]), ', ')}</div>
			))
		}

		return (
			<form onSubmit={handleSubmit((data) => submitHandler(data))} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='name'
										type={'text'}
										placeholder={locale['field.placeholder.name']}
										component={TextInputField}
										props={this.nameFieldOptions}
										validate={validateName}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='surname'
										type={'text'}
										placeholder={locale['field.placeholder.surname']}
										component={TextInputField}
										props={this.surnameFieldOptions}
										validate={validateSurname}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='email'
										type={'email'}
										placeholder={locale['field.placeholder.email']}
										component={TextInputField}
										props={this.emailFieldOptions}
										validate={validateEmail}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name={'notifications'}
										showLabel={true}
										component={CheckBoxField}
										props={this.checkBoxFieldOptions}
									/>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-12'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.permissions']}</label>
										{permissions}
									</div>
								</div>
							</div>
							<div className={'row'}>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.created']}</label>
										<div>{moment(initialValues.createdAt).format(locale['common.date.format.date'])}</div>
									</div>
								</div>
								<div className={'col-md-3'}>
									<div className={'info-wrapper'}>
										<label>{locale['page.users.detail.login']}</label>
										<div>{initialValues.lastLoginAt ? moment(initialValues.lastLoginAt).format(locale['common.date.format']) : '-'}</div>
									</div>
								</div>
							</div>
							<div className={'row'}>
								<button onClick={processLogout} className={'button red-bg show-on-mobile'} style={{ marginLeft: '15px' }}>
									{locale['logout.title']}
								</button>
							</div>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button
								onClick={this.changePassword}
							>
								{locale['page.users.detail.change.password']}
							</Button>
						</Col>
						<Col>
							<Button
								className={'secondary'}
								onClick={this.returnToList}
							>
								{locale['common.cancel']}
							</Button>
						</Col>
						<Col>
							<Button
								type="submit"
							>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	user: getAuthUser(state)
})

const mapDispatchToProps = (dispatch) => ({
	pushStatus: bindActionCreators(statusPush, dispatch),
	processLogout: bindActionCreators(authenticationActions.logoutUser, dispatch)
})

export default reduxForm({
	form: FORMS.USER_PROFILE_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(UserProfileForm))
