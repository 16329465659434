import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { times, clamp } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faForwardStep, faBackwardStep, faBackward, faForward } from '@fortawesome/free-solid-svg-icons'
import locale from '../resources/locale'

const NUMBER_OF_DISPLAYED_PAGES = 11

const PageItem = (({ page, currentPage, active = false, hidden = false, onPage, lastPage }) => {
	let content = null
	let handler = null
	if (page === 'next') {
		content = <FontAwesomeIcon icon={faForwardStep}/>
		handler = () => onPage(currentPage + 1)
	} else if (page === 'previous') {
		content = <FontAwesomeIcon icon={faBackwardStep}/>
		handler = () => onPage(currentPage - 1)
	} else if (page === 'first') {
		content = <FontAwesomeIcon icon={faBackward}/>
		handler = () => onPage(1)
	} else if (page === 'last') {
		content = <FontAwesomeIcon icon={faForward}/>
		handler = () => onPage(lastPage)
	} else if (page === 'jumpForward') {
		content = <span>+10</span>
		handler = () => onPage(currentPage + 10)
	} else if (page === 'jumpBackward') {
		content = <span>-10</span>
		handler = () => onPage(currentPage - 10)
	} else {
		content = <div>{page}</div>
		handler = () => onPage(page)
	}

	return (
		<div className={cx('page', { active, hidden, next: page === 'next', last: page === 'last', first: page === 'first', previous: page === 'previous' })} onClick={handler}>
			{content}
		</div>
	)
})

class Pagination extends React.Component {
	static propTypes = {
		pages: PropTypes.number,
		page: PropTypes.number,
		onPage: PropTypes.func.isRequired
	}

	render() {
		const { page, pages, onPage } = this.props
		if (pages <= 1) {
			return null
		}
		const showPreviousPage = page > 1
		const showNextPage = page < pages
		const lastPage = pages

		let firstPage = page
		if (pages <= NUMBER_OF_DISPLAYED_PAGES) {
			firstPage = 1
		} else if (page <= ((NUMBER_OF_DISPLAYED_PAGES - 1) / 2)) {
			firstPage = 1
		} else if (page === pages - 2 || page === pages - 1 || page === pages) {
			firstPage = pages - (NUMBER_OF_DISPLAYED_PAGES - 1)
		} else {
			firstPage = page - ((NUMBER_OF_DISPLAYED_PAGES - 1) / 2)
		}
		const lastDisplayedPage = clamp(firstPage + NUMBER_OF_DISPLAYED_PAGES, pages + 1)

		const pageItems = times(lastDisplayedPage - firstPage, (index) => (
			<PageItem key={firstPage + index} currentPage={page} page={firstPage + index} active={firstPage + index === page} onPage={onPage}/>
		))

		return (
			<div className={'pagination'}>
				<div className={'pagination-content'}>
					<PageItem page={'first'} currentPage={page} hidden={!showPreviousPage} onPage={onPage} firstPage={firstPage} />
					{!!page && page - 10 > 0 && <PageItem page={'jumpBackward'} currentPage={page} hidden={false} onPage={onPage} />}
					<PageItem page={'previous'} currentPage={page} hidden={!showPreviousPage} onPage={onPage} />
					{pageItems}
					<PageItem page={'next'} currentPage={page} hidden={!showNextPage} onPage={onPage} />
					{!!page && page + 10 <= pages && <PageItem page={'jumpForward'} currentPage={page} hidden={false} onPage={onPage} />}
					<PageItem page={'last'} currentPage={page} hidden={!showNextPage} onPage={onPage} lastPage={lastPage}/>
				</div>
				{!!pages && <div className={'total'}>
					{locale.formatString(locale['pagination.of.pages'], pages)}
				</div>}
			</div>
		)
	}
}

PageItem.propTypes = {
	page: PropTypes.any.isRequired,
	currentPage: PropTypes.number,
	lastPage: PropTypes.number,
	active: PropTypes.bool,
	hidden: PropTypes.bool,
	onPage: PropTypes.func.isRequired
}

export default Pagination
