import React, { Component } from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'
import { map } from 'lodash'
import i18next from 'i18next'

class SuggestionsInputField extends Component {
	static propTypes = {
		name: PropTypes.string,
		placeholder: PropTypes.string,
		showLabel: PropTypes.bool,
		label: PropTypes.string,
		loadOptions: PropTypes.func.isRequired,
		autoComplete: PropTypes.string,
		suggestions: PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired,
			value: PropTypes.object.isRequired
		})),
		onSelectSuggestion: PropTypes.func.isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool.isRequired,
			error: PropTypes.string
		}).isRequired,
		input: PropTypes.object.isRequired,
		required: PropTypes.bool,
		type: PropTypes.string
	}

	static defaultProps = {
		showLabel: true,
		placeholder: '',
		label: '',
		required: false,
		autoComplete: 'none'
	}

	state = {
		suggestions: [],
		loading: false
	}

	inputRef = React.createRef()

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside)
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node
	}

	handleClickOutside = (event) => {
		const { input, meta } = this.props

		if (meta.active && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			input.onBlur(this.inputRef.current.value)
		}
	}

	loadSuggestions = (value) => {
		const { loadOptions } = this.props

		loadOptions(value, this.onLoadSuggestions)
	}

	onLoadSuggestions = (suggestions) => {
		this.setState({
			loading: false,
			suggestions
		})
	}

	onChange = (event) => {
		const { input } = this.props
		input.onChange(event)

		const { value } = event.target

		this.setState({
			loading: true,
			suggestions: []
		}, () => this.loadSuggestions(value))
	}

	onFocus = (event) => {
		const { input } = this.props
		input.onFocus(event)

		const { value } = event.target

		this.setState({
			loading: true,
			suggestions: []
		}, () => this.loadSuggestions(value))
	}

	renderSuggestions = () => {
		const { suggestions, loading } = this.state

		if (loading) {
			return <div className={'suggestion label'}>{i18next.t('loc:Načítavam možnosti')}...</div>
		}

		if (suggestions && suggestions.length) {
			return map(suggestions, (item) => (
				<div key={item.key} onClick={(event) => this.selectSuggestion(event, item.value)} className={'suggestion'}>{item.label}</div>
			))
		}

		return <div className={'suggestion label'}>{i18next.t('loc:Žiadne možnosti')}</div>
	}

	selectSuggestion = (event, value) => {
		const { onSelectSuggestion, input } = this.props

		input.onBlur(value)
		onSelectSuggestion(value)
	}

	render() {
		const { input, name, label, placeholder, required, autoComplete, showLabel, meta: { touched, error, active } } = this.props
		const { suggestions } = this.state

		return (
			<div ref={this.setWrapperRef} className={cx('input-wrapper', { error: touched && error })}>
				{showLabel && <label htmlFor={name}>{label}</label>}
				<div className={cx('input-suggestions', { active, 'no-suggestions': !suggestions || suggestions.length === 0 })}>
					{this.renderSuggestions()}
				</div>
				<input
					{...input}
					ref={this.inputRef}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={() => {}}
					placeholder={placeholder}
					type={'text'}
					required={required}
					autoComplete={autoComplete}
					name={`form-${new Date().valueOf()}`}
				/>
				<div className={'close-error'}>
					<div className={'tooltip'}>
						{touched ? error : ''}
					</div>
				</div>
			</div>
		)
	}
}

export default SuggestionsInputField
