import React from 'react'

import styled from 'styled-components'

import { Col, Dropdown, Menu, Row, Table as AntTable } from 'antd'

import { EllipsisOutlined } from '@ant-design/icons'
import { map } from 'lodash'

const Table = styled(AntTable)`
	&.without-header table thead.ant-table-thead {
		display: none;
	}

	tbody {
		tr td {
			border-top: 1px solid ${({ theme }) => theme.colors.neutral['100']};
			border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['100']};

			&:first-child {
				border-left: 1px solid transparent;
			}

			&:last-child {
				border-right: 1px solid transparent;
			}
		}
	}
`

type ActionsProps = {
	actions: {
		icon?: string | React.ReactNode
		title: string
		callback: () => void
	}[]
}

export const TableActions = ({ actions }: ActionsProps) => (
	<Dropdown
		overlay={(
			<Menu>
				{map(actions, (action, index) => (
					<Menu.Item key={index}>
						<Row gutter={8} align={'middle'}>
							{!!action.icon &&
							<Col style={{ paddingBottom: 4 }}>
								{typeof action.icon === 'string' &&
								<img src={action.icon} alt={'Icon'}/>}
								{typeof action.icon !== 'string' && action.icon}
							</Col>}
							<Col>
								{action.title}
							</Col>
						</Row>
					</Menu.Item>
				))}
			</Menu>
		)}
	>
		<EllipsisOutlined/>
	</Dropdown>
)

export default Table
