import { createSelector } from 'reselect'
import { get, map } from 'lodash'

export const getDevicesList = (state) => state.devices.list
export const getDevicesSelectOptions = createSelector([getDevicesList], (list) => ({
	isLoading: get(list, 'isLoading', true),
	values: map(get(list, 'data.devices'), (item) => ({
		value: item.id,
		label: `${item.name} - ${item.number}`
	}))
}))
