import React from 'react'
import styled from 'styled-components'
import { Field, reduxForm } from 'redux-form'

import locale from '../../../resources/locale'

import { FILTER } from '../../../utils/enums'

import CheckBoxField from '../../../atoms/CheckBoxField'

import { HeadingFive } from '../../../components/Typography'

import ValidIcon from '../../../resources/images/icon-valid-pin.svg'
import InvalidIcon from '../../../resources/images/icon-invalid-pin.svg'
import UncollectedIcon from '../../../resources/images/icon-uncollected-pin.svg'

const Wrapper = styled.div`
	background: #FFFFFF;
	box-shadow: -10px 9px 21px rgba(128, 152, 213, 0.074983);
	border-radius: 8px;
	position: absolute;
	top: 32px;
	left: 32px;
	z-index: 2;
	padding: 16px;
`

const FilterIcon = styled.img`
	object-fit: contain;
	width: 20px;
	height: 20px;
`

export type FormValues = {
	collected: boolean
	notCollected: boolean
	invalid: boolean
}

const MapFilter = () => (
	<Wrapper>
		<HeadingFive>{locale['page.collectionRounds.detail.map.filter']}</HeadingFive>
		<div className={'row justify-content-between'}>
			<div className={'col'}>
				<Field
					name={'collected'}
					label={locale['pages.collectionRounds.detail.map.filter.collected']}
					// @ts-ignore
					component={CheckBoxField}
				/>
			</div>
			<div className={'col'} style={{ flexGrow: 0 }}>
				<FilterIcon src={ValidIcon}/>
			</div>
		</div>
		<div className={'row justify-content-between'}>
			<div className={'col'}>
				<Field
					name={'notCollected'}
					label={locale['pages.collectionRounds.detail.map.filter.notCollected']}
					// @ts-ignore
					component={CheckBoxField}
				/>
			</div>
			<div className={'col'} style={{ flexGrow: 0 }}>
				<FilterIcon src={UncollectedIcon}/>
			</div>
		</div>
		<div className={'row justify-content-between'}>
			<div className={'col'}>
				<Field
					name={'invalid'}
					label={locale['pages.collectionRounds.detail.map.filter.invalid']}
					// @ts-ignore
					component={CheckBoxField}
				/>
			</div>
			<div className={'col'} style={{ flexGrow: 0 }}>
				<FilterIcon src={InvalidIcon}/>
			</div>
		</div>
	</Wrapper>
)

const form = reduxForm<FormValues>({
	form: FILTER.COLLECTION_ROUND_MAP_FILTERS,
	initialValues: { collected: true, notCollected: true, invalid: true }
})

export default form(MapFilter)
