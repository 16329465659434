import React from 'react'
import { Field, reduxForm, unregisterField, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'
import { get, map, debounce, join } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Col, Row } from 'antd'
import i18next from 'i18next'

import locale from '../../resources/locale'

import SelectField from '../../atoms/SelectField'
import { validate, validateSelect, validatorOptions } from '../../utils/validator'

import { COLLECTION_ROUND_TYPE, ENDPOINTS, FORMS, INVALID_STATE } from '../../utils/enums'
import { formatAddress, parseNumber } from '../../utils/utils'
import AsyncSelectField from '../../atoms/AsyncSelectField'
import DateTimePickerField from '../../atoms/DateTimePickerField'
import CheckBoxField from '../../atoms/CheckBoxField'
import { getReq } from '../../utils/request'
import { unsuitableConditions } from '../../utils/select'
import InfoFooter from '../InfoFooter'
import NumericInputField from '../../atoms/NumericInputField'
import Button from '../buttons/Button'

const validateRequiredString = validate([validatorOptions.REQUIRED_STRING])
const validateNumber = validate([validatorOptions.REQUIRED_NUMBER])
const validateRequired = validateSelect([validatorOptions.REQUIRED])
const validateDate = validateSelect([validatorOptions.REQUIRED])

class CollectionForm extends React.Component {
	static propTypes = {
		submitHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		deleteHandler: PropTypes.func.isRequired,
		cancelHandler: PropTypes.func.isRequired,
		initialValues: PropTypes.object.isRequired,
		municipalityID: PropTypes.number.isRequired,
		isUnsuitableConditions: PropTypes.bool,
		loadCollectionRounds: PropTypes.func.isRequired,
		unregisterField: PropTypes.func.isRequired,
		wasteTypes: PropTypes.arrayOf(PropTypes.object),
		collectionRound: PropTypes.object
	}

	searchCollectionRounds = (keyword, callback) => {
		const { municipalityID, loadCollectionRounds } = this.props
		loadCollectionRounds(keyword, { municipalityID }, callback)
	}

	searchContainers = (keyword, callback) => {
		const { municipalityID } = this.props
		const context = {
			filter: 'collectionPlace'
		}
		if (keyword && keyword.length) {
			context.search = keyword
		}
		getReq(ENDPOINTS.MUNICIPALITY_CONTAINERS(municipalityID), context).then((response) => {
			const containers = map(get(response, 'data.containers', []), (item) => ({
				value: item.id,
				label: `${item.code || item.rfid} - ${item.wasteType}`
			}))
			callback(containers)
		}).catch(() => callback([]))
	}

	collectionRoundFieldOptions = {
		loadOptions: debounce(this.searchCollectionRounds, 300),
		label: i18next.t('loc:Priradený zvoz'),
		showLabel: true
	}

	containerFieldOptions = {
		loadOptions: debounce(this.searchContainers, 300),
		label: i18next.t('loc:Nádoba'),
		showLabel: true
	}

	quantityFieldOptions = {
		label: i18next.t('loc:Naplnenosť(%)'),
		showLabel: true,
		min: 0,
		max: 9999999
	}

	weightFieldOptions = {
		label: i18next.t('loc:Hmotnosť'),
		showLabel: true,
		min: 0,
		max: 9999999
	}

	scanFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Dátum a čas nasnímania'),
		format: locale['common.date.format'],
		timeFormat: locale['common.time.format']
	}

	isUnsuitableConditionsFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Nezhodný stav')
	}

	unsuitableConditionStateFieldOptions = {
		showLabel: true,
		label: i18next.t('loc:Typ nezhodného stavu'),
		options: unsuitableConditions
	}

	validateUnsuitableConditions = (value, allValues) => {
		const { isUnsuitableConditions } = allValues
		if (isUnsuitableConditions) {
			return validateRequiredString(value)
		}
		return undefined
	}

	handleIsUnsuitableConditionsChange = (value) => {
		if (!value) {
			const { unregisterField } = this.props
			unregisterField(FORMS.CREATE_COLLECTION, 'unsuitableConditionState')
		}
	}

	deleteCollection = (e) => {
		const { deleteHandler } = this.props
		e.preventDefault()
		deleteHandler()
	}

	cancelForm = (e) => {
		const { cancelHandler } = this.props
		e.preventDefault()
		cancelHandler()
	}

	render() {
		const { handleSubmit, submitHandler, initialValues, isUnsuitableConditions, collectionRound } = this.props

		return (
			<form onSubmit={handleSubmit(submitHandler)} noValidate>
				<div className={'box'}>
					<div className={'box-title'}>
						{locale['common.basic.info']}
					</div>
					<div className={'box-content with-padding'}>
						<div className={'box-head'}/>
						<div className={'box-body'}>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<Field
										name='container'
										placeholder={locale['field.placeholder.container']}
										component={AsyncSelectField}
										validate={validateRequired}
										props={this.containerFieldOptions}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='collectionRound'
										placeholder={locale['field.placeholder.collectionRound']}
										component={AsyncSelectField}
										validate={validateRequired}
										props={this.collectionRoundFieldOptions}
									/>
								</div>
								{(!collectionRound || collectionRound.type === COLLECTION_ROUND_TYPE.STANDARD) &&
								<div className={'col-md-6'}>
									<Field
										name='quantity'
										placeholder={locale['field.placeholder.quantity']}
										component={NumericInputField}
										normalize={parseNumber}
										validate={validateNumber}
										props={this.quantityFieldOptions}
									/>
								</div>}
								{collectionRound && collectionRound.type === COLLECTION_ROUND_TYPE.GENERAL &&
								<div className={'col-md-6'}>
									<Field
										name='weight'
										placeholder={locale['field.placeholder.weight']}
										component={NumericInputField}
										normalize={parseNumber}
										validate={validateNumber}
										props={this.weightFieldOptions}
									/>
								</div>}
								<div className={'col-md-6'}>
									<Field
										name='scanDatetime'
										placeholder={locale['field.placeholder.scan.datetime']}
										component={DateTimePickerField}
										validate={validateDate}
										props={this.scanFieldOptions}
									/>
								</div>
								<div className={'col-md-6'}>
									<Field
										name='isUnsuitableConditions'
										component={CheckBoxField}
										props={this.isUnsuitableConditionsFieldOptions}
										onChange={this.handleIsUnsuitableConditionsChange}
									/>
								</div>
								{isUnsuitableConditions && <div className={'col-md-6'}>
									<Field
										name='unsuitableConditionState'
										placeholder={locale['field.placeholder.is.invalid']}
										component={SelectField}
										validate={this.validateUnsuitableConditions}
										props={this.unsuitableConditionStateFieldOptions}
									/>
								</div>}
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Druh odpadu')}</label>
										<div>{get(initialValues, 'wasteType', '-')}</div>
									</div>
								</div>
								{(!collectionRound || collectionRound.type === COLLECTION_ROUND_TYPE.STANDARD) &&
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Hmotnosť')}</label>
										<div>{`${get(initialValues, 'weight', 0)}kg`}</div>
									</div>
								</div>}
								{collectionRound && collectionRound.type === COLLECTION_ROUND_TYPE.GENERAL &&
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Naplnenosť(%)')}</label>
										<div>{`${get(initialValues, 'quantity', 0)}%`}</div>
									</div>
								</div>}
							</div>
							<div className={'row'}>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Adresa')}</label>
										<div>{formatAddress(get(initialValues, 'address', null))}</div>
									</div>
								</div>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:GPS súradnice')}</label>
										{get(initialValues, 'googleMapsUrl')
											? <a href={get(initialValues, 'googleMapsUrl')} className={'link'} target={'_blank'} rel="noreferrer">{get(initialValues, 'lat')}, {get(initialValues, 'lon')}</a>
											: <div>-</div>
										}
									</div>
								</div>
							</div>
							{get(initialValues, 'isInvalid', false) && <div className={'row'}>
								<div className={'col-md-6'}>
									<div className={'info-wrapper'}>
										<label>{i18next.t('loc:Chybný záznam')}</label>
										<div>
											{join(map(get(initialValues, 'invalidState', null), (state) =>
												`${locale[`invalid.state.${state}`]}${initialValues.marker && state === INVALID_STATE.CONTAINER ? ` - ${initialValues.marker}` : ''}`), ', ')
											}
										</div>
									</div>
								</div>
							</div>}
							<InfoFooter
								createdAt={get(initialValues, 'createdAt')}
								creator={get(initialValues, 'creator')}
								updatedAt={get(initialValues, 'updatedAt')}
								editor={get(initialValues, 'editor')}
							/>
						</div>
					</div>
					<Row gutter={16} style={{ marginTop: 16 }}>
						<Col flex={1}>
							<Button className={'danger'} onClick={this.deleteCollection}>
								{locale['common.delete']}
							</Button>
						</Col>
						<Col>
							<Button
								onClick={this.cancelForm}
								className={'secondary'}
							>
								{locale['common.back']}
							</Button>
						</Col>
						<Col>
							<Button>
								{locale['common.save']}
							</Button>
						</Col>
					</Row>
				</div>
			</form>
		)
	}
}

const selector = formValueSelector(FORMS.COLLECTION_FORM)

const mapStateToProps = (state) => ({
	isUnsuitableConditions: selector(state, 'isUnsuitableConditions'),
	collectionRound: selector(state, 'collectionRound')
})

const mapDispatchToProps = (dispatch) => ({
	unregisterField: bindActionCreators(unregisterField, dispatch)
})

export default reduxForm({
	form: FORMS.COLLECTION_FORM,
	destroyOnUnmount: true,
	touchOnChange: true
})(connect(mapStateToProps, mapDispatchToProps)(CollectionForm))
