import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initialize, change, reset } from 'redux-form'
import { get, map } from 'lodash'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { Tabs, Tab } from 'react-bootstrap'

// components
import CustomerForm from './components/CustomerForm'
import Breadcrumbs from '../../components/Breadcrumb'
import DialogModal from '../../components/modals/DialogModal'

// utils
import { ENDPOINTS, FORMS, INFO, MODAL } from '../../utils/enums'
import { getReq, patchReq, deleteReq } from '../../utils/request'
import { history } from '../../utils/history'

// actions
import * as MunicipalityActions from '../../actions/municipalityActions'
import { statusPush } from '../../actions/statusActions'
import { assignCustomerCodes, expireCustomerCode } from '../../redux/customerCodes/actions'

// recources
import locale from '../../resources/locale'
import { formatCustomer } from '../../utils/customer'
import DeactivateModal from '../../components/modals/DeactivateModal'
import CustomerCodesTable from './components/CustomerCodesTable'
import CustomerSorting from './components/CustomerSorting'

class CustomerPage extends React.Component {
	static propTypes = {
		expireCode: PropTypes.func.isRequired,
		assignCodes: PropTypes.func.isRequired,
		pushStatus: PropTypes.func.isRequired,
		initialize: PropTypes.func.isRequired,
		reset: PropTypes.func.isRequired,
		change: PropTypes.func.isRequired,
		match: PropTypes.object.isRequired,
		municipalityActions: PropTypes.object.isRequired,
		municipality: PropTypes.object
	}

	_mounted = false

	constructor(props) {
		super(props)
		this.state = {
			modal: null,
			modalData: null,
			key: 'sorting',
			customer: {
				isLoading: false,
				isFailure: false,
				data: null
			}
		}
	}

	getCustomer = async () => {
		const { match } = this.props
		try {
			const { customerID } = match.params

			if (!this._mounted) {
				return
			}
			this.setState({
				customer: {
					data: null,
					isLoading: true,
					isFailure: false
				}
			})
			const res = await getReq(ENDPOINTS.CUSTOMER(customerID))
			if (!this._mounted) {
				return
			}
			this.setState({
				customer: {
					data: get(res, 'data'),
					isLoading: false,
					isFailure: false
				}
			})
		} catch (e) {
			if (!this._mounted) {
				return
			}
			this.setState({
				customer: {
					data: null,
					isLoading: false,
					isFailure: true
				}
			})
		}
	}

	componentDidUpdate() {
		const { customer, initialValues } = this.state
		const { initialize } = this.props
		if (customer.data && !customer.isLoading && !initialValues) {
			const initValues = {
				legalForm: get(customer, 'data.legalForm'),
				titleBefore: get(customer, 'data.titleBefore'),
				titleAfter: get(customer, 'data.titleAfter'),
				name: get(customer, 'data.name'),
				surname: get(customer, 'data.surname'),
				companyName: get(customer, 'data.companyName'),
				street: get(customer, 'data.street'),
				streetNumber: get(customer, 'data.streetNumber'),
				number: get(customer, 'data.number'),
				city: get(customer, 'data.city'),
				zip: get(customer, 'data.zip'),
				ico: get(customer, 'data.ico'),
				initTimestamp: new Date(),
				createdAt: get(customer, 'data.createdAt'),
				creator: get(customer, 'data.creator'),
				updatedAt: get(customer, 'data.updatedAt'),
				editor: get(customer, 'data.editor')
			}
			// clear form
			initialize(FORMS.UPDATE_CUSTOMER_FORM, initValues, true)
			reset(FORMS.UPDATE_CUSTOMER_FORM)
			if (!this._mounted) {
				return
			}
			this.setState({
				initialValues: initValues
			})
		}
	}

	componentDidMount() {
		this._mounted = true

		const { reset } = this.props

		// clear form
		reset(FORMS.UPDATE_CUSTOMER_FORM)

		// load data
		this.getCustomer()
	}

	componentWillUnmount = () => {
		this._mounted = false
	}

	openModal = (modal) => (modalData) => {
		this.setState({
			modal,
			modalData
		})
	}

	dismissModal = () => {
		this.setState({
			modal: null,
			modalData: null
		})
	}

	submitHandler = async (values) => {
		const { match, pushStatus } = this.props
		const { customer } = this.state
		try {
			const municipalityID = get(customer, 'data.municipalityID')
			const customerID = get(customer, 'data.id')

			const body = {
				legalForm: values.legalForm,
				name: values.name || undefined,
				surname: values.surname || undefined,
				titleBefore: values.titleBefore || null,
				titleAfter: values.titleAfter || null,
				companyName: values.companyName || undefined,
				ico: values.ico || undefined,
				street: values.street,
				streetNumber: values.streetNumber,
				number: values.number,
				city: values.city,
				zip: values.zip
			}
			await patchReq(ENDPOINTS.MUNICIPALITY_CUSTOMER(municipalityID, customerID), null, body)
			pushStatus({
				type: INFO,
				msg: locale['page.customers.update.success']
			})

			// redirect on customer list
			if (match.params.municipalityID) {
				history.push(locale.formatString(locale['path.municipality.customers'], match.params.municipalityID))
			} else {
				history.push(locale['path.lists.customers'])
			}
		} catch (error) {
			// Error
		}
	}

	backBtnCallback = () => {
		const { match } = this.props
		const { municipalityID } = match.params
		// redirect on customer list
		if (municipalityID) {
			history.push(locale.formatString(locale['path.municipality.customers'], municipalityID))
		} else {
			history.push(locale['path.lists.customers'])
		}
	}

	onDeleteCustomer = async () => {
		this.dismissModal()
		try {
			const { pushStatus, match } = this.props
			const { customer } = this.state
			const customerID = get(customer, 'data.id')
			const municipalityID = get(customer, 'data.municipalityID')

			await deleteReq(ENDPOINTS.MUNICIPALITY_CUSTOMER(municipalityID, customerID))
			pushStatus({
				type: INFO,
				msg: locale['page.customers.remove.success']
			})
			// redirect on customer list
			if (match.params.municipalityID) {
				history.push(locale.formatString(locale['path.municipality.customers'], match.params.municipalityID))
			} else {
				history.push(locale['path.lists.customers'])
			}
		} catch (e) {
			// Error
		}
	}

	breadcrumbsItems = () => {
		const { municipality } = this.props
		const { customer } = this.state
		const items = []

		if (municipality) {
			items.push({
				key: 'page.municipalities.breadcrumbs',
				name: locale['page.municipalities.breadcrumbs']
			})
			items.push({
				key: 'page.municipalities.detail',
				name: municipality.name
			})
		}

		return [...items, {
			key: 'page.lists.breadcrumbs',
			name: locale['page.lists.breadcrumbs']
		}, {
			key: 'page.customers.breadcrumbs',
			name: locale['page.customers.breadcrumbs'],
			link: municipality ?
				locale.formatString(locale['path.municipality.customers'], municipality.id)
				: locale['path.lists.customers']
		}, {
			key: 'page.customers.detail.breadcrumbs',
			name: formatCustomer(customer.data)
		}]
	}

	setActiveCustomer = async (values) => {
		try {
			const { pushStatus, match } = this.props
			const { customer } = this.state
			const body = {
				activeTo: get(values, 'activeTo', null)
			}
			await patchReq(ENDPOINTS.MUNICIPALITY_CUSTOMER(get(customer, 'data.municipalityID'), get(customer, 'data.id')), null, body)
			this.dismissModal()
			// redirect on customer list
			if (match.params.municipalityID) {
				history.push(locale.formatString(locale['path.municipality.customers'], match.params.municipalityID))
			} else {
				history.push(locale['path.lists.customers'])
			}
			pushStatus({
				type: INFO,
				msg: locale['page.customers.update.success']
			})
		} catch (e) {
			// Error
		}
	}

	onSelectAddress = (value) => {
		const { change } = this.props

		change(FORMS.UPDATE_CUSTOMER_FORM, 'street', value.street)
		change(FORMS.UPDATE_CUSTOMER_FORM, 'zip', value.zip)
		change(FORMS.UPDATE_CUSTOMER_FORM, 'city', value.city)
	}

	onSetActiveCustomer = () => {
		const { customer } = this.state
		if (customer.data) {
			if (customer.data.activeTo) {
				this.openModal(MODAL.SET_ACTIVE_CUSTOMER)(customer)
			} else {
				this.openModal(MODAL.SET_INACTIVE_CUSTOMER)(customer)
			}
		}
	}

	handleAssignCodes = (codes) => {
		const { assignCodes } = this.props
		const { customer } = this.state

		const data = map(codes, (code) => ({ code, customerID: get(customer, 'data.id') }))
		assignCodes({ id: get(customer, 'data.municipalityID') }, { customersCodes: data }, { onSuccess: this.getCustomer })
	}

	handleExpireCode = (code, validTo) => {
		const { expireCode } = this.props

		expireCode(code, { validTo }, { onSuccess: this.getCustomer })
	}

	render() {
		const { customer, initialValues, modal } = this.state

		if (!customer.data || customer.isLoading) {
			return (
				<div className={'col-12'}>
					<div className={'box-simple'} style={{ height: '200px', marginTop: '40px' }}>
						<div className={cx('table-loading', { hidden: !customer.isLoading })}>
							<div>
								<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
								<span>{locale['page.customers.detail.loading']}</span>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<Breadcrumbs
					items={this.breadcrumbsItems()}
				/>
				<Tabs
					id="collection-place-tabs"
					activeKey={this.state.key}
					onSelect={(key) => this.setState({ key })}
				>
					<Tab title={'Triedenie'} eventKey={'sorting'}>
						<CustomerSorting detail={customer.data}/>
					</Tab>
					<Tab title={'Informácie'} eventKey={'info'}>
						<CustomerForm
							deleteBtnCallback={this.openModal(MODAL.DELETE_CUSTOMER)}
							backBtnCallback={this.backBtnCallback}
							onSubmit={this.submitHandler}
							onSetActive={this.onSetActiveCustomer}
							initialValues={initialValues}
							customer={customer.data}
							municipalityID={customer.data.municipalityID}
							onSelectSuggestion={this.onSelectAddress}
						/>
					</Tab>
					<Tab title={'Kódy'} eventKey={'codes'}>
						<CustomerCodesTable
							customer={customer.data}
							codes={customer.data.codes}
							handleAssignCodes={this.handleAssignCodes}
							handleExpireCode={this.handleExpireCode}
						/>
					</Tab>
				</Tabs>
				<DialogModal
					shown={modal === MODAL.SET_ACTIVE_CUSTOMER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.setActiveCustomer}
					message={locale['page.customers.activate.message']}
					title={locale.formatString(locale['page.customers.activate.title'], formatCustomer(customer.data))}
					acceptTitle={locale['page.customers.activate.accept']}
				/>
				<DeactivateModal
					shown={modal === MODAL.SET_INACTIVE_CUSTOMER}
					dismissHandler={this.dismissModal}
					message={
						locale.formatString(locale['page.customers.deactivate.message'], get(customer, 'data.collectionPlacesCount', 0))
					}
					title={locale.formatString(locale['page.customers.deactivate.title'], formatCustomer(customer.data))}
					acceptTitle={locale['page.containerTypes.deactivate.accept']}
					onSubmit={this.setActiveCustomer}
				/>
				<DialogModal
					shown={modal === MODAL.DELETE_CUSTOMER}
					cancelHandler={this.dismissModal}
					acceptHandler={this.onDeleteCustomer}
					message={locale.formatString(locale['page.customer.remove.message'], get(customer, 'data.collectionPlacesCount', ''))}
					title={locale.formatString(locale['page.customer.remove.title'], formatCustomer(customer.data))}
					acceptTitle={locale['page.customer.remove.accept']}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data')
})

const mapDispatchToProps = (dispatch) => ({
	assignCodes: bindActionCreators(assignCustomerCodes, dispatch),
	expireCode: bindActionCreators(expireCustomerCode, dispatch),
	pushStatus: bindActionCreators(statusPush, dispatch),
	initialize: bindActionCreators(initialize, dispatch),
	change: bindActionCreators(change, dispatch),
	municipalityActions: bindActionCreators(MunicipalityActions, dispatch),
	reset: bindActionCreators(reset, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage)
