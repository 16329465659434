import dayjs from 'dayjs'
import i18next from 'i18next'
import { AnyObject } from 'final-form'

import { FormValues } from '../components/MotivationModelModal'

export const validateDateFrom = (value: string, allValues: FormValues) => {
	if (value && dayjs(allValues.validTo).endOf('day') < dayjs(value)) {
		return i18next.t('errors.dateFromAfterDateTo')
	}

	return undefined
}

export const validateDateTo = (value: string, allValues: AnyObject) => {
	if (value && dayjs(allValues.validFrom).endOf('day') > dayjs(value)) {
		return i18next.t('errors.dateFromAfterDateTo')
	}

	return undefined
}
