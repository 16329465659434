/* eslint-disable react/prop-types */
import React from 'react'
import { reduxForm, unregisterField, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { Col, Row } from 'antd'

import Modal from './Modal'

import locale from '../../resources/locale'

import { EVENT_TYPE, FORMS } from '../../utils/enums'
import EventFormFields from '../calendar/EventFormFields'
import Button from '../buttons/Button'

class CreateEvent extends React.Component {
	static propTypes = {
		dismissHandler: PropTypes.func.isRequired,
		handleSubmit: PropTypes.func.isRequired,
		createHandler: PropTypes.func.isRequired,
		wasteTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
		reset: PropTypes.func.isRequired,
		shown: PropTypes.bool.isRequired,
		type: PropTypes.string
	}

	componentDidMount() {
		const { reset } = this.props
		reset()
	}

	cancelCreate = (e) => {
		const { dismissHandler } = this.props
		e.preventDefault()
		dismissHandler()
	}

	onTypeChange = (e, type) => {
		const { unregisterField } = this.props
		if (type !== EVENT_TYPE.COLLECTION_ROUND) {
			unregisterField(FORMS.CREATE_EVENT, 'isPublic')
			unregisterField(FORMS.CREATE_EVENT, 'isDoorToDoor')
			unregisterField(FORMS.CREATE_EVENT, 'wasteTypeID')
		} else {
			unregisterField(FORMS.CREATE_EVENT, 'isVisibleForStatistics')
		}
	}

	render() {
		const {
			handleSubmit,
			dismissHandler,
			createHandler,
			shown,
			wasteTypes,
			type,
			municipality
		} = this.props

		return (
			<Modal dismiss={dismissHandler} shown={shown} size="s">
				<form onSubmit={handleSubmit(createHandler)} className={'form-horizontal'} noValidate>
					<div className={'modal-header'}>
						{locale['page.calendar.events.add']}
					</div>
					<div className={'modal-content'}>
						<EventFormFields
							municipality={municipality}
							isCreate={true}
							wasteTypes={wasteTypes}
							onTypeChange={this.onTypeChange}
							type={type}
						/>
					</div>
					<div className={'modal-footer clearfix'}>
						<Row gutter={16} style={{ marginTop: 16 }}>
							<Col>
								<Button
									type="submit"
								>
									{locale['page.calendar.events.add']}
								</Button>
							</Col>
							<Col>
								<Button
									className={'secondary'}
									onClick={this.cancelCreate}
								>
									{locale['common.cancel']}
								</Button>
							</Col>
						</Row>
					</div>
				</form>
			</Modal>
		)
	}
}

const selector = formValueSelector(FORMS.CREATE_EVENT)

const mapStateToProps = (state) => ({
	municipality: get(state, 'selectedMunicipality.municipality.data', null),
	type: selector(state, 'type'),
	wasteTypes: get(state, 'selectedMunicipality.wasteTypes.data.types', [])
})

const mapDispatchToProps = (dispatch) => ({
	unregisterField: bindActionCreators(unregisterField, dispatch)
})

export default reduxForm({
	form: FORMS.CREATE_EVENT,
	touchOnChange: true,
	destroyOnUnmount: true
})(connect(mapStateToProps, mapDispatchToProps)(CreateEvent))
