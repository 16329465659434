import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { map } from 'lodash'

import { State } from './index'
import { Container, SelectDataType } from '../types/data'

import { containers } from '../api'

export type ContainersState = {
	select: SelectDataType<Container>
}

const initialState: ContainersState = {
	select: {
		values: null,
		isLoading: false
	}
}

const loadContainersSelect = createAsyncThunk('containers/loadSelect',
	async (filters: object = { limit: 100 }) => {
		const { data } = await containers.loadContainers(filters)

		return map(data.containers, (container) => ({
			value: container.id,
			label: `${container.code || container.rfidSerial || container.rfidSerial || container.rfid} - ${container.containerType?.name}`,
			item: container
		}))
	})

export const containersSlice = createSlice({
	name: 'containers',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loadContainersSelect.pending, (state) => {
			state.select = { ...state.select, isLoading: true }
		})

		builder.addCase(loadContainersSelect.rejected, (state) => {
			state.select = { ...state.select, isLoading: false }
		})

		builder.addCase(loadContainersSelect.fulfilled, (state, { payload }) => {
			state.select = { ...state.select, isLoading: false, values: payload }
		})
	}
})

export const getContainersSelect = (state: State) => state.containers.select

export const containersActions = {
	...containersSlice.actions,
	loadContainersSelect
}
