import React from 'react'
import styled from 'styled-components'

import { useSelector } from 'react-redux'
import { AnyObject } from 'final-form'

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	z-index: 8;
	background-color: rgba(100, 100, 100, 0.9);
`

const Loading = () => {
	const { message, visible } = useSelector((state: AnyObject) => state.loading)

	if (!visible) {
		return null
	}

	return (
		<Wrapper>
			{message}
		</Wrapper>
	)
}

export default Loading
