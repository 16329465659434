import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'
import i18next from 'i18next'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import locale from '../../resources/locale'

// components
import ContainerTypesTableRow from './ContainerTypesTableRow'

// atoms
import TableHead from '../../atoms/TableHead'
import { EmptyTableState } from '../EmptyTableState'

class ContainerTypesTable extends React.Component {
	static propTypes = {
		containerTypes: PropTypes.arrayOf(PropTypes.object),
		error: PropTypes.string,
		loading: PropTypes.bool.isRequired,
		onSort: PropTypes.func.isRequired,
		onDetail: PropTypes.func.isRequired,
		defaultSort: PropTypes.string,
		isSorting: PropTypes.string,
		isSortingColumn: PropTypes.string,
		isSortingDirection: PropTypes.string,
		onDelete: PropTypes.func.isRequired,
		onSetActive: PropTypes.func.isRequired
	}

	render() {
		const { containerTypes, loading, onDetail, onSort, isSortingColumn, isSortingDirection, onSetActive, onDelete } = this.props
		const rows = map(containerTypes, (containerType, index) =>
			<ContainerTypesTableRow
				key={get(containerType, 'id')}
				containerType={containerType}
				onDetail={onDetail}
				countIndex={index}
				onDelete={onDelete}
				onSetActive={onSetActive}
			/>)
		return (
			<div className={'table-wrapper'}>
				<table>
					<thead>
						<tr>
							<TableHead
								name={'name'}
								isSorting={isSortingColumn === 'name'}
								title={i18next.t('loc:Názov')}
								initDirection={isSortingDirection}
								onSort={onSort}
							/>
							<TableHead
								name={'wasteType'}
								isSorting={isSortingColumn === 'wasteType'}
								title={i18next.t('loc:Druh odpadu')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'capacity'}
								isSorting={isSortingColumn === 'capacity'}
								title={i18next.t('loc:Objem nádoby (l)')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'type'}
								isSorting={isSortingColumn === 'type'}
								title={i18next.t('loc:Typ nádoby')}
								onSort={onSort}
								initDirection={isSortingDirection}
							/>
							<TableHead
								name={'isActive'}
								isSorting={isSortingColumn === 'isActive'}
								title={i18next.t('loc:Stav')}
								onSort={onSort}
								className={'text-center'}
								initDirection={isSortingDirection}
							/>
							<th/>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				{!loading && containerTypes && !containerTypes.length && <EmptyTableState title={locale['page.containersType.none']}/>}
				<div className={cx('table-loading', { hidden: !loading })}>
					<div>
						<FontAwesomeIcon icon={faCircleNotch} size={'2x'} spin/>
						<span>{locale['page.containersTypes.loading']}</span>
					</div>
				</div>
			</div>
		)
	}
}

export default ContainerTypesTable
